// @flow
import {
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { CLOSE_SPLIT_SAGA } from "../../helpers/constants";
import { closeSplitModal } from "../../actions/modals/actions";
import { restoreBackup } from "../../actions/backup/actions";

export function* closeSplitSaga(): Saga<void> {
  yield put(closeSplitModal());
  yield put(restoreBackup());
}

export default function* watchCloseSplit(): Saga<void> {
  yield takeEvery(CLOSE_SPLIT_SAGA, closeSplitSaga);
}
