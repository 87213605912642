// @flow
import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import {
  FileCopyOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Can from "../Can";
import FunnelTemplateViewHeaderBtn from "./FunnelTemplateViewHeaderBtn";

import type { HeaderArgs } from "./types/FunnelTemplateView.types";

const styles = makeStyles((theme) => ({
  headerText: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: theme.palette.text.titleColor,
  },
}));

const FunnelTemplateViewHeader = ({
  funnelTemplateId,
  funnelTemplateName,
}: HeaderArgs) => {
  const classes = styles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      flexWrap="wrap"
      mt={6}
      mb={3}
    >
      <Typography
        variant="h5"
        className={classes.headerText}
      >
        {funnelTemplateName}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
      >
        <Can permissions={["/dictionary/funnelTemplates/create"]}>
          <Box>
            <FunnelTemplateViewHeaderBtn
              color="inherit"
              innerIcon={<FileCopyOutlined />}
              innerText="Duplicate"
              path={`/funnelTemplates?sourceId=${funnelTemplateId}`}
              dataTestid="duplicate-button"
            />
          </Box>
        </Can>
        <Can permissions={["/dictionary/funnelTemplates/create"]}>
          <Box ml={1}>
            <FunnelTemplateViewHeaderBtn
              color="inherit"
              innerIcon={<EditOutlined />}
              innerText="Edit"
              path={`/funnelTemplates/${funnelTemplateId}`}
              dataTestid="edit-button"
            />
          </Box>
        </Can>
      </Box>
    </Box>
  );
};

export default FunnelTemplateViewHeader;
