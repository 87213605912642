// @flow
import React from "react";
import { Tab } from "@mui/material";
import { withStyles } from "@mui/styles";

const TabItem = ({
  ...rest
}) => {
  const { classes } = rest;
  return (
    <Tab classes={classes.disabled} {...rest} />
  );
};

export default withStyles(() => ({
  disabled: {
    minWidth: 0,
    padding: 0,
  },
}))(TabItem);
