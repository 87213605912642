// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
} from "@fas/ui-core";
import {
  Check,
  RemoveCircle,
} from "@mui/icons-material";
import type { TableActionProps } from "./FrequencyCapForJumps.types";

const FrequencyCapForJumpsTableActions: StatelessFunctionalComponent<TableActionProps> = ({
  items,
  onChangeIsFrequencyCap,
}: TableActionProps) => (
  <TableActions>
    <TableActionGroup>
      <SimpleTableActionButton
        tooltip="Activate Frequency Cap"
        onClick={(): mixed => onChangeIsFrequencyCap({ items, isFrequencyCap: true })}
        Icon={<Check />}
      />
      <SimpleTableActionButton
        tooltip="Deactivate Frequency Cap"
        onClick={(): mixed => onChangeIsFrequencyCap({ items, isFrequencyCap: false })}
        Icon={<RemoveCircle />}
      />
    </TableActionGroup>
  </TableActions>
);

export default FrequencyCapForJumpsTableActions;
