// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { createLoadingSelector, type StoreWithLoading } from "@fas/ui-framework/lib/redux/selectors/loading";
import { getErrorMessage } from "@fas/ui-framework/lib/redux/selectors/errors";
import {
  type State,
} from "../../reducers/antifraudDictionariesForm";
import type { Errors } from "../../containers/AntifraudDictionaryForm";
import type { State as AntifraudDictionariesFormState } from "../../pages/AntifraudDictionaryForm";
import type { AntifraudDictionaryLoadingTypes, DictionaryFormData } from "./types";

export const getAntifraudDictionary: OutputSelector<
  AntifraudDictionariesFormState, *, DictionaryFormData
> = createSelector(
  (state: AntifraudDictionariesFormState): State => state.antifraudDictionaryForm,
  (value: State): DictionaryFormData => value.toObject()
);

export const getLoading: OutputSelector<
  StoreWithLoading<AntifraudDictionaryLoadingTypes>, AntifraudDictionaryLoadingTypes, boolean
> = createLoadingSelector<AntifraudDictionaryLoadingTypes>();

export const getErrors: OutputSelector<AntifraudDictionariesFormState, *, Errors> = createSelector(
  [
    (state: AntifraudDictionariesFormState): string => getErrorMessage(state, "name"),
    (state: AntifraudDictionariesFormState): string => getErrorMessage(state, "description"),
    (state: AntifraudDictionariesFormState): string => getErrorMessage(state, "value"),
    (state: AntifraudDictionariesFormState): string => getErrorMessage(state, "scenario"),
    (state: AntifraudDictionariesFormState): string => getErrorMessage(state, "ttl"),
  ],
  (
    name: string,
    description: string,
    value: string,
    scenario: string,
    ttl: string
  ): Errors => ({
    name,
    description,
    value,
    scenario,
    ttl,
  })
);
