// @flow
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import {
  GET_ANTIFRAUD_SCENARIO_SAGA,
} from "../../helpers/constants";
import {
  setAntifraudScenario,
  type GetAntifraudScenarioSaga,
} from "../../actions/antifraudScenarioForm";
import { fetchAntifraudScenario } from "../../services/antifraudScenariosApi";
import type { Scenario } from "../../selectors/antifraudScenarios";

export function* makeFetch(action: GetAntifraudScenarioSaga): Saga<void> {
  try {
    const { scenarioId }: GetAntifraudScenarioSaga = action;
    yield put(setLoading(GET_FORM_DATA_SAGA, true));

    const { data: { scenario } }: { data: { scenario: Scenario }} = yield call(fetchAntifraudScenario, scenarioId);

    yield put(setAntifraudScenario(scenario));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch scenario", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(GET_FORM_DATA_SAGA, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_ANTIFRAUD_SCENARIO_SAGA, (makeFetch: Function));
}
