// @flow
import environment from "environment";
import qs from "qs";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import type {
  Filters,
  Sorting,
} from "@fas/ui-framework/lib/redux/reducers/table";
import { requestService } from "../request";
import type { Dictionary, DictionaryForSave } from "../../selectors/antifraudDictionaries";

export type AntifraudDictionariesListResponseType = {
  data: {
    list: Array<Dictionary>,
    count: number,
  },
}

export const fetchAntifraudDictionariesList: (
  options: {
    page: number,
    limit: number,
    filters: Filters,
    sorting: Sorting,
  },
) => PromiseResponse<AntifraudDictionariesListResponseType> = (
  options
) => requestService
  .get(environment.endpoints.get.antifraudDictionariesList, {
    params: { ...options },
    paramsSerializer: ({ filters, sorting, ...rest }: *): string => qs.stringify({
      ...rest,
      filters: Object.keys(filters).length !== 0 ? filters : "{}", // TODO: change contracts, fix backend and front in MTU-56306
      sorting: Object.keys(sorting).length !== 0 ? sorting : "{}", // TODO: change contracts, fix backend and front in MTU-56306
    }, { arrayFormat: "brackets", encode: true, encodeValuesOnly: true }),
  })
  .then(({ data }: { data: AntifraudDictionariesListResponseType }): AntifraudDictionariesListResponseType => data);

export const fetchAntifraudDictionary: (
  id: number
) => PromiseResponse<{ dictionary: Dictionary }> = (id) => requestService
  .get<{ dictionary: Dictionary }>(environment.endpoints.get.antifraudDictionary, {
    params: {
      dictId: id,
    },
  })
  .then(({ data }) => data);

export const createAntifraudDictionary: (
  dictionary: DictionaryForSave
) => PromiseResponse<{}> = (dictionary) => requestService
  .post(environment.endpoints.post.antifraudDictionary, { ...dictionary });

export const saveAntifraudDictionary: (
  dictionary: DictionaryForSave, dictId: number,
) => PromiseResponse<{}> = (dictionary, dictId) => requestService
  .put(environment.endpoints.put.antifraudDictionary, { ...dictionary, dictId });

export const fetchAntifraudDictionaryActions: () => PromiseResponse<*> = () => requestService
  .get(environment.endpoints.get.antifraudDictionaryActionsList)
  .then(({ data }) => data);
