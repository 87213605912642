// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";

export const getTemplateById: (string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.template, {
    params: { id },
  });

export const saveTemplate: (Object) => PromiseResponse<Object> = (template) => requestService
  .post(environment.endpoints.post.saveTemplate, template);

export const getTemplateViewById: (string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.templateView, {
    params: { id },
  });

export const getCampaignsByTemplateId: (
  string,
  {
    page: number,
    pageSize: number,
    filters: Object,
    sorting: Object,
  }
) => PromiseResponse<mixed> = (id, params) : Promise<Response> => requestService
  .post(environment.endpoints.post.templateCampaigns, {
    id,
    page: params.page,
    pageSize: params.pageSize,
    sorting: params.sorting,
    filters: params.filters,
  });

export const getFunnelsTemplateList: ({
  page: number,
  pageSize: number,
  filters: Object,
  sorting: Object
}) => PromiseResponse<mixed> = (params) => requestService
  .get(environment.endpoints.get.funnelTemplateList, {
    params: {
      ...params,
      filters: params.filters,
    },
  });

export const deleteFunnelsTemplate: (string) => PromiseResponse<mixed> = (templateId) => requestService
  .delete(environment.endpoints.delete.funnelsTemplate, {
    params: {
      templateId,
    },
  });

export const getFunnelsTemplateReportList: ({
  columns: string[],
  groupBy: string,
  page: number,
  pageSize: number,
  filters: Object,
  sorting: Object
}) => PromiseResponse<mixed> = (params) => requestService.get(environment.endpoints.get.funnelTemplateReportList, {
  params: { ...params, columns: params.columns.join(",") },
});

export const exportFunnelsTemplateReport: ({
  columns: string[],
  groupBy: string,
  sorting: Object,
  filters: Object,
}) => PromiseResponse<mixed> = (params) => requestService.get(environment.endpoints.get.exportFunnelTemplateReport, {
  params: { ...params },
});

export const getTemplateInfoById: (string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.templateInfo, {
    params: { id },
  });
