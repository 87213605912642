// @flow
import { GET_DICTIONARY_SAGA } from "../../helpers/constants";
import type { DictionaryParams } from "../../hooks/useDictionary";

export type GetDictionarySaga = {|
  type: typeof GET_DICTIONARY_SAGA,
  params: DictionaryParams,
|}

export function getDictionarySaga(params: DictionaryParams): GetDictionarySaga {
  return {
    type: GET_DICTIONARY_SAGA,
    params,
  };
}
