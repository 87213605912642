// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CopyToClipboardInput } from "@fas/ui-core";
import {
  Button,
} from "@mui/material";
import { useLoading } from "../../hooks";
import type { Props } from "./types/ShortUrlBuilder.types";
import {
  getShortUrlSaga,
} from "../../actions/campaignInfo";
import { GET_SHORT_URL_SAGA } from "../../helpers/constants";

const ShortUrlBuilder: StatelessFunctionalComponent<Props> = ({
  campaignId,
  shortUrlId,
  shortUrlTime,
  getUrl,
}: Props) => {
  const dispatch: <A>(A) => A = useDispatch();
  const isLoading: boolean = useLoading(GET_SHORT_URL_SAGA);
  const params = useParams();

  const url:string = getUrl();

  const isNewCampaign: boolean = !params.id || !campaignId;

  const handleBuildShortUrl = (): void => {
    dispatch(getShortUrlSaga(campaignId));
  };

  return (
    <>
      {(!shortUrlId || !shortUrlTime || isNewCampaign) && (
        <Button
          data-testid="generateShortUrl"
          variant="contained"
          color="primary"
          onClick={handleBuildShortUrl}
          disabled={isLoading || isNewCampaign}
        >
          Generate Short URL
        </Button>
      )}
      {shortUrlId && shortUrlTime && (
        <CopyToClipboardInput
          title="Campaign url"
          value={url}
          fullWidth
        />
      )}
    </>
  );
};

export default ShortUrlBuilder;
