// @flow
import React from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import AntifraudDictionariesList from "../../containers/AntifraudDictionariesList";
import NotificationsContainer from "../../components/Notification";

const store = configureStore();

function AntifraudDictionary() {
  return (
    <DocumentTitle title="Antifraud - Dictionaries">
      <Provider store={store}>
        <AntifraudDictionariesList />
        <NotificationsContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default AntifraudDictionary;
