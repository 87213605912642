// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Loader, GridUc } from "@fas/ui-core";
import type { Props } from "./types/FormHeader.types";

const FormHeader: StatelessFunctionalComponent<Props> = ({
  title,
  onSave,
  renderComponent,
  loading,
  isActionVisible = true,
}: Props) => (
  <Paper square>
    <GridUc container justifyContent="space-between" alignItems="center" px={3} py={2}>
      <Box component={Grid} item>
        <Typography variant="h6">
          {title}
        </Typography>
      </Box>
      {isActionVisible && (
        <Box component={Grid} item display="flex" flexDirection="row" alignItems="center">
          {renderComponent}
          <Loader loading={loading}>
            <Button
              data-testid="applyButton"
              color="primary"
              variant="contained"
              size="medium"
              onClick={onSave}
              disabled={loading}
            >
              Save
            </Button>
          </Loader>
        </Box>
      )}
    </GridUc>
  </Paper>
);

export default FormHeader;
