// @flow
import {
  ADD_MANUAL_TAG,
  REMOVE_MANUAL_TAG,
} from "../../helpers/constants";

export const addManualTag = (tag: { label: string, value: string }) => ({
  type: ADD_MANUAL_TAG,
  tag,
});

export const removeManualTag = (tag: string) => ({
  type: REMOVE_MANUAL_TAG,
  tag,
});
