// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetFormDataSaga from "../../sagas/getFormDataSaga";
import watchGetDropdownList from "../../sagas/getDropdownList";
import watchSaveAntifraudVariable from "../../sagas/saveAntifraudVariable";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetFormDataSaga),
    call(watchSaveAntifraudVariable),
    call(watchGetDropdownList),
  ]);
}
