// @flow
import { ANTIFRAUD_VARIABLE_FORM, SMARTLINK_REDIRECT_FORM, HISTORY_REPLACE_FORM } from "../../helpers/constants";
import { fetchAntifraudVariable } from "../antifraudVariablesApi";
import { getSmartlinkRedirect } from "../smartlinkAggregatorsApi";
import { getHistoryReplaceForm } from "../historyReplaceApi";

export const apiMapCreateFormData = {};

export const apiMapGetFormData = {
  [ANTIFRAUD_VARIABLE_FORM]: fetchAntifraudVariable,
  [SMARTLINK_REDIRECT_FORM]: getSmartlinkRedirect,
  [HISTORY_REPLACE_FORM]: getHistoryReplaceForm,
};
