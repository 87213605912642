// @flow
import type { StatelessFunctionalComponent } from "react";
/* eslint-disable import/max-dependencies */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container } from "@mui/material";
import { Loader, SetupCard, FormHeader } from "@fas/ui-core";
import { useParams, type Match } from "react-router-dom";
import {
  TextFieldForm,
  RadioSelect,
  SelectForm,
} from "@fas/ui-core/lib/Form";
import type { Option } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import { SAVE_FORM_DATA_SAGA, GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import {
  ANTIFRAUD_VARIABLE_FORM,
  ANTIFRAUD_VARIABLE_SOURCE_REQUEST,
  ANTIFRAUD_VARIABLE_SOURCE_DICTIONARY,
} from "../../helpers/constants";
import Form from "../../containers/Form";
import ShowByValue from "./ShowByValue";
import { useLoading, useDictionaryDropdownList } from "../../hooks";
import validate from "./validate";
import { useResetFieldByFieldChange } from "./useResetFieldByFieldChange";

type Props = {||};

const AntifraudVariableForm: StatelessFunctionalComponent<Props> = () => {
  const { id }: $PropertyType<Match, "params"> = useParams();
  const dispatch = useDispatch();

  const isSaveFormloading: boolean = useLoading(SAVE_FORM_DATA_SAGA);
  const isGetFormloading: boolean = useLoading(GET_FORM_DATA_SAGA);
  const loading: boolean = isSaveFormloading || isGetFormloading;

  const [types, isTypesLoading]: [Array<Option<string>>, boolean] = useDictionaryDropdownList("variables", "types");
  const [dictionaries, isVariablesLoading]: [Array<Option<number>>, boolean] = useDictionaryDropdownList("variables", "dictionaries");
  const [variables, isDictionariesLoading]: [Array<Option<number>>, boolean] = useDictionaryDropdownList("variables", "variables");

  const { source } = useSelector((state) => getFormData(state, ANTIFRAUD_VARIABLE_FORM));
  const typesBySource: Array<Option<string>> = source === ANTIFRAUD_VARIABLE_SOURCE_REQUEST
    ? types
    : types.filter(({ value }: Option<string>): boolean => value === "varScore");

  useEffect(() => {
    if (id) {
      dispatch(getFormDataSaga(ANTIFRAUD_VARIABLE_FORM, id));
    }
  }, [id]);

  useResetFieldByFieldChange("source", "type", "");

  return (
    <Form id={ANTIFRAUD_VARIABLE_FORM} validate={validate} redirectOnSave="/antifraudVariables/list">
      <FormHeader
        title={id ? `Edit variable: ${id}` : "Create new variable"}
        loading={loading}
        isActionVisible={!loading}
      />
      <Container maxWidth="md">
        <Loader loading={loading}>
          <SetupCard title="General options">
            <Box width={1}>
              <TextFieldForm
                name="name"
                label="Name"
              />
              <TextFieldForm
                name="description"
                label="Description"
              />
              <SelectForm
                name="type"
                label="Type"
                // $FlowFixMe
                options={typesBySource}
                disabled={isTypesLoading || Boolean(id)}
              />
              <RadioSelect
                name="source"
                label="Source"
                disabled={Boolean(id)}
                options={[
                  { label: "Request", value: ANTIFRAUD_VARIABLE_SOURCE_REQUEST },
                  { label: "Dictionary", value: ANTIFRAUD_VARIABLE_SOURCE_DICTIONARY },
                ]}
              />
              <ShowByValue name="source" targetValue={ANTIFRAUD_VARIABLE_SOURCE_REQUEST}>
                <TextFieldForm
                  name="value"
                  label="Value"
                />
              </ShowByValue>
              <ShowByValue name="source" targetValue={ANTIFRAUD_VARIABLE_SOURCE_DICTIONARY}>
                <>
                  <SelectForm
                    name="dictionaryId"
                    label="Dictionary"
                    // $FlowFixMe
                    options={dictionaries}
                    disabled={isDictionariesLoading}
                  />
                  <SelectForm
                    name="variableId"
                    label="Variable"
                    // $FlowFixMe
                    options={variables}
                    disabled={isVariablesLoading}
                  />
                </>
              </ShowByValue>
            </Box>
          </SetupCard>
        </Loader>
      </Container>
    </Form>
  );
};

export default AntifraudVariableForm;
