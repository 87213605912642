// @flow
import { Map, OrderedMap } from "immutable";
import type { Permission } from "@fas/ui-framework/lib/services/user";
import { userService } from "../../services/user";
import {
  TAB_CHANGE,
  TAB_INIT,
} from "../../helpers/constants";
import type {
  TabActions,
} from "../../actions/tabs";

export type Tab = {
  title: string,
  urlParam: String,
  permissions?: Array<Permission>,
}

export type TabState = {
  byId: OrderedMap<{
    [key: string]: Map<Tab>
  }>,
  current: string,
  currentUrlParam: string,
};

export type State = TabState;

export const initialState: Map<State> = Map({
  byId: OrderedMap({
    "1": Map({
      title: "General",
      urlParam: "general",
    }),
    "2": Map({
      title: "Campaigns",
      urlParam: "campaigns",
    }),
    "3": Map({
      title: "Funnels",
      urlParam: "funnels",
    }),
    "4": Map({
      title: "JSON",
      urlParam: "json",
      permissions: [{ method: "GET", path: "/api/v1/campaign" }, "/unitedReborn/campaign/json"],
    }),
  }),
  current: "1",
});

export function initTabsState() {
  return initialState;
}

type Action = TabActions;

export default (state: Map<State> = initialState, action: Action) => {
  switch (action.type) {
    case TAB_CHANGE: {
      const { id } = action;

      return state.set("current", id);
    }
    case TAB_INIT: {
      const { urlParam } = action;
      let newState = state;
      const initCurrentId = state.get("byId").findKey((x) => x.get("urlParam") === urlParam && userService.can(x.get("permissions") || []));

      if (initCurrentId) {
        newState = state.set("current", initCurrentId);
      }
      else {
        newState = state.set("current", 1);
      }

      return newState;
    }
    default:
      return state;
  }
};
