/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useEffect,
  useCallback,
  type StatelessFunctionalComponent,
} from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { SlideModal, Table } from "@fas/ui-core";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { setAntifraudScenarioRules } from "../../actions/antifraudScenarioForm";
import { getAntifraudTriggerListSaga } from "../../actions/antifraudTriggers";
import { getAntifraudScenarioRules } from "../../selectors/antifraudScenarios";
import { ANTIFRAUD_TRIGGERS_TABLE } from "../../helpers/constants";
import { useActions } from "../../hooks";
import { columns } from "../../containers/AntifraudTriggerList/AntifraudTriggerList";
import type { Rule } from "../../selectors/antifraudScenarios";
import type { Props, DispatchActions } from "./AntifraudScenarioRulesModal.types";

const AntifraudScenarioRulesModal: StatelessFunctionalComponent<Props> = ({
  modalOpen,
  setModalOpen,
}: Props) => {
  const rules: Array<Rule> = useSelector(getAntifraudScenarioRules);
  const tableProps: TableProps = useTable(ANTIFRAUD_TRIGGERS_TABLE);
  const { selected, onSelectAll }: TableProps = tableProps;

  const {
    setAntifraudScenarioRules: onSetAntifraudScenarioRules,
    getAntifraudTriggerListSaga: onGetAntifraudTriggerListSaga,
  }: DispatchActions = useActions({ setAntifraudScenarioRules, getAntifraudTriggerListSaga });

  useEffect(() => {
    if (modalOpen) {
      onSelectAll(rules);
    }
  }, [modalOpen, onSelectAll]);

  const handleClose: () => void = () => {
    setModalOpen(false);
  };

  const handleSave: () => void = useCallback(() => {
    const newRules: Array<Rule> = selected.map((newRule: Rule): Rule => {
      const rule: Rule | typeof undefined = rules.find(({ ruleId }: Rule): boolean => ruleId === newRule.ruleId);
      return rule || { ...newRule, isActive: true };
    });
    onSetAntifraudScenarioRules(newRules);
    setModalOpen(false);
  }, [selected]);

  return (
    <SlideModal
      open={modalOpen}
      onClose={handleClose}
      onSave={handleSave}
      title="Rules"
      dataTestid="rules-list-modal"
      content={(
        <Box p={3}>
          <Table
            {...tableProps}
            title="Rules"
            columns={columns}
            rowSelectAvailable={(): boolean => true}
            allSelectAvailable={(): boolean => true}
            compareRows={(r1: Rule, r2: Rule): boolean => r1.ruleId === r2.ruleId}
            onLoad={onGetAntifraudTriggerListSaga}
          />
        </Box>
      )}
    />
  );
};

export default AntifraudScenarioRulesModal;
