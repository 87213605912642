// @flow
import Joi from "@hapi/joi";

class BaseTargeting {
  getJoiSchemaValidation() {
    return Joi.object({
      name: Joi.string().required(),
      operator: Joi.string().required(),
      parentId: Joi.number(),
      type: Joi.string().required(),
      offer: Joi.object().keys({
        id: Joi.string(),
        url: Joi.string(),
      }).optional(),
      value: Joi.array().when("operator", {
        is: Joi.string().valid("exists", "absent"),
        then: Joi.array().min(0),
        otherwise: Joi.array().min(1),
      }),
    });
  }
}

export default BaseTargeting;
