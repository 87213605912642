// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import deleteTemplateSaga from "../../sagas/deleteTemplateSaga";
import getTemplatesListSaga from "../../sagas/getTemplatesListSaga";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(deleteTemplateSaga),
    call(getTemplatesListSaga),
  ]);
}
