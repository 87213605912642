/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import { connect } from "react-redux";
import { AddContent, StepControl } from "@fas/ui-core";
import { Box, Container } from "@mui/material";
import { stepClick } from "@fas/ui-framework/lib/redux/actions/steps";
import Splits from "../Splits";
import SmartlinkFunnel from "../SmartlinkFunnel";
import { addSplitSaga } from "../../actions/splits";
import SmartlinkGeneralFilter from "../SmartlinkGeneralFilter";
import SmartlinkGeneralFilterModal from "../SmartlinkGeneralFilterModal";
import SmartlinkSplitsInfo from "../SmartlinkSplitsInfo/SmartlinkSplitsInfo";
import {
  getCurrentStepId,
  getIsAddSplitDisabled,
} from "../../selectors/smartlink";
import SplitSetup from "../SplitSetup/SplitSetup";

type Props = {
  currentStep: string,
  isAddSplitDisabled: boolean,
  handleAddSplit: () => void,
  handleStepClick: (stepId: string) => void,
};

const mapStateToProps = (state) => ({
  currentStep: getCurrentStepId(state),
  isAddSplitDisabled: getIsAddSplitDisabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleAddSplit: () => dispatch(addSplitSaga()),
  handleStepClick: (stepId) => dispatch(stepClick(stepId)),
});

const getNextStepId = (currentStep: string): string => (Number(currentStep) + 1).toString();
const getPreviousStepId = (currentStep: string): string => (Number(currentStep) - 1).toString();

const SplitsFunnelsStep = ({
  currentStep,
  isAddSplitDisabled,
  handleAddSplit,
  handleStepClick,
}: Props) => (
  <Container maxWidth="md">
    <Box mb={3}>
      <AddContent
        title="+ ADD SPLIT"
        onTitileClick={() => handleAddSplit()}
        onArrowClick={() => handleAddSplit()}
        isAddDisabled={isAddSplitDisabled}
        isArrowVisible={!isAddSplitDisabled}
        styles={{ color: "#3e8fff" }}
      />
    </Box>
    <Box mb={3}>
      <SmartlinkGeneralFilter />
      <SmartlinkGeneralFilterModal />
    </Box>
    <Box mb={3}>
      <SmartlinkSplitsInfo />
    </Box>
    <Box mb={3} data-testid="split-section">
      <Splits />
      <SplitSetup />
      <SmartlinkFunnel />
    </Box>
    <Box mt={3} display="flex" justifyContent="space-between">
      <StepControl
        showBackButton
        onBack={() => handleStepClick(getPreviousStepId(currentStep))}
        onNext={() => handleStepClick(getNextStepId(currentStep))}
      />
    </Box>
  </Container>
);

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(SplitsFunnelsStep);
