/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import { useDispatch } from "react-redux";
import { Table, ViewLink, SelectSearchComponent } from "@fas/ui-core";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import moment from "moment";
import environment from "environment";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import { FUNNEL_TEMPLATE_CAMPAIGNS_TABLE } from "../../helpers/constants";
import { funnelTemplateCampaignListLoadPage } from "../../actions/templateCampaigns";
import type { Campaign } from "./types/FunnelTemplateCampaigns.types";

const rowSelectAvailable: () => boolean = () => false;
const allSelectAvailable: () => boolean = () => false;

const columns: Column<Campaign>[] = [
  {
    field: "templateCreatedAt",
    label: "TEMPLATE DATE",
    sortable: true,
    searchable: true,
    render: (row: Campaign): string => moment(row.templateCreatedAt).format("YYYY-MM-DD, HH:mm:ss"),
  },
  {
    field: "campaignId",
    label: "ID",
    sortable: true,
    searchable: true,
    render: ({ campaignId }: Campaign): Element<typeof ViewLink> => (
      <ViewLink {...{
        id: campaignId,
        name: campaignId,
        viewPath: `${environment.links.viewCampaign}?id=:id`,
      }}
      />
    ),
  },
  {
    field: "campaignName",
    label: "NAME",
    sortable: true,
    searchable: true,
  },
  {
    field: "campaignCreatedAt",
    label: "CREATED",
    sortable: true,
    searchable: true,
    render: (row: Campaign): string => moment(row.campaignCreatedAt).format("YYYY-MM-DD, HH:mm:ss"),
  },
  {
    field: "createdBy",
    label: "MANAGER",
    sortable: true,
    searchable: true,
  },
  {
    field: "publishingState",
    label: "STATUS",
    sortable: true,
    searchable: true,
    SearchComponent: (searchProps: SearchComponentProps): Element<typeof SelectSearchComponent> => (
      <SelectSearchComponent
        {...searchProps}
        values={[
          { title: "All", value: "" },
          { title: "Active", value: "active" },
          { title: "Stopped", value: "stopped" },
          { title: "Draft", value: "draft" },
        ]}
        label="isActive"
      />
    ),
  },
];

const FunnelTemplateCampaigns: StatelessFunctionalComponent<{}> = () => {
  const tableProps: TableProps = useTable(FUNNEL_TEMPLATE_CAMPAIGNS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onLoad: () => mixed = () => dispatch(funnelTemplateCampaignListLoadPage());

  return (
    <Table
      {...tableProps}
      title=""
      columns={columns}
      rowSelectAvailable={rowSelectAvailable}
      allSelectAvailable={allSelectAvailable}
      onLoad={onLoad}
    />
  );
};

export default FunnelTemplateCampaigns;
