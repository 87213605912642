// @flow
import environment from "environment";
import { requestService } from "../request";
import type { FrequencyCapForJumps } from "../../containers/FrequencyCapForJumps";

export const fetchFrequencyCapForJumpsList: (
  options: {
    page: number,
    pageSize: number,
    filters: { [string]: mixed },
    sorting?: { [string]: string },
  },
) => Promise<*> = (
  options
) => {
  const { sorting, ...params }: {
    page: number,
    pageSize: number,
    filters: { [string]: mixed },
    sorting?: { [string]: string },
  } = options;
  let newSorting: {
    current: string,
    direction: string,
  };
  if (sorting && Object.keys(sorting).length) {
    const current = Object.keys(sorting)[0];
    newSorting = {
      current,
      direction: sorting[current],
    };
  }
  // $FlowFixMe
  const paramsData = {
    ...params,
    ...(newSorting && { sorting: newSorting }),
  };
  return requestService
    .post(environment.endpoints.post.getFrequencyCapForJumpsList, paramsData)
    .then(({ data }: { data: Array<FrequencyCapForJumps> }): Array<FrequencyCapForJumps> => data);
};

export const bulkChangeIsFrequencyCap: ({ ids: number[], isFrequencyCap: boolean }) => Promise<*> = (
  body
) => requestService
  .put(environment.endpoints.put.changeIsFrequencyCap, body);
