// @flow
import { Map } from "immutable";
import type { Error } from "@fas/ui-framework/lib/redux/reducers/errors";
import {
  getCurrentStepId,
} from "../../../selectors/smartlink";

export type CollectionErrors = {
  [id: string]: Error,
};

export type Errors = {
  "splits"?: CollectionErrors,
  [key: string]: Error,
};

export type JoiError = {
  code: string,
  message: string,
  path: Array<string>,
  local: {
    key: string,
    label: string,
  }
};

export default class BaseType {
  // eslint-disable-next-line no-unused-vars
  _validateCampaignInfoStep(state: Map<{}>): Errors {
    return {};
  }

  // eslint-disable-next-line no-unused-vars
  _validateServiceOffersStep(state: Map<{}>): Errors {
    return {};
  }

  // eslint-disable-next-line no-unused-vars
  _validateSplitsFunnelsStep(state: Map<{}>): Errors {
    return {};
  }

  // eslint-disable-next-line no-unused-vars
  _validateCampaignPreviewStep(state: Map<{}>): Errors {
    return {};
  }

  validateCurrentStep(state: Map<{}>): Errors {
    const currentStepId: string = getCurrentStepId(state);

    switch (currentStepId) {
      case "1": {
        return this._validateCampaignInfoStep(state);
      }
      case "2": {
        return this._validateServiceOffersStep(state);
      }
      case "3": {
        return this._validateSplitsFunnelsStep(state);
      }
      case "4": {
        return this._validateCampaignPreviewStep(state);
      }
      default:
        return {};
    }
  }
}
