/* eslint-disable import/max-dependencies */
// @flow
// FIX 92090
import React, { useState, useEffect, type StatelessFunctionalComponent } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Container } from "@mui/material";
import { useParams, useLocation, type Match } from "react-router-dom";
import GeneralFilterInfo from "../GeneralFilterInfo";
import CampaignIn from "../CampaignIn";
import CampaignOut from "../CampaignOut";
import { saveGeneralFilterSaga, getGeneralFilterSaga, cloneGeneralFilterSaga } from "../../actions/generalFilters";
import { setBackup, restoreBackup } from "../../actions/backup";
import { getGeneralFilterPriorityList } from "../../services/generalFilterApiV2";

type Props = {||};

const FilterInfoStep: StatelessFunctionalComponent<Props> = () => {
  const { id: pageId }: $PropertyType<Match, "params"> = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [priorityList, setPriorityList] = useState([]);

  useEffect(() => {
    dispatch(setBackup(["filterInfo", "targeting", "steps"]));
    const isClone = pathname.includes("clone");

    if (pageId && !isClone) {
      dispatch(getGeneralFilterSaga(pageId));
    }
    if (pageId && isClone) {
      dispatch(cloneGeneralFilterSaga(pageId));
    }

    return () => {
      dispatch(restoreBackup());
    };
  }, [pageId]);

  const priorityListData = async () => {
    const data = await getGeneralFilterPriorityList();
    setPriorityList(data);
  };

  useEffect(() => {
    priorityListData();
  }, [pageId]);

  return (
    <Container maxWidth="md">
      <Box mt={3}>
        <GeneralFilterInfo priorityList={priorityList} />
        <CampaignIn />
        <CampaignOut />
        <br />
        <Button
          variant="contained"
          color="primary"
          style={{ float: "right" }}
          onClick={() => dispatch(saveGeneralFilterSaga())}
          data-testid="next-button"
        >
          Next
        </Button>
      </Box>
    </Container>
  );
};

export default FilterInfoStep;
