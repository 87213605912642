// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StepControl as UCStepControl } from "@fas/ui-core";
import { stepClick } from "@fas/ui-framework/lib/redux/actions/steps";
import { Box } from "@mui/material";
import { getCurrentStepId } from "../../selectors/smartlink";

type Props = $ReadOnly<{|
  showBackButton: boolean,
|}>;

const StepControl: StatelessFunctionalComponent<Props> = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const currentStep: string = useSelector(getCurrentStepId);
  const changeStep: (string) => mixed = (stepId) => dispatch(stepClick(stepId));

  return (
    <Box mb={3} display="flex" justifyContent="space-between">
      <UCStepControl
        showBackButton
        onBack={(): mixed => changeStep(String(Number(currentStep) - 1))}
        onNext={(): mixed => changeStep(String(Number(currentStep) + 1))}
      />
    </Box>
  );
};

export default StepControl;
