// @flow
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import type { RuleTargetingQb } from "@fas/ui-core/lib/FlatQueryBuilder";
import {
  ADD_TARGETING,
  DELETE_TARGETING,
  CLONE_TARGETING_SAGA,
  FETCH_TARGETING_SAGA,
  FETCH_TARGETING_VALUES_SAGA,
  CHANGE_TARGETING_GROUP_OPERATOR,
  ADD_TARGETING_RULE,
  ADD_TARGETING_GROUP,
  REMOVE_TARGETING_GROUP,
  CHANGE_TARGETING_VALUE,
  CHANGE_TARGETING_OPERATOR,
  CHANGE_TARGETING_RULE,
  REMOVE_TARGETING_RULE,
} from "../../helpers/constants";

export const addTargeting = (
  { targetingSchema } : { targetingSchema: any }
) => ({
  type: ADD_TARGETING,
  targetingSchema,
});

export const deleteTargeting = ({ targetingsIds }: {targetingsIds: Array<string>}) => ({
  type: DELETE_TARGETING,
  targetingsIds,
});

export const cloneTargetingSaga = ({ funnelId } : { funnelId: string}) => ({
  type: CLONE_TARGETING_SAGA,
  funnelId,
});

export const fetchTargetingsSaga = (funnelId: string) => ({
  type: FETCH_TARGETING_SAGA,
  funnelId,
});

export const fetchTargetingValuesSaga = (ruleName: string) => ({
  type: FETCH_TARGETING_VALUES_SAGA,
  ruleName,
});

export type ChangeTargetingGroupOperator = {
  type: typeof CHANGE_TARGETING_GROUP_OPERATOR,
  id: string,
  val: string,
};

export const changeTargetingGroupOperator = (id: string, val: string): ChangeTargetingGroupOperator => ({
  type: CHANGE_TARGETING_GROUP_OPERATOR,
  id,
  val,
});

export type AddRuleAction = {
  type: typeof ADD_TARGETING_RULE,
  groupId: string,
};

export const addRuleAction = (groupId: string): AddRuleAction => ({
  type: ADD_TARGETING_RULE,
  groupId,
});

export type AddGroupAction = {
  type: typeof ADD_TARGETING_GROUP,
  groupId: string,
};

export const addGroupAction = (groupId: string): AddGroupAction => ({
  type: ADD_TARGETING_GROUP,
  groupId,
});

export type RemoveGroupAction = {
  type: typeof REMOVE_TARGETING_GROUP,
  groupId: string,
};

export const removeGroupAction = (groupId: string): RemoveGroupAction => ({
  type: REMOVE_TARGETING_GROUP,
  groupId,
});

export type ChangeValueAction = {
  type: typeof CHANGE_TARGETING_VALUE,
  ruleId: string,
  values: Array<Option>,
};

export const changeValueAction = (
  ruleId: string,
  values: Array<Option>
): ChangeValueAction => ({
  type: CHANGE_TARGETING_VALUE,
  ruleId,
  values,
});

export type ChangeOperatorAction = {
  type: typeof CHANGE_TARGETING_OPERATOR,
  ruleId: string,
  operator: {
    name: string,
    value: string,
  },
};

export const changeOperatorAction = (ruleId: string, operator: {
  name: string,
  value: string,
}): ChangeOperatorAction => ({
  type: CHANGE_TARGETING_OPERATOR,
  ruleId,
  operator,
});

export type ChangeRuleAction = {
  type: typeof CHANGE_TARGETING_RULE,
  ruleId: string,
  rule: RuleTargetingQb | null,
};

export const changeRuleAction = (ruleId: string, rule: RuleTargetingQb | null): ChangeRuleAction => ({
  type: CHANGE_TARGETING_RULE,
  ruleId,
  rule,
});

export type RemoveRule = {
  type: typeof REMOVE_TARGETING_RULE,
  targetingId: string,
  groupId: string,
}

export const removeRule: (string, string) => RemoveRule = (targetingId, groupId) => ({
  type: REMOVE_TARGETING_RULE,
  targetingId,
  groupId,
});
