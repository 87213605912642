// @flow
/* eslint-disable import/max-dependencies */
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import environment from "environment";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { userService } from "../../services/user";
import ExternalRedirect from "../../components/App/ExternalRedirect";
import {
  SAVE_CAMPAIGN_SAGA,
} from "../../helpers/constants";
import CampaignApi from "../../services/smartlinkApi";
import {
  getCampaignData,
  getSplitsById,
} from "../../selectors/smartlink";
import { startSaveCampaign } from "../../actions/campaign";
import { showConfirmation } from "../confirmSaga/confirmSaga";

export function* saveCampaignSaga(): Saga<void> {
  if (userService.can(["unitedReborn.field.funnelTemplate"])) { // todo need move to smartlink campaign
    const splits = yield select(getSplitsById);
    const splitsWithoutTemplateNames = splits
      .filter((split) => split.get("isActive") && !split.get("funnelTemplate"))
      .map((split) => split.get("name"))
      .join(", ");

    if (splitsWithoutTemplateNames) {
      const message = `You dont have funnel template on split '${splitsWithoutTemplateNames}'. Are you sure you want to save this campaign?`;
      const confirmed = yield showConfirmation({ message });

      if (!confirmed) {
        return;
      }
    }
  }

  try {
    yield put(setLoading("loadingSaveCampaign", true));
    yield put(startSaveCampaign());

    const campaign = yield select(getCampaignData);
    yield call(CampaignApi.saveCampaign, campaign);

    yield put(addNotification({ message: "Campaign saved successfully", severity: "success" }));

    ExternalRedirect({ to: `${environment.links.viewCampaign}?id=${campaign.campaignInfo.id}` });
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
  }
  finally {
    yield put(setLoading("loadingSaveCampaign", false));
  }
}

export default function* watchSaveCampaign(): Saga<void> {
  yield takeEvery(SAVE_CAMPAIGN_SAGA, saveCampaignSaga);
}
