// @flow
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";
import { getDropdown } from "@fas/ui-framework/lib/redux/selectors/dropdowns";
import {
  GET_ANTIFRAUD_DICTIONARY_ACTIONS,
  GET_ANTIFRAUD_DICTIONARY_SAGA,
  SAVE_ANTIFRAUD_DICTIONARY_SAGA,
} from "../../helpers/constants";
import {
  getLoading,
  getErrors,
  getAntifraudDictionary,
} from "../../selectors/antifraudDictionaries";
import {
  setAntifraudDictionaryFormField,
  getAntifraudDictionarySaga,
  saveAntifraudDictionarySaga,
  getAntifraudDictionaryActions,
} from "../../actions/antifraudDictionaryForm";
import type {
  OwnProps,
  StateToProps,
  DispatchToProps,
  Props,
} from "./types/AntifraudDictionaryForm.types";
import type { State, Actions } from "../../pages/AntifraudDictionaryForm";
import AntifraudDictionaryForm from "../../components/AntifraudDictionaryForm";

const mapStateToProps: (state: State) => StateToProps = (state) => ({
  getAntifraudDictionaryLoading: getLoading(state, GET_ANTIFRAUD_DICTIONARY_SAGA),
  saveAntifraudDictionaryLoading: getLoading(state, SAVE_ANTIFRAUD_DICTIONARY_SAGA),
  getAntifraudDictionaryActionsLoading: getLoading(state, GET_ANTIFRAUD_DICTIONARY_ACTIONS),
  formData: getAntifraudDictionary(state),
  errors: getErrors(state),
  actionsList: getDropdown(state, "actions"),
});

const mapDispatchToProps: (Dispatch<Actions>) => DispatchToProps = (dispatch) => bindActionCreators({
  onSetAntifraudDictionaryFormField: setAntifraudDictionaryFormField,
  onGetAntifraudDictionarySaga: getAntifraudDictionarySaga,
  onSaveAntifraudDictionarySaga: saveAntifraudDictionarySaga,
  onGetAntifraudDictionaryActions: getAntifraudDictionaryActions,
}, dispatch);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(AntifraudDictionaryForm);
