// @flow
import {
  takeEvery,
  call,
  put,
  all,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { GET_ANTIFRAUD_CONDITIONS_LIST_SAGA } from "../../helpers/constants";
import { setConditionLists } from "../../actions/antifraudTriggerForm";
import { getConditionRulesList, getConditionValuesList } from "../../services/antifraudTriggersApi";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("isGetAntifraudConditionsListLoading", true));
    const [{ data: triggerRules }, { data: triggerValues }] = yield all([
      call(getConditionRulesList),
      call(getConditionValuesList),
    ]);
    yield put(setConditionLists({ triggerRules, triggerValues }));
  }
  catch (error) {
    yield put(addNotification({ message: error.message, severity: "error" }));
  }
  finally {
    yield put(setLoading("isGetAntifraudConditionsListLoading", false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_ANTIFRAUD_CONDITIONS_LIST_SAGA, makeFetch);
}
