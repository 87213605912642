// @flow
import {
  takeEvery,
  call,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import TemplateApi from "../../services/templateApi";
import {
  FETCH_FUNNEL_TEMPLATE_SAGA,
} from "../../helpers/constants";
import { setFunnelTemplate } from "../../actions/dictionaries";
import { getFunnelTemplateByTemplateId } from "../../selectors/smartlink";
import type { FetchFunnelTemplateAction } from "../../actions/splits";

export function* fetchFunnelTemplateNamesSaga(action: FetchFunnelTemplateAction): Saga<void> {
  try {
    const { templateId } = action;

    const isTemplateExist = yield select(getFunnelTemplateByTemplateId, templateId);
    if (!isTemplateExist) {
      const { data: response } = yield call(TemplateApi.getTemplateInfoById, templateId);
      const { template } = response;
      yield put(setFunnelTemplate(template));
    }
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
  }
}

export default function* watchFetchFunnelTemplateNamesSaga(): Saga<void> {
  yield takeEvery(FETCH_FUNNEL_TEMPLATE_SAGA, fetchFunnelTemplateNamesSaga);
}
