/* eslint-disable import/max-dependencies */
// @flow
/* eslint-disable complexity */

import Base from "./Base";
import Age from "./Age";
import AgeByUID from "./AgeByUID";
import RegDate from "./RegDate";
import GlobalFrequency from "./GlobalFrequency";
import TdsSO from "./TdsSO";
import InvalidEmail from "./InvalidEmail";
import LocationByIpMismatch from "./LocationByIpMismatch";
import LocationByGeoMismatch from "./LocationByGeoMismatch";
import Chargeback from "./Chargeback";
import ChargebackByUID from "./ChargebackByUID";
import FilteringWithoutValue from "./FilteringWithoutValue";
import AmountOfReg from "./AmountOfReg";
import EntriesCount from "./EntriesCount";
import TdsFCJ from "./TdsFCJ";

const TargetingFactory = (rule: { name: string, operator: string, value: Array<any> }, isFiltering:boolean = false) => {
  if (isFiltering) {
    if ([
      "pidxemidexists",
      "isnewdci",
      "refund",
      "openZoneEmids",
      "deleteduser",
      "isemailconfirm",
      "pidexists",
      "pidexistsbyuid",
      "chargeback",
      "chargebackbyuid",
      "invalidemail",
      "isoldemail",
      "isnewemail",
    ].includes(rule.name)) {
      return new FilteringWithoutValue();
    }
  }

  if (rule.name === "age") {
    return new Age();
  }

  if (rule.name === "agebyuid") {
    return new AgeByUID();
  }

  if (rule.name === "regdate") {
    return new RegDate();
  }

  if (rule.name === "globalFrequency" || rule.name === "campaignFrequency") {
    return new GlobalFrequency();
  }
  if (rule.name === "tds_bo" || rule.name === "tds_so") {
    return new TdsSO();
  }

  if (rule.name === "tds_fcj") {
    return new TdsFCJ();
  }

  if (rule.name === "invalidemail"
    || rule.name === "baddevice"
    || rule.name === "hidden_proxy") {
    return new InvalidEmail();
  }

  if (rule.name === "chargeback") {
    return new Chargeback();
  }

  if (rule.name === "chargebackbyuid") {
    return new ChargebackByUID();
  }

  if (rule.name === "locationByGeoMismatch") {
    return new LocationByGeoMismatch();
  }

  if (rule.name === "locationByIpMismatch") {
    return new LocationByIpMismatch();
  }

  if (rule.name === "regamount") {
    return new AmountOfReg();
  }

  if (rule.name === "entriesCount") {
    return new EntriesCount();
  }

  return new Base();
};

export default TargetingFactory;
