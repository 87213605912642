// @flow
import { List, Map, fromJS } from "immutable";
import type { State } from "../campaignInfo/reducer";
import {
  ADD_MANUAL_TAG,
  REMOVE_MANUAL_TAG,
} from "../../helpers/constants";

export type TagsState = {
  manualTags: List<string>,
};

export const initialState: Map<TagsState> = Map({
  manualTags: List([]),
});

type InitState = {
  tags: TagsState,
};

export function initTagsState(data: InitState) {
  const { tags } = data;
  return tags ? fromJS(tags) : initialState;
}

export default (state: Map<State> = initialState, action: any) => {
  switch (action.type) {
    case ADD_MANUAL_TAG: {
      const { tag } = action;
      const value = tag.value.trim();
      if (!RegExp(/^[0-9A-Za-z,_.]{2,15}$/).test(value)) {
        return state;
      }
      return state.withMutations((newState) => {
        newState.updateIn(["manualTags"], (tags) => tags.push(value.toUpperCase()));
      });
    }
    case REMOVE_MANUAL_TAG: {
      const { tag } = action;
      return state.withMutations((newState) => {
        newState.deleteIn(["manualTags",
          newState.get("manualTags").findIndex((el) => el === tag),
        ]);
      });
    }
    default:
      return state;
  }
};
