// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetAntifraudDictionaries from "../../sagas/getAntifraudDictionaries";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetAntifraudDictionaries),
  ]);
}
