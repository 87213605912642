// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchFetchTemplateReportListSaga from "../../sagas/fetchTemplateReportListSaga";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchFetchTemplateReportListSaga),
  ]);
}
