// @flow
import {
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import type { StepClickAction } from "@fas/ui-framework/lib/redux/actions/steps";
import { STEP_CLICK } from "@fas/ui-framework/lib/redux/constants";
import {
  stepChange,
  setStepError,
} from "@fas/ui-framework/lib/redux/actions/steps";
import {
  getCurrentStepId,
  getCampaignType,
  getState,
} from "../../selectors/smartlink";
import validator from "../../services/campaignValidation";

export function* stepsClick(action: StepClickAction): Saga<void> {
  const currentStepId = yield select(getCurrentStepId);
  if (currentStepId === action.id) {
    return;
  }

  const type = yield select(getCampaignType);
  const fullState = yield select(getState);

  const Validator = validator(type);
  const validationErrors = new Validator().validateCurrentStep(fullState, action);

  yield put(setErrors(validationErrors));

  if (!Object.keys(validationErrors).length) {
    yield put(stepChange(action.id));
    return;
  }

  yield put(setStepError());
}

export default function* watchStepsClick(): Saga<void> {
  yield takeEvery(STEP_CLICK, stepsClick);
}
