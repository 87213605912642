// @flow
import type { Saga } from "redux-saga";
import {
  call, put, takeEvery, select,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import CampaignApi from "../../services/smartlinkApi";
import { setTargetingValue } from "../../actions/dictionaries";
import { FETCH_TARGETING_VALUES_SAGA } from "../../helpers/constants";
import { isTargetingRuleValuesAlreadyFetched } from "../../selectors/smartlink";

export function* fetchTargetingValuesSaga(action: *): Saga<void> {
  const { ruleName } = action;

  const isRuleValuesFetched = yield select(isTargetingRuleValuesAlreadyFetched, ruleName);

  if (isRuleValuesFetched) {
    return;
  }
  try {
    yield put(setLoading(`loadingTargetingValues-${ruleName}`, true));

    const { data: response } = yield call(CampaignApi.getValuesForTargeting, ruleName);

    yield put(setTargetingValue({ data: response.data[0].values, key: ruleName }));
    yield put(setLoading(`loadingTargetingValues-${ruleName}`, false));
  }
  catch (err) {
    yield put(setLoading(`loadingTargetingValues-${ruleName}`, false));
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
  }
}

export default function* watchFetchTargetingValuesSaga(): Saga<void> {
  yield takeEvery(FETCH_TARGETING_VALUES_SAGA, fetchTargetingValuesSaga);
}
