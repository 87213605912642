import { put, select, takeEvery } from "redux-saga/effects";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import { CLONE_FILTERING_SAGA } from "../../helpers/constants";
import { getFilteringById, getFilteringsAllIds } from "../../selectors/smartlink";
import { addFiltering } from "../../actions/filterings";

export function* cloneFilteringSaga(action) {
  const { funnelId, filteringId } = action;

  const allIds = yield select(getFilteringsAllIds);
  const nextId = getNextId(allIds);

  const filtering = yield select(getFilteringById, filteringId);

  yield put(addFiltering(funnelId, nextId, filtering.toJS()));
}

export default function* watchCloneFiltering() {
  yield takeEvery(CLONE_FILTERING_SAGA, cloneFilteringSaga);
}
