// @flow
import React from "react";
import { Provider } from "react-redux";
import { CircularProgress, Grid, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import DocumentTitle from "react-document-title";
import Notification from "../../components/Notification";
import configureStore from "./store";
import TemplateApi from "../../services/templateApi";
import FunnelTemplateInfo from "../../containers/FunnelTemplateInfo";

type Props = {
  navigate: Object,
  params: Object,
  classes: Object
};

type State = {
  isLoading: boolean
};

class FunnelTemplate extends React.Component<Props, State> {
  funnelTemplate: Object;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
    };
    this.funnelTemplate = {};
  }

  componentDidMount() {
    const { navigate, params } = this.props;

    if (!params.id) {
      this.setState({ isLoading: false });
      navigate("/error");
    }

    TemplateApi.getTemplateViewById(params.id)
      .then(({ data: response }) => {
        this.funnelTemplate = response.data;
        this.setState({ isLoading: false });
      })
      .catch(() => navigate("/error"));
  }

  render() {
    const { isLoading } = this.state;
    const { classes, params } = this.props;
    const initTabName = params.tab || "";

    this.funnelTemplate.modals = {
      funnel: {
        isOpen: false,
        currentFunnel: null,
        isContentDisabled: true,
      },
    };

    return (
      <DocumentTitle title="Template Overview">
        <>
          {isLoading && (
            <Grid container justify="center">
              <Box mt={40}>
                <CircularProgress />
              </Box>
            </Grid>
          )}
          {!isLoading && (
            <Provider store={configureStore(this.funnelTemplate)}>
              <Grid container spacing={0} className={classes.mainBackground}>
                <FunnelTemplateInfo
                  initTabName={initTabName}
                />
              </Grid>
              <Notification />
            </Provider>
          )}
        </>
      </DocumentTitle>
    );
  }
}

export default withStyles((theme) => ({
  mainBackground: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}))(FunnelTemplate);
