// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import {
  TextField,
  Grid,
  Button,
  ListItem,
} from "@mui/material";
import { Multiselect } from "@fas/ui-core";
import DeleteIcon from "@mui/icons-material/Delete";
import type { CampaignType, ErrorOption } from "../../reducers/generalFilterInfo";
import { checkErrorList, getErrorMessageList } from "../../helpers/generalFilter";

type Props = {
  campaignsOut: CampaignType[],
  errors: ErrorOption,
  onDeleteCampaignOut: (index: number) => mixed,
  onSetWeight: (index: number, value: string) => mixed,
  onChangeCampaignOut: (index: number, value: string) => mixed,
  onClearCampaignOut: (index: number) => mixed,
};

const CampaignsOutList: StatelessFunctionalComponent<Props> = ({
  campaignsOut,
  errors,
  onDeleteCampaignOut,
  onSetWeight,
  onChangeCampaignOut,
  onClearCampaignOut,
}) => campaignsOut.map((item: CampaignType, index: number): Element<ListItem> => (
  <ListItem key={`tdsCampaignOutId-${item.id}`} style={{ paddingLeft: 0, paddingRight: 0 }}>
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="stretch"
      data-testid="campaign-line"
      spacing={1}
    >
      <Grid item xs={8}>
        <Multiselect
          type="enterManualMultiple"
          // $FlowFixMe
          options={item.id.length ? [item] : []}
          // $FlowFixMe
          selectedOptions={item.id.length ? [item] : []}
          error={{
            isError: checkErrorList("tdsCampaignsOut", errors, index, "id"),
            helperText: getErrorMessageList("tdsCampaignsOut", errors, index, "id"),
          }}
          // $FlowFixMe
          onAddValue={(e): mixed => onChangeCampaignOut(index, e.value)}
          // $FlowFixMe
          onRemoveValue={(e): mixed => onClearCampaignOut(index, e.value)}
          inputProps={{ "data-testid": "campaign-out-multiselect" }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          value={String(item.weight)}
          error={checkErrorList("tdsCampaignsOut", errors, index, "weight")}
          helperText={getErrorMessageList("tdsCampaignsOut", errors, index, "weight")}
          onChange={(e): mixed => onSetWeight(index, e.target.value)}
          inputProps={
            { "data-testid": "weight-input" }
          }
        />
      </Grid>
      <Grid item xs={1}>
        {
          campaignsOut.length <= 1 ? "" : (
            <Button
              color="primary"
              onClick={(): mixed => onDeleteCampaignOut(index)}
              data-testid="delete-button"
            >
              <DeleteIcon />
            </Button>
          )
        }
      </Grid>
    </Grid>
  </ListItem>
));

export default CampaignsOutList;
