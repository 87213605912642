// @flow
import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";

type Props = {
  title: string,
  children: React.Node,
  spacing?: number,
  titleColumnWidth?: number,
  inputColumnWidth?: number,
  multiLayout?: boolean,
  multiLayoutWidth?: number
};

const Row = ({
  title,
  children,
  spacing,
  titleColumnWidth,
  inputColumnWidth,
  multiLayout,
  multiLayoutWidth,
}: Props) => (
  <Box mb={2} width={1}>
    <Grid item container spacing={spacing} direction="row" alignItems="center" justifyContent="flex-start">
      <Grid item xs={titleColumnWidth}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item container spacing={2} xs={inputColumnWidth}>
        {React.Children.map(children, (child) => (
          <Grid item xs={multiLayout ? multiLayoutWidth : false}>
            {child}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Box>
);

Row.defaultProps = {
  spacing: 2,
  titleColumnWidth: 3,
  inputColumnWidth: 9,
  multiLayout: false,
  multiLayoutWidth: 6,
};

export default Row;
