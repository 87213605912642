// @flow
import {
  FREQUENCY_CAP_FOR_JUMPS_TABLE,
  CHANGE_IS_FREQUENCY_CAP,
} from "../../helpers/constants";
import type { FrequencyCapForJumps } from "../../containers/FrequencyCapForJumps";

export type GetFrequencyCapForListSaga = {
  type: typeof FREQUENCY_CAP_FOR_JUMPS_TABLE,
}

export type ChangeIsFrequencyCap = {
  type: typeof CHANGE_IS_FREQUENCY_CAP,
  payload: {
    items: FrequencyCapForJumps[],
    isFrequencyCap: boolean,
  }
}

export type Actions = GetFrequencyCapForListSaga | ChangeIsFrequencyCap;

export const getFrequencyCapForListSaga: () => GetFrequencyCapForListSaga = () => ({
  type: FREQUENCY_CAP_FOR_JUMPS_TABLE,
});

export const changeIsFrequencyCap: ({
  items: FrequencyCapForJumps[], isFrequencyCap: boolean,
}) => ChangeIsFrequencyCap = (payload) => ({
  type: CHANGE_IS_FREQUENCY_CAP,
  payload,
});
