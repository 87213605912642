// @flow
import {
  GET_ANTIFRAUD_SCENARIOS_LIST_SAGA,
  CHANGE_ANTIFRAUD_SCENARIO_ACTIVE_SAGA,
} from "../../helpers/constants";

export type GetAntifraudScenariosListSaga = {
  type: typeof GET_ANTIFRAUD_SCENARIOS_LIST_SAGA,
}

export type ChangeAntifraudScenarioActiveSaga = {
  type: typeof CHANGE_ANTIFRAUD_SCENARIO_ACTIVE_SAGA,
}

export type Actions = GetAntifraudScenariosListSaga
  | ChangeAntifraudScenarioActiveSaga;

export const getAntifraudScenariosListSaga: () => GetAntifraudScenariosListSaga = () => ({
  type: GET_ANTIFRAUD_SCENARIOS_LIST_SAGA,
});

export const changeAntifraudScenarioActiveSaga: () => ChangeAntifraudScenarioActiveSaga = () => ({
  type: CHANGE_ANTIFRAUD_SCENARIO_ACTIVE_SAGA,
});
