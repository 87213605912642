// @flow
import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Grid,
} from "@mui/material";
import { ComboBox, Loader } from "@fas/ui-core";
import CampaignApi from "../../services/smartlinkApi";

type Props = {
  domain: string,
  protocol: string,
  error: boolean,
  onChangeDomain: (domain: string, protocol: string) => any
}

const emptyDomain = {
  domain: "",
  hasCertificate: false,
};

const DomainSelector = ({
  domain,
  protocol,
  error,
  onChangeDomain,
}: Props) => {
  const [domainList, setDomainList] = useState([]);
  const [loadingDomains, setLoadingDomains] = useState(true);
  const fetchData = async () => {
    try {
      const { data: response } = await CampaignApi.getDomainsList();
      if (response && response.data) {
        setDomainList(response.data);

        if (domain && !response.data.map((item) => item.domain).includes(domain)) {
          handleChangeDomain(emptyDomain);
        }
      }
      setLoadingDomains(false);
    }
    catch (err) {
      setLoadingDomains(false);
    }
  };

  const handleChangeDomain = (selectedDomainItem: any) => {
    const selectedProtocol = selectedDomainItem.hasCertificate ? "https" : "http";

    onChangeDomain(selectedDomainItem.domain, selectedProtocol);
  };

  useEffect(() => {
    if (domainList.length === 0) {
      fetchData();
    }
  });
  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <TextField
          placeholder="Protocol"
          variant="outlined"
          size="small"
          value={protocol}
          data-testid="id-protocol-text"
          disabled
        />
      </Grid>
      <Grid item xs={10}>
        <FormControl error={error} fullWidth variant="outlined">
          <Loader loading={loadingDomains}>
            <ComboBox
              id="mui-autocomplete-domain"
              label="Domain"
              options={domainList}
              value={{ domain }}
              defaultValue={{ domain }}
              isOptionEqualToValue={(option, value) => option.domain === value.domain}
              getOptionLabel={(option) => option.domain || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  data-testid="mui-autocomplete-domain-text"
                  placeholder="Select domain"
                  variant="outlined"
                  error={error}
                  fullWidth
                />
              )}
              onChange={(e, value) => handleChangeDomain(value)}
              disableClearable
              data-testid="id-domain-comboBox"
            />
          </Loader>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default React.memo<Props>(DomainSelector);
