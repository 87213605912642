// @flow
import { put, select, takeEvery } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getActionOptionsParamsByFunnelId, getFilteringsByFunnelId } from "../../selectors/smartlink";
import { deleteFiltering } from "../../actions/filterings";
import { addActionOptionsParams } from "../../actions/actionOptionsParams";
import { DELETE_FILTERING_SAGA } from "../../helpers/constants";
import type { DeleteFilteringSagaType } from "../../actions/filterings/actions";

export function* deleteFilteringRuleSaga(action: DeleteFilteringSagaType): Saga<void> {
  const { filteringId, funnelId } = action;
  const actionOptionsParamsId = yield select(getActionOptionsParamsByFunnelId, funnelId);
  const filteringIds = yield select(getFilteringsByFunnelId, funnelId);

  if (filteringIds.size === 1) {
    yield put(addActionOptionsParams(funnelId, actionOptionsParamsId.get(0)));
  }
  yield put(deleteFiltering(funnelId, filteringId));
}

export default function* watchDeleteFilteringRule(): Saga<void> {
  yield takeEvery(DELETE_FILTERING_SAGA, deleteFilteringRuleSaga);
}
