// @flow
/* eslint-disable import/max-dependencies */
import React, { useCallback, useEffect, useState } from "react";
import type { Element } from "react";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import { List, Map, isImmutable } from "immutable";
import {
  AddData,
  SetupCard,
  FilteringRuleComponent,
  FilteringRuleContainer,
  GroupContainer,
} from "@fas/ui-core";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import type { Error } from "@fas/ui-framework/lib/redux/reducers/errors";
import type {
  RuleFilteringQb,
  SelectedFilteringRule,
  ErrorsFiltering as Errors,
} from "@fas/ui-core/lib/FlatQueryBuilder";
import type { MultiselectTypes, Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import UseOneLink from "../../components/UseOneLink";
import UserDataSource from "../../components/UserDataSource";
import { setEmailBase64, setEmailPlaceholder, setUserDataSource } from "../../actions/actionOptionsParams";
import {
  getActionOptionsParamsByFunnelId,
  getActionOptionsParamsById,
  getErrors,
  getFilteringErrorById,
  getFilterings,
  getFilteringsByFunnelId,
  getTargetingValues,
  getTargetingValue,
  getActionsOptionsParamsIdByFunnelId,
  getTargetingRulesList,
  getFilteringByIdV2,
  getFilteringRulesListV2,
  getLoadingStatusForValues,
  getIsTargetingListLoading,
} from "../../selectors/smartlink";
import {
  addFiltering,
  changeFilteringRule,
  changeFilteringRuleOneLinkOffer,
  changeFilteringRuleValueV2,
  changeFilteringRuleOfferV2,
  deleteFilteringSaga,
} from "../../actions/filterings";
import { fetchTargetingValuesSaga } from "../../actions/targetings";
import type {
  Offer,
  OfferItem,
} from "../../components/FilteringList/types/FilteringList.types";
import { defaultFiltering } from "../../reducers/filterings/reducer";
import { parseOffer } from "../../components/FilteringList/FilteringItem";

import type { Filtering } from "../../reducers/filterings";
import CampaignApi from "../../services/smartlinkApi";

const dataSources = [
  {
    value: "getAttribute",
    label: "GetAttribute",
  },
];

type OwnProps = $ReadOnly<{|
  funnelId: string,
|}>;

type StateProps = $ReadOnly<{|
  actionOptionsParamsId: string,
  errors: Map<*>,
  rulesList: List<RuleFilteringQb>,

  rulesValues: List<{ value: string, label: string, subValues: Array<string>}>,
  actionOptionsParams: Map<string>,
  filterings: Map<*>,
  filteringsIds: Map<string>,
  getFilteringRuleById: () => SelectedFilteringRule,
  getTargetingList: () => Array<RuleFilteringQb>,

  getValues: () => { multiSelectType: MultiselectTypes, list: Array<Option> },
  getLoadingValues: () => boolean,
  isRuleListLoading: boolean,
  getError: () => Errors,
|}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,

  onDataSourceChange: (actionOptionsParamsId: string, userDataSource: string) => void,
  onEmailPlaceholderChange: (actionOptionsParamsId: string, emailPlaceholder: string) => void,
  onBase64Change: (actionOptionsParamsId: string, emailBase64: boolean) => void,
  onAddFiltering: (funnelId: string, filteringId: string, filtering?: Filtering) => void,
  onDeleteFiltering: (funnelId: string, filteringId: string) => void,
  onChangeFilteringRule: (filteringId: string, name: string) => void,
  onChangeFilteringRuleValue: (filteringId: string, value: Array<Option>) => void,
  onChangeFilteringRuleOneLinkOffer: (filteringIds: Array<string>, offer: Offer) => void,
  handleFetchTargetingValuesSaga: (ruleName: string) => void,
  onChangeFilteringRuleOfferV2: (string, *) => void,
|}>;

const mapStateToProps = (state, { funnelId }: OwnProps): StateProps => ({
  actionOptionsParamsId: getActionsOptionsParamsIdByFunnelId(state, funnelId),
  errors: getErrors(state),
  rulesList: getTargetingRulesList(state),
  rulesValues: getTargetingValues(state),
  actionOptionsParams: getActionOptionsParamsById(state, getActionOptionsParamsByFunnelId(state, funnelId).get(0)),
  filterings: getFilterings(state),
  filteringsIds: getFilteringsByFunnelId(state, funnelId),

  getFilteringRuleById: getFilteringByIdV2,
  getTargetingList: () => getFilteringRulesListV2(state, funnelId),
  getValues: getTargetingValue,
  getLoadingValues: getLoadingStatusForValues,
  isRuleListLoading: getIsTargetingListLoading(state),
  getError: getFilteringErrorById,
});

const mapDispatchToProps = (dispatch) => ({
  onDataSourceChange:
    (actionOptionsParamsId, userDataSource) => dispatch(setUserDataSource(actionOptionsParamsId, userDataSource)),
  onEmailPlaceholderChange:
    (actionOptionsParamsId, emailPlaceholder) => dispatch(setEmailPlaceholder(actionOptionsParamsId, emailPlaceholder)),
  onBase64Change: (actionOptionsParamsId, emailBase64) => dispatch(setEmailBase64(actionOptionsParamsId, emailBase64)),
  onAddFiltering: (funnelId, filteringId, filtering) => dispatch(addFiltering(funnelId, filteringId, filtering)),
  onDeleteFiltering: (funnelId, filteringId) => dispatch(deleteFilteringSaga(funnelId, filteringId)),
  onChangeFilteringRule: (filteringId, name) => dispatch(changeFilteringRule(filteringId, name)),
  onChangeFilteringRuleValue: (filteringId, value) => dispatch(changeFilteringRuleValueV2(filteringId, value)),
  onChangeFilteringRuleOneLinkOffer:
    (filteringIds, offer) => dispatch(changeFilteringRuleOneLinkOffer(filteringIds, offer)),
  handleFetchTargetingValuesSaga: (ruleName) => dispatch(fetchTargetingValuesSaga(ruleName)),
  onChangeFilteringRuleOfferV2: (id, val) => dispatch(changeFilteringRuleOfferV2(id, val)),
});

const hasError = (key: string, errors: Map<string, Error>): boolean => errors.has(key);
const Filterings: (Props) => Element<Box> = ({
  actionOptionsParamsId,
  errors,
  filteringsIds,
  filterings,
  onDataSourceChange,
  onEmailPlaceholderChange,
  onBase64Change,
  funnelId,
  rulesList,
  actionOptionsParams,
  onAddFiltering,
  onDeleteFiltering,
  onChangeFilteringRule,
  rulesValues,
  handleFetchTargetingValuesSaga,
  onChangeFilteringRuleValue,
  onChangeFilteringRuleOneLinkOffer,
  getFilteringRuleById,
  getValues,
  getTargetingList,
  getLoadingValues,
  onChangeFilteringRuleOfferV2,
  isRuleListLoading,
  getError,
}: Props): Element<Box> => {
  const [useOneLink, setUseOneLink] = useState(false);
  const [useOneLinkOffer, setUseOneLinkOffer] = useState(defaultFiltering.offer);
  const { emailBase64, emailPlaceholder, userDataSource } = isImmutable(actionOptionsParams)
    ? actionOptionsParams.toJS()
    : {};
  const handleAddFiltering = () => {
    const filtering: Filtering = useOneLink ? { ...defaultFiltering, offer: useOneLinkOffer } : defaultFiltering;
    const filteringId = getNextId(filterings.get("allIds"));

    onAddFiltering(funnelId, filteringId, filtering);
  };

  useEffect(() => {
    if (filteringsIds.size !== 0) {
      const offer = filterings.getIn(["byId", filteringsIds.get(0), "offer"]);
      const isUseOneLink = filteringsIds.every((k) => {
        const o = filterings.getIn(["byId", k, "offer"]);
        return o.get("id") === offer.get("id") && o.get("url") === offer.get("url");
      });

      if (isUseOneLink) {
        setUseOneLink(true);
        setUseOneLinkOffer(offer.toJS());
      }
    }
  }, [filterings, filteringsIds]);

  const filteringsCollection = filterings.get("byId").toJS();
  const filteringsList = Object
    .keys(filteringsCollection)
    .filter((id) => filteringsIds.includes(id))
    .map((id) => filteringsCollection[id].name);
  const isAllValuesFetched = !filteringsList.filter((name) => name !== "").some((rule) => !rulesValues.has(rule));
  const showSpinner = rulesList.toJS().length === 0 || !isAllValuesFetched;
  const handleChangeRule = useCallback((ruleId, rule) => {
    if (!rule) {
      onChangeFilteringRule(ruleId, "");
      return;
    }
    onChangeFilteringRule(ruleId, rule.name.value);
    handleFetchTargetingValuesSaga(rule.name.value);
  }, [handleFetchTargetingValuesSaga, onChangeFilteringRule]);
  const handleGetCampaigns = useCallback(
    (val) => CampaignApi.getBasicCampaignsUrlById(val).then((response) => response.data.data),
    []
  );
  const handleChangeValue = useCallback((id, val) => onChangeFilteringRuleValue(id, val), [onChangeFilteringRuleValue]);
  const handleRemoveRule = useCallback(
    (filteringId) => onDeleteFiltering(funnelId, filteringId),
    [funnelId, onDeleteFiltering]
  );

  useEffect(() => {
    if (filteringsIds.size === 0) {
      setUseOneLinkOffer({ id: "", url: "" });
    }
  }, [filteringsIds]);
  return (
    <Box py={1} data-testid="funnel-filtering">
      {filteringsIds.size === 0 && (
        <AddData
          linkText="+ add new filtering"
          title="You have no filtering yet(optional)."
          error={false}
          onClick={handleAddFiltering}
        />
      )}
      {filteringsIds.size !== 0 && (

        <SetupCard
          title="Filtering by Email"
          showMainBtn={!showSpinner}
          mainBtnText="add filtering"
          onMainBtnClick={handleAddFiltering}
          onSecondaryBtnClick={() => {}}
          showSecondaryBtn={false}
          secondaryBtnText=""
        >
          <>
            <Box mb={2} width="100%" data-testid="action-options-group">
              <UserDataSource
                dataSources={dataSources}
                selectedDataSource={userDataSource}
                emailPlaceholder={emailPlaceholder}
                emailBase64={emailBase64}
                onDataSourceChange={(e) => onDataSourceChange(actionOptionsParamsId, e.target.value)}
                onEmailPlaceholderChange={(e) => onEmailPlaceholderChange(actionOptionsParamsId, e.target.value)}
                onBase64Change={(e) => onBase64Change(actionOptionsParamsId, e.target.checked)}
                error={hasError("actionOptionsParams", errors)}
              />
            </Box>
            <Box width="100%" data-testid="rules-group">
              <GroupContainer
                id="0"
                RuleComponent={FilteringRuleComponent}
                RuleContainer={FilteringRuleContainer}
                isRuleListLoading={isRuleListLoading}
                groupProps={{
                  getGroupByIdSelector: () => ({ groupOperator: "AND", targetings: filteringsIds.toJS(), type: "rule" }),
                  removeRule: handleRemoveRule,
                  getStatusRule: () => false,
                  disableSwitcher: true,
                }}
                ruleProps={{
                  getRuleByIdSelector: getFilteringRuleById,
                  getRuleList: getTargetingList,
                  getValuesList: getValues,
                  handleChangeValue,
                  handleChangeRule,
                  checkLoadingForValues: getLoadingValues,
                  getErrors: getError,
                  isRemoveDisabled: true,
                  getCampaignList: handleGetCampaigns,
                  changeCampaign: onChangeFilteringRuleOfferV2,
                  isDisabledCampaignId: useOneLink,
                }}
              />
            </Box>
            <Box borderTop={1} borderColor="borderColor" pt={2} width="100%" data-testid="one-link-group">
              <UseOneLink
                uniqueElementId="1"
                campaignId={useOneLinkOffer.id}
                url={useOneLinkOffer.url}
                onUrlChange={(offerItem: OfferItem) => {
                  const offer = parseOffer(offerItem);
                  setUseOneLinkOffer(offer);
                  onChangeFilteringRuleOneLinkOffer(filteringsIds, offer);
                }}
                checked={useOneLink}
                onCheckChange={(e) => {
                  setUseOneLink(e.target.checked);
                  if (e.target.checked) {
                    onChangeFilteringRuleOneLinkOffer(filteringsIds, useOneLinkOffer);
                  }
                }}
                label="Use one link for all filters"
              />
            </Box>
          </>
        </SetupCard>
      )}
    </Box>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Filterings);
