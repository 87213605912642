/* eslint-disable import/max-dependencies */
// @flow
import {
  select,
  call,
  debounce,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { getTableFilters, getTablePage, getTablePageSize } from "@fas/ui-framework/lib/redux/selectors/table";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { setTableData, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { GET_OFFERS_SAGA, OFFERS_TABLE } from "../../helpers/constants";
import CampaignApi from "../../services/smartlinkApi";
import type { Offer } from "../../reducers/offers";

export function* makeFetch(): Saga<void> {
  yield put(setLoading<typeof OFFERS_TABLE>(OFFERS_TABLE, true));

  const page: number = yield select(getTablePage, OFFERS_TABLE);
  const pageSize: number = yield select(getTablePageSize, OFFERS_TABLE);
  let filters: Filters = yield select(getTableFilters, OFFERS_TABLE);

  if (filters.name) {
    filters = { ...filters, campaignName: filters.name };
    delete filters.name;
  }

  try {
    const { data: response }: Response<{ data: Offer[], count: number }> = yield call(
      CampaignApi.getOffersList,
      page,
      pageSize,
      filters
    );

    yield put(setTableData(OFFERS_TABLE, response.data));
    yield put(changeTableItemsTotalAmount(OFFERS_TABLE, response.count));
  }
  catch (err) {
    console.error(err); // eslint-disable-line
    yield put(addNotification({ message: err.message, severity: "error" }));
  }
  finally {
    yield put(setLoading<typeof OFFERS_TABLE>(OFFERS_TABLE, false));
  }
}

export default function* watchFetchOffers(): Saga<void> {
  yield debounce(1000, GET_OFFERS_SAGA, (makeFetch: Function));
}
