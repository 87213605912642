import {
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import { CLONE_TARGETING_SAGA } from "../../helpers/constants";
import {
  getFunnelsAllIds,
  getState,
  getTargetingById,
  getTargetingIdsByFunnelId,
  getTargetingsAllIds,
} from "../../selectors/smartlink";
import { addTargeting } from "../../actions/targetings";

export function* cloneTargetingSaga(action) {
  const {
    funnelId,
  } = action;

  const allIds = yield select(getFunnelsAllIds);

  const nextFunnelId = getNextId(allIds);

  const targetingAllIds = yield select(getTargetingsAllIds);
  const nextRootTargetingId = getNextId(targetingAllIds);
  const targetingIdsList = yield select(getTargetingIdsByFunnelId, funnelId);
  const fullState = yield select(getState);

  const newTargetingSchema = {};
  let targetingIdCounter = Number(nextRootTargetingId);
  if (targetingIdsList.length) {
    targetingIdsList.forEach((targetingId) => {
      let counter = Number(targetingIdCounter);
      const prototypeRule = { ...getTargetingById(fullState, targetingId) };

      if (prototypeRule.targetings) {
        prototypeRule.targetings = prototypeRule.targetings.map(() => {
          counter++;
          return counter.toString();
        });
      }
      newTargetingSchema[targetingIdCounter] = prototypeRule;
      targetingIdCounter++;
    });
  }
  yield put(addTargeting({
    funnelId: nextFunnelId,
    targetingSchema: newTargetingSchema,
    targetingAllIds,
  }));
}

export default function* watchCloneTargetingSaga() {
  yield takeEvery(CLONE_TARGETING_SAGA, cloneTargetingSaga);
}
