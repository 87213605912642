// @flow
import React, {
  useState,
  type Node,
  type StatelessFunctionalComponent,
} from "react";
import { Box, IconButton } from "@mui/material";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SelectCustom } from "@fas/ui-core";
import { incomeUrlToPlaceholder } from "../../helpers/placeholders";
import RenamePlaceholderUrlBuilder from "../RenamePlaceholderUrlBuilder";
import type { SelectListType, StateType } from "../../helpers/types";

type Props = {
  url: string,
}

const FunnelUrl: StatelessFunctionalComponent<Props> = ({ url }: Props) => {
  const [urlType, setUrlType]: StateType<string> = useState<string>("redirect");
  const urlTypeValues: SelectListType = [
    { title: "Redirect", value: "redirect" },
    { title: "AE redirect", value: "ae" },
  ];
  const [prefix = "", incomeUrl = ""] = url.split("?");

  const urlViewMapComponent: {[string]: Node} = {
    ae: (
      <>
        <RenamePlaceholderUrlBuilder
          ButtonOpen={({ onClick }) => (
            <IconButton onClick={onClick} data-testid="copy-url-button">
              <LinkOutlinedIcon color="action" />
            </IconButton>
          )}
          manualPlaceholders={{
            data2: "{clickid}",
            utm_content: "{subid}",
            s3: "{subid2}",
            utm_campaign: "{affid}",
          }}
          getUrl={(newIncomeUrl: string): string => `${prefix}?${newIncomeUrl}`}
          placeholders={incomeUrlToPlaceholder(incomeUrl)}
        />
      </>
    ),
  };

  const defaultUrlView: Node = (
    <CopyToClipboard text={url}>
      <IconButton data-testid="copy-url-button" title="Copy URL">
        <LinkOutlinedIcon color="action" />
      </IconButton>
    </CopyToClipboard>
  );

  const urlView: Node = urlViewMapComponent[urlType] || defaultUrlView;

  return (
    <Box display="flex" alignItems="center">
      <Box width="120px">
        <SelectCustom
          title="Type"
          value={urlType}
          values={urlTypeValues}
          onTypeChange={(e) => setUrlType(e.target.value)}
        />
      </Box>
      <Box width="50px" pl={1}>
        {urlView}
      </Box>
    </Box>
  );
};

export default FunnelUrl;
