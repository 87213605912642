// @flow
import { useDispatch } from "react-redux";
import {
  setTableData,
  setTableSelection,
  changeTableItemsTotalAmount,
  changeTableSorting,
  changeTableFilters,
} from "@fas/ui-framework/lib/redux/actions/table";

export type ClearTableHook = {
  clearTableData: () => void,
  clearTableFilters: () => void,
};

const useClearTable: (string) => ClearTableHook = (key) => {
  const dispatch: <A>(A) => A = useDispatch();

  const clearTableData: () => void = () => {
    dispatch(setTableSelection(key, []));
    dispatch(setTableData(key, []));
    dispatch(changeTableItemsTotalAmount(key, 0));
  };

  const clearTableFilters: () => void = () => {
    dispatch(changeTableFilters(key, {}));
    dispatch(changeTableSorting(key, {}));
  };

  return { clearTableData, clearTableFilters };
};

export default useClearTable;
