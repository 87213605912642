import { createSelector } from "reselect";
import { getTableFilters, getTableSorting } from "@fas/ui-framework/lib/redux/selectors/table";
import { userService } from "../../services/user";

export const getCurrentStepId = createSelector(
  (state) => state.steps,
  (steps) => steps.get("current")
);

export const getCurrentTabId = createSelector(
  (state) => state.tabs,
  (tabs) => tabs.get("current")
);

export const getCurrentTabUrlById = createSelector(
  (state, tabId) => state.tabs.getIn(["byId", tabId, "urlParam"]),
  (urlParam) => urlParam
);

export const getTabTitles = createSelector(
  (state) => state.tabs.get("byId"),
  (tabs) => tabs.filter((tab) => userService.can(tab.get("permissions") || [])).map((tab) => tab.get("title"))
);

export const getErrors = createSelector(
  (state) => state.errors,
  (errors) => errors
);

export const getState = createSelector(
  (state) => state,
  (state) => state
);

export const getTemplateInfo = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo
);

export const getTemplateName = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo.get("name")
);

export const getTemplateType = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo.get("templateType")
);

export const getTemplateId = createSelector(
  (state) => state.campaignInfo,
  (campaignInfo) => campaignInfo.get("id")
);

export const getFunnelsCount = createSelector(
  (state) => state.funnels,
  (funnels) => funnels.get("allIds").size
);

export const getTemplateData = createSelector(
  [
    (state) => state.campaignInfo.toJS(),
    (state) => state.offers.toJS(),
    (state) => state.targetings.toJS(),
    (state) => state.filterings.toJS(),
    (state) => state.splits.toJS(),
    (state) => state.frequency.toJS(),
    (state) => state.funnels.toJS(),
    (state) => state.actionOptionsParams.toJS(),
  ],
  (
    campaignInfo,
    offers,
    targetings,
    filterings,
    splits,
    frequency,
    funnels,
    actionOptionsParams
    // eslint-disable-next-line max-params
  ) => ({
    campaignInfo,
    offers,
    targetings,
    filterings,
    splits,
    frequency,
    funnels,
    actionOptionsParams,
  })
);

export const getFunnelTemplateId = createSelector(
  (state) => state.funnelTemplateInfo,
  (funnelTemplateInfo) => funnelTemplateInfo.get("id")
);

export const getFunnelTemplateName = createSelector(
  (state) => state.funnelTemplateInfo,
  (funnelTemplateInfo) => funnelTemplateInfo.get("name")
);

export const getFunnelTemplateFunnelIds = createSelector(
  (state) => state.funnels.get("allIds"),
  (funnels) => funnels.toArray()
);

export const getFunnelTemplateInfo = createSelector(
  (state) => state.funnelTemplateInfo,
  (funnelTemplateInfo) => funnelTemplateInfo
);

export const getFunnelUrl = () => () => "";

export const getReportColumnsOrder = createSelector(
  (state) => state.funnelTemplateReport.get("filterOrder"),
  (reportColumnsOrder) => reportColumnsOrder
);

export const getReportGroupBy = createSelector(
  (state) => state.funnelTemplateReport.get("groupBy"),
  (groupBy) => groupBy
);

export const getReportExportLinkParams = createSelector(
  [
    getReportColumnsOrder,
    getReportGroupBy,
    getTableSorting,
    getTableFilters,
  ],
  (columns, groupBy, sorting, filters) => ({
    columns,
    groupBy,
    sorting,
    filters,
  })
);

export const getIsConfigureReportColumnsModalOpen = createSelector(
  (state) => state.configureColumnsModal.get("isOpen"),
  (isOpen) => isOpen
);
