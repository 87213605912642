// @flow
import joi from "joi";

// eslint-disable-next-line import/prefer-default-export
export const schema = joi.object({
  ruleId: joi.number(),
  description: joi.string().allow(""),
  scoreType: [joi.string(), joi.number()],
  location: joi.any().when("scoreType", {
    is: "feature",
    then: joi.string().valid("data", "headers", "cookies").messages({
      "string.base": "\"location\" is not allowed to be empty",
    }),
    otherwise: joi.valid(null),
  }),
  name: joi
    .string()
    .max(255)
    .required()
    .label("Trigger Name"),
  score: joi.any().when("scoreType", {
    is: "value",
    then: joi
      .number()
      .max(100)
      .min(-100)
      .label("Score")
      .messages({
        "number.min": "Invalid score. It must be from -100 to 100",
        "number.max": "Invalid score. It must be from -100 to 100",
      }),
    otherwise: joi.number().min(1).message("\"score\" is not allowed to be empty").label("Score"),
  }),
  isActive: joi.boolean(),
  conditions: joi.object().keys().pattern(
    /^[0-9]+$/,
    joi.object().when(joi.object({ type: "rule" }).unknown(), {
      then: joi.object({
        operator: joi.string().required(),
        parentId: joi.string(),
        type: joi.string().valid("rule"),
        name: [joi.string(), joi.number()],
        value: joi.any().when("operator", {
          not: "exists",
          then: joi.any().when("operator", {
            not: "absent",
            then: [joi.string(), joi.number()],
            otherwise: joi.optional(),
          }),
          otherwise: joi.optional(),
        }),
        valueType: joi.string().valid("value", "feature"),
        location: joi.string().valid("data", "headers", "cookies"),
      }),
      otherwise: joi.object({
        groupOperator: joi.string().valid("AND", "OR"),
        parentId: joi.string().allow(null),
        type: joi.string().valid("group"),
        children: joi.array(),
      }),
    })
  ),
}).options({ abortEarly: false });
