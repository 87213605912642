// @flow
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Box,
} from "@mui/material";
import { StepControl } from "@fas/ui-core";
import { stepClick } from "@fas/ui-framework/lib/redux/actions/steps";
import {
  getPreviousStepId,
} from "@fas/ui-framework/lib/services/generators";
import TemplatePreview from "../../components/TemplatePreview";
import {
  getCurrentStepId,
  getTemplateName,
  getTemplateType,
  getFunnelsCount,
  getTemplateId,
} from "../../selectors/templates";
import { saveTemplateSaga } from "../../actions/template";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  currentStep: string,
  name: string,
  type: string,
  id: string,
  funnelsCount: number,
  handleStepClick: (stepId: string) => void,
  handleTemplateSave: (cb: Function) => void,
|}>;

const mapStateToProps = (state) => ({
  currentStep: getCurrentStepId(state),
  name: getTemplateName(state),
  type: getTemplateType(state),
  id: getTemplateId(state),
  funnelsCount: getFunnelsCount(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleStepClick: (stepId) => dispatch(stepClick(stepId)),
  handleTemplateSave: (cb) => dispatch(saveTemplateSaga(cb)),
});

const TemplatePreviewStep = ({
  currentStep,
  name,
  type,
  id,
  funnelsCount,
  handleStepClick,
  handleTemplateSave,
}: Props) => {
  const [loadingSave, setLoadingSave] = useState(false);

  return (
    <Container maxWidth="md">
      <Box data-testid="templateInfo">
        <TemplatePreview
          name={name}
          type={type}
          id={id}
          funnelsCount={funnelsCount}
        />
      </Box>
      <Box mt={3} display="flex" justifyContent="space-between">
        <StepControl
          showBackButton
          nextButtonText="Save"
          onBack={() => handleStepClick(getPreviousStepId(currentStep))}
          onNext={() => {
            setLoadingSave(true);
            return handleTemplateSave(() => setLoadingSave(false));
          }}
          isLoading={loadingSave}
        />
      </Box>
    </Container>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(TemplatePreviewStep);
