// @flow
import {
  HISTORY_REPLACE_TABLE,
  GET_HISTORY_REPLACE_LISTS,
} from "../../helpers/constants";

export type GetHistoryReplaceListSaga = {
  type: typeof HISTORY_REPLACE_TABLE,
}

export type GetHistoryReplaceDropdownListsSaga = {
  type: typeof GET_HISTORY_REPLACE_LISTS,
}

export type Actions = GetHistoryReplaceListSaga | GetHistoryReplaceDropdownListsSaga;

export const getHistoryReplaceListSaga: () => GetHistoryReplaceListSaga = () => ({
  type: HISTORY_REPLACE_TABLE,
});

export const getHistoryReplaceDropdownListsSaga: () => GetHistoryReplaceDropdownListsSaga = () => ({
  type: GET_HISTORY_REPLACE_LISTS,
});
