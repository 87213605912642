// @flow
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  GET_ANTIFRAUD_DICTIONARY_SAGA,
} from "../../helpers/constants";
import {
  setAntifraudDictionary,
  type GetAntifraudDictionarySaga,
} from "../../actions/antifraudDictionaryForm";
import { fetchAntifraudDictionary } from "../../services/antifraudDictionariesApi";
import type { Dictionary } from "../../selectors/antifraudDictionaries";

export function* makeFetch(action: GetAntifraudDictionarySaga): Saga<void> {
  try {
    const { dictId }: GetAntifraudDictionarySaga = action;
    yield put(setLoading(GET_ANTIFRAUD_DICTIONARY_SAGA, true));

    const {
      data: {
        dictionary,
      },
    }: { data: { dictionary: Dictionary }} = yield call(fetchAntifraudDictionary, dictId);

    yield put(setAntifraudDictionary(dictionary));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch dictionary", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(GET_ANTIFRAUD_DICTIONARY_SAGA, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_ANTIFRAUD_DICTIONARY_SAGA, (makeFetch: Function));
}
