// @flow
import React from "react";
import {
  Grid, Switch, Checkbox, TextField, Link,
} from "@mui/material";
import environment from "environment";
import { makeStyles } from "@mui/styles";
import type { Setting } from "./types/FilterSettingsList.types";

interface Props extends Setting {
  index: number;
  onActiveChange: () => void;
}
const pointerStyle = { style: { cursor: "pointer" } };

const styles = makeStyles(() => ({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#616674",
    },
  },
}));

const FilterSettingItem = (props: Props) => {
  const classes = styles();
  const {
    isFilterActiveOnCampaign,
    tdsCampaignsOut,
    name,
    mustHave,
    rate,
    priority,
    onActiveChange,
    id: campaignId,
    index,
  } = props;

  const viewLink = `${environment.links.editGeneralFilter}/${campaignId}`;

  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={1} justifyContent="center" container alignItems="center">
        <Switch
          checked={isFilterActiveOnCampaign}
          onClick={onActiveChange}
          data-testid={`filter-setting-item-active-on-campaign-${campaignId}`}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          variant="outlined"
          disabled
          name="priority"
          value={priority}
          size="small"
          fullWidth
          classes={classes}
        />
      </Grid>
      <Grid item xs={5}>
        <Link
          color="inherit"
          underline="none"
          component="a"
          target="_blank"
          href={viewLink}
        >
          <TextField
            inputProps={pointerStyle}
            variant="outlined"
            disabled
            name="filter"
            value={name}
            size="small"
            fullWidth
            classes={classes}
          />
        </Link>
      </Grid>
      <Grid item xs={1}>
        <TextField
          variant="outlined"
          disabled
          name="percent"
          value={rate}
          size="small"
          fullWidth
          classes={classes}
        />
      </Grid>
      <Grid item container xs={3} spacing={1}>
        {tdsCampaignsOut.map(({ id, weight }) => (
          <React.Fragment key={id}>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                disabled
                name="campaignOut"
                value={id}
                size="small"
                fullWidth
                classes={classes}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                disabled
                size="small"
                value={weight}
                fullWidth
                classes={classes}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Grid item container xs={1} justifyContent="space-around">
        <Checkbox
          disabled
          checked={Boolean(mustHave)}
          data-testid={`filter-setting-item-must-have-checkbox-${index}`}
        />
      </Grid>
    </Grid>
  );
};

export default FilterSettingItem;
