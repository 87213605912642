// @flow
import type { Saga } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { DELETE_TEMPLATE_SAGA } from "../../helpers/constants";
import { deleteFunnelsTemplate } from "../../services/templateApi/template";
import type { DeleteFunnelsTemplateSaga } from "../../actions/funnelsTemplateList/actions";

export function* makeFetch(action: DeleteFunnelsTemplateSaga): Saga<void> {
  try {
    yield call(deleteFunnelsTemplate, action.templateId);
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
  }
}

export default function* fetchDeleteTemplateSaga(): Saga<void> {
  yield takeEvery(DELETE_TEMPLATE_SAGA, makeFetch);
}
