// @flow
import React from "react";
import {
  Box, IconButton, Switch, Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import makeStyles from "@mui/styles/makeStyles";
import { mtuDefaultTheme as globTheme } from "@fas/ui-themes";

type Props = {
  title: string,
  subtitle: string,
  checked: ?boolean,
  onSwitchClick: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onArrowClick: (e: SyntheticInputEvent<HTMLInputElement>) => void
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.background.blueBackgroundLight,
      border: `1px solid ${theme.palette.borderColor}`,
      borderRadius: "8px",
      padding: theme.spacing(1),
    },
  }),

  {
    defaultTheme: globTheme,
  }
);

const FilterList = (props: Props) => {
  const styles = useStyles();
  const {
    title, subtitle, checked, onSwitchClick, onArrowClick,
  } = props;
  return (
    <Box
      className={styles.root}
      display="flex"
      flexDirection="row"
      flexWrap="no-wrap"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Switch
        data-testid="filter-list-switch-btn"
        checked={checked}
        onClick={onSwitchClick}
      />
      <Box
        flexGrow="0.01"
        display="flex"
        flexDirection="column"
        flexWrap="no-wrap"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="no-wrap"
          alignItems="flex-start"
        >
          <Typography variant="body2" component="span" color="textSecondary">
            {title}
          </Typography>
          <Typography variant="subtitle1" component="span">
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Box marginLeft="auto">
        <IconButton onClick={onArrowClick} data-testid="filter-list-arrow-btn">
          <ArrowForwardIosIcon
            fontSize="small"
            color="primary"
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default FilterList;
