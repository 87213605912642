// @flow
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDropdown } from "@fas/ui-framework/lib/redux/selectors/dropdowns";
import type { Option } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import { getDictionaryLoadingKey, getDictionaryKey } from "../../helpers/generators";
import { getDictionarySaga } from "../../actions/dropdowns";
import { useLoading } from "../useLoading";

export type DictionaryParams = {|
  dictionary: string,
  type: string,
  filter?: string,
  format?: string,
  field?: string,
|}

type UseDictionaryType<T> = [
  T[],
  boolean,
];

type Selector<T> = (state: Object, key: string) => T[];

/**
 * Base get dictionary
 * @example
 * const params = { dictionary: "advertiser", type: "apiCountry", name: "offer", format: "name", ... }
 * const [countries, isCountryLoading] = useDictionary(params);
 * @param {DictionaryParams} params name of list
 * @param {Selector} selector selector to get list from state
 * @returns {[T[],boolean]} cortege [list, loading]
 */
export default function useDictionary<T>(params: DictionaryParams, selector: Selector<T>): UseDictionaryType<T> {
  const dispatch = useDispatch();
  const list: T[] = useSelector((state) => selector(state, getDictionaryKey(params)));
  const loading: boolean = useLoading<string>(getDictionaryLoadingKey(params));

  useEffect(() => {
    dispatch(getDictionarySaga(params));
  }, [dispatch, params.format, params.filter, params.type, params.field, params.dictionary]);

  return [
    list,
    loading,
  ];
}

/**
 * Get dictionary dropdown list by name
 * @example
 * const [countries, isCountryLoading] = useDictionary("advertiser", "apiCountry");
 * @param {string} dictionary name
 * @param {string} listType type of list
 * @returns {[DropDownObjItemType[],boolean]} cortege [list, loading]
 */
export function useDictionaryDropdownList<T>(
  dictionary: string,
  listType: string
): UseDictionaryType<Option<T>> {
  return useDictionary<Option<T>>({
    dictionary,
    type: listType,
  }, getDropdown);
}
