// @flow
import Joi from "@hapi/joi";
import type { Map } from "immutable";
import type { JoiError, Errors } from "../../types/Base";
import type { Split } from "../../../../reducers/splits";
import type { BaseSimpleErrorsRule } from "../Base";
import {
  WEIGHTS,
  FREQUENCY_WEIGHTS_UNIQUE,
} from "../../messages";
import withUniqueFrequency from "./splitsFrequencyUniqueSchema";

export default class SplitsWeights implements BaseSimpleErrorsRule {
  schema: Object;

  errors: Errors;

  errorKey: string;

  constructor() {
    this.errors = {};
    this.errorKey = "splitsFrequencyWeights";

    this.schema = withUniqueFrequency
      .array()
      .items(
        Joi
          .object({
            frequency: Joi
              .array()
              .min(1)
              .items(
                Joi
                  .number()
                  .integer()
                  .min(1)
                  .max(999)
              ),
          })
          .unknown(true)
      )
      .validateFrequencyUnique()
      .error((errors) => this._onError(errors));
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach((item) => {
      switch (item.code) {
        case "number.integer":
        case "number.min":
        case "number.max":
        case "array.min": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: WEIGHTS },
          };
          break;
        }
        case "frequency.unique": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: FREQUENCY_WEIGHTS_UNIQUE },
          };
          break;
        }
        default:
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: item.message },
          };
          break;
      }
    });

    return errors;
  }

  getErrors(data: Map<Split>): Errors {
    this.schema.validate(data.valueSeq().toJS());
    return this.errors;
  }
}
