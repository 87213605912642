import {
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import {
  getNextId,
} from "@fas/ui-framework/lib/services/generators";
import { CLONE_OFFER_SAGA } from "../../helpers/constants";
import {
  getOfferById,
  getOffersAllIds,
} from "../../selectors/smartlink";
import {
  addOffer,
} from "../../actions/offers";

export function* cloneOfferSaga(action) {
  const { funnelId, offerId } = action;

  const allIds = yield select(getOffersAllIds);
  const nextOfferId = getNextId(allIds);

  const offer = yield select(getOfferById, offerId);

  yield put(addOffer(funnelId, nextOfferId, offer.toJS()));
}

export default function* watchCloneOffer() {
  yield takeEvery(CLONE_OFFER_SAGA, cloneOfferSaga);
}
