// @flow
import { Map } from "immutable";
import {
  GENERAL_FILTER_INFO_CHANGE_NAME,
  GENERAL_FILTER_INFO_CHANGE_DESCRIPTION,
  GENERAL_FILTER_INFO_CHANGE_PRIORITY,
  GENERAL_FILTER_INFO_CHANGE_RATE,
  GENERAL_FILTER_INFO_CHANGE_MUST_HAVE,
  GENERAL_FILTER_INFO_CHANGE_IS_ACTIVE,
  GENERAL_FILTER_INFO_ADD_CAMPAIGN_IN,
  GENERAL_FILTER_INFO_DELETE_CAMPAIGN_IN,
  GENERAL_FILTER_INFO_ADD_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_DELETE_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_OPEN_MODAL_IN,
  GENERAL_FILTER_INFO_OPEN_MODAL_OUT,
  GENERAL_FILTER_INFO_SET_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_UNSET_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_ID,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_NAME,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_STATUS,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_TYPE,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_TAGS,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_TAGS,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_TAGS,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_FILTER,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_FILTER,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_FILTER,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_RESET_FILTERS,
  GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_IN,
  GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_IN,
  GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_MODAL_CAMPAIGN_IN_SAVE,
  GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_EDIT,
  GENERAL_FILTER_INFO_EQUAL_SHARES,
  GENERAL_FILTER_INFO_SET_WEIGHT,
  GENERAL_FILTER_INFO_INIT_STATE_EDIT,
  GENERAL_FILTER_CHANGE_TARGETING,
  GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_ADD,
  GENERAL_FILTER_INFO_FACT,
  GENERAL_FILTER_INFO_DRYMODE,
  GENERAL_FILTER_INFO_RERENDER,
} from "../../helpers/constants";
import type {
  CampaignType, CampaignTable, FilterInfo, schemaType,
} from "../../reducers/generalFilterInfo";

export type ChangeNameAction = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_NAME,
  value: string,
};
export type ChangeDescriptionAction = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_DESCRIPTION,
  value: string,
};
export type ChangePriorityAction = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_PRIORITY,
  value: number,
};
export type ChangeRateAction = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_RATE,
  value: number,
};
export type ChangeMustHaveAction = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_MUST_HAVE,
};
export type ChangeIsActiveAction = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_IS_ACTIVE,
};
export type AddCampaignIn = {
  type: typeof GENERAL_FILTER_INFO_ADD_CAMPAIGN_IN,
  value: CampaignType,
};
export type DeleteCampaignIn = {
  type: typeof GENERAL_FILTER_INFO_DELETE_CAMPAIGN_IN,
  value: CampaignType,
};
export type AddCampaignOut = {
  type: typeof GENERAL_FILTER_INFO_ADD_CAMPAIGN_OUT,
};
export type DeleteCampaignOut = {
  type: typeof GENERAL_FILTER_INFO_DELETE_CAMPAIGN_OUT,
  value: number,
};
export type OpenModalIn = {
  type: typeof GENERAL_FILTER_INFO_OPEN_MODAL_IN,
  value: string,
  index: number
};
export type OpenModalOut = {
  type: typeof GENERAL_FILTER_INFO_OPEN_MODAL_OUT,
  value: string,
  index: number
};
export type SetCampaignOut = {
  type: typeof GENERAL_FILTER_INFO_SET_CAMPAIGN_OUT,
  index: number,
  value: string,
};
export type UnSetCampaignOut = {
  type: typeof GENERAL_FILTER_INFO_UNSET_CAMPAIGN_OUT,
  index: number,
};
export type ChangeFiltersIdAction = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_FILTERS_ID,
  value: string,
};
export type ChangeFiltersNameAction = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_FILTERS_NAME,
  value: string,
}
export type ChangeFiltersStatusAction = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_FILTERS_STATUS,
  value: string,
}
export type ChangeFiltersTypeAction = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_FILTERS_TYPE,
  value: string,
}
export type AddTags = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_TAGS,
  value: CampaignType,
};
export type DeleteTags = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_TAGS,
  value: CampaignType,
};
export type ChangeStatusTags = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_TAGS,
  value: string,
};
export type AddFilter = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_FILTER,
  value: CampaignType,
};
export type DeleteFilter = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_FILTER,
  value: CampaignType,
};
export type ChangeStatusFilter = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_FILTER,
  value: string,
};
export type ResetFilters = {
  type: typeof GENERAL_FILTER_INFO_CHANGE_FILTERS_RESET_FILTERS,
};
export type SelectModalCampaignIn = {
  type: typeof GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_IN,
  value: CampaignTable,
  typeCampaign: string,
}
export type SelectModalCampaignOut = {
  type: typeof GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_OUT,
  value: CampaignTable,
  typeCampaign: string,
}
export type UnselectModalCampaignIn = {
  type: typeof GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_IN,
  value: CampaignTable,
}
export type UnselectModalCampaignOut = {
  type: typeof GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_OUT,
  value: CampaignTable,
}
export type SaveModalCampaignIn = {
  type: typeof GENERAL_FILTER_INFO_MODAL_CAMPAIGN_IN_SAVE,
  ids: string[],
}
export type SaveModalCampaignOutEdit = {
  type: typeof GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_EDIT,
}
export type SaveModalCampaignOutAdd = {
  type: typeof GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_ADD,
  campaigns: { id: string, weight: number }[],
}
export type EqualShares = {
  type: typeof GENERAL_FILTER_INFO_EQUAL_SHARES,
}
export type SetWeight = {
  type: typeof GENERAL_FILTER_INFO_SET_WEIGHT,
  index: number,
  value: string,
}
export type InitStateEdit = {
  type: typeof GENERAL_FILTER_INFO_INIT_STATE_EDIT,
  value: Map<FilterInfo>
};
export type ChangeTargeting = {
  type: typeof GENERAL_FILTER_CHANGE_TARGETING,
  value: Map<schemaType>
};
export type ChangeFact = {
  type: typeof GENERAL_FILTER_INFO_FACT,
  value: number,
};
export type ChangeDryMode = {
  type: typeof GENERAL_FILTER_INFO_DRYMODE,
  value: number,
};
export type GeneralFilterRerender = {
  type: typeof GENERAL_FILTER_INFO_RERENDER,
};

export const changeName = (
  value: string
): ChangeNameAction => ({
  type: GENERAL_FILTER_INFO_CHANGE_NAME,
  value,
});
export const changeDescription = (
  value: string
): ChangeDescriptionAction => ({
  type: GENERAL_FILTER_INFO_CHANGE_DESCRIPTION,
  value,
});
export const changePriority = (
  value: number
): ChangePriorityAction => ({
  type: GENERAL_FILTER_INFO_CHANGE_PRIORITY,
  value,
});
export const changeRate = (
  value: number
): ChangeRateAction => ({
  type: GENERAL_FILTER_INFO_CHANGE_RATE,
  value,
});
export const changeMustHave = (
): ChangeMustHaveAction => ({
  type: GENERAL_FILTER_INFO_CHANGE_MUST_HAVE,
});
export const changeIsActive = (
): ChangeIsActiveAction => ({
  type: GENERAL_FILTER_INFO_CHANGE_IS_ACTIVE,
});
export const addCampaignIn = (
  value: CampaignType
): AddCampaignIn => ({
  type: GENERAL_FILTER_INFO_ADD_CAMPAIGN_IN,
  value,
});
export const deleteCampaignIn = (
  value: CampaignType
): DeleteCampaignIn => ({
  type: GENERAL_FILTER_INFO_DELETE_CAMPAIGN_IN,
  value,
});
export const addCampaignOut = (
): AddCampaignOut => ({
  type: GENERAL_FILTER_INFO_ADD_CAMPAIGN_OUT,
});
export const openModalIn = (
  value: string,
  index: number
): OpenModalIn => ({
  type: GENERAL_FILTER_INFO_OPEN_MODAL_IN,
  value,
  index,
});
export const openModalOut = (
  value: string,
  index: number
): OpenModalOut => ({
  type: GENERAL_FILTER_INFO_OPEN_MODAL_OUT,
  value,
  index,
});
export const deleteCampaignOut = (
  value: number
): DeleteCampaignOut => ({
  type: GENERAL_FILTER_INFO_DELETE_CAMPAIGN_OUT,
  value,
});
export const setCampaignOut = (
  index: number,
  value: string
): SetCampaignOut => ({
  type: GENERAL_FILTER_INFO_SET_CAMPAIGN_OUT,
  index,
  value,
});
export const unSetCampaignOut = (
  index: number
): UnSetCampaignOut => ({
  type: GENERAL_FILTER_INFO_UNSET_CAMPAIGN_OUT,
  index,
});
export const changeFiltersId = (
  value: string
): ChangeFiltersIdAction => ({
  type: GENERAL_FILTER_INFO_CHANGE_FILTERS_ID,
  value,
});
export const changeFiltersName = (
  value: string
): ChangeFiltersNameAction => ({
  type: GENERAL_FILTER_INFO_CHANGE_FILTERS_NAME,
  value,
});
export const changeFiltersStatus = (
  value: string
): ChangeFiltersStatusAction => ({
  type: GENERAL_FILTER_INFO_CHANGE_FILTERS_STATUS,
  value,
});
export const changeFiltersType = (
  value: string
): ChangeFiltersTypeAction => ({
  type: GENERAL_FILTER_INFO_CHANGE_FILTERS_TYPE,
  value,
});
export const addTags = (
  value: CampaignType
): AddTags => ({
  type: GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_TAGS,
  value,
});
export const deleteTags = (
  value: CampaignType
): DeleteTags => ({
  type: GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_TAGS,
  value,
});
export const changeStatusTags = (
  value: string
): ChangeStatusTags => ({
  type: GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_TAGS,
  value,
});
export const addFilter = (
  value: CampaignType
): AddFilter => ({
  type: GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_FILTER,
  value,
});
export const deleteFilter = (
  value: CampaignType
): DeleteFilter => ({
  type: GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_FILTER,
  value,
});
export const changeStatusFilter = (
  value: string
): ChangeStatusFilter => ({
  type: GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_FILTER,
  value,
});
export const resetFilters = (
): ResetFilters => ({
  type: GENERAL_FILTER_INFO_CHANGE_FILTERS_RESET_FILTERS,
});
export const selectModalCampaignIn = (
  value: CampaignTable,
  typeCampaign: string
): SelectModalCampaignIn => ({
  type: GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_IN,
  value,
  typeCampaign,
});
export const selectModalCampaignOut = (
  value: CampaignTable,
  typeCampaign: string
): SelectModalCampaignOut => ({
  type: GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_OUT,
  value,
  typeCampaign,
});
export const unselectModalCampaignIn = (
  value: CampaignTable,
  typeCampaign: string
): UnselectModalCampaignIn => ({
  type: GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_IN,
  value,
  typeCampaign,
});
export const unselectModalCampaignOut = (
  value: CampaignTable,
  typeCampaign: string
): UnselectModalCampaignOut => ({
  type: GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_OUT,
  value,
  typeCampaign,
});
export const saveModalCampaignIn = (ids: string[]): SaveModalCampaignIn => ({
  type: GENERAL_FILTER_INFO_MODAL_CAMPAIGN_IN_SAVE,
  ids,
});
export const saveModalCampaignOutEdit = (
  index: number
): SaveModalCampaignOutEdit => ({
  type: GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_EDIT,
  index,
});
export const saveModalCampaignOutAdd = (campaigns: { id: string, weight: number }[]): SaveModalCampaignOutAdd => ({
  type: GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_ADD,
  campaigns,
});
export const equalShares = (): EqualShares => ({
  type: GENERAL_FILTER_INFO_EQUAL_SHARES,
});
export const setWeight = (
  index: number,
  value: string
): SetWeight => ({
  type: GENERAL_FILTER_INFO_SET_WEIGHT,
  index,
  value,
});
export const initStateEdit = (
  value: Map<FilterInfo>
): InitStateEdit => ({
  type: GENERAL_FILTER_INFO_INIT_STATE_EDIT,
  value,
});
export const changeTargeting = (
  value: Map<schemaType>
): ChangeTargeting => ({
  type: GENERAL_FILTER_CHANGE_TARGETING,
  value,
});
export const changeFact = (
  value: number
): ChangeFact => ({
  type: GENERAL_FILTER_INFO_FACT,
  value,
});
export const changeDryMode = (
  value: number
): ChangeDryMode => ({
  type: GENERAL_FILTER_INFO_DRYMODE,
  value,
});
export const generalFilterRerender = (): GeneralFilterRerender => ({
  type: GENERAL_FILTER_INFO_RERENDER,
});
