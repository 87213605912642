// @flow
/* eslint-disable max-len */
import {
  ADD_TARGETING_GF,
  ADD_TARGETING_GROUP_GF,
  CHANGE_GROUP_OPERATOR_GF,
  DELETE_TARGETING_GF,
  CHANGE_TARGETING_GF_RULE,
  CHANGE_TARGETING_GF_OPERATOR,
  CHANGE_TARGETING_GF_VALUE,
  FETCH_TARGETING_GF_SAGA,
  FETCH_TARGETING_VALUES_SAGA,
  REMOVE_TARGETING_GF_GROUP_SAGA,
  INIT_TARGETING_GF,
  SAVE_TARGETING_GF_SAGA,
} from "../../helpers/constants";

export type AddTargetingGf = {
  type: typeof ADD_TARGETING_GF,
  groupId: string,
}
export function addTargetingGf(groupId: string): AddTargetingGf {
  return {
    type: ADD_TARGETING_GF,
    groupId,
  };
}

export type AddTargetingGroupGf = {
  type: typeof ADD_TARGETING_GROUP_GF,
  groupId: string,
}
export function addTargetingGroupGf(groupId: string): AddTargetingGroupGf {
  return {
    type: ADD_TARGETING_GROUP_GF,
    groupId,
  };
}

export type ChangeTargetingGroupOperatorGf = {
  type: typeof CHANGE_GROUP_OPERATOR_GF,
  id: string,
  val: string,
}
export function changeTargetingGroupOperatorGf(id: string, val: "OR" | "AND"): ChangeTargetingGroupOperatorGf {
  return {
    type: CHANGE_GROUP_OPERATOR_GF,
    id,
    val,
  };
}

export type DeleteTargetingGf = {
  type: typeof DELETE_TARGETING_GF,
  targetingId: string,
  parentId: ?string,
}
export function deleteTargetingGf(targetingId: string, parentId: ?string): DeleteTargetingGf {
  return {
    type: DELETE_TARGETING_GF,
    parentId,
    targetingId,
  };
}

export type Rule = {
  name: {
    value: string,
    label: string
  },
  type: string,
  operators: Array<{| value: string, name: string |}>
}
export type ChangeTargetingGfRule = {
  type: typeof CHANGE_TARGETING_GF_RULE,
  targetingId: string,
  rule: Rule,
}
export function changeTargetingGfRule(targetingId: string, rule: Rule): ChangeTargetingGfRule {
  return {
    type: CHANGE_TARGETING_GF_RULE,
    targetingId,
    rule,
  };
}

export type ChangeTargetingGfOperator = {
  type: typeof CHANGE_TARGETING_GF_OPERATOR,
  targetingId: string,
  operator: {| name: string, value: string |},
}
export function changeTargetingGfOperator(targetingId: string, operator: {| name: string, value: string |}): ChangeTargetingGfOperator {
  return {
    type: CHANGE_TARGETING_GF_OPERATOR,
    targetingId,
    operator,
  };
}

export type ChangeTargetingGfValue = {
  type: typeof CHANGE_TARGETING_GF_VALUE,
  targetingId: string,
  value: Array<{
    label: string,
    value: string,
    subValues?: Array<string>,
  } | string>,
}
export function changeTargetingGfValue(targetingId: string, value: Array<{
  label: string,
  value: string,
  subValues?: Array<string>,
} | string>): ChangeTargetingGfValue {
  return {
    type: CHANGE_TARGETING_GF_VALUE,
    targetingId,
    value,
  };
}

export type FetchTargetingGfSaga = {
  type: typeof FETCH_TARGETING_GF_SAGA,
};
export function fetchTargetingGfSaga(): FetchTargetingGfSaga {
  return {
    type: FETCH_TARGETING_GF_SAGA,
  };
}

export type FetchTargetingValuesGfSaga = {
  type: typeof FETCH_TARGETING_VALUES_SAGA,
  ruleName: string,
};
export function fetchTargetingValuesGfSaga(ruleName: string): FetchTargetingValuesGfSaga {
  return {
    type: FETCH_TARGETING_VALUES_SAGA,
    ruleName,
  };
}

export type RemoveTargetingGfGroupSaga = {
  type: typeof REMOVE_TARGETING_GF_GROUP_SAGA,
  id: string,
  parentId: ?string,
}
export function removeTargetingGfGroupSaga(id: string, parentId: ?string): RemoveTargetingGfGroupSaga {
  return {
    type: REMOVE_TARGETING_GF_GROUP_SAGA,
    id,
    parentId,
  };
}

export type InitTargetingStateEdit = {
  type: typeof INIT_TARGETING_GF,
  value: *,
}
export function initTargetingStateEdit(value: *): InitTargetingStateEdit {
  return {
    type: INIT_TARGETING_GF,
    value,
  };
}

export type SaveTargetingGfSaga = {
  type: typeof SAVE_TARGETING_GF_SAGA,
}
export function saveTargetingGfSaga(): SaveTargetingGfSaga {
  return {
    type: SAVE_TARGETING_GF_SAGA,
  };
}

export type Actions = ChangeTargetingGfValue
  | ChangeTargetingGfOperator
  | ChangeTargetingGfRule
  | DeleteTargetingGf
  | ChangeTargetingGroupOperatorGf
  | AddTargetingGroupGf
  | AddTargetingGf
  | FetchTargetingGfSaga
  | FetchTargetingValuesGfSaga
  | RemoveTargetingGfGroupSaga
  | InitTargetingStateEdit;
