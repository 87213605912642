// @flow
// FIX 92090
import React from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import Steps from "../../containers/Steps";
import Notification from "../../components/Notification";
import GeneralFilterInfoStep from "../../containers/GeneralFilterInfoStep";
import TargetingsGeneralFilter from "../../containers/TargetingsGeneralFilter";

type Props = {
};

const componentsMapper = {
  "1": GeneralFilterInfoStep,
  "2": TargetingsGeneralFilter,
};

class GeneralFilter extends React.Component<Props> {
  campaign: Object;

  render() {
    return (
      <DocumentTitle title="General Filter">
        <Provider store={configureStore()}>
          <Steps getStepContent={(stepKey) => componentsMapper[stepKey]} />
          <Notification />
        </Provider>
      </DocumentTitle>
    );
  }
}

export default GeneralFilter;
