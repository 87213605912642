// @flow
import type { StatelessFunctionalComponent } from "react";
/* eslint-disable import/max-dependencies */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, type Match } from "react-router-dom";
import {
  Box,
  Container,
} from "@mui/material";
import {
  Loader, SetupCard, FormHeader,
} from "@fas/ui-core";
import {
  TextFieldForm,
} from "@fas/ui-core/lib/Form";
import { getFormDataSaga } from "@fas/ui-framework";
import { GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import {
  HISTORY_REPLACE_FORM,
} from "../../helpers/constants";
import Form from "../../containers/Form";
import { useLoading } from "../../hooks/useLoading";
import { setBackup, restoreBackup } from "../../actions/backup";

type Props = {||};

const HistoryReplaceForm: StatelessFunctionalComponent<Props> = () => {
  const { id: pageId }: $PropertyType<Match, "params"> = useParams();
  const dispatch = useDispatch();

  const loading: boolean = useLoading(GET_FORM_DATA_SAGA);

  useEffect(() => {
    dispatch(setBackup(["form"]));
    if (pageId) {
      dispatch(getFormDataSaga(HISTORY_REPLACE_FORM, pageId));
    }
    return () => {
      dispatch(restoreBackup());
    };
  }, [pageId]);

  return (
    <Form id={HISTORY_REPLACE_FORM}>
      <FormHeader
        title={pageId ? `HIstory Replace: ${pageId}` : ""}
        loading={loading}
        isActionVisible={false}
      />
      <Container maxWidth="md">
        <Loader loading={loading}>
          <SetupCard title="">
            <Box width={1} sx={{ display: "grid", gridGap: "20px", gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" } }}>
              <TextFieldForm
                name="campaignId"
                label="Campaign Id"
                disabled
              />
              <TextFieldForm
                name="lpNew"
                label="Lp New"
                disabled
              />
              <TextFieldForm
                name="lpNewName"
                label="Lp New Name"
                disabled
              />
              <TextFieldForm
                name="lpOld"
                label="Lp Old"
                disabled
              />
              <TextFieldForm
                name="lpOldName"
                label="Lp Old Name"
                disabled
              />
              <TextFieldForm
                name="layerId"
                label="Layer Id"
                disabled
              />
              <TextFieldForm
                name="cronus"
                label="Cronus"
                disabled
              />
              <TextFieldForm
                name="brand"
                label="Brand"
                disabled
              />
              <TextFieldForm
                name="pHash"
                label="pHash"
                disabled
              />
              <TextFieldForm
                name="pHashOld"
                label="pHash Old"
                disabled
              />
              <TextFieldForm
                name="urlCampaign"
                label="Url Campaign"
                disabled
              />
              <TextFieldForm
                name="splitName"
                label="Split Name"
                disabled
              />
              <TextFieldForm
                name="funnelId"
                label="Funnel Id"
                disabled
              />
              <TextFieldForm
                name="urlFunnel"
                label="Url Funnel"
                disabled
              />
              <TextFieldForm
                name="email"
                label="Email"
                disabled
              />
              <TextFieldForm
                name="status"
                label="Status"
                disabled
              />
              <TextFieldForm
                name="locale"
                label="Locale"
                disabled
              />
              <TextFieldForm
                name="platform"
                label="Platform"
                disabled
              />
              <TextFieldForm
                name="safeMode"
                label="Safe Mode"
                disabled
              />
              <TextFieldForm
                name="niche"
                label="Niche"
                disabled
              />
              <TextFieldForm
                name="s1"
                label="S1"
                disabled
              />
              <TextFieldForm
                name="errorSearch"
                label="Error Search"
                disabled
              />
              <TextFieldForm
                name="statusReplace"
                label="Status Replace"
                disabled
              />
              <TextFieldForm
                name="messageReplace"
                label="Message Replace"
                disabled
              />
              <TextFieldForm
                name="errorsReplace"
                label="Errors Replace"
                disabled
              />
              <TextFieldForm
                name="createdAt"
                label="createdAt"
                disabled
              />
            </Box>
          </SetupCard>
        </Loader>
      </Container>
    </Form>
  );
};

export default HistoryReplaceForm;
