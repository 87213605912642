// @flow
import {
  put,
  call,
  debounce,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { setTableData, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import TemplateApi from "../../services/templateApi";
import {
  FUNNELTEMPLATE_CAMPAIGN_LIST_LOAD_PAGE_SAGA,
  FUNNEL_TEMPLATE_CAMPAIGNS_TABLE,
} from "../../helpers/constants";
import { getFunnelTemplateId } from "../../selectors/templates";

export function* loadFunnelTemplateCampaignListPageSaga(): Saga<void> {
  yield put(setLoading<typeof FUNNEL_TEMPLATE_CAMPAIGNS_TABLE>(FUNNEL_TEMPLATE_CAMPAIGNS_TABLE, true));
  const templateId: string = yield select(getFunnelTemplateId);

  const page: number = yield select(getTablePage, FUNNEL_TEMPLATE_CAMPAIGNS_TABLE);
  const pageSize: number = yield select(getTablePageSize, FUNNEL_TEMPLATE_CAMPAIGNS_TABLE);
  const sorting: Sorting = yield select(getTableSorting, FUNNEL_TEMPLATE_CAMPAIGNS_TABLE);
  const filters: Filters = yield select(getTableFilters, FUNNEL_TEMPLATE_CAMPAIGNS_TABLE);

  try {
    const {
      data: { data, count },
    } = yield call(
      TemplateApi.getCampaignsByTemplateId,
      templateId,
      {
        page,
        pageSize,
        sorting,
        filters,
      }
    );

    yield put(setTableData(FUNNEL_TEMPLATE_CAMPAIGNS_TABLE, data));
    yield put(changeTableItemsTotalAmount(FUNNEL_TEMPLATE_CAMPAIGNS_TABLE, count));
    yield put(setLoading<typeof FUNNEL_TEMPLATE_CAMPAIGNS_TABLE>(FUNNEL_TEMPLATE_CAMPAIGNS_TABLE, false));
  }
  catch (error) {
    yield put(setLoading<typeof FUNNEL_TEMPLATE_CAMPAIGNS_TABLE>(FUNNEL_TEMPLATE_CAMPAIGNS_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield debounce(
    1000,
    FUNNELTEMPLATE_CAMPAIGN_LIST_LOAD_PAGE_SAGA,
    // $FlowFixMe
    loadFunnelTemplateCampaignListPageSaga
  );
}
