// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import Steps from "../../containers/Steps";
import GeneralFilterInfoStep from "../../containers/GeneralFilterInfoStepV2";
import TargetingsGeneralFilter from "../../containers/TargetingsGeneralFilter";

type Props = {
};

const componentsMapper = {
  "1": GeneralFilterInfoStep,
  "2": TargetingsGeneralFilter,
};

class GeneralFilterForm extends React.Component<Props> {
  campaign: Object;

  render() {
    return (
      <DocumentTitle title="General Filter">
        <Steps getStepContent={(stepKey) => componentsMapper[stepKey]} />
      </DocumentTitle>
    );
  }
}

export default GeneralFilterForm;
