// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import notifications, {
  initNotificationsState,
  type State as NotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import {
  reducer as errors,
  initState as initErrorState,
  type State as ErrorState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import {
  tableReducer as tables,
  initTableState,
  type TableState,
} from "@fas/ui-framework/lib/redux/reducers/table";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import dropdowns, { initDropdownState } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import type { DropdownActions } from "@fas/ui-framework/lib/redux/actions/dropdowns";
import type { DropdownsState } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import antifraudScenarioForm, {
  initialAntifraudScenarioFormState,
  type State as AntifraudScenarioFormState,
} from "../../reducers/antifraudScenarioForm";
import { ANTIFRAUD_TRIGGERS_TABLE } from "../../helpers/constants";
import type { Actions as AntifraudScenarioFormActions } from "../../actions/antifraudScenarioForm";
import type { AntifraudScenarioLoadingTypes } from "../../selectors/antifraudScenarios";
import mainSaga from "./saga";

export type State = $ReadOnly<{|
  loading: LoadingState<AntifraudScenarioLoadingTypes>,
  errors: ErrorState,
  notifications: NotificationsState,
  antifraudScenarioForm: AntifraudScenarioFormState,
  tables: TableState,
  dropdowns: DropdownsState<"dictionaries" | "variables" | "variables-dictionaries" | "variables-variables", string | number>,
|}>

function mapAntifraudScenarioToState(): State {
  return {
    loading: initLoadingState({
      antifraudScenario: false,
      saveAntifraudScenario: false,
    }),
    errors: initErrorState(),
    notifications: initNotificationsState(),
    antifraudScenarioForm: initialAntifraudScenarioFormState,
    tables: initTableState({
      [ANTIFRAUD_TRIGGERS_TABLE]: {
        pageSize: 20,
      },
    }),
    dropdowns: initDropdownState({
      dictionaries: [],
      variables: [],
    }),
  };
}

export type Actions = LoadingActions<AntifraudScenarioLoadingTypes>
  | NotificationsActions
  | AntifraudScenarioFormActions
  | TableActions
  | DropdownActions<"dictionaries" | "variables", string | number>;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  loading,
  errors,
  notifications,
  antifraudScenarioForm,
  tables,
  dropdowns,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapAntifraudScenarioToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
