// @flow
import type { RecordOf, RecordFactory } from "immutable";
import { Record } from "immutable";
import {
  CHANGE_CONFIGURE_FUNNEL_TEMPLATE_REPORT_COLUMNS_MODAL_OPEN,
} from "../../helpers/constants";
import type { Actions } from "../../actions/configureFunnelTemplateReportColumnsModal";

export type DefaultState = {
  isOpen: boolean,
};

export type State = RecordOf<DefaultState>;

const defaultValues: DefaultState = {
  isOpen: false,
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export const initialState: State = makeState({});

export function initConfigureFunnelTemplateReportColumnsModalState() {
  return initialState;
}

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case CHANGE_CONFIGURE_FUNNEL_TEMPLATE_REPORT_COLUMNS_MODAL_OPEN: {
      return state.set("isOpen", action.isOpen);
    }
    default:
      return state;
  }
};
