// @flow
import * as React from "react";
import { Button, Box, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const makeTestAttributeByName = (name: string = "default", suffix: string = "component"): string => {
  const transformedName = name.toLowerCase().replace(" ", "-");
  return `${transformedName}-${suffix}`;
};

type Props = {
  split: string,
  weight: number,
  onWeightChange: (SyntheticInputEvent<HTMLInputElement>) => void,
  isActive: boolean,
  error: boolean,
};

const useStyles = makeStyles((theme) => {
  const btnSize = 32;
  return {
    btn: {
      width: btnSize,
      height: btnSize,
      minWidth: btnSize,
      boxShadow: "none",
      marginRight: theme.spacing(1),
      "&:hover": "none",
    },
  };
});

const inputProps = { min: 0, max: 100 };

const SplitWeightInput = ({
  split,
  weight,
  onWeightChange,
  isActive,
  error,
}: Props) => {
  const classes = useStyles();

  return (
    <Box
      data-testid={makeTestAttributeByName(split, "split-weight-box")}
      display="flex"
      justify="space-between"
      alignItems="center"
    >
      <Button
        disableElevation
        disableTouchRipple
        disableFocusRipple
        disableRipple
        variant="contained"
        color="inherit"
        className={classes.btn}
        data-testid="default-split-weight-button"
      >
        {split}
      </Button>
      <TextField
        variant="outlined"
        size="small"
        value={weight}
        type="number"
        onChange={onWeightChange}
        fullWidth
        disabled={!isActive}
        inputProps={inputProps}
        error={error}
        data-testid="split-weight-input"
      />
    </Box>
  );
};

export default SplitWeightInput;
