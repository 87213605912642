// @flow
import {
  takeEvery,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  OPEN_MODAL_GENERAL_FILTERS_SAGA,
} from "../../helpers/constants";
import { setBackup } from "../../actions/backup";
import { openGeneralFiltersModal } from "../../actions/modals";

export function* openGeneralFiltersSaga(): Saga<void> {
  yield put(setBackup(["generalFilters"]));
  yield put(openGeneralFiltersModal());
}

export default function* watchOpenGeneralFilters(): Saga<void> {
  yield takeEvery(OPEN_MODAL_GENERAL_FILTERS_SAGA, openGeneralFiltersSaga);
}
