// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box,
  Button,
} from "@mui/material";
import { DateRangePickerV2 } from "@fas/ui-core";
import { useSelector, useDispatch } from "react-redux";
import {
  getTableFilters,
} from "@fas/ui-framework/lib/redux/selectors/table";
import { changeTableFilters, changeTableSorting } from "@fas/ui-framework/lib/redux/actions/table";
import { type Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { HISTORY_REPLACE_TABLE } from "../../helpers/constants";
import {
  maxDate, minDate, presets, defaultPreset as defaultHistoryReplacePreset,
} from "./presets";

export type Props = {
  onChangeDate: ({ startDate: string, endDate: string }) => mixed,
  isLoading: boolean,
  filters: Filters,
}

const HistoryReplaceActions: StatelessFunctionalComponent<{}> = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const filters: Filters = useSelector((state: *): Filters => getTableFilters(state, HISTORY_REPLACE_TABLE));
  const { dateFrom, dateTo }: Filters = filters;

  const onChangeDate: ({ startDate: string, endDate: string }) => void = ({
    startDate,
    endDate,
  }) => {
    if (filters.dateFrom !== startDate || filters.dateTo !== endDate) {
      dispatch(changeTableFilters(
        HISTORY_REPLACE_TABLE,
        {
          ...filters,
          dateFrom: startDate,
          dateTo: endDate,
        }
      ));
    }
  };

  const clearFIlters: () => void = () => {
    dispatch(changeTableFilters(HISTORY_REPLACE_TABLE, {
      dateFrom: defaultHistoryReplacePreset.start,
      dateTo: defaultHistoryReplacePreset.end,
    }));
    dispatch(changeTableSorting(HISTORY_REPLACE_TABLE, {}));
  };

  return (
    <Box display="flex" flexDirection="row" sx={{ position: "relative", zIndex: "10", marginBottom: "20px" }}>
      <DateRangePickerV2
        onChangeDate={onChangeDate}
        startDate={String(dateFrom)}
        endDate={String(dateTo)}
        presets={presets}
        minDate={minDate}
        maxDate={maxDate}
      />
      <Button sx={{ marginLeft: "20px" }} onClick={clearFIlters}>
        clear filters
      </Button>
    </Box>
  );
};

export default HistoryReplaceActions;
