// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
  type Element,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import {
  Table,
  SelectSearchComponent,
  ShowMoreItems,
  ViewLink,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import moment from "moment";
import { FUNNELS_TEMPLATE_LIST_TABLE } from "../../helpers/constants";
import { getFunnelsTemplateList, type GetFunnelsTemplateSaga } from "../../actions/funnelsTemplateList";
import type { Props as TableActionsProps } from "../TdsAdminTableActions/types/TdsAdminTableActions.types";
import type { Props, FunnelTemplate } from "./types/FunnelsTemplateList.types";

const columns: Column<FunnelTemplate>[] = [
  {
    field: "id",
    label: "ID",
    sortable: true,
    searchable: true,
  },
  {
    field: "name",
    label: "NAME",
    sortable: true,
    searchable: true,
    render: ({ id, name }: FunnelTemplate): Element<typeof ViewLink> => <ViewLink id={id} name={name} viewPath="/funnelTemplates/view/:id" />,
  },
  {
    field: "type",
    label: "TYPE",
    SearchComponent: (searchProps: SearchComponentProps): Element<typeof SelectSearchComponent> => (
      <SelectSearchComponent
        {...searchProps}
        values={[{
          value: "",
          title: "All",
        }, {
          value: "technical",
          title: "Technical",
        }, {
          value: "other",
          title: "Other",
        }]}
      />
    ),
    sortable: true,
    searchable: true,
  },
  {
    field: "funnels",
    label: "FUNNELS",
    sortable: true,
  },
  {
    field: "campaigns",
    label: "CAMPAIGN",
    render: ({ campaigns }: FunnelTemplate): Element<typeof ShowMoreItems> => <ShowMoreItems data={campaigns} />,
  },
  {
    field: "createdBy",
    label: "MANAGER",
    searchable: true,
  },
  {
    field: "createdAt",
    label: "DATE",
    sortable: true,
    render: ({ createdAt }: FunnelTemplate): string => moment(createdAt).format("YYYY-MM-DD, HH:mm:ss"),
  },
  {
    field: "updatedBy",
    label: "LAST EDITOR",
    searchable: true,
  },
  {
    field: "updatedAt",
    label: "LAST EDIT",
    sortable: true,
    render: ({ updatedAt }: FunnelTemplate): string => moment(updatedAt).format("YYYY-MM-DD, HH:mm:ss"),
  },
];
const allSelectAvailable: () => boolean = () => false;
const compareRows: (FunnelTemplate, FunnelTemplate) => boolean = (current, selected) => current.id === selected.id;

const actionsProps: TableActionsProps = {
  tableKey: FUNNELS_TEMPLATE_LIST_TABLE,
  editPath: "/funnelTemplates/:id",
  getIsEditAvailable: (items: FunnelTemplate[]): boolean => items.length > 1,
  viewPath: "/funnelTemplates/view/:id",
  clonePath: "/funnelTemplates?sourceId=:id",
};

const FunnelsTemplateList: StatelessFunctionalComponent<Props> = ({
  renderActions,
  rowSelectAvailable,
  onCreate,
}: Props) => {
  const tableProps: TableProps = useTable(FUNNELS_TEMPLATE_LIST_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onGetFunnelsTemplateSaga: () => GetFunnelsTemplateSaga = () => dispatch(getFunnelsTemplateList());

  return (
    <Table
      {...tableProps}
      title="Funnels Template List"
      columns={columns}
      rowSelectAvailable={rowSelectAvailable}
      allSelectAvailable={allSelectAvailable}
      onCreate={onCreate}
      onLoad={onGetFunnelsTemplateSaga}
      Actions={renderActions && renderActions({ ...actionsProps })}
      compareRows={compareRows}
    />
  );
};

export default FunnelsTemplateList;
