// @flow
import {
  CLOSE_MODAL_GENERAL_FILTERS_SAGA,
  FETCH_GENERAL_FILTERS_SAGA,
  OPEN_MODAL_GENERAL_FILTERS_SAGA,
  SET_ALL_ACTIVE_GENERAL_FILTERS,
  SET_ACTIVE_GENERAL_FILTERS,
  SET_GENERAL_FILTERS,
  SAVE_MODAL_GENERAL_FILTERS_SAGA,
  FETCH_GENERAL_FILTER_CAMPAIGNS_SAGA,
  GENERAL_FILTER_TABLE,
  CHANGE_GENERAL_FILTER_ACTIVE_SAGA,
  CLONE_GENERAL_FILTER_SAGA,
  SAVE_GENERAL_FILTER_SAGA,
  GET_GENERAL_FILTER_SAGA,
} from "../../helpers/constants";
import type { Setting } from "../../components/FilterSettingsList/types/FilterSettingsList.types";

export type SetAllActiveGeneralFilters = {|
  type: typeof SET_ALL_ACTIVE_GENERAL_FILTERS,
  value: boolean,
|};

export type SetGeneralFilters = {|
  type: typeof SET_GENERAL_FILTERS,
  payload: Array<Setting>,
|};

export type FetchGeneralFilters = {|
  type: typeof FETCH_GENERAL_FILTERS_SAGA,
|};

export type OpenGeneralFiltersModalSaga = {|
  type: typeof OPEN_MODAL_GENERAL_FILTERS_SAGA,
|};

export type SaveGeneralFiltersModalSaga = {|
  type: typeof SAVE_MODAL_GENERAL_FILTERS_SAGA,
|};

export type CloseGeneralFiltersModalSaga = {|
  type: typeof CLOSE_MODAL_GENERAL_FILTERS_SAGA,
|};

export type SetActiveGeneralFiltersAction = {|
  type: typeof SET_ACTIVE_GENERAL_FILTERS,
  index: number,
  value: boolean,
|};

export type FetchGeneralFilterCampaignsSaga = {|
  type: typeof FETCH_GENERAL_FILTER_CAMPAIGNS_SAGA,
|};

export type GetGeneralFilterListSaga = {|
  type: typeof GENERAL_FILTER_TABLE,
|};

export type ChangeGeneralFilterActive = {|
  type: typeof CHANGE_GENERAL_FILTER_ACTIVE_SAGA,
  ids: number[],
  status: boolean,
|};

export type CloneGeneralFilterSaga = {|
  type: typeof CLONE_GENERAL_FILTER_SAGA,
  id: number,
|};

export type SaveGeneralFilterSaga = {|
  type: typeof SAVE_GENERAL_FILTER_SAGA,
|};

export type GetGeneralFilterSaga = {|
  type: typeof GET_GENERAL_FILTER_SAGA,
  id: number,
|};

export type Actions = SetAllActiveGeneralFilters
  | GetGeneralFilterListSaga
  | GetGeneralFilterSaga
  | ChangeGeneralFilterActive
  | CloneGeneralFilterSaga
  | SetGeneralFilters
  | OpenGeneralFiltersModalSaga
  | SaveGeneralFiltersModalSaga
  | CloseGeneralFiltersModalSaga
  | SetActiveGeneralFiltersAction
  | FetchGeneralFilters
  | FetchGeneralFilterCampaignsSaga;

export const setAllActiveGeneralFilters = (value: boolean): SetAllActiveGeneralFilters => ({
  type: SET_ALL_ACTIVE_GENERAL_FILTERS,
  value,
});

export const saveGeneralFiltersModalSaga = (): SaveGeneralFiltersModalSaga => ({
  type: SAVE_MODAL_GENERAL_FILTERS_SAGA,
});

export const setGeneralFilters = (payload: Array<Setting>): SetGeneralFilters => ({
  type: SET_GENERAL_FILTERS,
  payload,
});

export const fetchGeneralFilters = (): FetchGeneralFilters => ({
  type: FETCH_GENERAL_FILTERS_SAGA,
});

export const openGeneralFiltersModalSaga = (): OpenGeneralFiltersModalSaga => ({
  type: OPEN_MODAL_GENERAL_FILTERS_SAGA,
});

export const closeGeneralFiltersModalSaga = (): CloseGeneralFiltersModalSaga => ({
  type: CLOSE_MODAL_GENERAL_FILTERS_SAGA,
});

export const setActiveGeneralFilters = (index: number, value: boolean): SetActiveGeneralFiltersAction => ({
  type: SET_ACTIVE_GENERAL_FILTERS,
  index,
  value,
});

export const fetchGeneralFilterCampaignsSaga: () => FetchGeneralFilterCampaignsSaga = () => ({
  type: FETCH_GENERAL_FILTER_CAMPAIGNS_SAGA,
});

export const getGeneralFilterSaga: (id: number) => GetGeneralFilterSaga = (id) => ({
  type: GET_GENERAL_FILTER_SAGA,
  id,
});

export const getGeneralFilterListSaga: () => GetGeneralFilterListSaga = () => ({
  type: GENERAL_FILTER_TABLE,
});

export const changeGeneralFilterActive = (ids: number[], status: boolean): ChangeGeneralFilterActive => ({
  type: CHANGE_GENERAL_FILTER_ACTIVE_SAGA,
  ids,
  status,
});

export const cloneGeneralFilterSaga = (id: number): CloneGeneralFilterSaga => ({
  type: CLONE_GENERAL_FILTER_SAGA,
  id,
});

export const saveGeneralFilterSaga = (): SaveGeneralFilterSaga => ({
  type: SAVE_GENERAL_FILTER_SAGA,
});
