// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import notifications from "@fas/ui-framework/lib/redux/reducers/notifications";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import { reducer as errors, initState as initErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors";
import { tableReducer as tables, initTableState } from "@fas/ui-framework/lib/redux/reducers/table";
import loading, { initLoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import mainSaga from "./saga";
import tabs from "../../reducers/tabs";
import modals, { initModalsState } from "../../reducers/modals";
import dictionaries from "../../reducers/dictionaries";
import splits, { initSplitsState } from "../../reducers/splits";
import funnels, { initFunnelsState } from "../../reducers/funnels";
import offers, { initOffersState } from "../../reducers/offers";
import targetings, { initTargetingsState } from "../../reducers/targetings";
import funnelTemplateInfo, { initFunnelTempplateInfoState } from "../../reducers/funnelTemplateInfo";
import filterings, { initFilteringsState } from "../../reducers/filterings";
import actionOptionsParams, { initActionOptionsParamsState } from "../../reducers/actionOptionsParams";
import { OFFERS_TABLE, FUNNEL_TEMPLATE_CAMPAIGNS_TABLE } from "../../helpers/constants";

import type { FunnelTemplate } from "../../reducers/funnelTemplateInfo";

function mapTemplateToState(template) {
  return {
    // $FlowFixMe
    modals: initModalsState(template),
    errors: initErrorsState(),
    // $FlowFixMe
    splits: initSplitsState(template),
    funnels: initFunnelsState(template),
    targetings: initTargetingsState(template),
    offers: initOffersState(template),
    funnelTemplateInfo: initFunnelTempplateInfoState(template),
    filterings: initFilteringsState(template),
    // $FlowFixMe
    actionOptionsParams: initActionOptionsParamsState(template),
    loading: initLoadingState({
      [FUNNEL_TEMPLATE_CAMPAIGNS_TABLE]: false,
    }),
    tables: initTableState({
      [OFFERS_TABLE]: { pageSize: 20 },
      [FUNNEL_TEMPLATE_CAMPAIGNS_TABLE]: { pageSize: 20 },
    }),
  };
}

const reducers = combineReducers({
  notifications,
  tabs,
  modals,
  dictionaries,
  errors,
  splits,
  funnels,
  offers,
  targetings,
  funnelTemplateInfo,
  filterings,
  actionOptionsParams,
  loading,
  tables,
});

// eslint-disable-next-line
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  serialize: {
    immutable: Immutable,
  },
}) : compose;

const sagaMiddleware = createSagaMiddleware();

export default (template: FunnelTemplate) => {
  const store = createStore(
    reducers,
    mapTemplateToState(template),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
