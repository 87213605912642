// @flow
import type { StatelessFunctionalComponent, Node } from "react";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";

type ShowByValueProps = { value?: string, targetValue: string, children: Node };

const ShowByValue: StatelessFunctionalComponent<ShowByValueProps> = (
  { targetValue, children, value }
) => targetValue === value && children;

export default withFormField(ShowByValue);
