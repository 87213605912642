// @flow
import {
  GET_ANTIFRAUD_DICTIONARY_SAGA,
  SET_ANTIFRAUD_DICTIONARY,
  SET_ANTIFRAUD_DICTIONARY_FORM_FIELD,
  SAVE_ANTIFRAUD_DICTIONARY_SAGA,
  GET_ANTIFRAUD_DICTIONARY_ACTIONS,
} from "../../helpers/constants";
import type { Dictionary, DictionaryFormData } from "../../selectors/antifraudDictionaries";

export type GetAntifraudDictionarySaga = {
  type: typeof GET_ANTIFRAUD_DICTIONARY_SAGA,
  dictId: number,
}

export type SetAntifraudDictionary = {|
  type: typeof SET_ANTIFRAUD_DICTIONARY,
  payload: Dictionary
|};

export type SetAntifraudDictionaryFormField = {|
  type: typeof SET_ANTIFRAUD_DICTIONARY_FORM_FIELD,
  payload: $Shape<DictionaryFormData>,
|};

export type SaveAntifraudDictionarySaga = {|
  type: typeof SAVE_ANTIFRAUD_DICTIONARY_SAGA,
|};

export type GetAntifraudDictionaryActions = {|
  type: typeof GET_ANTIFRAUD_DICTIONARY_ACTIONS,
|};

export type Actions = GetAntifraudDictionarySaga
  | SetAntifraudDictionary
  | SetAntifraudDictionaryFormField
  | SaveAntifraudDictionarySaga
  | GetAntifraudDictionaryActions;

export const getAntifraudDictionarySaga: (dictId: number) => GetAntifraudDictionarySaga = (dictId) => ({
  type: GET_ANTIFRAUD_DICTIONARY_SAGA,
  dictId,
});

export const setAntifraudDictionary: (payload: Dictionary) => SetAntifraudDictionary = (payload) => ({
  type: SET_ANTIFRAUD_DICTIONARY,
  payload,
});

export const setAntifraudDictionaryFormField: (
  payload: $Shape<DictionaryFormData>
) => SetAntifraudDictionaryFormField = (payload) => ({
  type: SET_ANTIFRAUD_DICTIONARY_FORM_FIELD,
  payload,
});

export const saveAntifraudDictionarySaga: () => SaveAntifraudDictionarySaga = () => ({
  type: SAVE_ANTIFRAUD_DICTIONARY_SAGA,
});

export const getAntifraudDictionaryActions: () => GetAntifraudDictionaryActions = () => ({
  type: GET_ANTIFRAUD_DICTIONARY_ACTIONS,
});
