// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { connect } from "react-redux";
import type { Map, List } from "immutable";
import {
  Container,
  Box,
} from "@mui/material";
import { StepControl, ConfirmDialog } from "@fas/ui-core";
import { stepClick } from "@fas/ui-framework/lib/redux/actions/steps";
import CampaignPreview from "../../components/CampaignPreview";
import {
  getDomain,
  getCampaignName,
  getCampaignExternalName,
  getCampaignProtocol,
  getCampaignPath,
  getPlaceholders,
  getShortUrlId,
  getShortUrlTime,
  getNonTargetOffers,
  getOffersByIds,
  getFallbackOffers,
  getManualTags,
  getCampaignId,
  getCurrentStepId,
  getIsConfirmDialogOpen,
  getCurrentConfirmDialogMessage,
  getIsCampaignSaveLoading,
} from "../../selectors/smartlink";
import { addManualTag, removeManualTag } from "../../actions/manualTags";
import { saveCampaignSaga } from "../../actions/campaign";
import {
  setConfirmNotificationCanceled,
  setConfirmNotificationConfirmed,
} from "../../actions/confirmation";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  loadingSaveCampaign: boolean,
  isConfirmDialogOpen: boolean,
  currentConfirmatioMessage: string,
  handleOnConfirmDialog: () => void,
  handleOnCancelDialog: () => void,
  campaignName: string,
  campaignExternalName: string,
  campaignId: string,
  protocol: string,
  domain: string,
  path: string,
  placeholders: Map,
  shortUrlId: string,
  shortUrlTime: string,
  nonTarget: Map,
  fallback: Map,
  tags: List,
  handleAddManualTag: (value: { label: string, value: string}) => void,
  handleRemoveManualTag: (value: string) => void,
  currentStep: string,
  handleStepClick: (stepId: string) => void,
  handleCampaignSave: (cb:Function) => void,
|}>;

const mapStateToProps = (state) => {
  const campaignName = getCampaignName(state);
  const campaignExternalName = getCampaignExternalName(state);
  const protocol = getCampaignProtocol(state);
  const path = getCampaignPath(state);
  const domain = getDomain(state);
  const placeholders = getPlaceholders(state);
  const shortUrlId = getShortUrlId(state);
  const shortUrlTime = getShortUrlTime(state);
  const nonTargetOffersId = getNonTargetOffers(state);
  const fallbackOffersId = getFallbackOffers(state);
  const nonTargetOffers = getOffersByIds(state, nonTargetOffersId);
  const fallbackOffers = getOffersByIds(state, fallbackOffersId);
  const campaignId = getCampaignId(state);
  const manualTags = getManualTags(state);

  return {
    campaignName,
    campaignExternalName,
    protocol,
    domain,
    path,
    placeholders,
    shortUrlId,
    shortUrlTime,
    nonTarget: nonTargetOffers,
    fallback: fallbackOffers,
    campaignId,
    tags: manualTags,
    currentStep: getCurrentStepId(state),
    isConfirmDialogOpen: getIsConfirmDialogOpen(state),
    currentConfirmatioMessage: getCurrentConfirmDialogMessage(state),
    loadingSaveCampaign: getIsCampaignSaveLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleAddManualTag: (tag) => dispatch(addManualTag(tag)),
  handleRemoveManualTag: (tag) => dispatch(removeManualTag(tag)),
  handleStepClick: (stepId) => dispatch(stepClick(stepId)),
  handleCampaignSave: () => dispatch(saveCampaignSaga()),
  handleOnConfirmDialog: () => dispatch(setConfirmNotificationConfirmed()),
  handleOnCancelDialog: () => dispatch(setConfirmNotificationCanceled()),
});

const getPreviousStepId = (currentStep: string): string => (Number(currentStep) - 1).toString();

const CampaignPreviewStep = ({
  campaignName,
  campaignExternalName,
  campaignId,
  protocol,
  domain,
  path,
  placeholders,
  shortUrlId,
  shortUrlTime,
  nonTarget,
  fallback,
  tags,
  handleAddManualTag,
  handleRemoveManualTag,
  currentStep,
  handleStepClick,
  handleCampaignSave,
  currentConfirmatioMessage,
  isConfirmDialogOpen,
  handleOnConfirmDialog,
  handleOnCancelDialog,
  loadingSaveCampaign,
}: Props) => (
  <Container maxWidth="md">
    <CampaignPreview
      campaignName={campaignName}
      campaignExternalName={campaignExternalName}
      campaignId={campaignId}
      protocol={protocol}
      domain={domain}
      path={path}
      placeholders={placeholders.toJS()}
      shortUrlId={shortUrlId}
      shortUrlTime={shortUrlTime}
      nonTarget={nonTarget.valueSeq().toJS()}
      fallback={fallback.valueSeq().toJS()}
      tags={tags.toJS()}
      handleAddManualTag={handleAddManualTag}
      handleRemoveManualTag={handleRemoveManualTag}
    />
    <Box mt={3} display="flex" justifyContent="space-between">
      <StepControl
        showBackButton
        nextButtonText="Save"
        onBack={() => handleStepClick(getPreviousStepId(currentStep))}
        onNext={handleCampaignSave}
        isLoading={loadingSaveCampaign}
      />
      <ConfirmDialog
        open={isConfirmDialogOpen}
        message={currentConfirmatioMessage}
        onMainBtnClick={handleOnConfirmDialog}
        onSecondaryBtnClick={handleOnCancelDialog}
      />
    </Box>
  </Container>
);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(CampaignPreviewStep);
