/* eslint-disable import/max-dependencies */
// @flow
import { Map, isImmutable } from "immutable";
import merge from "lodash.merge";
import type { List } from "immutable";
import BaseType from "./Base";
import type { Errors } from "./Base";
import {
  getOffersByFunnelId,
  getOffersByIds,
  getCampaignName,
  getDomain,
  getNonTargetOffers,
  getFallbackOffers,
  getSplitsById,
  getActiveSplitsById,
  getFrequencyMethod,
  getSplitsCount,
  getFrequency,
  getActionOptionsParamsByFunnelId,
  getActionOptionsParamsById,
  getFilteringsByFunnelId,
  getFilteringsByIds,
  getRequestLimitPerSec,
  getFilteringsByIdsV2,
  getPlaceholders,
  getAffiliateDataType,
} from "../../../selectors/smartlink";
import OffersValidator from "../rules/offers";
import DomainValidator from "../rules/domain";
import CampaignNameValidator from "../rules/campaignName";
import NonTargetOffers from "../rules/nonTargetOffers";
import FallbackOffers from "../rules/fallbackOffers";
import Splits from "../rules/splits";
import SplitsWeights from "../rules/splitsWeights";
import SplitsFrequencyWeights from "../rules/splitsFrequencyWeights";
import SplitsDistribution from "../rules/splitsDistribution";
import Targetings from "../rules/targeting";
import SmartlinkActionOptionsParams from "../rules/actionOptionsParams";
import Filterings from "../rules/filterings";
import RequestLimitPerSec from "../rules/requestLimitPerSec";
import PlaceholdersValidator from "../rules/placeholders";
import TargetingsGroup from "../rules/targetingGroup";
import AffiliateDataType from "../rules/affiliateDataType";

export default class Smartlink extends BaseType {
  validateFunnel(state: Map<{}>, action: any): Errors {
    const { funnelId } = action;

    const offersErrors = this._validateOffers(state, funnelId);
    const actionOptionsParamsErrors = this._getOptionsParamsErrors(state, funnelId);
    const filteringsError = this.validateFilteringsV2(state, funnelId);
    return Object.assign({}, offersErrors, actionOptionsParamsErrors, filteringsError);
  }

  _getOptionsParamsErrors(state: Map<{}>, funnelId: string) {
    const [actionOptionsParamsId] = getActionOptionsParamsByFunnelId(state, funnelId);
    const actionOptionsParams = getActionOptionsParamsById(state, actionOptionsParamsId);
    const isHaveFiltering = getFilteringsByFunnelId(state, funnelId).size > 0;

    return new SmartlinkActionOptionsParams().getErrors({
      ...(isImmutable(actionOptionsParams) ? actionOptionsParams.toJS() : {}),
      isHaveFiltering,
    });
  }

  validateTargeting(targetingSchema: any, rulesDictionary: List) {
    const targetingRules = Object.values(targetingSchema).filter((rule: any) => rule.type === "rule");

    return new Targetings(rulesDictionary).getErrors(targetingRules);
  }

  validateTargetingV2(targetingSchema: any, rulesDictionary: List) {
    const targetingRules = [];
    const targetingGroups = [];

    Object.keys(targetingSchema).forEach((ruleId) => {
      if (targetingSchema[ruleId].type === "rule") {
        targetingRules.push({ id: ruleId, ...targetingSchema[ruleId] });
      }
      else {
        targetingGroups.push({ id: ruleId, ...targetingSchema[ruleId] });
      }
    });

    const errorsRule = new Targetings(rulesDictionary).getErrorsV2(targetingRules);
    const errorsGroup = new TargetingsGroup().getErrors(targetingGroups);

    return merge(errorsRule, errorsGroup);
  }

  _validateOffers(state: Map<{}>, funnelId: string): Errors {
    const offersIds = getOffersByFunnelId(state, funnelId);
    const offers = getOffersByIds(state, offersIds);

    return new OffersValidator().getErrors(offers);
  }

  _validateCampaignInfoStep(state: Map<{}>): Errors {
    const name = getCampaignName(state);
    const affiliateDataType = getAffiliateDataType(state);
    const domain = getDomain(state);
    const requestLimitPerSec = getRequestLimitPerSec(state);
    const placeholders = getPlaceholders(state);

    const campaignNameErrors = new CampaignNameValidator().getErrors(name);
    const affiliateDataTypeErrors = new AffiliateDataType().getErrors(affiliateDataType);
    const domainErrors = new DomainValidator().getErrors(domain);
    const requestLimitPerSecErrors = new RequestLimitPerSec().getErrors(requestLimitPerSec);
    const placeholdersErrors = new PlaceholdersValidator().getErrors(placeholders);

    return Object.assign({},
      domainErrors,
      campaignNameErrors,
      requestLimitPerSecErrors,
      placeholdersErrors,
      affiliateDataTypeErrors);
  }

  _validateServiceOffersStep(state: Map<{}>): Errors {
    const nonTargetOffersId = getNonTargetOffers(state);
    const fallbackOffersId = getFallbackOffers(state);
    const nonTargetOffers = getOffersByIds(state, nonTargetOffersId);
    const fallbackOffers = getOffersByIds(state, fallbackOffersId);

    const fallbackOffersErrors = new FallbackOffers().getErrors(fallbackOffers);
    const nonTargetOffersErrors = new NonTargetOffers().getErrors(nonTargetOffers);

    return Object.assign({}, nonTargetOffersErrors, fallbackOffersErrors);
  }

  _validateSplitsFunnelsStep(state: Map<{}>): Errors {
    const splits = getSplitsById(state);
    const activeSplits = getActiveSplitsById(state);
    const splitsCount = getSplitsCount(state);
    const method = getFrequencyMethod(state);
    const frequency = getFrequency(state);

    let splitsErrors = new Splits().getErrors(splits);
    let frequencyWeightsErrors = {};
    const frequencyErrors = new SplitsDistribution().getErrors(frequency);

    if (Object.keys(splitsErrors).length) {
      splitsErrors = { splits: splitsErrors };
    }
    if (method === "weights" && splitsCount > 1) {
      frequencyWeightsErrors = new SplitsWeights().getErrors(activeSplits);
    }
    if (method === "frequency" && splitsCount > 1) {
      frequencyWeightsErrors = new SplitsFrequencyWeights().getErrors(splits);
    }

    return Object.assign({}, splitsErrors, frequencyWeightsErrors, frequencyErrors);
  }

  validateFilterings(state: Map<{}>, funnelId:string):Errors {
    const targetingsList = state.dictionaries.get("targetingRules").toJS();
    const filteringsId = getFilteringsByFunnelId(state, funnelId).toJS();
    const filterings = getFilteringsByIds(state, filteringsId).toList().toJS();
    const errors = new Filterings(targetingsList).getErrors(filterings);
    return errors;
  }

  validateFilteringsV2(state: Map<{}>, funnelId:string):Errors {
    const targetingsList = state.dictionaries.get("targetingRules").toJS();
    const filteringsId = getFilteringsByFunnelId(state, funnelId).toJS();
    const filterings = getFilteringsByIdsV2(state, filteringsId);
    const errors = new Filterings(targetingsList).getErrorsV2(filterings);
    return errors;
  }
}
