// @flow
import React from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import FrequencyCapForJumps from "../../containers/FrequencyCapForJumps";
import AddFrequencyCapForJump from "../../containers/AddFrequencyCapForJump";
import NotificationsContainer from "../../components/Notification";

const store = configureStore();

function FrequencyCupForJumpsPage() {
  return (
    <DocumentTitle title="Frequency Cap For Jumps">
      <Provider store={store}>
        <AddFrequencyCapForJump />
        <FrequencyCapForJumps />
        <NotificationsContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default FrequencyCupForJumpsPage;
