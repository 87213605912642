// @flow
import {
  Map, List,
} from "immutable";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import {
  ADD_TARGETING,
  DELETE_TARGETING,
  CHANGE_TARGETING_GROUP_OPERATOR,
  ADD_TARGETING_RULE,
  ADD_TARGETING_GROUP,
  REMOVE_TARGETING_GROUP,
  CHANGE_TARGETING_VALUE,
  CHANGE_TARGETING_OPERATOR,
  CHANGE_TARGETING_RULE,
  REMOVE_TARGETING_RULE,
} from "../../helpers/constants";

export type Targeting = {

};

export type TargetingsState = {
  byId: Map<{
    [key: string]: Map<Targeting>
  }>,
  allIds: List<string>
}

export type State = TargetingsState;

const emptySelectedRuleV2 = {
  type: "rule",
  name: "",
  operator: "",
  value: [],
};

const emptyRuleV2 = {
  operators: [],
  name: {
    value: "",
    label: "",
  },
};

const emptyGroup = {
  type: "group",
  groupOperator: "AND",
  targetings: [],
  parentGroup: null,
};

export const initialState: Map<State> = Map({
  byId: Map({}),
  allIds: List([]),
});

export function initTargetingsState(data: { targetings: TargetingsState }) {
  const { targetings } = data;

  if (targetings) {
    return initialState.withMutations((newState) => {
      Object.keys(targetings.byId).forEach((key) => {
        newState.setIn(["byId", key], targetings.byId[key]);
      });

      newState.set("allIds", List(targetings.allIds));
    });
  }

  return initialState;
}

export default (state: Map<State> = initialState, action: any) => {
  switch (action.type) {
    case ADD_TARGETING: {
      const {
        targetingSchema,
      } = action;
      const allIds = state.get("allIds");
      let nextTargetingId = Number(getNextId(allIds));

      const targetingSchemaKeys = Object.keys(targetingSchema);

      return state.withMutations((newState) => {
        targetingSchemaKeys.forEach((key) => {
          newState.setIn(["byId", nextTargetingId.toString()], targetingSchema[key]);
          newState.updateIn(["allIds"], (ids) => ids.push(nextTargetingId.toString()));
          nextTargetingId++;
        });
      });
    }
    case DELETE_TARGETING: {
      const { targetingsIds } = action;
      return state.withMutations((newState) => {
        targetingsIds.forEach((id) => {
          newState.deleteIn(["byId", id]);
          newState.deleteIn([
            "allIds",
            newState.get("allIds").findIndex((el) => el === id),
          ]);
        });
      });
    }
    case CHANGE_TARGETING_GROUP_OPERATOR: {
      const { id, val } = action;
      return state.withMutations((newState) => {
        newState.setIn(["byId", id, "groupOperator"], val);
      });
    }
    case ADD_TARGETING_RULE: {
      const { groupId } = action;
      return state.withMutations((newState) => {
        const newId = getNextId(newState.get("allIds"));
        newState.setIn(["byId", newId], emptySelectedRuleV2);
        newState.updateIn(["byId", groupId, "targetings"], (targetingsIds) => [...targetingsIds, newId]);
        newState.updateIn(["allIds"], (allIds) => allIds.push(newId));
      });
    }
    case ADD_TARGETING_GROUP: {
      const { groupId } = action;
      return state.withMutations((newState) => {
        const newId = getNextId(newState.get("allIds"));
        newState.setIn(["byId", newId], { ...emptyGroup, parentGroup: groupId });
        newState.updateIn(["byId", groupId, "targetings"], (targetingsIds) => [...targetingsIds, newId]);
        newState.updateIn(["allIds"], (allIds) => allIds.push(newId));
      });
    }
    case REMOVE_TARGETING_GROUP: {
      const { groupId } = action;
      return state.withMutations((newState) => {
        const removedGroup = newState.getIn(["byId", groupId]);

        const targetingIds = newState.getIn(["byId", groupId, "targetings"]);
        targetingIds.forEach((id) => {
          newState.deleteIn(["byId", id]);
          newState.deleteIn([
            "allIds",
            newState.get("allIds").findIndex((el) => el === Number(id)),
          ]);
        });
        newState.deleteIn(["byId", groupId]);
        newState.deleteIn([
          "allIds",
          newState.get("allIds").findIndex((el) => el === Number(groupId)),
        ]);

        newState.deleteIn([
          "byId",
          removedGroup.parentGroup,
          "targetings",
          newState.getIn(["byId", removedGroup.parentGroup, "targetings"]).findIndex((el) => Number(el) === Number(groupId))]);
      });
    }
    case CHANGE_TARGETING_VALUE: {
      const { ruleId, values } = action;
      return state.withMutations((newState) => {
        newState.setIn(["byId", ruleId, "value"], values);
      });
    }
    case CHANGE_TARGETING_OPERATOR: {
      const { ruleId, operator } = action;

      return state.withMutations((newState) => {
        newState.setIn(["byId", ruleId, "operator"], operator.value);
      });
    }
    case CHANGE_TARGETING_RULE: {
      const { ruleId, rule } = action;
      const newRule = rule || emptyRuleV2;
      return state.withMutations((newState) => {
        newState.setIn(["byId", ruleId], {
          type: "rule",
          name: newRule.name.value,
          operator: newRule.operators[0],
          value: [],
        });
      });
    }
    case REMOVE_TARGETING_RULE: {
      const { targetingId, groupId } = action;

      return state.withMutations((newState) => {
        newState.deleteIn(["byId", targetingId]);
        newState.deleteIn([
          "allIds",
          newState.get("allIds").findIndex((el) => el === targetingId),
        ]);
        newState.deleteIn([
          "byId",
          groupId,
          "targetings",
          newState.getIn(["byId", groupId, "targetings"]).findIndex((el) => Number(el) === Number(targetingId)),
        ]);
      });
    }
    default:
      return state;
  }
};
