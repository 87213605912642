// @flow
import React, {
  type Node,
  type StatelessFunctionalComponent,
  useEffect,
  useState,
} from "react";
import { CopyToClipboardInput } from "@fas/ui-core";
import {
  Box,
  Dialog,
  Divider,
  Grid,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import PlaceholderValue from "../PlaceholdersList/PlaceholderValue";
import { getRenamedPlaceholders, placeholderToIncomeUrl } from "../../helpers/placeholders";
import type { PlaceholderType } from "../../helpers/placeholders";
import type { UseState, Props } from "./types/RenamePlaceholderUrlBuilder.types";

/**
 * To rename placeholders,
 * this component will need to be removed
 * when other services learn to work with standard placeholders.
 * @returns {StatelessFunctionalComponent<Props>} component
 */
const RenamePlaceholderUrlBuilder: StatelessFunctionalComponent<Props> = ({
  placeholders,
  manualPlaceholders,
  getUrl,
  ButtonOpen = Button,
}: Props) => {
  const [open, setOpen]: UseState<boolean> = useState(false);
  const [renamedPlaceholders, setRenamedPlaceholders]: UseState<PlaceholderType> = useState<PlaceholderType>({});

  useEffect(() => {
    setRenamedPlaceholders(getRenamedPlaceholders(placeholders, manualPlaceholders));
  }, [manualPlaceholders, placeholders]);

  const manualValueKeys: string[] = Object.keys(placeholders)
    // eslint-disable-next-line no-prototype-builtins
    .filter((key: string): boolean => manualPlaceholders.hasOwnProperty(key))
    .map((key: string): string => manualPlaceholders[key].replace(/[{}]/g, ""));

  const isSomeEmpty: boolean = Object.keys(renamedPlaceholders)
    .some((key: string): boolean => !renamedPlaceholders[key]);

  return (
    <>
      <ButtonOpen data-testid="generateButton" variant="contained" color="primary" onClick={(): void => setOpen(true)}>
        Generate campaign URL
      </ButtonOpen>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={(): void => setOpen(false)}
        data-testid="configure-url-builder-modal"
      >
        <DialogTitle>Generate campaign url</DialogTitle>
        <Divider />
        <DialogContent>
          {Object.keys(placeholders)
            .filter((key: string): boolean => key !== "tds_campaign" && key !== "tdsId")
            .length === 0 ? (
              <pre>You must have at least one placeholder.</pre>
            ) : (
              <>
                <Box pt={1}>
                  <CopyToClipboardInput
                    title="Campaign url"
                    value={getUrl(placeholderToIncomeUrl(renamedPlaceholders))}
                    error={isSomeEmpty}
                    disable="true"
                    style={{ pointerEvents: isSomeEmpty ? "none" : "unset" }}
                    fullWidth
                    data-testid="copy-to-clipboard-url-container"
                  />
                </Box>
                <Box component={Grid} container spacing={2} pt={2}>
                  {
                    manualValueKeys.map((key: string): Node => (
                      <Box component={Grid} item key={key}>
                        <PlaceholderValue
                          name={key}
                          onChange={(value: string): void => setRenamedPlaceholders((
                            p: PlaceholderType
                          ): PlaceholderType => ({
                            ...p,
                            [key]: value,
                          }))}
                          value={renamedPlaceholders[key]}
                          error={!renamedPlaceholders[key] ? "Renamed placeholder" : ""}
                        />
                      </Box>
                    ))
                  }
                </Box>
              </>
            )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={(): void => setOpen(false)}
            color="primary"
            variant="contained"
            data-testid="back-modal-button"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RenamePlaceholderUrlBuilder;
