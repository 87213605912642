// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import { Steps } from "@fas/ui-core";
import { OrderedMap } from "immutable";
import makeStyles from "@mui/styles/makeStyles";
import { stepClick } from "@fas/ui-framework/lib/redux/actions/steps";
import type { Step } from "@fas/ui-framework/lib/redux/reducers/steps";
import {
  getSteps,
  getCurrentStepId,
} from "../../selectors/smartlink";

type OwnProps = $ReadOnly<{|
  getStepContent: (step: string) => StatelessFunctionalComponent<{}>,
|}>;

type Props = {
  ...OwnProps,
  items: OrderedMap<Step>,
  selected: string,
  handleStepClick: (step: string) => void,
};

const mapStateToProps = (state) => ({
  items: getSteps(state),
  selected: getCurrentStepId(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleStepClick: (id) => dispatch(stepClick(id)),
});

const useStyles = makeStyles((theme) => ({
  containerSteps: {
    height: "calc(100% - 64px)",
  },
  contentSteps: {
    backgroundColor: theme.palette.background.mainBg,
  },
}));

const StepsContainer = ({
  items,
  selected,
  handleStepClick,
  getStepContent,
}: Props) => (
  <Steps
    items={items.valueSeq().toJS()}
    selected={Number(selected) - 1}
    onClick={(id) => handleStepClick(String(id + 1))}
    getStepContent={(id) => getStepContent(String(id + 1))}
    classes={useStyles()}
  />
);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(StepsContainer);
