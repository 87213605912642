// @flow
import React from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import NotificationsContainer from "../../components/Notification";
import AntifraudScenarioForm from "../../containers/AntifraudScenarioForm";

const store = configureStore();

function AntifraudScenarioFormPage() {
  return (
    <DocumentTitle title="Antifraud - Scenario form">
      <Provider store={store}>
        <AntifraudScenarioForm />
        <NotificationsContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default AntifraudScenarioFormPage;
