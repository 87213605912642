// @flow
import React from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import AntifraudVariableFormContainer from "../../components/AntifraudVariableForm";
import NotificationsContainer from "../../components/Notification";

const store = configureStore();

function AntifraudVariableForm() {
  return (
    <DocumentTitle title="Antifraud - Variables">
      <Provider store={store}>
        <AntifraudVariableFormContainer />
        <NotificationsContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default AntifraudVariableForm;
