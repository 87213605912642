// @flow
import React from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import AntifraudScenariosList from "../../containers/AntifraudScenariosList";
import NotificationsContainer from "../../components/Notification";

const store = configureStore();

function AntifraudScenarios() {
  return (
    <DocumentTitle title="Antifraud - Scenarios">
      <Provider store={store}>
        <AntifraudScenariosList />
        <NotificationsContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default AntifraudScenarios;
