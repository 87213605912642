// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  GET_SHORT_URL_SAGA,
} from "../../helpers/constants";
import { setShortUrl } from "../../actions/campaignInfo";
import type { GetShortUrlSaga } from "../../actions/campaignInfo";
import CampaignApi from "../../services/smartlinkApi";

export function* makeFetch({ campaignId }: GetShortUrlSaga): Saga<void> {
  try {
    yield put(setLoading(GET_SHORT_URL_SAGA, true));
    const { data: { shortUrlId, shortUrlTime } } = yield call(CampaignApi.getShortUrl, campaignId);

    yield put(setShortUrl(shortUrlId, shortUrlTime));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
  }
  finally {
    yield put(setLoading(GET_SHORT_URL_SAGA, false));
  }
}

export default function* watchGetShortUrl(): Saga<void> {
  yield takeEvery(GET_SHORT_URL_SAGA, (makeFetch: Function));
}
