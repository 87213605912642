// @flow
import { Map, List, fromJS } from "immutable";
import type { FilteringActions } from "../../actions/filterings";
import {
  ADD_FILTERING,
  CHANGE_FILTERING_RULE,
  CHANGE_FILTERING_RULE_OFFER,
  CHANGE_FILTERING_RULE_OFFER_V2,
  CHANGE_FILTERING_RULE_ONE_LINK_OFFER,
  CHANGE_FILTERING_RULE_VALUE,
  CHANGE_FILTERING_RULE_VALUE_V2,
  DELETE_FILTERING,
} from "../../helpers/constants";
import type { Offer } from "../../components/FilteringList/types/FilteringList.types";

function parseOffer(campaign) {
  if (!campaign) {
    return { id: "", url: "" };
  }
  const {
    id,
    params,
  } = campaign;
  return {
    id,
    url: `${params.path}?tdsId=${id}_r&tds_campaign=${id}&${params.incomeUrl}`,
  };
}

export type Filtering = {|
  name: string,
  value: Array<string>,
  offer: Offer,
  operator: string,
  type: string,
|};

export const defaultFiltering: Filtering = {
  name: "",
  value: [],
  operator: "equal",
  type: "rule",
  offer: {
    id: "",
    url: "",
  },
};

export type FilteringsState = Map<{
  byId: Map<{
    [key: string]: Map<Filtering>
  }>,
  allIds: List<string>
}>;

export type State = FilteringsState;

export const initialState: Map<State> = Map({
  byId: Map({}),
  allIds: List([]),
});

export function initFilteringsState(data: { filterings: FilteringsState }) {
  const { filterings } = data;
  const state = filterings ? fromJS(filterings) : initialState;
  return state;
}

type Action = FilteringActions

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case ADD_FILTERING: {
      const { filtering = defaultFiltering, filteringId } = action;
      return state.withMutations((newState) => {
        newState.setIn(["byId", filteringId], fromJS(filtering));
        newState.updateIn(["allIds"], (allIds) => allIds.push(filteringId));
      });
    }
    case DELETE_FILTERING: {
      const { filteringId } = action;
      return state.withMutations((newState) => {
        newState.deleteIn([
          "allIds",
          newState.get("allIds").findIndex((el) => el === filteringId),
        ]);
        newState.deleteIn(["byId", filteringId]);
      });
    }
    case CHANGE_FILTERING_RULE: {
      const { filteringId, name } = action;
      return state.withMutations((newState) => {
        newState.setIn(["byId", filteringId, "name"], name);
        newState.setIn(["byId", filteringId, "value"], []);
      });
    }
    case CHANGE_FILTERING_RULE_VALUE: {
      const { filteringId, value } = action;
      const actualValues = value.map((x) => (x.value ? x.value : x));
      return state.setIn(["byId", filteringId, "value"], actualValues);
    }
    case CHANGE_FILTERING_RULE_VALUE_V2: {
      const { filteringId, value } = action;
      const actualValues = value.map((x) => (x.value ? x.value : x));
      return state.setIn(["byId", filteringId, "value"], actualValues);
    }
    case CHANGE_FILTERING_RULE_OFFER: {
      const { filteringId, offer } = action;
      return state.setIn(["byId", filteringId, "offer"], offer);
    }
    case CHANGE_FILTERING_RULE_OFFER_V2: {
      const { filteringId, rule } = action;
      return state.setIn(["byId", filteringId, "offer"], Map(parseOffer(rule)));
    }
    case CHANGE_FILTERING_RULE_ONE_LINK_OFFER: {
      const { filteringIds, offer } = action;
      return state.withMutations((newState) => {
        filteringIds.forEach((filteringId) => newState.setIn(["byId", filteringId, "offer"], Map(offer)));
      });
    }
    default:
      return state;
  }
};
