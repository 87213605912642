// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Table } from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { FREQUENCY_CAP_FOR_JUMPS_TABLE, IS_CHANGE_IS_FREQUENCY_CAP_LOADING } from "../../helpers/constants";
import {
  getFrequencyCapForListSaga,
  changeIsFrequencyCap,
  type GetFrequencyCapForListSaga,
  type ChangeIsFrequencyCap,
} from "../../actions/frequencyCapForJumps";
import type { FrequencyCapForJumps, Classes } from "./FrequencyCapForJumps.types";
import getColumns from "./getColumns";
import TableActions from "./TableActions";
import { useLoading } from "../../hooks";

const useStyles: () => Classes = makeStyles(() => ({
  smallTableCol: {
    minWidth: "50px",
    maxWidth: "50px",
    wordBreak: "break-all",
  },
  middleTableCol: {
    minWidth: "100px",
    maxWidth: "100px",
    wordBreak: "break-all",
  },
}));

const FrequencyCapForJumpsList: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();
  const tableProps: TableProps = useTable(FREQUENCY_CAP_FOR_JUMPS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onGetFrequencyCapForListSaga: () => GetFrequencyCapForListSaga = () => (
    dispatch(getFrequencyCapForListSaga()));

  const onChangeIsFrequencyCap: (
    { items: FrequencyCapForJumps[], isFrequencyCap: boolean }
  ) => ChangeIsFrequencyCap = (payload) => (
    dispatch(changeIsFrequencyCap(payload)));

  const columns: Array<Column<FrequencyCapForJumps>> = getColumns(classes);
  const isLoading = useLoading(FREQUENCY_CAP_FOR_JUMPS_TABLE, IS_CHANGE_IS_FREQUENCY_CAP_LOADING);

  return (
    <Box px={3} pb={3}>
      <Table
        {...tableProps}
        title="Frequency Cap For Jumps"
        columns={columns}
        isLoading={isLoading}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onLoad={onGetFrequencyCapForListSaga}
        Actions={(
          <TableActions
            items={tableProps.selected}
            onChangeIsFrequencyCap={onChangeIsFrequencyCap}
          />
        )}
      />
    </Box>
  );
};

export default FrequencyCapForJumpsList;
