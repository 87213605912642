// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { Provider } from "react-redux";
import { CircularProgress, Grid, Box } from "@mui/material";
import DocumentTitle from "react-document-title";
import queryString from "query-string";
import Notification from "../../components/Notification";
import configureStore from "./store";
import TemplateApi from "../../services/templateApi";
import Steps from "../../containers/Steps";
import TemplateGeneralInfoStep from "../../containers/TemplateGeneralInfoStep";
import TemplatePreviewStep from "../../containers/TemplatePreviewStep";
import TemplateFunnelsStep from "../../containers/TemplateFunnelsStep";

type Props = {
  location: Object,
  navigate: Object,
  params: Object,
};

type State = {
  isLoading: boolean
};

const componentsMapper = {
  "1": TemplateGeneralInfoStep,
  "2": TemplateFunnelsStep,
  "3": TemplatePreviewStep,
};

class FunnelTemplates extends React.Component<Props, State> {
  template: Object;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
    };
    this.template = {};
  }

  componentDidMount() {
    const { params, navigate, location } = this.props;
    const { sourceId } = queryString.parse(location.search);

    const id = params.id || sourceId;

    if (!id) {
      this.setState({ isLoading: false });
      return;
    }

    TemplateApi.getTemplateById(id)
      .then(({ data: response }) => {
        this.template = response.data;
        if (sourceId && !params.id) {
          this.template.campaignInfo.id = "";
        }
        this.setState({ isLoading: false });
      })
      .catch(() => navigate("/error"));
  }

  render() {
    const { isLoading } = this.state;
    const { params } = this.props;

    return (
      <DocumentTitle title={params.id ? "Update template" : "Create template"}>
        <>
          {isLoading && (
            <Grid container justify="center">
              <Box mt={40}>
                <CircularProgress />
              </Box>
            </Grid>
          )}
          {!isLoading && (
            <Provider store={configureStore(this.template)}>
              <Steps getStepContent={(stepKey) => componentsMapper[stepKey]} />
              <Notification />
            </Provider>
          )}
        </>
      </DocumentTitle>
    );
  }
}

export default FunnelTemplates;
