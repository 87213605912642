// @flow
import {
  FUNNELTEMPLATE_CAMPAIGN_LIST_LOAD_PAGE_SAGA,
} from "../../helpers/constants";

export type FunnelTemplateCampaignListLoadPageAction = {
  type: typeof FUNNELTEMPLATE_CAMPAIGN_LIST_LOAD_PAGE_SAGA,
}

export type TemplateCampaignsActionType = FunnelTemplateCampaignListLoadPageAction;

export const funnelTemplateCampaignListLoadPage = (): FunnelTemplateCampaignListLoadPageAction => ({
  type: FUNNELTEMPLATE_CAMPAIGN_LIST_LOAD_PAGE_SAGA,
});
