// @flow
import { fromJS, Map } from "immutable";
import {
  CHANGE_TEMPLATE_NAME,
  CHANGE_TEMPLATE_TYPE,
  SET_FETCHED_CAMPAIGN_ID,
  CAMPAIGN_TYPE_TEMPLATE,
} from "../../helpers/constants";
import type {
  ChangeTemplateNameAction,
  ChangeTemplateTypeAction,
} from "../../actions/templateInfo";
import type { SetCampaignIdAction } from "../../actions/campaignInfo/actions";

export type TemplateInfoState = {
  id: string,
  name: string,
  type: typeof CAMPAIGN_TYPE_TEMPLATE,
  templateType: string,
};

export const initialState: Map<TemplateInfoState> = Map({
  id: "",
  name: "",
  type: CAMPAIGN_TYPE_TEMPLATE,
  templateType: "",
});

export function initTemplateInfoState(data: { campaignInfo: TemplateInfoState }) {
  const { campaignInfo } = data;
  return campaignInfo ? fromJS(campaignInfo) : initialState;
}

type Actions = ChangeTemplateNameAction | ChangeTemplateTypeAction | SetCampaignIdAction;

export default (state: Map<TemplateInfoState> = initialState, action: Actions) => {
  switch (action.type) {
    case SET_FETCHED_CAMPAIGN_ID: {
      const { campaignId } = action;
      return state.set("id", campaignId);
    }
    case CHANGE_TEMPLATE_NAME: {
      return state.set("name", action.name);
    }
    case CHANGE_TEMPLATE_TYPE: {
      return state.set("templateType", action.templateType);
    }
    default:
      return state;
  }
};
