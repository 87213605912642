// @flow
import {
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import { ADD_SPLIT_SAGA } from "../../helpers/constants";
import { addSplit } from "../../actions/splits";
import {
  getSplitsAllIds,
  getSplitNames,
} from "../../selectors/smartlink";

const getNextSplitName = (splitNames, allIds) => splitNames.get(allIds.size);

export function* addSplitSaga(): Saga<void> {
  const allIds = yield select(getSplitsAllIds);
  const splitNames = yield select(getSplitNames);
  if (allIds.size === splitNames.size) {
    return;
  }

  const id = getNextId(allIds);
  const name = getNextSplitName(splitNames, allIds);
  const isActive = true;

  yield put(addSplit(id, name, isActive));
}

export default function* watchAddSplit(): Saga<void> {
  yield takeEvery(ADD_SPLIT_SAGA, addSplitSaga);
}
