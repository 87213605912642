// @flow
import {
  SET_STEP_ERROR,
  STEP_CLICK,
  STEP_CHANGE,
  SET_ERRORS,
} from "@fas/ui-framework/lib/redux/constants";
import {
  START_FETHING_CAMPAIGN_ID_SAGA,
  SET_FETCHED_CAMPAIGN_ID,
  START_SAVE_CAMPAIGN,
  OPEN_OFFERS_MODAL,
  CLOSE_OFFERS_MODAL,
} from "../helpers/constants";

let actionsCount = 0;
let isSaveEvent = false;

const ignoreActions = [
  START_FETHING_CAMPAIGN_ID_SAGA,
  SET_FETCHED_CAMPAIGN_ID,
  SET_ERRORS,
  SET_STEP_ERROR,
  STEP_CLICK,
  STEP_CHANGE,
  START_SAVE_CAMPAIGN,
  OPEN_OFFERS_MODAL,
  CLOSE_OFFERS_MODAL,
];

/*eslint-disable*/ // $FlowFixMe
export const pageLeaveConfirm = store => next => (async function(action) {
  
  if(!ignoreActions.includes(action.type)) {
    actionsCount++;
  }

  if(action.type === START_SAVE_CAMPAIGN) {
    isSaveEvent = true;
  }
  
  window.onbeforeunload = !(!actionsCount || isSaveEvent) && (() => true);

  return next(action);
});
