// @flow
/* eslint-disable import/max-dependencies */

import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import { reducer as errors, initState as initErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import notifications, { initNotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import modals, { initModalsState } from "@fas/ui-framework/lib/redux/reducers/modals";
import {
  tableReducer as tables,
  initTableState,
} from "@fas/ui-framework/lib/redux/reducers/table";
import loading, {
  initLoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import mainSaga from "./saga";
import steps, { initStepsState } from "../../reducers/stepsGeneralFilter";
import filterInfo, { initFilterInfoState } from "../../reducers/generalFilterInfo";
import { reducer as targeting, initGfTargetingState } from "../../reducers/gfTargeting";
import dictionaries, { initDictionariesState } from "../../reducers/dictionaries";
import {
  GENERAL_FILTERS_CAMPAIGNS_TABLE,
  GENERAL_FILTER_CAMPAIGNS_IN_MODAL,
  GENERAL_FILTER_CAMPAIGNS_OUT_MODAL,
} from "../../helpers/constants";

function mapCampaignToState() {
  return {
    steps: initStepsState(),
    filterInfo: initFilterInfoState(),
    targeting: initGfTargetingState(),
    errors: initErrorsState(),
    loading: initLoadingState({
      [GENERAL_FILTERS_CAMPAIGNS_TABLE]: false,
    }),
    dictionaries: initDictionariesState(),
    notifications: initNotificationsState(),
    tables: initTableState({
      [GENERAL_FILTERS_CAMPAIGNS_TABLE]: { pageSize: 20 },
    }),
    modals: initModalsState({
      [GENERAL_FILTER_CAMPAIGNS_IN_MODAL]: false,
      [GENERAL_FILTER_CAMPAIGNS_OUT_MODAL]: false,
    }),
  };
}

export const initialState = mapCampaignToState();

const reducers = combineReducers({
  notifications,
  steps,
  filterInfo,
  targeting,
  errors,
  loading,
  dictionaries,
  tables,
  modals,
});

// eslint-disable-next-line
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  serialize: {
    immutable: Immutable,
  },
}) : compose;
const sagaMiddleware = createSagaMiddleware();

// $FlowFixMe
export default () => {
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );
  sagaMiddleware.run(mainSaga);
  return store;
};
