// @flow
import React from "react";
import { Grid } from "@mui/material";
import { Map } from "immutable";
import PlaceholderButton from "./PlaceholderButton";
import PlaceholderValue from "./PlaceholderValue";
import type { Placeholder } from "./types/PlaceholdersList.types";

type Props = {
  placeholdersList: Array<Placeholder>,
  data: { [string]: string },
  onClickPlaceholder: (name: string, value?: string) => any,
  onChangeData: (name: string, value: string) => any,
  errors: Map
}

const PlaceholdersList = ({
  placeholdersList = [],
  data = {},
  onClickPlaceholder,
  onChangeData,
  errors,
}: Props) => {
  const placeholders = Object.keys(data);
  return (
    <Grid container spacing={3}>
      <Grid item container spacing={1}>
        {
          placeholdersList.map(({ name, value }: Placeholder) => {
            const selected = placeholders.includes(name);
            const placeholderButton = (
              <Grid item key={name}>
                <PlaceholderButton
                  name={name}
                  selected={selected}
                  onClick={() => onClickPlaceholder(name, value)}
                />
              </Grid>
            );
            return placeholderButton;
          })
        }
      </Grid>
      <Grid item container spacing={2}>
        {
          placeholders
            .map((name:string) => {
              const placeholderValue = (
                <Grid item key={name}>
                  <PlaceholderValue
                    name={name}
                    disabled={false}
                    value={data[name]}
                    onChange={(newValue) => onChangeData(name, newValue)}
                    error={errors.getIn([`placeholder_${name}`, "message"], false)}
                  />
                </Grid>
              );
              return placeholderValue;
            })
        }
      </Grid>
    </Grid>
  );
};

export default PlaceholdersList;
