// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { connect } from "react-redux";
import { SlideModal, ConfirmDialog } from "@fas/ui-core";
import type { List } from "immutable";
import {
  getCurrentSplit,
  getIsSplitModalOpen,
  getSplitById,
  getCurrentConfirmDialogMessage,
  getIsConfirmDialogOpen,
  getFunnelsArrayBySplitId,
  getFunnelSearchScrollToItem,
} from "../../selectors/smartlink";
import FunnelList from "../../components/FunnelList";
import { openFunnelSaga } from "../../actions/funnels";
import {
  setConfirmNotificationConfirmed,
  setConfirmNotificationCanceled,
} from "../../actions/confirmation";
import FunnelItem from "../FunnelItem";
import { changeFunnelsOrder, closeSplitSaga, saveSplitSaga } from "../../actions/splits/actions";
import SearchFunnels from "../SearchFunnels";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  open: boolean,
  handleCloseSplit: () => void,
  handleSaveSplit: (splitId: string) => void,
  splitId: string,
  splitName: string,
  funnelsIds: List<string>,
  handleOpenFunnel: (splitId: string, funnelId: string | null) => void,
  handleChangeFunnelsOrder: (splitId: string, funnelsIds: Array<string>) => void,
  currentConfirmatioMessage: string,
  scrollToItem: ?string,
  isConfirmDialogOpen: boolean,
  handleOnConfirmDialog: () => void,
  handleOnCancelDialog: () => void,
|}>;

const mapStateToProps = (state) => {
  const splitId = getCurrentSplit(state) || "";

  return {
    splitId,
    funnelsIds: getFunnelsArrayBySplitId(state, splitId),
    splitName: getSplitById(state, splitId).get("name"),
    open: getIsSplitModalOpen(state),
    isConfirmDialogOpen: getIsConfirmDialogOpen(state),
    currentConfirmatioMessage: getCurrentConfirmDialogMessage(state),
    scrollToItem: getFunnelSearchScrollToItem(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleOpenFunnel: (splitId, funnelId) => dispatch(openFunnelSaga(splitId, funnelId)),
  handleCloseSplit: () => dispatch(closeSplitSaga()),
  handleSaveSplit: (splitId) => dispatch(saveSplitSaga(splitId)),
  handleChangeFunnelsOrder: (splitId, funnels) => dispatch(changeFunnelsOrder(splitId, funnels)),
  handleOnConfirmDialog: () => dispatch(setConfirmNotificationConfirmed()),
  handleOnCancelDialog: () => dispatch(setConfirmNotificationCanceled()),
});

const SplitSetup = ({
  splitId,
  splitName = "",
  funnelsIds,
  open,
  handleCloseSplit,
  handleSaveSplit,
  handleChangeFunnelsOrder,
  currentConfirmatioMessage,
  isConfirmDialogOpen,
  handleOnConfirmDialog,
  handleOnCancelDialog,
  handleOpenFunnel,
  scrollToItem,
}: Props) => (
  <>
    <SlideModal
      open={open}
      onClose={handleCloseSplit}
      onSave={() => handleSaveSplit(splitId)}
      title={`Split ${splitName.toUpperCase()}`}
      dataTestid="split-modal"
      content={open && (
        <>
          <FunnelList
            SearchComponent={SearchFunnels}
            FunnelItemComponent={FunnelItem}
            splitId={splitId}
            funnelsIds={funnelsIds}
            onMoveRules={handleChangeFunnelsOrder}
            handleOpenFunnel={handleOpenFunnel}
            scrollToItem={scrollToItem}
          />
          <ConfirmDialog
            open={isConfirmDialogOpen}
            message={currentConfirmatioMessage}
            onMainBtnClick={handleOnConfirmDialog}
            onSecondaryBtnClick={handleOnCancelDialog}
          />
        </>
      )}
    />
  </>
);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SplitSetup);
