// @flow
import type { RecordOf, RecordFactory } from "immutable";
import { Record } from "immutable";
import {
  SET_ANTIFRAUD_DICTIONARY,
  SET_ANTIFRAUD_DICTIONARY_FORM_FIELD,
  VALUE_DICT,
} from "../../helpers/constants";
import type {
  Actions,
  SetAntifraudDictionaryFormField,
} from "../../actions/antifraudDictionaryForm";
import type { Dictionary, DictionaryFormData } from "../../selectors/antifraudDictionaries";

const defaultState: DictionaryFormData = {
  dictId: 0,
  name: "",
  dictTypeName: VALUE_DICT,
  description: "",
  value: "",
  scenario: 0,
  ttl: 60,
  isActive: false,
};

export type State = RecordOf<DictionaryFormData>;

export const makeAntifraudDictionariesState: RecordFactory<DictionaryFormData> = Record(defaultState);

export const initialAntifraudDictionariesState: State = makeAntifraudDictionariesState({});

export default (state: State = initialAntifraudDictionariesState, action: Actions): State => {
  switch (action.type) {
    case SET_ANTIFRAUD_DICTIONARY: {
      const { value = [], ...rest }: Dictionary = action.payload;
      return makeAntifraudDictionariesState({ ...rest, value: value.join("\n") });
    }
    case SET_ANTIFRAUD_DICTIONARY_FORM_FIELD: {
      const { payload }: SetAntifraudDictionaryFormField = action;
      return state.merge(payload);
    }
    default:
      return state;
  }
};
