// @flow
import { Map, fromJS } from "immutable";
import type { TabState } from "../tabs";
import type { FunnelsState } from "../funnels/reducer";
import type { TargetingsState } from "../targetings/reducer";
import type { FilteringsState } from "../filterings/reducer";
import type { OffersState } from "../offers/reducer";

export type FunnelTemplateInfoState = {
  id: string,
  type: string,
  name: string,
  funnels: number,
  manager: string,
  createdAt: string,
  updatedAt: string,
  lastEditor: string,
  campaigns: number,
};

export type State = FunnelTemplateInfoState;

export type FunnelTemplate = {
  tabs: TabState,
  funnels: FunnelsState,
  targetings: TargetingsState,
  filterings: FilteringsState,
  offers: OffersState,
  funnelTemplateInfo?: FunnelTemplateInfoState,
};

export const initialState: Map<State> = Map({
  id: "",
  type: "",
  name: "",
  funnels: 0,
  manager: "",
  createdAt: "",
  updatedAt: "",
  lastEditor: "",
  campaigns: 0,
});

export function initFunnelTempplateInfoState(template: FunnelTemplate) {
  const { funnelTemplateInfo } = template;
  const state = fromJS(funnelTemplateInfo);

  return state;
}

export default (state: Map<State> = initialState) => state;
