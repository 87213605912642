import {
  put,
  takeEvery,
} from "redux-saga/effects";
import {
  DELETE_NON_TARGET_OFFER_SAGA,
} from "../../helpers/constants";
import { deleteServiceOffer } from "../../actions/offers";
import { deleteNonTargetOffer } from "../../actions/nonTargetOffers";

export function* deleteNonTargetOffersSaga(action) {
  yield put(deleteServiceOffer(action.offerId));
  yield put(deleteNonTargetOffer(action.offerId));
}

export default function* watchDeleteNonTargetOffersSaga() {
  yield takeEvery(DELETE_NON_TARGET_OFFER_SAGA, deleteNonTargetOffersSaga);
}
