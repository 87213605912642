import { put, takeEvery, select } from "redux-saga/effects";
import { SPLIT_IS_ACTIVE_CHANGE_SAGA } from "../../helpers/constants";
import {
  changeIsActive,
  setSplitWeight,
} from "../../actions/splits";

import { changeFrequencyDefaultSplit } from "../../actions/frequency";

import { isSplitDefault, getAllActiveSplitsNames } from "../../selectors/smartlink";

export function* changeSplitIsActiveSags(action) {
  yield put(changeIsActive(action.id));
  yield put(setSplitWeight(action.id, 0));

  const isDefault = yield select(isSplitDefault, action.id);

  if (isDefault) {
    const activeSplits = yield select(getAllActiveSplitsNames);
    yield put(changeFrequencyDefaultSplit(activeSplits[0]));
  }
}

export default function* watchChangeSplitIsActive() {
  yield takeEvery(SPLIT_IS_ACTIVE_CHANGE_SAGA, changeSplitIsActiveSags);
}
