// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import HistoryReplaceList from "../../containers/HistoryReplaceList";

function HistoryReplace() {
  return (
    <DocumentTitle title="Replace LP History">
      <HistoryReplaceList />
    </DocumentTitle>
  );
}

export default HistoryReplace;
