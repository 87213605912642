// @flow
import {
  put, select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import { setTableData, setTableSelection, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table";
import { SAVE_NON_TARGET_OFFERS_SAGA, OFFERS_TABLE } from "../../helpers/constants";
import { addNonTargetOffer, deleteNonTargetOffer } from "../../actions/nonTargetOffers";
import { deleteServiceOffer } from "../../actions/offers";
import { closeOffersModal } from "../../actions/modals";
import { getNonTargetOffers, getOffersAllIds } from "../../selectors/smartlink";

export function* saveNonTargetOffersSaga(action: *): Saga<void> {
  let { offers } = action;
  offers = offers.map((x) => ({
    ...x,
    isActive: true,
  }));
  const oldOffers = yield select(getNonTargetOffers);

  for (let i = 0; i < oldOffers.size; i++) {
    yield put(deleteServiceOffer(oldOffers.get(i)));
    yield put(deleteNonTargetOffer(oldOffers.get(i)));
  }

  for (let i = 0; i < offers.length; i++) {
    const allIds = yield select(getOffersAllIds);
    const offerId = getNextId(allIds);

    yield put(addNonTargetOffer(offerId, offers[i]));
  }

  yield put(closeOffersModal());
  yield put(setTableData(OFFERS_TABLE, []));
  yield put(setTableSelection(OFFERS_TABLE, []));
  yield put(changeTableItemsTotalAmount(OFFERS_TABLE, 0));
}

export default function* watchSaveFallbackOfferSaga(): Saga<void> {
  yield takeEvery(SAVE_NON_TARGET_OFFERS_SAGA, saveNonTargetOffersSaga);
}
