// @flow
import Joi from "@hapi/joi";
import type { Errors, JoiError } from "../types/Base";
import type { BaseSimpleErrorsRule } from "./Base";
import {
  ACTION_OPTIONS_PARAM_IS_INVALID_PLACEHOLDER,
  ACTION_OPTIONS_PARAM_IS_INVALID_USER_DATA,
} from "../messages";
import type { ActionOptionsParam } from "../../../reducers/actionOptionsParams/reducer";

const needPlaceholder = Joi.object({
  isHaveFiltering: true,
}).unknown();

const needUserDataByFiltering = Joi.object({
  isHaveFiltering: true,
}).unknown();

const EMAIL_PLACEHOLDER = Joi.string().alphanum().min(1).max(64);
const ANY_STRING = Joi.string().allow("");

export default class SmartlinkActionOptionsParams implements BaseSimpleErrorsRule {
  schema: Object;

  errors: Errors;

  errorKey: string;

  constructor() {
    this.errors = {};
    this.errorKey = "actionOptionsParams";

    this.schema = Joi.object({
      emailPlaceholder: Joi.when(Joi.ref("/"), {
        is: needPlaceholder,
        then: EMAIL_PLACEHOLDER,
        otherwise: ANY_STRING,
      }),
      userDataSource: Joi.when(Joi.ref("/"), {
        is: Joi.alternatives(needUserDataByFiltering),
        then: "getAttribute",
        otherwise: ANY_STRING,
      }),
      emailBase64: Joi.boolean(),
      isHaveFiltering: Joi.boolean(),
    })
      .error((errors) => this._onError(errors));
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach((item) => {
      const path = item.path.join(".");
      switch (path) {
        case "emailPlaceholder": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: ACTION_OPTIONS_PARAM_IS_INVALID_PLACEHOLDER },
          };
          break;
        }
        case "userDataSource": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: ACTION_OPTIONS_PARAM_IS_INVALID_USER_DATA },
          };
          break;
        }
        default:
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: item.message },
          };
          break;
      }
    });

    return errors;
  }

  getErrors(data: ActionOptionsParam & { isHaveFiltering: boolean }): Errors {
    this.errors = {};
    this.schema.validate(data);
    return this.errors;
  }
}
