// @flow
import { Map, List, fromJS } from "immutable";
import {
  ADD_NON_TARGET_OFFERS,
  DELETE_NON_TARGET_OFFER,
} from "../../helpers/constants";
import type { Campaign } from "../campaignInfo/reducer";

export type NonTargetState = {
  offers: List<string>
};

export type State = NonTargetState;

export const initialState: Map<State> = Map({
  offers: List([]),
});

export function initNonTargetState(campaign: Campaign) {
  let state;
  const { nonTarget } = campaign;
  if (nonTarget) {
    state = fromJS(nonTarget);
  }
  else {
    state = initialState;
  }
  return state;
}

export default (state: Map<State> = initialState, action: any) => {
  switch (action.type) {
    case ADD_NON_TARGET_OFFERS: {
      const { offerId } = action;

      return state.withMutations((newState) => {
        newState.updateIn(["offers"], (offers) => offers.push(offerId));
      });
    }
    case DELETE_NON_TARGET_OFFER: {
      const { offerId } = action;

      return state.deleteIn(["offers", state.get("offers").findIndex((el) => el === offerId)]);
    }
    default:
      return state;
  }
};
