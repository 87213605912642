// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import SmartlinkRedirectForm from "../../components/SmartlinkRedirectForm";

function SmartlinkAggregatorFormPage() {
  return (
    <DocumentTitle title="Smartlink Aggregator - Redirect">
      <SmartlinkRedirectForm />
    </DocumentTitle>
  );
}

export default SmartlinkAggregatorFormPage;
