// @flow
import React, {
  useState,
  type Node,
  type StatelessFunctionalComponent,
} from "react";
import {
  Box,
  Grid,
} from "@mui/material";
import { SelectCustom } from "@fas/ui-core";
import UrlType, { getUrlTypeModel } from "../../helpers/UrlTypeFactory";

export type Props = {
  campaignId: string,
  domain: string,
  protocol: string,
  placeholders: {
    [string]: string,
  },
  path: string,
  shortUrlId: string,
  shortUrlTime: string,
}

type UseState<T> = [T, (((T) => T) | T) => void];

const CampaignUrlBuilder: StatelessFunctionalComponent<Props> = ({
  campaignId,
  domain,
  protocol,
  path,
  placeholders,
  shortUrlId,
  shortUrlTime,
}: Props) => {
  const [urlType, setUrlType]: UseState<string> = useState("redirect");

  const urlTypeModel: UrlType = getUrlTypeModel({
    type: urlType,
    campaignId,
    protocol,
    domain,
    path,
    placeholders,
    shortUrlId,
    shortUrlTime,
  });

  const urlTypeValues: Array<{ value: string, title: string }> = urlTypeModel.getUrlTypeValues();

  const urlView: Node = urlTypeModel.getView();

  return (
    <>
      <Grid item xs={3}>
        <SelectCustom
          title="Type"
          value={urlType}
          values={urlTypeValues}
          onTypeChange={(e: { target: { value: string } }): void => setUrlType(e.target.value)}
        />
      </Grid>
      <Grid item xs={9}>
        <Box ml={1}>
          {urlView}
        </Box>
      </Grid>
    </>
  );
};

export default CampaignUrlBuilder;
