// @flow
import { put, select, takeEvery } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getNextId,
} from "@fas/ui-framework/lib/services/generators";
import { setTableData, setTableSelection, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table";
import { SAVE_OFFERS_SAGA, OFFERS_TABLE } from "../../helpers/constants";
import {
  getOffersByFunnelId,
  getOffersAllIds,
} from "../../selectors/smartlink";
import {
  addOffer,
  deleteOffer,
} from "../../actions/offers";
import { closeOffersModal } from "../../actions/modals";

export function* saveOffersSaga(action: *): Saga<void> {
  const { funnelId } = action;
  let { offers } = action;
  offers = offers.map((x) => ({
    ...x,
    isActive: x.isActive === undefined || x.isActive,
  }));
  const oldOffers = yield select(getOffersByFunnelId, funnelId);

  for (let i = 0; i < oldOffers.size; i++) {
    yield put(deleteOffer(funnelId, oldOffers.get(i)));
  }

  for (let i = 0; i < offers.length; i++) {
    const allIds = yield select(getOffersAllIds);

    const offerId = getNextId(allIds);

    yield put(addOffer(funnelId, offerId, offers[i]));
  }
  yield put(closeOffersModal());
  yield put(setTableData(OFFERS_TABLE, []));
  yield put(setTableSelection(OFFERS_TABLE, []));
  yield put(changeTableItemsTotalAmount(OFFERS_TABLE, 0));
}

export default function* watchSaveOffers(): Saga<void> {
  yield takeEvery(SAVE_OFFERS_SAGA, saveOffersSaga);
}
