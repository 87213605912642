// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { Provider } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";
import DocumentTitle from "react-document-title";
import { ComboBox, GridUc } from "@fas/ui-core";
import { userService } from "../../services/user";
import Notification from "../../components/Notification";
import configureStore from "./store";
import FunnelsTemplateList from "../../containers/FunnelsTemplateList";
import TableActions from "../../containers/TdsAdminTableActions";
import type { Props as TablesActionsProps } from "../../containers/TdsAdminTableActions/types/TdsAdminTableActions.types";

type Props = {
  classes: Object,
};

const store = configureStore();

function FunnelsTemplateIndex(props: Props) {
  const { classes } = props;

  const comboBoxProps = {
    id: "mui-autocomplete-funnel-templates-list",
    label: "FunnelTemplateList",
    options: [
      {
        text: "Reporting",
        permissions: ["/dictionary/funnelTemplates/report"],
      },
      {
        text: "Summary table",
        permissions: ["/dictionary/funnelTemplates/list"],
      },
    ]
      .filter(({ permissions }) => userService.can(permissions))
      .map(({ text }) => text),
    value: "Summary table",
    isOptionEqualToValue: (option, value) => option === value,
    getOptionLabel: (option) => option || "",
    renderInput: (params) => (
      <TextField
        {...params}
        data-testid="mui-autocomplete-funnel-templates-list-page"
        variant="outlined"
        fullWidth
        InputProps={{
          // eslint-disable-next-line react/destructuring-assignment
          ...params.InputProps,
          readOnly: true,
          startAdornment: (
            <Box p={1}>
              <InputAdornment position="start">
                <TimelineIcon />
              </InputAdornment>
            </Box>
          ),
        }}
      />
    ),
    onChange: () => {
      window.location = "/funnelTemplates/report";
    },
    disableClearable: true,
    "data-testid": "id-funnel-templates-list-comboBox",
  };

  return (
    <Provider store={store}>
      <DocumentTitle title="Funnels Template">
        <Grid container spacing={0} className={classes.mainBackground}>
          <Box flex={1} m={3}>
            <GridUc mb={1} container spacing={1}>
              <Grid item xs={3}>
                <ComboBox {...comboBoxProps} />
              </Grid>
            </GridUc>
            <FunnelsTemplateList
              renderActions={(actionsProps: TablesActionsProps) => <TableActions {...actionsProps} />}
              rowSelectAvailable={(): boolean => true}
              onCreate={(): void => {
                window.location = "/funnelTemplates";
              }}
            />
          </Box>
        </Grid>
      </DocumentTitle>
      <Notification />
    </Provider>
  );
}

export default withStyles((theme) => ({
  mainBackground: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}))(FunnelsTemplateIndex);
