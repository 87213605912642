// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import environment from "environment";
import {
  Box,
  Link, Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "@fas/ui-core/lib";
import {
  generateLinkToBasic,
} from "@fas/ui-core/lib/utils";
import MultiselectWithSuggest from "@fas/ui-core/lib/SearchComponentsV2/MultiselectWithSuggest";
import SimpleSelect from "@fas/ui-core/lib/SearchComponentsV2/SimpleSelect";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import { useLocation } from "react-router-dom";
import { getDropdown } from "@fas/ui-framework/lib/redux/selectors/dropdowns";
import type { Node } from "react";
import moment from "moment";
import type { Option } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import { SMARTLINK_AGGREGATORS_TO_CAMPAIGNS_TABLE } from "../../helpers/constants";
import { getSmartlinkAggregatorsToCampaignsSaga } from "../../actions/smartlinkAggregators";
import { fetchSAFiltersList } from "../../services/smartlinkAggregatorsApi";
import type { State } from "../../pages/AppStoreWrapper";
import type { SmartlinkAggregatorsToCampaigns } from "../../services/smartlinkAggregatorsApi";

function SmartlinkAggregatorsToCampaign() {
  const dispatch: <A>(A) => A = useDispatch();
  const search: URLSearchParams = new URLSearchParams(useLocation().search);
  const SAids: string | null = search.get("id");
  const additionalParameter: string = SAids || "";
  const splitOptions: Array<Option<string>> = useSelector((state: State) => getDropdown(state, "split"));
  const campaignStatusOptions: Array<Option<number>> = useSelector((state: State) => getDropdown(state, "campaignStatus"));
  const offerStatusOptions: Array<Option<number>> = useSelector((state: State): * => getDropdown(state, "offerStatus"));
  const offerInCampaignStatusOptions: Array<Option<number>> = useSelector((state: State): * => getDropdown(state, "offerInCampaignStatus"));

  const handleGetSAFiltersList = (name: string, filterValue: string):
    Promise<Array<{
      label: string, value: string
    }>> => fetchSAFiltersList(name, filterValue, additionalParameter);
  const handleChangeFilters = (filters: Filters): void => {
    const formatedFilters: Filters = {};
    for (const key: string in filters) {
      if (filters[key] && filters[key].length) {
        formatedFilters[key] = filters[key];
      }
    }
    tableProps.onChangeFilters(formatedFilters);
  };
  const handleGetSAtoCampaignsList = () => dispatch(getSmartlinkAggregatorsToCampaignsSaga());
  const tableProps = useTable(SMARTLINK_AGGREGATORS_TO_CAMPAIGNS_TABLE);

  const columns = [
    {
      label: "Smartlink aggregator ID",
      field: "saId",
      sortable: true,
      searchable: true,
      SearchComponent: ({ value, onChange }) => (
        <MultiselectWithSuggest
          selectedList={value || []}
          // $FlowFixMe
          onClose={onChange}
          // $FlowFixMe
          fetchMethod={handleGetSAFiltersList}
          name="saId"
          searchLimit={1}
        />
      ),
      render: ({ saId }) => (
        <Link
          target="_blank"
          href={`/smartlinkAggregators/${saId}`}
          underline="hover"
        >
          {saId}
        </Link>
      ),
    },
    {
      label: "Smartlink aggregator name",
      field: "saName",
      sortable: true,
      searchable: true,
      SearchComponent: ({ value, onChange }) => (
        <MultiselectWithSuggest
          selectedList={value || []}
          // $FlowFixMe
          onClose={onChange}
          // $FlowFixMe
          fetchMethod={handleGetSAFiltersList}
          name="saName"
        />
      ),
    },
    {
      label: "Basic id",
      field: "campaignId",
      sortable: true,
      searchable: true,
      SearchComponent: ({ value, onChange }) => (
        <MultiselectWithSuggest
          selectedList={value || []}
          // $FlowFixMe
          onClose={onChange}
          // $FlowFixMe
          fetchMethod={handleGetSAFiltersList}
          name="campaignId"
        />
      ),
    },
    {
      label: "Basic name",
      field: "campaignName",
      sortable: true,
      searchable: true,
      SearchComponent: ({ value, onChange }) => (
        <MultiselectWithSuggest
          selectedList={value || []}
          // $FlowFixMe
          onClose={onChange}
          // $FlowFixMe
          fetchMethod={handleGetSAFiltersList}
          name="campaignName"
        />
      ),
    },
    {
      label: "Split",
      field: "split",
      sortable: true,
      searchable: true,
      SearchComponent: ({ value, onChange }) => (
        <SimpleSelect
          selectedValues={value || []}
          onChange={onChange}
          // $FlowFixMe
          values={splitOptions}
          name="split"
        />
      ),
    },
    {
      label: "Funnel name",
      // $FlowFixMe
      field: "funnelName",
      searchable: true,
      sortable: true,
      SearchComponent: ({ value, onChange }) => (
        <MultiselectWithSuggest
          selectedList={value || []}
          // $FlowFixMe
          onClose={onChange}
          // $FlowFixMe
          fetchMethod={handleGetSAFiltersList}
          name="funnelName"
        />
      ),
      render: ({
        campaignId,
        split,
        funnel: { funnelIndex, funnelName },
      }: SmartlinkAggregatorsToCampaigns): Node => (
        <Link
          target="_blank"
          href={generateLinkToBasic(environment.appLinks.dmp, campaignId, split, funnelIndex, "funnel")}
          underline="hover"
        >
          {funnelName}
        </Link>
      ),
    },
    {
      label: "Created at",
      field: "createdAt",
      sortable: true,
      render: ({ created }: SmartlinkAggregatorsToCampaigns): Node => (<span>{moment(created).format("MM.DD.YY hh:mm:ss")}</span>),
    },
    {
      label: "Basic status",
      field: "campaignStatus",
      render: ({ campaignStatus }: SmartlinkAggregatorsToCampaigns): Node => (
        <Typography sx={{ color: campaignStatus ? "green" : "red" }}>{campaignStatus ? "Yes" : "No"}</Typography>
      ),
      SearchComponent: ({ value, onChange }) => (
        <SimpleSelect
          selectedValues={value || []}
          onChange={onChange}
          // $FlowFixMe
          values={campaignStatusOptions}
          name="campaignStatus"
        />
      ),
    },
    {
      label: "SA status",
      field: "saStatus",
      render: ({ saStatus }: SmartlinkAggregatorsToCampaigns): Node => (
        <Typography sx={{ color: saStatus ? "green" : "red" }}>{saStatus ? "Yes" : "No"}</Typography>
      ),
      SearchComponent: ({ value, onChange }) => (
        <SimpleSelect
          selectedValues={value || []}
          onChange={onChange}
          // $FlowFixMe
          values={offerStatusOptions}
          name="saStatus"
        />
      ),
    },
    {
      label: "Offer status in basic",
      field: "saInCampaignStatus",
      render: ({ saInCampaignStatus }: SmartlinkAggregatorsToCampaigns): Node => (
        <Typography sx={{ color: saInCampaignStatus ? "green" : "red" }}>{saInCampaignStatus ? "Yes" : "No"}</Typography>
      ),
      SearchComponent: ({ value, onChange }) => (
        <SimpleSelect
          selectedValues={value || []}
          onChange={onChange}
          // $FlowFixMe
          values={offerInCampaignStatusOptions}
          name="saInCampaignStatus"
        />
      ),
    },
  ];

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        allCheckboxVisible={false}
        onChangeFilters={handleChangeFilters}
        title="Smartlink aggregators to campaign"
        allSelectAvailable={() => false}
        rowSelectAvailable={() => false}
        onLoad={handleGetSAtoCampaignsList}
        columns={columns}
      />
    </Box>
  );
}

export default SmartlinkAggregatorsToCampaign;
