// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { GET_GENERAL_FILTER_SAGA } from "../../helpers/constants";
import { getGeneralFilter } from "../../services/generalFilterApiV2";
import { initStateEdit } from "../../actions/generalFilterInfo";
import { initTargetingStateEdit } from "../../actions/gfTargeting";
import { type GetGeneralFilterSaga } from "../../actions/generalFilters";

export function* makeFetch({ id }: GetGeneralFilterSaga): Saga<void> {
  try {
    yield put(setLoading(GET_GENERAL_FILTER_SAGA, true));

    const data = yield call(getGeneralFilter, id);

    yield put(initStateEdit(data));
    yield put(initTargetingStateEdit(data.targeting));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(GET_GENERAL_FILTER_SAGA, false));
  }
}

export default function* watchGetFormDataSaga(): Saga<void> {
  yield takeEvery(GET_GENERAL_FILTER_SAGA, (makeFetch: Function));
}
