// @flow
import React from "react";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import type { Placeholder, PlaceholderOptions } from "./types/PlaceholdersList.types";

type Props = Placeholder & PlaceholderOptions & {
  onChange?: (value: string) => any,
  error?: string,
}

const PlaceholderValue = ({
  name,
  disabled,
  value,
  onChange,
  error,
}: Props) => (
  <FormControl>
    <TextField
      label={name}
      variant="outlined"
      size="small"
      disabled={disabled}
      error={Boolean(error)}
      value={value}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      data-testid={`placeholderValue-${name}`}
    />
    <FormHelperText>{error}</FormHelperText>
  </FormControl>
);

export default React.memo<Props>(PlaceholderValue, (prevProps, nextProps) => prevProps.name === nextProps.name
    && prevProps.value === nextProps.value
    && prevProps.error === nextProps.error
    && prevProps.disabled === nextProps.disabled);
