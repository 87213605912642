// @flow

import Basic from "./types/Basic";
import Smartlink from "./types/Smartlink";
import { CAMPAIGN_TYPE_BASIC, CAMPAIGN_TYPE_SMARTLINK, CAMPAIGN_TYPE_TEMPLATE } from "../../helpers/constants";
import FunnelTemplate from "../templateValidation";

const mapper = {
  "basic": Basic,
  "smartlink": Smartlink,
  "template": FunnelTemplate,
};

const validator = (
  type: typeof CAMPAIGN_TYPE_BASIC | typeof CAMPAIGN_TYPE_SMARTLINK | typeof CAMPAIGN_TYPE_TEMPLATE
): Object => mapper[type];

export default validator;
