/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put, select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import type { SaveFormDataSagaType } from "@fas/ui-framework/lib/redux/actions/form";
import { SAVE_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import type { SmartlinkRedirect } from "../../selectors/smartlinkAggregators";
import { createSmartlinkRedirect, updateSmartlinkRedirect } from "../../services/smartlinkAggregatorsApi";
import ExternalRedirect from "../../components/App/ExternalRedirect";
import { getPlaceholders } from "../../selectors/smartlink";
import { type PlaceholdersState } from "../../reducers/placeholders/reducer";

export function* makeFetch<T>({ key, redirect }: SaveFormDataSagaType<T>): Saga<void> {
  try {
    yield put(setLoading(SAVE_FORM_DATA_SAGA, true));

    const data: SmartlinkRedirect = yield select((state)
    : SmartlinkRedirect => getFormData(state, key));

    const placeholdersData: PlaceholdersState = yield select((state)
    : PlaceholdersState => getPlaceholders(state).toJS());
    const placeholdersValue: string = Object.keys(placeholdersData).map((name) => `${name}=${placeholdersData[name]}`).join("&");

    const { id }: SmartlinkRedirect = data;

    if (id) {
      yield call(updateSmartlinkRedirect, { ...data, placeholders: placeholdersValue }, id);
    }
    else {
      yield call(createSmartlinkRedirect, { ...data, placeholders: placeholdersValue });
    }

    if (redirect) {
      ExternalRedirect({ to: redirect });
    }
  }
  catch (err) {
    if (err.isAxiosError
      && err.response
      && err.response.data
      && err.response.data.errors
      && Array.isArray(err.response.data.errors)) {
      yield put(setErrors(prepareFetchErrors(err.response.data.errors)));
    }
    else {
      yield put(addNotification({ message: err.errorMessage || "Failed to save", severity: "error" }));
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  finally {
    yield put(setLoading(SAVE_FORM_DATA_SAGA, false));
  }
}

export default function* watchSaveSmartlinkRedirect(): Saga<void> {
  yield takeEvery(SAVE_FORM_DATA_SAGA, (makeFetch: Function));
}
