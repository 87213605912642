import {
  SET_CONFIRM_NOTIFICATION_MESSAGE,
  OPEN_CONFIRM_NOTIFICATION,
  CLOSE_CONFIRM_NOTIFICATION,
  SET_CONFIRM_NOTIFICATION_CONFIRMED,
  SET_CONFIRM_NOTIFICATION_CANCELED,
} from "../../helpers/constants";

export type SetConfirmNotificationMessage = {|
  type: typeof SET_CONFIRM_NOTIFICATION_MESSAGE,
|}

export type OpenConfirmNotification = {|
  type: typeof OPEN_CONFIRM_NOTIFICATION,
|};

export type CloseConfirmNotification = {|
  type: typeof CLOSE_CONFIRM_NOTIFICATION,
|};

export type SetConfirmNotificationConfirmed = {|
  type: typeof SET_CONFIRM_NOTIFICATION_CONFIRMED,
|}

export type SetConfirmNotificationCanceled = {|
  type: typeof SET_CONFIRM_NOTIFICATION_CANCELED,
|}

export type ConfirmatioActionType = SetConfirmNotificationMessage
  | OpenConfirmNotification
  | CloseConfirmNotification
  | SetConfirmNotificationConfirmed
  | SetConfirmNotificationCanceled;

export const setConfirmNotificationMessage = (message: string): SetConfirmNotificationMessage => ({
  type: SET_CONFIRM_NOTIFICATION_MESSAGE,
  message,
});

export const openConfirmNotification = (): OpenConfirmNotification => ({
  type: OPEN_CONFIRM_NOTIFICATION,
});

export const closeConfirmNotification = (): CloseConfirmNotification => ({
  type: CLOSE_CONFIRM_NOTIFICATION,
});

export const setConfirmNotificationConfirmed = (): SetConfirmNotificationConfirmed => ({
  type: SET_CONFIRM_NOTIFICATION_CONFIRMED,
});

export const setConfirmNotificationCanceled = (): SetConfirmNotificationCanceled => ({
  type: SET_CONFIRM_NOTIFICATION_CANCELED,
});
