/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import type { Map } from "immutable";
import type { Split } from "../../reducers/splits";
import SplitItem from "../../components/SplitItem";
import Funnels from "../Funnels";
import {
  changeIsActiveSaga,
  cloneSplitSaga,
  deleteSplitSaga,
  setSplitFunnelTemplate,
  fetchFunnelTemplate,
} from "../../actions/splits";
import { openSplitSaga } from "../../actions/splits/actions";
import {
  funnelTemplateListModalOpenSaga,
} from "../../actions/modals";
import {
  getDefaultSplit,
  getFunnelTemplates,
} from "../../selectors/smartlink";
import FunnelTemplate from "../../components/FunnelTemplate";
import FunnelTemplateListModal from "../FunnelTemplateListModal/FunnelTemplateListModal";
import Can from "../../components/Can";
import type { FunnelTemplate as Template } from "../../actions/dictionaries";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  splits: {
    [key: string]: Split,
  },
  isSplitsClonable: boolean,
  defaultSplit: string,
  templates: Map<{
    [key: string]: Template
  }>,
  handleDeleteSplit: (id: string) => void,
  handleCloneSplit: (id: string) => void,
  handleChangeIsActive: (id: string) => void,
  handleShowSplit: (id: string) => void,
  handleFetchFunnelTemplate: (templateId: string) => void,
  openFunnelTemplateList: (id: string, templateId: string) => void,
  clearSplitFunnelTemplate: (id: string) => void,
|}>;

const mapStateToProps = (state) => ({
  splits: state.splits.get("byId").toJS(),
  isSplitsClonable:
    state.splits.get("allIds").size
    !== state.dictionaries.get("splitNames").size,
  defaultSplit: getDefaultSplit(state),
  templates: getFunnelTemplates(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleDeleteSplit: (id) => dispatch(deleteSplitSaga(id)),
  handleCloneSplit: (id) => dispatch(cloneSplitSaga(id)),
  handleChangeIsActive: (id) => dispatch(changeIsActiveSaga(id)),
  handleShowSplit: (id) => dispatch(openSplitSaga(id)),
  handleFetchFunnelTemplate: (templateId) => dispatch(fetchFunnelTemplate(templateId)),
  openFunnelTemplateList: (id, templateId) => dispatch(funnelTemplateListModalOpenSaga(id, templateId)),
  clearSplitFunnelTemplate: (id) => dispatch(setSplitFunnelTemplate(id, "")),
});

const getSplitLabel = (name) => `Split ${name.toUpperCase()}`;
const isLastActiveSplit = (id, splits) => !Object.keys(splits).some(
  (splitId) => splitId !== id && splits[splitId].isActive
);
const getSplitIsEditing = (id, splits) => Object.keys(splits).length > 1 && !isLastActiveSplit(id, splits);

const Splits = ({
  splits,
  isSplitsClonable,
  defaultSplit,
  templates,
  handleDeleteSplit,
  handleCloneSplit,
  handleChangeIsActive,
  handleShowSplit,
  handleFetchFunnelTemplate,
  openFunnelTemplateList,
  clearSplitFunnelTemplate,
}: Props) => (
  <>
    {Object.keys(splits).map((id) => {
      const { name, isActive, funnelTemplate: funnelTemplateId } = splits[id];

      return (
        <Box mb={3} key={name}>
          <SplitItem
            title={getSplitLabel(name)}
            isActive={isActive}
            isEditing={getSplitIsEditing(id, splits)}
            isSelected={name === defaultSplit}
            isClonable={isSplitsClonable}
            onChangeIsActive={() => handleChangeIsActive(id)}
            onClone={() => handleCloneSplit(id)}
            onDelete={() => handleDeleteSplit(id)}
            onShow={() => handleShowSplit(id)}
          >
            <Funnels splitId={id} />
            <Can permissions={["unitedReborn.field.funnelTemplate"]}>
              <Box px={3} pb={2}>
                <FunnelTemplate
                  isActive={isActive}
                  templateId={funnelTemplateId}
                  template={templates.get(funnelTemplateId)}
                  onFunnelTemplateFetch={handleFetchFunnelTemplate}
                  onOpen={() => openFunnelTemplateList(id, funnelTemplateId)}
                  onDelete={() => clearSplitFunnelTemplate(id)}
                />
              </Box>
            </Can>

          </SplitItem>
        </Box>
      );
    })}
    <FunnelTemplateListModal />
  </>
);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Splits);
