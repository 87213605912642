// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchChangeAntifraudScenarioActive from "../../sagas/changeAntifraudScenarioActive";
import watchGetAntifraudScenariosList from "../../sagas/getAntifraudScenariosList";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetAntifraudScenariosList),
    call(watchChangeAntifraudScenarioActive),
  ]);
}
