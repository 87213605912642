// @flow

export const CAMPAIGN_TYPE_BASIC: "basic" = "basic";
export const CAMPAIGN_TYPE_SMARTLINK: "smartlink" = "smartlink";
export const CAMPAIGN_TYPE_TEMPLATE: "template" = "template";

export const SEARCH_FUNNELS: "SEARCH_FUNNELS" = "SEARCH_FUNNELS";
export const SET_SEARCH_DATA: "SET_SEARCH_DATA" = "SET_SEARCH_DATA";

export const SET_USER_DATA_SOURCE: "SET_USER_DATA_SOURCE" = "SET_USER_DATA_SOURCE";
export const SET_EMAIL_PLACEHOLDER: "SET_EMAIL_PLACEHOLDER" = "SET_EMAIL_PLACEHOLDER";
export const SET_EMAIL_BASE64: "SET_EMAIL_BASE64" = "SET_EMAIL_BASE64";
export const ADD_ACTION_OPTIONS_PARAMS: "ADD_ACTION_OPTIONS_PARAMS" = "ADD_ACTION_OPTIONS_PARAMS";
export const DELETE_ACTION_OPTIONS_PARAMS: "DELETE_ACTION_OPTIONS_PARAMS" = "DELETE_ACTION_OPTIONS_PARAMS";
export const CLONE_ACTION_OPTIONS_PARAMS_SAGA: "CLONE_ACTION_OPTIONS_PARAMS_SAGA" = "CLONE_ACTION_OPTIONS_PARAMS_SAGA";

export const ADD_FILTERING: "ADD_FILTERING" = "ADD_FILTERING";
export const DELETE_FILTERING: "DELETE_FILTERING" = "DELETE_FILTERING";
export const DELETE_FILTERING_SAGA: "DELETE_FILTERING_SAGA" = "DELETE_FILTERING_SAGA";
export const CHANGE_FILTERING_RULE: "CHANGE_FILTERING" = "CHANGE_FILTERING";
export const CHANGE_FILTERING_RULE_VALUE: "CHANGE_FILTERING_RULE_VALUE" = "CHANGE_FILTERING_RULE_VALUE";
export const CHANGE_FILTERING_RULE_OFFER: "CHANGE_FILTERING_RULE_OFFER" = "CHANGE_FILTERING_RULE_OFFER";
export const CHANGE_FILTERING_RULE_ONE_LINK_OFFER: "CHANGE_FILTERING_RULE_ONE_LINK_OFFER" = "CHANGE_FILTERING_RULE_ONE_LINK_OFFER";
export const CHANGE_FILTERING_ORDER: "CHANGE_FILTERING_ORDER" = "CHANGE_FILTERING_ORDER";
export const CLONE_FILTERING_SAGA: "CLONE_FILTERING_SAGA" = "CLONE_FILTERING_SAGA";

export const OPEN_GENERAL_FILTERS_MODAL: "OPEN_GENERAL_FILTERS_MODAL" = "OPEN_GENERAL_FILTERS_MODAL";
export const CLOSE_GENERAL_FILTERS_MODAL: "CLOSE_GENERAL_FILTERS_MODAL" = "CLOSE_GENERAL_FILTERS_MODAL";

export const CHANGE_FUNNEL_ORDER: "CHANGE_FUNNEL_ORDER" = "CHANGE_FUNNEL_ORDER";
export const SET_CURRENT_SPLIT: "SET_CURRENT_SPLIT" = "SET_CURRENT_SPLIT";
export const OPEN_SPLIT_MODAL: "OPEN_SPLIT_MODAL" = "OPEN_SPLIT_MODAL";
export const CLOSE_SPLIT_MODAL: "CLOSE_SPLIT_MODAL" = "CLOSE_SPLIT_MODAL";
export const SAVE_SPLIT_SAGA: "SAVE_SPLIT_SAGA" = "SAVE_SPLIT_SAGA";
export const OPEN_SPLIT_SAGA: "OPEN_SPLIT_SAGA" = "OPEN_SPLIT_SAGA";
export const CLOSE_SPLIT_SAGA: "CLOSE_SPLIT_SAGA" = "CLOSE_SPLIT_SAGA";

export const FUNNELS_TEMPLATE_LIST_TABLE: "FUNNELS_TEMPLATE_LIST_TABLE" = "FUNNELS_TEMPLATE_LIST_TABLE";

export const GET_TEMPLATE_LIST_SAGA: "GET_TEMPLATE_LIST_SAGA" = "GET_TEMPLATE_LIST_SAGA";
export const DELETE_TEMPLATE_SAGA: "DELETE_TEMPLATE_SAGA" = "DELETE_TEMPLATE_SAGA";
export const FETCH_GENERAL_FILTERS_SAGA: "FETCH_GENERAL_FILTERS_SAGA" = "FETCH_GENERAL_FILTERS_SAGA";
export const OPEN_MODAL_GENERAL_FILTERS_SAGA: "OPEN_MODAL_GENERAL_FILTERS_SAGA" = "OPEN_MODAL_GENERAL_FILTERS_SAGA";
export const SAVE_MODAL_GENERAL_FILTERS_SAGA: "SAVE_MODAL_GENERAL_FILTERS_SAGA" = "SAVE_MODAL_GENERAL_FILTERS_SAGA";
export const CLOSE_MODAL_GENERAL_FILTERS_SAGA: "CLOSE_MODAL_GENERAL_FILTERS_SAGA" = "CLOSE_MODAL_GENERAL_FILTERS_SAGA";
export const START_FETCH_GENERAL_FILTERS: "START_FETCH_GENERAL_FILTERS" = "START_FETCH_GENERAL_FILTERS";
export const SET_GENERAL_FILTERS: "SET_GENERAL_FILTERS" = "SET_GENERAL_FILTERS";
export const SET_ALL_ACTIVE_GENERAL_FILTERS: "SET_ALL_ACTIVE_GENERAL_FILTERS" = "SET_ALL_ACTIVE_GENERAL_FILTERS";
export const SET_ACTIVE_GENERAL_FILTERS: "SET_ACTIVE_GENERAL_FILTERS" = "SET_ACTIVE_GENERAL_FILTERS";
export const GENERAL_FILTER_TABLE: "GENERAL_FILTER_TABLE" = "GENERAL_FILTER_TABLE";
export const CHANGE_GENERAL_FILTER_ACTIVE_SAGA: "CHANGE_GENERAL_FILTER_ACTIVE_SAGA" = "CHANGE_GENERAL_FILTER_ACTIVE_SAGA";
export const CLONE_GENERAL_FILTER_SAGA: "CLONE_GENERAL_FILTER_SAGA" = "CLONE_GENERAL_FILTER_SAGA";
export const SAVE_GENERAL_FILTER_SAGA: "SAVE_GENERAL_FILTER_SAGA" = "SAVE_GENERAL_FILTER_SAGA";
export const GET_GENERAL_FILTER_SAGA: "GET_GENERAL_FILTER_SAGA" = "GET_GENERAL_FILTER_SAGA";

export const CONFIRM: "CONFIRM" = "CONFIRM";
export const SET_CONFIRM_NOTIFICATION_MESSAGE: "SET_CONFIRM_NOTIFICATION_MESSAGE" = "SET_CONFIRM_NOTIFICATION_MESSAGE";
export const OPEN_CONFIRM_NOTIFICATION: "OPEN_CONFIRM_NOTIFICATION" = "OPEN_CONFIRM_NOTIFICATION";
export const CLOSE_CONFIRM_NOTIFICATION: "CLOSE_CONFIRM_NOTIFICATION" = "CLOSE_CONFIRM_NOTIFICATION";
export const SET_CONFIRM_NOTIFICATION_CONFIRMED: "SET_CONFIRM_NOTIFICATION_MESSAGE" = "SET_CONFIRM_NOTIFICATION_MESSAGE";
export const SET_CONFIRM_NOTIFICATION_CANCELED: "SET_CONFIRM_NOTIFICATION_CANCELED" = "SET_CONFIRM_NOTIFICATION_CANCELED";

export const START_FETHING_CAMPAIGN_ID_SAGA: "START_FETHING_CAMPAIGN_ID" = "START_FETHING_CAMPAIGN_ID";
export const SET_FETCHED_CAMPAIGN_ID: "SET_FETCHED_CAMPAIGN_ID" = "SET_FETCHED_CAMPAIGN_ID";

export const TAB_CLICK: "TAB_CLICK" = "TAB_CLICK";
export const TAB_CHANGE: "TAB_CHANGE" = "TAB_CHANGE";
export const TAB_INIT: "TAB_INIT" = "TAB_INIT";

export const CHANGE_DOMAIN: "CHANGE_DOMAIN" = "CHANGE_DOMAIN";
export const CHANGE_CAMPAIGN_NAME: "CHANGE_CAMPAIGN_NAME" = "CHANGE_CAMPAIGN_NAME";
export const CHANGE_CAMPAIGN_EXTERNAL_NAME: "CHANGE_CAMPAIGN_EXTERNAL_NAME" = "CHANGE_CAMPAIGN_EXTERNAL_NAME";
export const CHANGE_FUNNEL_GUARD_OUT: "CHANGE_FUNNEL_GUARD_OUT" = "CHANGE_FUNNEL_GUARD_OUT";
export const CHANGE_TEST_CAMPAIGN: "CHANGE_TEST_CAMPAIGN" = "CHANGE_TEST_CAMPAIGN";
export const CHANGE_REQUEST_LIMIT_PER_SEC: "CHANGE_REQUEST_LIMIT_PER_SEC" = "CHANGE_REQUEST_LIMIT_PER_SEC";
export const CHANGE_AFFILIATE_DATA_TYPE: "CHANGE_AFFILIATE_DATA_TYPE" = "CHANGE_AFFILIATE_DATA_TYPE";

export const ADD_FALLBACK_OFFER: "ADD_FALLBACK_OFFER" = "ADD_FALLBACK_OFFER";
export const DELETE_FALLBACK_OFFER: "DELETE_FALLBACK_OFFER" = "DELETE_FALLBACK_OFFER";
export const DELETE_FALLBACK_OFFER_SAGA: "DELETE_FALLBACK_OFFER_SAGA" = "DELETE_FALLBACK_OFFER_SAGA";

export const ADD_NON_TARGET_OFFERS: "ADD_NON_TARGET_OFFERS" = "ADD_NON_TARGET_OFFERS";
export const DELETE_NON_TARGET_OFFER: "DELETE_NON_TARGET_OFFER" = "DELETE_NON_TARGET_OFFER";
export const DELETE_NON_TARGET_OFFER_SAGA: "DELETE_NON_TARGET_OFFER_SAGA" = "DELETE_NON_TARGET_OFFER_SAGA";

export const DELETE_SERVICE_OFFER: "DELETE_SERVICE_OFFER" = "DELETE_SERVICE_OFFER";

export const SAVE_FALLBACK_OFFER_SAGA: "SAVE_FALLBACK_OFFER_SAGA" = "SAVE_FALLBACK_OFFER_SAGA";
export const SAVE_NON_TARGET_OFFERS_SAGA: "SAVE_NON_TARGET_OFFERS_SAGA" = "SAVE_NON_TARGET_OFFERS_SAGA";

export const ADD_SPLIT_SAGA: "ADD_SPLIT_SAGA" = "ADD_SPLIT_SAGA";
export const ADD_SPLIT: "ADD_SPLIT" = "ADD_SPLIT";
export const DELETE_SPLIT_SAGA: "DELETE_SPLIT_SAGA" = "DELETE_SPLIT_SAGA";
export const DELETE_SPLIT: "DELETE_SPLIT" = "DELETE_SPLIT";
export const CLONE_SPLIT_SAGA: "CLONE_SPLIT_SAGA" = "CLONE_SPLIT_SAGA";
export const SPLIT_IS_ACTIVE_CHANGE: "SPLIT_IS_ACTIVE_CHANGE" = "SPLIT_IS_ACTIVE_CHANGE";
export const SPLIT_IS_ACTIVE_CHANGE_SAGA: "SPLIT_IS_ACTIVE_CHANGE_SAGA" = "SPLIT_IS_ACTIVE_CHANGE_SAGA";
export const MOVE_SPLIT_NAMES: "MOVE_SPLIT_NAMES" = "MOVE_SPLIT_NAMES";

export const FUNNEL_IS_ACTIVE_CHANGE: "FUNNEL_IS_ACTIVE_CHANGE" = "FUNNEL_IS_ACTIVE_CHANGE";
export const FUNNEL_NAME_CHANGE: "FUNNEL_NAME_CHANGE" = "FUNNEL_NAME_CHANGE";
export const DELETE_FUNNEL_SAGA: "DELETE_FUNNEL_SAGA" = "DELETE_FUNNEL_SAGA";
export const DELETE_FUNNEL: "DELETE_FUNNEL" = "DELETE_FUNNEL";
export const CLONE_FUNNEL_SAGA: "CLONE_FUNNEL_SAGA" = "CLONE_FUNNEL_SAGA";
export const ADD_FUNNEL_SAGA: "ADD_FUNNEL_SAGA" = "ADD_FUNNEL_SAGA";
export const ADD_FUNNEL: "ADD_FUNNEL" = "ADD_FUNNEL";
export const OPEN_FUNNEL_SAGA: "OPEN_FUNNEL_SAGA" = "OPEN_FUNNEL_SAGA";
export const CLOSE_FUNNEL_SAGA: "CLOSE_FUNNEL_SAGA" = "CLOSE_FUNNEL_SAGA";
export const SAVE_FUNNEL_SAGA: "SAVE_FUNNEL_SAGA" = "SAVE_FUNNEL_SAGA";

export const ADD_TARGETING: "ADD_TARGETING" = "ADD_TARGETING";
export const DELETE_TARGETING: "DELETE_TARGETING" = "DELETE_TARGETING";
export const CLONE_TARGETING_SAGA: "CLONE_TARGETING_SAGA" = "CLONE_TARGETING_SAGA";
export const ADD_TARGETING_TO_FUNNEL: "ADD_TARGETING_TO_FUNNEL" = "ADD_TARGETING_TO_FUNNEL";

export const ADD_OFFER: "ADD_OFFER" = "ADD_OFFER";
export const OFFER_WEIGHT_CHANGE: "OFFER_WEIGHT_CHANGE" = "OFFER_WEIGHT_CHANGE";
export const DELETE_OFFER: "DELETE_OFFER" = "DELETE_OFFER";
export const EQUAL_SHARES_OFFERS: "EQUAL_SHARES_OFFERS" = "EQUAL_SHARES_OFFERS";
export const CLONE_OFFER_SAGA: "CLONE_OFFER_SAGA" = "CLONE_OFFER_SAGA";
export const SAVE_OFFERS_SAGA: "SAVE_OFFERS_SAGA" = "SAVE_OFFERS_SAGA";
export const TOGGLE_OFFER_ACTIVE_STATUS: "TOGGLE_OFFER_ACTIVE_STATUS" = "TOGGLE_OFFER_ACTIVE_STATUS";
export const CHANGE_OFFER_URL: "CHANGE_OFFER_URL" = "CHANGE_OFFER_URL";

export const SET_BACKUP: "SET_BACKUP" = "SET_BACKUP";
export const CLEAR_BACKUP: "CLEAR_BACKUP" = "CLEAR_BACKUP";
export const RESTORE_BACKUP: "RESTORE_BACKUP" = "RESTORE_BACKUP";

export const GENERAL_FILTER_INFO_CHANGE_NAME: "GENERAL_FILTER_INFO_CHANGE_NAME" = "GENERAL_FILTER_INFO_CHANGE_NAME";
export const GENERAL_FILTER_INFO_CHANGE_DESCRIPTION: "GENERAL_FILTER_INFO_CHANGE_DESCRIPTION" = "GENERAL_FILTER_INFO_CHANGE_DESCRIPTION";
export const GENERAL_FILTER_INFO_CHANGE_PRIORITY: "GENERAL_FILTER_INFO_CHANGE_PRIORITY" = "GENERAL_FILTER_INFO_CHANGE_PRIORITY";
export const GENERAL_FILTER_INFO_CHANGE_RATE: "GENERAL_FILTER_INFO_CHANGE_RATE" = "GENERAL_FILTER_INFO_CHANGE_RATE";
export const GENERAL_FILTER_INFO_CHANGE_MUST_HAVE: "GENERAL_FILTER_INFO_CHANGE_MUST_HAVE" = "GENERAL_FILTER_INFO_CHANGE_MUST_HAVE";
export const GENERAL_FILTER_INFO_CHANGE_IS_ACTIVE: "GENERAL_FILTER_INFO_CHANGE_IS_ACTIVE" = "GENERAL_FILTER_INFO_CHANGE_IS_ACTIVE";
export const GENERAL_FILTER_INFO_ADD_CAMPAIGN_IN: "GENERAL_FILTER_INFO_ADD_CAMPAIGN_IN" = "GENERAL_FILTER_INFO_ADD_CAMPAIGN_IN";
export const GENERAL_FILTER_INFO_DELETE_CAMPAIGN_IN: "GENERAL_FILTER_INFO_DELETE_CAMPAIGN_IN" = "GENERAL_FILTER_INFO_DELETE_CAMPAIGN_IN";
export const GENERAL_FILTER_INFO_ADD_CAMPAIGN_OUT: "GENERAL_FILTER_INFO_ADD_CAMPAIGN_OUT" = "GENERAL_FILTER_INFO_ADD_CAMPAIGN_OUT";
export const GENERAL_FILTER_INFO_SET_CAMPAIGN_OUT: "GENERAL_FILTER_INFO_SET_CAMPAIGN_OUT" = "GENERAL_FILTER_INFO_SET_CAMPAIGN_OUT";
export const GENERAL_FILTER_INFO_OPEN_MODAL_IN: "GENERAL_FILTER_INFO_OPEN_MODAL_IN" = "GENERAL_FILTER_INFO_OPEN_MODAL_IN";
export const GENERAL_FILTER_INFO_OPEN_MODAL_OUT: "GENERAL_FILTER_INFO_OPEN_MODAL_OUT" = "GENERAL_FILTER_INFO_OPEN_MODAL_OUT";
export const GENERAL_FILTER_INFO_UNSET_CAMPAIGN_OUT: "GENERAL_FILTER_INFO_UNSET_CAMPAIGN_OUT" = "GENERAL_FILTER_INFO_UNSET_CAMPAIGN_OUT";
export const GENERAL_FILTER_INFO_DELETE_CAMPAIGN_OUT: "GENERAL_FILTER_INFO_DELETE_CAMPAIGN_OUT" = "GENERAL_FILTER_INFO_DELETE_CAMPAIGN_OUT";
export const GENERAL_FILTER_INFO_CHANGE_FILTERS_ID: "GENERAL_FILTER_INFO_CHANGE_FILTERS_ID" = "GENERAL_FILTER_INFO_CHANGE_FILTERS_ID";
export const GENERAL_FILTER_INFO_CHANGE_FILTERS_NAME: "GENERAL_FILTER_INFO_CHANGE_FILTERS_NAME" = "GENERAL_FILTER_INFO_CHANGE_FILTERS_NAME";
export const GENERAL_FILTER_INFO_CHANGE_FILTERS_TYPE: "GENERAL_FILTER_INFO_CHANGE_FILTERS_TYPE" = "GENERAL_FILTER_INFO_CHANGE_FILTERS_TYPE";
export const GENERAL_FILTER_INFO_CHANGE_FILTERS_STATUS: "GENERAL_FILTER_INFO_CHANGE_FILTERS_STATUS" = "GENERAL_FILTER_INFO_CHANGE_FILTERS_STATUS";
export const GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_TAGS: "GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_TAGS" = "GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_TAGS";
export const GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_TAGS: "GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_TAGS" = "GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_TAGS";
export const GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_TAGS: "GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_TAGS" = "GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_TAGS";
export const GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_FILTER: "GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_FILTER" = "GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_FILTER";
export const GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_FILTER: "GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_FILTER" = "GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_FILTER";
export const GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_FILTER: "GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_FILTER" = "GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_FILTER";
export const GENERAL_FILTER_INFO_CHANGE_FILTERS_RESET_FILTERS: "GENERAL_FILTER_INFO_CHANGE_FILTERS_RESET_FILTERS" = "GENERAL_FILTER_INFO_CHANGE_FILTERS_RESET_FILTERS";
export const GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_IN: "GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_IN" = "GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_IN";
export const GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_OUT: "GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_OUT" = "GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_OUT";
export const GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_IN: "GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_IN" = "GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_IN";
export const GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_OUT: "GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_OUT" = "GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_OUT";
export const GENERAL_FILTER_INFO_MODAL_CAMPAIGN_IN_SAVE: "GENERAL_FILTER_INFO_MODAL_CAMPAIGN_IN_SAVE" = "GENERAL_FILTER_INFO_MODAL_CAMPAIGN_IN_SAVE";
export const GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_EDIT: "GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_EDIT" = "GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_EDIT";
export const GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_ADD: "GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_ADD" = "GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_ADD";
export const GENERAL_FILTER_INFO_EQUAL_SHARES: "GENERAL_FILTER_INFO_EQUAL_SHARES" = "GENERAL_FILTER_INFO_EQUAL_SHARES";
export const GENERAL_FILTER_INFO_SET_WEIGHT: "GENERAL_FILTER_INFO_SET_WEIGHT" = "GENERAL_FILTER_INFO_SET_WEIGHT";
export const GENERAL_FILTER_INFO_INIT_STATE_EDIT: "GENERAL_FILTER_INFO_INIT_STATE_EDIT" = "GENERAL_FILTER_INFO_INIT_STATE_EDIT";
export const GENERAL_FILTER_CHANGE_TARGETING: "GENERAL_FILTER_CHANGE_TARGETING" = "GENERAL_FILTER_CHANGE_TARGETING";
export const GENERAL_FILTER_INFO_FACT: "GENERAL_FILTER_INFO_FACT" = "GENERAL_FILTER_INFO_FACT";
export const GENERAL_FILTER_INFO_DRYMODE: "GENERAL_FILTER_INFO_DRYMODE" = "GENERAL_FILTER_INFO_DRYMODE";

export const OPEN_FUNNEL_MODAL: "OPEN_FUNNEL_MODAL" = "OPEN_FUNNEL_MODAL";
export const CLOSE_FUNNEL_MODAL: "CLOSE_FUNNEL_MODAL" = "CLOSE_FUNNEL_MODAL";
export const SET_CURRENT_FUNNEL: "SET_CURRENT_FUNNEL" = "SET_CURRENT_FUNNEL";
export const OPEN_OFFERS_MODAL: "OPEN_OFFERS_MODAL" = "OPEN_OFFERS_MODAL";
export const CLOSE_OFFERS_MODAL: "CLOSE_OFFERS_MODAL" = "CLOSE_OFFERS_MODAL";

export const CHANGE_PLACEHOLDER: "CHANGE_PLACEHOLDER" = "CHANGE_PLACEHOLDER";
export const CHANGE_PLACEHOLDER_VALUE: "CHANGE_PLACEHOLDER_VALUE" = "CHANGE_PLACEHOLDER_VALUE";
export const CLICK_PRESET: "CLICK_PRESET" = "CLICK_PRESET";
export const CLEAR_PLACEHOLDERS: "CLEAR_PLACEHOLDERS" = "CLEAR_PLACEHOLDERS";

export const CHANGE_SPLITS_FREQUENCY_METHOD: "CHANGE_SPLITS_FREQUENCY_METHOD" = "CHANGE_SPLITS_FREQUENCY_METHOD";
export const CHANGE_SPLITS_FREQUENCY_PERIOD: "CHANGE_SPLITS_FREQUENCY_PERIOD" = "CHANGE_SPLITS_FREQUENCY_PERIOD";
export const CHANGE_SPLITS_FREQUENCY_SCOPE: "CHANGE_SPLITS_FREQUENCY_SCOPE" = "CHANGE_SPLITS_FREQUENCY_SCOPE";
export const CHANGE_SPLITS_FREQUENCY_DEFAULT_SPLIT: "CHANGE_SPLITS_FREQUENCY_DEFAULT_SPLIT" = "CHANGE_SPLITS_FREQUENCY_DEFAULT_SPLIT";

export const SET_SPLIT_WEIGHT: "SET_SPLIT_WEIGHT" = "SET_SPLIT_WEIGHT";
export const SET_SPLIT_FREQUENCY: "SET_SPLIT_FREQUENCY" = "SET_SPLIT_FREQUENCY";
export const SPLIT_WEIGHTS_EQUAL_SHARES: "SPLIT_WEIGHTS_EQUAL_SHARES" = "SPLIT_WEIGHTS_EQUAL_SHARES";

export const SET_TARGETING_VALUES: "SET_TARGETING_VALUES" = "SET_TARGETING_VALUES";
export const SET_TARGETING_VALUE: "SET_TARGETING_VALUE" = "SET_TARGETING_VALUE";
export const SET_TARGETING_RULES: "SET_TARGETING_RULES" = "SET_TARGETING_RULES";
export const SELECT: "SELECT" = "SELECT";
export const MULTISELECT: "MULTISELECT" = "MULTISELECT";
export const MULTISELECT_WITH_SUBVALUES: "MULTISELECT_WITH_SUBVALUES" = "MULTISELECT_WITH_SUBVALUES";
export const MULTISELECT_CUSTOM: "MULTISELECT_CUSTOM" = "MULTISELECT_CUSTOM";
export const WITHOUT_VALUE: "WITHOUT_VALUE" = "WITHOUT_VALUE";
export const FETCH_TARGETING_SAGA: "FETCH_TARGETING_SAGA" = "FETCH_TARGETING_SAGA";
export const FETCH_TARGETING_VALUES_SAGA: "FETCH_TARGETING_VALUES_SAGA" = "FETCH_TARGETING_VALUES_SAGA";
export const START_FETCH_TARGETINGS_RULES: "START_FETCH_TARGETINGS_RULES" = "START_FETCH_TARGETINGS_RULES";
export const SUCCESS_FETCH_TARGETINGS_RULES: "SUCCESS_FETCH_TARGETINGS_RULES" = "SUCCESS_FETCH_TARGETINGS_RULES";
export const ERROR_START_FETCH_TARGETINGS_RULES: "ERROR_START_FETCH_TARGETINGS_RULES" = "ERROR_START_FETCH_TARGETINGS_RULES";
export const START_FETCH_TARGETINGS_VALUES: "START_FETCH_TARGETINGS_VALUES" = "START_FETCH_TARGETINGS_VALUES";
export const SUCCESS_FETCH_TARGETINGS_VALUES: "SUCCESS_FETCH_TARGETINGS_VALUES" = "SUCCESS_FETCH_TARGETINGS_VALUES";
export const CHANGE_TARGETING_GROUP_OPERATOR: "CHANGE_TARGETING_GROUP_OPERATOR" = "CHANGE_TARGETING_GROUP_OPERATOR";
export const ADD_TARGETING_RULE: "ADD_TARGETING_RULE" = "ADD_TARGETING_RULE";
export const ADD_TARGETING_GROUP: "ADD_TARGETING_GROUP" = "ADD_TARGETING_GROUP";
export const REMOVE_TARGETING_GROUP: "REMOVE_TARGETING_GROUP" = "REMOVE_TARGETING_GROUP";
export const CHANGE_TARGETING_VALUE: "CHANGE_TARGETING_VALUE" = "CHANGE_TARGETING_VALUE";
export const CHANGE_TARGETING_OPERATOR: "CHANGE_TARGETING_OPERATOR" = "CHANGE_TARGETING_OPERATOR";
export const CHANGE_TARGETING_RULE: "CHANGE_TARGETING_RULE" = "CHANGE_TARGETING_RULE";
export const ADD_DICTIONARY: "ADD_DICTIONARY" = "ADD_DICTIONARY";
export const ADD_DICTIONARY_OPTIONS: "ADD_DICTIONARY_OPTIONS" = "ADD_DICTIONARY_OPTIONS";

export const SAVE_CAMPAIGN_SAGA: "SAVE_CAMPAIGN_SAGA" = "SAVE_CAMPAIGN_SAGA";
export const START_SAVE_CAMPAIGN: "START_SAVE_CAMPAIGN" = "START_SAVE_CAMPAIGN";

export const ADD_MANUAL_TAG: "ADD_MANUAL_TAG" = "ADD_MANUAL_TAG";
export const REMOVE_MANUAL_TAG: "REMOVE_MANUAL_TAG" = "REMOVE_MANUAL_TAG";

export const CHANGE_TEMPLATE_NAME: "CHANGE_TEMPLATE_NAME" = "CHANGE_TEMPLATE_NAME";
export const CHANGE_TEMPLATE_TYPE: "CHANGE_TEMPLATE_TYPE" = "CHANGE_TEMPLATE_TYPE";

export const SAVE_TEMPLATE_SAGA: "SAVE_TEMPLATE_SAGA" = "SAVE_TEMPLATE_SAGA";
export const START_SAVE_TEMPLATE: "START_SAVE_TEMPLATE" = "START_SAVE_TEMPLATE";
export const OPEN_FUNNELTEMPLATE_LIST_SAGA: "OPEN_FUNNELTEMPLATE_LIST_SAGA" = "OPEN_FUNNELTEMPLATE_LIST_SAGA";

export const FUNNELTEMPLATE_LIST_MODAL_OPEN: "FUNNELTEMPLATE_LIST_MODAL_OPEN" = "FUNNELTEMPLATE_LIST_MODAL_OPEN";
export const FUNNELTEMPLATE_LIST_MODAL_CLOSE: "FUNNELTEMPLATE_LIST_MODAL_CLOSE" = "FUNNELTEMPLATE_LIST_MODAL_CLOSE";
export const FUNNELTEMPLATE_LIST_MODAL_SET_SPLIT_ID: "FUNNELTEMPLATE_LIST_MODAL_SET_SPLIT_ID" = "FUNNELTEMPLATE_LIST_MODAL_SET_SPLIT_ID";
export const FUNNELTEMPLATE_LIST_MODAL_SET_TEMPLATE_ID: "FUNNELTEMPLATE_LIST_MODAL_SET_TEMPLATE_ID" = "FUNNELTEMPLATE_LIST_MODAL_SET_TEMPLATE_ID";
export const FUNNELTEMPLATE_LIST_SET_COUNT: "FUNNELTEMPLATE_LIST_SET_COUNT" = "FUNNELTEMPLATE_LIST_SET_COUNT";

export const CHANGE_LOADING_STATUS: "CHANGE_LOADING_STATUS" = "CHANGE_LOADING_STATUS";

export const FETCH_FUNNEL_TEMPLATE_SAGA: "FETCH_FUNNEL_TEMPLATE_SAGA" = "FETCH_FUNNEL_TEMPLATE_SAGA";

export const SET_FUNNELTEMPLATE: "SET_FUNNELTEMPLATE" = "SET_FUNNELTEMPLATE";
export const SET_FUNNELTEMPLATE_LIST: "SET_FUNNELTEMPLATE_LIST" = "SET_FUNNELTEMPLATE_LIST";
export const SET_SPLIT_FUNNELTEMPLATE: "SET_SPLIT_FUNNELTEMPLATE" = "SET_SPLIT_FUNNELTEMPLATE";
export const SET_CAMPAIGN_JSON: "SET_CAMPAIGN_JSON" = "SET_CAMPAIGN_JSON";
export const FETCH_CAMPAIGN_JSON_SAGA: "FETCH_CAMPAIGN_JSON_SAGA" = "FETCH_CAMPAIGN_JSON_SAGA";

export const SUCCESS_FETCH_FUNNELTEMPLATE_CAMPAIGN_LIST: "SUCCESS_FETCH_FUNNELTEMPLATE_CAMPAIGN_LIST" = "SUCCESS_FETCH_FUNNELTEMPLATE_CAMPAIGN_LIST";
export const ERROR_FETCH_FUNNELTEMPLATE_CAMPAIGN_LIST: "ERROR_FETCH_FUNNELTEMPLATE_CAMPAIGN_LIST" = "ERROR_FETCH_FUNNELTEMPLATE_CAMPAIGN_LIST";

export const FUNNELTEMPLATE_CAMPAIGN_LIST_LOAD_PAGE_SAGA: "FUNNELTEMPLATE_CAMPAIGN_LIST_LOAD_PAGE_SAGA" = "FUNNELTEMPLATE_CAMPAIGN_LIST_LOAD_PAGE_SAGA";
export const FUNNELTEMPLATE_REPORT_SET_GROUP_FILTERS_ORDER: "FUNNELTEMPLATE_REPORT_SET_GROUP_FILTERS_ORDER" = "FUNNELTEMPLATE_REPORT_SET_GROUP_FILTERS_ORDER";

export const GET_FUNNELS_TEMPLATES_REPORT_LIST_SAGA: "GET_FUNNELS_TEMPLATES_REPORT_LIST_SAGA" = "GET_FUNNELS_TEMPLATES_REPORT_LIST_SAGA";

export const CHANGE_TABLE_PAGE: "CHANGE_TABLE_PAGE" = "CHANGE_TABLE_PAGE";
export const CHANGE_TABLE_SORTING: "CHANGE_TABLE_SORTING" = "CHANGE_TABLE_SORTING";
export const CHANGE_TABLE_FILTERS: "CHANGE_TABLE_FILTERS" = "CHANGE_TABLE_FILTERS";

export const CHANGE_CONFIGURE_FUNNEL_TEMPLATE_REPORT_COLUMNS_MODAL_OPEN: "CHANGE_CONFIGURE_FUNNEL_TEMPLATE_REPORT_COLUMNS_MODAL_OPEN" = "CHANGE_CONFIGURE_FUNNEL_TEMPLATE_REPORT_COLUMNS_MODAL_OPEN";
export const REMOVE_TARGETING_RULE: "REMOVE_TARGETING_RULE" = "REMOVE_TARGETING_RULE";
export const CHANGE_FILTERING_RULE_OFFER_V2: "CHANGE_FILTERING_RULE_OFFER_V2" = "CHANGE_FILTERING_RULE_OFFER_V2";
export const CHANGE_FILTERING_RULE_VALUE_V2: "CHANGE_FILTERING_RULE_VALUE_V2" = "CHANGE_FILTERING_RULE_VALUE_V2";

/* start ANTIFRAUD */
export const GET_ANTIFRAUD_TRIGGER_LIST_SAGA: "GET_ANTIFRAUD_TRIGGER_LIST_SAGA" = "GET_ANTIFRAUD_TRIGGER_LIST_SAGA";
export const CHANGE_ANTIFRAUD_TRIGGER_ACTIVE_SAGA: "CHANGE_ANTIFRAUD_TRIGGER_ACTIVE_SAGA" = "CHANGE_ANTIFRAUD_TRIGGER_ACTIVE_SAGA";
export const GET_ANTIFRAUD_TRIGGER_SAGA: "GET_ANTIFRAUD_TRIGGER_SAGA" = "GET_ANTIFRAUD_TRIGGER_SAGA";
export const SET_ANTIFRAUD_TRIGGER: "SET_ANTIFRAUD_TRIGGER" = "SET_ANTIFRAUD_TRIGGER";
export const SET_ANTIFRAUD_TRIGGER_FORM_FIELD: "SET_ANTIFRAUD_TRIGGER_FORM_FIELD" = "SET_ANTIFRAUD_TRIGGER_FORM_FIELD";
export const SAVE_ANTIFRAUD_TRIGGER_SAGA: "SAVE_ANTIFRAUD_TRIGGER_SAGA" = "SAVE_ANTIFRAUD_TRIGGER_SAGA";
export const GET_ANTIFRAUD_CONDITIONS_LIST_SAGA: "GET_ANTIFRAUD_CONDITIONS_LIST_SAGA" = "GET_ANTIFRAUD_CONDITIONS_LIST_SAGA";
export const GET_ANTIFRAUD_CONDITIONS_VALUES_SAGA: "GET_ANTIFRAUD_CONDITIONS_VALUES_SAGA" = "GET_ANTIFRAUD_CONDITIONS_VALUES_SAGA";
export const ADD_ANTIFRAUD_CONDITION: "ADD_ANTIFRAUD_CONDITION" = "ADD_ANTIFRAUD_CONDITION";
export const ADD_ANTIFRAUD_CONDITION_GROUP: "ADD_ANTIFRAUD_CONDITION_GROUP" = "ADD_ANTIFRAUD_CONDITION_GROUP";
export const CHANGE_ANTIFRAUD_CONDITION_GROUP_OPERATOR: "CHANGE_ANTIFRAUD_CONDITION_GROUP_OPERATOR" = "CHANGE_ANTIFRAUD_CONDITION_GROUP_OPERATOR";
export const DELETE_ANTIFRAUD_CONDITION_RULE: "DELETE_ANTIFRAUD_CONDITION_RULE" = "DELETE_ANTIFRAUD_CONDITION_RULE";
export const DELETE_ANTIFRAUD_CONDITION_GROUP: "DELETE_ANTIFRAUD_CONDITION_GROUP" = "DELETE_ANTIFRAUD_CONDITION_GROUP";
export const CHANGE_ANTIFRAUD_CONDITION_RULE: "CHANGE_ANTIFRAUD_CONDITION_RULE" = "CHANGE_ANTIFRAUD_CONDITION_RULE";
export const CHANGE_ANTIFRAUD_CONDITION_OPERATOR: "CHANGE_ANTIFRAUD_CONDITION_OPERATOR" = "CHANGE_ANTIFRAUD_CONDITION_OPERATOR";
export const CHANGE_ANTIFRAUD_CONDITION_VALUE: "CHANGE_ANTIFRAUD_CONDITION_VALUE" = "CHANGE_ANTIFRAUD_CONDITION_VALUE";
export const CHANGE_ANTIFRAUD_CONDITION_VALUE_TYPE: "CHANGE_ANTIFRAUD_CONDITION_VALUE_TYPE" = "CHANGE_ANTIFRAUD_CONDITION_VALUE_TYPE";
export const CHANGE_ANTIFRAUD_CONDITION_LOCATION: "CHANGE_ANTIFRAUD_CONDITION_LOCATION" = "CHANGE_ANTIFRAUD_CONDITION_LOCATION";

export const SET_CONDITION_LISTS: "SET_CONDITION_LISTS" = "SET_CONDITION_LISTS";
export const TRIGGER_VALUE_TYPE: "value" = "value";
export const TRIGGER_FEATURE_TYPE: "feature" = "feature";

export const ANTIFRAUD_TRIGGERS_TABLE: "ANTIFRAUD_TRIGGERS_TABLE" = "ANTIFRAUD_TRIGGERS_TABLE";

export const ANTIFRAUD_DICTIONARIES_TABLE: "ANTIFRAUD_DICTIONARIES_TABLE" = "ANTIFRAUD_DICTIONARIES_TABLE";
export const GET_ANTIFRAUD_DICTIONARIES_LIST_SAGA: "GET_ANTIFRAUD_DICTIONARIES_LIST_SAGA" = "GET_ANTIFRAUD_DICTIONARIES_LIST_SAGA";

export const GET_ANTIFRAUD_DICTIONARY_SAGA: "GET_ANTIFRAUD_DICTIONARY_SAGA" = "GET_ANTIFRAUD_DICTIONARY_SAGA";
export const SET_ANTIFRAUD_DICTIONARY: "SET_ANTIFRAUD_DICTIONARY" = "SET_ANTIFRAUD_DICTIONARY";
export const SET_ANTIFRAUD_DICTIONARY_FORM_FIELD: "SET_ANTIFRAUD_DICTIONARY_FORM_FIELD" = "SET_ANTIFRAUD_DICTIONARY_FORM_FIELD";
export const SAVE_ANTIFRAUD_DICTIONARY_SAGA: "SAVE_ANTIFRAUD_DICTIONARY_SAGA" = "SAVE_ANTIFRAUD_DICTIONARY_SAGA";
export const GET_ANTIFRAUD_DICTIONARY_ACTIONS: "GET_ANTIFRAUD_DICTIONARY_ACTIONS" = "GET_ANTIFRAUD_DICTIONARY_ACTIONS";
export const DYNAMIC_DICT: "Dynamic dict" = "Dynamic dict";
export const VALUE_DICT: "Value dict" = "Value dict";

export const GET_ANTIFRAUD_SCENARIO_SAGA: "GET_ANTIFRAUD_SCENARIO_SAGA" = "GET_ANTIFRAUD_SCENARIO_SAGA";
export const SET_ANTIFRAUD_SCENARIO: "SET_ANTIFRAUD_SCENARIO" = "SET_ANTIFRAUD_SCENARIO";
export const SET_ANTIFRAUD_SCENARIO_FORM_FIELD: "SET_ANTIFRAUD_SCENARIO_FORM_FIELD" = "SET_ANTIFRAUD_SCENARIO_FORM_FIELD";
export const SET_ANTIFRAUD_SCENARIO_RULES: "SET_ANTIFRAUD_SCENARIO_RULES" = "SET_ANTIFRAUD_SCENARIO_RULES";
export const REMOVE_ANTIFRAUD_SCENARIO_RULE: "REMOVE_ANTIFRAUD_SCENARIO_RULE" = "REMOVE_ANTIFRAUD_SCENARIO_RULE";
export const SAVE_ANTIFRAUD_SCENARIO_SAGA: "SAVE_ANTIFRAUD_SCENARIO_SAGA" = "SAVE_ANTIFRAUD_SCENARIO_SAGA";
export const GET_ANTIFRAUD_SCENARIOS_LIST_SAGA: "GET_ANTIFRAUD_SCENARIOS_LIST_SAGA" = "GET_ANTIFRAUD_SCENARIOS_LIST_SAGA";
export const CHANGE_ANTIFRAUD_SCENARIO_ACTIVE_SAGA: "CHANGE_ANTIFRAUD_SCENARIO_ACTIVE_SAGA" = "CHANGE_ANTIFRAUD_SCENARIO_ACTIVE_SAGA";
export const ANTIFRAUD_SCENARIOS_TABLE: "ANTIFRAUD_SCENARIOS_TABLE" = "ANTIFRAUD_SCENARIOS_TABLE";
export const ADD_ANTIFRAUD_SCENARIO_ACTION: "ADD_ANTIFRAUD_SCENARIO_ACTION" = "ADD_ANTIFRAUD_SCENARIO_ACTION";
export const DELETE_ANTIFRAUD_SCENARIO_ACTION: "DELETE_ANTIFRAUD_SCENARIO_ACTION" = "DELETE_ANTIFRAUD_SCENARIO_ACTION";
export const SET_ANTIFRAUD_SCENARIO_ACTION: "SET_ANTIFRAUD_SCENARIO_ACTION" = "SET_ANTIFRAUD_SCENARIO_ACTION";
export const DEFAULT_SCENARIO: "default" = "default";
export const DYNAMIC_SCENARIO: "dynamicDict" = "dynamicDict";
export const CHANGE_ANTIFRAUD_SCENARIO_RULE_STATUS: "CHANGE_ANTIFRAUD_SCENARIO_RULE_STATUS" = "CHANGE_ANTIFRAUD_SCENARIO_RULE_STATUS";
/* end ANTIFRAUD */

export const ANTIFRAUD_VARIABLES_TABLE: "ANTIFRAUD_VARIABLES_TABLE" = "ANTIFRAUD_VARIABLES_TABLE";
export const GET_ANTIFRAUD_VARIABLES_LIST_SAGA: "GET_ANTIFRAUD_VARIABLES_LIST_SAGA" = "GET_ANTIFRAUD_VARIABLES_LIST_SAGA";
export const CHANGE_ANTIFRAUD_VARIABLE_ACTIVE_SAGA: "CHANGE_ANTIFRAUD_VARIABLE_ACTIVE_SAGA" = "CHANGE_ANTIFRAUD_VARIABLE_ACTIVE_SAGA";

export const ANTIFRAUD_VARIABLE_FORM: "ANTIFRAUD_VARIABLE_FORM" = "ANTIFRAUD_VARIABLE_FORM";
export const GET_ANTIFRAUD_VARIABLE_DROPDOWNS_SAGA: "GET_ANTIFRAUD_VARIABLE_DROPDOWNS_SAGA" = "GET_ANTIFRAUD_VARIABLE_DROPDOWNS_SAGA";
export const ANTIFRAUD_VARIABLE_SOURCE_REQUEST: "request" = "request";
export const ANTIFRAUD_VARIABLE_SOURCE_DICTIONARY: "dictionary" = "dictionary";

/* flat gf */
export const ADD_TARGETING_GF: "ADD_TARGETING_GF" = "ADD_TARGETING_GF";
export const ADD_TARGETING_GROUP_GF: "ADD_TARGETING_GROUP_GF" = "ADD_TARGETING_GROUP_GF";
export const CHANGE_GROUP_OPERATOR_GF: "CHANGE_GROUP_OPERATOR_GF" = "CHANGE_GROUP_OPERATOR_GF";
export const DELETE_TARGETING_GF: "DELETE_TARGETING_GF" = "DELETE_TARGETING_GF";
export const CHANGE_TARGETING_GF_RULE: "CHANGE_TARGETING_GF_RULE" = "CHANGE_TARGETING_GF_RULE";
export const CHANGE_TARGETING_GF_OPERATOR: "CHANGE_TARGETING_GF_OPERATOR" = "CHANGE_TARGETING_GF_OPERATOR";
export const CHANGE_TARGETING_GF_VALUE: "CHANGE_TARGETING_GF_VALUE" = "CHANGE_TARGETING_GF_VALUE";
export const FETCH_TARGETING_GF_SAGA: "FETCH_TARGETING_GF_SAGA" = "FETCH_TARGETING_GF_SAGA";
export const REMOVE_TARGETING_GF_GROUP_SAGA: "REMOVE_TARGETING_GF_GROUP_SAGA" = "REMOVE_TARGETING_GF_GROUP_SAGA";
export const INIT_TARGETING_GF: "INIT_TARGETING_GF" = "INIT_TARGETING_GF";
export const SAVE_TARGETING_GF_SAGA: "SAVE_TARGETING_GF_SAGA" = "SAVE_TARGETING_GF_SAGA";
/* end flat gf */

export const URL_TYPE_REDIRECT: "redirect" = "redirect";
export const URL_TYPE_RICHMEDIA: "richMedia" = "richMedia";
export const URL_TYPE_IFRAME: "iframe" = "iframe";
export const URL_TYPE_API: "api" = "api";
export const URL_TYPE_AE: "ae" = "ae";
export const URL_TYPE_AEAPI: "aeApi" = "aeApi";
export const URL_TYPE_SHORTURL: "shorturl" = "shorturl";

export const OFFERS_TABLE: "OFFERS_TABLE" = "OFFERS_TABLE";
export const GET_OFFERS_SAGA: "GET_OFFERS_SAGA" = "GET_OFFERS_SAGA";

export const FUNNEL_TEMPLATE_CAMPAIGNS_TABLE: "FUNNEL_TEMPLATE_CAMPAIGNS_TABLE" = "FUNNEL_TEMPLATE_CAMPAIGNS_TABLE";
export const FUNNEL_TEMPLATE_REPORT_TABLE: "FUNNEL_TEMPLATE_REPORT_TABLE" = "FUNNEL_TEMPLATE_REPORT_TABLE";
export const GENERAL_FILTERS_CAMPAIGNS_TABLE: "GENERAL_FILTERS_CAMPAIGNS_TABLE" = "GENERAL_FILTERS_CAMPAIGNS_TABLE";

export const FETCH_GENERAL_FILTER_CAMPAIGNS_SAGA: "FETCH_GENERAL_FILTER_CAMPAIGNS_SAGA" = "FETCH_GENERAL_FILTER_CAMPAIGNS_SAGA";

export const GENERAL_FILTER_CAMPAIGNS_IN_MODAL: "GENERAL_FILTER_CAMPAIGNS_IN_MODAL" = "GENERAL_FILTER_CAMPAIGNS_IN_MODAL";
export const GENERAL_FILTER_CAMPAIGNS_OUT_MODAL: "GENERAL_FILTER_CAMPAIGNS_OUT_MODAL" = "GENERAL_FILTER_CAMPAIGNS_OUT_MODAL";

export const GET_DICTIONARY_SAGA: "GET_DICTIONARY_SAGA" = "GET_DICTIONARY_SAGA";

export const FREQUENCY_CAP_FOR_JUMPS_TABLE: "FREQUENCY_CAP_FOR_JUMPS_TABLE" = "FREQUENCY_CAP_FOR_JUMPS_TABLE";
export const CHANGE_IS_FREQUENCY_CAP: "CHANGE_IS_FREQUENCY_CAP" = "CHANGE_IS_FREQUENCY_CAP";
export const IS_CHANGE_IS_FREQUENCY_CAP_LOADING: "IS_CHANGE_IS_FREQUENCY_CAP_LOADING" = "IS_CHANGE_IS_FREQUENCY_CAP_LOADING";

export const GET_SHORT_URL_SAGA: "GET_SHORT_URL_SAGA" = "GET_SHORT_URL_SAGA";
export const SET_SHORT_URL: "SET_SHORT_URL" = "SET_SHORT_URL";

export const SMARTLINK_AGGREGATORS_TABLE: "SMARTLINK_AGGREGATORS_TABLE" = "SMARTLINK_AGGREGATORS_TABLE";
export const SMARTLINK_AGGREGATORS_TO_CAMPAIGNS_TABLE: "SMARTLINK_AGGREGATORS_TO_CAMPAIGNS_TABLE" = "SMARTLINK_AGGREGATORS_TO_CAMPAIGNS_TABLE";
export const SMARTLINK_REDIRECT_FORM: "SMARTLINK_REDIRECT_FORM" = "SMARTLINK_REDIRECT_FORM";
export const CHANGE_SMARTLINK_AGGREGATOR_ACTIVE: "CHANGE_SMARTLINK_AGGREGATOR_ACTIVE" = "CHANGE_SMARTLINK_AGGREGATOR_ACTIVE";

export const HISTORY_REPLACE_TABLE: "HISTORY_REPLACE_TABLE" = "HISTORY_REPLACE_TABLE";
export const HISTORY_REPLACE_FORM: "HISTORY_REPLACE_FORM" = "HISTORY_REPLACE_FORM";
export const SET_HISTORY_REPLACE_LISTS: "SET_HISTORY_REPLACE_LISTS" = "SET_HISTORY_REPLACE_LISTS";
export const GET_HISTORY_REPLACE_LISTS: "GET_HISTORY_REPLACE_LISTS" = "GET_HISTORY_REPLACE_LISTS";
export const GET_HISTORY_REPLACE_LISTS_LOADING: "GET_HISTORY_REPLACE_LISTS_LOADING" = "GET_HISTORY_REPLACE_LISTS_LOADING";
export const GENERAL_FILTER_INFO_RERENDER: "GENERAL_FILTER_INFO_RERENDER" = "GENERAL_FILTER_INFO_RERENDER";
