// @flow
import { Box, Button } from "@mui/material";
import React from "react";

const Footer = ({ onApply, disabled }: {onApply: () => void, disabled: boolean}) => (
  <Box display="flex" justifyContent="flex-end" p={3}>
    <Button
      disabled={disabled}
      color="secondary"
      variant="contained"
      onClick={onApply}
      data-testid="applyButton"
    >
      Apply
    </Button>
  </Box>
);

export default Footer;
