/* eslint-disable import/max-dependencies */
// @flow
import React, {
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import { Table, SelectSearchComponent, IsActiveLabel } from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { userService } from "../../services/user";
import { ANTIFRAUD_DICTIONARIES_TABLE, DYNAMIC_DICT, VALUE_DICT } from "../../helpers/constants";
import { getAntifraudDictionariesListSaga, type GetAntifraudDictionariesListSaga } from "../../actions/antifraudDictionaries";
import TableActions from "../TdsAdminTableActions";
import type {
  Props,
  DictionaryListItem,
} from "./types/AntifraudDictionariesList.types";

export type Classes = {
  smallTableCol: string,
  middleTableCol: string,
  longTableCol: string,
};

const useStyles: () => Classes = makeStyles(() => ({
  smallTableCol: {
    minWidth: "80px",
    maxWidth: "80px",
    wordBreak: "break-all",
  },
  middleTableCol: {
    minWidth: "160px",
    maxWidth: "160px",
    wordBreak: "break-all",
  },
  longTableCol: {
    minWidth: "350px",
    maxWidth: "350px",
    wordBreak: "break-all",
  },
}));

const onCreate: () => void = () => {
  window.location = "/antifraudDictionaries";
};

const AntifraudDictionariesList: StatelessFunctionalComponent<Props> = () => {
  const classes: Classes = useStyles();
  const tableProps: TableProps = useTable(ANTIFRAUD_DICTIONARIES_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onGetAntifraudDictionariesListSaga: () => GetAntifraudDictionariesListSaga = () => (
    dispatch(getAntifraudDictionariesListSaga()));

  const getIsEditAvailable: (items: DictionaryListItem[]) => boolean = (items) => (
    items.length > 1 || !userService.can([
      { path: "/api/v1/frodi/dictionaries", method: "GET" },
      { path: "/api/v1/frodi/dictionaries", method: "PUT" },
    ]));

  const columns: Array<Column<DictionaryListItem>> = [
    {
      field: "dictId",
      label: "ID",
      className: classes.middleTableCol,
      searchable: true,
      sortable: true,
    },
    {
      field: "dictTypeName",
      label: "Type",
      className: classes.middleTableCol,
      searchable: true,
      sortable: true,
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { title: "All", value: "" },
            { title: VALUE_DICT, value: VALUE_DICT },
            { title: DYNAMIC_DICT, value: DYNAMIC_DICT },
          ]}
        />
      ),
    },
    {
      field: "name",
      className: classes.middleTableCol,
      label: "Dictionary",
      searchable: true,
      sortable: true,
    },
    {
      field: "description",
      label: "Description",
      className: classes.longTableCol,
      searchable: true,
      sortable: true,
    },
    {
      field: "isActive",
      label: "Is Active",
      searchable: true,
      sortable: true,
      className: classes.smallTableCol,
      render: IsActiveLabel,
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { title: "All", value: "" },
            { title: "Active", value: "true" },
            { title: "Inactive", value: "false" },
          ]}
          label="isActive"
        />
      ),
    },
  ];

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        title="Dictionaries"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => false}
        onCreate={onCreate}
        onLoad={onGetAntifraudDictionariesListSaga}
        Actions={(
          <TableActions
            tableKey={ANTIFRAUD_DICTIONARIES_TABLE}
            editPath="/antifraudDictionaries/:id"
            getIsEditAvailable={getIsEditAvailable}
          />
        )}
      />
    </Box>
  );
};

export default AntifraudDictionariesList;
