// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Table } from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { useDispatch } from "react-redux";
import { type ReportRow } from "./types/FunnelsTemplateReportList.types";
import { FUNNEL_TEMPLATE_REPORT_TABLE } from "../../helpers/constants";
import { getFunnelTemplatesReportListSaga } from "../../actions/funnelTemplateReport";

type Props = {|
  columns: Column<ReportRow>[],
|};

const allSelectAvailable: () => boolean = () => false;
const rowSelectAvailable: () => boolean = () => false;

const FunnelsTemplateReportList: StatelessFunctionalComponent<Props> = ({ columns }) => {
  const dispatch: <A>(A) => A = useDispatch();
  const tableProps: TableProps = useTable(FUNNEL_TEMPLATE_REPORT_TABLE);

  const onLoad: () => mixed = () => dispatch(getFunnelTemplatesReportListSaga());

  return (
    <Table
      {...tableProps}
      title=""
      allSelectAvailable={allSelectAvailable}
      rowSelectAvailable={rowSelectAvailable}
      columns={columns}
      onLoad={onLoad}
    />
  );
};

export default FunnelsTemplateReportList;
