/* eslint-disable import/max-dependencies */
// @flow
import React, {
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import {
  Table, SelectSearchComponent, IsActiveLabel,
} from "@fas/ui-core";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { GENERAL_FILTER_TABLE } from "../../helpers/constants";
import {
  getGeneralFilterListSaga,
  type GetGeneralFilterListSaga,
} from "../../actions/generalFilters";
import TableRowActions from "./TableRowActions";
import type { GeneralFilterListItem } from "./type";

type Props = $ReadOnly<{||}>

const GeneralFilterList: StatelessFunctionalComponent<Props> = () => {
  const tableProps: TableProps = useTable(GENERAL_FILTER_TABLE);
  const dispatch: <A>(A) => A = useDispatch();
  // const navigate = useNavigate();

  const onCreate: () => void = () => {
    window.location = "/generalFilter/add";
    // navigate("/generalFilter");
  };

  const onGetGeneralFilterListSaga: () => GetGeneralFilterListSaga = () => (
    dispatch(getGeneralFilterListSaga()));

  const columns: Array<Column<GeneralFilterListItem>> = [
    {},
    {
      field: "id",
      label: "Id",
      searchable: true,
      sortable: true,
      render: ({ id }: GeneralFilterListItem) => <Link to={`/generalFilter/edit/${id}`}>{id}</Link>,
    },
    {
      field: "name",
      label: "Name",
      searchable: true,
      sortable: true,
    },
    {
      field: "tdsCampaignsOut",
      label: "Tds Campaign Out",
      searchable: true,
      render: ({ tdsCampaignsOut }) => tdsCampaignsOut.length && tdsCampaignsOut.reduce((acc, { weight, id }) => {
        acc.push(`${id} - ${weight}`);
        return acc;
      }, []),
    },
    {
      field: "rate",
      label: "% Traffic",
      searchable: true,
      sortable: true,
    },
    {
      field: "priority",
      label: "Priority",
      sortable: true,
    },
    {
      field: "description",
      label: "Description",
      searchable: true,
    },
    {
      field: "dryModeRate",
      label: "Dry Mode Rate, %",
      sortable: true,
    },
    {
      field: "fact",
      label: "Fact, %",
    },
    {
      field: "isActive",
      label: "Is Active",
      searchable: true,
      sortable: true,
      render: IsActiveLabel,
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { title: "All", value: "" },
            { title: "Active", value: "1" },
            { title: "Inactive", value: "0" },
          ]}
          label="isActive"
        />
      ),
    },
    {
      field: "mustHave",
      label: "Must Have",
      searchable: true,
      sortable: true,
      render: IsActiveLabel,
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { title: "All", value: "" },
            { title: "Yes", value: "1" },
            { title: "No", value: "0" },
          ]}
          label="mustHave"
        />
      ),
    },
    {
      field: "createdAt",
      label: "Created At",
      sortable: true,
    },
    {
      field: "updatedAt",
      label: "Updated At",
      sortable: true,
    },
    {
      field: "updatedBy",
      label: "Updated By",
      render: (data) => (<div>{data.updatedBy}</div>),
    },
    {
      field: "id",
      label: "",
      render: (data) => <TableRowActions {...data} />,
    },
  ];

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        title="General Filters"
        columns={columns}
        rowSelectAvailable={(): boolean => false}
        allSelectAvailable={(): boolean => false}
        allCheckboxVisible={false}
        onCreate={onCreate}
        onLoad={onGetGeneralFilterListSaga}
      />
    </Box>
  );
};

export default GeneralFilterList;
