// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";

export const getFormEdit: (string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.generalFilterEdit.replace("{id}", id));

export const getFormAdd: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.generalFilterAdd);

export const getCampaigns: (number, number, Object) => PromiseResponse<mixed> = (
  page,
  pageSize,
  filters
) => requestService.post(environment.endpoints.post.generalFilterGetCampaigns, {
  ...filters,
  page,
});

export const save: (Object) => PromiseResponse<mixed> = (body) => {
  const route: string = body.id
    ? environment.endpoints.post.generalFilterUpdate.replace("{id}", body.id)
    : environment.endpoints.post.generalFilterSave;

  return requestService.post(route, body);
};

export const getTargetingRules: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.targetingRulesGeneralFilter);
