// @flow
import React from "react";
import { Provider } from "react-redux";
import { Outlet } from "react-router-dom";
import configureStore from "./store";
import NotificationsContainer from "../../components/Notification";

let store;
function AppStoreWrapper() {
  if (!store) {
    store = configureStore();
  }

  return (
    <Provider store={store}>
      <NotificationsContainer />
      <Outlet />
    </Provider>
  );
}

export default AppStoreWrapper;
