/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { List } from "immutable";
import { ComboBox } from "@fas/ui-core";
import TimelineIcon from "@mui/icons-material/Timeline";
import FilterIcon from "@mui/icons-material/FilterList";
import makeStyles from "@mui/styles/makeStyles";
import environment from "environment";
import { userService } from "../../services/user";
import ConfigureColumnsComponent from "../../components/ConfigureColumnsComponent";
import { setGroupFiltersOrder } from "../../actions/funnelTemplateReport";
import {
  getReportGroupBy,
  getReportColumnsOrder,
  getReportExportLinkParams,
  getIsConfigureReportColumnsModalOpen,
} from "../../selectors/templates";
import type { ExportLinkParams, ConfigureParam } from "./types/FunnelTemplateReportContainer.types";
import { FUNNEL_TEMPLATE_REPORT_TABLE } from "../../helpers/constants";
import { changeConfigureFunnelTemplateReportColumnsModalOpen } from "../../actions/configureFunnelTemplateReportColumnsModal";
import { useClearTable, type ClearTableHook } from "../../hooks";

type UseFunnelTemplateReport = {
  groupBy: string,
  filterOrder: List,
  isConfigureColumnsModalOpen: boolean,
  exportLinkParams: ExportLinkParams,
  onApply: (ConfigureParam) => void,
  onClose: () => void,
  onOpen: () => void,
};

const useFunnelTemplateReport: () => UseFunnelTemplateReport = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const { clearTableData, clearTableFilters }: ClearTableHook = useClearTable(FUNNEL_TEMPLATE_REPORT_TABLE);

  const groupBy: string = useSelector(getReportGroupBy);
  const filterOrder: List = useSelector(getReportColumnsOrder);
  const isConfigureColumnsModalOpen: boolean = useSelector(getIsConfigureReportColumnsModalOpen);
  const exportLinkParams: ExportLinkParams = useSelector(
    (state) => getReportExportLinkParams(state, FUNNEL_TEMPLATE_REPORT_TABLE)
  );

  const onOpen: () => void = () => {
    dispatch(changeConfigureFunnelTemplateReportColumnsModalOpen(true));
  };
  const onClose: () => void = () => {
    dispatch(changeConfigureFunnelTemplateReportColumnsModalOpen(false));
  };
  const onApply: (ConfigureParam) => void = (params) => {
    dispatch(setGroupFiltersOrder(params));
    clearTableData();
    clearTableFilters();
    onClose();
  };

  return {
    groupBy,
    filterOrder,
    isConfigureColumnsModalOpen,
    exportLinkParams,
    onOpen,
    onApply,
    onClose,
  };
};

const useStyles = makeStyles(() => ({
  whiteBtn: {
    background: "white",
  },
}));

const getExportLinkSearchParams = (exportLinkParams: ExportLinkParams): string => {
  const exportLinkSearch = new URLSearchParams({
    groupBy: exportLinkParams.groupBy,
    columns: exportLinkParams.columns.join(","),
    sorting: JSON.stringify(exportLinkParams.sorting),
    filters: JSON.stringify(exportLinkParams.filters),
  });

  return exportLinkSearch.toString();
};

const getExportLink = (exportLinkParams: ExportLinkParams): string => decodeURI(`${environment.endpoints.get.exportFunnelTemplateReport}?${getExportLinkSearchParams(exportLinkParams)}`);

const FunnelTemplateReportContainer: StatelessFunctionalComponent<{}> = () => {
  const classes = useStyles();
  const {
    groupBy,
    filterOrder,
    isConfigureColumnsModalOpen,
    exportLinkParams,
    onOpen,
    onApply,
    onClose,
  }: UseFunnelTemplateReport = useFunnelTemplateReport();

  const exportLink: string = getExportLink(exportLinkParams);

  const comboBoxProps = {
    id: "mui-autocomplete-funnel-templates-report",
    label: "FunnelTemplateReports",
    options: [
      {
        text: "Reporting",
        permissions: ["/dictionary/funnelTemplates/report"],
      },
      {
        text: "Summary table",
        permissions: ["/dictionary/funnelTemplates/list"],
      },
    ]
      .filter(({ permissions }) => userService.can(permissions))
      .map(({ text }) => text),
    value: "Reporting",
    isOptionEqualToValue: (option, value) => option === value,
    getOptionLabel: (option) => option || "",
    renderInput: (params) => (
      <TextField
        {...params}
        data-testid="mui-autocomplete-funnel-templates-report-page"
        variant="outlined"
        fullWidth
        InputProps={{
          // eslint-disable-next-line react/destructuring-assignment
          ...params.InputProps,
          readOnly: true,
          startAdornment: (
            <Box p={1}>
              <InputAdornment position="start">
                <TimelineIcon />
              </InputAdornment>
            </Box>
          ),
        }}
      />
    ),
    onChange: () => {
      window.location = "/funnelTemplates/list";
    },
    disableClearable: true,
    "data-testid": "id-funnel-templates-report-comboBox",
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <ComboBox {...comboBoxProps} />
      </Grid>
      <Grid item xs={3}>
        <Button
          className={classes.whiteBtn}
          variant="outlined"
          onClick={onOpen}
          data-testid="columnButton"
          size="large"
          startIcon={<FilterIcon />}
        >
          Columns
        </Button>
        <ConfigureColumnsComponent
          initialGroupBy={groupBy}
          initialFilterOrder={filterOrder}
          onApply={onApply}
          onClose={onClose}
          open={isConfigureColumnsModalOpen}
        />
      </Grid>
      <Grid item xs>
        <Grid item container spacing={0} justifyContent="flex-end">
          <Box display="flex" flexDirection="row-reverse">
            <Button
              variant="contained"
              color="primary"
              data-testid="exportButton"
              size="large"
              target="_blank"
              href={exportLink}
              disabled={!filterOrder.length}
            >
              Export
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FunnelTemplateReportContainer;
