// @flow
import {
  CHANGE_CONFIGURE_FUNNEL_TEMPLATE_REPORT_COLUMNS_MODAL_OPEN,
} from "../../helpers/constants";

export type ChangeConfigureFunnelTemplateReportColumnsModalOpen = {|
  type: typeof CHANGE_CONFIGURE_FUNNEL_TEMPLATE_REPORT_COLUMNS_MODAL_OPEN,
  isOpen: boolean,
|};

export type Actions = ChangeConfigureFunnelTemplateReportColumnsModalOpen;

// eslint-disable-next-line import/prefer-default-export
export const changeConfigureFunnelTemplateReportColumnsModalOpen = (
  isOpen: boolean
): ChangeConfigureFunnelTemplateReportColumnsModalOpen => ({
  type: CHANGE_CONFIGURE_FUNNEL_TEMPLATE_REPORT_COLUMNS_MODAL_OPEN,
  isOpen,
});
