// @flow
import {
  Record,
  RecordOf,
  RecordFactory,
  List,
} from "immutable";
import {
  SET_ANTIFRAUD_SCENARIO,
  SET_ANTIFRAUD_SCENARIO_FORM_FIELD,
  SET_ANTIFRAUD_SCENARIO_RULES,
  REMOVE_ANTIFRAUD_SCENARIO_RULE,
  ADD_ANTIFRAUD_SCENARIO_ACTION,
  DELETE_ANTIFRAUD_SCENARIO_ACTION,
  SET_ANTIFRAUD_SCENARIO_ACTION,
  DEFAULT_SCENARIO,
  CHANGE_ANTIFRAUD_SCENARIO_RULE_STATUS,
} from "../../helpers/constants";
import type {
  Actions,
  DeleteAntifraudScenarioAction,
  SetAntifraudScenarioAction,
  ChangeAntifraudScenarioRuleStatus,
} from "../../actions/antifraudScenarioForm";
import type { Scenario, Rule } from "../../selectors/antifraudScenarios";

export type DynamicDict = { dictId: string, varId: string, location: string };

export type ScenarioFormData = {
  scenarioId?: number,
  name: string,
  event: string,
  typeName: string,
  actions: List<DynamicDict>,
  isActive: boolean,
  rules: List<Rule>,
}

const defaultAction: DynamicDict = { dictId: "", varId: "", location: "data" };

const defaultState: ScenarioFormData = {
  scenarioId: 0,
  name: "",
  event: "",
  typeName: DEFAULT_SCENARIO,
  actions: List([defaultAction]),
  isActive: false,
  rules: List([]),
};

export type State = RecordOf<ScenarioFormData>;

export const makeAntifraudScenariosFormState: RecordFactory<ScenarioFormData> = Record(defaultState);

export const initialAntifraudScenarioFormState: State = makeAntifraudScenariosFormState({});

export default (state: State = initialAntifraudScenarioFormState, action: Actions): State => {
  switch (action.type) {
    case SET_ANTIFRAUD_SCENARIO: {
      const { actions, rules, ...scenario }: Scenario = action.payload;
      return makeAntifraudScenariosFormState({ ...scenario, rules: List(rules), actions: List(actions) });
    }
    case SET_ANTIFRAUD_SCENARIO_FORM_FIELD: {
      return state.merge(action.payload);
    }
    case ADD_ANTIFRAUD_SCENARIO_ACTION: {
      return state.update("actions", (arr) => arr.concat([defaultAction]));
    }
    case DELETE_ANTIFRAUD_SCENARIO_ACTION: {
      const { payload: index }: DeleteAntifraudScenarioAction = action;
      return state.deleteIn(["actions", index]);
    }
    case SET_ANTIFRAUD_SCENARIO_ACTION: {
      const { payload: { index, value } }: SetAntifraudScenarioAction = action;
      return state.updateIn(["actions", index], (val) => ({ ...val, ...value }));
    }
    case SET_ANTIFRAUD_SCENARIO_RULES: {
      const { payload } = action;
      return state.set("rules", List(payload));
    }
    case REMOVE_ANTIFRAUD_SCENARIO_RULE: {
      const index: number = action.payload;
      return state.deleteIn(["rules", index]);
    }
    case CHANGE_ANTIFRAUD_SCENARIO_RULE_STATUS: {
      const { payload: { index, isActive } }: ChangeAntifraudScenarioRuleStatus = action;
      return state.updateIn(["rules", index], (rule: Rule): Rule => ({ ...rule, isActive }));
    }
    default:
      return state;
  }
};
