// @flow
import {
  ADD_NON_TARGET_OFFERS,
  SAVE_NON_TARGET_OFFERS_SAGA,
  DELETE_NON_TARGET_OFFER,
  DELETE_NON_TARGET_OFFER_SAGA,
} from "../../helpers/constants";
import type { Offer } from "../../reducers/offers";

export const saveNonTargetOffersSaga = (offers: Array<Offer>) => ({
  type: SAVE_NON_TARGET_OFFERS_SAGA,
  offers,
});

export const addNonTargetOffer = (offerId: string, offer: Offer) => ({
  type: ADD_NON_TARGET_OFFERS,
  offerId,
  offer,
});

export const deleteNonTargetOffer = (offerId: string) => ({
  type: DELETE_NON_TARGET_OFFER,
  offerId,
});

export const deleteNonTargetOfferSaga = (offerId: string) => ({
  type: DELETE_NON_TARGET_OFFER_SAGA,
  offerId,
});
