// @flow
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Card,
  CardContent,
  Box,
  Typography,
} from "@mui/material";
import { userService } from "../../services/user";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: theme.spacing(1),
    minWidth: 180,
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
      },
    },
  },
}));

function IndexComponent() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      p={4}
      m="auto"
      display="flex"
      flexWrap="wrap"
      justifyContent="space-evenly"
      maxWidth="lg"
      fontSize="18px"
      gap={2}
    >
      <Card variant="outlined" p={2} sx={{ minWidth: 300, minHeight: 200, textAlign: "center" }}>
        <Typography variant="h5" component="div" mt={2}>
          TDS
        </Typography>
        <CardContent>
          {userService.can(["/unitedReborn/v2/smartlink/create"]) && (
            <Box mb={1}>
              <Link to="/smartlink">Smartlink</Link>
            </Box>
          )}
          {userService.can([{ path: "/api/v1/smartlinkAggregators/list", method: "POST" }]) && (
            <Box mb={1}>
              <Link to="/smartlinkAggregators/list">Smartlink Aggregators</Link>
            </Box>
          )}
          {userService.can(["/dictionary/funnelTemplates/list"]) && (
            <Box mb={1}>
              <Link to="/funnelTemplates/list">Funnels Template</Link>
            </Box>
          )}

          <Box display="flex" justifyContent="center">
            <Box
              id="gf-menu-button"
              aria-controls={open ? "gf-menu-list" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              display="flex"
              alignItems="center"
              sx={{ cursor: "pointer" }}
            >
              General filter
              <KeyboardArrowDownIcon />
            </Box>
            <StyledMenu
              id="gf-menu-list"
              MenuListProps={{
                "aria-labelledby": "gf-menu-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {userService.can([{ path: "/api/v1/generalFilterV2/list", method: "POST" }]) && (
                <MenuItem disableRipple component={NavLink} to="/generalFilter/list">
                  List
                </MenuItem>
              )}
              <MenuItem disableRipple component={NavLink} to="/generalFilter/add">
                Create new
              </MenuItem>
            </StyledMenu>
          </Box>
        </CardContent>
      </Card>
      <Card variant="outlined" p={2} sx={{ minWidth: 300, minHeight: 200, textAlign: "center" }}>
        <Typography variant="h5" component="div" mt={2}>
          Antifraud
        </Typography>
        <CardContent>
          {userService.can([{ path: "/api/v1/frodi/triggers/list", method: "GET" }]) && (
            <Box mb={1}>
              <Link to="/antifraudTriggers/list">Triggers</Link>
            </Box>
          )}
          {userService.can([{ path: "/api/v1/frodi/dictionaries/list", method: "GET" }]) && (
            <Box mb={1}>
              <Link to="/antifraudDictionaries/list">Dictionaries</Link>
            </Box>
          )}
          {userService.can([{ path: "/api/v1/frodi/scenarios/list", method: "GET" }]) && (
            <Box mb={1}>
              <Link to="/antifraudScenarios/list">Scenarios</Link>
            </Box>
          )}
          {userService.can([{ path: "/api/v1/frodi/variables/list", method: "GET" }]) && (
            <Box>
              <Link to="/antifraudVariables/list">Variables</Link>
            </Box>
          )}
        </CardContent>
      </Card>
      <Card variant="outlined" p={2} sx={{ minWidth: 300, minHeight: 200, textAlign: "center" }}>
        <Typography variant="h5" component="div" mt={2}>
          Other
        </Typography>
        <CardContent>
          {userService.can([{ path: "/api/v1/historyReplaceLP/list", method: "POST" }]) && (
            <Box mb={1}>
              <Link to="/historyReplace/list">Replace LP History</Link>
            </Box>
          )}
          {userService.can([{ path: "/api/v1/jumpsForFrequencyCap", method: "PUT" }]) && (
            <Box>
              <Link to="/frequencyCapForJumps">Frequency Cap for Jumps</Link>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default IndexComponent;
