// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  FormControl,
  TextField,
  Switch,
  Box,
  Grid,
  FormControlLabel,
  Divider,
  Typography,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { Map } from "immutable";
import { ComboBox, SetupCard } from "@fas/ui-core";
import type { Error } from "@fas/ui-framework/lib/redux/reducers/errors";

import makeStyles from "@mui/styles/makeStyles";
import {
  getErrors,
  getCampaignInfo,
} from "../../selectors/smartlink";
import DomainSelector from "../../components/DomainSelector";
import {
  selectDomain,
  changeCampaignName,
  changeRequestLimitPerSec,
  changeFGOut,
  changeTestCampaign,
  startFetchCampaignIdSaga,
  changeAffiliateDataType,
} from "../../actions/campaignInfo";

import Can from "../../components/Can";
import { changeCampaignExternalName } from "../../actions/campaignInfo/actions";
import { userService } from "../../services/user";

export type CampaignInfoProps = {
  domain: string,
  protocol: string,
  type: string,
  name: string,
  externalName: string,
  requestLimitPerSec: number,
  funnelGuardOut: boolean,
  isTest: boolean,
};

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  campaignInfo: Map<CampaignInfoProps>,
  errors: Map<string, Error>,
  handleChangeDomain: (domain: string, protocol: string) => any,
  handleChangeCampaignName: (value: string) => any,
  handleChangeCampaignExternalName: (value: string) => any,
  handleRequestLimitPerSecChange: (value: number) => void,
  handleAffiliateDataTypeChange: (value: string) => void,
  handleChangeFGOut: (value: boolean) => any,
  handleChangeIsTest: (value: boolean) => any,
  startFetchCampaignId: (type: string) => void,
|}>;

const mapStateToProps = (state) => ({
  campaignInfo: getCampaignInfo(state),
  errors: getErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleChangeDomain: (domain, protocol) => dispatch(selectDomain(domain, protocol)),
  handleChangeCampaignName: (name) => dispatch(changeCampaignName(name)),
  handleChangeCampaignExternalName: (name) => dispatch(changeCampaignExternalName(name)),
  handleRequestLimitPerSecChange: (value) => dispatch(changeRequestLimitPerSec(value)),
  handleAffiliateDataTypeChange: (value) => dispatch(changeAffiliateDataType(value)),
  handleChangeFGOut: (value) => dispatch(changeFGOut(value)),
  handleChangeIsTest: (value) => dispatch(changeTestCampaign(value)),
  startFetchCampaignId: (type) => dispatch(startFetchCampaignIdSaga(type)),
});

const hasError = (key: string, errors: Map<Error>): boolean => errors.has(key);

const useStyles = makeStyles(() => ({
  listbox: {
    textTransform: "capitalize",
  },
  input: {
    textTransform: "capitalize",
  },
}));

const CampaignInfo = ({
  campaignInfo,
  errors,
  handleChangeDomain,
  handleChangeCampaignName,
  handleChangeCampaignExternalName,
  handleRequestLimitPerSecChange,
  handleAffiliateDataTypeChange,
  handleChangeFGOut,
  handleChangeIsTest,
  startFetchCampaignId,
}: Props) => {
  const {
    domain,
    protocol,
    type,
    name,
    externalName,
    requestLimitPerSec,
    affiliateDataType,
    isAffiliateDataTypeDisabled,
    funnelGuardOut,
    isTest,
  } = campaignInfo.toJS();
  const matchParams = useParams();

  if (!matchParams.id && !campaignInfo.get("id")) {
    startFetchCampaignId(type);
  }

  const fgOut = (
    <FormControlLabel
      control={(
        <Switch
          color="primary"
          checked={funnelGuardOut}
          onClick={() => handleChangeFGOut(!funnelGuardOut)}
          data-testid="is-fg-out-switch"
        />
      )}
      label="Funnel Guard Out"
      data-testid="is-fg-out-switch"
    />
  );

  return (
    <SetupCard title="Campaign info">
      <>
        <Box mb={2} width={1}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3}>
              <Typography>Domain</Typography>
            </Grid>
            <Grid item xs={9}>
              <DomainSelector
                domain={domain}
                protocol={protocol}
                onChangeDomain={handleChangeDomain}
                error={hasError("domain", errors)}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={2} width={1}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3}>
              <Typography>Campaign type</Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="campaign-type"
                  variant="outlined"
                  size="small"
                  value={type}
                  data-testid="campaign-type-text"
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box width={1} mb={2}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3}>
              <Typography>Campaign name</Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="campaign-name"
                  variant="outlined"
                  size="small"
                  value={name}
                  placeholder="Example: Smartlink_test"
                  onChange={(e) => handleChangeCampaignName(e.target.value)}
                  error={hasError("campaignName", errors)}
                  inputProps={{ maxLength: "60" }}
                  data-testid="name-filter-text"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box width={1} mb={2}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
            <Grid item xs={3}>
              <Typography>Campaign external name</Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  id="campaign-external-name"
                  variant="outlined"
                  size="small"
                  value={externalName}
                  placeholder="Example: Smartlink_test"
                  onChange={(e) => handleChangeCampaignExternalName(e.target.value)}
                  error={hasError("campaignExternalName", errors)}
                  inputProps={{
                    maxLength: "60",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ cursor: "default" }}>
                        <Tooltip title="External name is displayed to affiliate in custom links / smartoffer links">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  data-testid="external-name-filter-text"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Can permissions={["unitedReborn.field.requestLimitPerSec"]}>
          <Box mb={2} width={1}>
            <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
              <Grid item xs={3}>
                <Typography>RPS hard limit</Typography>
              </Grid>
              <Grid item xs={9}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="requestLimitPerSec"
                    variant="outlined"
                    size="small"
                    onChange={(e) => handleRequestLimitPerSecChange(e.target.value)}
                    value={requestLimitPerSec}
                    error={hasError("requestLimitPerSec", errors)}
                    inputProps={{ type: "number", "data-testid": "requestLimitPerSec-input" }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Can>
        <Can permissions={["unitedReborn.field.showAffiliateDataType"]}>
          <Box mb={2} width={1}>
            <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
              <Grid item xs={3}>
                <Typography>Smartlink Type</Typography>
              </Grid>
              <Grid item xs={9}>
                <FormControl variant="outlined" fullWidth>
                  <ComboBox
                    classes={useStyles()}
                    id="affiliateDataType"
                    onChange={(e, option) => handleAffiliateDataTypeChange(option)}
                    value={affiliateDataType || null}
                    options={["direct", "smartlink"]}
                    disabled={isAffiliateDataTypeDisabled && !userService.can(["unitedReborn.field.editAffiliateDataType"])}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={hasError("affiliateDataType", errors)}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="end" style={{ cursor: "default" }}>
                              <Tooltip title="">
                                <HelpOutlineIcon />
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Can>
        <Box width={1} mb={2}>
          <Divider />
        </Box>
        <Box width={1}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
            {type !== "smartlink" && (
              <Grid item xs={4}>
                {fgOut}
              </Grid>
            )}
            <Can permissions={["unitedReborn.field.isTest"]}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={(
                    <Switch
                      color="primary"
                      checked={isTest}
                      onClick={() => handleChangeIsTest(!isTest)}
                    />
                  )}
                  label="Test campaign"
                  data-testid="is-test-switch"
                />
              </Grid>
            </Can>
          </Grid>
        </Box>
      </>
    </SetupCard>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(CampaignInfo);
