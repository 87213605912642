// @flow
import React from "react";
import {
  Tabs, Box, Card, Divider,
} from "@mui/material";
import TabItem from "./TabItem";
import TabPanel from "./TabPanel";
import type { Props, Tab } from "./types/TabsMapper.type";

const TabsMapper = ({
  value,
  onChange,
  tabs,
  tabPanelComponents,
}: Props) => (
  <Card>
    <Tabs
      value={parseInt(value, 10)}
      onChange={(e, v) => onChange(String(v))}
      aria-label="funnel-template-tabs"
    >
      {tabs.map((tab: Tab, index) => (
        <TabItem
          key={tab.label}
          id={`tab-${index}`}
          aria-controls={`tabpanel-${index}`}
          label={tab.label}
          disabled={tab.disabled || false}
        />
      ))}
    </Tabs>
    <Divider />
    <Box mx={1} my={2}>
      {tabPanelComponents.map(({ name, id, component }) => (
        <TabPanel
          key={name}
          value={parseInt(value, 10)}
          index={id}
        >
          {component}
        </TabPanel>
      ))}
    </Box>
  </Card>
);

export default TabsMapper;
