// @flow
import setwith from "lodash.setwith";
import type { ValidationResult, ValidationErrorItem } from "joi";
import type { MultiselectTypes, Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";

const typeMapper: {[key: string]: MultiselectTypes} = {
  varString: "simpleSelect",
  varIp: "enterManualMultiple",
  varFloat: "simpleSelect",
  varEmail: "enterManualMultiple",
  varBool: "simpleSelect",
  varArr: "enterManualMultiple",
  varAcceptLanguage: "simpleSelect",
};

export const getMultiselectType: (type: string) => MultiselectTypes = (type) => {
  if (type in typeMapper) return typeMapper[type];
  return "simpleMultiple";
};

export const prepareValuesList: (values: Array<string>) => Array<Option> = (values) => values
  .map((item: string): Option => ({ label: item, value: item }));

export function parseConditionErrors(
  validationResult: ValidationResult
): ErrorsMapper {
  if (validationResult.error && validationResult.error.details) {
    const errorObj: ErrorsMapper = validationResult.error.details
      .reduce((acc: ErrorsMapper, errorItem: ValidationErrorItem): ErrorsMapper => {
        setwith(
          acc,
          errorItem.path,
          { message: errorItem.message.split("\"")[2] },
          Object
        );
        return acc;
      }, {});
    return errorObj;
  }
  return {};
}
