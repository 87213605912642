// @flow
import Joi from "@hapi/joi";
import type { JoiError, Errors } from "../types/Base";
import type { BaseSimpleErrorsRule } from "./Base";
import {
  AFFILIATE_DATA_TYPE_REQUIRED,
} from "../messages";
import { userService } from "../../user";

export default class AffiliateDataType implements BaseSimpleErrorsRule {
  schema: Object;

  errorKey: string;

  errors: Errors;

  constructor() {
    this.errors = {};
    this.errorKey = "affiliateDataType";
    const isRequired = userService.can(["unitedReborn.field.showAffiliateDataType"]);
    this.schema = isRequired ? Joi.string().valid("direct", "smartlink").required().error((errors) => this._onError(errors)) : Joi.any();
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach(() => {
      this.errors = {
        ...this.errors,
        [this.errorKey]: { message: AFFILIATE_DATA_TYPE_REQUIRED },
      };
    });

    return errors;
  }

  getErrors(data: string): Errors {
    this.schema.validate(data);
    return this.errors;
  }
}
