// @flow
import joi from "joi";

const defaultSchemaObject = {
  name: joi
    .string()
    .min(1)
    .max(255)
    .required()
    .label("Scenario Name"),
  event: joi
    .string()
    .min(1)
    .max(255)
    .required()
    .label("Scenario Event"),
};

export const defaultSchema = joi.object(defaultSchemaObject)
  .options({ abortEarly: false, allowUnknown: true });

export const dynamicSchema = joi.object({
  ...defaultSchemaObject,
  actions: joi
    .array()
    .min(1)
    .max(5)
    .items(joi.object().keys({ dictId: joi.number().required(), varId: joi.number().required() })),
})
  .options({ abortEarly: false, allowUnknown: true });
