// @flow
import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Box,
} from "@mui/material";
import { StepControl } from "@fas/ui-core";
import { stepClick } from "@fas/ui-framework/lib/redux/actions/steps";
import CampaignInfo from "../CampaignInfo";
import Placeholders from "../Placeholders";
import {
  getCurrentStepId,
} from "../../selectors/smartlink";

type Props = {
  currentStep: string,
  handleNextClick: (stepId: string) => void,
};

const mapStateToProps = (state) => ({
  currentStep: getCurrentStepId(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleNextClick: (stepId) => dispatch(stepClick(stepId)),
});

const getNextStepId = (currentStep: string): string => (Number(currentStep) + 1).toString();

const CampaignInfoStep = ({
  currentStep,
  handleNextClick,
}: Props) => (
  <Container maxWidth="md">
    <Box data-testid="campaignInfo">
      <CampaignInfo />
    </Box>
    <Box mt={3} data-testid="placeholders">
      <Placeholders />
    </Box>
    <Box mt={3} display="flex" justifyContent="flex-end">
      <StepControl
        showBackButton={false}
        onNext={() => handleNextClick(getNextStepId(currentStep))}
      />
    </Box>
  </Container>
);

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CampaignInfoStep);
