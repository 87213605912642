// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { type ValidationResult } from "joi";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseJoiErrors from "@fas/ui-framework/lib/services/parseJoiErrors";
import { SAVE_ANTIFRAUD_TRIGGER_SAGA } from "../../helpers/constants";
import { getAntifraudTrigger } from "../../selectors/antifraudTrigger";
import { saveAntifraudTrigger, createAntifraudTrigger } from "../../services/antifraudTriggersApi";
import type { Trigger, Conditions } from "../../selectors/antifraudTrigger";
import { parseConditionErrors } from "../../helpers/antifraudUtils";
import { schema } from "./schema";
import ExternalRedirect from "../../components/App/ExternalRedirect";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("isSaveAntifraudTriggerLoading", true));

    const {
      ruleId,
      ...rest
    }: Trigger = yield select(getAntifraudTrigger);

    const validationResult: ValidationResult = schema.validate(rest);

    const validationErrors: ErrorsMapper = {
      ...parseConditionErrors(validationResult),
      ...parseJoiErrors(validationResult),
    };

    yield put(setErrors(validationErrors));

    const conditions: Conditions = Object.keys(rest.conditions).length === 1 ? {} : rest.conditions;

    if (Object.keys(validationErrors).length === 0) {
      if (ruleId) {
        yield call(saveAntifraudTrigger, { ...rest, conditions, ruleId });
      }
      else {
        yield call(createAntifraudTrigger, { ...rest, conditions });
      }

      ExternalRedirect({ to: "/antifraudTriggers/list" });
    }
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
  }
  finally {
    yield put(setLoading("isSaveAntifraudTriggerLoading", false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(SAVE_ANTIFRAUD_TRIGGER_SAGA, (makeFetch: Function));
}
