// @flow
import React from "react";
import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import type { Preset } from "./types/PresetsList.types";
import type { Placeholder } from "../PlaceholdersList/types/PlaceholdersList.types";

type Props = {
  presetsList: Array<Preset>,
  onClickPreset: (placeholders: Array<Placeholder>) => any,
  onClear: () => any,
}

const useStyles = makeStyles((theme) => ({
  pinkButton: {
    backgroundColor: theme.palette.pinkColor.main,
    "&:hover": {
      backgroundColor: theme.palette.pinkColor.dark,
    },
    textTransform: "none",
  },
  upperCaseDisable: {
    textTransform: "none",
  },
}));

const PresetsList = ({ presetsList = [], onClickPreset, onClear }: Props) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item container spacing={1}>
        {
          presetsList.map(({ name, placeholders }: Preset) => (
            <Grid item key={name}>
              <Button
                className={classes.upperCaseDisable}
                variant="contained"
                color="secondary"
                disableElevation
                onClick={() => onClickPreset(placeholders)}
                data-testid={`presetButton-${name}`}
              >
                {name}
              </Button>
            </Grid>
          ))
        }
        <Grid item key="clear">
          <Button
            className={classes.pinkButton}
            variant="contained"
            disableElevation
            onClick={() => onClear()}
            data-testid="clearButton"
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PresetsList;
