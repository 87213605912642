// @flow
import React from "react";
import {
  Grid, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SetupCard } from "@fas/ui-core";
import { VariableSizeList as List } from "react-window";
import AutoSizer, { type AutoSizerProps } from "react-virtualized-auto-sizer";
import FilterSettingItem from "./FilterSettingItem";
import type { Setting, RowProps } from "./types/FilterSettingsList.types";

type Props = {
  data: Array<Setting>,
  onActiveChange: (
    index: number,
    value: boolean,
  ) => void
};

const styles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.titleColor,
  },
  tableHeader: {
    lineHeight: 1.3,
  },
  gridWrapper: {
    backgroundColor: theme.palette.background.blueBackgroundLight,
    border: `1px solid ${theme.palette.borderColorLight}`,
    padding: `${theme.spacing(2)} 0`,
    borderRadius: theme.borderRadius,
    marginTop: theme.spacing(1),
    height: "calc(100vh - 300px)",
  },
}));

const Row = ({
  data: list,
  index,
  style,
}: RowProps) => {
  const {
    isFilterActiveOnCampaign,
    tdsCampaignsOut,
    name,
    id,
    conditions,
    mustHave,
    rate,
    priority,
  } = list.data[index];
  return (
    <div style={style}>
      <FilterSettingItem
        key={`${name}-${rate}`}
        isFilterActiveOnCampaign={isFilterActiveOnCampaign}
        tdsCampaignsOut={tdsCampaignsOut}
        name={name}
        id={id}
        conditions={conditions}
        mustHave={mustHave}
        rate={rate}
        priority={priority}
        index={index}
        onActiveChange={() => list.onActiveChange(index, !isFilterActiveOnCampaign)}
      />
    </div>
  );
};

const FilterSettingsList = (props: Props) => {
  const { data, onActiveChange } = props;
  const classes = styles();
  const getItemSize = (index) => Math.max(1, data[index].tdsCampaignsOut.length) * 46;
  return (
    <SetupCard
      title="Filter Setting"
    >
      <Grid container direction="column" padding={1} data-testid="filter-setting-body">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item container xs={1} justifyContent="flex-start">
            <Typography variant="subtitle1" className={classes.tableHeader}>Active on campaign</Typography>
          </Grid>
          <Grid item container justifyContent="flex-start" xs={1}>
            <Typography variant="subtitle1" className={classes.tableHeader}>Priority</Typography>
          </Grid>
          <Grid item container justifyContent="flex-start" xs={5}>
            <Typography variant="subtitle1" className={classes.tableHeader}>Filter</Typography>
          </Grid>
          <Grid item container justifyContent="flex-start" xs={1}>
            <Typography variant="subtitle1" className={classes.tableHeader}>%</Typography>
          </Grid>
          <Grid item container justifyContent="flex-start" xs={2}>
            <Typography variant="subtitle1" className={classes.tableHeader}>Campaing Out</Typography>
          </Grid>
          <Grid item container justifyContent="flex-start" xs={1}>
            <Typography variant="subtitle1" className={classes.tableHeader}>Weight</Typography>
          </Grid>
          <Grid item container xs={1} justifyContent="flex-start">
            <Typography variant="subtitle1" className={classes.tableHeader}>Must Have</Typography>
          </Grid>
        </Grid>

        {data.length > 0 && (
          <Grid item container className={classes.gridWrapper} spacing={1}>
            <AutoSizer
              defaultWidth={100}
              defaultHeight={100}
            >
              {({ height, width }: AutoSizerProps) => (
                <List
                  height={height}
                  width={width}
                  itemCount={data.length}
                  itemSize={getItemSize}
                  itemData={{
                    data,
                    onActiveChange,
                  }}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          </Grid>
        )}
      </Grid>
    </SetupCard>
  );
};

export default FilterSettingsList;
