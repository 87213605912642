// @flow
import {
  GET_ANTIFRAUD_VARIABLES_LIST_SAGA,
  CHANGE_ANTIFRAUD_VARIABLE_ACTIVE_SAGA,
} from "../../helpers/constants";

export type GetAntifraudVariablesListSaga = {
  type: typeof GET_ANTIFRAUD_VARIABLES_LIST_SAGA,
}

export type ChangeAntifraudVariableActiveSaga = {
  type: typeof CHANGE_ANTIFRAUD_VARIABLE_ACTIVE_SAGA,
}

export type Actions = GetAntifraudVariablesListSaga
  | ChangeAntifraudVariableActiveSaga;

export const getAntifraudVariablesListSaga: () => GetAntifraudVariablesListSaga = () => ({
  type: GET_ANTIFRAUD_VARIABLES_LIST_SAGA,
});

export const changeAntifraudVariableActiveSaga: () => ChangeAntifraudVariableActiveSaga = () => ({
  type: CHANGE_ANTIFRAUD_VARIABLE_ACTIVE_SAGA,
});
