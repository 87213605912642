// @flow
import Joi from "@hapi/joi";
import type { JoiError, Errors } from "../../../campaignValidation/types/Base";
import type { BaseSimpleErrorsRule } from "../../../campaignValidation/rules/Base";
import {
  TEMPLATE_TYPE_REQUIRED,
  TEMPLATE_TYPE_WRONG,
} from "../../messages";

export default class CampaignName implements BaseSimpleErrorsRule {
  schema: Object;

  errorKey: string;

  errors: Errors;

  constructor() {
    this.errors = {};
    this.errorKey = "templateType";

    this.schema = Joi
      .string()
      .valid("technical", "other")
      .error((errors) => this._onError(errors));
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach((item) => {
      switch (item.code) {
        case "string.empty":
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: TEMPLATE_TYPE_REQUIRED },
          };
          break;
        case "any.only":
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: TEMPLATE_TYPE_WRONG },
          };
          break;
        default:
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: item.message },
          };
          break;
      }
    });

    return errors;
  }

  getErrors(data: string): Errors {
    this.schema.validate(data);
    return this.errors;
  }
}
