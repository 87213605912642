// @flow
/* eslint-disable import/max-dependencies */
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchConfirmation from "../../sagas/confirmSaga";
import stepsSaga from "../../sagas/stepsSaga";
import cloneSplitSaga from "../../sagas/cloneSplitSaga";
import addSplitSaga from "../../sagas/addSplitSaga";
import deleteSplitSaga from "../../sagas/deleteSplitSaga";
import deleteFunnelSaga from "../../sagas/deleteFunnelSaga";
import cloneFunnelSaga from "../../sagas/cloneFunnelSaga";
import addFunnelSaga from "../../sagas/addFunnelSaga";
import openFunnelSaga from "../../sagas/openFunnelSaga";
import closeFunnelSaga from "../../sagas/closeFunnelSaga";
import fetchGeneralFiltersSaga from "../../sagas/fetchGeneralFiltersSaga";
import openGeneralFiltersSaga from "../../sagas/openGeneralFiltersSaga";
import closeGeneralFiltersSaga from "../../sagas/closeGeneralFiltersSaga";
import saveOffersSaga from "../../sagas/saveOffersSaga";
import saveFunnelSaga from "../../sagas/saveFunnelSaga";
import cloneOfferSaga from "../../sagas/cloneOfferSaga";
import watchSaveFallbackOfferSaga from "../../sagas/saveFallbackOfferSaga";
import watchDeleteFallbackOffer from "../../sagas/deleteFallbackOfferSaga";
import watchSaveNonTargetOffersSaga from "../../sagas/saveNonTargetOffersSaga";
import watchDeleteNonTargetOffersSaga from "../../sagas/deleteNonTargetOffersSaga";
import watchChangeSplitIsActive from "../../sagas/changeSplitActiveSaga";
import cloneTargetingSaga from "../../sagas/cloneTargetingSaga";
import watchFetchCampaignId from "../../sagas/campaignId";
import fetchTargetingsSaga from "../../sagas/fetchTargetingsSaga";
import fetchTargetingValuesSaga from "../../sagas/fetchTargetingValuesSaga";
import saveCampaignSaga from "../../sagas/saveCampaignSaga";
import watchSaveSplit from "../../sagas/saveSplitSaga";
import watchOpenSplit from "../../sagas/openSplitSaga";
import watchCloseSplit from "../../sagas/closeSplitSaga";
import watchSaveGeneralFilters from "../../sagas/saveGeneralFiltersSaga";
import watchCloneFiltering from "../../sagas/cloneFilteringSaga";
import watchCloneActionOptionsParams from "../../sagas/cloneActionOptionsParamsSaga";
import watchDeleteFilteringRule from "../../sagas/deleteFilteringRuleSaga";
import watchTemplateListSaga from "../../sagas/openFunnelTemplateListSaga";
import watchFetchFunnelTemplateSaga from "../../sagas/fetchFunnelTemplateSaga";
import watchSearchFunnels from "../../sagas/searchFunnels/searchFunnels";
import watchFetchOffers from "../../sagas/getOffersSaga";
import watchFetchfunnelTemplates from "../../sagas/getTemplatesListSaga";
import watchGetShortUrl from "../../sagas/getShortUrl";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchCloneFiltering),
    call(watchDeleteFilteringRule),
    call(watchCloneActionOptionsParams),
    call(watchOpenSplit),
    call(watchCloseSplit),
    call(watchSaveSplit),
    call(fetchGeneralFiltersSaga),
    call(openGeneralFiltersSaga),
    call(closeGeneralFiltersSaga),
    call(watchSaveGeneralFilters),
    call(watchConfirmation),
    call(stepsSaga),
    call(addSplitSaga),
    call(deleteSplitSaga),
    call(cloneSplitSaga),
    call(deleteFunnelSaga),
    call(cloneFunnelSaga),
    call(addFunnelSaga),
    call(openFunnelSaga),
    call(closeFunnelSaga),
    call(saveOffersSaga),
    call(saveFunnelSaga),
    call(cloneOfferSaga),
    call(watchSaveFallbackOfferSaga),
    call(watchDeleteFallbackOffer),
    call(watchSaveNonTargetOffersSaga),
    call(watchDeleteNonTargetOffersSaga),
    call(watchChangeSplitIsActive),
    call(cloneTargetingSaga),
    call(watchFetchCampaignId),
    call(fetchTargetingsSaga),
    call(fetchTargetingValuesSaga),
    call(saveCampaignSaga),
    call(watchTemplateListSaga),
    call(watchFetchFunnelTemplateSaga),
    call(watchSearchFunnels),
    call(watchFetchOffers),
    call(watchFetchfunnelTemplates),
    call(watchGetShortUrl),
  ]);
}
