// @flow
import type { StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import FunnelItem from "../../components/FunnelItem";
import {
  getFunnelDescriptionByFunnelId,
  getFunnelFilteringCount,
  getFunnelIsActive,
  getFunnelIsEditing,
  getFunnelName,
  getFunnelHits,
  getFunnelOffersCount,
  getFunnelsArrayBySplitId,
  getFunnelsCountBySplitId,
  getFunnelSearchScrollToItem,
  getFunnelSearchText,
  getFunnelUrl,
  getMaxFunnels,
  getTargetingCountByFunnelId,
} from "../../selectors/smartlink";
import {
  changeIsActive, cloneFunnelSaga, deleteFunnelSaga, openFunnelSaga,
} from "../../actions/funnels";
import { changeFunnelsOrder } from "../../actions/splits/actions";

type OwnProps = $ReadOnly<{|
  funnelId: string,
  splitId: string,
  DragHandleComponent?: StatelessFunctionalComponent<Object>,
|}>;

type StateProps = $ReadOnly<{|
  id: string,
  url: string,
  text: string,
  title: string,
  hits: string,
  isActive: boolean,
  order: string[],
  orderPosition: number,
  isEditing: boolean,
  isClonable: boolean,
  searchText: string,
  isHighlight: boolean,
  countOffers: number,
  countFilterings: number,
  countTargetings: number,
|}>;

type DispatchProps = $ReadOnly<{|
  onChangeOrder: (funnels: string[]) => void,
  onChangeIsActive: () => void,
  onDelete: () => void,
  onClone: () => void,
  onShow: () => void,
|}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps
|}>;

const mapStateToProps = (state, { splitId, funnelId }: OwnProps): StateProps => {
  const order = getFunnelsArrayBySplitId(state, splitId);

  return ({
    id: funnelId,
    url: getFunnelUrl(state, funnelId),
    title: getFunnelName(state, funnelId),
    hits: getFunnelHits(state, funnelId),
    isActive: getFunnelIsActive(state, funnelId),
    order,
    orderPosition: order.indexOf(funnelId),
    isEditing: getFunnelIsEditing(state, splitId, funnelId),
    isClonable: getFunnelsCountBySplitId(state, splitId) < getMaxFunnels(state),
    countOffers: getFunnelOffersCount(state, funnelId),
    countFilterings: getFunnelFilteringCount(state, funnelId),
    searchText: getFunnelSearchText(state),
    isHighlight: funnelId === getFunnelSearchScrollToItem(state),
    text: getFunnelDescriptionByFunnelId(state, funnelId),
    countTargetings: getTargetingCountByFunnelId(state, funnelId),
  });
};

const mapDispatchToProps = (dispatch, { splitId, funnelId }: OwnProps): DispatchProps => ({
  onChangeOrder: (funnels) => dispatch(changeFunnelsOrder(splitId, funnels)),
  onChangeIsActive: () => dispatch(changeIsActive(funnelId)),
  onDelete: () => dispatch(deleteFunnelSaga(splitId, funnelId)),
  onClone: () => dispatch(cloneFunnelSaga(splitId, funnelId)),
  onShow: () => dispatch(openFunnelSaga(splitId, funnelId)),
});

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(FunnelItem);
