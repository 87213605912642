// @flow
import {
  SAVE_TEMPLATE_SAGA,
  START_SAVE_TEMPLATE,
} from "../../helpers/constants";

export type SaveTemplateSagaType = {|
  type: typeof SAVE_TEMPLATE_SAGA,
  cb?: Function
|};

export type StartSaveTemplateType = {|
  type: typeof START_SAVE_TEMPLATE
|};

export const saveTemplateSaga = (cb: Function): SaveTemplateSagaType => ({ type: SAVE_TEMPLATE_SAGA, cb });

export const startSaveTemplate = (): StartSaveTemplateType => ({ type: START_SAVE_TEMPLATE });
