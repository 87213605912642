// @flow
import type { Saga } from "redux-saga";
import {
  call, put, takeEvery,
} from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addDropdown } from "@fas/ui-framework/lib/redux/actions/dropdowns";
import { fetchAntifraudDictionaryActions } from "../../services/antifraudDictionariesApi";
import { GET_ANTIFRAUD_DICTIONARY_ACTIONS } from "../../helpers/constants";

export function* getAntifraudDictionaryActions(): Saga<void> {
  yield put(setLoading(GET_ANTIFRAUD_DICTIONARY_ACTIONS, true));
  try {
    const { data: actions } = yield call(fetchAntifraudDictionaryActions);

    yield put(addDropdown("actions", actions));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(err);
  }
  finally {
    yield put(setLoading(GET_ANTIFRAUD_DICTIONARY_ACTIONS, false));
  }
}

export default function* watchGetAntifraudDictionaryActions(): Saga<void> {
  yield takeEvery(GET_ANTIFRAUD_DICTIONARY_ACTIONS, getAntifraudDictionaryActions);
}
