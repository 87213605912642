/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put, select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  stepChange,
  setStepError,
} from "@fas/ui-framework/lib/redux/actions/steps";
import { createGeneralFilter, updateGeneralFilter } from "../../services/generalFilterApiV2";
import { getFilterInfo } from "../../selectors/generalFilter/indexV2";
import { type FilterInfo } from "../../reducers/generalFilterInfo";
import { SAVE_GENERAL_FILTER_SAGA } from "../../helpers/constants";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(SAVE_GENERAL_FILTER_SAGA, true));

    const {
      id,
      name,
      description,
      priority,
      oldPriority,
      rate,
      mustHave,
      isActive,
      tdsCampaignsIn,
      tdsCampaignsOut,
      conditions,
      fact,
      dryModeRate,
    }: FilterInfo = yield select(getFilterInfo);

    const body = {
      id,
      name,
      description,
      priority,
      oldPriority,
      rate,
      isActive,
      mustHave,
      tdsCampaignsIn: tdsCampaignsIn.map((item) => item.value),
      tdsCampaignsOut,
      conditions,
      isLastStep: false,
      fact,
      dryModeRate,
    };

    if (id) {
      yield call(updateGeneralFilter, body, id);
    }
    else {
      yield call(createGeneralFilter, body);
    }

    yield put(stepChange("2"));
  }
  catch (err) {
    if (err.isAxiosError
      && err.response
      && err.response.data
      && err.response.data.errors
      && Array.isArray(err.response.data.errors)) {
      yield put(setErrors(err.response.data.errors));
      yield put(setStepError());
    }
    else {
      yield put(addNotification({ message: err.errorMessage || "Failed to save", severity: "error" }));
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  finally {
    yield put(setLoading(SAVE_GENERAL_FILTER_SAGA, false));
  }
}

export default function* watchSaveGeneralFilter(): Saga<void> {
  yield takeEvery(SAVE_GENERAL_FILTER_SAGA, (makeFetch: Function));
}
