/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect, type StatelessFunctionalComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SlideModal } from "@fas/ui-core";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { changeTableFilters, changeTableSorting, setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import {
  funnelTemplateListModalIsOpen,
  getCurrentSelectedFunnelTemplateSplit,
  getCurrentSelectedFunnelTemplateId,
  getFunnelTemplateByTemplateId,
} from "../../selectors/smartlink";
import { funnelTemplateListModalClose } from "../../actions/modals";
import { setSplitFunnelTemplate } from "../../actions/splits";
import { setFunnelTemplate, type FunnelTemplate } from "../../actions/dictionaries";
import FunnelsTemplateList from "../FunnelsTemplateList";
import { FUNNELS_TEMPLATE_LIST_TABLE } from "../../helpers/constants";
import { useClearTable, type ClearTableHook } from "../../hooks";

type Props = {
  open: boolean,
  selected: FunnelTemplate[],
  onSave: () => void,
  onClose: () => void,
};

const useFunnelTemplateListModal: () => Props = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const { clearTableData }: ClearTableHook = useClearTable(FUNNELS_TEMPLATE_LIST_TABLE);

  const open: boolean = useSelector(funnelTemplateListModalIsOpen);
  const splitId: string = useSelector(getCurrentSelectedFunnelTemplateSplit);
  const templateId: string = useSelector(getCurrentSelectedFunnelTemplateId);
  const template: FunnelTemplate = useSelector((state) => getFunnelTemplateByTemplateId(state, templateId));
  const selected: FunnelTemplate[] = useSelector((state) => getTableSelections(state, FUNNELS_TEMPLATE_LIST_TABLE));

  useEffect(() => {
    if (open) {
      dispatch(changeTableFilters(FUNNELS_TEMPLATE_LIST_TABLE, { type: "technical" }));
      dispatch(changeTableSorting(FUNNELS_TEMPLATE_LIST_TABLE, { createdAt: "desc" }));
      dispatch(setTableSelection(FUNNELS_TEMPLATE_LIST_TABLE, templateId ? [template] : []));
    }
  }, [open]);

  const onClose: () => void = () => {
    dispatch(funnelTemplateListModalClose());
    clearTableData();
  };

  const onSave: () => void = () => {
    dispatch(setSplitFunnelTemplate(splitId, selected[0].id));
    dispatch(setFunnelTemplate(selected[0]));
    onClose();
  };

  return {
    open,
    selected,
    onSave,
    onClose,
  };
};

const rowSelectAvailable: (FunnelTemplate, FunnelTemplate[]) => boolean = (_, selected) => selected.length < 1;

const FunnelTemplateListModal: StatelessFunctionalComponent<{}> = () => {
  const {
    open,
    selected,
    onSave,
    onClose,
  }: Props = useFunnelTemplateListModal();

  return (
    <SlideModal
      open={open}
      onClose={onClose}
      title="Funnels Template"
      isSaveDisabled={selected.length < 1}
      dataTestid="template-list-modal"
      content={<FunnelsTemplateList rowSelectAvailable={rowSelectAvailable} />}
      onSave={onSave}
    />
  );
};

export default FunnelTemplateListModal;
