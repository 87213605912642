// @flow
/* eslint-disable no-useless-return */
import {
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { STEP_CLICK } from "@fas/ui-framework/lib/redux/constants";
import type { StepClickAction } from "@fas/ui-framework/lib/redux/actions/steps";
import {
  getCurrentStepId,
} from "../../selectors/smartlink";

export function* stepsClickGeneralFilter(action: StepClickAction): Saga<void> {
  const currentStepId = yield select(getCurrentStepId);
  if (currentStepId === action.id) {
    return;
  }
  return;
}

export default function* watchStepsClick(): Saga<void> {
  yield takeEvery(STEP_CLICK, stepsClickGeneralFilter);
}
