/* eslint-disable import/max-dependencies */
// @flow
import React, {
  type StatelessFunctionalComponent,
  type Element,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import {
  Table,
  SelectSearchComponent,
  IsActiveLabel,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import { userService } from "../../services/user";
import { ANTIFRAUD_TRIGGERS_TABLE } from "../../helpers/constants";
import {
  getAntifraudTriggerListSaga,
  changeAntifraudTriggerActiveSaga,
  type GetAntifraudTriggerListSaga,
} from "../../actions/antifraudTriggers";
import TableActions from "../TdsAdminTableActions";
import type {
  Props,
  TriggerListItem,
} from "./types/AntifraudTriggerList.types";

const onCreate: () => void = () => {
  window.location = "/antifraudTriggers";
};

export const columns: Array<Column<TriggerListItem>> = [
  {
    field: "ruleId",
    label: "ID",
    searchable: true,
    sortable: true,
  },
  {
    field: "name",
    label: "Trigger",
    searchable: true,
    sortable: true,
  },
  {
    field: "score",
    label: "Score",
    searchable: true,
    sortable: true,
  },
  {
    field: "isActive",
    label: "Is Active",
    searchable: true,
    sortable: true,
    render: IsActiveLabel,
    SearchComponent: (searchProps: SearchComponentProps): Element<typeof SelectSearchComponent> => (
      <SelectSearchComponent
        {...searchProps}
        values={[
          { title: "All", value: "" },
          { title: "Active", value: "true" },
          { title: "Inactive", value: "false" },
        ]}
        label="isActive"
      />
    ),
  },
];

const AntifraudTriggerList: StatelessFunctionalComponent<Props> = () => {
  const tableProps: TableProps = useTable(ANTIFRAUD_TRIGGERS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onGetAntifraudTriggerListSaga: () => GetAntifraudTriggerListSaga = () => (
    dispatch(getAntifraudTriggerListSaga()));

  const isActivateAvailable: boolean = userService.can([{ path: "/api/v1/frodi/triggers/changeIsActive", method: "POST" }]);

  const getIsEditAvailable: (items: TriggerListItem[]) => boolean = (items) => (
    items.length > 1 || !userService.can([
      { path: "/api/v1/frodi/triggers", method: "GET" },
      { path: "/api/v1/frodi/triggers", method: "PUT" },
    ])
  );

  const getIsActivateAvailable: (items: TriggerListItem[]) => boolean = (items) => (
    items.some(({ isActive }: TriggerListItem): boolean => isActive) || !isActivateAvailable
  );

  const getIsDeactivateAvailable: (items: TriggerListItem[]) => boolean = (items) => (
    items.some(({ isActive }: TriggerListItem): boolean => !isActive) || !isActivateAvailable
  );

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        title="Triggers"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onCreate={onCreate}
        onLoad={onGetAntifraudTriggerListSaga}
        Actions={(
          <TableActions
            tableKey={ANTIFRAUD_TRIGGERS_TABLE}
            editPath="/antifraudTriggers/:id"
            getIsEditAvailable={getIsEditAvailable}
            onChangeIsActive={changeAntifraudTriggerActiveSaga}
            getIsActivateAvailable={getIsActivateAvailable}
            getIsDeactivateAvailable={getIsDeactivateAvailable}
          />
        )}
      />
    </Box>
  );
};

export default AntifraudTriggerList;
