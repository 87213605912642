// @flow
import {
  CHANGE_TEMPLATE_NAME,
  CHANGE_TEMPLATE_TYPE,
} from "../../helpers/constants";

export type ChangeTemplateNameAction = {|
  type: typeof CHANGE_TEMPLATE_NAME,
  name: string,
|};

export type ChangeTemplateTypeAction = {|
  type: typeof CHANGE_TEMPLATE_TYPE,
  templateType: string,
|};

export const changeTemplateName = (name: string): ChangeTemplateNameAction => ({
  type: CHANGE_TEMPLATE_NAME,
  name,
});

export const changeTemplateType = (templateType: string): ChangeTemplateTypeAction => ({
  type: CHANGE_TEMPLATE_TYPE,
  templateType,
});
