/* eslint-disable import/max-dependencies */
// @flow
import {
  takeEvery,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { type ValidationResult } from "joi";
import { SAVE_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import parseJoiErrors from "@fas/ui-framework/lib/services/parseJoiErrors";
import {
  SAVE_ANTIFRAUD_SCENARIO_SAGA,
  DYNAMIC_SCENARIO,
} from "../../helpers/constants";
import { getAntifraudScenario, type RuleForSave, type Rule } from "../../selectors/antifraudScenarios";
import { updateAntifraudScenario, createAntifraudScenario } from "../../services/antifraudScenariosApi";
import type { ScenarioFormData, DynamicDict } from "../../reducers/antifraudScenarioForm";
import { defaultSchema, dynamicSchema } from "./schemas";
import ExternalRedirect from "../../components/App/ExternalRedirect";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(SAVE_FORM_DATA_SAGA, true));

    const {
      rules,
      actions: actionsList,
      scenarioId,
      ...scenario
    }: ScenarioFormData = yield select(getAntifraudScenario);
    const rulesForSave: Array<RuleForSave> = rules.toArray()
      .map(({ ruleId, isActive }: Rule): RuleForSave => ({ ruleId, isActive }));
    const actions: Array<DynamicDict> = actionsList.toArray();

    let body: $Shape<ScenarioFormData> = scenario;
    let validationErrors: ErrorsMapper;
    let actionsError: { actions: { message: string } };

    if (scenario.typeName === DYNAMIC_SCENARIO) {
      const validationResult: ValidationResult = dynamicSchema.validate({ ...scenario, actions });
      actionsError = validationResult?.error?.details.some(({ path }) => path.includes("actions"))
        ? { actions: { message: "Empty fields are not allowed" } }
        : {};

      validationErrors = {
        ...parseJoiErrors(validationResult),
        ...actionsError,
      };

      body = { ...body, actions };
    }
    else {
      const validationResult: ValidationResult = defaultSchema.validate(body);
      validationErrors = parseJoiErrors(validationResult);
    }

    yield put(setErrors(validationErrors));
    if (Object.keys(validationErrors).length) return;

    if (scenarioId) {
      yield call(updateAntifraudScenario, { ...body, scenarioId, rules: rulesForSave });
    }
    else {
      yield call(createAntifraudScenario, { ...body, rules: rulesForSave });
    }

    ExternalRedirect({ to: "/antifraudScenarios/list" });
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to save scenario", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(SAVE_FORM_DATA_SAGA, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(SAVE_ANTIFRAUD_SCENARIO_SAGA, (makeFetch: Function));
}
