// @flow
import React, {
  type StatelessFunctionalComponent,
  type Element,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import { Table, SelectSearchComponent } from "@fas/ui-core";
import { Check, Close } from "@mui/icons-material";
import { OFFERS_TABLE } from "../../helpers/constants";
import { getOffersSaga, type GetOffersSaga } from "../../actions/offers";
import type { Offer } from "../../reducers/offers";

type Props = $ReadOnly<{|
  rowSelectAvailable: (Offer, Offer[]) => boolean,
|}>;

const columns: Column<Offer>[] = [
  {
    field: "id",
    label: "Id",
    searchable: true,
  },
  {
    field: "name",
    label: "Name",
    searchable: true,
  },
  {
    field: "withBanners",
    label: "Banners",
    searchable: true,
    SearchComponent: (props: SearchComponentProps): Element<*> => (
      <SelectSearchComponent
        {...props}
        label="Select"
        values={[
          { value: "", title: "None" },
          { value: "true", title: "Contain" },
          { value: "false", title: "Not Contain" },
        ]}
      />
    ),
    render: ({ withBanners }: Offer): Element<*> => (withBanners ? <Check color="action" /> : <Close color="error" />),
  },
];

const compareRows: (Offer, Offer) => boolean = (current, selected) => current.id === selected.id;
const allSelectAvailable: () => boolean = () => false;

const OffersTable: StatelessFunctionalComponent<Props> = (props) => {
  const tableProps: TableProps = useTable(OFFERS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onLoad: () => GetOffersSaga = () => dispatch(getOffersSaga());

  return (
    <Table
      {...tableProps}
      {...props}
      title="Offers"
      columns={columns}
      allSelectAvailable={allSelectAvailable}
      onLoad={onLoad}
      compareRows={compareRows}
    />
  );
};

export default OffersTable;
