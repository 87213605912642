// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { useSelector } from "react-redux";
import { SlideModal } from "@fas/ui-core";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import OffersTable from "../OffersTable";
import { getIsOffersModalOpen } from "../../selectors/smartlink";
import type { Offer } from "../../reducers/offers";
import { OFFERS_TABLE } from "../../helpers/constants";

export type Props = $ReadOnly<{|
  title: string,
  limit: number,
  onSave: (Offer[]) => mixed,
  onCancel: () => mixed,
|}>;

const rowSelectAvailable: (number) => (Offer, Offer[]) => boolean = (limit) => (_, selected) => selected.length < limit;

const OffersSetup: StatelessFunctionalComponent<Props> = ({
  title,
  limit,
  onSave,
  onCancel,
}) => {
  const isOpen: boolean = useSelector(getIsOffersModalOpen);
  const selectedOffers: Offer[] = useSelector((state): Offer[] => getTableSelections(state, OFFERS_TABLE));

  return (
    <SlideModal
      open={isOpen}
      title={title}
      onClose={onCancel}
      data-testid="offers-modal"
      isSaveDisabled={selectedOffers.length === 0}
      content={<OffersTable rowSelectAvailable={rowSelectAvailable(limit)} />}
      onSave={(): mixed => onSave(selectedOffers)}
    />
  );
};

export default OffersSetup;
