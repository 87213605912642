// @flow
import {
  takeEvery,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getNextId,
  getFunnelTimestamp,
  getFunnelName,
} from "@fas/ui-framework/lib/services/generators";
import { ADD_FUNNEL_SAGA } from "../../helpers/constants";
import { addFunnel, addTargetingToFunnel } from "../../actions/funnels";
import {
  getActionOptionsParamsllIds,
  getFunnelsAllIds,
  getNameBySplitId,
  getTargetingsAllIds,
  getFunnelsBySplitId,
} from "../../selectors/smartlink";
import { addTargeting } from "../../actions/targetings";
import { addActionOptionsParams } from "../../actions/actionOptionsParams";
import type { AddFunnelSagaAction } from "../../actions/funnels/actions";

export function* addFunnelSaga(action: AddFunnelSagaAction): Saga<void> {
  const { splitId } = action;

  const allIds = yield select(getFunnelsAllIds);
  const splitName = yield select(getNameBySplitId, splitId);
  const targetingAllIds = yield select(getTargetingsAllIds);
  const aopAllIds = yield select(getActionOptionsParamsllIds);
  const aopId = getNextId(aopAllIds);
  const funnelId = getNextId(allIds);

  const funnels = yield select(getFunnelsBySplitId, splitId);
  const name = getFunnelName(splitName, funnels.size + 1);

  const timestamp = getFunnelTimestamp(funnelId);
  const isActive = true;
  const rootTargetingId = getNextId(targetingAllIds);
  const nextTargetingId = Number(rootTargetingId) + 1;
  const targetingSchema = {
    [rootTargetingId]: {
      type: "group",
      targetings: [nextTargetingId.toString()],
      groupOperator: "AND",
    },
    [nextTargetingId]: {
      type: "rule",
      name: "platform",
      operator: "equal",
      value: [],
    },
  };

  yield put(addFunnel(
    splitId,
    funnelId,
    name,
    timestamp,
    isActive,
    rootTargetingId
  ));
  yield put(addActionOptionsParams(funnelId, aopId));
  yield put(addTargetingToFunnel({ funnelId, targetingRootId: rootTargetingId }));
  yield put(addTargeting({ targetingSchema }));
}

export default function* watchAddFunnel(): Saga<void> {
  yield takeEvery(ADD_FUNNEL_SAGA, addFunnelSaga);
}
