// @flow
import { call, put, takeEvery } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setFormData, type GetFormDataSagaType } from "@fas/ui-framework/lib/redux/actions/form";
import { GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { apiMapGetFormData } from "../../services/form";

export function* makeFetch({ key, id }: GetFormDataSagaType): Saga<void> {
  try {
    yield put(setLoading(GET_FORM_DATA_SAGA, true));
    const data = yield call(apiMapGetFormData[key], id);
    yield put(setFormData(key, data));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(GET_FORM_DATA_SAGA, false));
  }
}

export default function* watchGetFormDataSaga(): Saga<void> {
  yield takeEvery(GET_FORM_DATA_SAGA, (makeFetch: Function));
}
