// @flow
import type { RecordOf, RecordFactory } from "immutable";
import {
  Record, Map, List, fromJS,
} from "immutable";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import {
  ADD_TARGETING_GF,
  ADD_TARGETING_GROUP_GF,
  CHANGE_GROUP_OPERATOR_GF,
  DELETE_TARGETING_GF,
  CHANGE_TARGETING_GF_RULE,
  CHANGE_TARGETING_GF_OPERATOR,
  CHANGE_TARGETING_GF_VALUE,
  INIT_TARGETING_GF,
} from "../../helpers/constants";
import type {
  AddTargetingGf,
  AddTargetingGroupGf,
  ChangeTargetingGfRule,
  ChangeTargetingGroupOperatorGf,
  DeleteTargetingGf,
  ChangeTargetingGfOperator,
  ChangeTargetingGfValue,
  Actions,
} from "../../actions/gfTargeting";

export type RuleState = Map<string, *>;
export type RuleStateJs = {|
  name: string,
  type: string,
  value: Array<*>,
  operator: string,
|};
export type GroupState = Map<string, *>;
export type GroupStateJs = {
  type: string,
  groupOperator: "OR" | "AND",
  targetings: Array<string>,
};
export type TargetingRule = RuleState | GroupState;
export type TargetingRuleJs = RuleStateJs | GroupStateJs;
export type DefaultState = {
  byId: Map<string, TargetingRule>,
  allIds: List<string>,
};

export type State = RecordOf<DefaultState>;

const emptyRule: RuleStateJs = {
  type: "rule",
  name: "",
  operator: "",
  value: List([]),
};

const emptyGroup: GroupStateJs = {
  type: "group",
  groupOperator: "AND",
  targetings: List([]),
};

const defaultValues: DefaultState = {
  byId: Map({
    "1": Map({
      type: "group",
      groupOperator: "AND",
      targetings: List(["2"]),
      parentId: null,
    }),
    "2": Map({
      type: "rule",
      name: "platform",
      operator: "equal",
      value: List([]),
      parentId: "1",
    }),
  }),
  allIds: List(["1", "2"]),
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export const initialState: State = makeState({});

export function initGfTargetingState(): State {
  return initialState;
}

export const reducer: (State, Actions) => State = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case ADD_TARGETING_GF: {
      const { groupId }: AddTargetingGf = action;
      return state.withMutations((newState: State) => {
        const newId: string = getNextId(newState.get("allIds"));
        newState.setIn(["byId", newId], Map({ ...emptyRule, parentId: groupId }));
        newState.updateIn(["byId", groupId, "targetings"], (targetingsIds: List<string>): List<string> => List([...targetingsIds, newId]));
        newState.updateIn(["allIds"], (allIds: List<string>): List<string> => allIds.push(newId));
      });
    }
    case ADD_TARGETING_GROUP_GF: {
      const { groupId }: AddTargetingGroupGf = action;
      return state.withMutations((newState: State) => {
        const newId: string = getNextId(newState.get("allIds"));
        newState.setIn(["byId", newId], Map({ ...emptyGroup, parentId: groupId }));
        newState.updateIn(["byId", groupId, "targetings"], (targetingsIds: List<string>): List<string> => List([...targetingsIds, newId]));
        newState.updateIn(["allIds"], (allIds: List<string>): List<string> => allIds.push(newId));
      });
    }
    case CHANGE_GROUP_OPERATOR_GF: {
      const { id, val }: ChangeTargetingGroupOperatorGf = action;
      return state.withMutations((newState: State) => {
        newState.setIn(["byId", id, "groupOperator"], val);
      });
    }
    case DELETE_TARGETING_GF: {
      const { targetingId, parentId }: DeleteTargetingGf = action;
      return state.withMutations((newState: State) => {
        newState.deleteIn(["byId", targetingId]);
        newState.deleteIn([
          "allIds",
          newState.get("allIds").findIndex((el: string): boolean => el === targetingId),
        ]);
        newState.updateIn(["byId", parentId, "targetings"], (targeting: List<string>): List<string> => targeting.filter((id: string): boolean => id !== targetingId));
      });
    }
    case CHANGE_TARGETING_GF_RULE: {
      const { targetingId, rule }: ChangeTargetingGfRule = action;
      return state.withMutations((newState: State) => {
        newState.setIn(["byId", targetingId, "name"], rule ? rule.name.value : "");
        newState.setIn(["byId", targetingId, "operator"], "");
        newState.setIn(["byId", targetingId, "value"], List([]));
      });
    }
    case CHANGE_TARGETING_GF_OPERATOR: {
      const { targetingId, operator }: ChangeTargetingGfOperator = action;
      return state.withMutations((newState: State) => {
        newState.setIn(["byId", targetingId, "operator"], operator.value);
      });
    }
    case CHANGE_TARGETING_GF_VALUE: {
      const { targetingId, value }: ChangeTargetingGfValue = action;
      return state.withMutations((newState: State) => {
        newState.setIn(["byId", targetingId, "value"], List(value));
      });
    }
    case INIT_TARGETING_GF: {
      const { value } = action;
      return state.withMutations((newState: State) => {
        newState.set("byId", fromJS(value.byId));
        newState.set("allIds", fromJS(value.allIds));
      });
    }
    default:
      return state;
  }
};
