// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box,
  Typography,
  IconButton,
  type Theme,
  Grid,
  Switch,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import type { Rule } from "../../selectors/antifraudScenarios";
import type { RemoveAntifraudScenarioRule, ChangeAntifraudScenarioRuleStatus } from "../../actions/antifraudScenarioForm";

type Classes = {
  root: string,
  deleteIcon: string,
};

type RowProps = {
  index: number,
  data: {
    rules: Array<Rule>,
    onRemoveAntifraudScenarioRule: (index: number) => RemoveAntifraudScenarioRule,
    onChangeAntifraudScenarioRuleStatus: (index: number, status: boolean) => ChangeAntifraudScenarioRuleStatus,
  },
  style: mixed,
};

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: mixed} => ({
  root: {
    border: `1px solid ${theme.palette.borderColor}`,
    backgroundColor: theme.palette.background.blueBackgroundLight,
    borderRadius: theme.spacing(1),
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    marginBottom: theme.spacing(1),
  },
  deleteIcon: {
    textAlign: "right",
  },
}));

const AntifraudScenarioRulesRow: StatelessFunctionalComponent<RowProps> = ({ index, data, style }) => {
  const classes: Classes = useStyles();
  const { rules, onRemoveAntifraudScenarioRule, onChangeAntifraudScenarioRuleStatus } = data;
  const rule: Rule = rules[index];

  return (
    <Box style={style}>
      <Grid container className={classes.root} key={rule.ruleId}>
        <Grid item xs={2}>
          <Typography>{rule.ruleId}</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography>{rule.name}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography>{rule.score}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            data-testid="rule-isActive"
            name="isActive"
            checked={rule.isActive}
            onClick={(e) => {
              onChangeAntifraudScenarioRuleStatus(index, e.target.checked);
            }}
            color="secondary"
          />
        </Grid>
        <Grid item xs={1} textAlign="right">
          <Box>
            <IconButton
              size="small"
              data-testid="antifraud-scenario-remove-rule"
              onClick={() => {
                onRemoveAntifraudScenarioRule(index);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AntifraudScenarioRulesRow;
