// @flow
import { Grid, FormControlLabel, Switch } from "@mui/material";
import React from "react";
import SelectBasicCampaignUrl from "../SelectBasicCampaignUrl";
import type { OfferItem } from "../FilteringList/types/FilteringList.types";

type Props = {
  uniqueElementId: string,
  campaignId: ?string,
  url: ?string,
  onUrlChange: (e: OfferItem) => void,
  checked: boolean,
  onCheckChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  label: string
};

const UseOneLink = (props: Props) => {
  const {
    uniqueElementId,
    campaignId,
    url,
    onUrlChange,
    checked,
    onCheckChange,
    label,
  } = props;
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={2}>
        <FormControlLabel
          control={<Switch checked={checked} onChange={onCheckChange} data-testid="use-one-link-switch" />}
          label={label}
        />
      </Grid>
      <Grid item xs={8}>
        <SelectBasicCampaignUrl
          uniqueElementId={uniqueElementId}
          campaignId={campaignId}
          url={url}
          changeUrl={onUrlChange}
          isDisabled={!checked}
        />
      </Grid>
    </Grid>
  );
};

export default UseOneLink;
