// @flow
import Joi from "@hapi/joi";
import Offers from "./offers";
import WithWeights from "./weights";

export default class FallbackOffers extends Offers {
  constructor() {
    super();
    this.errorKey = "fallbackOffers";

    this.schema = WithWeights.array()
      .min(1)
      .items(
        Joi.object({
          weight: Joi.number()
            .integer()
            .min(0)
            .max(100),
          url: Joi.string(),
          withBanners: Joi.boolean(),
        }).unknown(true)
      )
      .validateTotalWeightOnActive()
      .error((errors) => this._onError(errors));
  }
}
