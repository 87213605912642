// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { SmartlinkRedirect } from "../../selectors/smartlinkAggregators";
import type { Sorting } from "../../sagas/getTableSaga/getTableSaga";

export type SmartlinkAggregatorsToCampaigns = {
  saId: string,
  saName: string,
  saStatus: number,
  saInCampaignStatus: number,
  campaignId: string,
  campaignName: string,
  campaignStatus: string,
  created: string,
  split: string,
  funnel: {
    funnelIndex: number,
    funnelName: string,
    funnelType: string,
  },
};
export type SmartlinkAggregatorsToCampaignsFilters = {|
  saId?: Array<{ label: string, value: string }>,
  saName?: Array<{ label: string, value: string }>,
  campaignId?: Array<{ label: string, value: string }>,
  campaignName?: Array<{ label: string, value: string }>,
  split?: Array<{ label: string, value: string }>,
  funnelName?: Array<{ label: string, value: string }>,
  campaignStatus?: Array<{ label: string, value: string }>,
  saStatus?: Array<{ label: string, value: string }>,
  saInCampaignStatus?: Array<{ label: string, value: string }>,
|}
export type SmartlinkAggregatorsToCampaignsProps = {
  page: number,
  limit: number,
  filters: {
    [string]: Array<string>
  },
  sorting: {
    current?: string,
    direction?: "asc" | "desc"
  },
  headers: Array<string>,
}

// eslint-disable-next-line
export const fetchSmartlinkAggregatorsList: (
  options: {
    page: number,
    pageSize: number,
    filters: { [string]: mixed },
    sorting?: Sorting,
  },
) => PromiseResponse<{ data: { data: Array<SmartlinkRedirect>, count: number } }> = (
  options
) => {
  const { filters, sorting, ...params }: {
    page: number,
    pageSize: number,
    filters: { [string]: mixed },
    sorting?: Sorting,
  } = options;
  let newSorting: Sorting;
  if (sorting && Object.keys(sorting).length) {
    const current = Object.keys(sorting)[0];
    newSorting = {
      current,
      direction: sorting[current],
    };
  }
  // $FlowFixMe
  const paramsData = {
    ...params,
    // $FlowFixMe
    filters: {
      ...filters,
      ...(filters.isActive && { isActive: Number(filters.isActive) }),
    },
    ...(newSorting && { sorting: newSorting }),
  };

  return requestService
    .post(environment.endpoints.post.getSmartlinkAggregators, paramsData)
    .then(({ data: { data, ...rest } }): Array<SmartlinkRedirect> => ({
      ...rest,
      data: data.map(({ isActive, ...redirectData }: SmartlinkRedirect): SmartlinkRedirect => ({
        ...redirectData,
        isActive: Boolean(isActive),
      })),
    }));
};

export const getSmartlinkRedirect: (
  id: number
) => PromiseResponse<{ data: SmartlinkRedirect }> = (id) => requestService
  .get(environment.endpoints.get.getSmartlinkAggregatorsBySmartlinkId.replace("{id}", `${id}`))
  .then(({ data }): SmartlinkRedirect => ({ ...data.data, isActive: Boolean(data.data.isActive) }));

export const createSmartlinkRedirect: (
  data: SmartlinkRedirect,
) => PromiseResponse<{}> = ({ isActive, ...rest }) => requestService
  .post(environment.endpoints.post.smartlinkAggregators, { ...rest, isActive: (isActive) ? 1 : 0 });

export const updateSmartlinkRedirect: (
  data: SmartlinkRedirect,
  id: number,
) => PromiseResponse<{}> = ({
  name, isActive, placeholders, smartlinkId,
}, id) => requestService
  .put(environment.endpoints.put.updateSmartlinkAggregators.replace("{id}", `${id}`), {
    name, smartlinkId, isActive: (isActive) ? 1 : 0, placeholders,
  });

export const getSmartlinkIdList = (searchStr?: string, limit?: number): PromiseResponse<mixed> => requestService
  .get(environment.endpoints.get.getSmartlinkAggregatorsDropdown, {
    params: {
      smartlinkId: searchStr,
      limit,
    },
  }).then((result) => result.data.data.map((i) => ({ label: i.value, value: i.value })));

export const changeSmartlinkAggregatorActive: (
  ids: number[],
  isActive: boolean
) => PromiseResponse<{}> = (ids, isActive) => requestService
  .put(environment.endpoints.put.smartlinkAggregatorActive, { ids, isActive: (isActive) ? 1 : 0 });

export const fetchSmartlinkAggregatorsToCampaignList = (props: SmartlinkAggregatorsToCampaignsProps):
  PromiseResponse<*, {
  data: Array<SmartlinkAggregatorsToCampaigns>,
  count: number,
}> => requestService.post(environment.endpoints.post.getSmartlinkAggregatorsToCampaigns, props);

export const fetchSAFiltersList = async (name: string, filterValue: string, additionalParameter: string):
  PromiseResponse<*, Array<{label: string, value: string }>
> => {
  const result = await requestService.get(environment.endpoints.get.getSmartlinkAggregatorsToCampaignDropdown, {
    params: {
      type: name,
      filter: filterValue,
      additionalParameter,
    },
  });

  return result.data.data;
};
