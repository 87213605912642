// @flow
import { put, takeEvery } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { SAVE_MODAL_GENERAL_FILTERS_SAGA } from "../../helpers/constants";
import { clearBackup } from "../../actions/backup";
import { closeGeneralFiltersModal } from "../../actions/modals";

export function* saveGeneralFiltersSaga(): Saga<void> {
  yield put(closeGeneralFiltersModal());
  yield put(clearBackup());
}

export default function* watchSaveGeneralFilters(): Saga<void> {
  yield takeEvery(SAVE_MODAL_GENERAL_FILTERS_SAGA, saveGeneralFiltersSaga);
}
