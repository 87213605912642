// @flow
import {
  FUNNELTEMPLATE_REPORT_SET_GROUP_FILTERS_ORDER,
  GET_FUNNELS_TEMPLATES_REPORT_LIST_SAGA,
} from "../../helpers/constants";

export type SetGroupFilters = {|
  type: typeof FUNNELTEMPLATE_REPORT_SET_GROUP_FILTERS_ORDER,
  groupBy: string,
  filterOrder: string[],
|};

export type GetFunnelTemplatesReportListSaga = {|
  type: typeof GET_FUNNELS_TEMPLATES_REPORT_LIST_SAGA,
|};

export type Actions = SetGroupFilters | GetFunnelTemplatesReportListSaga;

type GroupFilterOrderType = { groupBy: string, filterOrder: string[] };

export const setGroupFiltersOrder = ({ groupBy, filterOrder }: GroupFilterOrderType): SetGroupFilters => ({
  type: FUNNELTEMPLATE_REPORT_SET_GROUP_FILTERS_ORDER,
  groupBy,
  filterOrder,
});

export const getFunnelTemplatesReportListSaga = (): GetFunnelTemplatesReportListSaga => ({
  type: GET_FUNNELS_TEMPLATES_REPORT_LIST_SAGA,
});
