// @flow
import { List } from "immutable";
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { createLoadingSelector, type StoreWithLoading } from "@fas/ui-framework/lib/redux/selectors/loading";
import { getErrorMessage } from "@fas/ui-framework/lib/redux/selectors/errors";
import type { Errors } from "../../components/AntifraudScenarioForm";
import type { ScenarioFormData, DynamicDict, State } from "../../reducers/antifraudScenarioForm";
import type { State as AntifraudScenarioFormState } from "../../pages/AntifraudScenarioForm";
import type { AntifraudScenarioLoadingTypes, Rule } from "./types";

export const getAntifraudScenario: OutputSelector<
  AntifraudScenarioFormState, *, ScenarioFormData
> = createSelector(
  (state: AntifraudScenarioFormState): State => state.antifraudScenarioForm,
  (value: State): ScenarioFormData => value.toObject()
);

export const getAntifraudScenarioActions: OutputSelector<
  AntifraudScenarioFormState, *, Array<DynamicDict>,
> = createSelector(
  (state: AntifraudScenarioFormState): List<DynamicDict> => state.antifraudScenarioForm.get("actions"),
  (value: List<DynamicDict>): Array<DynamicDict> => value.toArray()
);

export const getAntifraudScenarioRules: OutputSelector<
  AntifraudScenarioFormState, *, Array<Rule>
> = createSelector(
  (state: AntifraudScenarioFormState): List<Rule> => state.antifraudScenarioForm.get("rules"),
  (value: List<Rule>): Array<Rule> => value.toArray()
);

export const getLoading: OutputSelector<
  StoreWithLoading<AntifraudScenarioLoadingTypes>, AntifraudScenarioLoadingTypes, boolean
> = createLoadingSelector<AntifraudScenarioLoadingTypes>();

export const getErrors: OutputSelector<AntifraudScenarioFormState, *, Errors> = createSelector(
  [
    (state: AntifraudScenarioFormState): string => getErrorMessage(state, "name"),
    (state: AntifraudScenarioFormState): string => getErrorMessage(state, "event"),
    (state: AntifraudScenarioFormState): string => getErrorMessage(state, "typeName"),
    (state: AntifraudScenarioFormState): string => getErrorMessage(state, "actions"),
  ],
  (name: string, event: string, typeName: string, actions: string): Errors => (
    {
      name,
      event,
      typeName,
      actions,
    })
);
