// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { FREQUENCY_CAP_FOR_JUMPS_TABLE } from "../../helpers/constants";
import { fetchFrequencyCapForJumpsList } from "../../services/frequencyCapForJumps";
import getTableSaga from "../../sagas/getTableSaga";
import changeIsFrequencyCap from "../../sagas/changeIsFrequencyCap";

const getListSaga = getTableSaga({
  tableName: FREQUENCY_CAP_FOR_JUMPS_TABLE,
  fetchMethod: fetchFrequencyCapForJumpsList,
});

export default function* mainSaga(): Saga<void> {
  yield all([
    call(getListSaga),
    call(changeIsFrequencyCap),
  ]);
}
