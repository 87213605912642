/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useEffect,
  type StatelessFunctionalComponent,
} from "react";
import { GroupContainer } from "@fas/ui-core";
import * as actions from "../../actions/antifraudTriggerForm";
import {
  getGroupByIdSelector,
  getRuleByIdSelector,
  getRuleList,
  getOperatorsList,
  getTriggerValuesList,
  getConditionsErrorById,
  getGroupErrorById,
  type BuilderDispatchActions,
} from "../../selectors/antifraudTrigger";
import {
  TriggerRuleComponent,
  TriggerRuleContainer,
} from "../TriggerRule";
import { useLoading, useActions } from "../../hooks";

type Props = {||};

const AntifraudTriggerConditionsBuilder: StatelessFunctionalComponent<Props> = () => {
  const {
    getAntifraudConditionsListSaga: onGetAntifraudConditionsListSaga,
    changeAntifraudConditionGroupOperator: onChangeGroupOperator,
    deleteAntifraudConditionRule: onRemoveRule,
    deleteAntifraudConditionGroup: onRemoveGroup,
    addAntifraudCondition: onAddRule,
    addAntifraudConditionGroup: onAddGroup,
    changeAntifraudConditionLocation: onChangeLocation,
    changeAntifraudConditionValue: onChangeValue,
    changeAntifraudConditionValueType: onChangeValueType,
    changeAntifraudConditionRule: onChangeRule,
    changeAntifraudConditionOperator: onChangeRuleOperator,
  }: BuilderDispatchActions = useActions(actions);

  const isGetAntifraudConditionsListLoading: boolean = useLoading("isGetAntifraudConditionsListLoading");

  useEffect(() => {
    onGetAntifraudConditionsListSaga();
  }, [onGetAntifraudConditionsListSaga]);

  return (
    <GroupContainer
      id="1"
      isRuleListLoading={isGetAntifraudConditionsListLoading}
      RuleComponent={TriggerRuleComponent}
      RuleContainer={TriggerRuleContainer}
      groupProps={{
        getGroupByIdSelector,
        changeGroupOperator: onChangeGroupOperator,
        removeRule: onRemoveRule,
        getStatusRule: (): boolean => false,
        addGroup: onAddGroup,
        removeGroup: onRemoveGroup,
        addRule: onAddRule,
        disabledDelete: true,
        getErrorMessage: getGroupErrorById,
      }}
      ruleProps={{
        getRuleByIdSelector,
        handleChangeLocation: onChangeLocation,
        handleChangeValue: onChangeValue,
        handleChangeRule: onChangeRule,
        handleChangeOperator: onChangeRuleOperator,
        handleChangeValueType: onChangeValueType,
        isRemoveDisabled: false,
        checkLoadingForValues: (): boolean => false,
        getRuleList,
        getOperatorsList,
        getValuesList: getTriggerValuesList,
        getErrors: getConditionsErrorById,
      }}
    />
  );
};

export default AntifraudTriggerConditionsBuilder;
