// @flow
import React, {
  type Element,
} from "react";
import { SelectSearchComponent } from "@fas/ui-core";
import { Link } from "react-router-dom";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { HistoryReplaceItem } from "../../selectors/historyReplace";
import { type Classes } from "./HistoryReplaceList.types";

export default function getColumns(
  dropdowns: {
    [key: string]: string[],
  },
  classes: Classes
): Array<Column<HistoryReplaceItem>> {
  const {
    brand,
    locale,
    platform,
    safeMode,
    niche,
    s1,
    status,
    statusReplace,
    messageReplace,
  } = dropdowns;

  return [
    {
      field: "id",
      label: "Id",
      sortable: true,
      render: ({ id }: HistoryReplaceItem) => <Link to={`/historyReplace/view/${id}`}>{id}</Link>,
    },
    {
      field: "campaignId",
      label: "Campaign Id",
      searchable: true,
      sortable: true,
      render: ({ campaignId, urlFunnel }: HistoryReplaceItem) => <Link to={urlFunnel}>{campaignId}</Link>,
    },
    {
      field: "lpNew",
      label: "Lp New",
      searchable: true,
      sortable: true,
    },
    {
      field: "lpNewName",
      label: "Lp New Name",
      searchable: true,
      sortable: true,
    },
    {
      field: "lpOld",
      label: "Lp Old",
      searchable: true,
      sortable: true,
    },
    {
      field: "lpOldName",
      label: "Lp Old Name",
      searchable: true,
      sortable: true,
    },
    {
      field: "brand",
      label: "Brand",
      searchable: true,
      sortable: true,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={brand.map((item) => ({ label: item, value: item }))}
          label="Brand"
        />
      ),
    },
    {
      field: "email",
      label: "Email",
      searchable: true,
      sortable: true,
    },
    {
      field: "locale",
      label: "Locale",
      searchable: true,
      sortable: true,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={locale.map((item) => ({ label: item, value: item }))}
          label="Locale"
        />
      ),
      render: ({ locale: locales }) => locales.join(", "),
    },
    {
      field: "platform",
      label: "Platform",
      searchable: true,
      sortable: true,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={platform.map((item) => ({ label: item, value: item }))}
          label="Platform"
        />
      ),
    },
    {
      field: "safeMode",
      label: "SafeMode",
      searchable: true,
      sortable: true,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={safeMode.map((item) => ({ label: item, value: item }))}
          label="SafeMode"
        />
      ),
    },
    {
      field: "niche",
      label: "Niche",
      searchable: true,
      sortable: true,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={niche.map((item) => ({ label: item, value: item }))}
          label="Niche"
        />
      ),
    },
    {
      field: "status",
      label: "Status",
      searchable: true,
      sortable: true,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={status.map((item) => ({ label: item, value: item }))}
          label="Status"
        />
      ),
    },
    {
      field: "s1",
      label: "S1",
      searchable: true,
      sortable: true,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={s1.map((item) => ({ label: item, value: item }))}
          label="S1"
        />
      ),
      render: ({ s1: s1List }) => s1List.join(", "),
    },
    {
      field: "errorSearch",
      label: "Error Search",
    },
    {
      field: "statusReplace",
      label: "Status Replace",
      className: classes.smallTableCol,
      searchable: true,
      sortable: true,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={statusReplace.map((item) => ({ label: item, value: item }))}
          label="Status Replace"
        />
      ),
    },
    {
      field: "messageReplace",
      label: "Message Replace",
      className: classes.smallTableCol,
      searchable: true,
      sortable: true,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={messageReplace.map((item) => ({ label: item, value: item }))}
          label="Message Replace"
        />
      ),
    },
    {
      field: "errorsReplace",
      label: "Errors Replace",
    },
    {
      field: "createdAt",
      label: "Created At",
      sortable: true,
    },
  ];
}
