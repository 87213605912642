// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import qs from "qs";
import type {
  Filters,
  Sorting,
} from "@fas/ui-framework/lib/redux/reducers/table";
import type { DropdownItem } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import { requestService } from "../request";
import type { Variable, VariableFormData } from "../../selectors/antifraudVariables";

export const fetchAntifraudVariablesList: (
  options: {
    page: number,
    limit: number,
    filters: Filters,
    sorting: Sorting,
  },
) => PromiseResponse<{ data: { list: Array<Variable>, count: number } }> = (
  options
) => requestService
  .get(environment.endpoints.get.antifraudVariablesList, {
    params: { ...options },
    paramsSerializer: ({ filters, sorting, ...rest }: *): string => qs.stringify({
      ...rest,
      filters,
      sorting,
    }, { arrayFormat: "brackets", encode: true }),
  })
  .then(({ data }: { data: Array<Variable> }): Array<Variable> => data);

export const changeAntifraudVariableActive: (
  ids: Array<{ id: number, status: boolean }>,
) => PromiseResponse<{}> = (ids) => requestService
  .post(environment.endpoints.post.antifraudVariableActive, ids);

export const fetchAntifraudVariable: (
  id: number
) => PromiseResponse<{ variable: VariableFormData }> = (id) => requestService
  .get<{ variable: VariableFormData }>(environment.endpoints.get.antifraudVariable, {
    params: {
      varId: id,
    },
  })
  .then(({ data }) => data);

export const createAntifraudVariable: (
  variable: VariableFormData
) => PromiseResponse<{}> = (variable) => requestService
  .post(environment.endpoints.post.antifraudVariable, variable)
  .then(({ data }) => data);

export const updateAntifraudVariable: (variable: VariableFormData) => PromiseResponse<{}> = (variable) => requestService
  .put(environment.endpoints.put.antifraudVariable, variable)
  .then(({ data }) => data);

export const fetchAntifraudVariableDropdown: (
  listName: string
) => PromiseResponse<{ data: Array<DropdownItem<string | number>> }> = (listName) => requestService
  .get<{ variable: VariableFormData }>(environment.endpoints.get.antifraudVariablesDropdown.replace("{name}", listName))
  .then(({ data }) => data);
