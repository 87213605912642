// @flow
import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

import {
  Typography, IconButton, Switch, Box,
} from "@mui/material";

export type Props = {
  title: string,
  isActive: boolean,
  isEditing: boolean,
  isSelected: boolean,
  isClonable: boolean,
  onChangeIsActive: Function,
  onClone: Function,
  onDelete: Function,
}

const SplitHeader = (
  {
    title,
    isActive,
    onChangeIsActive,
    onDelete,
    onClone,
    isEditing,
    isSelected,
    isClonable,
  }: Props
) => (
  <Box display="flex" alignItems="center">
    <Switch
      disabled={!isEditing}
      color="primary"
      checked={isActive}
      onClick={() => onChangeIsActive(!isActive)}
      data-testid="is-active-switch"
    />

    <Box minWidth={0} flexGrow={1}>
      <Typography
        color={isSelected ? "primary" : "initial"}
        noWrap
        data-testid="title"
      >
        {title}
      </Typography>
    </Box>

    <Box display="flex">
      {isEditing && (
        <IconButton onClick={onDelete} data-testid="delete-button">
          <DeleteOutlineIcon color="action" />
        </IconButton>
      )}
      {isClonable && (
        <IconButton onClick={onClone} data-testid="clone-button">
          <FileCopyOutlinedIcon color="action" />
        </IconButton>
      )}
    </Box>
  </Box>
);

export default SplitHeader;
