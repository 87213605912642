// @flow
import type { OutputSelector } from "reselect";
import { createSelector } from "reselect";
import { createLoadingSelector, type StoreWithLoading } from "@fas/ui-framework/lib/redux/selectors/loading";
import type { HistoryReplaceLoadingTypes } from "./types";
import { type State } from "../../pages/AppStoreWrapper/store";

// eslint-disable-next-line import/prefer-default-export
export const getLoading: OutputSelector<
  StoreWithLoading<HistoryReplaceLoadingTypes>, HistoryReplaceLoadingTypes, boolean
> = createLoadingSelector<HistoryReplaceLoadingTypes>();

export const getHistoryReplaceDropdownLists
: OutputSelector<State, *, { [string]: Array<string> }> = createSelector(
  (state: State) => state.dictionaries.get("historyReplace"),
  (value) => value.toJS()
);
