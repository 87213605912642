// @flow
import Joi from "@hapi/joi";
import BaseTargeting from "./Base";

class InvalidEmail extends BaseTargeting {
  getJoiSchemaValidation() {
    return Joi.object({
      name: Joi.string().required(),
      operator: Joi.string().required(),
      parentId: Joi.number(),
      type: Joi.string().required(),
      value: Joi.array().items(Joi.string()),
    });
  }
}

export default InvalidEmail;
