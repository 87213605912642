/* eslint-disable complexity */
// @flow
import { Map, fromJS } from "immutable";
import {
  OPEN_FUNNEL_MODAL,
  CLOSE_FUNNEL_MODAL,
  SET_CURRENT_FUNNEL,
  OPEN_OFFERS_MODAL,
  CLOSE_OFFERS_MODAL,
  OPEN_GENERAL_FILTERS_MODAL,
  CLOSE_GENERAL_FILTERS_MODAL,
  SET_CURRENT_SPLIT,
  OPEN_SPLIT_MODAL,
  CLOSE_SPLIT_MODAL,
  FUNNELTEMPLATE_LIST_MODAL_OPEN,
  FUNNELTEMPLATE_LIST_MODAL_CLOSE,
  FUNNELTEMPLATE_LIST_MODAL_SET_SPLIT_ID,
  FUNNELTEMPLATE_LIST_SET_COUNT,
  FUNNELTEMPLATE_LIST_MODAL_SET_TEMPLATE_ID,
} from "../../helpers/constants";
import type { ModalsActionType } from "../../actions/modals/actions";

export type ModalsState = {
  split: Map<{
    isOpen: boolean,
    currentSplit: string | null,
  }>,
  funnel: Map<{
    isOpen: boolean,
    currentFunnel: string | null,
    isContentDisabled: boolean,
  }>,
  offers: Map<{
    isOpen: boolean,
  }>,
  generalFilters: Map<{
    isOpen: boolean,
  }>,
  funnelTemplate: Map<{ selectedTemplate: string, count: number }>,
};

export type State = ModalsState;

export const initialState: Map<State> = Map({
  split: Map({
    isOpen: false,
    currentSplit: null,
  }),
  funnel: Map({
    isOpen: false,
    currentFunnel: null,
    isContentDisabled: false,
  }),
  offers: Map({
    isOpen: false,
  }),
  generalFilters: Map({
    isOpen: false,
  }),
  funnelTemplate: Map({
    isOpen: false,
    currentSplit: null,
    currentTemplate: null,
    count: 0,
  }),
});

export function initModalsState(data: { modals: $Shape<ModalsState> }) {
  let state;
  const { modals } = data;
  if (modals) {
    state = fromJS({
      ...initialState.toJS(),
      ...modals,
    });
  }
  else {
    state = initialState;
  }
  return state;
}

type Action = ModalsActionType;

export default (state: Map<State> = initialState, action: Action) => {
  switch (action.type) {
    case OPEN_FUNNEL_MODAL: {
      return state.setIn(["funnel", "isOpen"], true);
    }
    case CLOSE_FUNNEL_MODAL: {
      return state.setIn(["funnel", "isOpen"], false);
    }
    case SET_CURRENT_FUNNEL: {
      return state.setIn(["funnel", "currentFunnel"], action.funnelId);
    }
    case OPEN_OFFERS_MODAL: {
      return state.setIn(["offers", "isOpen"], true);
    }
    case CLOSE_OFFERS_MODAL: {
      return state.setIn(["offers", "isOpen"], false);
    }
    case OPEN_GENERAL_FILTERS_MODAL: {
      return state.setIn(["generalFilters", "isOpen"], true);
    }
    case CLOSE_GENERAL_FILTERS_MODAL: {
      return state.setIn(["generalFilters", "isOpen"], false);
    }
    case OPEN_SPLIT_MODAL: {
      return state.setIn(["split", "isOpen"], true);
    }
    case CLOSE_SPLIT_MODAL: {
      return state.setIn(["split", "isOpen"], false);
    }
    case SET_CURRENT_SPLIT: {
      return state.setIn(["split", "currentSplit"], action.splitId);
    }
    case FUNNELTEMPLATE_LIST_MODAL_OPEN: {
      return state.setIn(["funnelTemplate", "isOpen"], true);
    }
    case FUNNELTEMPLATE_LIST_MODAL_CLOSE: {
      return state.setIn(["funnelTemplate", "isOpen"], false);
    }
    case FUNNELTEMPLATE_LIST_MODAL_SET_SPLIT_ID: {
      const { splitId } = action;
      return state.setIn(["funnelTemplate", "currentSplit"], splitId);
    }
    case FUNNELTEMPLATE_LIST_MODAL_SET_TEMPLATE_ID: {
      const { templateId } = action;
      return state.setIn(["funnelTemplate", "currentTemplate"], templateId);
    }
    case FUNNELTEMPLATE_LIST_SET_COUNT: {
      const { count } = action;
      return state.setIn(["funnelTemplate", "count"], count);
    }
    default:
      return state;
  }
};
