// @flow
import Joi from "joi";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import { ANTIFRAUD_VARIABLE_SOURCE_REQUEST } from "../../helpers/constants";

const commonSchema = {
  name: Joi.string().required(),
  type: Joi.string().required(),
};

const valueFormSchema = Joi.object({
  ...commonSchema,
  value: Joi.string().required(),
}).messages({
  "number.base": "This field is required",
  "string.empty": "This field is required",
}).options({ stripUnknown: true });

const dictionaryFormSchema = Joi.object({
  ...commonSchema,
  dictionaryId: Joi.number().min(1),
  variableId: Joi.number().min(1),
}).messages({
  "number.base": "This field is required",
  "string.empty": "This field is required",
}).options({ stripUnknown: true });

export default function (values: Object): ErrorsMapper {
  const schema = values.source === ANTIFRAUD_VARIABLE_SOURCE_REQUEST ? valueFormSchema : dictionaryFormSchema;
  const { error: { details = {} } = {} } = schema.validate(values, { abortEarly: false });
  return Object.keys(details).reduce((acc: ErrorsMapper, key: string) => ({
    ...acc,
    [details[key].path.join(".")]: { message: details[key].message },
  }), {});
}
