// @flow
import React from "react";
import { Provider } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { Grid, Box } from "@mui/material";
import DocumentTitle from "react-document-title";
import Notification from "../../components/Notification";
import configureStore from "./store";
import FunnelTemplateReportContainer from "../../containers/FunnelTemplateReportContainer";
import FunnelsTemplateReport from "../../containers/FunnelsTemplateReport";

type Props = {
  classes: Object,
};

function FunnelTemplateReport(props: Props) {
  const { classes } = props;

  return (
    <Provider store={configureStore()}>
      <DocumentTitle title="Funnels Template">
        <Grid container spacing={0} className={classes.mainBackground}>
          <Box flex={1} m={3}>
            <FunnelTemplateReportContainer />
            <FunnelsTemplateReport />
          </Box>
        </Grid>
      </DocumentTitle>
      <Notification />
    </Provider>
  );
}

export default withStyles((theme) => ({
  mainBackground: {
    background: theme.palette.background.mainBg,
    minHeight: "calc(100% - 64px)",
  },
}))(FunnelTemplateReport);
