// @flow
import { put, takeEvery } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { OPEN_SPLIT_SAGA } from "../../helpers/constants";
import { setBackup } from "../../actions/backup";
import { openSplitModal, setCurrentSplit } from "../../actions/modals/actions";
import type { OpenSplitSagaAction } from "../../actions/splits/actions";

export function* openSplitSaga(action: OpenSplitSagaAction): Saga<void> {
  const { splitId } = action;

  const backup = [
    "splits",
    "funnels",
    "targetings",
    "filterings",
    "offers",
    "actionOptionsParams",
  ];

  yield put(setBackup(backup));

  yield put(setCurrentSplit(splitId));
  yield put(openSplitModal());
}

export default function* watchOpenSplit(): Saga<void> {
  yield takeEvery(OPEN_SPLIT_SAGA, openSplitSaga);
}
