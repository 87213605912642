// @flow
import Joi from "@hapi/joi";
import type { Map } from "immutable";
import type { JoiError, Errors } from "../../types/Base";
import type { Split } from "../../../../reducers/splits";
import type { BaseSimpleErrorsRule } from "../Base";
import {
  FREQUENCY_PERIOD_REQUIRED,
} from "../../messages";

export default class SplitsDistribution implements BaseSimpleErrorsRule {
  schema: Object;

  errors: Errors;

  errorKey: string;

  constructor() {
    this.errors = {};
    this.errorKey = "frequencyPeriod";

    this.schema = Joi
      .object()
      .keys({
        method: Joi.string().required(),
        period: Joi.when(
          "method", {
            is: "frequency",
            then: Joi.string().valid("24h", "48h", "72h", "96h", "120h"),
          }
        ),
      })
      .unknown(true)
      .error((errors) => this._onError(errors));
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach((item) => {
      switch (item.code) {
        case "any.only": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: FREQUENCY_PERIOD_REQUIRED },
          };
          break;
        }
        default:
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: item.message },
          };
          break;
      }
    });

    return errors;
  }

  getErrors(data: Map<Split>): Errors {
    this.schema.validate(data.toJS());
    return this.errors;
  }
}
