import {
  put,
  takeEvery,
} from "redux-saga/effects";
import {
  DELETE_FALLBACK_OFFER_SAGA,
} from "../../helpers/constants";
import { deleteServiceOffer } from "../../actions/offers";
import { deleteFallbackOffer } from "../../actions/fallbackOffers";

export function* deleteFallbackOfferSaga(action) {
  yield put(deleteServiceOffer(action.offerId));
  yield put(deleteFallbackOffer(action.offerId));
}

export default function* watchDeleteFallbackOffer() {
  yield takeEvery(DELETE_FALLBACK_OFFER_SAGA, deleteFallbackOfferSaga);
}
