/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  List,
} from "@mui/material";
import { SetupCard, AddData } from "@fas/ui-core";
import { toggleModal } from "@fas/ui-framework/lib/redux/actions/modals";
import { selectCampaignsOut, selectErrors } from "../../selectors/generalFilter";
import {
  deleteCampaignOut,
  equalShares,
  setWeight,
  setCampaignOut,
  unSetCampaignOut,
} from "../../actions/generalFilterInfo";
import type {
  CampaignType,
  ErrorOption,
} from "../../reducers/generalFilterInfo";
import GeneralFilterModal from "../GeneralFilterModal";
import { GENERAL_FILTER_CAMPAIGNS_OUT_MODAL } from "../../helpers/constants";
import CampaignsOutList from "./CampaignsOutList";

type UseCampaignsOut = {
  campaignsOut: CampaignType[],
  errors: ErrorOption,
  onDeleteCampaignOut: (index: number) => mixed,
  onEqualShares: () => mixed,
  onSetWeight: (index: number, value: string) => mixed,
  onChangeCampaignOut: (index: number, value: string) => mixed,
  onClearCampaignOut: (index: number) => mixed,
  onToggleModal: (boolean) => mixed,
};

const useCampaignsOut: () => UseCampaignsOut = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const campaignsOut: CampaignType[] = useSelector(selectCampaignsOut);
  const errors: ErrorOption = useSelector(selectErrors);

  const onDeleteCampaignOut: (number) => mixed = (index) => dispatch(deleteCampaignOut(index));
  const onEqualShares: () => mixed = () => dispatch(equalShares());
  const onSetWeight: (number, string) => mixed = (index, value) => dispatch(setWeight(index, value));
  const onChangeCampaignOut: (number, string) => mixed = (index, value) => dispatch(setCampaignOut(index, value));
  const onClearCampaignOut: (number) => mixed = (index) => dispatch(unSetCampaignOut(index));
  const onToggleModal: (boolean) => mixed = (isOpen) => dispatch(toggleModal(
    GENERAL_FILTER_CAMPAIGNS_OUT_MODAL,
    isOpen
  ));

  return {
    campaignsOut,
    errors,
    onDeleteCampaignOut,
    onEqualShares,
    onSetWeight,
    onChangeCampaignOut,
    onClearCampaignOut,
    onToggleModal,
  };
};

const CampaignsOut: StatelessFunctionalComponent<{}> = () => {
  const {
    errors,
    campaignsOut,
    onDeleteCampaignOut,
    onEqualShares,
    onSetWeight,
    onChangeCampaignOut,
    onClearCampaignOut,
    onToggleModal,
  }: UseCampaignsOut = useCampaignsOut();

  return (
    <>
      {campaignsOut.length === 0 && (
        <Box data-testid="campaign-out-box">
          <AddData
            title="You have no campaigns out yet."
            linkText="+ ADD NEW CAMPAIGN OUT"
            onClick={(): mixed => onToggleModal(true)}
            error={"tdsCampaignsOut" in errors}
          />
        </Box>
      )}
      {campaignsOut.length !== 0 && (
        <Box mt={3} data-testid="campaign-out-box">
          <SetupCard
            title="TDS Campaign out"
            showMainBtn={campaignsOut.length < 5}
            mainBtnText="ADD CAMPAIGN"
            showSecondaryBtn
            secondaryBtnText="Equal Shares"
            // $FlowFixMe
            onMainBtnClick={(): mixed => onToggleModal(true)}
            // $FlowFixMe
            onSecondaryBtnClick={onEqualShares}
          >
            <Box mt={2} width="100%">
              <List>
                <CampaignsOutList
                  {...{
                    campaignsOut,
                    errors,
                    onDeleteCampaignOut,
                    onSetWeight,
                    onChangeCampaignOut,
                    onClearCampaignOut,
                  }}
                />
              </List>
            </Box>
          </SetupCard>
          <GeneralFilterModal />
        </Box>
      )}
    </>
  );
};

export default CampaignsOut;
