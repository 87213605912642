/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Container,
  Typography,
  type Theme,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SetupCard, Loader } from "@fas/ui-core";
import { FixedSizeList } from "react-window";
import AutoSizer, { type AutoSizerProps } from "react-virtualized-auto-sizer";
import { SAVE_FORM_DATA_SAGA, GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import { removeAntifraudScenarioRule, changeAntifraudScenarioRuleStatus } from "../../actions/antifraudScenarioForm";
import { getAntifraudScenarioRules } from "../../selectors/antifraudScenarios";
import { useLoading, useActions } from "../../hooks";
import AntifraudScenarioRulesRow from "./AntifraudScenarioRulesRow";

type Props = {| setModalOpen: (boolean) => void |}

const AntifraudScenarioRules: StatelessFunctionalComponent<Props> = ({ setModalOpen }: Props) => {
  const theme: Theme = useTheme();
  const rules = useSelector(getAntifraudScenarioRules);
  const {
    removeAntifraudScenarioRule: onRemoveAntifraudScenarioRule,
    changeAntifraudScenarioRuleStatus: onChangeAntifraudScenarioRuleStatus,
  } = useActions({ removeAntifraudScenarioRule, changeAntifraudScenarioRuleStatus });
  const isFormloading: boolean = useLoading(SAVE_FORM_DATA_SAGA, GET_FORM_DATA_SAGA);

  const itemsHeight: number = rules.length > 8 ? 8 : rules.length;

  return (
    <Container maxWidth="md">
      <Loader loading={isFormloading}>
        <SetupCard
          title="Rules"
          showMainBtn
          mainBtnText="ADD RULES"
          data-testid="antifraud-scenario-open-modal"
          onMainBtnClick={() => {
            setModalOpen(true);
          }}
        >
          <Box width={1}>
            <Box
              mb={1}
              py={1}
              px={2}
              bgcolor="background.blueBackgroundLight"
            >
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">ID</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="subtitle1">NAME</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">SCORE</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box>
                    <Typography variant="subtitle1">ACTIVE</Typography>
                  </Box>
                </Grid>
                <Grid item xs={1} textAlign="right">
                  <Box>
                    <Typography variant="subtitle1">DELETE</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box height={theme.spacing(itemsHeight * 8)}>
              <AutoSizer>
                {({ width, height }: AutoSizerProps) => (
                  <FixedSizeList
                    height={height}
                    width={width}
                    itemCount={rules.length}
                    itemSize={62} // 8 * 8, theme.spacing(8) returns string "48px"
                    itemData={{ rules, onRemoveAntifraudScenarioRule, onChangeAntifraudScenarioRuleStatus }}
                  >
                    {AntifraudScenarioRulesRow}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </Box>
          </Box>
        </SetupCard>
      </Loader>
    </Container>
  );
};

export default AntifraudScenarioRules;
