// @flow
/* eslint-disable import/max-dependencies */
import {
  call,
  put,
  takeEvery,
  select,
  all,
} from "redux-saga/effects";
import type { Map } from "immutable";
import type { Saga, Effect } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { FETCH_TARGETING_GF_SAGA } from "../../helpers/constants";
import { changeLoadingStatus } from "../../actions/loading";
import { setTargetingRules, setTargetingValues } from "../../actions/dictionaries";
import Api from "../../services/generalFilterApiV2";
import CampaignApi from "../../services/smartlinkApi";
import { getAllTargetingRules } from "../../selectors/generalFilter/indexV2";

export function* fetchSaga(): Saga<void> {
  yield put(changeLoadingStatus("loadingTargetingRules", true));
  try {
    const targetingRulesList: * = yield call(Api.getTargetingRules);
    const rules: Map<string, string> = yield select(getAllTargetingRules);
    // $FlowFixMe
    const rulesJs: Array<string> = Object.values(rules.toJS());
    const valuesList: { [string]: Effect} = {};

    for (let i: number = 0; i < rulesJs.length; i++) {
      valuesList[rulesJs[i]] = call(CampaignApi.getValuesForTargeting, rulesJs[i]);
    }

    const apiValuesList: * = yield all(valuesList);

    const mappedApiValuesList: {
      [string]: Array<{ label: string, value: string, subValue?: Array<string> }>
    } = {};

    Object.keys(apiValuesList).forEach((key: string) => {
      mappedApiValuesList[key] = apiValuesList[key].data.data[0].values;
    });
    yield put(setTargetingValues({ data: mappedApiValuesList }));

    yield put(setTargetingRules(targetingRulesList.data.data));
  }
  catch (err) {
    yield put(changeLoadingStatus("loadingTargetingRules", false));
    yield put(addNotification({ message: parseResponse(err), severity: "error" }));
  }
  yield put(changeLoadingStatus("loadingTargetingRules", false));
}

export default function* watch(): Saga<void> {
  yield takeEvery(FETCH_TARGETING_GF_SAGA, fetchSaga);
}
