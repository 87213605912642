// @flow
import type { DictionaryParams } from "../hooks/useDictionary";

export function getDictionaryLoadingKey(params: DictionaryParams): string {
  return `${getDictionaryKey(params)}-loading`;
}

export function getDictionaryKey(params: DictionaryParams): string {
  return `${params.dictionary}-${params.type}`;
}
