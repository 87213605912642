// @flow
import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type Props = {
  count: number,
  disabled: boolean,
  onClick: () => void
};

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.borderColor}`,
    backgroundColor: theme.palette.background.blueBackgroundLight,
    borderRadius: theme.spacing(1),
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const FunnelsCounter = ({ count, disabled, onClick }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography>{`${count} Funnels`}</Typography>
      <IconButton
        disabled={disabled}
        onClick={onClick}
        data-testid="open-funnels-btn"
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default FunnelsCounter;
