// @flow
import { createSelector } from "reselect";
import type { RecordOf, List } from "immutable";
import type { OutputSelector } from "reselect";
import type { FilterInfo, CampaignType } from "../../reducers/generalFilterInfo";
import { type State } from "../../pages/AppStoreWrapper/store";

type FilterInfoState = { filterInfo: RecordOf<FilterInfo> };

export const getGeneralFilterInfo: (FilterInfoState) => {| filterInfo: RecordOf<FilterInfo> |} = (state) => ({
  filterInfo: state.filterInfo,
});

export const selectCampaignsIn: OutputSelector<FilterInfoState, mixed, CampaignType[]> = createSelector(
  (state: FilterInfoState): List<RecordOf<CampaignType>> => state.filterInfo.get("tdsCampaignsIn", []),
  (campaigns: List<RecordOf<CampaignType>>): CampaignType[] => campaigns.toJSON().map((campaign) => campaign.toObject())
);

export const selectCampaignsOut: OutputSelector<FilterInfoState, mixed, CampaignType[]> = createSelector(
  (state: FilterInfoState): List<RecordOf<CampaignType>> => state.filterInfo.get("tdsCampaignsOut", []),
  (campaigns: List<RecordOf<CampaignType>>): CampaignType[] => campaigns.toJSON().map((campaign) => campaign.toObject())
);

export const selectErrors = (state: State) => state.errors.toJS();
