// @flow
import type { DropdownItem } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import {
  GET_ANTIFRAUD_TRIGGER_SAGA,
  SET_ANTIFRAUD_TRIGGER,
  SET_ANTIFRAUD_TRIGGER_FORM_FIELD,
  SAVE_ANTIFRAUD_TRIGGER_SAGA,
  GET_ANTIFRAUD_CONDITIONS_LIST_SAGA,
  GET_ANTIFRAUD_CONDITIONS_VALUES_SAGA,
  ADD_ANTIFRAUD_CONDITION,
  ADD_ANTIFRAUD_CONDITION_GROUP,
  CHANGE_ANTIFRAUD_CONDITION_GROUP_OPERATOR,
  DELETE_ANTIFRAUD_CONDITION_RULE,
  DELETE_ANTIFRAUD_CONDITION_GROUP,
  CHANGE_ANTIFRAUD_CONDITION_RULE,
  CHANGE_ANTIFRAUD_CONDITION_OPERATOR,
  CHANGE_ANTIFRAUD_CONDITION_VALUE,
  CHANGE_ANTIFRAUD_CONDITION_VALUE_TYPE,
  CHANGE_ANTIFRAUD_CONDITION_LOCATION,
  SET_CONDITION_LISTS,
} from "../../helpers/constants";
import type {
  Trigger,
  RuleTriggerQb,
  TriggerValueType,
} from "../../selectors/antifraudTrigger/types";

export type GetAntifraudTriggerSaga = {
  type: typeof GET_ANTIFRAUD_TRIGGER_SAGA,
  id: string,
}

export type GetAntifraudConditionsListSaga = {
  type: typeof GET_ANTIFRAUD_CONDITIONS_LIST_SAGA,
}

export type GetAntifraudConditionsValuesSaga = {
  type: typeof GET_ANTIFRAUD_CONDITIONS_VALUES_SAGA,
  condition: string,
}

export type SetAntifraudTrigger = {|
  type: typeof SET_ANTIFRAUD_TRIGGER,
  payload: Trigger
|};

export type SetAntifraudTriggerFormField = {|
  type: typeof SET_ANTIFRAUD_TRIGGER_FORM_FIELD,
  payload: $Shape<Trigger>,
|};

export type SaveAntifraudTriggerSaga = {|
  type: typeof SAVE_ANTIFRAUD_TRIGGER_SAGA,
|};

export type AddAntifraudCondition = {|
  type: typeof ADD_ANTIFRAUD_CONDITION,
  groupId: string,
|}

export type AddAntifraudConditionGroup = {|
  type: typeof ADD_ANTIFRAUD_CONDITION_GROUP,
  groupId: string,
|}

export type ChangeAntifraudConditionGroupOperator = {|
  type: typeof CHANGE_ANTIFRAUD_CONDITION_GROUP_OPERATOR,
  id: string,
  value: "AND" | "OR",
|}

export type DeleteAntifraudConditionRule = {|
  type: typeof DELETE_ANTIFRAUD_CONDITION_RULE,
  id: string,
|}

export type DeleteAntifraudConditionGroup = {|
  type: typeof DELETE_ANTIFRAUD_CONDITION_GROUP,
  id: string,
|}

export type ChangeAntifraudConditionRule = {|
  type: typeof CHANGE_ANTIFRAUD_CONDITION_RULE,
  id: string,
  rule: RuleTriggerQb,
|}

export type ChangeAntifraudConditionOperator = {|
  type: typeof CHANGE_ANTIFRAUD_CONDITION_OPERATOR,
  id: string,
  operator: string,
|}

export type ChangeAntifraudConditionValue = {|
  type: typeof CHANGE_ANTIFRAUD_CONDITION_VALUE,
  id: string,
  value: string,
|}

export type ChangeAntifraudConditionLocation = {|
  type: typeof CHANGE_ANTIFRAUD_CONDITION_LOCATION,
  id: string,
  location: "data" | "headers" | "cookies",
|}

export type ChangeAntifraudConditionValueType = {|
  type: typeof CHANGE_ANTIFRAUD_CONDITION_VALUE_TYPE,
  payload: {
    id: string,
    type: TriggerValueType,
  },
|}

export type SetConditionLists = {|
  type: typeof SET_CONDITION_LISTS,
  payload: {
    triggerRules: Array<RuleTriggerQb>,
    triggerValues: Array<DropdownItem<string>>,
  }
|}

export type Actions= GetAntifraudTriggerSaga
  | SetAntifraudTrigger
  | SetAntifraudTriggerFormField
  | SaveAntifraudTriggerSaga
  | GetAntifraudConditionsListSaga
  | GetAntifraudConditionsValuesSaga
  | AddAntifraudCondition
  | AddAntifraudConditionGroup
  | ChangeAntifraudConditionGroupOperator
  | DeleteAntifraudConditionRule
  | DeleteAntifraudConditionGroup
  | ChangeAntifraudConditionRule
  | ChangeAntifraudConditionOperator
  | ChangeAntifraudConditionValue
  | ChangeAntifraudConditionValueType
  | ChangeAntifraudConditionLocation
  | SetConditionLists;

export const getAntifraudTriggerSaga: (id: string) => GetAntifraudTriggerSaga = (id) => ({
  type: GET_ANTIFRAUD_TRIGGER_SAGA,
  id,
});

export const setAntifraudTrigger: (payload: Trigger) => SetAntifraudTrigger = (payload) => ({
  type: SET_ANTIFRAUD_TRIGGER,
  payload,
});

export const setAntifraudTriggerFormField: (payload: $Shape<Trigger>) => SetAntifraudTriggerFormField = (payload) => ({
  type: SET_ANTIFRAUD_TRIGGER_FORM_FIELD,
  payload,
});

export const saveAntifraudTriggerSaga: () => SaveAntifraudTriggerSaga = () => ({
  type: SAVE_ANTIFRAUD_TRIGGER_SAGA,
});

export const getAntifraudConditionsListSaga: () => GetAntifraudConditionsListSaga = () => ({
  type: GET_ANTIFRAUD_CONDITIONS_LIST_SAGA,
});

export const getAntifraudConditionsValuesSaga: (
  condition: string
) => GetAntifraudConditionsValuesSaga = (
  condition
) => ({
  type: GET_ANTIFRAUD_CONDITIONS_VALUES_SAGA,
  condition,
});

export const addAntifraudCondition: (groupId: string) => AddAntifraudCondition = (groupId) => ({
  type: ADD_ANTIFRAUD_CONDITION,
  groupId,
});

export const addAntifraudConditionGroup: (groupId: string) => AddAntifraudConditionGroup = (groupId) => ({
  type: ADD_ANTIFRAUD_CONDITION_GROUP,
  groupId,
});

export const changeAntifraudConditionGroupOperator: (id: string, value: "AND" | "OR") => ChangeAntifraudConditionGroupOperator = (id, value) => ({
  type: CHANGE_ANTIFRAUD_CONDITION_GROUP_OPERATOR,
  id,
  value,
});

export const deleteAntifraudConditionRule: (id: string) => DeleteAntifraudConditionRule = (id) => ({
  type: DELETE_ANTIFRAUD_CONDITION_RULE,
  id,
});

export const deleteAntifraudConditionGroup: (id: string) => DeleteAntifraudConditionGroup = (id) => ({
  type: DELETE_ANTIFRAUD_CONDITION_GROUP,
  id,
});

export const changeAntifraudConditionRule: (
  id: string,
  rule: RuleTriggerQb,
) => ChangeAntifraudConditionRule = (id, rule) => ({
  type: CHANGE_ANTIFRAUD_CONDITION_RULE,
  id,
  rule,
});

export const changeAntifraudConditionOperator: (
  id: string,
  operator: string,
) => ChangeAntifraudConditionOperator = (id, operator) => ({
  type: CHANGE_ANTIFRAUD_CONDITION_OPERATOR,
  id,
  operator,
});

export const changeAntifraudConditionValue: (
  id: string,
  value: string,
) => ChangeAntifraudConditionValue = (id, value) => ({
  type: CHANGE_ANTIFRAUD_CONDITION_VALUE,
  id,
  value,
});

export const changeAntifraudConditionLocation: (
  id: string,
  location: "data" | "headers" | "cookies",
) => ChangeAntifraudConditionLocation = (id, location) => ({
  type: CHANGE_ANTIFRAUD_CONDITION_LOCATION,
  id,
  location,
});

export const setConditionLists: ({
  triggerRules: Array<RuleTriggerQb>,
  triggerValues: Array<DropdownItem<string>>,
}) => SetConditionLists = (payload) => ({
  type: SET_CONDITION_LISTS,
  payload,
});

export const changeAntifraudConditionValueType: (
  id: string,
  type: TriggerValueType,
) => ChangeAntifraudConditionValueType = (id, type) => ({
  type: CHANGE_ANTIFRAUD_CONDITION_VALUE_TYPE,
  payload: { id, type },
});
