// @flow
import {
  Map, OrderedMap, List, fromJS,
} from "immutable";
import {
  ADD_SPLIT,
  DELETE_SPLIT,
  SPLIT_IS_ACTIVE_CHANGE,
  MOVE_SPLIT_NAMES,
  DELETE_FUNNEL,
  ADD_FUNNEL,
  SET_SPLIT_WEIGHT,
  SET_SPLIT_FREQUENCY,
  SPLIT_WEIGHTS_EQUAL_SHARES,
  CHANGE_FUNNEL_ORDER,
  SET_SPLIT_FUNNELTEMPLATE,
} from "../../helpers/constants";

import { equalShares } from "../../services/campaign";
import type { SplitActions } from "../../actions/splits/actions";
import type { FunnelActions } from "../../actions/funnels/actions";
import type { Campaign } from "../campaignInfo/reducer";

export type Split = {
  name: string,
  isActive: boolean,
  funnels: Array<string>,
  weight: number,
  frequency: Array<number>,
  funnelTemplate: string,
};
export type SplitsState = {
  byId: OrderedMap<{
    [key: string]: Map<Split>
  }>,
  allIds: List<string>
};

export type State = SplitsState;

export const initialState: Map<State> = Map({
  byId: OrderedMap({
    "1": Map({
      name: "a",
      isActive: true,
      funnels: List([]),
      weight: 100,
      frequency: List([]),
      funnelTemplate: "",
    }),
  }),
  allIds: List(["1"]),
});

export function initSplitsState(campaign: Campaign) {
  let state;
  const { splits } = campaign;
  if (splits) {
    state = fromJS(splits);// , (key, value) => (key === "byId" ? value.toOrderedMap() : value));
  }
  else {
    state = initialState;
  }
  return state;
}

type Action = SplitActions | FunnelActions;

export default (state: Map<State> = initialState, action: Action) => {
  switch (action.type) {
    case ADD_SPLIT: {
      const {
        id, name, isActive, weight, frequency, funnelTemplate,
      } = action;

      return state.withMutations((newState) => {
        newState.setIn(
          ["byId", id],
          Map({
            name,
            isActive,
            funnels: List([]),
            frequency: List(frequency),
            weight,
            funnelTemplate: funnelTemplate || "",
          })
        );
        newState.updateIn(["allIds"], (allIds) => allIds.push(id));
      });
    }
    case CHANGE_FUNNEL_ORDER: {
      const {
        splitId,
        funnels: orders,
      } = action;
      return state.updateIn(["byId", splitId, "funnels"],
        (funnels) => funnels.sort((a, b) => orders.indexOf(a) - orders.indexOf(b)));
    }
    case DELETE_SPLIT: {
      const { id } = action;

      return state.withMutations((newState) => {
        newState.deleteIn(["byId", id]);
        newState.deleteIn([
          "allIds",
          newState.get("allIds").findIndex((el) => el === id),
        ]);
      });
    }
    case SPLIT_IS_ACTIVE_CHANGE: {
      const { id } = action;
      return state.updateIn(["byId", id], (split) => split.set("isActive", !split.get("isActive")));
    }
    case MOVE_SPLIT_NAMES: {
      const { mapper } = action;

      return state.withMutations((newState) => {
        Object.keys(mapper).forEach((id) => newState.setIn(["byId", id, "name"], mapper[id]));
      });
    }
    case DELETE_FUNNEL: {
      const { splitId, funnelId } = action;

      return state.updateIn(["byId", splitId], (split) => split.deleteIn([
        "funnels",
        split.get("funnels").findIndex((el) => el === funnelId),
      ]));
    }
    case ADD_FUNNEL: {
      const { splitId, funnelId } = action;

      return state.updateIn(["byId", splitId, "funnels"], (funnels) => funnels.push(funnelId));
    }
    case SET_SPLIT_WEIGHT: {
      const { splitId, weight } = action;
      return state.setIn(["byId", splitId, "weight"], weight);
    }
    case SET_SPLIT_FREQUENCY: {
      const { splitId, frequency } = action;
      return state.setIn(["byId", splitId, "frequency"], frequency);
    }
    case SPLIT_WEIGHTS_EQUAL_SHARES: {
      const activeSplits = state.get("byId").filter((x) => x.get("isActive")).keySeq().toArray();
      return state.update("byId", (splits) => equalShares(activeSplits, splits));
    }
    case SET_SPLIT_FUNNELTEMPLATE: {
      const { funnelTemplateId, splitId } = action;
      return state.setIn(["byId", splitId, "funnelTemplate"], funnelTemplateId);
    }
    default:
      return state;
  }
};
