// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import HistoryReplaceForm from "../../components/HistoryReplaceForm";

function HistoryReplacePage() {
  return (
    <DocumentTitle title="Replace LP History - View">
      <HistoryReplaceForm />
    </DocumentTitle>
  );
}

export default HistoryReplacePage;
