// @flow

import {
  SET_TARGETING_VALUES,
  SET_TARGETING_VALUE,
  SET_TARGETING_RULES,
  START_FETCH_TARGETINGS_RULES,
  SUCCESS_FETCH_TARGETINGS_RULES,
  ERROR_START_FETCH_TARGETINGS_RULES,
  START_FETCH_TARGETINGS_VALUES,
  SUCCESS_FETCH_TARGETINGS_VALUES,
  SET_FUNNELTEMPLATE,
  SET_FUNNELTEMPLATE_LIST,
  SET_CAMPAIGN_JSON,
  FETCH_CAMPAIGN_JSON_SAGA,
  ADD_DICTIONARY,
  ADD_DICTIONARY_OPTIONS,
  SET_HISTORY_REPLACE_LISTS,
} from "../../helpers/constants";

export type FunnelTemplate = {
  id: string,
  name: string,
  type: string,
  funnels: number,
  campaigns: Array<string>,
  createdBy: string,
  createdAt: string,
  updatedBy: string,
  updatedAt: string,
};

export type SetTargetingValuesAction = {|
  type: typeof SET_TARGETING_VALUES,
  data: {
    [key: string]: Array<{ label: string, value: string, subValue?: Array<string> }>
  },
|}

type SetTargetingValueAction = {|
  type: typeof SET_TARGETING_VALUE,
  data: Array<{ label: string, value: string }>,
  key: string,
|}

export type SetTargetingRulesAction = {|
  type: typeof SET_TARGETING_RULES,
  data: Array<any>,
|}

export type SetFunnelTemplateAction = {|
  type: typeof SET_FUNNELTEMPLATE,
  template: FunnelTemplate,
|}

export type SetFunnelTemplatesListAction = {|
  type: typeof SET_FUNNELTEMPLATE_LIST,
  data: Array<FunnelTemplate>,
|}

export type SetCampaignJsonAction = {|
  type: typeof SET_CAMPAIGN_JSON,
  jsonCampaign: Object,
|}

export type FetchCampaignJsonSagaAction = {|
  type: typeof FETCH_CAMPAIGN_JSON_SAGA,
  id: string,
|}

export type AddDictionaryAction<T> = {|
  type: typeof ADD_DICTIONARY,
  key: string,
  options: T[],
|}

export type AddDictionaryOptionsAction<T> = {|
  type: typeof ADD_DICTIONARY_OPTIONS,
  key: string,
  options: T
|}

export type AddHistoryReplaceDictionaries = {|
  type: typeof SET_HISTORY_REPLACE_LISTS,
  data: {
    [key: string]: Array<string>
  },
|}

export type Actions<T> = SetTargetingValuesAction
  | SetTargetingValueAction
  | SetTargetingRulesAction
  | SetFunnelTemplateAction
  | SetFunnelTemplatesListAction
  | SetCampaignJsonAction
  | FetchCampaignJsonSagaAction
  | AddHistoryReplaceDictionaries
  | AddDictionaryAction<T>
  | AddDictionaryOptionsAction<T>

export const setTargetingValues = ({
  data,
}: {
  data: {
    [key: string]: Array<{ label: string, value: string, subValue?: Array<string> }>
  },
}): SetTargetingValuesAction => ({
  type: SET_TARGETING_VALUES,
  data,
});

export const setTargetingValue = ({
  data,
  key,
}: {
  data: Array<{ label: string, value: string }>,
  key: string,
}): SetTargetingValueAction => ({
  type: SET_TARGETING_VALUE,
  data,
  key,
});

export const setTargetingRules = (data: Array<any>): SetTargetingRulesAction => ({
  type: SET_TARGETING_RULES,
  data,
});

export const startFetchTargetingRules = () => ({
  type: START_FETCH_TARGETINGS_RULES,
});

export const errorStartFetchTargetingRules = () => ({
  type: ERROR_START_FETCH_TARGETINGS_RULES,
});

export const successFetchTargetingRules = () => ({
  type: SUCCESS_FETCH_TARGETINGS_RULES,
});

export const startFetchTargetingValues = () => ({
  type: START_FETCH_TARGETINGS_VALUES,
});

export const successFetchTargetingValues = () => ({
  type: SUCCESS_FETCH_TARGETINGS_VALUES,
});

export const setFunnelTemplate = (template: FunnelTemplate): SetFunnelTemplateAction => ({
  type: SET_FUNNELTEMPLATE,
  template,
});

export const setFunnelTemplatesList = (data: Array<FunnelTemplate>): SetFunnelTemplatesListAction => ({
  type: SET_FUNNELTEMPLATE_LIST,
  data,
});

export const addHistoryReplaceDictionaries = (data: {[key: string]: Array<string>}): AddHistoryReplaceDictionaries => ({
  type: SET_HISTORY_REPLACE_LISTS,
  data,
});

export const fetchCampaignJsonSaga = (id: string): FetchCampaignJsonSagaAction => ({
  type: FETCH_CAMPAIGN_JSON_SAGA,
  id,
});

export const setCampaignJson = (jsonCampaign: Object): SetCampaignJsonAction => ({
  type: SET_CAMPAIGN_JSON,
  jsonCampaign,
});

export function addDictionary<T>(key: string, options: T[]): AddDictionaryAction<T> {
  return {
    type: ADD_DICTIONARY,
    key,
    options,
  };
}

export function addDictionaryOptions<T>(key: string, options: T): AddDictionaryOptionsAction<T> {
  return {
    type: ADD_DICTIONARY_OPTIONS,
    key,
    options,
  };
}
