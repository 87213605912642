/* eslint-disable import/max-dependencies */
// @flow
import type { Saga } from "redux-saga";
import {
  put,
  call,
  takeEvery,
} from "redux-saga/effects";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { addDropdown } from "@fas/ui-framework/lib/redux/actions/dropdowns";
import type { Option } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { getDictionaryKey, getDictionaryLoadingKey } from "../../helpers/generators";
import { GET_DICTIONARY_SAGA } from "../../helpers/constants";
import type { GetDictionarySaga } from "../../actions/dropdowns";
import { getDropdownList } from "../../services/dropdownsApi";

export function* makeFetch<T>(action: GetDictionarySaga): Saga<void> {
  const { params }: GetDictionarySaga = action;
  try {
    yield put(setLoading(getDictionaryLoadingKey(params), true));

    const { data }: { data: Array<Option<T>> } = yield call(getDropdownList, params);

    yield put(addDropdown(getDictionaryKey(params), data));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(getDictionaryLoadingKey(params), false));
  }
}

export default function* watchGetDropdownListSaga(): Saga<void> {
  yield takeEvery(GET_DICTIONARY_SAGA, (makeFetch: Function));
}
