// @flow
import React, { Fragment } from "react";
import type { Element } from "react";
import {
  Grid,
  TextField,
  Autocomplete,
  type AutocompleteRenderInputParams,
} from "@mui/material";
import { Multiselect } from "@fas/ui-core";
import type { MultiselectTypes, Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import SelectBasicCampaignUrl from "../SelectBasicCampaignUrl";
import type {
  Item,
  ValueOption,
  Offer,
  OfferItem,
  RuleOption,
} from "./types/FilteringList.types";

type Props = {
  isDisabledChangeOffer: boolean,
  uniqueElementId: string,
  item: Item,
  rulesList: Array<RuleOption>,
  valuesList: Array<ValueOption>,
  onChangeRule: (value: string) => void,
  onChangeValues: (selectedValues: Array<ValueOption | string>) => void,
  onChangeOffer: (offer: Offer) => void,
  getOptionRuleDisabled: (option: RuleOption) => boolean,
};

const isRuleWithValue: (rule: RuleOption) => boolean = (rule = {}): boolean => rule.type !== "WITHOUT_VALUE";
const groupOrder: Array<string> = ["With value", "Without value", "Query"];
const getRuleGroup: (rule: RuleOption) => string = (rule = {}) => {
  if (rule.group === "query") return "Query";
  return isRuleWithValue(rule) ? "With value" : "Without value";
};

export const parseOffer: (
  offerItem: OfferItem
) => Offer = (
  offerItem: OfferItem
): Offer => {
  const {
    id: campaignId,
    params: { path, incomeUrl },
  }: OfferItem = offerItem;

  return {
    id: campaignId,
    url: `${path}?tdsId=${campaignId}_r&tds_campaign=${campaignId}&${incomeUrl}`,
  };
};

const getSelectedRule: (
  rulesList: Array<RuleOption>,
  name: string
) => RuleOption = (
  rulesList: Array<RuleOption>,
  name: string
): RuleOption => rulesList.find(({ name: { value } }: RuleOption): boolean => value === name) || {};

const getTypeSelect: (
  rule: RuleOption
) => MultiselectTypes = (
  rule: RuleOption
): MultiselectTypes => {
  const map: {[key: string]: MultiselectTypes} = {
    "MULTISELECT_CUSTOM": "manualMultiple",
  };
  return map[rule.type] || "simpleMultiple";
};

const FilteringItem: (Props) => Element<typeof Fragment> = ({
  uniqueElementId,
  item,
  rulesList,
  valuesList,
  onChangeRule,
  onChangeValues,
  onChangeOffer,
  getOptionRuleDisabled,
  isDisabledChangeOffer,
}: Props): Element<typeof Fragment> => {
  const {
    id,
    url,
  }: Offer = item.offer;

  const handleChangeOffer: (offerItem: OfferItem) => void = (offerItem: OfferItem) => {
    onChangeOffer(parseOffer(offerItem));
  };

  const selectedRule: RuleOption = getSelectedRule(rulesList, item.name);
  const allowedAddValues: boolean = !(selectedRule.filteringMaxValueCount !== undefined
    && selectedRule.filteringMaxValueCount <= item.value.length);

  return (
    <>
      <Grid item xs={2} data-testid="filtering-rule-select">
        <Autocomplete
          id={`${uniqueElementId}-name`}
          data-testid={`${uniqueElementId}-name`}
          options={rulesList.sort((a: RuleOption, b: RuleOption): number => (
            groupOrder.indexOf(getRuleGroup(a)) - groupOrder.indexOf(getRuleGroup(b))))}
          multiple={false}
          value={selectedRule}
          clearIcon={null}
          groupBy={(rule: RuleOption): string => getRuleGroup(rule)}
          getOptionLabel={({ name: { label = "" } = {} }:RuleOption = {}): string => label}
          isOptionEqualToValue={({ name: { value } }: RuleOption): boolean => value === item.name}
          getOptionDisabled={getOptionRuleDisabled}
          renderInput={(params: AutocompleteRenderInputParams): Element<typeof TextField> => (
            <TextField
              {...params}
              variant="outlined"
              placeholder=""
              fullWidth
              size="small"
            />
          )}
          onChange={(e: Event, rule: RuleOption): void => rule && onChangeRule(rule.name.value)}
        />
      </Grid>
      <Grid item xs={2} data-testid="value-rule-select">
        {isRuleWithValue(selectedRule) && (
          <Multiselect
            dataTestId={`${uniqueElementId}-value`}
            type={getTypeSelect(selectedRule)}
            options={allowedAddValues
              ? valuesList.map(({ label, value }: ValueOption): Option => ({ label: String(label), value }))
              : []}
            selectedOptions={item.value.map((x: string): Option => {
              const valueFromList: ValueOption | void = valuesList.find((k: ValueOption): boolean => k.value === x);

              return ({ label: (valueFromList && valueFromList.label) || x, value: x });
            })}
            onAddValue={(option: ValueOption) => {
              if (allowedAddValues) {
                onChangeValues([...item.value, option]);
              }
            }}
            onRemoveValue={(value: string) => {
              const index: number = item.value.findIndex((option: ValueOption | string): boolean => (
                option.value === value || option === value));
              onChangeValues([
                ...item.value.slice(0, index),
                ...item.value.slice(index + 1),
              ]);
            }}
          />
        )}
      </Grid>
      <Grid item xs={6} data-testid="offer-rule-select">
        <SelectBasicCampaignUrl
          uniqueElementId={`${uniqueElementId}-offer`}
          campaignId={id}
          url={url}
          changeUrl={handleChangeOffer}
          isDisabled={isDisabledChangeOffer}
        />
      </Grid>
    </>
  );
};

export default React.memo<Props>(FilteringItem);
