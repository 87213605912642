// @flow
import Joi from "@hapi/joi";
import BaseTargeting from "./Base";

class TdsFCJ extends BaseTargeting {
  getJoiSchemaValidation() {
    return Joi.object({
      name: Joi.string(),
      operator: Joi.string(),
      type: Joi.string(),
      parentId: Joi.number(),
      value: Joi.array().min(1).items(
        Joi.string().pattern(/^[0-9]+$/, "must be a number")
      ),
    });
  }
}

export default TdsFCJ;
