// @flow
import Joi from "@hapi/joi";
import BaseTargeting from "./Base";

class FilteringWithoutValue extends BaseTargeting {
  getJoiSchemaValidation() {
    return super.getJoiSchemaValidation().keys({
      value: Joi.array().length(0),
    });
  }
}

export default FilteringWithoutValue;
