// @flow
import React, {
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Table, SelectSearchComponent, IsActiveLabel } from "@fas/ui-core";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { SMARTLINK_AGGREGATORS_TABLE } from "../../helpers/constants";
import {
  getSmartlinkAggregatorsListSaga,
  type GetSmartlinkAggregatorsListSaga,
  changeSmartlinkAggregatorActive,
} from "../../actions/smartlinkAggregators";
import SaTableActions from "./SaTableActions";
import type { Props } from "./SmartlinkAggregatorsList.types";
import type { SmartlinkRedirect } from "../../selectors/smartlinkAggregators";

const SmartlinkAggregatorsList: StatelessFunctionalComponent<Props> = () => {
  const tableProps: TableProps = useTable(SMARTLINK_AGGREGATORS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();
  const navigate = useNavigate();

  const onCreate: () => void = () => {
    navigate("/smartlinkAggregators");
  };

  const onGetSmartlinkAggregatorsListSaga: () => GetSmartlinkAggregatorsListSaga = () => (
    dispatch(getSmartlinkAggregatorsListSaga()));

  const columns: Array<Column<SmartlinkRedirect>> = [
    {
      field: "id",
      label: "Id",
      searchable: true,
      sortable: true,
    },
    {
      field: "smartlinkId",
      label: "Smartlink Id",
      searchable: true,
      sortable: true,
    },
    {
      field: "name",
      label: "Name",
      searchable: true,
      sortable: true,
    },
    {
      field: "type",
      label: "Type",
      searchable: true,
      sortable: true,
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { title: "All", value: "" },
            { title: "Internal", value: "internal" },
            { title: "External", value: "external" },
          ]}
          label="type"
        />
      ),
    },
    {
      field: "placeholders",
      label: "Placeholders",
      searchable: true,
      sortable: true,
    },
    {
      field: "isActive",
      label: "Is Active",
      searchable: true,
      sortable: true,
      render: IsActiveLabel,
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { title: "All", value: "" },
            { title: "Active", value: "1" },
            { title: "Inactive", value: "0" },
          ]}
          label="isActive"
        />
      ),
    },
    {
      field: "createdAt",
      label: "Created At",
      sortable: true,
    },
    {
      field: "updatedAt",
      label: "Updated At",
      sortable: true,
    },
    {
      field: "createdBy",
      label: "Created By",
    },
    {
      field: "updatedBy",
      label: "Updated By",
    },
  ];

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        title="Smartlink Aggregators"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onCreate={onCreate}
        onLoad={onGetSmartlinkAggregatorsListSaga}
        Actions={(
          <SaTableActions
            onChangeIsActive={changeSmartlinkAggregatorActive}
          />
        )}
      />
    </Box>
  );
};

export default SmartlinkAggregatorsList;
