/* eslint-disable import/max-dependencies */
// @flow
import { AddData, SetupCard } from "@fas/ui-core";
import React, { type StatelessFunctionalComponent, type Element, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { type ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import { closeOffersModal, openOffersModal } from "../../actions/modals";
import type { Offer } from "../../reducers/offers";
import {
  selectErrors,
  makeSelectOffersByIds,
  makeSelectActiveOffersIdsByIds,
  makeSelectMapOffersIdsToCollectionIds,
} from "../../selectors/smartlinkV2";
import { type StoreWithOffers } from "../../selectors/smartlinkV2/types";
import OfferItem from "../../components/OfferItem";
import {
  changeOfferWeight,
  deleteOffer,
  equalSharesOffers,
  saveOffersSaga,
  toggleOfferActiveStatus,
  changeOfferUrl,
} from "../../actions/offers";
import { OFFERS_TABLE } from "../../helpers/constants";
import OffersModal from "../OffersModal";
import { useClearTable, type ClearTableHook } from "../../hooks";

type Props = {
  funnelId: string,
  offersIds: string[],
};

type OffersProps = {
  offers: Offer[],
  offersIdsToCollectionIds: { [string]: string },
  activeOffersIds: string[],
  errors: ErrorsMapper,
  onChangeOfferWeight: (offerId: string, weight: number) => mixed,
  onDeleteOffer: (funnelId: string, offerId: string) => mixed,
  onEqualSharesOffers: (offersIds: Array<string>) => mixed,
  onOpenOffers: () => mixed,
  onCloseOffers: () => mixed,
  onSaveOffers: (funnelId: string, offers: Offer[]) => mixed,
  onIsActiveChange: (offerId: string, isActive: boolean) => mixed,
  onChangeUrl: (offerId: string, url: string) => mixed,
};

const useOffersState: (string[]) => OffersProps = (offersIds) => {
  const dispatch: <A>(A) => A = useDispatch();
  const { clearTableData, clearTableFilters }: ClearTableHook = useClearTable(OFFERS_TABLE);

  const selectOffersByIds = useMemo(makeSelectOffersByIds, []);
  const selectActiveOffersIdsByIds = useMemo(makeSelectActiveOffersIdsByIds, []);
  const selectMapOffersIdsToCollectionIds = useMemo(makeSelectMapOffersIdsToCollectionIds, []);

  const errors: ErrorsMapper = useSelector(selectErrors);
  const offers: Offer[] = useSelector((state: StoreWithOffers): Offer[] => selectOffersByIds(state, offersIds));
  const activeOffersIds: string[] = useSelector(
    (state: StoreWithOffers): string[] => selectActiveOffersIdsByIds(state, offersIds)
  );
  const offersIdsToCollectionIds: { [string]: string, } = useSelector(
    (state: StoreWithOffers): { [string]: string, } => selectMapOffersIdsToCollectionIds(state, offersIds)
  );

  const onDeleteOffer: (string, string) => mixed = (funnelId, offerId) => dispatch(deleteOffer(funnelId, offerId));
  const onEqualSharesOffers: (string[]) => mixed = (ids) => dispatch(equalSharesOffers(ids));
  const onSaveOffers: (string, Offer[]) => mixed = (funnelId, list) => dispatch(saveOffersSaga(funnelId, list));
  const onChangeUrl: (string, string) => mixed = (offerId, url) => dispatch(changeOfferUrl(offerId, url));
  const onChangeOfferWeight: (string, number) => mixed = (
    offerId,
    weight
  ) => dispatch(changeOfferWeight(offerId, weight));
  const onIsActiveChange: (string, boolean) => mixed = (
    offerId,
    isActive
  ) => dispatch(toggleOfferActiveStatus(offerId, isActive));
  const onOpenOffers: () => void = () => {
    dispatch(setTableSelection(OFFERS_TABLE, offers));
    clearTableFilters();
    dispatch(openOffersModal());
  };
  const onCloseOffers: () => void = () => {
    dispatch(closeOffersModal());
    clearTableData();
  };

  return {
    offers,
    activeOffersIds,
    offersIdsToCollectionIds,
    errors,
    onChangeOfferWeight,
    onDeleteOffer,
    onEqualSharesOffers,
    onOpenOffers,
    onCloseOffers,
    onSaveOffers,
    onIsActiveChange,
    onChangeUrl,
  };
};

const getIsFieldValid: (ErrorsMapper, string) => boolean = (errors, field) => !Object.keys(errors).includes(field);

const useStyles = makeStyles((theme) => ({
  root: {
    border: (props) => (getIsFieldValid(props.errors, "offers")
      ? ""
      : `1px solid ${theme.palette.error.main}`),
  },
}));

const Offers: StatelessFunctionalComponent<Props> = ({ funnelId, offersIds }) => {
  const { id: campaingId } = useParams();

  const {
    errors,
    offers,
    offersIdsToCollectionIds,
    activeOffersIds,
    onChangeOfferWeight,
    onDeleteOffer,
    onEqualSharesOffers,
    onOpenOffers,
    onCloseOffers,
    onSaveOffers,
    onIsActiveChange,
    onChangeUrl,
  }: OffersProps = useOffersState(offersIds);

  const classes = useStyles({ errors });

  const isOffersValid: boolean = getIsFieldValid(errors, "offers");
  const isOffersWeightsValid: boolean = getIsFieldValid(errors, "offersWeights");

  return (
    <>
      <OffersModal
        title="Offers Setup"
        limit={10}
        onSave={(newOffers: Offer[]): mixed => onSaveOffers(funnelId, newOffers)}
        onCancel={onCloseOffers}
      />
      {offers.length === 0 && (
        <Box m={0} data-testid="funnel-offer">
          <AddData
            linkText="+ add new offer"
            title="You have no offers yet."
            error={!isOffersValid}
            onClick={onOpenOffers}
          />
        </Box>
      )}
      {offers.length > 0 && (
        <Box m={0} data-testid="funnel-offer">
          <SetupCard
            title="Offers"
            showMainBtn
            mainBtnText="add offer"
            // $FlowFixMe @TODO fix in core
            onMainBtnClick={onOpenOffers}
            showSecondaryBtn
            secondaryBtnText="equal shares"
            // $FlowFixMe @TODO fix in core
            onSecondaryBtnClick={(): mixed => onEqualSharesOffers(activeOffersIds)}
          >
            <Box className={classes.root} width="inherit" padding={1}>
              {offers.map(({
                id,
                url,
                name,
                weight,
                isActive,
              }: Offer): Element<OfferItem> => {
                const activationChangeDisabled: boolean = isActive && activeOffersIds.length === 1 && !campaingId;
                const disableDelete: boolean = isActive && activeOffersIds.length === 1 && !campaingId;
                const collectionId: string = offersIdsToCollectionIds[id];

                return (
                  <OfferItem
                    key={collectionId}
                    idValue={id}
                    urlTitle={name}
                    urlValue={url}
                    weightValue={weight}
                    weightError={!isOffersWeightsValid}
                    offerError={!isOffersValid}
                    onChangeWeight={(e): mixed => onChangeOfferWeight(collectionId, Number(e.target.value))}
                    onDelete={(): mixed => onDeleteOffer(funnelId, collectionId)}
                    onChangeUrl={(e): mixed => onChangeUrl(collectionId, e.target.value)}
                    isActive={isActive}
                    onIsActiveChange={(e): mixed => onIsActiveChange(collectionId, e.target.checked)}
                    activationChangeDisabled={activationChangeDisabled}
                    disableDelete={disableDelete}
                  />
                );
              })}
            </Box>
          </SetupCard>
        </Box>
      )}
    </>
  );
};

export default Offers;
