// @flow
import React, { type StatelessFunctionalComponent, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeTableFilters } from "@fas/ui-framework/lib/redux/actions/table";
import { getTableFilters } from "@fas/ui-framework/lib/redux/selectors/table";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { Multiselect } from "@fas/ui-core";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import {
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { GENERAL_FILTERS_CAMPAIGNS_TABLE } from "../../helpers/constants";
import type { UseState } from "./types/GeneralFilterCampaignsFilters.types";

type Operator = "contains" | "not contains";

const CONTAINS: Operator = "contains";
const NOT_CONTAINS: Operator = "not contains";
const switchOperator: (Operator) => Operator = (operator) => (operator === CONTAINS ? NOT_CONTAINS : CONTAINS);
const getFilter: (Object, string) => Option[] = (filters, key) => (filters[key] || {}).value || [];

type UseGeneralFilterCampaignsFilters = {
  tags: Option[],
  filter: Option[],
  tagsOperator: Operator,
  filterOperator: Operator,
  onChangeTagsOperator: () => mixed,
  onChangeFilterOperator: () => mixed,
  onAddTags: (Option) => void,
  onAddFilter: (Option) => void,
  onDeleteTags: (string) => void,
  onDeleteFilter: (string) => void,
};

const useOperator: (Filters, string) => [Operator, () => mixed] = (filters, key) => {
  const dispatch: <A>(A) => A = useDispatch();

  const [operator, setOperator]: UseState<Operator> = useState(NOT_CONTAINS);

  const onChange: () => mixed = () => {
    const newOperator: Operator = switchOperator(operator);
    setOperator(newOperator);

    const value: Option[] = getFilter(filters, key);

    if (value.length) {
      dispatch(changeTableFilters(GENERAL_FILTERS_CAMPAIGNS_TABLE, {
        ...filters,
        [key]: {
          operator: newOperator,
          value,
        },
      }));
    }
  };

  return [operator, onChange];
};

const useGeneralFilterCampaignsFilters: () => UseGeneralFilterCampaignsFilters = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const filters: Filters = useSelector((state) => getTableFilters(state, GENERAL_FILTERS_CAMPAIGNS_TABLE));
  const [tagsOperator, onChangeTagsOperator]: [Operator, () => mixed] = useOperator(filters, "tags");
  const [filterOperator, onChangeFilterOperator]: [Operator, () => mixed] = useOperator(filters, "filter");

  const tags: Option[] = getFilter(filters, "tags");
  const filter: Option[] = getFilter(filters, "filter");

  const onAdd: (string, Operator) => (Option) => void = (key, operator) => (val: Option) => {
    dispatch(changeTableFilters(GENERAL_FILTERS_CAMPAIGNS_TABLE, {
      ...filters,
      [key]: {
        operator,
        value: [...getFilter(filters, key), val],
      },
    }));
  };

  const onDelete: (string, Operator) => (string) => void = (key, operator) => (val: string) => {
    // $FlowFixMe
    if (filters[key].value.length === 1) {
      dispatch(changeTableFilters(
        GENERAL_FILTERS_CAMPAIGNS_TABLE,
        Object.keys(filters).reduce((acc: Filters, current: string): Filters => {
          if (current !== key) {
            acc[current] = filters[current];
          }
          return acc;
        }, {})
      ));
      return;
    }

    dispatch(changeTableFilters(GENERAL_FILTERS_CAMPAIGNS_TABLE, {
      ...filters,
      [key]: {
        operator,
        value: filters[key].value.filter(({ value }: Option): boolean => value !== val),
      },
    }));
  };

  const onAddTags: (Option) => void = onAdd("tags", tagsOperator);
  const onAddFilter: (Option) => void = onAdd("filter", filterOperator);
  const onDeleteTags: (string) => void = onDelete("tags", tagsOperator);
  const onDeleteFilter: (string) => void = onDelete("filter", filterOperator);

  return {
    tags,
    filter,
    tagsOperator,
    filterOperator,
    onChangeTagsOperator,
    onChangeFilterOperator,
    onAddTags,
    onAddFilter,
    onDeleteTags,
    onDeleteFilter,
  };
};

const GeneralFilterCampaignsFilters: StatelessFunctionalComponent<{}> = () => {
  const {
    tags,
    filter,
    tagsOperator,
    filterOperator,
    onChangeTagsOperator,
    onChangeFilterOperator,
    onAddTags,
    onAddFilter,
    onDeleteTags,
    onDeleteFilter,
  }: UseGeneralFilterCampaignsFilters = useGeneralFilterCampaignsFilters();

  return (
    <Box display="flex" flexDirection="column" mt={1} mb={1}>
      <Grid container>
        <Grid item xs={2}>
          <FormControl variant="outlined" size="small" fullWidth>
            <Select value={tagsOperator} onChange={onChangeTagsOperator} data-testid="tags-select">
              <MenuItem value="contains">Contains</MenuItem>
              <MenuItem value="not contains">Not contains</MenuItem>
            </Select>
            <FormHelperText>Tags</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Box ml={1}>
            <Multiselect
              dataTestId="tags-multiselect"
              type="enterManualMultiple"
              options={[]}
              selectedOptions={tags}
              onAddValue={onAddTags}
              onRemoveValue={onDeleteTags}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box ml={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select value={filterOperator} onChange={onChangeFilterOperator} data-testid="filters-select">
                <MenuItem value="contains">Contains</MenuItem>
                <MenuItem value="not contains">Not contains</MenuItem>
              </Select>
              <FormHelperText>Filters</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box ml={1}>
            <Multiselect
              dataTestId="filters-multiselect"
              type="enterManualMultiple"
              options={[]}
              selectedOptions={filter}
              onAddValue={onAddFilter}
              onRemoveValue={onDeleteFilter}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralFilterCampaignsFilters;
