// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchChangeAntifraudVariableActive from "../../sagas/changeAntifraudVariableActive";
import watchGetAntifraudVariablesList from "../../sagas/getAntifraudVariablesList";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetAntifraudVariablesList),
    call(watchChangeAntifraudVariableActive),
  ]);
}
