// @flow

import type { DropResult } from "react-beautiful-dnd";

export const isChangedOrder = (drag: DropResult): boolean => {
  const { index: fromIndex, droppableId: fromId } = drag.source;
  const { index: toIndex, droppableId: toId } = drag.destination || {};
  return fromId === toId && fromIndex !== toIndex;
};

export const swap = <T>(order: Array<T>, fromIndex: number, toIndex: number): Array<T> => {
  const newOrder = [...order];
  newOrder.splice(toIndex, 0, newOrder.splice(fromIndex, 1)[0]);
  return newOrder;
};

export const dragEndWrapper = <T>(order: Array<T>, onChange: (newOrder: Array<T>) => void) => (drag: DropResult) => {
  if (isChangedOrder(drag)) {
    const { index: fromIndex } = drag.source;
    const { index: toIndex } = drag.destination || {};
    onChange(swap(order, fromIndex, toIndex));
  }
};
