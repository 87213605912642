// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import qs from "qs";
import type {
  Filters,
  Sorting,
} from "@fas/ui-framework/lib/redux/reducers/table";
import { requestService } from "../request";
import type { Scenario, RuleForSave } from "../../selectors/antifraudScenarios";

export const fetchAntifraudScenariosList: (
  options: {
    page: number,
    limit: number,
    filters: Filters,
    sorting: Sorting,
  },
) => PromiseResponse<{ data: { list: Array<Scenario>, count: number } }> = (
  options
) => requestService
  .get(environment.endpoints.get.antifraudScenariosList, {
    params: { ...options },
    paramsSerializer: ({ filters, sorting, ...rest }: *): string => qs.stringify({
      ...rest,
      filters: Object.keys(filters).length !== 0 ? filters : "{}", // TODO: change contracts, fix backend and front in MTU-56306
      sorting: Object.keys(sorting).length !== 0 ? sorting : "{}", // TODO: change contracts, fix backend and front in MTU-56306
    }, { arrayFormat: "brackets", encode: true }),
  })
  .then(({ data }) => data);

export const changeAntifraudScenarioActive: (
  ids: number[],
  status: boolean
) => PromiseResponse<{}> = (ids, status) => requestService
  .post(environment.endpoints.post.antifraudScenarioActive, { ids, status });

export const fetchAntifraudScenario: (
  id: number
) => PromiseResponse<{ scenario: Scenario }> = (id) => requestService
  .get<{ scenario: Scenario }>(environment.endpoints.get.antifraudScenario, {
    params: {
      scenarioId: id,
    },
  })
  .then(({ data }) => data);

export const createAntifraudScenario: (
  scenario: { name: string, event: string, isActive: boolean, rules: Array<RuleForSave> }
) => PromiseResponse<{}> = (scenario) => requestService
  .post(environment.endpoints.post.antifraudScenario, scenario);

export const updateAntifraudScenario: (
  scenario: { name: string, event: string, isActive: boolean, rules: Array<RuleForSave>, scenarioId: number }
) => PromiseResponse<{}> = (scenario) => requestService
  .put(environment.endpoints.put.antifraudScenario, scenario);
