// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetAntifraudTriggerListSaga from "../../sagas/getAntifraudTriggerListSaga";
import watchChangeAntifraudTriggerActiveSaga from "../../sagas/changeAntifraudTriggerActiveSaga";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetAntifraudTriggerListSaga),
    call(watchChangeAntifraudTriggerActiveSaga),
  ]);
}
