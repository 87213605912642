// @flow
import {
  CHANGE_PLACEHOLDER,
  CHANGE_PLACEHOLDER_VALUE,
  CLICK_PRESET,
  CLEAR_PLACEHOLDERS,
} from "../../helpers/constants";
import type { Placeholder } from "../../components/PlaceholdersList/types/PlaceholdersList.types";

export type ChangePlaceholderAction = {
  type: typeof CHANGE_PLACEHOLDER,
  name: string,
  value?: string,
};
export type ChangePlaceholderValueAction = {
  type: typeof CHANGE_PLACEHOLDER_VALUE,
  name: string,
  value: string,
};
export type ClickPresetAction = {
  type: typeof CLICK_PRESET,
  placeholders: Array<Placeholder>,
};
export type ClearPlaceholdersAction = {
  type: typeof CLEAR_PLACEHOLDERS,
};
export type PlaceholdersActionType = ChangePlaceholderAction
| ChangePlaceholderValueAction
| ClickPresetAction
| ClearPlaceholdersAction;

export const changePlaceholder = (
  name: string,
  value?: string
): ChangePlaceholderAction => ({
  type: CHANGE_PLACEHOLDER,
  name,
  value,
});
export const changePlaceholderValue = (
  name: string,
  value: string
): ChangePlaceholderValueAction => ({
  type: CHANGE_PLACEHOLDER_VALUE,
  name,
  value,
});
export const clickPreset = (
  placeholders: Array<Placeholder>
): ClickPresetAction => ({
  type: CLICK_PRESET,
  placeholders,
});
export const clearPlaceholders = (
): ClearPlaceholdersAction => ({
  type: CLEAR_PLACEHOLDERS,
});
