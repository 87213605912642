/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put, select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import { SAVE_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import type { VariableFormData } from "../../selectors/antifraudVariables";
import {
  ANTIFRAUD_VARIABLE_FORM,
  ANTIFRAUD_VARIABLE_SOURCE_REQUEST,
} from "../../helpers/constants";
import { createAntifraudVariable, updateAntifraudVariable } from "../../services/antifraudVariablesApi";
import ExternalRedirect from "../../components/App/ExternalRedirect";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(SAVE_FORM_DATA_SAGA, true));

    const {
      varId,
      value,
      dictionaryId,
      variableId,
      ...restDict
    }: VariableFormData = yield select((state): VariableFormData => getFormData(state, ANTIFRAUD_VARIABLE_FORM));

    let body: VariableFormData;

    if (restDict.source === ANTIFRAUD_VARIABLE_SOURCE_REQUEST) {
      body = { ...restDict, value };
    }
    else {
      body = { ...restDict, dictionaryId, variableId };
    }

    if (varId) {
      yield call(updateAntifraudVariable, { ...body, varId });
    }
    else {
      yield call(createAntifraudVariable, body);
    }

    ExternalRedirect({ to: "/antifraudVariables/list" });
  }
  catch (err) {
    if (err.isAxiosError
      && err.response
      && err.response.data
      && err.response.data.errors
      && Array.isArray(err.response.data.errors)) {
      yield put(setErrors(prepareFetchErrors(err.response.data.errors)));
    }
    else {
      yield put(addNotification({ message: err.errorMessage || "Failed to save", severity: "error" }));
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  finally {
    yield put(setLoading(SAVE_FORM_DATA_SAGA, false));
  }
}

export default function* watchSaveAntifraudVariable(): Saga<void> {
  yield takeEvery(SAVE_FORM_DATA_SAGA, (makeFetch: Function));
}
