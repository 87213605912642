import {
  put,
  race,
  take,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  setConfirmNotificationMessage,
  openConfirmNotification,
  closeConfirmNotification,
} from "../../actions/confirmation";
import {
  CONFIRM,
  SET_CONFIRM_NOTIFICATION_CONFIRMED,
  SET_CONFIRM_NOTIFICATION_CANCELED,
} from "../../helpers/constants";

export function* showConfirmation(action): Saga<void> {
  const { message } = action;
  yield put(setConfirmNotificationMessage(message));
  yield put(openConfirmNotification());

  const { confirmed } = yield race({
    confirmed: take(SET_CONFIRM_NOTIFICATION_CONFIRMED),
    canceled: take(SET_CONFIRM_NOTIFICATION_CANCELED),
  });

  yield put(closeConfirmNotification());

  return !!confirmed;
}

export default function* watchConfirmation(): Saga<void> {
  yield takeEvery(CONFIRM, showConfirmation);
}
