// @flow
import { Map, List, Record } from "immutable";
import {
  GENERAL_FILTER_INFO_CHANGE_NAME,
  GENERAL_FILTER_INFO_CHANGE_DESCRIPTION,
  GENERAL_FILTER_INFO_CHANGE_PRIORITY,
  GENERAL_FILTER_INFO_CHANGE_RATE,
  GENERAL_FILTER_INFO_CHANGE_MUST_HAVE,
  GENERAL_FILTER_INFO_CHANGE_IS_ACTIVE,
  GENERAL_FILTER_INFO_ADD_CAMPAIGN_IN,
  GENERAL_FILTER_INFO_DELETE_CAMPAIGN_IN,
  GENERAL_FILTER_INFO_ADD_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_DELETE_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_OPEN_MODAL_IN,
  GENERAL_FILTER_INFO_OPEN_MODAL_OUT,
  GENERAL_FILTER_INFO_SET_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_UNSET_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_ID,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_NAME,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_TYPE,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_STATUS,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_TAGS,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_TAGS,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_TAGS,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_FILTER,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_FILTER,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_FILTER,
  GENERAL_FILTER_INFO_CHANGE_FILTERS_RESET_FILTERS,
  GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_IN,
  GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_IN,
  GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_OUT,
  GENERAL_FILTER_INFO_MODAL_CAMPAIGN_IN_SAVE,
  GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_EDIT,
  GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_ADD,
  GENERAL_FILTER_INFO_EQUAL_SHARES,
  GENERAL_FILTER_INFO_SET_WEIGHT,
  GENERAL_FILTER_INFO_INIT_STATE_EDIT,
  GENERAL_FILTER_CHANGE_TARGETING,
  GENERAL_FILTER_INFO_FACT,
  GENERAL_FILTER_INFO_DRYMODE,
  GENERAL_FILTER_INFO_RERENDER,
} from "../../helpers/constants";
import { type Targeting } from "../targetings/reducer";

export type schemaType = {
  [string]: {
    type: string,
    targetings?: Array<string>,
    operators: Array<string>,
    value?: Array<any>,
  },
}

export type CampaignType = {
  id: string,
  value: string,
  label: string,
  weight: number,
};

export type CampaignTable = {
  id: string,
  name: string,
};

export type Tags = {
  operator: string,
  value: Map<CampaignType>,
};

export type CampaignFiltersType = {
  id: string,
  name: string,
  type: string,
  status: string,
  tags: Tags,
  filter: Tags,
};

export type FilterInfo = {
  id: number,
  name: string,
  description: string,
  priority: number,
  oldPriority: number,
  rate: number,
  mustHave: boolean,
  isActive: boolean,
  fact: number,
  dryModeRate: number,
  tdsCampaignsIn: Array<CampaignType>,
  tdsCampaignsOut: Array<CampaignType>,
  campaignFilters: CampaignFiltersType,
  selectedCampaign: CampaignTable,
  conditions: Map<schemaType>,
};

export type GeneralFilter = {
  id?: number,
  name: string,
  description: string,
  priority: number,
  oldPriority?: number,
  rate: number,
  mustHave: boolean,
  isActive: boolean,
  fact: number,
  dryModeRate: number,
  conditions: Map<schemaType>,
  isLastStep: boolean,
  tdsCampaignsIn: Array<string>,
  tdsCampaignsOut: Array<CampaignType>,
  targeting?: {
    [key: string]: Targeting,
  },
};

export type ErrorOption = {
  isError: boolean,
  helperText: string,
};

export type FormFilterInfo = {
  list: Array<number>
};

export const makeState = Record({
  id: "",
  name: "",
  description: "",
  priority: 1,
  oldPriority: null,
  rate: "",
  fact: 0,
  dryModeRate: 0,
  mustHave: false,
  isActive: false,
  tdsCampaignsIn: List([]),
  tdsCampaignsOut: List([]),
  campaignFilters: Map({
    id: "",
    name: "",
    type: "",
    status: "",
    tags: {
      operator: "contains",
      value: List([]),
    },
    filter: {
      operator: "contains",
      value: List([]),
    },
  }),
  selectedCampaign: List([]),
  rerender: true, // FIX 92090
});

export const initialState: Map<FilterInfo> = makeState();

export function initFilterInfoState() {
  return initialState;
}

/* eslint-disable complexity */
export default (state: Map<FilterInfo> = initialState, action: any) => {
  switch (action.type) {
    case GENERAL_FILTER_INFO_CHANGE_NAME: {
      return state.set("name", action.value);
    }
    case GENERAL_FILTER_INFO_CHANGE_DESCRIPTION: {
      return state.set("description", action.value);
    }
    case GENERAL_FILTER_INFO_CHANGE_PRIORITY: {
      return state.set("priority", action.value);
    }
    case GENERAL_FILTER_INFO_CHANGE_RATE: {
      const value = typeof action.value === "string" ? action.value.replace(/^0+/, "") : action.value;
      return state.set("rate", value);
    }
    case GENERAL_FILTER_INFO_CHANGE_MUST_HAVE: {
      return state.set("mustHave", !state.get("mustHave"));
    }
    case GENERAL_FILTER_INFO_CHANGE_IS_ACTIVE: {
      return state.set("isActive", !state.get("isActive"));
    }
    case GENERAL_FILTER_INFO_ADD_CAMPAIGN_IN: {
      return state.set("tdsCampaignsIn", List([
        ...state.get("tdsCampaignsIn"),
        Map(action.value),
      ]));
    }
    case GENERAL_FILTER_INFO_DELETE_CAMPAIGN_IN: {
      return state
        .updateIn(["tdsCampaignsIn"], ((tdsCampaignsIn) => tdsCampaignsIn.filter((el) => el.get("value") !== action.value)))
        .updateIn(["selectedCampaign"], ((selectedCampaign) => selectedCampaign.filter((el) => el !== action.value)));
    }
    case GENERAL_FILTER_INFO_ADD_CAMPAIGN_OUT: {
      return state.updateIn(["tdsCampaignsOut", state.get("tdsCampaignsOut").size], () => Map({
        id: "",
        value: "",
        label: "",
        weight: 0,
      }));
    }
    case GENERAL_FILTER_INFO_DELETE_CAMPAIGN_OUT: {
      return state
        .updateIn(["tdsCampaignsOut"], ((tdsCampaignsOut) => tdsCampaignsOut.filter((el, index) => index !== action.value)))
        .updateIn(["selectedCampaign"], ((selectedCampaign) => selectedCampaign.filter((el) => el !== action.value)));
    }
    case GENERAL_FILTER_INFO_OPEN_MODAL_IN: {
      return state.set("selectedCampaign", List(state.get("tdsCampaignsIn").toJS().map((item) => item.id)));
    }
    case GENERAL_FILTER_INFO_OPEN_MODAL_OUT: {
      if (action.index !== 5) {
        return state.set("selectedCampaign", List(...state.getIn(["tdsCampaignsOut", action.index])));
      }

      return state.set("selectedCampaign", List(...state.get("tdsCampaignsOut")));
    }
    case GENERAL_FILTER_INFO_SET_CAMPAIGN_OUT: {
      return state.updateIn(["tdsCampaignsOut", action.index], (item) => Map({
        id: action.value,
        label: action.value,
        value: action.value,
        weight: item.get("weight"),
      }));
    }
    case GENERAL_FILTER_INFO_UNSET_CAMPAIGN_OUT: {
      return state.updateIn(["tdsCampaignsOut", action.index], () => Map({
        id: "",
        value: "",
        label: "",
        weight: 0,
      }));
    }
    case GENERAL_FILTER_INFO_CHANGE_FILTERS_ID: {
      return state.setIn(["campaignFilters", "id"], action.value);
    }
    case GENERAL_FILTER_INFO_CHANGE_FILTERS_NAME: {
      return state.setIn(["campaignFilters", "name"], action.value);
    }
    case GENERAL_FILTER_INFO_CHANGE_FILTERS_STATUS: {
      return state.setIn(["campaignFilters", "status"], action.value);
    }
    case GENERAL_FILTER_INFO_CHANGE_FILTERS_TYPE: {
      return state.setIn(["campaignFilters", "type"], action.value);
    }
    case GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_TAGS: {
      return state.updateIn(["campaignFilters", "tags", "value"], (tags) => tags.push(action.value));
    }
    case GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_TAGS: {
      return state.updateIn(["campaignFilters", "tags", "value"], ((tags) => tags.filter((el) => el.value !== action.value)));
    }
    case GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_TAGS: {
      return state.setIn(["campaignFilters", "tags", "operator"], action.value);
    }
    case GENERAL_FILTER_INFO_CHANGE_FILTERS_ADD_FILTER: {
      return state.updateIn(["campaignFilters", "filter", "value"], (filter) => filter.push(action.value));
    }
    case GENERAL_FILTER_INFO_CHANGE_FILTERS_DELETE_FILTER: {
      return state.updateIn(["campaignFilters", "filter", "value"], ((filter) => filter.filter((el) => el.value !== action.value)));
    }
    case GENERAL_FILTER_INFO_CHANGE_FILTERS_CHANGE_STATUS_FILTER: {
      return state.setIn(["campaignFilters", "filter", "operator"], action.value);
    }
    case GENERAL_FILTER_INFO_CHANGE_FILTERS_RESET_FILTERS: {
      return state
        .setIn(["campaignFilters"], Map({
          id: "",
          name: "",
          type: "",
          status: "",
          tags: {
            operator: "contains",
            value: List([]),
          },
          filter: {
            operator: "contains",
            value: List([]),
          },
        }));
    }
    case GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_IN: {
      return state.updateIn(["selectedCampaign"], (selectedCampaign) => selectedCampaign.push(action.value.id));
    }
    case GENERAL_FILTER_INFO_MODAL_SELECT_CAMPAIGN_OUT: {
      return state.set("selectedCampaign", List([action.value.id]));
    }
    case GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_IN: {
      return state.updateIn(["selectedCampaign"], ((selectedCampaign) => selectedCampaign.filter((item) => item !== action.value.id)));
    }
    case GENERAL_FILTER_INFO_MODAL_UNSELECT_CAMPAIGN_OUT: {
      return state.set("selectedCampaign", List([]));
    }
    case GENERAL_FILTER_INFO_MODAL_CAMPAIGN_IN_SAVE: {
      return state.set("tdsCampaignsIn", List(action.ids.map((id) => Map({ value: id, label: id, id }))));
    }
    case GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_EDIT: {
      let id = state.get("selectedCampaign").toJS();
      id = id[id.length - 1];

      return state.updateIn(["tdsCampaignsOut", action.index], (campaign) => campaign
        .set("id", id)
        .set("label", id)
        .set("value", id)
        .set("weight", 0));
    }
    case GENERAL_FILTER_INFO_MODAL_CAMPAIGN_OUT_SAVE_ADD: {
      return state.set(
        "tdsCampaignsOut",
        List([
          ...action.campaigns.map(({ id, weight }) => Map({
            value: id,
            label: id,
            weight,
            id,
          })),
        ])
      );
    }
    case GENERAL_FILTER_INFO_EQUAL_SHARES: {
      const collection = state.get("tdsCampaignsOut");
      const totalLength = collection.size;
      const integerPart = Math.floor(100 / totalLength);
      const residualPart = 100 % totalLength;

      return state.set("tdsCampaignsOut", state.get("tdsCampaignsOut")
        .map((item, index) => item.set("weight", index < residualPart ? integerPart + 1 : integerPart)));
    }
    case GENERAL_FILTER_INFO_SET_WEIGHT: {
      return state.updateIn(["tdsCampaignsOut", action.index], (item) => item.set("weight", action.value && parseInt(action.value, 10)));
    }
    case GENERAL_FILTER_INFO_INIT_STATE_EDIT: {
      const {
        tdsCampaignsIn = "",
        tdsCampaignsOut = [],
        ...rest
      } = action.value.row;

      const data: $Shape<FilterInfo> = {
        ...rest,
        tdsCampaignsIn: List((tdsCampaignsIn)
          .split(",")
          .filter((item) => item)
          .map((campaign) => Map({
            value: campaign,
            label: campaign,
            id: campaign,
          }))),
        tdsCampaignsOut: List((tdsCampaignsOut).map((campaign) => Map({
          id: campaign.id,
          label: campaign.id,
          value: campaign.id,
          weight: campaign.weight,
        }))),
      };

      return state.merge(initialState).merge(data);
    }
    case GENERAL_FILTER_CHANGE_TARGETING: {
      return state.set("conditions", action.value[0]);
    }
    case GENERAL_FILTER_INFO_FACT: {
      // eslint-disable-next-line radix
      let value = parseInt(+action.value);
      value = value < 0 ? 0 : value;
      value = value > 100 ? 100 : value;

      return state
        .set("fact", value)
        .set("dryModeRate", 100 - value);
    }
    case GENERAL_FILTER_INFO_DRYMODE: {
      // eslint-disable-next-line radix
      let value = parseInt(+action.value);
      value = value < 0 ? 0 : value;
      value = value > 100 ? 100 : value;

      return state
        .set("fact", 100 - value)
        .set("dryModeRate", value);
    }
    case GENERAL_FILTER_INFO_RERENDER: {
      return state.set("rerender", false);
    }
    default:
      return state;
  }
};
