// @flow
import {
  AppBar, Box, IconButton, Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

export default ({ onClose }: { onClose: () => void }) => (
  <AppBar position="relative" color="primary">
    <Box p={2} display="flex" alignItems="center">
      <Box flexGrow={1}>
        <Typography variant="h6">
          COLUMNS
        </Typography>
      </Box>
      <Box>
        <IconButton onClick={onClose} color="inherit" data-testid="closeButton">
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  </AppBar>
);
