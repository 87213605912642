// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";

export const getCampaignById: (string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.campaign, {
    params: { id },
  });

export const getCampaignJsonById: (string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.campaignJsonById.replace("{campaignId}", id));

export const getBasicCampaignsUrlById: (id?: string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.basicCampaignsUrlById, {
    params: {
      offerType: "basic",
      method: "id",
      q: id || "",
    },
  });

export const getDomainsList: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.domainsList);

export const getOffersList: (number, number, Object) => PromiseResponse<mixed> = (
  page,
  pageSize,
  filters
) => requestService.post(environment.endpoints.post.offersList, {
  page,
  pageSize,
  ...filters,
});

export const getGeneralFilters: (string) => PromiseResponse<mixed> = (smartlinkId) => requestService
  .get(environment.endpoints.get.generalFilters, {
    params: {
      id: smartlinkId,
    },
  });

export const getCampaignId: (string) => PromiseResponse<mixed> = (type = "smartlink") => requestService
  .get(environment.endpoints.get.campaignId.replace("{campaignType}", type));

export const getTargetingRules: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.targetingRules, {
    params: {
      type: "smartlink",
    },
  });

export const getValuesForTargeting: (string) => PromiseResponse<mixed> = (ruleName) => requestService
  .get(environment.endpoints.get.targetingValues, {
    params: {
      rule: ruleName,
    },
  });

export const saveCampaign: () => PromiseResponse<Object> = (campaign) => requestService
  .post(environment.endpoints.post.saveCampaign, campaign);

export const getShortUrl: (string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.getShortUrl, {
    params: { id },
  });
