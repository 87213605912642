// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import loading, { initLoadingState, type State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import notifications, { initNotificationsState, type State as NotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import {
  reducer as errors,
  initState as initErrorState,
  type State as ErrorState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import dropdowns, { initDropdownState } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import type { DropdownsState } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import antifraudTriggerForm, { initAntifraudTriggerFormState, type State as AntifraudTriggerFormState } from "../../reducers/antifraudTriggerForm";
import type { Actions as AntifraudTriggerFormActions } from "../../actions/antifraudTriggerForm";
import type { AntifraudTriggersLoadingTypes } from "../../selectors/antifraudTrigger/types";
import mainSaga from "./saga";

export type State = $ReadOnly<{|
  loading: LoadingState<AntifraudTriggersLoadingTypes>,
  errors: ErrorState,
  notifications: NotificationsState,
  antifraudTriggerForm: AntifraudTriggerFormState,
  dropdowns: DropdownsState<"dictionaries" | "triggers-variables/dynamic", string | number>,
|}>

function mapAntifraudTriggerToState(): State {
  return {
    loading: initLoadingState({
      isGetAntifraudTriggerLoading: false,
      isSaveAntifraudTriggerLoading: false,
      isGetAntifraudConditionsListLoading: false,
      isGetAntifraudConditionsValuesLoading: false,
    }),
    errors: initErrorState(),
    notifications: initNotificationsState(),
    antifraudTriggerForm: initAntifraudTriggerFormState(),
    dropdowns: initDropdownState({
      dictionaries: [],
    }),
  };
}

export type Actions = LoadingActions<AntifraudTriggersLoadingTypes>
  | NotificationsActions
  | AntifraudTriggerFormActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  loading,
  errors,
  notifications,
  antifraudTriggerForm,
  dropdowns,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapAntifraudTriggerToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
