// @flow
import React, { useEffect } from "react";
import { Loader } from "@fas/ui-core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IconButton } from "@mui/material";
import FileCopyOutlined from "@mui/icons-material/FileCopyOutlined";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { fetchCampaignJsonSaga } from "../../actions/dictionaries";
import { getIsCampaignJsonLoading, getJsonCampaign } from "../../selectors/smartlink";
import { getFunnelTemplateId } from "../../selectors/templates";

const styles = makeStyles(() => ({
  pre: {
    margin: 0,
    display: "block",
    overflowX: "auto",
    maxWidth: "94vw",
  },
  copyBtn: {
    right: 0,
    position: "absolute",
  },
}));
type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  id: string, // todo maybe move to OwnProps
  jsonCampaign: Object,
  loading: boolean,
  fetchCampaignJson: (id: string) => void,
|}>;

const mapStateToProps = (state) => ({
  id: getFunnelTemplateId(state),
  jsonCampaign: getJsonCampaign(state),
  loading: getIsCampaignJsonLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCampaignJson: (id) => dispatch(fetchCampaignJsonSaga(id)),
});

export const JsonCampaignViewer = ({
  id,
  jsonCampaign,
  loading,
  fetchCampaignJson,
}: Props) => {
  const classes = styles();

  useEffect(() => {
    if (jsonCampaign.id !== id) {
      fetchCampaignJson(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const text = JSON.stringify(jsonCampaign, null, 4);

  return (
    <Loader loading={loading}>
      {!loading && id && (
        <>
          <CopyToClipboard text={text} className={classes.copyBtn} data-testid="clipboard-btn">
            <IconButton>
              <FileCopyOutlined color="action" />
            </IconButton>
          </CopyToClipboard>
          <pre className={classes.pre} data-testid="json-campaign-view">
            {text}
          </pre>
        </>
      )}
    </Loader>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(JsonCampaignViewer);
