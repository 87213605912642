// @flow
import Joi from "@hapi/joi";
import type { OrderedMap } from "immutable";
import type { JoiError, CollectionErrors } from "../../types/Base";
import type { Split } from "../../../../reducers/splits";
import type { BaseCollectionErrorsRule } from "../Base";
import {
  FUNNELS_REQUIRED,
  FUNNELS_COUNT_REACHED,
} from "../../messages";

export default class Splits implements BaseCollectionErrorsRule {
  schema: Object;

  errorKey: string;

  errors: CollectionErrors;

  constructor() {
    this.errors = {};
    this.errorKey = "a";

    this.schema = Joi
      .object({
        funnels: Joi
          .array()
          .min(1)
          .max(300),
      })
      .unknown(true)
      .error((errors) => this._onError(errors));
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach((item) => {
      switch (item.code) {
        case "array.min": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: FUNNELS_REQUIRED },
          };
          break;
        }
        case "array.max": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: FUNNELS_COUNT_REACHED },
          };
          break;
        }
        default:
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: item.message },
          };
          break;
      }
    });

    return errors;
  }

  getErrors(data: OrderedMap<Split>): CollectionErrors {
    data.forEach((split, splitId) => {
      this.errorKey = splitId;
      this.schema.validate(split.toJS());
    });

    return this.errors;
  }
}
