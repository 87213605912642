// @flow

export const WEIGHTS = "Weights are wrong";
export const OFFERS_EMPTY = "You have no offers";
export const DOMAIN_REQUIRED = "Domain is required";
export const DOMAIN_INVALID = "Domain is must be a valid domain";
export const CAMPAIGN_NAME_REQUIRED = "Campaign name is required";
export const AFFILIATE_DATA_TYPE_REQUIRED = "Smartlink type is required";
export const FUNNELS_REQUIRED = "Funnels in split are required";
export const FUNNELS_COUNT_REACHED = "You have more funnels than allowed";
export const FREQUENCY_WEIGHTS_UNIQUE = "Frequency weights must be unique";
export const FREQUENCY_PERIOD_REQUIRED = "Period is required";
export const ACTION_OPTIONS_PARAM_IS_INVALID_USER_DATA = "Invalid user data source";
export const ACTION_OPTIONS_PARAM_IS_INVALID_PLACEHOLDER = "Invalid placeholder";
export const REQUEST_LIMIT_PER_SEC_INVALID = "Invalid RPS Hard Limit";
