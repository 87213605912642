// @flow
import React from "react";
import { connect } from "react-redux";
// $FlowFixMe
import moment from "moment";
import GeneralInfoTable from "../../components/GeneralInfoTable";
import { getFunnelTemplateInfo } from "../../selectors/templates";

type OwnProps = $ReadOnly<{||}>

type Props = {
  ...OwnProps,
  generalInfoData: Map<string, string>,
};

const mapStateToProps = (state) => ({
  generalInfoData: getFunnelTemplateInfo(state),
});

const columns = [
  { field: "id", label: "ID", format: (value) => value.toString() },
  { field: "type", label: "Type", format: (value) => value.toString() },
  { field: "funnels", label: "Funnels", format: (value) => value.toString() },
  { field: "manager", label: "Manager", format: (value) => value.toString() },
  {
    field: "createdAt",
    label: "Created",
    format: (value) => moment(value).format("YYYY-MM-DD, HH:mm:ss"),
  },
  {
    field: "updatedAt",
    label: "Updated",
    format: (value) => moment(value).format("YYYY-MM-DD, HH:mm:ss"),
  },
  { field: "lastEditor", label: "Last Editor", format: (value) => value.toString() },
  { field: "campaigns", label: "Campaigns", format: (value) => value.toString() },
];

const FunnelTemplateGeneral = ({
  generalInfoData,
}: Props) => (
  <GeneralInfoTable
    data={generalInfoData}
    columns={columns}
  />
);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps)(FunnelTemplateGeneral);
