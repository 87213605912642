// @flow
import {
  takeEvery,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  CLOSE_MODAL_GENERAL_FILTERS_SAGA,
} from "../../helpers/constants";
import { closeGeneralFiltersModal } from "../../actions/modals";
import { restoreBackup } from "../../actions/backup/actions";

export function* closeGeneralFiltersSaga(): Saga<void> {
  yield put(closeGeneralFiltersModal());
  yield put(restoreBackup());
}

export default function* watchCloseGeneralFilters(): Saga<void> {
  yield takeEvery(CLOSE_MODAL_GENERAL_FILTERS_SAGA, closeGeneralFiltersSaga);
}
