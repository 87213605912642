// @flow
import React from "react";
import type { ComponentType } from "react";
import { useSelector } from "react-redux";
import type { Option } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import type { Props as TargetingComponentProps } from "./TriggerRuleComponent";
import type {
  SelectedTriggerRule,
  RuleTriggerQb,
  TriggerValueType,
  CounditionErrors,
} from "../../selectors/antifraudTrigger";

type Props<S> = {
  id: string,
  RuleComponent: ComponentType<TargetingComponentProps>,
  groupProps: {
    removeRule: (string, string) => void,
    getStatusRule: (string) => boolean,
  },
  ruleProps: {
    getRuleByIdSelector: (S, string) => SelectedTriggerRule,
    getRuleList: (S, string) => Array<RuleTriggerQb>,
    getOperatorsList: (S, string) => Array<Option<string>>,
    getValuesList: (S) => Array<Option<string>>,
    handleChangeValue: (string, string) => void,
    handleChangeLocation: (string, "data" | "headers" | "cookies") => void,
    handleChangeValueType: (string, TriggerValueType) => void,
    handleChangeRule: <T>(string, RuleTriggerQb) => void,
    handleChangeOperator: (string, string) => void,
    isRemoveDisabled: boolean,
    getErrors?: (S, string) => {
      name: { message: string } | null,
      value: { message: string } | null,
      operator: { message: string } | null
    },
    checkLoadingForValues: (*, string) => boolean,
  },
  parentGroupId: string,
};

function TargetingRuleContainer<S>(props: Props<S>) {
  const {
    RuleComponent,
    groupProps,
    id,
    ruleProps,
    parentGroupId,
  }: Props<S> = props;

  const {
    getRuleList,
    getOperatorsList,
    isRemoveDisabled,
    handleChangeLocation,
    handleChangeValue,
    handleChangeValueType,
    handleChangeOperator,
    handleChangeRule,
    getErrors,
    getValuesList,
  } = ruleProps;

  const defaultErrors: CounditionErrors = { name: null, value: null, operator: null };
  const errors: CounditionErrors = useSelector((state: S) => (getErrors ? getErrors(state, id) : defaultErrors));
  const selectedRule: SelectedTriggerRule = useSelector(
    (state: S) => ruleProps.getRuleByIdSelector(state, id)
  );
  const operatorList: Array<Option<string>> = useSelector((state: S) => getOperatorsList(state, selectedRule.name || ""));
  const rulesList: Array<RuleTriggerQb> = useSelector((state: S) => getRuleList(state, parentGroupId));
  const valuesList: Array<Option<string>> = useSelector((state: S) => getValuesList(state));

  return (
    <RuleComponent
      id={id}
      parentGroupId={parentGroupId}
      selectedRule={selectedRule}
      isDisabledRule={groupProps.getStatusRule(id)}
      ruleList={rulesList}
      operatorList={operatorList}
      valuesList={valuesList}
      handleChangeValue={handleChangeValue}
      handleChangeLocation={handleChangeLocation}
      handleChangeValueType={handleChangeValueType}
      handleChangeRule={handleChangeRule}
      handleChangeOperator={handleChangeOperator}
      handleRemoveRule={groupProps.removeRule}
      isRemoveDisabled={isRemoveDisabled}
      errorRule={errors.name}
      errorValue={errors.value}
      errorOperator={errors.operator}
    />
  );
}

export default TargetingRuleContainer;
