// @flow
import type { BaseSimpleErrorsRule } from "./Base";
import type { Errors, JoiError } from "../types/Base";
import Group from "../targetingModels/Group";

export default class TargetingsGroup implements BaseSimpleErrorsRule {
  schema: Object;

  errors: Errors;

  errorKey: string;

  constructor() {
    this.errors = {};
    this.errorKey = "targetings";
  }

  _onError(errors: Array<JoiError>, id: string = ""): Array<JoiError> {
    errors.forEach(() => {
      this.errors = {
        ...this.errors,
        [this.errorKey]: {
          ...this.errors[this.errorKey],
          [id]: {
            message: "Group can't be empty.",
          },
        },
      };
    });
    return errors;
  }

  getErrors(data: Array<any>): Errors {
    data.forEach(({ id, ...group }) => {
      const schema = new Group().getJoiSchemaValidation()
        .error((errors) => this._onError(errors, id));
      schema.validate(group);
    });
    return this.errors;
  }
}
