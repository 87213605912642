// @flow
import * as React from "react";
import {
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import Row from "./Row";
import type { Split } from "./types/SplitsDistribution.types";
import SplitFrequency from "../FrequencySelector";

type Props = {
  period: string,
  periods: Array<string>,
  defaultSplit: string,
  splits: Map<number, Split>,
  errors: ErrorsMapper | {},
  onPeriodChange: (e: string) => void,
  onChangeFrequency: (splitId: string, newFrequency: Array<number>) => void,
  onSelectDefault: (e: any) => void
};

const hasError = (errors: ErrorsMapper | {}, key: string): boolean => Object.keys(errors).includes(key);

const FrequencyBody = (props: Props) => {
  const {
    period,
    periods,
    onPeriodChange,
    defaultSplit,
    splits,
    errors,
    onChangeFrequency,
    onSelectDefault,
  } = props;

  return (
    <>
      <Row title="Period">
        <FormControl
          variant="outlined"
          error={hasError(errors, "frequencyPeriod")}
        >
          <Select
            margin="dense"
            displayEmpty
            value={period}
            onChange={(e) => onPeriodChange(e.target.value)}
            MenuProps={{ "data-testid": "select-menu" }}
            data-testid="period-select"
          >
            <MenuItem value="" disabled>
              <em>Select From List</em>
            </MenuItem>
            {periods.map<React.Element<typeof React.Component>, string>(
              (value) => (
                <MenuItem key={value} name={value} value={value}>
                  {value}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Row>
      <Row title="Splits" multiLayout>
        {Object.entries(splits).map<
          React.Node,
          Array<
            Array<
              | string
              | { name: string, frequency: Array<number>, isActive: boolean }
            >
          >
        >(
          // $FlowFixMe
          ([splitId, { name, frequency, isActive }]) => (
            <SplitFrequency
              key={splitId}
              splitName={name}
              isDefault={defaultSplit === name}
              values={frequency}
              error={hasError(errors, "splitsFrequencyWeights")}
              onChangeValues={(newFrequency) => onChangeFrequency(splitId, newFrequency)}
              onClickDefault={onSelectDefault}
              isActive={isActive}
            />
          )
        )}
      </Row>
    </>
  );
};

export default FrequencyBody;
