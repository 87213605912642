// @flow
import Joi from "@hapi/joi";
import type { Map } from "immutable";
import type { JoiError, Errors } from "../../types/Base";
import WithWeights from "../weights";
import type { Split } from "../../../../reducers/splits";
import type { BaseSimpleErrorsRule } from "../Base";
import {
  WEIGHTS,
} from "../../messages";

export default class SplitsWeights implements BaseSimpleErrorsRule {
  schema: Object;

  errors: Errors;

  errorKey: string;

  constructor() {
    this.errors = {};
    this.errorKey = "splitsWeights";

    this.schema = WithWeights
      .array()
      .items(
        Joi.object({
          weight: Joi
            .number()
            .integer()
            .min(1)
            .max(100),
        }).unknown(true)
      )
      .validateTotalWeight()
      .error((errors) => this._onError(errors));
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach((item) => {
      switch (item.code) {
        case "number.integer":
        case "number.min":
        case "number.max":
        case "array.wrongWeights": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: WEIGHTS },
          };
          break;
        }
        default:
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: item.message },
          };
          break;
      }
    });

    return errors;
  }

  getErrors(data: Map<Split>): Errors {
    this.schema.validate(data.valueSeq().toJS());
    return this.errors;
  }
}
