// @flow
import {
  select,
  call,
  put,
  debounce,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import {
  GET_FUNNELS_TEMPLATES_REPORT_LIST_SAGA,
  FUNNEL_TEMPLATE_REPORT_TABLE,
} from "../../helpers/constants";
import {
  getReportGroupBy,
  getReportColumnsOrder,
} from "../../selectors/templates";
import TemplateApi from "../../services/templateApi";

export function* makeFetch(): Saga<void> {
  yield put(setLoading(FUNNEL_TEMPLATE_REPORT_TABLE, true));

  const groupBy = yield select(getReportGroupBy);

  const page = yield select(getTablePage, FUNNEL_TEMPLATE_REPORT_TABLE);
  const pageSize = yield select(getTablePageSize, FUNNEL_TEMPLATE_REPORT_TABLE);
  const sorting = yield select(getTableSorting, FUNNEL_TEMPLATE_REPORT_TABLE);
  const filters = yield select(getTableFilters, FUNNEL_TEMPLATE_REPORT_TABLE);
  const columns = yield select(getReportColumnsOrder);

  try {
    const { data: response } = yield call(TemplateApi.getFunnelsTemplateReportList, {
      columns,
      groupBy,
      page,
      pageSize,
      sorting,
      filters,
    });

    const { data, count } = response;

    yield put(setTableData(FUNNEL_TEMPLATE_REPORT_TABLE, data));
    yield put(changeTableItemsTotalAmount(FUNNEL_TEMPLATE_REPORT_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
  }
  finally {
    yield put(setLoading(FUNNEL_TEMPLATE_REPORT_TABLE, false));
  }
}

export default function* watchFetchTemplateReportListSaga(): Saga<void> {
  yield debounce(1000, GET_FUNNELS_TEMPLATES_REPORT_LIST_SAGA, makeFetch);
}
