// @flow
import React from "react";
import { connect } from "react-redux";
import type { List } from "immutable";
import { SlideModal } from "@fas/ui-core";
import FilterSettingsList from "../../components/FilterSettingsList";
import { getIsSmartlinkGeneralFiltersModalOpen } from "../../selectors/smartlink";
import { closeGeneralFiltersModalSaga, saveGeneralFiltersModalSaga } from "../../actions/generalFilters/actions";
import { setActiveGeneralFilters } from "../../actions/generalFilters";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  open: boolean,
  filtersInCampaign: List,
  handleClose: () => void,
  handleSave: () => void,
  onActiveChange: (index: number, value: boolean) => void,
|}>;

const mapStateToProps = (state) => ({
  filtersInCampaign: state.generalFilters,
  open: getIsSmartlinkGeneralFiltersModalOpen(state),
});

const mapDispatchToProps = (dispatch) => ({
  onActiveChange: (index, value) => dispatch(setActiveGeneralFilters(index, value)),
  handleClose: () => dispatch(closeGeneralFiltersModalSaga()),
  handleSave: () => dispatch(saveGeneralFiltersModalSaga()),
});

const SmartlinkGeneralFilterModal = ({
  filtersInCampaign,
  open,
  handleClose,
  handleSave,
  onActiveChange,
}: Props) => (
  <SlideModal
    open={open}
    onClose={handleClose}
    title="GENERAL FILTERS"
    content={(
      <FilterSettingsList
        data={filtersInCampaign.toJS()}
        onActiveChange={onActiveChange}
      />
    )}
    onSave={handleSave}
  />
);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SmartlinkGeneralFilterModal);
