// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import { Loader } from "@fas/ui-core";
import Notification from "../../components/Notification";
import configureStore from "./store";
import CampaignApi from "../../services/smartlinkApi";
import Steps from "../../containers/Steps";
import CampaignInfoStep from "../../containers/CampaignInfoStep";
import ServiceOffersStep from "../../containers/ServiceOffersStep";
import SplitsFunnelsStep from "../../containers/SplitsFunnelsStep";
import CampaignPreviewStep from "../../containers/CampaignPreviewStep";

type Props = {
  navigate: Object,
  params: Object,
};

type State = {
  isLoading: boolean
};

const componentsMapper = {
  "1": CampaignInfoStep,
  "2": ServiceOffersStep,
  "3": SplitsFunnelsStep,
  "4": CampaignPreviewStep,
};

class Smartlink extends React.Component<Props, State> {
  campaign: Object;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
    };
    this.campaign = {
      campaignType: "smartlink",
    };
  }

  componentDidMount() {
    const { navigate, params } = this.props;

    if (!params.id) {
      this.setState({ isLoading: false });
      return;
    }

    CampaignApi.getCampaignById(params.id)
      .then(({ data: response }) => {
        this.campaign = response.data;
        this.setState({ isLoading: false });
      })
      .catch(() => navigate("/error"));
  }

  render() {
    const { isLoading } = this.state;
    const { params } = this.props;

    return (
      <DocumentTitle title={params.id ? "Update campaign" : "Create campaign"}>
        <>
          {isLoading && (
            <Loader loading size={35} mt={37} />
          )}
          {!isLoading && (
            <Provider store={configureStore(this.campaign)}>
              <Steps getStepContent={(stepKey) => componentsMapper[stepKey]} />
              <Notification />
            </Provider>
          )}
        </>
      </DocumentTitle>
    );
  }
}

export default Smartlink;
