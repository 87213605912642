// @flow
import {
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { TAB_CLICK } from "../../helpers/constants";
import { getCurrentTabId } from "../../selectors/templates";
import { tabChange } from "../../actions/tabs";

import type { TabAction } from "../../actions/tabs";

export function* tabsClick(action: TabAction): Saga<void> {
  const currentTabId = yield select(getCurrentTabId);
  if (currentTabId === action.id) {
    return;
  }
  yield put(tabChange(action.id));
}

export default function* watchTabsClick(): Saga<void> {
  yield takeEvery(TAB_CLICK, tabsClick);
}
