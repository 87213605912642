// @flow
import {
  put, select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import { setTableData, setTableSelection, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table";
import { SAVE_FALLBACK_OFFER_SAGA, OFFERS_TABLE } from "../../helpers/constants";
import { addFallbackOffer } from "../../actions/fallbackOffers";
import { closeOffersModal } from "../../actions/modals";
import { deleteServiceOffer } from "../../actions/offers";
import { getFallbackOffers, getOffersAllIds } from "../../selectors/smartlink";

export function* saveFallbackOfferSaga(action: *): Saga<void> {
  const allIds = yield select(getOffersAllIds);
  const offerId = getNextId(allIds);
  const oldOffers = yield select(getFallbackOffers);
  let { offer } = action;
  offer = {
    ...offer,
    isActive: true,
  };

  for (let i = 0; i < oldOffers.size; i++) {
    yield put(deleteServiceOffer(oldOffers.get(i)));
  }

  yield put(addFallbackOffer(offerId, offer));

  yield put(closeOffersModal());
  yield put(setTableData(OFFERS_TABLE, []));
  yield put(setTableSelection(OFFERS_TABLE, []));
  yield put(changeTableItemsTotalAmount(OFFERS_TABLE, 0));
}

export default function* watchSaveFallbackOfferSaga(): Saga<void> {
  yield takeEvery(SAVE_FALLBACK_OFFER_SAGA, saveFallbackOfferSaga);
}
