// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  START_FETHING_CAMPAIGN_ID_SAGA,
} from "../../helpers/constants";
import { setCampaignId } from "../../actions/campaignInfo";
import CampaignApi from "../../services/smartlinkApi";
import type { GetCampaignIdSagaAction } from "../../actions/campaignInfo/actions";

export function* fetchCampaignIdSaga({ campaignType }: GetCampaignIdSagaAction): Saga<void> {
  try {
    const { data: { id } } = yield call(CampaignApi.getCampaignId, campaignType);

    yield put(setCampaignId(id));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
  }
}

export default function* watchFetchCampaignId(): Saga<void> {
  yield takeEvery(START_FETHING_CAMPAIGN_ID_SAGA, fetchCampaignIdSaga);
}
