// @flow
import type { Saga } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { FETCH_CAMPAIGN_JSON_SAGA } from "../../helpers/constants";
import { setCampaignJson } from "../../actions/dictionaries";
import { getCampaignJsonById } from "../../services/smartlinkApi/campaign";
import type { FetchCampaignJsonSagaAction } from "../../actions/dictionaries/actions";
import { changeLoadingStatus } from "../../actions/loading";

export function* makeFetch(action: FetchCampaignJsonSagaAction): Saga<void> {
  try {
    yield put(changeLoadingStatus("loadingCampaignJson", true));
    const response = yield call(getCampaignJsonById, action.id);
    yield put(setCampaignJson(response.data.campaign));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
  }
  finally {
    yield put(changeLoadingStatus("loadingCampaignJson", false));
  }
}

export default function* fetchCampaignJsonSaga(): Saga<void> {
  yield takeEvery(FETCH_CAMPAIGN_JSON_SAGA, makeFetch);
}
