// @flow
import {
  call, put, select, takeEvery, all,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { Action } from "redux";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  FETCH_TARGETING_SAGA,
} from "../../helpers/constants";
import {
  setTargetingValues,
  setTargetingRules,
  startFetchTargetingRules,
} from "../../actions/dictionaries";
import CampaignApi from "../../services/smartlinkApi";
import {
  getAllTargetingRuleNames,
  getRootTargetingByFunnelId,
  getTargetingById,
  getTargetingRulesList,
  getTargetingValues,
  getFilteringNamesByFunnelId,
} from "../../selectors/smartlink";

export function* fetchTargetingsSaga(action: Action): Saga<void> {
  const { funnelId } = action;
  let targetingRulesList = yield select(getTargetingRulesList);
  try {
    if (!targetingRulesList.size) {
      yield put(setLoading("loadingTargetingRules", true));
      yield put(startFetchTargetingRules());
      targetingRulesList = yield call(CampaignApi.getTargetingRules);
      yield put(setTargetingRules(targetingRulesList.data.data));
      yield put(setLoading("loadingTargetingRules", false));
    }
  }
  catch (error) {
    addNotification({ message: error.errorMessage, severity: "error" });
    // eslint-disable-next-line
    console.error(error);
  }

  try {
    const rootTargetingGroupId = yield select(getRootTargetingByFunnelId, funnelId);
    const rootTargetingGroup = yield select(getTargetingById, rootTargetingGroupId);
    const allTargetingsRuleNames = yield select(getAllTargetingRuleNames, rootTargetingGroup);
    const allFilteringsRuleNames = yield select(getFilteringNamesByFunnelId, funnelId);

    const targetingValues = yield select(getTargetingValues);
    const valuesList = {};
    yield put(setLoading("loadingTargetingValues", true));
    for (let i = 0; i < allTargetingsRuleNames.size; i++) {
      const ruleName = allTargetingsRuleNames.get(i);
      if (!targetingValues.get(ruleName)) {
        valuesList[ruleName] = call(CampaignApi.getValuesForTargeting, ruleName);
      }
    }
    for (let i = 0; i < allFilteringsRuleNames.length; i++) {
      const ruleName = allFilteringsRuleNames[i];
      if (!targetingValues.get(ruleName)) {
        valuesList[ruleName] = call(CampaignApi.getValuesForTargeting, ruleName);
      }
    }
    const apiValuesList = yield all(valuesList);

    const mappedApiValuesList = {};

    Object.keys(apiValuesList).forEach((key) => {
      mappedApiValuesList[key] = apiValuesList[key].data.data[0].values;
    });

    yield put(setTargetingValues({ data: mappedApiValuesList }));
    yield put(setLoading("loadingTargetingValues", false));
  }
  catch (error) {
    yield put(setLoading("loadingTargetingRules", false));
    yield put(setLoading("loadingTargetingValues", false));
    addNotification({ message: error.errorMessage, severity: "error" });
    // eslint-disable-next-line
    console.error(error);
  }
}

export default function* watchFetchTargetingsSaga(): Saga<void> {
  yield takeEvery(FETCH_TARGETING_SAGA, fetchTargetingsSaga);
}
