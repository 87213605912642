// @flow
import {
  takeEvery, put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  funnelTemplateListModalOpen,
  funnelTemplateListModalSetSplitId,
  funnelTemplateListModalSetTemplateId,

} from "../../actions/modals";
import type {
  FunnelTemplateListModalOpenSaga,
} from "../../actions/modals";
import {
  OPEN_FUNNELTEMPLATE_LIST_SAGA,
} from "../../helpers/constants";

export function* openFunnelTemplateListSaga(
  action: FunnelTemplateListModalOpenSaga
): Saga<void> {
  const { splitId, templateId } = action;
  yield put(funnelTemplateListModalSetSplitId(splitId));
  yield put(funnelTemplateListModalSetTemplateId(templateId));
  yield put(funnelTemplateListModalOpen());
}

export default function* watchTemplateListSaga(): Saga<void> {
  yield takeEvery(OPEN_FUNNELTEMPLATE_LIST_SAGA, openFunnelTemplateListSaga);
}
