// @flow
import {
  Record,
  Map,
  List,
  fromJS,
} from "immutable";
import type {
  RecordOf,
  RecordFactory,
  Map as MapType,
  List as ListType,
} from "immutable";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import type { DropdownItem } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import {
  SET_ANTIFRAUD_TRIGGER,
  SET_ANTIFRAUD_TRIGGER_FORM_FIELD,
  ADD_ANTIFRAUD_CONDITION,
  ADD_ANTIFRAUD_CONDITION_GROUP,
  CHANGE_ANTIFRAUD_CONDITION_GROUP_OPERATOR,
  DELETE_ANTIFRAUD_CONDITION_RULE,
  DELETE_ANTIFRAUD_CONDITION_GROUP,
  CHANGE_ANTIFRAUD_CONDITION_RULE,
  CHANGE_ANTIFRAUD_CONDITION_OPERATOR,
  CHANGE_ANTIFRAUD_CONDITION_LOCATION,
  CHANGE_ANTIFRAUD_CONDITION_VALUE,
  CHANGE_ANTIFRAUD_CONDITION_VALUE_TYPE,
  TRIGGER_VALUE_TYPE,
  SET_CONDITION_LISTS,
} from "../../helpers/constants";
import type {
  Actions,
  SetAntifraudTrigger,
  SetAntifraudTriggerFormField,
  AddAntifraudCondition,
  AddAntifraudConditionGroup,
  ChangeAntifraudConditionGroupOperator,
  DeleteAntifraudConditionRule,
  DeleteAntifraudConditionGroup,
  ChangeAntifraudConditionRule,
  ChangeAntifraudConditionOperator,
  ChangeAntifraudConditionLocation,
  ChangeAntifraudConditionValue,
  ChangeAntifraudConditionValueType,
  SetConditionLists,
} from "../../actions/antifraudTriggerForm";
import type { Trigger, RuleTriggerQb, TriggerValueType } from "../../selectors/antifraudTrigger/types";

export type DefaultConditionsState = MapType<string, MapType<string, mixed>>;

export const defaultConditionsState: DefaultConditionsState = Map({
  "1": Map({
    type: "group",
    groupOperator: "AND",
    children: List([]),
    parentId: null,
  }),
});

type DefaultState = {
  ruleId?: number,
  name: string,
  description: string,
  scoreType: TriggerValueType,
  score: number,
  location: string | null,
  conditions: MapType<string, MapType<string, mixed>>,
  isActive: boolean,
  triggerRules: List<RuleTriggerQb>,
  triggerValues: List<DropdownItem<string>>,
}

const defaultState: DefaultState = {
  ruleId: 0,
  name: "",
  description: "",
  scoreType: "value",
  location: null,
  score: 0,
  conditions: defaultConditionsState,
  isActive: false,
  triggerRules: List(),
  triggerValues: List(),
};

export type State = RecordOf<DefaultState>;

export const makeState: RecordFactory<DefaultState> = Record(defaultState);

export const initialState: State = makeState({});

export function initAntifraudTriggerFormState(): State {
  return initialState;
}

/* eslint-disable complexity */
export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_ANTIFRAUD_TRIGGER: {
      const { payload }: SetAntifraudTrigger = action;
      const {
        ruleId,
        name,
        description,
        conditions,
        score,
        scoreType,
        location,
        isActive,
      }: Trigger = payload;

      return state.withMutations((newState: State) => {
        newState.set("ruleId", ruleId);
        newState.set("name", name);
        newState.set("description", description);
        newState.set("score", score);
        newState.set("scoreType", scoreType);
        newState.set("location", location);
        newState.set("isActive", isActive);
        newState.set("conditions", Object.keys(conditions).length > 1 ? fromJS(conditions) : defaultConditionsState);
      });
    }
    case SET_ANTIFRAUD_TRIGGER_FORM_FIELD: {
      const { payload }: SetAntifraudTriggerFormField = action;

      return state.merge(payload);
    }
    case ADD_ANTIFRAUD_CONDITION: {
      const { groupId }: AddAntifraudCondition = action;
      return state.withMutations((newState: State) => {
        const newId: string = getNextId(newState.getIn(["conditions"]).keySeq());
        newState.setIn(["conditions", newId], Map({
          operator: "",
          name: "",
          value: "",
          valueType: TRIGGER_VALUE_TYPE,
          parentId: groupId,
          location: "data",
          type: "rule",
        }));
        newState.updateIn(["conditions", groupId, "children"], (conditions: ListType<string>): ListType<string> => List([...conditions, newId]));
      });
    }
    case ADD_ANTIFRAUD_CONDITION_GROUP: {
      const { groupId }: AddAntifraudConditionGroup = action;
      return state.withMutations((newState: State) => {
        const newId: string = getNextId(newState.getIn(["conditions"]).keySeq());
        newState.setIn(["conditions", newId], Map({
          groupOperator: "AND",
          parentId: groupId,
          type: "group",
          children: List([]),
        }));
        newState.updateIn(["conditions", groupId, "children"], (conditions: ListType<string>): ListType<string> => List([...conditions, newId]));
      });
    }
    case CHANGE_ANTIFRAUD_CONDITION_GROUP_OPERATOR: {
      const { id, value }: ChangeAntifraudConditionGroupOperator = action;
      return state.setIn(["conditions", id, "groupOperator"], value);
    }
    case DELETE_ANTIFRAUD_CONDITION_RULE: {
      const { id }: DeleteAntifraudConditionRule = action;
      return state.withMutations((newState: State) => {
        const parentId: string = newState.getIn(["conditions", id, "parentId"]);
        newState.deleteIn(["conditions", id]);
        newState.updateIn(["conditions", parentId, "children"], (rules: ListType<string>): ListType<string> => rules.filter((conditionId: string): boolean => conditionId !== id));
      });
    }
    case DELETE_ANTIFRAUD_CONDITION_GROUP: {
      const { id }: DeleteAntifraudConditionGroup = action;
      return state.withMutations((newState: State) => {
        const parentId: string = newState.getIn(["conditions", id, "parentId"]);
        const rulesList: ListType<string> = newState.getIn(["conditions", id, "children"]);

        rulesList.forEach((ruleId: string) => {
          newState.deleteIn(["conditions", ruleId]);
        });

        newState.deleteIn(["conditions", id]);
        newState.updateIn(["conditions", parentId, "children"], (rules: ListType<string>): ListType<string> => rules.filter((conditionId: string): boolean => conditionId !== id));
      });
    }
    case CHANGE_ANTIFRAUD_CONDITION_RULE: {
      const { id, rule }: ChangeAntifraudConditionRule = action;
      return state.withMutations((newState: State) => {
        newState.setIn(["conditions", id, "name"], rule.value || "");
        newState.setIn(["conditions", id, "operator"], "");
        newState.setIn(["conditions", id, "value"], "");
      });
    }
    case CHANGE_ANTIFRAUD_CONDITION_OPERATOR: {
      const { id, operator }: ChangeAntifraudConditionOperator = action;
      return state.setIn(["conditions", id, "operator"], operator);
    }
    case CHANGE_ANTIFRAUD_CONDITION_VALUE: {
      const { id, value }: ChangeAntifraudConditionValue = action;
      return state.setIn(["conditions", id, "value"], value);
    }
    case CHANGE_ANTIFRAUD_CONDITION_VALUE_TYPE: {
      const { payload: { id, type } }: ChangeAntifraudConditionValueType = action;
      return state.withMutations((newState: State) => {
        newState.setIn(["conditions", id, "valueType"], type);
        newState.setIn(["conditions", id, "value"], "");
      });
    }
    case SET_CONDITION_LISTS: {
      const { payload: { triggerRules, triggerValues } }: SetConditionLists = action;
      return state.withMutations((newState: State) => {
        newState.set("triggerRules", List(triggerRules));
        newState.set("triggerValues", List(triggerValues));
      });
    }
    case CHANGE_ANTIFRAUD_CONDITION_LOCATION: {
      const { id, location }: ChangeAntifraudConditionLocation = action;
      return state.setIn(["conditions", id, "location"], location);
    }
    default:
      return state;
  }
};
