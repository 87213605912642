// @flow
import {
  CLOSE_FUNNEL_MODAL,
  CLOSE_GENERAL_FILTERS_MODAL,
  CLOSE_OFFERS_MODAL,
  CLOSE_SPLIT_MODAL,
  OPEN_FUNNEL_MODAL,
  OPEN_GENERAL_FILTERS_MODAL,
  OPEN_OFFERS_MODAL,
  OPEN_SPLIT_MODAL,
  SET_CURRENT_FUNNEL,
  SET_CURRENT_SPLIT,
  FUNNELTEMPLATE_LIST_MODAL_OPEN,
  FUNNELTEMPLATE_LIST_MODAL_CLOSE,
  OPEN_FUNNELTEMPLATE_LIST_SAGA,
  FUNNELTEMPLATE_LIST_MODAL_SET_SPLIT_ID,
  FUNNELTEMPLATE_LIST_SET_COUNT,
  FUNNELTEMPLATE_LIST_MODAL_SET_TEMPLATE_ID,
} from "../../helpers/constants";

export type OpenFunnelModalAction = {
  type: typeof OPEN_FUNNEL_MODAL,
};

export type CloseFunnelModalAction = {
  type: typeof CLOSE_FUNNEL_MODAL,
};

export type SetCurrentFunnelAction = {
  type: typeof SET_CURRENT_FUNNEL,
  funnelId: string,
};

export type OpenOffersModalAction = {
  type: typeof OPEN_OFFERS_MODAL,
};

export type CloseOffersModalAction = {
  type: typeof CLOSE_OFFERS_MODAL,
};

export type OpenGeneralFiltersModalAction = {
  type: typeof OPEN_GENERAL_FILTERS_MODAL,
};

export type CloseGeneralFiltersModalAction = {
  type: typeof CLOSE_GENERAL_FILTERS_MODAL,
};

export type OpenSplitModalAction = {
  type: typeof OPEN_SPLIT_MODAL,
};

export type CloseSplitModalAction = {
  type: typeof CLOSE_SPLIT_MODAL,
};

export type SetCurrentSplitAction = {
  type: typeof SET_CURRENT_SPLIT,
  splitId: string,
};

export type FunnelTemplateSetCount = {
  type: typeof FUNNELTEMPLATE_LIST_SET_COUNT,
  count: number,
};

export type OpenFunnelTemplateModalType = {|
  type: typeof FUNNELTEMPLATE_LIST_MODAL_OPEN,
|};

export type CloseFunnelTemplateModalType = {|
  type: typeof FUNNELTEMPLATE_LIST_MODAL_CLOSE,
|};

export type FunnelTemplateListModalOpenSaga = {|
  type: typeof OPEN_FUNNELTEMPLATE_LIST_SAGA,
  splitId: string,
  templateId: string,
|};

export type FunnelTemplateListModalSetSplitId = {|
  type: typeof FUNNELTEMPLATE_LIST_MODAL_SET_SPLIT_ID,
  splitId: ?string,
|};

export type FunnelTemplateListModalSetTemplateId = {|
  type: typeof FUNNELTEMPLATE_LIST_MODAL_SET_TEMPLATE_ID,
  templateId: string,
|};

export type ModalsActionType =
  | OpenFunnelModalAction
  | CloseFunnelModalAction
  | SetCurrentFunnelAction
  | OpenOffersModalAction
  | CloseOffersModalAction
  | OpenSplitModalAction
  | CloseSplitModalAction
  | SetCurrentSplitAction
  | OpenGeneralFiltersModalAction
  | OpenFunnelTemplateModalType
  | CloseFunnelTemplateModalType
  | FunnelTemplateListModalOpenSaga
  | FunnelTemplateListModalSetSplitId
  | FunnelTemplateListModalSetTemplateId
  | FunnelTemplateSetCount
  | CloseGeneralFiltersModalAction;

export const openSplitModal = (): OpenSplitModalAction => ({
  type: OPEN_SPLIT_MODAL,
});

export const closeSplitModal = (): CloseSplitModalAction => ({
  type: CLOSE_SPLIT_MODAL,
});

export const setCurrentSplit = (splitId: string): SetCurrentSplitAction => ({
  type: SET_CURRENT_SPLIT,
  splitId,
});

export const openFunnelModal = (): OpenFunnelModalAction => ({
  type: OPEN_FUNNEL_MODAL,
});

export const closeFunnelModal = (): CloseFunnelModalAction => ({
  type: CLOSE_FUNNEL_MODAL,
});

export const setCurrentFunnel = (funnelId: string): SetCurrentFunnelAction => ({
  type: SET_CURRENT_FUNNEL,
  funnelId,
});

export const openOffersModal = (): OpenOffersModalAction => ({
  type: OPEN_OFFERS_MODAL,
});

export const closeOffersModal = (): CloseOffersModalAction => ({
  type: CLOSE_OFFERS_MODAL,
});

/**
 * Open Smartlink general filter modal
 * @returns {OpenGeneralFiltersModalAction} action
 */
export const openGeneralFiltersModal = (): OpenGeneralFiltersModalAction => ({
  type: OPEN_GENERAL_FILTERS_MODAL,
});

/**
 * Close Smartlink general filter modal
 * @returns {CloseGeneralFiltersModalAction} action
 */
export const closeGeneralFiltersModal = (): CloseGeneralFiltersModalAction => ({
  type: CLOSE_GENERAL_FILTERS_MODAL,
});

export const funnelTemplateListModalOpen = (): OpenFunnelTemplateModalType => ({
  type: FUNNELTEMPLATE_LIST_MODAL_OPEN,
});

export const funnelTemplateListModalClose = (): CloseFunnelTemplateModalType => ({
  type: FUNNELTEMPLATE_LIST_MODAL_CLOSE,
});

export const funnelTemplateListModalOpenSaga = (
  splitId: string,
  templateId: string
): FunnelTemplateListModalOpenSaga => ({
  type: OPEN_FUNNELTEMPLATE_LIST_SAGA,
  splitId,
  templateId,
});

export const funnelTemplateListModalSetSplitId = (
  splitId: ?string
): FunnelTemplateListModalSetSplitId => ({
  type: FUNNELTEMPLATE_LIST_MODAL_SET_SPLIT_ID,
  splitId,
});

export const funnelTemplateListModalSetTemplateId = (
  templateId: string
): FunnelTemplateListModalSetTemplateId => ({
  type: FUNNELTEMPLATE_LIST_MODAL_SET_TEMPLATE_ID,
  templateId,
});

export const funnelTemplateSetCount = (
  count: number
): FunnelTemplateSetCount => ({
  type: FUNNELTEMPLATE_LIST_SET_COUNT,
  count,
});
