// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Typography, IconButton, Switch, Box, Grid, Select, FormControl,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Highlighter from "react-highlight-words";
import { swap } from "../../helpers/dndUtil";
import FunnelUrl from "./FunnelUrl";

/* eslint-disable react/require-default-props */
export type Props<T> = {
  id: string,
  title: string,
  text: string,
  hits: string,
  order?: Array<T>,
  orderPosition?: number,
  searchText?: string,
  isHighlight?: boolean,
  countTargetings: number,
  countOffers: number,
  countFilterings: number,
  isActive: boolean,
  isEditing?: boolean,
  isClonable?: boolean,
  url?: string,
  onChangeIsActive?: () => void,
  onClone?: () => void,
  onDelete?: () => void,
  onShow?: () => void,
  onChangeOrder?: (newOrder: Array<T>) => void,
  DragHandleComponent?: StatelessFunctionalComponent<Object>,
}

const styles = makeStyles((theme) => (
  {
    root: {
      border: `1px solid ${theme.palette.borderColor}`,
      backgroundColor: theme.palette.background.blueBackgroundLight,
      borderRadius: theme.spacing(1),
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      marginBottom: theme.spacing(1),
    },
    highlightClassName: {
      backgroundColor: "#ff9632",
    },
  }
));

// eslint-disable-next-line complexity
const FunnelItem = (
  {
    id,
    title,
    text,
    order,
    hits,
    orderPosition,
    isActive,
    onChangeIsActive,
    onDelete,
    onClone,
    onShow,
    onChangeOrder,
    isEditing,
    isClonable,
    url,
    countTargetings,
    countOffers,
    countFilterings,
    DragHandleComponent,
    searchText,
    isHighlight,
  }: Props<string>
) => {
  const classes = styles();

  return (
    <Grid container classes={{ root: classes.root }} data-testid="funnel-item">
      {(!DragHandleComponent && !onChangeIsActive) && (
        <Grid item xs={1}>
          <Typography noWrap data-testid="id">
            {id}
          </Typography>
        </Grid>
      )}
      {(DragHandleComponent || onChangeIsActive) && (
        <Grid item xs={1}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {DragHandleComponent && <DragHandleComponent />}
            {onChangeIsActive && (
              <Switch
                disabled={!isEditing}
                color="primary"
                checked={isActive}
                onClick={onChangeIsActive}
                data-testid="is-active-switch"
                title="Is active"
              />
            )}
          </Box>
        </Grid>
      )}
      <Grid item xs={DragHandleComponent || onChangeIsActive ? 4 : 3}>
        <Typography
          noWrap
          color="textSecondary"
          data-testid="funnel-name"
          title={title}
        >
          <Highlighter
            autoEscape
            highlightClassName={isHighlight ? classes.highlightClassName : ""}
            searchWords={[searchText]}
            textToHighlight={title}
          />
        </Typography>
        <Typography
          noWrap
          data-testid="funnel-params"
          title={text}
        >
          <Highlighter
            autoEscape
            highlightClassName={isHighlight ? classes.highlightClassName : ""}
            searchWords={[searchText]}
            textToHighlight={text}
          />
        </Typography>
      </Grid>

      <Grid item xs={1}>
        <Typography
          noWrap
          data-testid="title"
          title="Targetings"
        >
          {countTargetings}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography
          noWrap
          data-testid="title"
          title="Offers"
        >
          {countOffers}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography
          noWrap
          data-testid="title"
          title="Filtering by email"
        >
          {countFilterings}
        </Typography>
      </Grid>
      {(!DragHandleComponent && !onChangeIsActive) && (
        <>
          <Grid item xs={1}>
            <Typography
              noWrap
              data-testid="isActive"
              title="isActive"
              sx={{ color: (isActive) ? "success.main" : "error.main" }}
            >
              {(isActive) ? "is Active" : "Not active"}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              noWrap
              data-testid="hits"
              title="hits"
            >
              {hits}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={url ? 4 : 3}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {onChangeOrder && order && orderPosition !== undefined && (
            <FormControl variant="outlined" size="small">
              <Select
                native
                value={String(orderPosition)}
                onChange={({ target: { value: newValue } }) => onChangeOrder(swap(order, orderPosition, newValue))}
              >
                {order.map((item, index) => <option key={item} value={index}>{ (index + 1) }</option>)}
              </Select>
            </FormControl>
          )}
          {isEditing && (
            <IconButton
              onClick={onDelete}
              data-testid="delete-button"
              title="Delete"
            >
              <DeleteOutlineIcon color="action" />
            </IconButton>
          )}

          {isClonable && (
            <IconButton onClick={onClone} data-testid="clone-button" title="Clone">
              <FileCopyOutlinedIcon color="action" />
            </IconButton>
          )}
          {url && (
            <FunnelUrl url={url} />
          )}
          <IconButton
            onClick={onShow}
            data-testid="show-button"
            title="Edit"
            disabled={!isActive}
          >
            <KeyboardArrowRightIcon color={isActive ? "primary" : "action"} />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FunnelItem;
