// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import SmartlinkAggregatorsList from "../../containers/SmartlinkAggregatorsList";

function SmartlinkAggregators() {
  return (
    <DocumentTitle title="Smartlink Aggregators">
      <SmartlinkAggregatorsList />
    </DocumentTitle>
  );
}

export default SmartlinkAggregators;
