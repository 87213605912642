// @flow
import type { List } from "immutable";
import type { Errors, JoiError } from "../types/Base";
import TargetingFactory from "../targetingModels";

import Targetings from "./targeting";

export default class Filtering extends Targetings {
  constructor(targetingsList: List) {
    super(targetingsList);
    this.errorKey = "filterings";
  }

  _onError(errors: Array<JoiError>, ruleName?: string = "filtering"): Array<JoiError> {
    errors.forEach((item) => {
      switch (item.code) {
        case "array.min": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: `${ruleName} value can't be empty.` },
          };
          break;
        }
        case "string.empty": {
          if (item.local.key === "id") {
            this.errors = {
              ...this.errors,
              [this.errorKey]: { message: `Define offer for ${ruleName} rule.` },
            };
          }
          else {
            this.errors = {
              ...this.errors,
              [this.errorKey]: { message: "Define filtering rule, value and offer." },
            };
          }
          break;
        }
        case "any.required": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: "Define filtering rule, value and offer." },
          };
          break;
        }
        case "string.pattern.name":
        case "any.custom": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: `Wrong value format for filtering ${ruleName}` },
          };
          break;
        }
        default:
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: `Filtering ${ruleName} isn't valid.` },
          };
          break;
      }
    });
    return errors;
  }

  _onErrorV2(errors: Array<JoiError>, ruleName?: string = "filtering", id: string): Array<JoiError> {
    errors.forEach((item) => {
      switch (item.code) {
        case "array.min": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: {
              ...this.errors[this.errorKey],
              [id]: {
                ...(this.errors[this.errorKey] && this.errors[this.errorKey][id]),
                errorValue: {
                  message: `${ruleName} value can't be empty.`,
                },
              },
            },
          };
          break;
        }
        case "string.empty": {
          if (item.local.key === "id") {
            this.errors = {
              ...this.errors,
              [this.errorKey]: {
                ...this.errors[this.errorKey],
                [id]: {
                  ...(this.errors[this.errorKey] && this.errors[this.errorKey][id]),
                  errorUrl: {
                    message: `Define offer for ${ruleName} rule.`,
                  },
                },
              },
            };
          }
          else {
            this.errors = {
              ...this.errors,
              [this.errorKey]: {
                ...this.errors[this.errorKey],
                [id]: {
                  ...(this.errors[this.errorKey] && this.errors[this.errorKey][id]),
                  errorUrl: {
                    message: "Define offer.",
                  },
                  errorRule: {
                    message: "Define rule.",
                  },
                  errorValue: {
                    message: "Define value.",
                  },
                },
              },
            };
          }
          break;
        }
        case "any.required": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: {
              ...this.errors[this.errorKey],
              [id]: {
                ...(this.errors[this.errorKey] && this.errors[this.errorKey][id]),
                errorUrl: {
                  message: "Define offer.",
                },
                errorRule: {
                  message: "Define rule.",
                },
                errorValue: {
                  message: "Define value.",
                },
              },
            },
          };
          break;
        }
        case "string.pattern.name":
        case "any.custom": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: {
              ...this.errors[this.errorKey],
              [id]: {
                ...(this.errors[this.errorKey] && this.errors[this.errorKey][id]),
                errorValue: {
                  message: `Wrong value format for filtering ${ruleName}`,
                },
              },
            },
          };
          break;
        }
        default:
          this.errors = {
            ...this.errors,
            [this.errorKey]: {
              ...this.errors[this.errorKey],
              [id]: {
                ...(this.errors[this.errorKey] && this.errors[this.errorKey][id]),
                errorRule: {
                  message: `Filtering ${ruleName} isn't valid.`,
                },
              },
            },
          };
          break;
      }
    });
    return errors;
  }

  getErrors(data: Array<any>): Errors {
    data.forEach((rule) => {
      const schema = TargetingFactory(rule, true).getJoiSchemaValidation()
        .error((errors) => {
          const ruleFromDic = this.dictionary.find((item) => item.name.value === rule.name);
          return this._onError(errors, ruleFromDic ? ruleFromDic.name.label : "");
        });
      schema.validate(rule);
    });
    return this.errors;
  }

  getErrorsV2(data: Array<any>): Errors {
    data.forEach(({ id, ...rule }) => {
      const schema = TargetingFactory(rule, true).getJoiSchemaValidation()
        .error((errors) => {
          const ruleFromDic = this.dictionary.find((item) => item.name.value === rule.name);
          return this._onErrorV2(errors, ruleFromDic ? ruleFromDic.name.label : "", id);
        });
      schema.validate(rule);
    });
    return this.errors;
  }
}
