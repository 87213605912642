import {
  takeEvery,
  select,
  put,
} from "redux-saga/effects";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import {
  OPEN_FUNNEL_SAGA,
} from "../../helpers/constants";
import { setBackup } from "../../actions/backup";
import { addFunnelSaga } from "../addFunnelSaga/addFunnelSaga";
import { openFunnelModal, setCurrentFunnel } from "../../actions/modals";
import { fetchTargetingsSaga } from "../fetchTargetingsSaga/fetchTargetingsSaga";
import {
  isFunnelExists,
  getFunnelsAllIds,
} from "../../selectors/smartlink";

export function* openFunnelSaga(action) {
  const { splitId, funnelId } = action;

  const funnelsAllIds = yield select(getFunnelsAllIds);

  const backup = [
    "splits",
    "funnels",
    "targetings",
    "filterings",
    "offers",
    "actionOptionsParams",
    "errors",
  ];

  yield put(setBackup(backup));

  const isExists = yield select(isFunnelExists, funnelId);
  const currentFunnel = funnelId || getNextId(funnelsAllIds);

  if (!isExists) {
    yield* addFunnelSaga({ splitId });
  }

  yield put(setCurrentFunnel(currentFunnel));
  yield put(openFunnelModal());
  yield* fetchTargetingsSaga({ funnelId: currentFunnel });
}

export default function* watchOpenFunnel() {
  yield takeEvery(OPEN_FUNNEL_SAGA, openFunnelSaga);
}
