/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import { SetupCard, SlideModal } from "@fas/ui-core";
import { connect } from "react-redux";
import { Map } from "immutable";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FunnelHeader from "../../components/FunnelHeader";
import type { Funnel } from "../../reducers/funnels";
import {
  getFunnelById,
  getCurrentFunnel,
  getIsFunnelModalOpen,
  getIsModalContentDisabled,
} from "../../selectors/smartlink";
import {
  changeName,
  saveFunnelSaga,
  closeFunnelSaga,
} from "../../actions/funnels";
import Offers from "../Offers";
import Targeting from "../Targeting";
import Filterings from "../Filterings/FilteringsV2";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  open: boolean,
  funnelId: string,
  funnel: Map<Funnel>,
  handleNameChange: (funnelId: string, name: string) => void,
  handleSaveFunnel: (funnelId: string, targetingSchema: any) => void,
  handleCloseFunnel: () => void,
  isFieldsDisabled: boolean,
|}>;

type SlideModalProps = {
  open?: boolean,
  onClose: (...args: Array<any>) => any,
  onSave?: (...args: Array<any>) => any,
  title?: string,
  header?: Node,
  content?: Node,
  actions?: Node,
  width?: string,
  maxWidth?: string,
  isSaveDisabled?: boolean,
}
// $FlowFixMe
const ForWardedSlideModal = React.forwardRef<SlideModalProps, SlideModal>((props, ref) => (
  <SlideModal {...props} ref={ref} />
));

const mapStateToProps = (state) => {
  const funnelId = getCurrentFunnel(state);
  const funnel = getFunnelById(state, funnelId);
  const open = getIsFunnelModalOpen(state);
  const isFieldsDisabled = getIsModalContentDisabled(state);

  return {
    funnelId,
    funnel,
    open,
    isFieldsDisabled,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleNameChange: (funnelId, name) => dispatch(changeName(funnelId, name)),
  handleSaveFunnel: (funnelId) => dispatch(saveFunnelSaga(funnelId)),
  handleCloseFunnel: () => dispatch(closeFunnelSaga()),
});

const useStyles = makeStyles({
  disablingContentClass: {
    opacity: 0.7,
    pointerEvents: "none",
  },
});

const SmartlinkFunnel: StatelessFunctionalComponent<Props> = ({
  funnelId,
  funnel,
  open,
  handleNameChange,
  handleSaveFunnel,
  handleCloseFunnel,
  isFieldsDisabled,
}: Props) => {
  const {
    name,
    offers: offersIds,
  } = funnel.toJS();
  const classes = useStyles();

  return (
    <ForWardedSlideModal
      open={open}
      onClose={handleCloseFunnel}
      dataTestid="funnel-modal"
      header={(
        <FunnelHeader
          name={name}
          onNameChange={(newName) => handleNameChange(funnelId, newName)}
          onClose={handleCloseFunnel}
          isFieldsDisabled={isFieldsDisabled}
        />
      )}
      content={(
        <Box className={isFieldsDisabled ? classes.disablingContentClass : ""}>
          {open && (
            <SetupCard
              title="Targeting"
              mainBtnText="add filtering"
              onSecondaryBtnClick={() => {}}
              showSecondaryBtn={false}
              secondaryBtnText=""
            >
              <Targeting
                funnelId={funnelId}
              />
            </SetupCard>
          )}

          {open && <Filterings funnelId={funnelId} />}

          <Offers funnelId={funnelId} offersIds={offersIds} />
        </Box>
      )}
      onSave={() => handleSaveFunnel(funnelId)}
      isSaveDisabled={isFieldsDisabled}
    />
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SmartlinkFunnel);
