// @flow
import {
  TAB_CLICK,
  TAB_CHANGE,
  TAB_INIT,
} from "../../helpers/constants";

export type TabAction = {
  type: typeof TAB_CLICK | typeof TAB_CHANGE,
  id: string,
};

export type TabInitAction = {
  type: typeof TAB_INIT,
  urlParam: string,
};

export type TabActions = TabAction | TabInitAction;

export const tabClick = (id: string): TabAction => ({ type: TAB_CLICK, id });

export const tabChange = (id: string): TabAction => ({ type: TAB_CHANGE, id });

export const tabInit = (urlParam: string): TabInitAction => ({ type: TAB_INIT, urlParam });
