// @flow
import environment from "environment";
import { requestService } from "../request";
import { type DictionaryParams } from "../../hooks/useDictionary";

// eslint-disable-next-line import/prefer-default-export
export function getDropdownList({ dictionary, type, ...params }: DictionaryParams): Promise<*> {
  return requestService
    .get(environment.endpoints.get.getFrodiList
      .replace("{dictionary}", dictionary)
      .replace("{type}", type),
    { params })
    .then(({ data }) => data);
}
