// @flow
import { CHANGE_LOADING_STATUS } from "../../helpers/constants";

export type ChangeLoadingStatusType = {
  type: typeof CHANGE_LOADING_STATUS,
  key: string,
  value: boolean,
};

export const changeLoadingStatus = (
  key: string,
  value: boolean
): ChangeLoadingStatusType => ({
  type: CHANGE_LOADING_STATUS,
  key,
  value,
});

export default changeLoadingStatus;
