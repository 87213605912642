// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import stepsGeneralFilterSaga from "../../sagas/stepsGeneralFilterSaga";
import fetchTargetingGfSaga from "../../sagas/fetchTargetingGfSaga";
import fetchTargetingValuesSaga from "../../sagas/fetchTargetingValuesSaga";
import removeGfGroup from "../../sagas/removeGfGroup";
import saveGfTargetingSaga from "../../sagas/saveGfTargetingSaga";
import fetchCampignsSaga from "../../sagas/getGeneralFilterCampaigns";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(stepsGeneralFilterSaga),
    call(fetchTargetingGfSaga),
    call(fetchTargetingValuesSaga),
    call(removeGfGroup),
    call(saveGfTargetingSaga),
    call(fetchCampignsSaga),
  ]);
}
