// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Typography, Box, Grid,
} from "@mui/material";
import { Multiselect, SetupCard } from "@fas/ui-core";
import { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import environment from "environment";

import type { Props } from "./types/CampaignPreview.types";
import PlaceholderValue from "../PlaceholdersList/PlaceholderValue";
import CampaignUrlBuilder from "../CampaignUrlBuilder";

const useStyles = makeStyles({
  linkClass: {
    wordBreak: "break-all",
  },
});

const urlPreview = `${environment.baseUrl}/unitedReborn/campaign/view?id=`;

const CampaignPreview: StatelessFunctionalComponent<Props> = ({
  campaignName,
  campaignExternalName,
  placeholders,
  campaignId,
  domain,
  path,
  protocol,
  nonTarget,
  fallback,
  tags,
  handleAddManualTag,
  handleRemoveManualTag,
  shortUrlId,
  shortUrlTime,
}: Props) => {
  const classes = useStyles();
  const preparedTags = tags.map((tag) => ({ label: tag, value: tag }));
  const placeholdersList: Array<string> = Object.keys(placeholders);
  return (
    <SetupCard title="Campaign preview">
      <Box width="100%" my={1} pb={2}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography>Id:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{campaignId}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>Name:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{campaignName}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>ExternalName:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography>{campaignExternalName}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>URL:</Typography>
          </Grid>
          <Grid container item xs={8}>
            <CampaignUrlBuilder
              campaignId={campaignId}
              domain={domain}
              protocol={protocol}
              placeholders={placeholders}
              path={path}
              shortUrlId={shortUrlId}
              shortUrlTime={shortUrlTime}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography>Placeholders:</Typography>
          </Grid>
          <Grid container item xs={8}>
            {placeholdersList.map((placeholder) => (
              <Box key={placeholder} mr={1} mb={1} component="div">
                <PlaceholderValue
                  name={placeholder}
                  disabled
                  value={placeholders[placeholder]}
                />
              </Box>
            ))}
          </Grid>
          <Grid item xs={4}>
            <Typography>Non Target Funnel:</Typography>
          </Grid>
          <Grid item xs={8}>
            {nonTarget.map(({ id }) => (
              <Box key={id} mb={1}>
                <Link
                  href={`${urlPreview}${id}`}
                  variant="body1"
                  target="_blank"
                  className={classes.linkClass}
                  underline="hover"
                >
                  {id}
                </Link>
              </Box>
            ))}
          </Grid>
          <Grid item xs={4}>
            <Typography>Fallback Offer:</Typography>
          </Grid>
          <Grid item xs={8}>
            {fallback.map(({ id }) => (
              <Box key={id} mb={1}>
                <Link
                  href={`${urlPreview}${id}`}
                  variant="body1"
                  target="_blank"
                  className={classes.linkClass}
                  underline="hover"
                >
                  {id}
                </Link>
              </Box>
            ))}
          </Grid>
          <Grid item xs={4}>
            <Typography>Add tags:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Multiselect
              type="manualMultiple"
              options={[]}
              selectedOptions={preparedTags}
              onAddValue={handleAddManualTag}
              onRemoveValue={handleRemoveManualTag}
              dataTestId="tags-multi-select"
            />
          </Grid>
        </Grid>
      </Box>
    </SetupCard>
  );
};

export default CampaignPreview;
