// @flow
import Joi from "@hapi/joi";
import BaseTargeting from "./Base";

class GlobalFrequency extends BaseTargeting {
  getJoiSchemaValidation() {
    return Joi.object({
      name: Joi.string(),
      operator: Joi.string(),
      type: Joi.string(),
      parentId: Joi.number(),
      value: Joi.array().min(1).when("operator", {
        is: Joi.string().valid("equal", "not equal"),
        then: Joi.array().items(Joi.object({
          value: Joi.string(),
          subValues: Joi.array().min(1),
          label: Joi.string(),
        })),
      }).when("operator", {
        is: Joi.string().valid("greater than", "less than"),
        then: Joi.array().items(Joi.object({
          value: Joi.string(),
          subValues: Joi.array().min(1).max(1),
          label: Joi.string(),
        })),
      }),
    });
  }
}

export default GlobalFrequency;
