/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { Multiselect, SetupCard } from "@fas/ui-core";
import { toggleModal } from "@fas/ui-framework/lib/redux/actions/modals";
import { selectCampaignsIn, selectErrors } from "../../selectors/generalFilter";
import {
  addCampaignIn,
  deleteCampaignIn,
} from "../../actions/generalFilterInfo";
import type {
  CampaignType,
  ErrorOption,
} from "../../reducers/generalFilterInfo";
import GeneralFilterModal from "../GeneralFilterModal";
import { GENERAL_FILTER_CAMPAIGNS_IN_MODAL } from "../../helpers/constants";
import {
  checkError,
  getErrorMessage,
} from "../../helpers/generalFilter";

type CampaignsInHook = {
  campaignsIn: CampaignType[],
  errors: ErrorOption,
  onToggleModal: (boolean) => mixed,
  onAddCampaignIn: (CampaignType) => mixed,
  onDeleteCampaignIn: (CampaignType) => mixed,
};

const useCampaignsIn: () => CampaignsInHook = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const campaignsIn: CampaignType[] = useSelector(selectCampaignsIn);
  const errors: ErrorOption = useSelector(selectErrors);

  const onAddCampaignIn: (CampaignType) => mixed = (campaign) => dispatch(addCampaignIn(campaign));
  const onDeleteCampaignIn: (CampaignType) => mixed = (campaign) => dispatch(deleteCampaignIn(campaign));
  const onToggleModal: (boolean) => mixed = (isOpen) => dispatch(toggleModal(
    GENERAL_FILTER_CAMPAIGNS_IN_MODAL,
    isOpen
  ));

  return {
    campaignsIn,
    errors,
    onToggleModal,
    onAddCampaignIn,
    onDeleteCampaignIn,
  };
};

const CampaignIn: StatelessFunctionalComponent<{}> = () => {
  const {
    errors,
    campaignsIn,
    onToggleModal,
    onAddCampaignIn,
    onDeleteCampaignIn,
  }: CampaignsInHook = useCampaignsIn();

  return (
    <Box mt={3} data-testid="campaign-in-box">
      <SetupCard
        title="TDS Campaign in"
        showMainBtn
        mainBtnText="ADD CAMPAIGN"
        // $FlowFixMe
        onMainBtnClick={() => onToggleModal(true)}
      >
        <Box width="100%" mt={2}>
          <Multiselect
            type="enterManualMultiple"
            // $FlowFixMe
            options={campaignsIn}
            // $FlowFixMe
            selectedOptions={campaignsIn}
            // $FlowFixMe
            onAddValue={onAddCampaignIn}
            // $FlowFixMe
            onRemoveValue={onDeleteCampaignIn}
            error={{
              isError: checkError("tdsCampaignsIn", errors),
              helperText: getErrorMessage("tdsCampaignsIn", errors),
            }}
          />
        </Box>
      </SetupCard>
      <GeneralFilterModal />
    </Box>
  );
};

export default CampaignIn;
