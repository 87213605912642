// @flow
import {
  GET_ANTIFRAUD_TRIGGER_LIST_SAGA,
  CHANGE_ANTIFRAUD_TRIGGER_ACTIVE_SAGA,
} from "../../helpers/constants";

export type GetAntifraudTriggerListSaga = {
  type: typeof GET_ANTIFRAUD_TRIGGER_LIST_SAGA,
}

export type ChangeAntifraudTriggerActiveSaga = {
  type: typeof CHANGE_ANTIFRAUD_TRIGGER_ACTIVE_SAGA,
}

export type Actions = GetAntifraudTriggerListSaga
  | ChangeAntifraudTriggerActiveSaga;

export const getAntifraudTriggerListSaga: () => GetAntifraudTriggerListSaga = () => ({
  type: GET_ANTIFRAUD_TRIGGER_LIST_SAGA,
});

export const changeAntifraudTriggerActiveSaga: () => ChangeAntifraudTriggerActiveSaga = () => ({
  type: CHANGE_ANTIFRAUD_TRIGGER_ACTIVE_SAGA,
});
