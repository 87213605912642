// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { getErrors } from "@fas/ui-framework/lib/redux/selectors/errors";
import { type StoreWithErrors } from "@fas/ui-framework/lib/redux/selectors/errors/selectors";
import { type ErrorsMapper, type State as ErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors";
import { Map } from "immutable";
import type { StoreWithOffers } from "./types";
import { type Offer } from "../../reducers/offers";

type Mapper = { [string]: string, };

const selectOffers: (StoreWithOffers) => Map<string, Offer> = (state) => state.offers.get("byId");
const selectOffersIds: (StoreWithOffers, string[]) => string[] = (_, ids) => ids;

const selectOffersByIds: OutputSelector<StoreWithOffers, *, Offer[]> = createSelector(
  [selectOffers, selectOffersIds],
  (offers: Map<string, Offer>, ids: string[]): Offer[] => offers
    .filter((_: Offer, key: string): boolean => ids.includes(key))
    .valueSeq()
    .toArray()
    .map((offer: Map<Offer>): Offer => offer.toObject())
);
const selectActiveOffersIdsByIds: OutputSelector<StoreWithOffers, *, string[]> = createSelector(
  [selectOffers, selectOffersIds],
  (offers: Map<string, Offer>, ids: string[]): string[] => offers
    .filter((val: Map<Offer>, key: string): boolean => ids.includes(key) && val.get("isActive"))
    .keySeq()
    .toArray()
);

const selectMapOffersIdsToCollectionIds: OutputSelector<StoreWithOffers, *, Mapper> = createSelector(
  [selectOffers, selectOffersIds],
  (offers: Map<string, Offer>, ids: string[]): { [string]: string } => {
    const selectedOffers: { [string]: Offer } = offers
      .filter((_: Offer, key: string): boolean => ids.includes(key))
      .toJS();

    return Object.keys(selectedOffers).reduce((accumulator: Mapper, current: string): Mapper => {
      accumulator[selectedOffers[current].id] = current;
      return accumulator;
    }, {});
  }
);

export const selectErrors: OutputSelector<StoreWithErrors, *, ErrorsMapper> = createSelector(
  getErrors,
  (errors: ErrorsState): ErrorsMapper => errors.toJSON()
);

export const makeSelectOffersByIds: () => OutputSelector<StoreWithOffers, *, Offer[]> = () => selectOffersByIds;

export const makeSelectActiveOffersIdsByIds: () => OutputSelector<
  StoreWithOffers,
  *,
  string[]
> = () => selectActiveOffersIdsByIds;

export const makeSelectMapOffersIdsToCollectionIds: () => OutputSelector<
  StoreWithOffers,
  *,
  Mapper
> = () => selectMapOffersIdsToCollectionIds;
