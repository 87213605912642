// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import { initState as initErrorsState, reducer as errors } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import type { State as ErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import type { Actions as ErrorActions } from "@fas/ui-framework/lib/redux/actions/errors/actions";
import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";
import type { Actions as FormActions } from "@fas/ui-framework/lib/redux/actions/form";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import dropdowns, { initDropdownState } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import type { DropdownActions } from "@fas/ui-framework/lib/redux/actions/dropdowns";
import type { DropdownsState } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import notifications, {
  initNotificationsState,
  type State as NotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import type { AntifraudVariableLoadingTypes } from "../../selectors/antifraudVariables";
import { ANTIFRAUD_VARIABLE_FORM, ANTIFRAUD_VARIABLE_SOURCE_REQUEST } from "../../helpers/constants";
import mainSaga from "./saga";

export type State = $ReadOnly<{|
  form: FormState,
  errors: ErrorsState,
  loading: LoadingState<AntifraudVariableLoadingTypes>,
  notifications: NotificationsState,
  dropdowns: DropdownsState<string, string | number>,
|}>

function mapVariableFormToState(): State {
  return {
    form: initFormState({
      [ANTIFRAUD_VARIABLE_FORM]: {
        varId: "",
        name: "",
        description: "",
        type: "",
        source: ANTIFRAUD_VARIABLE_SOURCE_REQUEST,
        value: "",
        dictionaryId: "",
        variableId: "",
      },
    }),
    errors: initErrorsState(),
    loading: initLoadingState({}),
    notifications: initNotificationsState(),
    dropdowns: initDropdownState({
      types: [],
    }),
  };
}

export type Actions = FormActions<typeof ANTIFRAUD_VARIABLE_FORM>
  | ErrorActions
  | LoadingActions<AntifraudVariableLoadingTypes>
  | NotificationsActions
  | DropdownActions<string>;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  form,
  errors,
  loading,
  notifications,
  dropdowns,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapVariableFormToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
