// @flow
import { Map } from "immutable";
import type { ErrorOption } from "../../reducers/generalFilterInfo";

export const checkError = (field: string, errors: Map<ErrorOption>) => {
  if (errors[field]) {
    return errors[field].length > 0;
  }

  return false;
};

export const getErrorMessage = (field: string, errors: Map<ErrorOption>) => {
  if (errors[field]) {
    return errors[field][0].message;
  }

  return "";
};

export const checkErrorList = (field: string, errors: Map<ErrorOption>, index: number = 0, child: string) => {
  if (errors[field]) {
    return errors[field].some((item) => item.fullPath.index === index || [null, child].includes(item.fullPath.child));
  }

  return false;
};

export const getErrorMessageList = (field: string, errors: Map<ErrorOption>, index: number = 0, child: string) => {
  if (errors[field]) {
    // eslint-disable-next-line max-len
    const error = errors[field].filter((item) => item.fullPath.index === index || [null, child].includes(item.fullPath.child));

    return error[0] ? error[0].message : "";
  }

  return "";
};
