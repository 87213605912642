/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import { connect } from "react-redux";
import { AddData } from "@fas/ui-core";
import { Box, Link } from "@mui/material";
import { openFunnelSaga } from "../../actions/funnels";
import {
  getFunnelsCountBySplitId,
  getIsActiveBySplitId,
  getIsSplitFunnelsValid,
  getMaxFunnels,
} from "../../selectors/smartlink";
import FunnelsCounter from "../../components/FunnelsCounter";
import { openSplitSaga } from "../../actions/splits/actions";

type OwnProps = $ReadOnly<{|
  splitId: string,
|}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  splitIsActive: boolean,
  maxFunnels: number,
  funnelsCount: number,
  isFunnelsValid: boolean,
  handleAddFunnel: (splitId: string) => void,
  handleOpenSplit: (splitId: string) => void,
|}>;

const mapStateToProps = (
  state,
  { splitId }: OwnProps
) => ({
  splitIsActive: getIsActiveBySplitId(state, splitId),
  funnelsCount: getFunnelsCountBySplitId(state, splitId),
  maxFunnels: getMaxFunnels(state),
  isFunnelsValid: getIsSplitFunnelsValid(state, splitId),
});

const mapDispatchToProps = (dispatch) => ({
  handleAddFunnel: (splitId) => {
    dispatch(openSplitSaga(splitId));
    dispatch(openFunnelSaga(splitId, null));
  },
  handleOpenSplit: (splitId) => dispatch(openSplitSaga(splitId)),
});

const Funnels = ({
  splitId,
  splitIsActive,
  funnelsCount,
  maxFunnels,
  isFunnelsValid,
  handleAddFunnel,
  handleOpenSplit,
}: Props) => {
  const isFunnelsLimitReached = funnelsCount === maxFunnels;

  return (
    <>
      {funnelsCount === 0 && (
        <Box
          px={3}
          pb={1}
        >
          <AddData
            linkText="+ add new funnel"
            title="You have no funnels yet."
            error={isFunnelsValid}
            onClick={() => handleAddFunnel(splitId)}
          />
        </Box>
      )}
      {funnelsCount > 0 && (
        <>
          <Box
            px={3}
            pb={1}
          >
            <FunnelsCounter
              count={funnelsCount}
              disabled={!splitIsActive}
              onClick={() => handleOpenSplit(splitId)}
            />
          </Box>
          <Box
            align="center"
            px={3}
            pb={3}
            pt={2}
          >
            {!isFunnelsLimitReached && (
              <Link
                variant="button"
                component="button"
                onClick={() => handleAddFunnel(splitId)}
                data-testid="addEditButton"
                underline="hover"
              >
                + ADD NEW FUNNEL
              </Link>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Funnels);
