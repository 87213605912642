/* eslint-disable import/max-dependencies */
// @flow
import React, { useEffect, type StatelessFunctionalComponent, type Node } from "react";
import { useParams, type ContextRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Container,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  IconButton,
  Typography,
} from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { Loader, SetupCard } from "@fas/ui-core";
import { SAVE_FORM_DATA_SAGA, GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import type { Option } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import {
  getErrors,
  getAntifraudScenario,
  getAntifraudScenarioActions,
} from "../../selectors/antifraudScenarios";
import { DEFAULT_SCENARIO, DYNAMIC_SCENARIO } from "../../helpers/constants";
import { useDictionaryDropdownList, useLoading, useActions } from "../../hooks";
import FormHeader from "../FormHeader";
import * as actionsCreators from "../../actions/antifraudScenarioForm";
import type { ScenarioFormData, DynamicDict } from "../../reducers/antifraudScenarioForm";
import type { Props, DispatchActions, Errors } from "./AntifraudScenarioForm.types";

const AntifraudScenarioForm: StatelessFunctionalComponent<Props> = () => {
  const { id }: $PropertyType<$PropertyType<ContextRouter, "match">, "params"> = useParams();

  const {
    setAntifraudScenarioFormField: onSetAntifraudScenarioFormField,
    saveAntifraudScenarioSaga: onSaveAntifraudScenarioSaga,
    addAntifraudScenarioAction: onAddAntifraudScenarioAction,
    deleteAntifraudScenarioAction: onDeleteAntifraudScenarioAction,
    setAntifraudScenarioAction: onSetAntifraudScenarioAction,
    getAntifraudScenarioSaga: onGetAntifraudScenarioSaga,
  }: DispatchActions = useActions(actionsCreators);

  const isFormloading: boolean = useLoading(SAVE_FORM_DATA_SAGA, GET_FORM_DATA_SAGA);
  const [dictNames, isDictionariesLoading]: [Option<number>[], boolean] = useDictionaryDropdownList("variables", "dictionaries");
  const [variables, isVariablesLoading]: [Option<number>[], boolean] = useDictionaryDropdownList("variables", "variables");

  const formData: ScenarioFormData = useSelector(getAntifraudScenario);
  const actions: Array<DynamicDict> = useSelector(getAntifraudScenarioActions);
  const errors: Errors = useSelector(getErrors);

  useEffect(() => {
    if (id) {
      onGetAntifraudScenarioSaga(id);
    }
  }, [onGetAntifraudScenarioSaga, id]);

  return (
    <Box>
      <FormHeader
        title={id ? `Edit scenario ${id}` : "Create Scenario"}
        onSave={onSaveAntifraudScenarioSaga}
        renderComponent={(
          <Box
            component={FormControlLabel}
            pr={3}
            control={(
              <Switch
                disabled={isFormloading}
                data-testid="antifraud-scenario-isActive"
                name="isActive"
                checked={Boolean(formData.isActive)}
                onClick={(e: SyntheticInputEvent<HTMLInputElement>) => {
                  onSetAntifraudScenarioFormField({ isActive: e.target.checked });
                }}
                color="secondary"
              />
            )}
            label="Is active"
          />
        )}
        loading={isFormloading}
        isActionVisible={!isFormloading}
      />
      <Container maxWidth="md">
        <Loader loading={isFormloading}>
          <SetupCard title="General options">
            <>
              <Box width={1}>
                <TextField
                  data-testid="antifraud-scenario-name"
                  fullWidth
                  label="Name"
                  variant="outlined"
                  type="string"
                  margin="dense"
                  size="small"
                  value={formData.name}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    onSetAntifraudScenarioFormField({ name: e.target.value });
                  }}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                />
              </Box>
              <Box width={1}>
                <TextField
                  data-testid="antifraud-scenario-event"
                  fullWidth
                  label="Event"
                  variant="outlined"
                  type="string"
                  margin="dense"
                  size="small"
                  value={formData.event}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    onSetAntifraudScenarioFormField({ event: e.target.value });
                  }}
                  error={Boolean(errors.event)}
                  helperText={errors.event}
                />
              </Box>
              <Box width={1}>
                <TextField
                  data-testid="antifraud-scenario-typeName"
                  fullWidth
                  select
                  label="Type"
                  variant="outlined"
                  type="string"
                  margin="dense"
                  size="small"
                  value={formData.typeName}
                  disabled={Boolean(id)}
                  onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                    onSetAntifraudScenarioFormField({ typeName: e.target.value });
                  }}
                  error={Boolean(errors.typeName)}
                  helperText={errors.typeName}
                >
                  <MenuItem value={DEFAULT_SCENARIO}>{DEFAULT_SCENARIO}</MenuItem>
                  <MenuItem value={DYNAMIC_SCENARIO}>{DYNAMIC_SCENARIO}</MenuItem>
                </TextField>
              </Box>
            </>
          </SetupCard>
          {formData.typeName === DYNAMIC_SCENARIO && (
            <SetupCard
              title="Actions"
              showMainBtn={actions.length < 5}
              mainBtnText="add action"
              onMainBtnClick={() => {
                onAddAntifraudScenarioAction();
              }}
            >
              <>
                {errors.actions && (
                  <Box mb={1}>
                    <Typography color="error">
                      {errors.actions}
                    </Typography>
                  </Box>
                )}
                {actions.map(({ dictId, varId, location }: DynamicDict, index: number): Node => (
                  <Box
                    p={2}
                    mb={2}
                    border="1px dashed"
                    borderRadius={3}
                    width={1}
                    display="flex"
                    key={`${dictId}-${varId + index}`}
                  >
                    <Box flex={1} mr={1}>
                      <Box width={1}>
                        <TextField
                          data-testid="antifraud-scenario-dictionary"
                          fullWidth
                          select
                          label="Dictionary"
                          variant="outlined"
                          type="string"
                          margin="dense"
                          size="small"
                          value={dictId}
                          disabled={isDictionariesLoading}
                          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                            onSetAntifraudScenarioAction(index, { dictId: e.target.value });
                          }}
                        >
                          {dictNames.map(({ label, value }: Option<number>): Node => (
                            <MenuItem key={value} value={value}>{label}</MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Box width={1}>
                        <TextField
                          data-testid="antifraud-scenario-variable"
                          fullWidth
                          select
                          label="Variable"
                          variant="outlined"
                          type="string"
                          margin="dense"
                          size="small"
                          value={varId}
                          disabled={isVariablesLoading}
                          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                            onSetAntifraudScenarioAction(index, { varId: e.target.value });
                          }}
                        >
                          {variables.map(({ label, value }): Node => (
                            <MenuItem key={value} value={value}>{label}</MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Box width={1}>
                        <TextField
                          data-testid="antifraud-scenario-location"
                          fullWidth
                          select
                          label="Location"
                          variant="outlined"
                          type="string"
                          margin="dense"
                          size="small"
                          value={location}
                          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                            onSetAntifraudScenarioAction(index, { location: e.target.value });
                          }}
                        >
                          <MenuItem value="data">data</MenuItem>
                          <MenuItem value="headers">headers</MenuItem>
                          <MenuItem value="cookies">cookies</MenuItem>
                        </TextField>
                      </Box>
                    </Box>
                    <Box alignSelf="start">
                      <IconButton
                        data-testid="delete-action"
                        onClick={() => {
                          onDeleteAntifraudScenarioAction(index);
                        }}
                        disabled={index === 0}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </>
            </SetupCard>
          )}
        </Loader>
      </Container>
    </Box>
  );
};

export default AntifraudScenarioForm;
