import { put, select, takeEvery } from "redux-saga/effects";
import { DELETE_SPLIT_SAGA } from "../../helpers/constants";
import {
  deleteSplit,
  moveSplitNames,
  setSplitWeight,
  setSplitFrequency,
} from "../../actions/splits";
import { deleteFunnelSaga } from "../deleteFunnelSaga/deleteFunnelSaga";
import {
  getSplitsCount,
  getFunnelsBySplitId,
  getSplitNames,
  getAllActiveSplitsNames,
  getSplitsAllIds,
} from "../../selectors/smartlink";

import { changeFrequencyDefaultSplit, changeFrequencyMethod } from "../../actions/frequency";

const getSplitsToRename = (state, id) => state.splits
  .get("byId")
  .filter((val, key) => Number(key) > Number(id))
  .toJS();
const getSplitNamesMapper = (splits, splitNames) => Object.keys(splits).reduce(
  (acc, id) => Object.assign(acc, {
    [id]: splitNames[splitNames.indexOf(splits[id].name) - 1],
  }),
  {}
);

export function* deleteSplitSaga(action) {
  const splitsCount = yield select(getSplitsCount);
  if (splitsCount === 1) {
    return;
  }

  const { id } = action;
  const funnels = yield select(getFunnelsBySplitId, id);
  for (let i = 0; i < funnels.size; i++) {
    yield* deleteFunnelSaga({ splitId: id, funnelId: funnels.get(i) });
  }
  yield put(deleteSplit(id));

  const splitsToRename = yield select(getSplitsToRename, id);
  const splitNames = yield select(getSplitNames);
  const splitNamesMapper = getSplitNamesMapper(
    splitsToRename,
    splitNames.toJS()
  );

  if (Object.keys(splitNamesMapper).length) {
    yield put(moveSplitNames(splitNamesMapper));
  }

  const activeSplits = yield select(getAllActiveSplitsNames);
  if (activeSplits.length) yield put(changeFrequencyDefaultSplit(activeSplits[0]));

  const splitsCountAfter = yield select(getSplitsCount);
  if (splitsCountAfter === 1) {
    const ids = yield select(getSplitsAllIds);
    const idToSetWeight = ids.toArray()[0];
    yield put(changeFrequencyMethod("weights"));
    yield put(setSplitWeight(idToSetWeight, 100));
    yield put(setSplitFrequency(idToSetWeight, []));
  }
}

export default function* watchDeleteSplit() {
  yield takeEvery(DELETE_SPLIT_SAGA, deleteSplitSaga);
}
