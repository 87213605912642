// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetAntifraudTriggerSaga from "../../sagas/getAntifraudTriggerSaga";
import watchSaveAntifraudTriggerSaga from "../../sagas/saveAntifraudTriggerSaga";
import watchGetAntifraudConditionsListSaga from "../../sagas/getAntifraudConditionsListSaga";
import watchGetDropdownList from "../../sagas/getDropdownList";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetAntifraudTriggerSaga),
    call(watchSaveAntifraudTriggerSaga),
    call(watchGetAntifraudConditionsListSaga),
    call(watchGetDropdownList),
  ]);
}
