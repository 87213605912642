// @flow
/* new selectors for V2 */
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { List } from "immutable";
import type { MultiselectTypes, Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import type { SelectedTargetingRule, RuleTargetingQb } from "@fas/ui-core/lib/FlatQueryBuilder";
import { Map } from "immutable";
import type {
  GroupState,
  RuleState,
  TargetingRule,
  State,
  RuleStateJs,
  GroupStateJs,
} from "../../reducers/gfTargeting";
import type { TargetingRuleOption, TargetingValueOption, State as DictionariesState } from "../../reducers/dictionaries";
import { operatorsLocalizationV2, prepareOperators, typeMapper } from "../../helpers/targetingUtils";

export type QBRule = {|
  disabled: boolean,
  name: {
    value: string,
    label: string,
  },
  group?: string,
  type: string,
  components: Array<string>,
  maxTargetingRulesCount?: number,
  targetingMaxValueCount?: number,
  maxFilteringRulesCount?: number,
  filteringMaxValueCount?: number,
  operators: Array<{|
    value: string,
    name: string,
  |}>,
  allowedCampaignTypes: Array<string>,
|}

export const selectCountriesList: OutputSelector<
  State,
  string,
  string[]
  > = createSelector(
    [
      (state: State): Map<DictionariesState> => state.dictionaries,
      (state: State, countryType: string): string => countryType,
    ],
    (dictionariesList: Map<DictionariesState>, type: string): string[] => {
      const selectedCountries: string[] = dictionariesList.get(type).toArray();
      return selectedCountries;
    }
  );

export const getAllTargetings: OutputSelector<State, *, *> = createSelector(
  [
    (state: State): Map<string, RuleStateJs> => state.targeting,
  ], (targeting: Map<string, RuleStateJs>): * => targeting.toJS()
);

export const getFilterInfo: OutputSelector<State, *, SelectedTargetingRule> = createSelector(
  [
    (state: State): Map<string, *> => state.filterInfo,
  ], (filterInfo: Map<string, *>): * => filterInfo.toJS()
);

export const getTargetingGroupById: OutputSelector<State, *, GroupStateJs> = createSelector(
  [
    (state: State, id: string): TargetingRule => state.targeting.getIn(["byId", id]),
  ],
  (rule: TargetingRule): * => ({
    ...rule.toJS(),
  })
);

export const getTargetingRuleById: OutputSelector<State, *, SelectedTargetingRule> = createSelector(
  [
    (state: State, id: string): RuleStateJs => state.targeting.getIn(["byId", id]),
    (state: State): List<QBRule> => state.dictionaries.get("targetingRules"),
  ],
  (rule: TargetingRule, dictionary: List<QBRule>): SelectedTargetingRule => {
    const jsRule: RuleStateJs = rule.toJS();
    const jsDic: Array<QBRule> = dictionary.toJS();

    const targetingFromDic: ?QBRule = jsDic.find((i: QBRule): boolean => i.name.value === jsRule.name);
    const label: string = targetingFromDic ? targetingFromDic.name.label : "";
    const targetingMaxValueCount: ?number = targetingFromDic && targetingFromDic.targetingMaxValueCount
      ? targetingFromDic.targetingMaxValueCount : undefined;
    const result: SelectedTargetingRule = {
      ...jsRule,
      label,
      operator: {
        value: jsRule.operator,
        name: operatorsLocalizationV2[jsRule.operator],
      },
    };
    if (targetingMaxValueCount) {
      result.targetingMaxValueCount = targetingMaxValueCount;
    }

    return result;
  }
);

export const getIsTargetingListLoading: OutputSelector<State, *, boolean> = createSelector(
  (state: State): boolean => state.loading.get("loadingTargetingRules"),
  (isLoading: boolean): boolean => isLoading
);

export const getIsSaveGFLoading: OutputSelector<State, *, boolean> = createSelector(
  (state: State): boolean => state.loading.get("loadingSave"),
  (isLoading: boolean): boolean => isLoading
);

export const getAllTargetingRuleNames: OutputSelector<State, *, List<string>> = createSelector(
  (state: State, rootTargetingGroup: GroupState): Array<TargetingRule> => rootTargetingGroup.get("targetings").map((id: string): TargetingRule => state.targeting.getIn(["byId", id])),
  (targetings: Array<TargetingRule>): List<string> => targetings.filter((targeting: TargetingRule): RuleState => targeting.get("type") === "rule").map((targeting: RuleState): string => targeting.get("name"))
);

export const getTargetingRulesList: OutputSelector<State, *, Array<RuleTargetingQb>> = createSelector(
  [
    (state: State): List<TargetingRuleOption> => state.dictionaries.get("targetingRules"),
    (state: State, groupId: string): List<string> => {
      const group: GroupState = state.targeting.get("byId").get(groupId);
      return getAllTargetingRuleNames(state, group);
    },
  ],
  (dictionaries: List<TargetingRuleOption>, rulesList: List<string>): Array<RuleTargetingQb> => {
    const rules: Array<TargetingRuleOption> = dictionaries.toArray();
    const result: Array<RuleTargetingQb> = [];

    rules.forEach((ruleFromDic: TargetingRuleOption) => {
      const maxCount: number = ruleFromDic.maxTargetingRulesCount;
      if (!ruleFromDic.components.includes("targeting")) {
        return;
      }
      const setupedCounter: number = rulesList.filter((i: string): boolean => i === ruleFromDic.name.value).size;
      if (setupedCounter >= maxCount) {
        result.push({
          name: ruleFromDic.name,
          type: ruleFromDic.type,
          group: ruleFromDic.group,
          disabled: true,
          operators: prepareOperators(ruleFromDic.operators),
        });
        return;
      }
      result.push({
        name: ruleFromDic.name,
        type: ruleFromDic.type,
        group: ruleFromDic.group,
        disabled: false,
        operators: prepareOperators(ruleFromDic.operators),
      });
    });
    return result;
  }
);

export const getOperators: OutputSelector<State, *, Array<{|
  value: string,
  name: string,
|}>> = createSelector(
  (state: State, ruleName: string): TargetingRuleOption => state.dictionaries.get("targetingRules").find((rule: TargetingRuleOption): boolean => rule.name.value === ruleName),
  (rule: TargetingRuleOption): Array<{|
    value: string,
    name: string,
  |}> => {
    if (!rule) {
      return [];
    }
    return prepareOperators(rule.operators);
  }
);

function getMultiselectType(type: string, values: Array<Option>) {
  if (type === "MULTISELECT" && values.length) {
    return "simpleMultiple";
  }
  return typeMapper[type];
}

export const getValues: OutputSelector<State, *, {
  multiSelectType: MultiselectTypes,
  list: Array<Option>
}> = createSelector(
  [
    (state: State): List<TargetingValueOption> => state.dictionaries.get("targetingValues"),
    (state: State, ruleName: string): TargetingRuleOption => state.dictionaries.get("targetingRules").find((rule: TargetingRuleOption): boolean => rule.name.value === ruleName),
  ], (targetingValues: Map<string, TargetingValueOption>, targetingRule: TargetingRuleOption): {
    multiSelectType: MultiselectTypes,
    list: Array<Option>
  } => {
    if (!targetingRule) {
      return {
        list: [],
        multiSelectType: "simpleMultiple",
      };
    }
    const list: Array<Option> = targetingValues.get(targetingRule.name.value) || [];
    return {
      list,
      multiSelectType: getMultiselectType(targetingRule.type, list),
    };
  }
);

export function getLoadingStatusForValues(state: State, ruleName: string): boolean {
  const status: boolean = state.loading.get(`loadingTargetingValues-${ruleName}`) || false;
  const statusAll: boolean = state.loading.get("loadingTargetingValues") || false;

  return statusAll || status;
}

export const getAllTargetingRules: OutputSelector<State, *, string> = createSelector([
  (state: State): Map<string, RuleStateJs> => state.targeting.get("byId"),
], (targetings: Map<string, RuleStateJs>): string => targetings.filter((rule: Map<string, *>): boolean => rule.get("type") === "rule").map((rule: Map<string, *>): ?string => rule.get("name")));

export const getTargetingErrorById: OutputSelector<State, *, {
  errorRule: { message: string } | null,
  errorValue: { message: string } | null,
  errorOperator: { message: string } | null
}> = createSelector(
  [
    (state: State, id: string): Map<string, *> => state.errors.getIn(["targetings", id], Map()),
  ],
  (error: Map<string, *>): {
    errorRule: { message: string } | null,
    errorValue: { message: string } | null,
    errorOperator: { message: string } | null
  } => error.toJS()
);

export const getTargetingGroupErrorById = (state: State, id: string): string => state.errors.getIn(["targetings", id, "message"], "");
