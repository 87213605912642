/* eslint-disable max-len */
// @flow
import {
  FUNNEL_IS_ACTIVE_CHANGE,
  FUNNEL_NAME_CHANGE,
  DELETE_FUNNEL_SAGA,
  DELETE_FUNNEL,
  CLONE_FUNNEL_SAGA,
  ADD_FUNNEL_SAGA,
  ADD_FUNNEL,
  OPEN_FUNNEL_SAGA,
  CLOSE_FUNNEL_SAGA,
  SAVE_FUNNEL_SAGA,
  ADD_TARGETING_TO_FUNNEL,
  CHANGE_FILTERING_ORDER,
} from "../../helpers/constants";

export type ChangeIsActiveAction = {
  type: typeof FUNNEL_IS_ACTIVE_CHANGE,
  id: string,
};

export type ChangeNameAction = {
  type: typeof FUNNEL_NAME_CHANGE,
  funnelId: string,
  name: string,
};

export type DeleteAction = {
  type: typeof DELETE_FUNNEL_SAGA | typeof DELETE_FUNNEL,
  splitId: string,
  funnelId: string,
};

export type CloneFunnelSagaAction = {
  type: typeof CLONE_FUNNEL_SAGA,
  splitId: string,
  funnelId: string,
};

export type AddFunnelSagaAction = {
  type: typeof ADD_FUNNEL_SAGA,
  splitId: string,
};

export type AddFunnelAction = {
  type: typeof ADD_FUNNEL,
  splitId: string,
  funnelId: string,
  name: string,
  timestamp: number,
  isActive: boolean,
  rootTargetingId: string,
};

export type OpenFunnelSagaAction = {
  type: typeof OPEN_FUNNEL_SAGA,
  splitId: string,
  funnelId: string | null
};

export type CloseFunnelSagaAction = {
  type: typeof CLOSE_FUNNEL_SAGA
};

export type SaveFunnelSagaAction = {
  type: typeof SAVE_FUNNEL_SAGA,
  funnelId: string
};

export type AddTargetingToFunnelAction = {
  type: typeof ADD_TARGETING_TO_FUNNEL,
  funnelId: string,
  targetingRootId: string,
};

export type ChangeFilteringOrderType = {|
  type: typeof CHANGE_FILTERING_ORDER,
  funnelId: string,
  filterings: Array<string>,
|};

export type FunnelActions = SaveFunnelSagaAction
| CloseFunnelSagaAction
| OpenFunnelSagaAction
| AddFunnelAction
| AddFunnelSagaAction
| CloneFunnelSagaAction
| DeleteAction
| ChangeNameAction
| AddTargetingToFunnelAction
| ChangeFilteringOrderType
| ChangeIsActiveAction;

export const changeIsActive = (id: string): ChangeIsActiveAction => ({
  type: FUNNEL_IS_ACTIVE_CHANGE,
  id,
});

export const changeName = (funnelId: string, name: string): ChangeNameAction => ({
  type: FUNNEL_NAME_CHANGE,
  funnelId,
  name,
});

export const deleteFunnelSaga = (splitId: string, funnelId: string): DeleteAction => ({
  type: DELETE_FUNNEL_SAGA,
  splitId,
  funnelId,
});

export const deleteFunnel = (splitId: string, funnelId: string): DeleteAction => ({
  type: DELETE_FUNNEL,
  splitId,
  funnelId,
});

export const cloneFunnelSaga = (splitId: string, funnelId: string): CloneFunnelSagaAction => ({
  type: CLONE_FUNNEL_SAGA,
  splitId,
  funnelId,
});

export const addFunnelSaga = (splitId: string): AddFunnelSagaAction => ({
  type: ADD_FUNNEL_SAGA,
  splitId,
});

export const addFunnel = (
  splitId: string,
  funnelId: string,
  name: string,
  timestamp: number,
  isActive: boolean,
  rootTargetingId: string
): AddFunnelAction => ({
  type: ADD_FUNNEL,
  splitId,
  funnelId,
  name,
  timestamp,
  isActive,
  rootTargetingId,
});

export const openFunnelSaga = (splitId: string, funnelId: string | null): OpenFunnelSagaAction => ({
  type: OPEN_FUNNEL_SAGA,
  splitId,
  funnelId,
});

export const closeFunnelSaga = (): CloseFunnelSagaAction => ({
  type: CLOSE_FUNNEL_SAGA,
});

export const saveFunnelSaga = (funnelId: string): SaveFunnelSagaAction => ({
  type: SAVE_FUNNEL_SAGA,
  funnelId,
});

export const addTargetingToFunnel = ({ funnelId, targetingRootId } : { funnelId: string, targetingRootId: string }): AddTargetingToFunnelAction => ({
  type: ADD_TARGETING_TO_FUNNEL,
  targetingRootId,
  funnelId,
});

export const changeFilteringOrder = (funnelId: string, filterings: Array<string>): ChangeFilteringOrderType => ({
  type: CHANGE_FILTERING_ORDER,
  funnelId,
  filterings,
});
