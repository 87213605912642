// @flow
import { Map } from "immutable";
import {
  SET_CONFIRM_NOTIFICATION_MESSAGE,
  OPEN_CONFIRM_NOTIFICATION,
  CLOSE_CONFIRM_NOTIFICATION,
} from "../../helpers/constants";
import type { ConfirmatioActionType } from "../../actions/confirmation/actions";

export type State = {
  confirmation: Map<{
    isOpen: boolean,
    currentMessage: string,
  }>
};

export const initialState: Map<State> = Map({
  confirmation: Map({
    isOpen: false,
    currentMessage: "",
  }),
});

export function initConfirmDialogState() {
  return initialState;
}

type Action = Object
  | ConfirmatioActionType;

export default (state: Map<State> = initialState, action: Action) => {
  switch (action.type) {
    case SET_CONFIRM_NOTIFICATION_MESSAGE: {
      return state.setIn(["confirmation", "currentMessage"], action.message);
    }
    case OPEN_CONFIRM_NOTIFICATION: {
      return state.setIn(["confirmation", "isOpen"], true);
    }
    case CLOSE_CONFIRM_NOTIFICATION: {
      return state.setIn(["confirmation", "isOpen"], false);
    }
    default:
      return state;
  }
};
