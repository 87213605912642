// @flow
import { Record } from "immutable";
import type { RecordOf, RecordFactory } from "immutable";
import type { Actions } from "../../actions/funnelTemplateReport";
import { FUNNELTEMPLATE_REPORT_SET_GROUP_FILTERS_ORDER } from "../../helpers/constants";

export type DefaultState = {
  groupBy: string,
  filterOrder: string[],
};

export type State = RecordOf<DefaultState>;

const defaultValues: DefaultState = {
  groupBy: "",
  filterOrder: [],
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export const initialState: State = makeState({});

export const initFunnelTemplateReport: State = () => makeState({});

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case FUNNELTEMPLATE_REPORT_SET_GROUP_FILTERS_ORDER: {
      return state.withMutations((newState) => {
        const { groupBy, filterOrder } = action;
        newState.set("groupBy", groupBy);
        newState.set("filterOrder", filterOrder);
      });
    }
    default:
      return state;
  }
};
