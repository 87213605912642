// @flow
import * as React from "react";
import { Box } from "@mui/material";

type Props = {
  children: React.AbstractComponent<{||}>,
  value: number,
  index: number,
}

const TabPanel = ({
  children,
  value,
  index,
}: Props) => {
  const TabPanelComponent = children;

  return (
    <Box
      role="tabpanel"
      hidden={parseInt(value, 10) !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {parseInt(value, 10) === index && (
        <Box p={1}>
          <TabPanelComponent />
        </Box>
      )}
    </Box>
  );
};

export default TabPanel;
