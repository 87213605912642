// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
} from "@mui/material";
import type { List } from "immutable";
import { Loader, SetupCard } from "@fas/ui-core";
import FilterList from "../../components/FilterList";
import {
  fetchGeneralFilters,
  setAllActiveGeneralFilters,
  openGeneralFiltersModalSaga,
} from "../../actions/generalFilters";
import { getIsGeneralFiltersLoading } from "../../selectors/smartlink";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  isLoading: boolean,
  onFetchGeneralFilters: () => void,
  onOpenGeneralFiltersModalSaga: () => void,
  onSetAllActiveGeneralFilters: (value: boolean) => void,
  filtersInCampaign: List,
|}>;

const mapStateToProps = (state) => ({
  filtersInCampaign: state.generalFilters,
  isLoading: getIsGeneralFiltersLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  onFetchGeneralFilters: () => dispatch(fetchGeneralFilters()),
  onOpenGeneralFiltersModalSaga: () => dispatch(openGeneralFiltersModalSaga()),
  onSetAllActiveGeneralFilters: (value) => dispatch(setAllActiveGeneralFilters(value)),
});

const SmartlinkGeneralFilter = ({
  isLoading,
  filtersInCampaign,
  onFetchGeneralFilters,
  onOpenGeneralFiltersModalSaga,
  onSetAllActiveGeneralFilters,
}: Props) => {
  useEffect(() => {
    onFetchGeneralFilters();
  }, []); // eslint-disable-line

  const filtersActiveInCampaign = filtersInCampaign.filter((filter) => filter.get("isFilterActiveOnCampaign"));
  return (
    <>
      <SetupCard
        title="General filters"
      >
        <Box width="100%" pt={1}>
          <Loader loading={isLoading}>
            <FilterList
              title="Filter settings"
              subtitle={`${filtersActiveInCampaign.size} Filters`}
              checked={filtersActiveInCampaign.size > 0}
              onArrowClick={onOpenGeneralFiltersModalSaga}
              onSwitchClick={(e) => onSetAllActiveGeneralFilters(e.target.checked)}
            />
          </Loader>
        </Box>
      </SetupCard>
    </>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SmartlinkGeneralFilter);
