// @flow
import {
  ADD_FALLBACK_OFFER,
  SAVE_FALLBACK_OFFER_SAGA,
  DELETE_FALLBACK_OFFER,
  DELETE_FALLBACK_OFFER_SAGA,
} from "../../helpers/constants";
import type { Offer } from "../../reducers/offers";

export const saveFallbackOfferSaga = (offer: Offer) => ({
  type: SAVE_FALLBACK_OFFER_SAGA,
  offer,
});

export const addFallbackOffer = (offerId: string, offer: Offer) => ({
  type: ADD_FALLBACK_OFFER,
  offerId,
  offer,
});

export const deleteFallbackOffer = (offerId: string) => ({
  type: DELETE_FALLBACK_OFFER,
  offerId,
});

export const deleteFallbackOfferSaga = (offerId: string) => ({
  type: DELETE_FALLBACK_OFFER_SAGA,
  offerId,
});
