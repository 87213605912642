// @flow
import React from "react";
import {
  Box, Button, Typography, Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import type { AbstractComponent } from "react";

interface Props {
  onAdd?: () => void,
  Search?: AbstractComponent<*>,
  columns: Array<{
    width: number | string,
    title?: string,
    dataTestid: string,
    noWrap?: boolean,
  }>
}

const styles = makeStyles((theme) => ({
  headerText: {
    textTransform: "uppercase",
    fontWeight: "bold",
    padding: `${theme.spacing(1)} 0`,
  },
  headerBlock: (rest) => ({
    padding: rest.headerBlockPadding,
  }),
  headerRow: (rest) => ({
    background: rest.headerBackground,
    color: theme.palette.text.primary,
  }),
}));

const FunnelListHeader = ({
  onAdd,
  columns,
  Search,
  ...rest
}: Props) => {
  const classes = styles(rest);
  return (
    <Box mb={1}>
      {onAdd && (
        <Box px={2} display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          {Search && <Search />}
          <Button
            onClick={onAdd}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            data-testid="addEditButton"
          >
            Add
          </Button>
        </Box>
      )}
      <Box
        className={classes.headerRow}
        display="flex"
        alignItems="center"
        px={4}
        justifyContent="space-between"
        bgcolor="background.blueBackgroundLight"
      >
        <Grid container>
          {columns && columns.map((item) => (
            <Grid
              key={`${item.dataTestid}`}
              item
              xs={item.width}
              className={classes.headerBlock}
            >
              {item.title ? (
                <Typography
                  noWrap
                  data-testid={item.dataTestid}
                  title={item.title}
                  className={classes.headerText}
                  variant="subtitle1"
                >
                  {item.title}
                </Typography>
              ) : null}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default FunnelListHeader;
