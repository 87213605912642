// @flow
import React from "react";
import {
  IconButton, Grid, Typography, OutlinedInput, Link, Switch,
} from "@mui/material";
import DeleteOutlineSharpIcon from "@mui/icons-material/DeleteOutlineSharp";
import { CopyToClipboardInput } from "@fas/ui-core";
import makeStyles from "@mui/styles/makeStyles";
import environment from "environment";

const useStyles = makeStyles((theme) => ({
  root: {
    wordBreak: "break-all",
  },
  offerItem: {
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  weightValue?: number,
  isWeightDisabled?: boolean,
  urlTitle: string,
  urlValue: string,
  idValue: string,
  weightError?: boolean,
  onDelete?: null | (any) => void,
  onChangeWeight?: null | (any) => void,
  isActive?: boolean,
  activationChangeDisabled?: boolean,
  onIsActiveChange?: (any) => void,
  onChangeUrl?: null | (e: SyntheticInputEvent<HTMLInputElement>)=>void,
  disableDelete?: boolean,
};

const OfferItem = (props: Props) => {
  const classes = useStyles();
  const {
    weightValue,
    isWeightDisabled,
    urlTitle,
    urlValue,
    idValue,
    onDelete,
    weightError,
    onChangeWeight,
    isActive,
    activationChangeDisabled,
    onIsActiveChange,
    onChangeUrl,
    disableDelete,
  } = props;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
      data-testid="offer-line"
      className={classes.offerItem}
    >
      <Grid item container direction="row" alignItems="center" xs={2}>
        {
          onIsActiveChange && (
            <Switch
              color="primary"
              checked={isActive}
              disabled={activationChangeDisabled}
              onClick={onIsActiveChange}
              data-testid="isActive_switch_offer"
            />
          )
        }

        <Typography variant="subtitle2" classes={{ root: classes.root }}>
          <Link
            color="inherit"
            underline="none"
            component="a"
            target="_blank"
            href={`${environment.links.viewCampaign}?id=${idValue}`}
          >
            {idValue}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={(Number(weightValue) >= 0) ? 7 : 9}>
        <CopyToClipboardInput
          title={urlTitle}
          value={urlValue}
          fullWidth
          readOnly={false}
          onChange={onChangeUrl}
        />
      </Grid>
      {(Number(weightValue) >= 0) && (
        <Grid item xs={2}>
          <OutlinedInput
            type="number"
            margin="dense"
            size="small"
            value={weightValue}
            error={weightError}
            disabled={isWeightDisabled || !isActive}
            onChange={onChangeWeight}
            inputProps={{ min: 0, max: 100, "data-testid": "offer-item-weight" }}
          />
        </Grid>
      )}
      {onDelete && (
        <Grid item xs={1}>
          <IconButton size="medium" onClick={onDelete} disabled={disableDelete} data-testid="offer-item-component-delete">
            <DeleteOutlineSharpIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

OfferItem.defaultProps = {
  weightValue: undefined,
  isWeightDisabled: false,
  weightError: false,
  onDelete: null,
  onChangeWeight: null,
  isActive: false,
  activationChangeDisabled: false,
  onIsActiveChange: null,
  onChangeUrl: null,
  disableDelete: false,
};

export default OfferItem;
