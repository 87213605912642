// @flow
import {
  DELETE_TEMPLATE_SAGA,
  GET_TEMPLATE_LIST_SAGA,
} from "../../helpers/constants";

export type DeleteFunnelsTemplateSaga = {| type: typeof DELETE_TEMPLATE_SAGA, templateId: string |};
export type GetFunnelsTemplateSaga = {|
  type: typeof GET_TEMPLATE_LIST_SAGA, payload?: { page: number, pageSize: number, filters: Object, sorting: Object}
|}
export type Actions =
  DeleteFunnelsTemplateSaga
| GetFunnelsTemplateSaga

export const deleteFunnelsTemplate: (templateId: string) => DeleteFunnelsTemplateSaga = (templateId) => ({
  type: DELETE_TEMPLATE_SAGA,
  templateId,
});

export const getFunnelsTemplateList: (
  payload?: { page: number, pageSize: number, filters: Object, sorting: Object }
) => GetFunnelsTemplateSaga = (payload) => ({
  type: GET_TEMPLATE_LIST_SAGA,
  payload,
});
