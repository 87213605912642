// @flow
import type { StatelessFunctionalComponent } from "react";
/* eslint-disable import/max-dependencies */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, type Match } from "react-router-dom";
import {
  Box,
  Container,
} from "@mui/material";
import {
  Loader, SetupCard, FormHeader,
} from "@fas/ui-core";
import {
  TextFieldForm,
  SelectForm,
  SwitchFieldForm,
  AutocompleteWithFetchForm,
} from "@fas/ui-core/lib/Form";
import { getFormDataSaga, useFormField } from "@fas/ui-framework";
import { SAVE_FORM_DATA_SAGA, GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import {
  SMARTLINK_REDIRECT_FORM,
} from "../../helpers/constants";
import Form from "../../containers/Form";
import { useLoading } from "../../hooks/useLoading";
import validate from "./validate";
import Placeholders from "../../containers/Placeholders";
import { clickPreset } from "../../actions/placeholders";
import { setBackup, restoreBackup } from "../../actions/backup";
import { getSmartlinkIdList } from "../../services/smartlinkAggregatorsApi";

type Props = {||};

const SmartlinkRedirectForm: StatelessFunctionalComponent<Props> = () => {
  const { id: pageId }: $PropertyType<Match, "params"> = useParams();
  const dispatch = useDispatch();

  const isSaveFormloading: boolean = useLoading(SAVE_FORM_DATA_SAGA);
  const isGetFormloading: boolean = useLoading(GET_FORM_DATA_SAGA);
  const loading: boolean = isSaveFormloading || isGetFormloading;

  const { placeholders } = useSelector((state) => getFormData(state, SMARTLINK_REDIRECT_FORM));

  const traficTypes = [ // need move to dictionary
    {
      label: "Internal",
      value: "internal",
    },
    {
      label: "External",
      value: "external",
    },
  ];

  useEffect(() => {
    dispatch(setBackup(["form", "placeholders"]));
    if (pageId) {
      dispatch(getFormDataSaga(SMARTLINK_REDIRECT_FORM, pageId));
    }
    return () => {
      dispatch(restoreBackup());
    };
  }, [pageId]);

  useEffect(() => {
    // set placeholders data on page load
    if (placeholders) {
      const presets = placeholders.split("&").map((item) => {
        const [name, value] = item.split("=");
        return { name, value };
      });
      dispatch(clickPreset(presets));
    }
  }, [placeholders]);

  const smartlinkIdField: any = useFormField<{ value: string }>(SMARTLINK_REDIRECT_FORM, "smartlinkId");

  return (
    <Form id={SMARTLINK_REDIRECT_FORM} validate={validate} redirectOnSave="/smartlinkAggregators/list">
      <FormHeader
        title={pageId ? `Edit Smartlink Redirect: ${pageId}` : "Create new Smartlink Redirect"}
        loading={loading}
        isActionVisible={!loading}
      >
        <Box pr={3}>
          <SwitchFieldForm
            disabled={loading}
            name="isActive"
            label="Is Active"
          />
        </Box>
      </FormHeader>
      <Container maxWidth="md">
        <Loader loading={loading}>
          <SetupCard title="General options">
            <Box width={1}>
              <TextFieldForm
                name="name"
                label="Name"
              />
              <AutocompleteWithFetchForm
                primitiveValue
                name="smartlinkId"
                label="Smartlink id"
                fetchMethod={({ filter }) => getSmartlinkIdList(filter)}
                searchLimit={2}
                onChange={smartlinkIdField.onChange}
              />
              <SelectForm
                name="type"
                label="Type"
                options={traficTypes}
                disabled={Boolean(pageId)}
              />
            </Box>
          </SetupCard>
          <Box mt={3} data-testid="placeholders">
            <Placeholders />
          </Box>
        </Loader>
      </Container>
    </Form>
  );
};

export default SmartlinkRedirectForm;
