/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AddData,
  ViewLink,
  SelectSearchComponent,
} from "@fas/ui-core";
import { Typography, Box } from "@mui/material";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import environment from "environment";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { getReportColumnsOrder } from "../../selectors/templates";
import type { ColumnsMapperType } from "./types/FunnelsTemplateReport.types";
import type { ReportRow } from "../FunnelsTemplateReportList/types/FunnelsTemplateReportList.types";
import ReportList from "../FunnelsTemplateReportList";
import { changeConfigureFunnelTemplateReportColumnsModalOpen } from "../../actions/configureFunnelTemplateReportColumnsModal";

type TemplateReportHook = {
  columns: Column<ReportRow>[],
  onToggleColumnsModal: (boolean) => mixed,
};

const columnsMapper: ColumnsMapperType = {
  templateId: {
    field: "id",
    label: "ID TEMPLATE",
    sortable: true,
    searchable: true,
    render: ({ id }: ReportRow): Element<*> => (
      <>
        {id !== "unknown" && (
          <ViewLink {...{
            id,
            name: id,
            viewLink: "/funnelTemplates/view/:id",
          }}
          />
        )}
        {id === "unknown" && (
          <Typography
            component="div"
            display="block"
          >
            {id}
          </Typography>
        )}
      </>
    ),
  },
  templateName: {
    field: "name",
    label: "NAME",
    sortable: true,
    searchable: true,
  },
  templateType: {
    field: "type",
    label: "TYPE",
    sortable: true,
    searchable: true,
    SearchComponent: (searchProps: SearchComponentProps): Element<typeof SelectSearchComponent> => (
      <SelectSearchComponent
        {...searchProps}
        values={[
          { title: "All", value: "" },
          { title: "Technical", value: "technical" },
          { title: "Other", value: "other" },
        ]}
        label="type"
      />
    ),
  },
  smartlinkId: {
    field: "smartlinkId",
    label: "SMARTLINK ID",
    sortable: true,
    searchable: true,
    render: ({ smartlinkId }: ReportRow): Element<typeof ViewLink> => (
      <ViewLink
        {...{
          id: smartlinkId,
          name: smartlinkId,
          viewLink: `${environment.links.viewCampaign}?id=:id`,
        }}
      />
    ),
  },
  smartlinkSplit: {
    field: "smartlinkSplit",
    label: "SMARTLINK SPLIT",
    sortable: true,
    searchable: true,
  },
  smartlinkAuthor: {
    field: "author",
    label: "SMARTLINK AUTHOR",
    sortable: true,
    searchable: true,
  },
};

const useTemplateReport: () => TemplateReportHook = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const columnsOrder: string[] = useSelector(getReportColumnsOrder);
  const columns: Column<ReportRow>[] = columnsOrder.map((column: string): Column<ReportRow> => columnsMapper[column]);

  const onToggleColumnsModal: (boolean) => mixed = (isOpen) => dispatch(
    changeConfigureFunnelTemplateReportColumnsModalOpen(isOpen)
  );

  return { columns, onToggleColumnsModal };
};

const FunnelsTemplateReport: StatelessFunctionalComponent<{}> = () => {
  const { columns, onToggleColumnsModal }: TemplateReportHook = useTemplateReport();

  return (
    <>
      {columns.length === 0 && (
        <Box pt={3} data-testid="configure-columns-btn">
          <AddData
            linkText="+ select columns"
            title="You have no selected report columns yet."
            onClick={(): mixed => onToggleColumnsModal(true)}
          />
        </Box>
      )}
      {columns.length > 0 && (
        <Box mt={3} data-testid="card">
          <ReportList columns={columns} />
        </Box>
      )}
    </>
  );
};

export default FunnelsTemplateReport;
