// @flow
import * as React from "react";
import { Button } from "@mui/material";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import Row from "./Row";
import SplitWeightsInput from "./SplitWeightInput";
import type { Split } from "./types/SplitsDistribution.types";

type Props = {
  splits: Map<number, Split>,
  errors: ErrorsMapper | {},
  onWeightChange: (split: string, weight: number) => void,
  onSplitWeightsEqualShares: () => void
};

const hasError = (errors: ErrorsMapper | {}): boolean => Object.keys(errors).includes("splitsWeights");

const WeightBody = ({
  splits,
  errors,
  onWeightChange,
  onSplitWeightsEqualShares,
}: Props) => (
  <>
    <Row title="Splits" multiLayout>
      {Object.entries(splits).map<
        React.Node,
        Array<Array<string | { name: string, weight: number }>>
      >(
        // $FlowFixMe
        ([splitId, { name, weight, isActive }]) => (
          <SplitWeightsInput
            key={splitId}
            isActive={isActive}
            split={name}
            weight={weight}
            error={hasError(errors)}
            onWeightChange={
              (e: SyntheticInputEvent<HTMLInputElement>) => onWeightChange(splitId, e.target.valueAsNumber)
            }
          />
        )
      )}
    </Row>
    <Row title="">
      <Button
        variant="outlined"
        onClick={onSplitWeightsEqualShares}
        data-testid="secondary-button"
      >
        Equal shares
      </Button>
    </Row>
  </>
);

export default WeightBody;
