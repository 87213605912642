// @flow
// eslint-disable-next-line no-unused-vars
import type { LoadingTypes, StoreWithLoadingType } from "./types";

/**
 * Get loading state
 * @param {StoreWithLoadingType} state redux state
 * @param {string} key loading key to get from loading state
 * @returns {boolean} loading
 */

// eslint-disable-next-line import/prefer-default-export
export function getLoading<T: string = LoadingTypes>(state: StoreWithLoadingType<T>, key: T): boolean {
  return state.loading.get(key, false);
}
