// @flow
import React from "react";
import {
  Box, Button, DialogTitle, Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TextEdit from "../TextEdit";

type Props = {
  name: string,
  onNameChange: (name: string) => void,
  onClose: () => void,
  isFieldsDisabled?: boolean,
}

const useStyles = makeStyles({
  disablingContentClass: {
    opacity: 0.7,
    pointerEvents: "none",
  },
});

const FunnelHeader = ({
  name,
  onNameChange,
  onClose,
  isFieldsDisabled,
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <Box display="flex">
        <Button
          data-testid="funnel-header-close-button"
          onClick={onClose}
        >
          Back
        </Button>

        <Divider orientation="vertical" />
        <Box ml={3} className={isFieldsDisabled ? classes.disablingContentClass : ""}>
          <DialogTitle>
            <TextEdit
              text={name}
              placeholder="Funnel name"
              onTextChange={onNameChange}
              isFieldsDisabled={isFieldsDisabled}
            />
          </DialogTitle>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

FunnelHeader.defaultProps = {
  isFieldsDisabled: false,
};

export default FunnelHeader;
