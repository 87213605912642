// @flow
import type { RecordOf, RecordFactory } from "immutable";
import { Record } from "immutable";
import { SET_SEARCH_DATA } from "../../helpers/constants";
import type { Actions } from "../../actions/search";

export type DefaultState = {
  text: string,
  result: string[],
  scrollToItem: ?string,
};

export type State = RecordOf<DefaultState>;

const defaultValues: DefaultState = {
  text: "",
  result: [],
  scrollToItem: null,
};

export const makeState: RecordFactory<DefaultState> = Record(defaultValues);

export const initialState: State = makeState({});

export function initSearchNavigationState() {
  return initialState;
}

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_SEARCH_DATA: {
      return state.merge(action.data);
    }
    default:
      return state;
  }
};
