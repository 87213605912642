// @flow
import React, { useState, useEffect, useRef } from "react";
import { ComboBox } from "@fas/ui-core";
import { Grid, TextField } from "@mui/material";
import throttle from "lodash.throttle";
import CampaignApi from "../../services/smartlinkApi";
import type { OfferItem } from "../FilteringList/types/FilteringList.types";

type Props = {
  uniqueElementId: string,
  campaignId?: string,
  url?: string,
  isDisabled?: boolean,
  requestDelay?: number,
  changeUrl: (selectedItem: OfferItem) => void,
}

const SelectBasicCampaignUrl = (
  {
    uniqueElementId,
    campaignId = "",
    url,
    changeUrl,
    isDisabled,
    requestDelay,
  }: Props
) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const isCancelled = React.useRef(false);
  useEffect(() => setInputValue(campaignId), [campaignId]);

  const throttled = useRef(throttle((newValue, campId) => {
    if (newValue.length > 1 && newValue !== campId) {
      fetchData(newValue);
    }
  }, requestDelay));

  useEffect(() => {
    if (inputValue === "" || inputValue === campaignId) {
      setOptions([]);
    }

    throttled.current(inputValue, campaignId);

    return () => {
      isCancelled.current = true;
    };
  }, [campaignId, inputValue]);

  const fetchData = async (id) => {
    isCancelled.current = false;
    setLoading(true);
    const result = await CampaignApi.getBasicCampaignsUrlById(id);
    setLoading(false);

    if (!isCancelled.current) {
      setOptions(result.data.data);
    }
  };

  function handleChange(e, val) {
    setInputValue(val);
  }

  function handleSelectChange(event, val) {
    changeUrl(val);
  }

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item xs={3} data-testid="use-one-link-offer-select">
        <ComboBox
          id={`mui-autocomplete-${uniqueElementId}`}
          data-testid="mui-autocomplete"
          label="Add campaign"
          loading={loading}
          options={options}
          getOptionLabel={(option) => option.id}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, val) => handleSelectChange(e, val)}
          inputValue={inputValue}
          onInputChange={(e, val) => handleChange(e, val)}
          disableClearable
          disabled={isDisabled}
        />
      </Grid>
      <Grid item xs={9}>
        <TextField
          data-testid="mui-url-input"
          label="URL"
          variant="outlined"
          size="small"
          value={url}
          readOnly
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

SelectBasicCampaignUrl.defaultProps = {
  campaignId: "",
  url: "",
  isDisabled: false,
  requestDelay: 1000,
};

export default SelectBasicCampaignUrl;
