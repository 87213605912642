/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useState,
  type StatelessFunctionalComponent,
} from "react";
import { Box } from "@mui/material";
import AntifraudScenarioForm from "../../components/AntifraudScenarioForm";
import AntifraudScenarioRules from "../../components/AntifraudScenarioRules";
import AntifraudScenarioRulesModal from "../../components/AntifraudScenarioRulesModal";

type Props = {||};

const AntifraudScenarioFormContainer: StatelessFunctionalComponent<Props> = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box pb={4}>
      <AntifraudScenarioForm />
      <AntifraudScenarioRules
        setModalOpen={setModalOpen}
      />
      <AntifraudScenarioRulesModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </Box>
  );
};

export default AntifraudScenarioFormContainer;
