// @flow
import type { Node } from "react";
import type { Permission } from "@fas/ui-framework/lib/services/user";
import { userService } from "../../services/user";

type Props = {
  permissions: Array<Permission>,
  children: Node,
  renderNoAccess?: Node,
}

const Can = ({
  permissions,
  children,
  renderNoAccess = null,
}: Props) => (userService.can(permissions) ? children : renderNoAccess);

Can.defaultProps = {
  permissions: [],
  children: null,
  renderNoAccess: null,
};

export default Can;
