// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import notifications, {
  initNotificationsState,
  type State as NotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import {
  reducer as errors,
  initState as initErrorState,
  type State as ErrorState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import dropdowns, {
  initDropdownState,
  type DropdownsState,
} from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import antifraudDictionaryForm, {
  initialAntifraudDictionariesState,
  type State as AntifraudDictionaryFormState,
} from "../../reducers/antifraudDictionariesForm";
import type { Actions as AntifraudDictionaryFormActions } from "../../actions/antifraudDictionaryForm";
import type { AntifraudDictionaryLoadingTypes } from "../../selectors/antifraudDictionaries";
import mainSaga from "./saga";

export type State = $ReadOnly<{|
  loading: LoadingState<AntifraudDictionaryLoadingTypes>,
  errors: ErrorState,
  notifications: NotificationsState,
  antifraudDictionaryForm: AntifraudDictionaryFormState,
  dropdowns: DropdownsState<string, string>,
|}>

function mapAntifraudDictionaryToState(): State {
  return {
    loading: initLoadingState({
      antifraudDictionary: false,
      saveAntifraudDictionary: false,
    }),
    errors: initErrorState(),
    notifications: initNotificationsState(),
    antifraudDictionaryForm: initialAntifraudDictionariesState,
    dropdowns: initDropdownState({
      actions: [],
    }),
  };
}

export type Actions = LoadingActions<AntifraudDictionaryFormActions>
  | NotificationsActions
  | AntifraudDictionaryFormActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  loading,
  errors,
  notifications,
  antifraudDictionaryForm,
  dropdowns,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapAntifraudDictionaryToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
