// @flow
import Joi from "@hapi/joi";
import { isTotalWeightValid } from "../../campaign";

const extendSchema = Joi.extend((joi) => ({
  type: "array",
  base: joi.array(),
  rules: {
    validateTotalWeight: {
      method() {
        return this.$_addRule({ name: "validateTotalWeight" });
      },
      validate: (value, helpers) => {
        const isValid = isTotalWeightValid(value);

        return isValid
          ? value
          : helpers.error("array.wrongWeights");
      },
    },
    validateTotalWeightOnActive: {
      method() {
        return this.$_addRule({ name: "validateTotalWeightOnActive" });
      },
      validate: (value, helpers) => {
        const activeOffers = value.filter(({ isActive }) => isActive);
        if (activeOffers.some(({ weight }) => weight === 0)) {
          return helpers.error("array.wrongWeights");
        }
        const isValid = isTotalWeightValid(activeOffers);
        return isValid
          ? value
          : helpers.error("array.wrongWeights");
      },
    },
  },
}));

export default extendSchema;
