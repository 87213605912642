// @flow
import React from "react";
import {
  Box,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import type { SelectListItem } from "./types/UserDataSource.types";

type Props = {
  selectedDataSource: string,
  dataSources: Array<SelectListItem>,
  emailPlaceholder: ?string,
  emailBase64: boolean,
  onDataSourceChange: () => {},
  onBase64Change: () => {},
  onEmailPlaceholderChange: () => {},
  error: ?boolean,
};

const UserDataSource = (props: Props) => {
  const {
    dataSources,
    selectedDataSource,
    emailPlaceholder,
    emailBase64,
    onDataSourceChange,
    onBase64Change,
    onEmailPlaceholderChange,
    error,
  } = props;

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={2}>
        <FormControl variant="outlined" fullWidth>
          <Select
            margin="dense"
            value={selectedDataSource}
            data-testid="user-data-source"
            onChange={onDataSourceChange}
          >
            {dataSources.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label || value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <TextField
          size="small"
          variant="outlined"
          value={emailPlaceholder}
          placeholder="Enter placeholder"
          onChange={onEmailPlaceholderChange}
          helperText={error ? "Invalid placeholder" : ""}
          fullWidth
          error={error}
          data-testid="user-data-source-email-placeholder"
        />
      </Grid>
      <Grid item xs={2}>
        <Box>
          <FormControlLabel
            control={(
              <Switch
                data-testid="user-data-source-base64-switch"
                checked={emailBase64}
                onChange={onBase64Change}
              />
            )}
            label="base64"
          />
        </Box>
      </Grid>
    </Grid>
  );
};
export default UserDataSource;
