// @flow
import {
  fromJS,
  List,
  Map,
  Record,
  type RecordOf,
  type RecordFactory,
} from "immutable";
import {
  ADD_FALLBACK_OFFER,
  ADD_NON_TARGET_OFFERS,
  ADD_OFFER,
  DELETE_OFFER,
  DELETE_SERVICE_OFFER,
  EQUAL_SHARES_OFFERS,
  OFFER_WEIGHT_CHANGE,
  TOGGLE_OFFER_ACTIVE_STATUS,
  CHANGE_OFFER_URL,
} from "../../helpers/constants";
import { equalShares } from "../../services/campaign";

export type Offer = {
  id: string,
  name: string,
  url: string,
  weight: number,
  withBanners: boolean,
  isActive: boolean,
};

export type OffersState = RecordOf<{
  byId: Map<{
    [key: string]: Map<Offer>,
  }>,
  allIds: List<string>,
}>;

export type State = OffersState;

export const makeState: RecordFactory<OffersState> = Record({
  byId: Map({}),
  allIds: List([]),
});

export const initialState: Map<State> = makeState({});

export function initOffersState({ offers }: { offers: OffersState }): State {
  return offers ? fromJS(offers) : initialState;
}

export default (state: State = initialState, action: any) => {
  switch (action.type) {
    case ADD_OFFER: {
      const { offerId, offer } = action;
      const {
        id, name, url, weight, withBanners, isActive,
      } = offer;

      return state.withMutations((newState) => {
        newState.setIn(
          ["byId", offerId],
          Map({
            id,
            name,
            url,
            weight,
            withBanners,
            isActive,
          })
        );
        newState.updateIn(["allIds"], (allIds) => allIds.push(offerId));
      });
    }
    case OFFER_WEIGHT_CHANGE: {
      const { offerId, weight } = action;
      return state.setIn(["byId", offerId, "weight"], weight);
    }
    case DELETE_OFFER: {
      const { offerId } = action;

      return state.withMutations((newState) => {
        newState.deleteIn(["byId", offerId]);
        newState.deleteIn([
          "allIds",
          newState.get("allIds").findIndex((el) => el === offerId),
        ]);
      });
    }
    case DELETE_SERVICE_OFFER: {
      const { offerId } = action;

      return state.withMutations((newState) => {
        newState.deleteIn(["byId", offerId]);
        newState.deleteIn([
          "allIds",
          newState.get("allIds").findIndex((el) => el === offerId),
        ]);
      });
    }
    case EQUAL_SHARES_OFFERS: {
      const { offersIds } = action;

      return state.update("byId", (offers) => equalShares(offersIds, offers));
    }
    case ADD_FALLBACK_OFFER: {
      const { offer, offerId } = action;
      const {
        id, name, url, weight, withBanners, isActive,
      } = offer;

      return state.withMutations((newState) => {
        newState.setIn(
          ["byId", offerId],
          Map({
            id,
            name,
            url,
            weight,
            withBanners,
            isActive,
          })
        );
        newState.updateIn(["allIds"], (allIds) => allIds.push(offerId));
      });
    }
    case ADD_NON_TARGET_OFFERS: {
      const { offer, offerId } = action;
      const {
        id, name, url, weight, withBanners, isActive,
      } = offer;

      return state.withMutations((newState) => {
        newState.setIn(
          ["byId", offerId],
          Map({
            id,
            name,
            url,
            weight,
            withBanners,
            isActive,
          })
        );
        newState.updateIn(["allIds"], (allIds) => allIds.push(offerId));
      });
    }
    case TOGGLE_OFFER_ACTIVE_STATUS: {
      const { offerId, isActive } = action;
      return state.setIn(["byId", offerId, "isActive"], isActive);
    }
    case CHANGE_OFFER_URL: {
      const { offerId, url } = action;
      return state.setIn(["byId", offerId, "url"], url.trim());
    }
    default:
      return state;
  }
};
