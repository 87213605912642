// @flow
import React from "react";
import { Typography } from "@mui/material";
import environment from "environment";

const FunnelTemplateListBreadcrumbText = {
  component: <Typography>Funnel Templates</Typography>,
  path: "/funnelTemplates/list",
};

const FunnelTemplateListBreadcrumbLink = {
  path: "/funnelTemplates/list",
  title: "Funnel Templates",
};

const SmartlinkBreadcrumbLink = {
  path: environment.endpoints.redirect.manageCampaigns,
  title: "Smartlink",
};

const SmartlinkAggregatorsBreadcrumbLink = {
  path: environment.endpoints.redirect.smartlinkAggregatorsList,
  title: "Smartlink Aggregators",
};

const HistoryReplaceListBreadcrumbLink = {
  path: environment.endpoints.redirect.historyReplaceList,
  title: "Replace LP History",
};

const AntifraudTriggersBreadcrumbLink = {
  path: environment.endpoints.redirect.antifraudTriggersList,
  title: "Antifraud Triggers",
};

const AntifraudDictionariesBreadcrumbLink = {
  path: environment.endpoints.redirect.antifraudDictionariesList,
  title: "Antifraud Dictionaries",
};

const AntifraudScenariosBreadcrumbLink = {
  path: environment.endpoints.redirect.antifraudScenariosList,
  title: "Antifraud Scenarios",
};

const AntifraudVariablesBreadcrumbLink = {
  path: environment.endpoints.redirect.antifraudVariablesList,
  title: "Antifraud Variables",
};

const FrequencyCupForJumpsBreadcrumbLink = {
  path: environment.endpoints.redirect.frequencyCupForJumps,
  title: "Frequency Cap For Jumps",
};

const GeneralFilterBreadcrumbLink = {
  path: environment.endpoints.redirect.generalFilter,
  title: "General Filters",
};

const breadcrumbsMap = {
  "/smartlink": [
    SmartlinkBreadcrumbLink,
    { component: <Typography>New Campaign</Typography>, path: "/smartlink" },
  ],
  "/smartlinkAggregators/list": [
    SmartlinkAggregatorsBreadcrumbLink,
  ],
  "/smartlinkAggregators": [
    SmartlinkAggregatorsBreadcrumbLink,
    { component: <Typography>Create Smartlink Redirect</Typography>, path: "/smartlinkAggregators" },
  ],
  "/historyReplace/list": [
    HistoryReplaceListBreadcrumbLink,
  ],
  // FIX 92090
  "/generalFilter/add": [
    { path: environment.endpoints.redirect.generalFilter, title: "General Filter" },
    { component: <Typography>New General Filter</Typography>, path: "/generalFilter/add" },
  ],
  "/generalFilter/list": [
    { path: environment.endpoints.redirect.generalFilter, title: "General Filters" },
  ],
  "/generalFilter": [
    GeneralFilterBreadcrumbLink,
    { component: <Typography>New General Filter</Typography>, path: "/generalFilter" },
  ],
  "/funnelTemplates": [
    FunnelTemplateListBreadcrumbLink,
    { component: <Typography>New Funnel Template</Typography>, path: "/funnelTemplates" },
  ],
  "/funnelTemplates/list": [FunnelTemplateListBreadcrumbText],
  "/funnelTemplates/report": [FunnelTemplateListBreadcrumbText],
  "/funneltemplates": [FunnelTemplateListBreadcrumbLink],
  "/antifraudTriggers/list": [AntifraudTriggersBreadcrumbLink],
  "/antifraudDictionaries/list": [
    AntifraudDictionariesBreadcrumbLink,
  ],
  "/antifraudTriggers": [
    AntifraudTriggersBreadcrumbLink,
    { component: <Typography>Create trigger</Typography>, path: "/antifraudTriggers" },
  ],
  "/antifraudDictionaries": [
    AntifraudDictionariesBreadcrumbLink,
    { component: <Typography>Create dictionary</Typography>, path: "/antifraudDictionaries" },
  ],
  "/antifraudScenarios/list": [
    AntifraudScenariosBreadcrumbLink,
  ],
  "/antifraudScenarios": [
    AntifraudScenariosBreadcrumbLink,
    { component: <Typography>Create scenario</Typography>, path: "/antifraudScenarios" },
  ],
  "/antifraudVariables/list": [
    AntifraudVariablesBreadcrumbLink,
  ],
  "/antifraudVariables": [
    AntifraudVariablesBreadcrumbLink,
    { component: <Typography>Create variable</Typography>, path: "/antifraudVariables" },
  ],
  "/frequencyCapForJumps": [
    FrequencyCupForJumpsBreadcrumbLink,
  ],
};

function getBreadcrumbs(location: string): Array<*> {
  if (breadcrumbsMap[location]) {
    return breadcrumbsMap[location];
  }

  // match /funnelTemplates/view/:id
  if (location.match(/\/funnelTemplates\/view\/.+/g)) {
    const {
      // $FlowFixMe
      groups: { id },
    } = location.match("/funnelTemplates/view/(?<id>.+)");
    return [
      FunnelTemplateListBreadcrumbLink,
      { component: <Typography>{id.split("/")[0]}</Typography>, path: location },
    ];
  }
  // match /funnelTemplates/:id
  if (location.match(/\/funnelTemplates\/.+/g)) {
    const {
      // $FlowFixMe
      groups: { id },
    } = location.match("/funnelTemplates/(?<id>.+)");

    return [
      FunnelTemplateListBreadcrumbLink,
      { component: <Typography>{`ID: ${id}`}</Typography>, path: location },
    ];
  }

  // match /smartlink/:id
  if (location.match(/\/smartlink\/.+/g)) {
    const {
      // $FlowFixMe
      groups: { id },
    } = location.match("/smartlink/(?<id>.+)");

    return [
      SmartlinkBreadcrumbLink,
      { component: <Typography>{`ID: ${id}`}</Typography>, path: location },
    ];
  }

  if (location.match("/smartlinkAggregators/campaigns")) {
    return [
      SmartlinkAggregatorsBreadcrumbLink,
      { component: <Typography>Smartlink aggregators to campaigns</Typography>, path: location },
    ];
  }
  // match /smartlinkAggregators/:id
  if (location.match(/\/smartlinkAggregators\/.+/g)) {
    const {
      // $FlowFixMe
      groups: { id },
    } = location.match("/smartlinkAggregators/(?<id>.+)");
    return [
      SmartlinkAggregatorsBreadcrumbLink,
      { component: <Typography>{`ID: ${id}`}</Typography>, path: location },
    ];
  }

  // match /generalFilter/:id
  if (location.match(/\/generalFilter\/.+/g)) {
    const {
      // $FlowFixMe
      groups: { id },
    } = location.match("/generalFilter/(?<id>.+)");

    return [
      GeneralFilterBreadcrumbLink,
      { component: <Typography>{`ID: ${id}`}</Typography>, path: location },
    ];
  }

  if (location.match(/\/historyReplace\/view\/.+/g)) {
    const {
      // $FlowFixMe
      groups: { id },
    } = location.match("/historyReplace/view/(?<id>.+)");
    return [
      HistoryReplaceListBreadcrumbLink,
      { component: <Typography>{id.split("/")[0]}</Typography>, path: location },
    ];
  }

  // match /antifraudDictionaries/:id
  if (location.match(/\/antifraudDictionaries\/.+/g)) {
    const {
      // $FlowFixMe
      groups: { id },
    } = location.match("/antifraudDictionaries/(?<id>.+)");

    return [
      AntifraudDictionariesBreadcrumbLink,
      { component: <Typography>{`ID: ${id}`}</Typography>, path: location },
    ];
  }

  // match /antifraudScenarios/:id
  if (location.match(/\/antifraudScenarios\/.+/g)) {
    const {
      // $FlowFixMe
      groups: { id },
    } = location.match("/antifraudScenarios/(?<id>.+)");

    return [
      AntifraudScenariosBreadcrumbLink,
      { component: <Typography>{`ID: ${id}`}</Typography>, path: location },
    ];
  }

  if (location.match(/\/antifraudTriggers\/.+/g)) {
    const {
      // $FlowFixMe
      groups: { id },
    } = location.match("/antifraudTriggers/(?<id>.+)");

    return [
      AntifraudTriggersBreadcrumbLink,
      { component: <Typography>{`ID: ${id}`}</Typography>, path: location },
    ];
  }

  // match /antifraudVariables/:id
  if (location.match(/\/antifraudVariables\/.+/g)) {
    const {
      // $FlowFixMe
      groups: { id },
    } = location.match("/antifraudVariables/(?<id>.+)");

    return [
      AntifraudVariablesBreadcrumbLink,
      { component: <Typography>{`ID: ${id}`}</Typography>, path: location },
    ];
  }

  return [];
}
export default getBreadcrumbs;
