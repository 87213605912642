// @flow
import { connect } from "react-redux";
import SearchComponent from "../../components/SearchComponent";
import {
  getFunnelSearchResult,
  getFunnelSearchScrollToItem,
  getFunnelSearchText,
} from "../../selectors/smartlink";
import { searchFunnels, setSearchData } from "../../actions/search";

type OwnProps = $ReadOnly<{||}>;

type StateProps = $ReadOnly<{|
  result: string[],
  searchText: string,
  scrollToItem?: string,
|}>

type DispatchProps = $ReadOnly<{|
  onChange: typeof searchFunnels,
  onChangeSearchData: typeof setSearchData,
|}>

type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps
|}>;

const mapStateToProps = (state): StateProps => ({
  result: getFunnelSearchResult(state),
  searchText: getFunnelSearchText(state),
  scrollToItem: getFunnelSearchScrollToItem(state),
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onChange: (text: string) => dispatch(searchFunnels(text)),
  onChangeSearchData: (data) => dispatch(setSearchData(data)),
});

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SearchComponent);
