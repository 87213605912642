// @flow
import { GET_ANTIFRAUD_DICTIONARIES_LIST_SAGA } from "../../helpers/constants";

export type GetAntifraudDictionariesListSaga = {
  type: typeof GET_ANTIFRAUD_DICTIONARIES_LIST_SAGA,
}

export type Actions = GetAntifraudDictionariesListSaga;

// eslint-disable-next-line import/prefer-default-export
export const getAntifraudDictionariesListSaga: () => GetAntifraudDictionariesListSaga = () => ({
  type: GET_ANTIFRAUD_DICTIONARIES_LIST_SAGA,
});
