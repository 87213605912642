// @flow
import {
  URL_TYPE_RICHMEDIA,
  URL_TYPE_IFRAME,
  URL_TYPE_API,
  URL_TYPE_AE,
  URL_TYPE_AEAPI,
  URL_TYPE_SHORTURL,
} from "../constants";
import UrlType, * as UrlTypeModels from "./UrlTypeFactory";
import type { Props } from "./UrlTypeFactory";

export const getUrlTypeModel: (props: { ...Props, type: string }) => UrlType = ({
  type,
  ...rest
}) => {
  switch (type) {
    case URL_TYPE_AE:
      return new UrlTypeModels.AEUrlType(rest);
    case URL_TYPE_AEAPI:
      return new UrlTypeModels.AEApiUrlType(rest);
    case URL_TYPE_API:
      return new UrlTypeModels.ApiUrlType(rest);
    case URL_TYPE_IFRAME:
      return new UrlTypeModels.IFrameUrlType(rest);
    case URL_TYPE_RICHMEDIA:
      return new UrlTypeModels.RichMediaUrlType(rest);
    case URL_TYPE_SHORTURL:
      return new UrlTypeModels.ShortLinkUrlType(rest);
    default:
      return new UrlType(rest);
  }
};

export default UrlType;
