// @flow
import { OrderedMap } from "immutable";
import {
  CHANGE_PLACEHOLDER,
  CHANGE_PLACEHOLDER_VALUE,
  CLICK_PRESET,
  CLEAR_PLACEHOLDERS,
} from "../../helpers/constants";
import type { PlaceholdersActionType } from "../../actions/placeholders";
import type { Campaign } from "../campaignInfo/reducer";

export type PlaceholdersState = {
  [string]: string,
};

export type State = OrderedMap<PlaceholdersState>;

export const initialState: OrderedMap<State> = OrderedMap({});

export function initPlaceholdersState(campaign: Campaign) {
  let state = initialState;
  const { placeholders } = campaign;
  if (placeholders) {
    state = OrderedMap(placeholders);
  }
  return state;
}

type Action = Object
| PlaceholdersActionType;

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case CHANGE_PLACEHOLDER: {
      const {
        name,
        value = `{${name}}`,
      } = action;
      if (state.has(name)) {
        return state.delete(name);
      }
      return state.set(name, value);
    }
    case CHANGE_PLACEHOLDER_VALUE: {
      const {
        name,
        value = `{${name}}`,
      } = action;

      return state.set(name, value);
    }
    case CLICK_PRESET: {
      const {
        placeholders,
      } = action;

      return state.withMutations((newState) => {
        placeholders.forEach(({ name, value }) => {
          newState.set(name, value);
        });
      });
    }
    case CLEAR_PLACEHOLDERS: {
      return state.clear();
    }
    default:
      return state;
  }
};
