// @flow
import React, { useState, useEffect, useMemo } from "react";

import {
  Box, Autocomplete, TextField, Chip,
} from "@mui/material";
import FunnelItem from "../FunnelItem";
import FunnelListHeader from "../FunnelListHeader";

import type { FunnelsDescription, Funnels } from "./types/FunnelTemplateFunnelList.types";

type Props = {
  funnels: Funnels,
  splitId: string,
  getTargetingCountByFunnelId: (id: string) => number,
  getFunnelDescriptionByFunnelId: (id: string) => string,
  handleOpenFunnel: (splitId: string, funnelId: string) => void,
}

const FunnelTemplateFunnelList = ({
  funnels,
  splitId,
  getFunnelDescriptionByFunnelId,
  getTargetingCountByFunnelId,
  handleOpenFunnel,
}: Props) => {
  const [filtersData, setFiltersData] = useState([]);
  const [filteredFunnelsId, setFilteredFunnelsId] = useState([]);

  useEffect(() => {
    updateFilteredFunnels();
  }, [filtersData]);

  const funnelsDescriptionByFunnelId: FunnelsDescription = useMemo(() => Object
    .keys(funnels)
    .reduce((acc, funnelId) => {
      const params = getFunnelDescriptionByFunnelId(funnelId).toLowerCase().split("-");
      acc[funnelId] = params;
      return acc;
    }, {}), [funnels]);

  const getFilteredFunnelsIdByDescription = (funnelsById: FunnelsDescription, searchValues: string[]) => Object
    .keys(funnelsById)
    .filter((key) => searchValues.every((val: string) => funnelsById[key].includes(val.toLowerCase())));

  const updateFilteredFunnels = () => {
    const filteredFunnelsIdByDescription: string[] = getFilteredFunnelsIdByDescription(
      funnelsDescriptionByFunnelId, filtersData
    );
    setFilteredFunnelsId(filteredFunnelsIdByDescription);
  };

  return (
    <Box>
      <Box mb={2}>
        <Autocomplete
          multiple
          id="funnels-filters"
          size="small"
          options={[]}
          freeSolo
          renderTags={(value: string[], getTagProps) => value.map((option: string, index: number) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Target params"
              placeholder="Enter target params"
            />
          )}
          onChange={(e, values) => setFiltersData(values)}
        />
      </Box>
      <FunnelListHeader
        columns={
          [
            {
              width: 1,
              title: "#",
              dataTestid: "number",
              noWrap: true,
            },
            {
              width: 3,
              title: "Name",
              dataTestid: "name",
              noWrap: true,
            },
            {
              width: 1,
              title: "Targetings",
              dataTestid: "targetings",
              noWrap: true,
            },
            {
              width: 1,
              title: "Offers",
              dataTestid: "offers",
              noWrap: true,
            },
            {
              width: 1,
              title: "Filtering by email",
              dataTestid: "filterings",
              noWrap: true,
            },
            {
              width: 1,
              title: "Status",
              dataTestid: "isActive",
              noWrap: true,
            },
            {
              width: 1,
              title: "Hits",
              dataTestid: "hits",
              noWrap: true,
            },
            { width: 3, dataTestid: "empty-header-cell-last", noWrap: false },
          ]
        }
      />

      <Box px={1}>
        {filteredFunnelsId.map((id) => {
          const {
            isActive,
            name,
            hits,
            offers = [],
            filterings = [],
          } = funnels[id];
          return (
            <Box key={`funnel-template-item-${id}`}>
              <FunnelItem
                id={id}
                title={name}
                hits={hits}
                text={getFunnelDescriptionByFunnelId(id)}
                isActive={isActive}
                countFilterings={filterings.length}
                countOffers={offers.length}
                countTargetings={getTargetingCountByFunnelId(id)}
                onShow={() => handleOpenFunnel(splitId, id)}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default FunnelTemplateFunnelList;
