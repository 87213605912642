// @flow
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  FETCH_GENERAL_FILTERS_SAGA,
  START_FETCH_GENERAL_FILTERS,
} from "../../helpers/constants";
import { setGeneralFilters } from "../../actions/generalFilters";
import CampaignApi from "../../services/smartlinkApi";
import { getCampaignId } from "../../selectors/smartlink";

export function* makeFetch(): Saga<void> {
  yield put(setLoading("generalFilters", true));

  try {
    yield put({ type: START_FETCH_GENERAL_FILTERS });

    const campaignId = yield select((state) => getCampaignId(state));
    const response = yield call(CampaignApi.getGeneralFilters, campaignId);

    const { filters } = response.data;
    yield put(setGeneralFilters(filters));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
  }
  finally {
    yield put(setLoading("generalFilters", false));
  }
}

export default function* fetchGeneralFiltersSaga(): Saga<void> {
  yield takeEvery(FETCH_GENERAL_FILTERS_SAGA, makeFetch);
}
