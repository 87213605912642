// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import SmartlinkAggregatorsToCampaignsList from "../../containers/SmartlinkAggregatorsToCampaigns";

function SmartlinkAggregatorsToCampaigns() {
  return (
    <DocumentTitle title="Smartlink Aggregators in campaigns">
      <SmartlinkAggregatorsToCampaignsList />
    </DocumentTitle>
  );
}

export default SmartlinkAggregatorsToCampaigns;
