// @flow
import {
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { SAVE_SPLIT_SAGA } from "../../helpers/constants";
import { clearBackup } from "../../actions/backup";
import { closeSplitModal } from "../../actions/modals/actions";
import {
  getFunnelsBySplitId,
  getFunnelNamesByIds,
} from "../../selectors/smartlink";
import { showConfirmation } from "../confirmSaga/confirmSaga";

export function* saveSplitSaga(action: any): Saga<void> {
  const { splitId } = action;

  const FunnelIdsBySplitId = yield select(getFunnelsBySplitId, splitId);
  const FunnelsAllNames = yield select(getFunnelNamesByIds, FunnelIdsBySplitId);

  const isFunnelsAllNamesUnique = (new Set(FunnelsAllNames)).size === FunnelsAllNames.length;

  if (!isFunnelsAllNamesUnique) {
    const message = "You have 2 or more funnels with the same name. Are you sure you want to save this split?";
    const confirmed = yield showConfirmation({ message });

    if (!confirmed) {
      return;
    }
  }

  yield put(closeSplitModal());
  yield put(clearBackup());
}

export default function* watchSaveSplit(): Saga<void> {
  yield takeEvery(SAVE_SPLIT_SAGA, saveSplitSaga);
}
