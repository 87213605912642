// @flow
import { List, fromJS } from "immutable";
import {
  SET_ACTIVE_GENERAL_FILTERS,
  SET_ALL_ACTIVE_GENERAL_FILTERS,
  SET_GENERAL_FILTERS,
} from "../../helpers/constants";
import type { Actions as GeneralFiltersActionType } from "../../actions/generalFilters";
import type { Setting } from "../../components/FilterSettingsList/types/FilterSettingsList.types";
import type { Campaign } from "../campaignInfo/reducer";

export type State = List<Setting>;
export const initialState: State = List([]);

export function initGeneralFiltersState(campaign: Campaign) {
  const { generalFilters }: Campaign = campaign;
  const state = generalFilters ? fromJS(generalFilters) : initialState;
  return state;
}
type Action = GeneralFiltersActionType
  | Object;

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_ALL_ACTIVE_GENERAL_FILTERS: {
      return state.map((item) => item.set("isFilterActiveOnCampaign", action.value));
    }
    case SET_ACTIVE_GENERAL_FILTERS: {
      return state.setIn([action.index, "isFilterActiveOnCampaign"], action.value);
    }
    case SET_GENERAL_FILTERS: {
      return fromJS(action.payload);
    }
    default:
      return state;
  }
};
