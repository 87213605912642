// @flow
import Joi from "@hapi/joi";
import { Map } from "immutable";
import WithWeights from "./weights";
import type { JoiError, Errors } from "../types/Base";
import type { BaseSimpleErrorsRule } from "./Base";
import { OFFERS_EMPTY, WEIGHTS } from "../messages";
import type { Offer } from "../../../reducers/offers";

export default class Offers implements BaseSimpleErrorsRule {
  schema: Object;

  errors: Errors;

  errorKey: string;

  constructor() {
    this.errors = {};
    this.errorKey = "offers";

    this.schema = WithWeights.array()
      .min(1)
      .items(
        Joi.object({
          weight: Joi.number()
            .integer()
            .min(0)
            .max(100),
          url: Joi.string()
            .custom((value) => {
              const nValue = Number(value);
              if (!isNaN(nValue)) {
                throw new Error("Can't be a number");
              }
              let matchValue = value.match("^[^/]");
              if (matchValue) {
                throw new Error(`Can't start with ${matchValue[0]}`);
              }
              // eslint-disable-next-line no-useless-escape
              matchValue = value.match(/\s/g);
              if (matchValue) {
                throw new Error("Cant have spaces");
              }

              const lengthKey = value.split("&");
              const lengthVal = value.split("=");
              if (lengthKey.length + 1 !== lengthVal.length) {
                throw new Error("Url is wrong, check get parameters");
              }
              const countQues = value.split("?");
              if (countQues.length !== 2) {
                throw new Error("Url is wrong, check get parameters");
              }
              if (value.indexOf("?=") > 0 || value.indexOf("=&") > 0 || value.indexOf("&=") > 0) {
                throw new Error("Url is wrong, check get parameters");
              }
              if (value.split("=").includes("")) {
                throw new Error("Url is wrong, check get parameters");
              }

              return value;
            })
            .optional()
            .trim(),
          withBanners: Joi.boolean(),
        }).unknown(true)
      )
      .validateTotalWeightOnActive()
      .error((errors) => this._onError(errors));
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach((item) => {
      const weightKey = `${this.errorKey}Weights`;
      switch (item.code) {
        case "array.min": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: OFFERS_EMPTY },
          };
          break;
        }
        case "any.custom": {
          // $FlowFixMe
          const message = (item.local.error && item.local.error.message) || "Error";

          this.errors = {
            ...this.errors,
            [this.errorKey]: { message },
          };
          break;
        }
        case "custom": {
          // $FlowFixMe
          const message = (item.local.error && item.local.error.message) || "Error";

          this.errors = {
            ...this.errors,
            [this.errorKey]: { message },
          };
          break;
        }
        case "string.empty": {
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: "Can`t be empty" },
          };
          break;
        }
        case "number.integer":
        case "number.min":
        case "number.max":
        case "array.wrongWeights": {
          this.errors = {
            ...this.errors,
            [weightKey]: { message: WEIGHTS },
          };
          break;
        }
        default:
          this.errors = {
            ...this.errors,
            [this.errorKey]: { message: item.message },
          };
          break;
      }
    });

    return errors;
  }

  getErrors(data: Map<Offer>): Errors {
    this.schema.validate(data.valueSeq().toJS());
    return this.errors;
  }
}
