// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  GET_ANTIFRAUD_TRIGGER_SAGA,
} from "../../helpers/constants";
import {
  setAntifraudTrigger,
  type GetAntifraudTriggerSaga,
} from "../../actions/antifraudTriggerForm";
import { getAntifraudTrigger } from "../../services/antifraudTriggersApi";

export function* makeFetch(action: GetAntifraudTriggerSaga): Saga<void> {
  try {
    const { id }: GetAntifraudTriggerSaga = action;
    yield put(setLoading("isGetAntifraudTriggerLoading", true));

    const {
      data: {
        trigger,
      },
    }: * = yield call(getAntifraudTrigger, id);

    yield put(setAntifraudTrigger(trigger));
  }
  catch (error) {
    yield put(addNotification({ message: error.message, severity: "error" }));
  }
  finally {
    yield put(setLoading("isGetAntifraudTriggerLoading", false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_ANTIFRAUD_TRIGGER_SAGA, (makeFetch: Function));
}
