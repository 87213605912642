// @flow
import React from "react";
import { connect } from "react-redux";
import type { Map } from "immutable";
import {
  getFunnelDescriptionByFunnelId,
  getTargetingCountByFunnelId,
  getFunnelsObject,
} from "../../selectors/smartlink";
import {
  getFunnelTemplateId,
} from "../../selectors/templates";
import {
  openFunnelSaga,
} from "../../actions/funnels";
import FunnelTemplateFunnelList from "../../components/FunnelTemplateFunnelList";
import SmartlinkFunnel from "../SmartlinkFunnel";
import type { Funnel } from "../../reducers/funnels";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  templateId: string,
  funnels: {
    [key: string]: Map<Funnel>
  },
  getFunnelDescription: (funnelId: string) => string,
  getTargetingCount: (funnelId: string) => number,
  handleOpenFunnel: (splitId: string, funnelId: string) => void,
|}>;

const mapStateToProps = (state) => ({
  templateId: getFunnelTemplateId(state),
  funnels: getFunnelsObject(state),
  getFunnelDescription: (funnelId) => getFunnelDescriptionByFunnelId(state, funnelId),
  getTargetingCount: (funnelId) => getTargetingCountByFunnelId(state, funnelId),
});
const mapDispatchToProps = (dispatch) => ({
  handleOpenFunnel: (splitId, funnelId) => dispatch(openFunnelSaga(splitId, funnelId)),
});

const FunnelTemplateFunnels = ({
  templateId,
  funnels,
  getFunnelDescription,
  getTargetingCount,
  handleOpenFunnel,
}: Props) => (
  <>
    <FunnelTemplateFunnelList
      templateId={templateId}
      funnels={funnels}
      splitId="1"
      getFunnelDescriptionByFunnelId={getFunnelDescription}
      getTargetingCountByFunnelId={getTargetingCount}
      handleOpenFunnel={handleOpenFunnel}
    />
    <SmartlinkFunnel />
  </>
);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(FunnelTemplateFunnels);
