// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useEffect,
  useRef,
  useState,
  type StatelessFunctionalComponent,
} from "react";
import { useDispatch } from "react-redux";
import { SetupCard, Loader } from "@fas/ui-core";
import { Box, TextField, Button } from "@mui/material";
import debounce from "lodash.debounce";
import { IS_CHANGE_IS_FREQUENCY_CAP_LOADING } from "../../helpers/constants";
import { changeIsFrequencyCap } from "../../actions/frequencyCapForJumps";
import type { FrequencyCapForJumps } from "../FrequencyCapForJumps";
import { fetchFrequencyCapForJumpsList } from "../../services/frequencyCapForJumps";
import { useLoading } from "../../hooks";
import type { StateType } from "../../helpers/types";

const AddCapForJumpsList: StatelessFunctionalComponent<{}> = () => {
  const [jumpId, setJumpId]: StateType<string> = useState("");
  const [jump, setJump]: StateType<FrequencyCapForJumps | null> = useState(null);
  const [isJumpNameLoading, setIsJumpNameLoading]: StateType<boolean> = useState(false);

  const isActivationLoading: boolean = useLoading(IS_CHANGE_IS_FREQUENCY_CAP_LOADING);
  const dispatch: <A>(A) => A = useDispatch();

  const onSetJumpId = ({ target: { value } }: SyntheticInputEvent<HTMLInputElement>) => {
    setJumpId(value);
  };

  const fetchJumpName = useRef(debounce(async (id: string) => {
    if (id) {
      try {
        setIsJumpNameLoading(true);
        const { data } = await fetchFrequencyCapForJumpsList({
          page: 1, pageSize: 1, filters: { jump_key: id, useGlobalList: true },
        });
        setJump(data.length > 0 ? data[0] : null);
        setIsJumpNameLoading(false);
      }
      catch (err) {
        setIsJumpNameLoading(false);
      }
    }
    else {
      setJump(null);
    }
  }, 1000));

  useEffect(() => {
    fetchJumpName.current(jumpId);
  }, [jumpId]);

  useEffect(() => {
    setJumpId("");
    setJump(null);
  }, [isActivationLoading]);

  return (
    <Box px={3} width={1}>
      <SetupCard title="Activate Frequency Cap For Jump">
        <Box display="flex" width={1} gap={2}>
          <TextField
            size="small"
            label="Jump id"
            value={jumpId}
            onChange={onSetJumpId}
          />
          <Loader loading={isJumpNameLoading}>
            <TextField
              size="small"
              label="Jump name"
              sx={{ width: 300 }}
              disabled
              value={jump ? jump.name : "-"}
            />
          </Loader>
          <Button
            variant="contained"
            disabled={!jump || jump?.isFrequencyCap || isActivationLoading}
            onClick={() => {
              if (jump) {
                dispatch(changeIsFrequencyCap({ items: [jump], isFrequencyCap: true }));
              }
            }}
          >
            Activate
          </Button>
          <Button
            variant="contained"
            disabled={!jump || !jump?.isFrequencyCap || isActivationLoading}
            onClick={() => {
              if (jump) {
                dispatch(changeIsFrequencyCap({ items: [jump], isFrequencyCap: false }));
              }
            }}
          >
            Deactivate
          </Button>
        </Box>
      </SetupCard>
    </Box>
  );
};

export default AddCapForJumpsList;
