// @flow
import {
  call,
  put,
  debounce,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { setTableData, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  GET_TEMPLATE_LIST_SAGA,
  FUNNELS_TEMPLATE_LIST_TABLE,
} from "../../helpers/constants";
import { getFunnelsTemplateList } from "../../services/templateApi/template";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(FUNNELS_TEMPLATE_LIST_TABLE, true));

    const page: number = yield select(getTablePage, FUNNELS_TEMPLATE_LIST_TABLE);
    const pageSize: number = yield select(getTablePageSize, FUNNELS_TEMPLATE_LIST_TABLE);
    const sorting: Sorting = yield select(getTableSorting, FUNNELS_TEMPLATE_LIST_TABLE);
    const filters: Filters = yield select(getTableFilters, FUNNELS_TEMPLATE_LIST_TABLE);

    const {
      data: { count, data },
    } = yield call(getFunnelsTemplateList, {
      page,
      pageSize,
      filters,
      sorting,
    });

    yield put(setTableData(FUNNELS_TEMPLATE_LIST_TABLE, data));
    yield put(changeTableItemsTotalAmount(FUNNELS_TEMPLATE_LIST_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
  }
  finally {
    yield put(setLoading(FUNNELS_TEMPLATE_LIST_TABLE, false));
  }
}

export default function* getTemplatesListSaga(): Saga<void> {
  // $FlowFixMe
  yield debounce(1000, GET_TEMPLATE_LIST_SAGA, makeFetch);
}
