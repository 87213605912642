// @flow
import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import type { Props } from "./types/GeneralInfoTable.types";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderRadius: 0,
  },
  tableCell: {
    border: "none",
    padding: theme.spacing(1),
  },
  tableHeader: {
    background: theme.palette.background.blueBackgroundLight,
    textTransform: "uppercase",
  },
}));

const GeneralInfoTable = ({
  data,
  columns,
}: Props) => {
  const classes = useStyles();
  return (
    <TableContainer
      className={classes.tableContainer}
    >
      <Table data-testid="general-info-table">
        <TableHead
          className={classes.tableHeader}
        >
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.field}
                className={classes.tableCell}
              >
                <Typography variant="subtitle1">
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {columns.map((column) => {
              const item = data.get(column.field) || "";

              return (
                <TableCell
                  key={column.field}
                  className={classes.tableCell}
                >
                  <Typography variant="body1">
                    {(column.format && column.format(item)) || item}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GeneralInfoTable;
