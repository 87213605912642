import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ExternalRedirect from "./ExternalRedirect";
import Can from "../Can";

export default ({ permissions, component: Component }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Can permissions={permissions} renderNoAccess={<ExternalRedirect to="/accessDenied" />}>
      <Component location={location} navigate={navigate} params={params} />
    </Can>
  );
};
