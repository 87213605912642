// @flow
import {
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { DELETE_FUNNEL_SAGA } from "../../helpers/constants";
import { deleteFunnel } from "../../actions/funnels";
import { deleteTargeting } from "../../actions/targetings";
import {
  getActionOptionsParamsByFunnelId,
  getFilteringsByFunnelId,
  getOffersByFunnelId,
  getTargetingIdsByFunnelId,
} from "../../selectors/smartlink";
import { deleteActionOptionsParams } from "../../actions/actionOptionsParams";
import { deleteFiltering } from "../../actions/filterings";
import type { DeleteAction } from "../../actions/funnels";
import { deleteOffer } from "../../actions/offers";

export function* deleteFunnelSaga(action: DeleteAction): Saga<void> {
  const { splitId, funnelId } = action;
  const targetingsIds = yield select(getTargetingIdsByFunnelId, funnelId);
  const filteringsIds = yield select(getFilteringsByFunnelId, funnelId);
  const actionOptionsParamses = yield select(getActionOptionsParamsByFunnelId, funnelId);
  const offersIds = yield select(getOffersByFunnelId, funnelId);

  for (let i = 0; i < offersIds.size; i++) {
    yield put(deleteOffer(funnelId, offersIds.get(i)));
  }

  for (let i = 0; i < filteringsIds.size; i++) {
    yield put(deleteFiltering(funnelId, filteringsIds.get(i)));
  }

  for (let i = 0; i < actionOptionsParamses.size; i++) {
    yield put(deleteActionOptionsParams(funnelId, actionOptionsParamses.get(i)));
  }

  yield put(deleteFunnel(splitId, funnelId));
  yield put(deleteTargeting({ targetingsIds }));
}

export default function* watchDeleteFunnel(): Saga<void> {
  yield takeEvery(DELETE_FUNNEL_SAGA, deleteFunnelSaga);
}
