// @flow
import React from "react";
import {
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { SetupCard } from "@fas/ui-core";

type Props = $ReadOnly<{|
  name: string,
  type: string,
  id: string,
  funnelsCount: number,
|}>;

const TemplatePreview = ({
  name,
  type,
  id,
  funnelsCount,
}: Props) => (
  <SetupCard title="Funnels template preview">
    <>
      <Box width={1} mb={2}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
          <Grid item xs={3}>
            <Typography>Id</Typography>
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={8}>
              <Typography>{id}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box width={1} mb={2}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
          <Grid item xs={3}>
            <Typography>Name</Typography>
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={8}>
              <Typography>{name}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box width={1} mb={2}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
          <Grid item xs={3}>
            <Typography>Template type</Typography>
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={8}>
              <Typography>{type}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box width={1} mb={2}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
          <Grid item xs={3}>
            <Typography>Funnels</Typography>
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={8}>
              <Typography>{funnelsCount}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  </SetupCard>
);

export default TemplatePreview;
