import {
  takeEvery,
  put,
} from "redux-saga/effects";
import { CLOSE_FUNNEL_SAGA } from "../../helpers/constants";
import { closeFunnelModal } from "../../actions/modals";
import { restoreBackup } from "../../actions/backup/actions";

export function* closeFunnelSaga() {
  yield put(closeFunnelModal());
  yield put(restoreBackup());
}

export default function* watchCloseFunnel() {
  yield takeEvery(CLOSE_FUNNEL_SAGA, closeFunnelSaga);
}
