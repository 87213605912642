// @flow
import environment from "environment";
import qs from "qs";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import type {
  Filters,
  Sorting,
} from "@fas/ui-framework/lib/redux/reducers/table";
import { requestService } from "../request";
import type { Trigger as TriggerFormItem } from "../../selectors/antifraudTrigger";

export type Trigger = {
  ruleId: number,
  name: string,
  conditions: string,
  score: number,
  isActive: boolean
}

export type AntifraudTriggerListResponseType = {
  data: {
    data: {
      list: Array<Trigger>,
      count: number,
    },
  },
}

type VariablesList = [
  {
    name: {
      label: string,
      value: string,
    },
    type: string,
    operators: string[],
  }
];

export type VariableValues = {
  variable: string,
  values: string[]
}

export const getAntifraudTriggerList: (
  options: {
    page: number,
    limit: number,
    filters: Filters,
    sorting: Sorting,
  },
) => PromiseResponse<AntifraudTriggerListResponseType> = (
  options
) => requestService
  .get<AntifraudTriggerListResponseType>(environment.endpoints.get.antifraudTriggerList, {
    params: { ...options },
    paramsSerializer: ({ filters, sorting, ...rest }: *): string => qs.stringify({
      ...rest,
      filters: Object.keys(filters).length !== 0 ? filters : "{}", // TODO: change contracts, fix backend and front in MTU-56306
      sorting: Object.keys(sorting).length !== 0 ? sorting : "{}", // TODO: change contracts, fix backend and front in MTU-56306
    }, {
      arrayFormat: "brackets",
      encode: true,
      encodeValuesOnly: true,
    }),
  });

export const changeAntifraudTriggerActive: (
  ids: number[],
  status: boolean
) => PromiseResponse<{}> = (ids, status) => requestService
  .post(environment.endpoints.post.antifraudTriggerActive, { ids, status });

export const getAntifraudTrigger: (id: string) => PromiseResponse<{ trigger: TriggerFormItem }> = (id) => requestService
  .get<{ trigger: TriggerFormItem }>(environment.endpoints.get.antifraudTrigger, {
    params: {
      ruleId: id,
    },
  })
  .then(({ data }) => data);

export const createAntifraudTrigger: (trigger: TriggerFormItem) => PromiseResponse<{}> = (trigger) => requestService
  .post(environment.endpoints.post.antifraudTrigger, { ...trigger });

export const saveAntifraudTrigger: (trigger: TriggerFormItem) => PromiseResponse<{}> = (trigger) => requestService
  .put(environment.endpoints.put.antifraudTrigger, { ...trigger });

export const getConditionRulesList: () => PromiseResponse<VariablesList> = () => requestService
  .get(environment.endpoints.get.antifraudСonditionsList)
  .then(({ data }) => data);

export const getConditionValuesList: () => PromiseResponse<VariablesList> = () => requestService
  .get(environment.endpoints.get.antifraudСonditionsValues)
  .then(({ data }) => data);
