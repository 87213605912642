/* eslint-disable no-param-reassign */
import { getNextId } from "@fas/ui-framework/lib/services/generators";

function IsJsonString(str) {
  try {
    JSON.parse(str);
  }
  catch (e) {
    return false;
  }
  return true;
}
// eslint-disable-next-line complexity
export const transformOperator = (operator) => {
  let result = "";
  switch (operator) {
    case "equal":
      result = "EQUAL";
      break;
    case "not equal":
      result = "NOT_EQUAL";
      break;
    case "contains all":
      result = "ALL_IN";
      break;
    case "contains one":
      result = "ANY_IN";
      break;
    case "not contains all":
      result = "NOT_IN";
      break;
    case "greater than":
      result = "LARGER";
      break;
    case "absent":
      result = "NOT_LIKE";
      break;
    case "exists":
      result = "LIKE";
      break;
    case "not contains one":
      result = "NOT_BETWEEN";
      break;
    case "less than":
      result = "SMALLER";
      break;
    case "EQUAL":
      result = "equal";
      break;
    case "NOT_EQUAL":
      result = "not equal";
      break;
    case "ALL_IN":
      result = "contains all";
      break;
    case "ANY_IN":
      result = "contains one";
      break;
    case "NOT_IN":
      result = "not contains all";
      break;
    case "LARGER":
      result = "greater than";
      break;
    case "NOT_LIKE":
      result = "absent";
      break;
    case "LIKE":
      result = "exists";
      break;
    case "NOT_BETWEEN":
      result = "not contains one";
      break;
    case "SMALLER":
      result = "less than";
      break;
    default:
      result = operator;
  }
  return result;
};
const parseValue = (value) => {
  const isString = typeof value === "string";
  const isWithoutValue = isString && value === "true";
  const isArray = Array.isArray(value);
  const isJson = isArray && value.length && IsJsonString(value[0]) && typeof JSON.parse(value[0]) === "object";

  if (isString && !isWithoutValue) {
    return [value];
  }

  if (isString && isWithoutValue) {
    return [];
  }

  if (isJson) {
    return value.map((item) => JSON.parse(item));
  }

  return value;
};
const transformValue = (rule) => {
  const { value, operator } = rule;
  const isWithoutValue = !value.length;
  const withSubValues = !isWithoutValue && value[0].subValues;
  const isStatement = ["exists", "absent"].includes(operator);

  if (withSubValues) {
    return value.map((item) => JSON.stringify(item));
  }

  if (isWithoutValue || isStatement) {
    return [];
  }

  return value;
};

export const iterateChildren = (state, ruleIdList) => ruleIdList.map((id) => {
  const rule = state.targetings.get("byId").get(id);
  let result = {};
  if (rule.type === "group") {
    result = transformGroup(state, rule);
  }
  if (rule.type === "rule") {
    result = transformRule(rule);
  }
  return result;
});

export const transformRule = (rule) => ({
  field: rule.name,
  operator: transformOperator(rule.operator),
  value: transformValue(rule),
});

export const transformGroup = (state, group) => ({
  type: "GROUP",
  value: group.groupOperator,
  isNegated: false,
  children: iterateChildren(state, group.targetings),
});

export const parseChildren = (schema, result, allIds) => {
  const nextId = getNextId(allIds);
  allIds.push(nextId);

  if (!schema.type) {
    result[nextId] = {
      type: "rule",
      name: schema.field,
      operator: transformOperator(schema.operator),
      value: parseValue(schema.value),
    };

    return nextId;
  }

  result[nextId] = {
    type: "group",
    groupOperator: schema.value,
    targetings: schema.children.map((item) => parseChildren(item, result, allIds)),
  };

  return nextId;
};

export const prepareSchema = (schema, allIds) => {
  const result = {};
  parseChildren(schema[0], result, [...allIds]);
  return result;
};

export const operatorsLocalization = {
  LARGER: "Greater than",
  SMALLER: "Less than",
  EQUAL: "Equal",
  NOT_EQUAL: "Not equal",
  ALL_IN: "Contains all",
  ANY_IN: "Contains one",
  NOT_IN: "Not contains all",
  NOT_BETWEEN: "Not contains one",
  NOT_LIKE: "Absent",
  LIKE: "Exists",
};

export const operatorsLocalizationV2 = {
  "greater than": "Greater than",
  "less than": "Less than",
  "equal": "Equal",
  "not equal": "Not equal",
  "contains all": "Contains all",
  "contains one": "Contains one",
  "not contains all": "Not contains all",
  "not contains one": "Not contains one",
  "absent": "Absent",
  "exists": "Exists",
};

export function prepareOperators(operators: Array<string>): Array<{| value: string, name: string |}> {
  return operators.map((operator: string): {| value: string, name: string |} => ({
    value: operator,
    name: operatorsLocalizationV2[operator],
  }));
}

export const typeMapper = {
  "MULTISELECT": "manualMultiple",
  "WITHOUT_VALUE": "WITHOUT_VALUE",
  "MULTISELECT_CUSTOM": "manualMultiple",
  "MULTISELECT_WITH_SUBVALUES": "withSubValuesMultiple",
  "SELECT": "simpleMultiple",
};
