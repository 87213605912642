// @flow
import joi from "joi";

const commonSchema = {
  name: joi
    .string()
    .min(1)
    .required()
    .label("Name"),
};

export const valueSchema = joi
  .object({
    ...commonSchema,
    value: joi
      .array()
      .min(1)
      .required()
      .label("Elements"),
  })
  .options({ abortEarly: false, allowUnknown: true });

export const dynamicSchema = joi
  .object({
    ...commonSchema,
    scenario: joi
      .number()
      .min(1)
      .required()
      .label("Scenario")
      .messages({ "number.min": "\"Scenario\" is not allowed to be empty" }),
    ttl: joi
      .number()
      .required()
      .label("TTL"),
  })
  .options({ abortEarly: false, allowUnknown: true });
