/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useEffect,
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import { useSelector } from "react-redux";
import { useParams, type ContextRouter } from "react-router-dom";
import type { Option } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import {
  Box,
  Container,
  TextField,
  FormControlLabel,
  Switch,
  FormLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Typography,
} from "@mui/material";
import { Loader, SetupCard } from "@fas/ui-core";
import {
  TRIGGER_VALUE_TYPE,
  TRIGGER_FEATURE_TYPE,
} from "../../helpers/constants";
import {
  setAntifraudTriggerFormField,
  getAntifraudTriggerSaga,
  saveAntifraudTriggerSaga,
} from "../../actions/antifraudTriggerForm";
import { getAntifraudTrigger, getErrors } from "../../selectors/antifraudTrigger";
import type { FromDispatchActions, Errors, Trigger } from "../../selectors/antifraudTrigger";
import { useDictionaryDropdownList, useLoading, useActions } from "../../hooks";
import FormHeader from "../FormHeader";
import type { SetAntifraudTriggerFormField } from "../../actions/antifraudTriggerForm";
import AntifraudTriggerConditionsBuilder from "../AntifraudTriggerConditionsBuilder";

type Props = {||};

const AntifraudTriggerForm: StatelessFunctionalComponent<Props> = () => {
  const { id }: $PropertyType<$PropertyType<ContextRouter, "match">, "params"> = useParams();

  const {
    name,
    description,
    score,
    scoreType,
    location,
    isActive,
  }: Trigger = useSelector(getAntifraudTrigger);

  const {
    setAntifraudTriggerFormField: onSetAntifraudTriggerFormField,
    getAntifraudTriggerSaga: onGetAntifraudTriggerSaga,
    saveAntifraudTriggerSaga: onSaveAntifraudTriggerSaga,
  }: FromDispatchActions = useActions({
    setAntifraudTriggerFormField,
    getAntifraudTriggerSaga,
    saveAntifraudTriggerSaga,
  });

  const errors: Errors = useSelector(getErrors);
  const [dictionaries, isDictionariesLoading]: [Option<number>[], boolean] = useDictionaryDropdownList("triggers", "variables/dynamic");
  const isGetAntifraudTriggerLoading: boolean = useLoading("isGetAntifraudTriggerLoading");
  const isSaveAntifraudTriggerLoading: boolean = useLoading("isSaveAntifraudTriggerLoading");

  useEffect(() => {
    if (id) {
      onGetAntifraudTriggerSaga(id);
    }
  }, [onGetAntifraudTriggerSaga, id]);

  return (
    <>
      <FormHeader
        title={id ? `Edit Trigger ${id}` : "Create Trigger"}
        onSave={onSaveAntifraudTriggerSaga}
        renderComponent={(
          <Box
            component={FormControlLabel}
            pr={3}
            control={(
              <Switch
                data-testid="trigger-isActive"
                name="isActive"
                checked={Boolean(isActive)}
                onClick={({
                  target: {
                    checked,
                  },
                }: SyntheticInputEvent<HTMLInputElement>): SetAntifraudTriggerFormField => (
                  onSetAntifraudTriggerFormField({ isActive: checked })
                )}
                color="secondary"
              />
            )}
            label="Is active"
          />
        )}
        loading={isSaveAntifraudTriggerLoading}
        isActionVisible={!isGetAntifraudTriggerLoading}
      />
      <Container maxWidth="md">
        <SetupCard title="General options">
          <Box width={1}>
            <Loader loading={isGetAntifraudTriggerLoading}>
              <TextField
                data-testid="trigger-name"
                fullWidth
                label="Name"
                variant="outlined"
                type="string"
                margin="dense"
                size="small"
                value={name}
                onChange={({
                  target: {
                    value,
                  },
                }: SyntheticInputEvent<HTMLInputElement>): SetAntifraudTriggerFormField => (
                  onSetAntifraudTriggerFormField({ name: value })
                )}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
              <TextField
                data-testid="trigger-description"
                fullWidth
                label="Description"
                variant="outlined"
                type="string"
                margin="dense"
                size="small"
                value={description}
                onChange={({
                  target: {
                    value,
                  },
                }: SyntheticInputEvent<HTMLInputElement>): SetAntifraudTriggerFormField => (
                  onSetAntifraudTriggerFormField({ description: value })
                )}
                error={Boolean(errors.description)}
                helperText={errors.description}
              />
              <Box p={1}>
                <FormLabel>Type</FormLabel>
                <RadioGroup
                  data-testid="trigger-score-type"
                  row
                  name="type"
                  value={scoreType}
                  onChange={(_: *, newValue: *) => {
                    onSetAntifraudTriggerFormField({ location: null });
                    onSetAntifraudTriggerFormField({ scoreType: newValue, score: 0 });
                  }}
                >
                  <FormControlLabel
                    key={TRIGGER_VALUE_TYPE}
                    value={TRIGGER_VALUE_TYPE}
                    control={<Radio color="primary" />}
                    label={TRIGGER_VALUE_TYPE}
                  />
                  <FormControlLabel
                    key={TRIGGER_FEATURE_TYPE}
                    value={TRIGGER_FEATURE_TYPE}
                    control={<Radio color="primary" />}
                    label={TRIGGER_FEATURE_TYPE}
                  />
                </RadioGroup>
              </Box>
              {scoreType === TRIGGER_VALUE_TYPE && (
                <TextField
                  data-testid="trigger-score"
                  fullWidth
                  label="Score"
                  variant="outlined"
                  type="number"
                  margin="dense"
                  size="small"
                  value={isNaN(score) ? "" : String(score)}
                  onChange={({
                    target: {
                      value,
                    },
                  }: SyntheticInputEvent<HTMLInputElement>) => {
                    onSetAntifraudTriggerFormField({ score: parseInt(value, 10) });
                  }}
                  onBlur={({
                    target: {
                      value,
                    },
                  }: SyntheticInputEvent<HTMLInputElement>) => {
                    if (!value) {
                      onSetAntifraudTriggerFormField({ score: 0 });
                    }
                  }}
                  error={Boolean(errors.score)}
                  helperText={errors.score}
                />
              )}
              {scoreType === TRIGGER_FEATURE_TYPE && (
                <>
                  <TextField
                    data-testid="form-location-input"
                    fullWidth
                    select
                    label="Location"
                    variant="outlined"
                    type="string"
                    size="small"
                    value={location || ""}
                    disabled={isDictionariesLoading}
                    onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                      onSetAntifraudTriggerFormField({ location: e.target.value });
                    }}
                    error={Boolean(errors.location)}
                    helperText={errors.location}
                  >
                    <MenuItem value="data">Data</MenuItem>
                    <MenuItem value="headers">Headers</MenuItem>
                    <MenuItem value="cookies">Cookies</MenuItem>
                  </TextField>
                  <TextField
                    data-testid="trigger-score-dictionary"
                    fullWidth
                    select
                    label="Dictionary"
                    variant="outlined"
                    type="string"
                    margin="dense"
                    size="small"
                    value={score || ""}
                    disabled={isDictionariesLoading}
                    onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                      onSetAntifraudTriggerFormField({ score: Number(e.target.value) });
                    }}
                    error={Boolean(errors.score)}
                    helperText={errors.score}
                  >
                    {dictionaries.map(({ label, value }): Node => (
                      <MenuItem key={value} value={value}>{label}</MenuItem>
                    ))}
                  </TextField>
                </>
              )}
            </Loader>
          </Box>
        </SetupCard>
      </Container>
      <Box p={4}>
        {errors.conditions && (
          <Box display="flex" justifyContent="center">
            <Typography color="error">Invalid rules</Typography>
          </Box>
        )}
        <AntifraudTriggerConditionsBuilder />
      </Box>
    </>
  );
};

export default AntifraudTriggerForm;
