// @flow
import {
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getFunnelDescriptionByFunnelId,
  getFunnels,
  getState,
  getFunnelsArrayBySplitId,
  getCurrentSplit,
} from "../../selectors/smartlink";
import { setSearchData } from "../../actions/search";
import { SEARCH_FUNNELS } from "../../helpers/constants";
import type { SearchFunnels } from "../../actions/search";

export function* searchFunnels(action: SearchFunnels): Saga<void> {
  try {
    const { text } = action;

    const splitId = yield select(getCurrentSplit);
    const funnelsIds = yield select(getFunnelsArrayBySplitId, splitId || "1");
    const funnels = yield select(getFunnels);
    const state = yield select(getState);

    const result: string[] = funnelsIds.filter((key: string): boolean => Boolean(text) && (funnels.getIn(["byId", key, "name"]).toLowerCase().includes(text.toLowerCase()) || getFunnelDescriptionByFunnelId(state, key).toLowerCase().includes(text.toLowerCase())));

    yield put(setSearchData({
      scrollToItem: result.length > 0 ? result[0] : null,
      result,
      text,
    }));
  }
  catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(SEARCH_FUNNELS, searchFunnels);
}
