/* eslint-disable import/max-dependencies */
// @flow
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, Button, Container } from "@mui/material";
import { Map } from "immutable";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import type { NotificationRaw as Notification } from "@fas/ui-framework/lib/redux/reducers/notifications";
import {
  stepChange,
  setStepError,
} from "@fas/ui-framework/lib/redux/actions/steps";
import { useParams } from "react-router-dom";

import GeneralFilterInfo from "../GeneralFilterInfo";
import CampaignIn from "../CampaignIn";
import CampaignOut from "../CampaignOut";
import type {
  FilterInfo,
} from "../../reducers/generalFilterInfo";
import { getGeneralFilterInfo } from "../../selectors/generalFilter";
import Api from "../../services/generalFilter";
import {
  initStateEdit,
  generalFilterRerender,
} from "../../actions/generalFilterInfo";
import {
  initTargetingStateEdit,
} from "../../actions/gfTargeting";

type OwnProps = $ReadOnly<{||}>;

type Props = {
  ...OwnProps,
  filterInfo: Map<FilterInfo>,
  handleInitStateEdit: (value: Map<FilterInfo>) => any,
  handleStepChange: (id: string) => void,
  handleStepErrorChange: () => any,
  handleGeneralFilterRerender: () => any,
  handleInitStateEditTargeting: (*) => void,
  handleOnAddNotification: (notification: Notification) => mixed,
  handleSetErrors: (*) => any,
}

const mapStateToProps = getGeneralFilterInfo;
const mapDispatchToProps = (dispatch) => ({
  handleInitStateEdit: (value: Map<FilterInfo>) => dispatch(initStateEdit(value)),
  handleStepChange: (id: string) => dispatch(stepChange(id)),
  handleStepErrorChange: () => dispatch(setStepError()),
  handleGeneralFilterRerender: () => dispatch(generalFilterRerender()),
  handleInitStateEditTargeting: (val) => dispatch(initTargetingStateEdit(val)),
  handleOnAddNotification: (notification: Notification) => dispatch(addNotification(notification)),
  handleSetErrors: (errors) => dispatch(setErrors(errors)),
});

const FilterInfoStep = ({
  filterInfo,
  handleInitStateEdit,
  handleStepChange,
  handleStepErrorChange,
  handleGeneralFilterRerender,
  handleInitStateEditTargeting,
  handleOnAddNotification,
  handleSetErrors,
}: Props) => {
  const { id: idFromParams } = useParams();
  const {
    id,
    name,
    description,
    priority,
    oldPriority,
    rate,
    mustHave,
    isActive,
    tdsCampaignsIn,
    tdsCampaignsOut,
    conditions,
    rerender,
    fact,
    dryModeRate,
  } = filterInfo.toJS();

  const body = {
    id,
    name,
    description,
    priority,
    oldPriority,
    rate,
    mustHave,
    isActive,
    tdsCampaignsIn: tdsCampaignsIn.map((item) => item.value).join(","),
    tdsCampaignsOut,
    conditions,
    isLastStep: false,
    fact,
    dryModeRate,
  };

  const [lists, setLists] = useState([]);

  const fetchSave = () => {
    Api.save(body)
      .then(() => {
        handleGeneralFilterRerender();
        handleSetErrors({});
        handleStepChange("2");
      })
      .catch((error) => {
        if (error.response
          && error.response.data
          && error.response.data.errors
        ) {
          handleSetErrors(error.response.data.errors);
          handleStepErrorChange();
          handleOnAddNotification({ message: error.errorMessage, severity: "error" });
        }
      });
  };

  const fetchFormAdd = () => {
    Api.getFormAdd()
      .then((response) => {
        setLists(response.data.list);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const fetchFormEdit = (rowId: string) => {
    Api.getFormEdit(rowId)
      .then((response) => {
        const { row } = response.data;

        if (rerender) {
          handleInitStateEdit(response.data);
        }
        handleInitStateEditTargeting(row.targeting);

        setLists(response.data.list);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        window.location.pathname = "/error";
      });
  };

  const fetchFormClone = (rowId: string) => {
    Api.getFormEdit(rowId)
      .then((response) => {
        const { row, list } = response.data;

        if (rerender) {
          handleInitStateEdit({
            row: {
              conditions: row.conditions, // get only conditions from source general filter
            },
            list,
          });
        }
        handleInitStateEditTargeting(row.targeting);

        setLists(response.data.list);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        window.location.pathname = "/error";
      });
  };

  useEffect(() => {
    const { pathname } = window.location;
    if (idFromParams && pathname.includes("edit")) {
      fetchFormEdit(idFromParams);
    }
    else if (idFromParams && pathname.includes("clone")) {
      fetchFormClone(idFromParams);
    }
    else if (pathname.includes("add")) {
      fetchFormAdd();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="md">
      <Box mt={3}>
        <GeneralFilterInfo priorityList={lists} />
        <CampaignIn />
        <CampaignOut />
        <br />
        <Button
          variant="contained"
          color="primary"
          style={{ float: "right" }}
          onClick={fetchSave}
          data-testid="next-button"
        >
          Next
        </Button>
      </Box>
    </Container>
  );
};

// $FlowFixMe
export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(FilterInfoStep);
