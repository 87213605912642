// @flow
/* eslint-disable import/max-dependencies */
import React, { useState, type StatelessFunctionalComponent } from "react";
import { connect, useDispatch } from "react-redux";
import { AddData, SetupCard } from "@fas/ui-core";
import { List, Map } from "immutable";
import { Box, Container } from "@mui/material";
import type { Error } from "@fas/ui-framework/lib/redux/reducers/errors";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import type { Offer } from "../../reducers/offers";
import {
  getFallbackOffers,
  getNonTargetOffers,
  getOffersByIds,
  getErrors,
} from "../../selectors/smartlink";
import OfferItem from "../../components/OfferItem";
import { closeOffersModal, openOffersModal } from "../../actions/modals";
import { deleteFallbackOfferSaga, saveFallbackOfferSaga } from "../../actions/fallbackOffers";
import { deleteNonTargetOfferSaga, saveNonTargetOffersSaga } from "../../actions/nonTargetOffers";
import { changeOfferWeight, equalSharesOffers } from "../../actions/offers";
import OffersModal, { type Props as OffersModalProps } from "../OffersModal";
import StepControl from "../StepControl";
import { OFFERS_TABLE } from "../../helpers/constants";
import { useClearTable, type ClearTableHook } from "../../hooks";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  fallbackOffersIds: List<string>,
  nonTargetOffersIds: List<string>,
  fallbackOffers: Map<Offer>,
  nonTargetOffers: Map<Offer>,
  errors: Map<string, Error>,
  handleDeleteOffer: (offerId: string) => void,
  handleDeleteNonTargetOffer: (offerId: string) => void,
  handleChangeOfferWeight: (offerId: string, weight: number) => void,
  handleEqualSharesOffers: (offersIds: Array<string>) => void,
|}>;

const mapStateToProps = (state) => {
  const fallbackOffersIds = getFallbackOffers(state);
  const nonTargetOffersIds = getNonTargetOffers(state);

  return {
    errors: getErrors(state),
    fallbackOffersIds,
    nonTargetOffersIds,
    fallbackOffers: getOffersByIds(state, getFallbackOffers(state)),
    nonTargetOffers: getOffersByIds(state, getNonTargetOffers(state)),
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleDeleteOffer: (offerId) => dispatch(deleteFallbackOfferSaga(offerId)),
  handleDeleteNonTargetOffer: (offerId) => dispatch(deleteNonTargetOfferSaga(offerId)),
  handleChangeOfferWeight: (offerId, weight) => dispatch(changeOfferWeight(offerId, weight)),
  handleEqualSharesOffers: (offersIds: Array<string>) => dispatch(equalSharesOffers(offersIds)),
});
const hasError = (key: string, errors: Map<string, Error>): boolean => errors.has(key);

// @TODO refactor this, must not mix redux state management with react useState
const useModalConfig: (*[], *[]) => [OffersModalProps, ("fallback" | "nonTarget") => void] = (fallbackList, nonTargetList) => {
  const [type, setType] = useState("fallback");

  const dispatch: <A>(A) => A = useDispatch();
  const { clearTableData, clearTableFilters }: ClearTableHook = useClearTable(OFFERS_TABLE);

  const onCancel: () => void = () => {
    dispatch(closeOffersModal());
    clearTableData();
  };

  const openModal: ("fallback" | "nonTarget") => void = (newType) => {
    setType(newType);
    // $FlowFixMe
    dispatch(setTableSelection(OFFERS_TABLE, newType === "fallback" ? fallbackList.valueSeq().toJS() : nonTargetList.valueSeq().toJS()));
    clearTableFilters();
    dispatch(openOffersModal());
  };

  const mapper: { ["fallback" | "nonTarget"]: OffersModalProps } = {
    "fallback": {
      title: "Fallback Setup",
      limit: 1,
      onCancel,
      onSave: (offers) => dispatch(saveFallbackOfferSaga({ ...offers[0], weight: 100 })),
    },
    "nonTarget": {
      title: "Non Target Setup",
      limit: 10,
      onCancel,
      onSave: (offers) => dispatch(saveNonTargetOffersSaga(offers)),
    },
  };

  return [mapper[type], openModal];
};

const ServiceOffersStep: StatelessFunctionalComponent<Props> = ({
  fallbackOffersIds,
  nonTargetOffersIds,
  fallbackOffers,
  nonTargetOffers,
  errors,
  handleDeleteOffer,
  handleDeleteNonTargetOffer,
  handleChangeOfferWeight,
  handleEqualSharesOffers,
}: Props) => {
  const [config, openModal] = useModalConfig(fallbackOffers, nonTargetOffers);

  return (
    <Container maxWidth="md">
      <Box mb={3} mt={3}>
        <OffersModal {...config} />
        {fallbackOffersIds.size === 0 && (
          <Box mb={3} data-testid="fallback-offer">
            <AddData
              linkText="+ add new fallback offer"
              title="You can add fallback offer"
              error={hasError("fallbackOffers", errors)}
              onClick={() => openModal("fallback")}
            />
          </Box>
        )}
        {fallbackOffersIds.size > 0 && (
          <Box mb={3} data-testid="fallback-offer">
            <SetupCard
              title="Fallback offer"
              showMainBtn
              mainBtnText="edit fallback offer"
              onMainBtnClick={() => openModal("fallback")}
              showSecondaryBtn={false}
              onSecondaryBtnClick={() => {}}
              secondaryBtnText="equal shares"
            >
              {fallbackOffersIds.map((offerId) => {
                const {
                  id,
                  name,
                  url,
                  isActive,
                } = fallbackOffers.get(offerId).toJS();

                return (
                  <OfferItem
                    key={id}
                    idValue={id}
                    urlTitle={name}
                    urlValue={url}
                    onDelete={() => handleDeleteOffer(offerId)}
                    isActive={isActive}
                  />
                );
              })}
            </SetupCard>
          </Box>
        )}
        {nonTargetOffersIds.size === 0 && (
          <Box mb={3} data-testid="non-target-offers">
            <AddData
              linkText="+ add new non-target offer"
              title="You can add non-target offers"
              error={hasError("nonTargetOffers", errors)}
              onClick={() => openModal("nonTarget")}
            />
          </Box>
        )}
        {nonTargetOffersIds.size > 0 && (
          <Box mb={3} data-testid="non-target-offers">
            <SetupCard
              title="Non-target offers"
              showMainBtn
              mainBtnText="edit non-target offer"
              onMainBtnClick={() => openModal("nonTarget")}
              showSecondaryBtn
              onSecondaryBtnClick={() => handleEqualSharesOffers(nonTargetOffersIds.toJS())}
              secondaryBtnText="equal shares"
            >
              {nonTargetOffersIds.map((offerId) => {
                const {
                  id,
                  name,
                  url,
                  weight,
                  isActive,
                } = nonTargetOffers.get(offerId).toJS();

                return (
                  <OfferItem
                    key={id}
                    idValue={id}
                    urlTitle={name}
                    urlValue={url}
                    weightValue={weight}
                    weightError={hasError("nonTargetOffersWeights", errors)}
                    onChangeWeight={(e) => handleChangeOfferWeight(offerId, Number(e.target.value))}
                    onDelete={() => handleDeleteNonTargetOffer(offerId)}
                    isActive={isActive}
                  />
                );
              })}
            </SetupCard>
          </Box>
        )}
        <StepControl showBackButton />
      </Box>
    </Container>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ServiceOffersStep);
