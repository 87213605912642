// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import type { Sorting } from "../../sagas/getTableSaga/getTableSaga";
import { requestService } from "../request";
import type { GeneralFilterListItem } from "../../containers/GeneralFilterList/type";
import { type GeneralFilter } from "../../reducers/generalFilterInfo";

export const fetchGeneralFilterList: (
  options: {
    page: number,
    pageSize: number,
    filters: { [string]: mixed },
    sorting?: Sorting,
  },
) => PromiseResponse<{ data: { data: Array<GeneralFilterListItem>, count: number } }> = (
  options
) => {
  const { filters, sorting, ...params }: {
    page: number,
    pageSize: number,
    filters: { [string]: mixed },
    sorting?: Sorting,
  } = options;
  let newSorting: Sorting;
  if (sorting && Object.keys(sorting).length) {
    const current = Object.keys(sorting)[0];
    newSorting = {
      current,
      direction: sorting[current],
    };
  }
  // $FlowFixMe
  const paramsData = {
    ...params,
    // $FlowFixMe
    filters: {
      ...filters,
      ...(filters.isActive && { isActive: Number(filters.isActive) }),
    },
    ...(newSorting && { sorting: newSorting }),
  };

  return requestService
    .post(environment.endpoints.post.generalFilterList, paramsData)
    .then(({ data: { data, ...rest } }): Array<GeneralFilterListItem> => ({
      ...rest,
      data: data.map(({ isActive, mustHave, ...generalFIlterData }: GeneralFilterListItem): GeneralFilterListItem => ({
        ...generalFIlterData,
        isActive: Boolean(isActive),
        mustHave: Boolean(mustHave),
      })),
    }));
};
export const changeGeneralFilterActive: (
  ids: number[],
  isActive: boolean
) => PromiseResponse<{}> = (ids, isActive) => requestService
  .put(environment.endpoints.put.generalFilterActive, { ids, isActive: (isActive) ? 1 : 0 });

export const cloneGeneralFilter: (
  id: number,
) => PromiseResponse<{}> = (id) => requestService
  .get(environment.endpoints.get.generalFilterClone.replace("{id}", `${id}`))
  .then(({ data }): GeneralFilterListItem => data.data);

export const getGeneralFilter: (
  id: number
) => PromiseResponse<{ data: GeneralFilterListItem }> = (id) => requestService
  .get(environment.endpoints.get.generalFilter.replace("{id}", `${id}`))
  .then(({ data }): GeneralFilterListItem => ({
    ...data.data,
    isActive: Boolean(data.data.isActive),
    mustHave: Boolean(data.data.mustHave),
  }));

export const getGeneralFilterPriorityList: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.generalFilterPiorityList)
  .then(({ data }): GeneralFilterListItem => data.data);

export const getCampaigns: (number, number, Object) => PromiseResponse<mixed> = (
  page,
  pageSize,
  filters
) => requestService.post(environment.endpoints.post.generalFilterGetCampaigns, {
  ...filters,
  page,
});

export const getTargetingRules: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.targetingRulesGeneralFilter);

export const createGeneralFilter: (
  data: GeneralFilter,
) => PromiseResponse<{}> = ({
  isActive, mustHave, rate, ...rest
}) => requestService
  .post(environment.endpoints.post.generalFilter, {
    ...rest,
    isActive: (isActive) ? 1 : 0,
    mustHave: (mustHave) ? 1 : 0,
    rate: (rate) ? Number(rate) : "",
  });

export const updateGeneralFilter: (
  data: GeneralFilter,
  id: number,
) => PromiseResponse<{}> = ({
  isActive, mustHave, rate, ...rest
}, id) => requestService
  .put(environment.endpoints.put.generalFilter.replace("{id}", `${id}`), {
    ...rest,
    isActive: (isActive) ? 1 : 0,
    mustHave: (mustHave) ? 1 : 0,
    rate: (rate) ? Number(rate) : "",
  });
