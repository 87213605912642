// @flow
/* eslint-disable import/max-dependencies */
import {
  debounce,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { setTableData, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  GET_ANTIFRAUD_TRIGGER_LIST_SAGA,
  ANTIFRAUD_TRIGGERS_TABLE,
} from "../../helpers/constants";
import {
  getAntifraudTriggerList,
  type AntifraudTriggerListResponseType,
  type Trigger,
} from "../../services/antifraudTriggersApi";
import type { TriggerListItem } from "../../containers/AntifraudTriggerList/types/AntifraudTriggerList.types";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(ANTIFRAUD_TRIGGERS_TABLE, true));

    const page: number = yield select(getTablePage, ANTIFRAUD_TRIGGERS_TABLE);
    const limit: number = yield select(getTablePageSize, ANTIFRAUD_TRIGGERS_TABLE);
    const sorting: Sorting = yield select(getTableSorting, ANTIFRAUD_TRIGGERS_TABLE);
    const filters: Filters = yield select(getTableFilters, ANTIFRAUD_TRIGGERS_TABLE);

    const { data }: AntifraudTriggerListResponseType = yield call(getAntifraudTriggerList, {
      page,
      limit,
      sorting,
      filters,
    });
    const { data: { list, count } }: $PropertyType<AntifraudTriggerListResponseType, "data"> = data;

    const tableData: TriggerListItem[] = list.map(({ ruleId, ...rest }: Trigger): TriggerListItem => ({ ...rest, id: `${ruleId}`, ruleId }));

    yield put(setTableData(ANTIFRAUD_TRIGGERS_TABLE, tableData));
    yield put(changeTableItemsTotalAmount(ANTIFRAUD_TRIGGERS_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
  }
  finally {
    yield put(setLoading(ANTIFRAUD_TRIGGERS_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield debounce(1000, GET_ANTIFRAUD_TRIGGER_LIST_SAGA, (makeFetch: Function));
}
