// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import { reducer as errors, initState as initErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors";
import notifications from "@fas/ui-framework/lib/redux/reducers/notifications";
import { tableReducer as tables, initTableState } from "@fas/ui-framework/lib/redux/reducers/table";
import loading, { initLoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import mainSaga from "./saga";
import { pageLeaveConfirm } from "../../services/pageLeaveConfirm";

import { OFFERS_TABLE, FUNNELS_TEMPLATE_LIST_TABLE, GET_SHORT_URL_SAGA } from "../../helpers/constants";

import steps, { initStepsState } from "../../reducers/smartlinkSteps";
import splits, { initSplitsState } from "../../reducers/splits";
import funnels, { initFunnelsState } from "../../reducers/funnels";
import dictionaries, { initDictionariesState } from "../../reducers/dictionaries";
import campaignInfo, { initCampaignInfoState } from "../../reducers/campaignInfo";
import placeholders, { initPlaceholdersState } from "../../reducers/placeholders";
import targetings, { initTargetingsState } from "../../reducers/targetings";
import offers, { initOffersState } from "../../reducers/offers";
import fallback, { initFallbackState } from "../../reducers/fallback";
import nonTarget, { initNonTargetState } from "../../reducers/nonTarget";
import filterings, { initFilteringsState } from "../../reducers/filterings";
import generalFilters, { initGeneralFiltersState } from "../../reducers/generalFilters";
import modals, { initModalsState } from "../../reducers/modals";
import confirmDialog, { initConfirmDialogState } from "../../reducers/confirmDialog";
import frequency, { initFrequencyState } from "../../reducers/frequency";
import actionOptionsParams, { initActionOptionsParamsState } from "../../reducers/actionOptionsParams";
import backupReducer from "../../reducers/backup";
import tags, { initTagsState } from "../../reducers/manualTags";
import searchNavigation, { initSearchNavigationState } from "../../reducers/searchNavigation";

function mapCampaignToState(campaign) {
  return {
    steps: initStepsState(campaign),
    dictionaries: initDictionariesState(),
    errors: initErrorsState(),
    modals: initModalsState(campaign),
    confirmDialog: initConfirmDialogState(),
    campaignInfo: initCampaignInfoState(campaign),
    placeholders: initPlaceholdersState(campaign),
    splits: initSplitsState(campaign),
    funnels: initFunnelsState(campaign),
    targetings: initTargetingsState(campaign),
    offers: initOffersState(campaign),
    fallback: initFallbackState(campaign),
    nonTarget: initNonTargetState(campaign),
    filterings: initFilteringsState(campaign),
    generalFilters: initGeneralFiltersState(campaign),
    frequency: initFrequencyState(campaign),
    actionOptionsParams: initActionOptionsParamsState(campaign),
    tags: initTagsState(campaign),
    loading: initLoadingState({
      generalFilters: false,
      funnelsTemplateList: false,
      loadingSaveCampaign: false,
      loadingCampaignJson: false,
      loadingTargetingRules: false,
      [OFFERS_TABLE]: false,
      [FUNNELS_TEMPLATE_LIST_TABLE]: false,
      [GET_SHORT_URL_SAGA]: false,
    }),
    searchNavigation: initSearchNavigationState(),
    tables: initTableState({
      [OFFERS_TABLE]: { pageSize: 20 },
      [FUNNELS_TEMPLATE_LIST_TABLE]: { pageSize: 20, filters: { type: "technical", createdAt: "desc" } },
    }),
  };
}

const reducers = combineReducers({
  searchNavigation,
  notifications,
  tags,
  steps,
  dictionaries,
  errors,
  modals,
  confirmDialog,
  campaignInfo,
  placeholders,
  splits,
  funnels,
  targetings,
  offers,
  fallback,
  nonTarget,
  filterings,
  generalFilters,
  frequency,
  actionOptionsParams,
  loading,
  tables,
});

// eslint-disable-next-line
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  serialize: {
    immutable: Immutable,
  },
}) : compose;

const sagaMiddleware = createSagaMiddleware();

// $FlowFixMe
export default (campaign) => {
  const store = createStore(
    backupReducer(reducers),
    mapCampaignToState(campaign),
    composeEnhancers(applyMiddleware(
      sagaMiddleware,
      pageLeaveConfirm
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
