// @flow
/* eslint-disable import/max-dependencies */
import {
  take,
  debounce,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  setTableData,
  changeTableItemsTotalAmount,
  setTableSelection,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { GET_ANTIFRAUD_DICTIONARIES_LIST_SAGA, ANTIFRAUD_DICTIONARIES_TABLE } from "../../helpers/constants";
import { fetchAntifraudDictionariesList, type AntifraudDictionariesListResponseType } from "../../services/antifraudDictionariesApi";
import type { Dictionary } from "../../selectors/antifraudDictionaries";
import type { DictionaryListItem } from "../../containers/AntifraudDictionariesList/types/AntifraudDictionariesList.types";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(ANTIFRAUD_DICTIONARIES_TABLE, true));

    const page: number = yield select(getTablePage, ANTIFRAUD_DICTIONARIES_TABLE);
    const limit: number = yield select(getTablePageSize, ANTIFRAUD_DICTIONARIES_TABLE);
    const sorting: Sorting = yield select(getTableSorting, ANTIFRAUD_DICTIONARIES_TABLE);
    const filters: Filters = yield select(getTableFilters, ANTIFRAUD_DICTIONARIES_TABLE);

    const {
      data: { list, count }, // TODO: change contracts, fix backend and fix nesting on front in MTU-56306
    }: AntifraudDictionariesListResponseType = yield call(fetchAntifraudDictionariesList, {
      page,
      limit,
      sorting,
      filters,
    });

    const tableData: DictionaryListItem[] = list.map(({ dictId, ...rest }: Dictionary): DictionaryListItem => ({ ...rest, id: `${dictId}`, dictId }));

    yield put(setTableData(ANTIFRAUD_DICTIONARIES_TABLE, tableData));
    yield put(changeTableItemsTotalAmount(ANTIFRAUD_DICTIONARIES_TABLE, count));
    yield put(setTableSelection(ANTIFRAUD_DICTIONARIES_TABLE, []));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch Dictionaries", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(ANTIFRAUD_DICTIONARIES_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield take(GET_ANTIFRAUD_DICTIONARIES_LIST_SAGA);
  yield call(makeFetch);
  yield debounce(1000, GET_ANTIFRAUD_DICTIONARIES_LIST_SAGA, (makeFetch: Function));
}
