// @flow
import {
  GET_ANTIFRAUD_SCENARIO_SAGA,
  SET_ANTIFRAUD_SCENARIO,
  SET_ANTIFRAUD_SCENARIO_FORM_FIELD,
  SET_ANTIFRAUD_SCENARIO_RULES,
  REMOVE_ANTIFRAUD_SCENARIO_RULE,
  SAVE_ANTIFRAUD_SCENARIO_SAGA,
  ADD_ANTIFRAUD_SCENARIO_ACTION,
  DELETE_ANTIFRAUD_SCENARIO_ACTION,
  SET_ANTIFRAUD_SCENARIO_ACTION,
  CHANGE_ANTIFRAUD_SCENARIO_RULE_STATUS,
} from "../../helpers/constants";
import type { Scenario, Rule } from "../../selectors/antifraudScenarios";
import type { ScenarioFormData, DynamicDict } from "../../reducers/antifraudScenarioForm";

export type GetAntifraudScenarioSaga = {
  type: typeof GET_ANTIFRAUD_SCENARIO_SAGA,
  scenarioId: number,
}

export type SetAntifraudScenario = {|
  type: typeof SET_ANTIFRAUD_SCENARIO,
  payload: Scenario
|};

export type SetAntifraudScenarioFormField = {|
  type: typeof SET_ANTIFRAUD_SCENARIO_FORM_FIELD,
  payload: $Shape<ScenarioFormData>,
|};

export type SetAntifraudScenarioRules = {|
  type: typeof SET_ANTIFRAUD_SCENARIO_RULES,
  payload: Array<Rule>
|};

export type RemoveAntifraudScenarioRule = {|
  type: typeof REMOVE_ANTIFRAUD_SCENARIO_RULE,
  payload: number
|};

export type SaveAntifraudScenarioSaga = {|
  type: typeof SAVE_ANTIFRAUD_SCENARIO_SAGA,
|};

export type AddAntifraudScenarioAction = {|
  type: typeof ADD_ANTIFRAUD_SCENARIO_ACTION,
|};

export type DeleteAntifraudScenarioAction = {|
  type: typeof DELETE_ANTIFRAUD_SCENARIO_ACTION,
  payload: number,
|};

export type SetAntifraudScenarioAction = {|
  type: typeof SET_ANTIFRAUD_SCENARIO_ACTION,
  payload: {
    index: number,
    value: $Shape<DynamicDict>,
  },
|};

export type ChangeAntifraudScenarioRuleStatus = {|
  type: typeof CHANGE_ANTIFRAUD_SCENARIO_RULE_STATUS,
  payload: {
    index: number,
    isActive: boolean,
  }
|};

export type Actions = GetAntifraudScenarioSaga
  | SetAntifraudScenario
  | SetAntifraudScenarioFormField
  | SetAntifraudScenarioRules
  | RemoveAntifraudScenarioRule
  | SaveAntifraudScenarioSaga
  | AddAntifraudScenarioAction
  | DeleteAntifraudScenarioAction
  | SetAntifraudScenarioAction
  | ChangeAntifraudScenarioRuleStatus;

export const getAntifraudScenarioSaga: (scenarioId: number) => GetAntifraudScenarioSaga = (scenarioId) => ({
  type: GET_ANTIFRAUD_SCENARIO_SAGA,
  scenarioId,
});

export const setAntifraudScenario: (payload: Scenario) => SetAntifraudScenario = (payload) => ({
  type: SET_ANTIFRAUD_SCENARIO,
  payload,
});

export const setAntifraudScenarioFormField: (
  payload: $Shape<ScenarioFormData>
) => SetAntifraudScenarioFormField = (payload) => ({
  type: SET_ANTIFRAUD_SCENARIO_FORM_FIELD,
  payload,
});

export const setAntifraudScenarioRules: (payload: Array<Rule>) => SetAntifraudScenarioRules = (payload) => ({
  type: SET_ANTIFRAUD_SCENARIO_RULES,
  payload,
});

export const removeAntifraudScenarioRule: (index: number) => RemoveAntifraudScenarioRule = (index) => ({
  type: REMOVE_ANTIFRAUD_SCENARIO_RULE,
  payload: index,
});

export const saveAntifraudScenarioSaga: () => SaveAntifraudScenarioSaga = () => ({
  type: SAVE_ANTIFRAUD_SCENARIO_SAGA,
});

export const addAntifraudScenarioAction: () => AddAntifraudScenarioAction = () => ({
  type: ADD_ANTIFRAUD_SCENARIO_ACTION,
});

export const deleteAntifraudScenarioAction: (index: number) => DeleteAntifraudScenarioAction = (index) => ({
  type: DELETE_ANTIFRAUD_SCENARIO_ACTION,
  payload: index,
});

export const setAntifraudScenarioAction: (
  index: number, value: $Shape<DynamicDict>
) => SetAntifraudScenarioAction = (index, value) => ({
  type: SET_ANTIFRAUD_SCENARIO_ACTION,
  payload: { index, value },
});

export const changeAntifraudScenarioRuleStatus: (number, boolean) => ChangeAntifraudScenarioRuleStatus = (
  index, isActive
) => ({
  type: CHANGE_ANTIFRAUD_SCENARIO_RULE_STATUS,
  payload: {
    index,
    isActive,
  },
});
