import { CLEAR_BACKUP, RESTORE_BACKUP, SET_BACKUP } from "../../helpers/constants";

const actions = [SET_BACKUP, RESTORE_BACKUP, CLEAR_BACKUP];

export default (reducer, [SET, RESTORE, CLEAR] = actions) => {
  const history = [];
  return (state = reducer(undefined, {}), action) => {
    switch (action.type) {
      case SET: {
        const backup = action.backup.reduce((obj, key) => ({ ...obj, [key]: state[key] }), {});
        history.push(backup);
        return state;
      }
      case RESTORE: {
        const backup = history.pop() || {};
        return {
          ...state,
          ...backup,
        };
      }
      case CLEAR: {
        history.pop();
        return state;
      }
      default:
        return reducer(state, action);
    }
  };
};
