/* eslint-disable import/max-dependencies */
// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import templatesStepsSaga from "../../sagas/templatesStepsSaga";
import saveTemplateSaga from "../../sagas/saveTemplateSaga";
import fetchId from "../../sagas/campaignId";
import openFunnelSaga from "../../sagas/openFunnelSaga";
import saveFunnelSaga from "../../sagas/saveFunnelSaga";
import closeFunnelSaga from "../../sagas/closeFunnelSaga";
import saveOffersSaga from "../../sagas/saveOffersSaga";
import cloneOfferSaga from "../../sagas/cloneOfferSaga";
import cloneTargetingSaga from "../../sagas/cloneTargetingSaga";
import fetchTargetingsSaga from "../../sagas/fetchTargetingsSaga";
import fetchTargetingValuesSaga from "../../sagas/fetchTargetingValuesSaga";
import watchCloneFiltering from "../../sagas/cloneFilteringSaga";
import watchCloneActionOptionsParams from "../../sagas/cloneActionOptionsParamsSaga";
import cloneFunnelSaga from "../../sagas/cloneFunnelSaga";
import deleteFunnelSaga from "../../sagas/deleteFunnelSaga";
import watchDeleteFilteringRule from "../../sagas/deleteFilteringRuleSaga/deleteFilteringRuleSaga";
import watchSearchFunnels from "../../sagas/searchFunnels";
import watchFetchOffers from "../../sagas/getOffersSaga";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(templatesStepsSaga),
    call(saveTemplateSaga),
    call(fetchId),
    call(openFunnelSaga),
    call(saveFunnelSaga),
    call(closeFunnelSaga),
    call(saveOffersSaga),
    call(cloneOfferSaga),
    call(cloneTargetingSaga),
    call(fetchTargetingsSaga),
    call(fetchTargetingValuesSaga),
    call(watchCloneFiltering),
    call(watchDeleteFilteringRule),
    call(watchCloneActionOptionsParams),
    call(cloneFunnelSaga),
    call(deleteFunnelSaga),
    call(watchSearchFunnels),
    call(watchFetchOffers),
  ]);
}
