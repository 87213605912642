// @flow
import {
  takeEvery,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { REMOVE_TARGETING_GF_GROUP_SAGA } from "../../helpers/constants";
import type { RemoveTargetingGfGroupSaga } from "../../actions/gfTargeting";
import { deleteTargetingGf, removeTargetingGfGroupSaga } from "../../actions/gfTargeting";
import { getTargetingGroupById } from "../../selectors/generalFilter/indexV2";
import type { GroupStateJs } from "../../reducers/gfTargeting";

export function* removeGroup(action: RemoveTargetingGfGroupSaga): Saga<void> {
  const { id, parentId }: RemoveTargetingGfGroupSaga = action;
  const group: GroupStateJs = yield select(getTargetingGroupById, id);

  if (group.type === "group") {
    // eslint-disable-next-line guard-for-in
    for (let idx: number = 0; group.targetings.length > idx; idx++) {
      yield put(removeTargetingGfGroupSaga(group.targetings[idx], parentId));
    }
  }
  yield put(deleteTargetingGf(id, parentId));
}

export default function* watch(): Saga<void> {
  yield takeEvery(REMOVE_TARGETING_GF_GROUP_SAGA, removeGroup);
}
