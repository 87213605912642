// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Table, SelectSearchComponent } from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import { GENERAL_FILTERS_CAMPAIGNS_TABLE } from "../../helpers/constants";
import type { GeneralFilterCampaign } from "./types/GeneralFilterCampaignsList.types";
import { fetchGeneralFilterCampaignsSaga } from "../../actions/generalFilters";

type Props = $ReadOnly<{|
  rowSelectAvailable: (currentRow: GeneralFilterCampaign, selectedRows: GeneralFilterCampaign[]) => boolean,
|}>;

type UseGeneralFilterCampaignHook = $ReadOnly<{|
  ...TableProps,
  columns: Column<GeneralFilterCampaign>[],
  title: string,
  allSelectAvailable: () => boolean,
  compareRows: (GeneralFilterCampaign, GeneralFilterCampaign) => boolean,
|}>;

const columns: Column<GeneralFilterCampaign>[] = [
  {
    field: "id",
    label: "ID",
    searchable: true,
  },
  {
    field: "campaignName",
    label: "NAME",
    searchable: true,
  },
  {
    field: "type",
    label: "TYPE",
  },
  {
    field: "publishingState",
    label: "STATUS",
    searchable: true,
    SearchComponent: (props: SearchComponentProps): Element<typeof SelectSearchComponent> => (
      <SelectSearchComponent
        {...props}
        values={[
          { title: "All", value: "" },
          { title: "Draft", value: "draft" },
          { title: "Active", value: "active" },
          { title: "Stopped", value: "stopped" },
        ]}
        label="status"
      />
    ),
  },
  {
    field: "createdBy",
    label: "AUTHOR",
  },
];
const title: string = "Campaigns";
const allSelectAvailable: () => boolean = () => false;
const compareRows: (GeneralFilterCampaign, GeneralFilterCampaign) => boolean = (
  current,
  selected
) => current.id === selected.id;

const useGeneralFilterCampaigns: (string) => UseGeneralFilterCampaignHook = (key) => {
  const dispatch: <A>(A) => A = useDispatch();
  const tableProps: TableProps = useTable(key);

  const onLoad: () => mixed = () => dispatch(fetchGeneralFilterCampaignsSaga());

  return {
    ...tableProps,
    columns,
    title,
    allSelectAvailable,
    compareRows,
    onLoad,
  };
};

const GeneralFilterCampaignsList: StatelessFunctionalComponent<Props> = ({ rowSelectAvailable }) => (
  <Table
    {...useGeneralFilterCampaigns(GENERAL_FILTERS_CAMPAIGNS_TABLE)}
    rowSelectAvailable={rowSelectAvailable}
  />
);

export default GeneralFilterCampaignsList;
