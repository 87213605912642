// @flow
import {
  takeEvery,
  put,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { GET_HISTORY_REPLACE_LISTS, GET_HISTORY_REPLACE_LISTS_LOADING } from "../../helpers/constants";
import { getDropdownList } from "../../services/historyReplaceApi";
import { addHistoryReplaceDictionaries } from "../../actions/dictionaries";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(GET_HISTORY_REPLACE_LISTS_LOADING, true));

    const data = yield call(getDropdownList);

    yield put(addHistoryReplaceDictionaries(data));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));

    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(GET_HISTORY_REPLACE_LISTS_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_HISTORY_REPLACE_LISTS, (makeFetch: Function));
}
