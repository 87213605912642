// @flow
import React from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import configureStore from "./store";
import AntifraudVariablesList from "../../containers/AntifraudVariablesList";
import NotificationsContainer from "../../components/Notification";

const store = configureStore();

function AntifraudVariables() {
  return (
    <DocumentTitle title="Antifraud - Variables">
      <Provider store={store}>
        <AntifraudVariablesList />
        <NotificationsContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default AntifraudVariables;
