// @flow
import {
  CHANGE_SPLITS_FREQUENCY_METHOD,
  CHANGE_SPLITS_FREQUENCY_SCOPE,
  CHANGE_SPLITS_FREQUENCY_PERIOD,
  CHANGE_SPLITS_FREQUENCY_DEFAULT_SPLIT,
} from "../../helpers/constants";

export const changeFrequencyMethod = (method: string) => ({
  type: CHANGE_SPLITS_FREQUENCY_METHOD,
  method,
});

export const changeFrequencyPeriod = (period: string) => ({
  type: CHANGE_SPLITS_FREQUENCY_PERIOD,
  period,
});

export const changeFrequencyScope = (scope: string) => ({
  type: CHANGE_SPLITS_FREQUENCY_SCOPE,
  scope,
});

export const changeFrequencyDefaultSplit = (split: string) => ({
  type: CHANGE_SPLITS_FREQUENCY_DEFAULT_SPLIT,
  split,
});
