// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery,
  select,
  put,
  call,
} from "redux-saga/effects";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import type { Saga } from "redux-saga";
import environment from "environment";
import type { RuleTargetingQb } from "@fas/ui-core/lib/FlatQueryBuilder";
import {
  SAVE_TARGETING_GF_SAGA,
} from "../../helpers/constants";
import validator from "../../services/campaignValidation";
import {
  getTargetingRulesList,
  getAllTargetings,
  getFilterInfo,
} from "../../selectors/generalFilter/indexV2";
import { updateGeneralFilter } from "../../services/generalFilterApiV2";
import ExternalRedirect from "../../components/App/ExternalRedirect";
import type { Errors } from "../../services/campaignValidation/types/Base";
import { changeLoadingStatus } from "../../actions/loading";

export function* saveTargetingStep(): Saga<void> {
  const filterInfo: * = yield select(getFilterInfo);
  const {
    id,
    name,
    description,
    priority,
    rate,
    mustHave,
    isActive,
    tdsCampaignsIn,
    tdsCampaignsOut,
    conditions,
    dryModeRate,
    fact,
  }: * = filterInfo;
  const targetingRulesList: Array<RuleTargetingQb> = yield select(getTargetingRulesList, "1");
  const schema: * = yield select(getAllTargetings);
  const Validator: * = validator("smartlink");
  const targettingErrors: Errors = new Validator().validateTargetingV2(schema.byId, targetingRulesList);
  // $FlowFixMe
  yield put(setErrors(targettingErrors));

  const body: * = {
    id,
    name,
    description,
    priority,
    rate,
    mustHave,
    isActive,
    tdsCampaignsIn: tdsCampaignsIn.map((item: *): string => item.value).join(","),
    tdsCampaignsOut,
    conditions,
    isLastStep: true,
    dryModeRate,
    fact,
    targeting: schema.byId,
  };

  if (!Object.keys(targettingErrors).length) {
    try {
      yield put(changeLoadingStatus("loadingSave", true));
      const response: * = yield call(updateGeneralFilter, body, id);
      if (response.status === 200) {
        yield put(changeLoadingStatus("loadingSave", false));
        ExternalRedirect({ to: environment.endpoints.redirect.generalFilter });
        return;
      }

      yield put(changeLoadingStatus("loadingSave", false));
    }
    catch (err) {
      yield put(changeLoadingStatus("loadingSave", false));
      yield put(addNotification({ message: parseResponse(err), severity: "error" }));
    }
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(SAVE_TARGETING_GF_SAGA, saveTargetingStep);
}
