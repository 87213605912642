// @flow
/* eslint-disable import/max-dependencies */
import {
  debounce,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { GET_ANTIFRAUD_VARIABLES_LIST_SAGA, ANTIFRAUD_VARIABLES_TABLE } from "../../helpers/constants";
import { fetchAntifraudVariablesList } from "../../services/antifraudVariablesApi";
import type { Variable, VariableListItem } from "../../selectors/antifraudVariables";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(ANTIFRAUD_VARIABLES_TABLE, true));

    const page: number = yield select(getTablePage, ANTIFRAUD_VARIABLES_TABLE);
    const limit: number = yield select(getTablePageSize, ANTIFRAUD_VARIABLES_TABLE);
    const sorting: Sorting = yield select(getTableSorting, ANTIFRAUD_VARIABLES_TABLE);
    const filters: Filters = yield select(getTableFilters, ANTIFRAUD_VARIABLES_TABLE);

    const {
      data, count,
    }: { data: Array<Variable>, count: number } = yield call(fetchAntifraudVariablesList, {
      page,
      limit,
      sorting,
      filters,
    });

    const tableData: Array<VariableListItem> = data
      .map(({ varId, ...rest }: Variable): VariableListItem => ({ ...rest, id: `${varId}`, varId }));

    yield put(setTableData(ANTIFRAUD_VARIABLES_TABLE, tableData));
    yield put(changeTableItemsTotalAmount(ANTIFRAUD_VARIABLES_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch Variables", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(ANTIFRAUD_VARIABLES_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield debounce(1000, GET_ANTIFRAUD_VARIABLES_LIST_SAGA, (makeFetch: Function));
}
