// @flow
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PasteValuesPopup } from "@fas/ui-core";
import type { SelectedTargetingRule } from "@fas/ui-core/lib/FlatQueryBuilder";
import type { MultiselectTypes, Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import { getTargetingRuleById, getValues } from "../../selectors/generalFilter/indexV2";
import type { State } from "../../reducers/gfTargeting";

type OwnProps = $ReadOnly<{|
  open: boolean,
  ruleId: string,
  onClose: () => void,
  onSave: (Array<Option>) => void,
|}>;

type StateToProps = $ReadOnly<{|
  getRuleById: (string) => SelectedTargetingRule,
  getValuesList: (string) => {
    multiSelectType: MultiselectTypes,
    list: Array<Option>
  },
|}>;

type DispatchToProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
|}>;

const mapStateToProps = (state: State): StateToProps => ({
  getRuleById: (id: string) => getTargetingRuleById(state, id),
  getValuesList: (name: string) => getValues(state, name),
});

const mapDispatchToProps = () => ({});

const PasteValuesPopupContainer = ({
  open,
  ruleId,
  getRuleById,
  getValuesList,
  onClose,
  onSave,
}: Props) => {
  const selectedRule = open ? getRuleById(ruleId) : {
    name: "",
    value: [],
  };
  const ruleValuesList = open ? getValuesList(selectedRule.name).list : [];
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [incorrectValues, setIncorrectValues] = useState([]);

  const handleApply = (values: Array<string>) => {
    const duplicates = [];
    const incorrects = [];
    let ruleLabels = ruleValuesList.map(({ label }: Option) => label);

    if (!ruleValuesList.length) {
      ruleLabels = values.filter((value, index, array) => array.indexOf(value) === index);
    }

    values.forEach((value, index) => {
      if (!ruleLabels.includes(value)) {
        incorrects.push(value);
      }
      if (values.indexOf(value) !== index) {
        duplicates.push(value);
      }
    });

    setIncorrectValues([].concat(duplicates, incorrects));
  };

  useEffect(() => {
    const newSelectedLabels = [];
    selectedRule.value.forEach((ruleValue) => {
      const rule = ruleValuesList.find(({ value }) => (value === ruleValue)) || {
        label: ruleValue,
        value: ruleValue,
      };
      if (rule) {
        newSelectedLabels.push(rule.label);
      }
    });

    setSelectedLabels(newSelectedLabels);
  }, [selectedRule.value]);

  return (
    <PasteValuesPopup
      open={open}
      ruleName={selectedRule.name}
      values={selectedLabels}
      incorrectValues={incorrectValues}
      handleClose={() => {
        setIncorrectValues([]);
        onClose();
      }}
      handleApply={handleApply}
      handleSave={(newValues) => {
        const values = [];

        newValues.forEach((ruleValue) => {
          const rule = ruleValuesList.find(({ label }) => (label === ruleValue)) || {
            label: ruleValue,
            value: ruleValue,
          };
          if (rule) {
            values.push(rule);
          }
        });

        onSave(values);
      }}
    />
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(PasteValuesPopupContainer);
