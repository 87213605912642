// @flow
import { List } from "immutable";
import {
  ADD_SPLIT_SAGA,
  ADD_SPLIT,
  DELETE_SPLIT_SAGA,
  DELETE_SPLIT,
  CLONE_SPLIT_SAGA,
  SPLIT_IS_ACTIVE_CHANGE,
  SPLIT_IS_ACTIVE_CHANGE_SAGA,
  MOVE_SPLIT_NAMES,
  SET_SPLIT_WEIGHT,
  SET_SPLIT_FREQUENCY,
  SPLIT_WEIGHTS_EQUAL_SHARES,
  SAVE_SPLIT_SAGA,
  OPEN_SPLIT_SAGA,
  CLOSE_SPLIT_SAGA,
  CHANGE_FUNNEL_ORDER,
  SET_SPLIT_FUNNELTEMPLATE,
  FETCH_FUNNEL_TEMPLATE_SAGA,
} from "../../helpers/constants";

export type AddSplitSagaAction = {
  type: typeof ADD_SPLIT_SAGA,
};

export type AddSplitAction = {
  type: typeof ADD_SPLIT,
  id: string,
  name: string,
  isActive: boolean,
  weight: number,
  frequency: Array<number>,
  funnelTemplate?: string,
};

export type DeleteSplitSagaAction = {
  type: typeof DELETE_SPLIT_SAGA,
  id: string,
};

export type DeleteSplitAction = {
  type: typeof DELETE_SPLIT,
  id: string,
};

export type CloneSplitSagaAction = {
  type: typeof CLONE_SPLIT_SAGA,
  id: string,
};

export type ChangeIsActiveAction = {
  type: typeof SPLIT_IS_ACTIVE_CHANGE,
  id: string,
};

export type ChangeIsActiveActionSaga = {
  type: typeof SPLIT_IS_ACTIVE_CHANGE_SAGA,
  id: string,
};

export type SplitNamesMapper = {
  [key: string]: string,
};

export type MoveSplitNamesAction = {
  type: typeof MOVE_SPLIT_NAMES,
  mapper: SplitNamesMapper,
};

export type SetSplitWeightAction = {
  type: typeof SET_SPLIT_WEIGHT,
  splitId: string,
  weight: number,
};

export type SetSplitFrequency = {
  type: typeof SET_SPLIT_FREQUENCY,
  splitId: string,
  frequency: Array<number>,
};

export type SplitEqualShares = {
  type: typeof SPLIT_WEIGHTS_EQUAL_SHARES,
};

export type OpenSplitSagaAction = {
  type: typeof OPEN_SPLIT_SAGA,
  splitId: string,
};

export type CloseSplitSagaAction = {
  type: typeof CLOSE_SPLIT_SAGA,
};

export type SaveSplitSagaAction = {
  type: typeof SAVE_SPLIT_SAGA,
  splitId: string,
};

export type ChangeFunnelsOrderAction = {
  type: typeof CHANGE_FUNNEL_ORDER,
  splitId: string,
  funnels: Array<string>,
};

export type ChangeFunnelTemplateAction = {
  type: typeof SET_SPLIT_FUNNELTEMPLATE,
  splitId: string,
  funnelTemplateId?: string,
};

export type FetchFunnelTemplateAction = {
  type: typeof FETCH_FUNNEL_TEMPLATE_SAGA,
  templateId: string
};

export type SplitActions =
  | ChangeFunnelsOrderAction
  | OpenSplitSagaAction
  | SaveSplitSagaAction
  | CloseSplitSagaAction
  | SplitEqualShares
  | SetSplitFrequency
  | SetSplitWeightAction
  | MoveSplitNamesAction
  | ChangeIsActiveActionSaga
  | ChangeIsActiveAction
  | CloneSplitSagaAction
  | DeleteSplitAction
  | DeleteSplitSagaAction
  | AddSplitAction
  | AddSplitSagaAction
  | ChangeFunnelTemplateAction
  | FetchFunnelTemplateAction;

export const changeFunnelsOrder = (
  splitId: string,
  funnels: Array<string>
): ChangeFunnelsOrderAction => ({
  type: CHANGE_FUNNEL_ORDER,
  splitId,
  funnels,
});

export const openSplitSaga = (splitId: string): OpenSplitSagaAction => ({
  type: OPEN_SPLIT_SAGA,
  splitId,
});

export const closeSplitSaga = (): CloseSplitSagaAction => ({
  type: CLOSE_SPLIT_SAGA,
});

export const saveSplitSaga = (splitId: string): SaveSplitSagaAction => ({
  type: SAVE_SPLIT_SAGA,
  splitId,
});

export const addSplitSaga = (): AddSplitSagaAction => ({
  type: ADD_SPLIT_SAGA,
});

export const addSplit = (
  id: string,
  name: string,
  isActive: boolean,
  weight: number = 100,
  frequency: List<number> = List([]),
  funnelTemplate?: string
): AddSplitAction => ({
  type: ADD_SPLIT,
  id,
  name,
  isActive,
  weight,
  frequency,
  funnelTemplate,
});

export const deleteSplitSaga = (id: string): DeleteSplitSagaAction => ({
  type: DELETE_SPLIT_SAGA,
  id,
});

export const deleteSplit = (id: string): DeleteSplitAction => ({
  type: DELETE_SPLIT,
  id,
});

export const cloneSplitSaga = (id: string): CloneSplitSagaAction => ({
  type: CLONE_SPLIT_SAGA,
  id,
});

export const changeIsActive = (id: string): ChangeIsActiveAction => ({
  type: SPLIT_IS_ACTIVE_CHANGE,
  id,
});

export const changeIsActiveSaga = (id: string): ChangeIsActiveActionSaga => ({
  type: SPLIT_IS_ACTIVE_CHANGE_SAGA,
  id,
});

export const moveSplitNames = (
  mapper: SplitNamesMapper
): MoveSplitNamesAction => ({
  type: MOVE_SPLIT_NAMES,
  mapper,
});

export const setSplitWeight = (
  splitId: string,
  weight: number
): SetSplitWeightAction => ({
  type: SET_SPLIT_WEIGHT,
  splitId,
  weight,
});

export const setSplitFrequency = (
  splitId: string,
  frequency: Array<number>
): SetSplitFrequency => ({
  type: SET_SPLIT_FREQUENCY,
  splitId,
  frequency,
});

export const splitWeightsEqualShares = (): SplitEqualShares => ({
  type: SPLIT_WEIGHTS_EQUAL_SHARES,
});

export const setSplitFunnelTemplate = (
  splitId: string,
  funnelTemplateId?: string
): ChangeFunnelTemplateAction => ({
  type: SET_SPLIT_FUNNELTEMPLATE,
  funnelTemplateId,
  splitId,
});

export const fetchFunnelTemplate = (
  templateId: string
): FetchFunnelTemplateAction => ({
  type: FETCH_FUNNEL_TEMPLATE_SAGA,
  templateId,
});
