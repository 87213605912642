import {
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import {
  SAVE_FUNNEL_SAGA,
} from "../../helpers/constants";
import { clearBackup } from "../../actions/backup";
import { closeFunnelModal } from "../../actions/modals";
import {
  getErrors,
  getCampaignType,
  getState,
  getTargetingIdsByFunnelId,
  getTargetingRulesList,
  getAllTargetingsById,
} from "../../selectors/smartlink";
import validator from "../../services/campaignValidation";

export function* saveFunnelSaga(action) {
  const { funnelId } = action;
  const fullState = yield select(getState);
  const type = yield select(getCampaignType);
  const targetingIdsList = yield select(getTargetingIdsByFunnelId, funnelId);
  const rulesDictionary = yield select(getTargetingRulesList);
  const targetingRules = yield select(getAllTargetingsById, targetingIdsList);
  const Validator = validator(type);
  let validationErrors = new Validator().validateFunnel(fullState, action);
  const targettingErrors = new Validator().validateTargetingV2(targetingRules, rulesDictionary);
  validationErrors = Object.assign({}, validationErrors, targettingErrors);

  yield put(setErrors(validationErrors));

  const errors = yield select(getErrors);

  if (!errors.size) {
    yield put(closeFunnelModal());
    yield put(clearBackup());
  }
}

export default function* watchSaveFunnel() {
  yield takeEvery(SAVE_FUNNEL_SAGA, saveFunnelSaga);
}
