// @flow
/* eslint-disable import/max-dependencies */

import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import {
  TargetingRuleComponent,
  TargetingRuleContainer,
  GroupContainer,
} from "@fas/ui-core";
import { removeError } from "@fas/ui-framework/lib/redux/actions/errors";
import type {
  RuleTargetingQb,
  SelectedTargetingRule,
  BaseOperator,
} from "@fas/ui-core/lib/FlatQueryBuilder";
import type { MultiselectTypes, Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import type {
  ChangeRuleAction,
} from "../../actions/targetings";

import {
  getRootTargetingByFunnelId,
  getTargetingByIdV2,
  getTargetingOperators,
  getTargetingRulesListV2,
  getTargetingValue,
  getLoadingStatusForValues,
  getIsTargetingListLoading,
  getTargetingErrorById,
  canChangeRule,
} from "../../selectors/smartlink";
import {
  addGroupAction,
  addRuleAction,
  changeOperatorAction,
  changeRuleAction,
  changeTargetingGroupOperator,
  changeValueAction,
  fetchTargetingValuesSaga,
  removeGroupAction,
  removeRule,
} from "../../actions/targetings";
import { selectCountriesList } from "../../selectors/generalFilter/indexV2";

type OwnProps = {|
  // eslint-disable-next-line react/no-unused-prop-types
  funnelId: string,
|}

type DispatchProps = {|
  // eslint-disable-next-line react/no-unused-prop-types
  changeGroupOperator: (string, "AND" | "OR") => void,
  addRule: (string) => void,
  // eslint-disable-next-line react/no-unused-prop-types
  addGroup: (groupId: string) => void,
  removeGroup: (string) => void,
  changeValue: (ruleId: string, Array<Option>) => void,
  changeOperator: (ruleId: string, operator: {
    name: string,
    value: string,
  }) => void,
  changeRule: (ruleId: string, RuleTargetingQb) => ChangeRuleAction,
  handleFetchTargetingValuesSaga: (string) => void,
  handleDeleteTargeting: (string, string) => void,
  handleRemoveError: (Array<string>) => void,
|}

type StateProps = {|
  getRuleByIdSelector: () => SelectedTargetingRule,
  getRuleList: (string) => Array<RuleTargetingQb>,
  getValueList: (string) => ({ multiSelectType: MultiselectTypes, list: Array<Option>}),
  getOperatorsList: (string) => Array<BaseOperator>,
  engSpeakingCountries: Array<string>,
  commercialCountries: Array<string>,
  id: string,
  handleGetLoading: (string) => boolean,
  isRuleListLoading: boolean,
  isRuleDisabledForChange: (string) => boolean,
|}

type Props = {
  ...OwnProps,
  ...DispatchProps,
  ...StateProps,
}

const mapDispatchToProps = (dispatch) => ({
  changeGroupOperator: (id: string, operator: "OR" | "AND") => dispatch(changeTargetingGroupOperator(id, operator)),
  addRule: (id: string) => dispatch(addRuleAction(id)),
  addGroup: (id: string) => dispatch(addGroupAction(id)),
  removeGroup: (groupId: string) => dispatch(removeGroupAction(groupId)),
  changeValue: (ruleId: string,
    value: Array<Option>) => dispatch(changeValueAction(ruleId, value)),
  changeOperator: (ruleId: string, operator: {
    name: string,
    value: string,
  }) => dispatch(changeOperatorAction(ruleId, operator)),
  changeRule: (ruleId: string, rule: RuleTargetingQb) => dispatch(changeRuleAction(ruleId, rule)),
  handleFetchTargetingValuesSaga: (ruleName) => dispatch(fetchTargetingValuesSaga(ruleName)),
  handleDeleteTargeting: (id, groupId) => dispatch(removeRule(id, groupId)),
  handleRemoveError: (keyPath) => dispatch(removeError(keyPath)),
});

const mapStateToProps = (state, props: OwnProps) => {
  const { funnelId } = props;
  const rootId = getRootTargetingByFunnelId(state, funnelId);
  return {
    id: rootId,
    getRuleByIdSelector: getTargetingByIdV2,
    getRuleList: getTargetingRulesListV2,
    getValueList: getTargetingValue,
    getOperatorsList: getTargetingOperators,
    engSpeakingCountries: selectCountriesList(state, "englishSpeakingCountries"),
    commercialCountries: selectCountriesList(state, "commercialCountries"),
    handleGetLoading: getLoadingStatusForValues,
    isRuleListLoading: getIsTargetingListLoading(state),
    isRuleDisabledForChange: (id) => !canChangeRule(state, rootId, id),
  };
};

function TargetingV2(props: Props) {
  const {
    id,
    getRuleByIdSelector,
    addRule,
    removeGroup,
    getRuleList,
    getValueList,
    getOperatorsList,
    engSpeakingCountries,
    commercialCountries,
    changeValue,
    changeOperator,
    changeRule,
    handleFetchTargetingValuesSaga,
    handleDeleteTargeting,
    handleGetLoading,
    isRuleListLoading,
    handleRemoveError,
    isRuleDisabledForChange,
  } = props;
  const handleChangeRule = useCallback((ruleId, rule) => {
    changeRule(ruleId, rule);
    if (rule) {
      handleFetchTargetingValuesSaga(rule.name.value);
    }
    handleRemoveError(["targetings", ruleId]);
  }, [changeRule, handleFetchTargetingValuesSaga, handleRemoveError]);

  // should remove this after refactor targeting state
  const handleChangeValue = (ruleId, value) => {
    // $FlowFixMe
    changeValue(ruleId, value.map((val) => (val.subValues ? val : val.value)));
    handleRemoveError(["targetings", ruleId]);
  };
  return (
    <Box width="100%">
      <GroupContainer
        id={id}
        RuleComponent={TargetingRuleComponent}
        RuleContainer={TargetingRuleContainer}
        groupProps={{
          // $FlowFixMe
          getGroupByIdSelector: getRuleByIdSelector,
          addRule,
          removeGroup,
          removeRule: handleDeleteTargeting,
          getStatusRule: (ruleId) => isRuleDisabledForChange(ruleId),
          disabledDelete: true,
          disableSwitcher: true,
        }}
        ruleProps={{
          getRuleByIdSelector,
          getRuleList,
          getValuesList: getValueList,
          handleChangeValue,
          handleChangeRule,
          handleChangeOperator: changeOperator,
          getOperatorsList,
          checkLoadingForValues: handleGetLoading,
          engSpeakingCountries,
          commercialCountries,
          getErrors: getTargetingErrorById,
          isRemoveDisabled: true,
        }}
        isRuleListLoading={isRuleListLoading}
        disableSwitcher
      />
    </Box>
  );
}

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(TargetingV2);
