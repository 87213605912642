// @flow
import React from "react";
import {
  Link,
  Button,
} from "@mui/material";
import { withStyles } from "@mui/styles";

const FunnelTemplateViewHeaderBtn = ({
  color,
  underline = "none",
  innerIcon,
  innerText,
  path,
  dataTestid = "header-button",
  ...rest
}) => {
  const { classes } = rest;
  return (
    <Link
      color={color}
      underline={underline}
      href={path}
    >
      <Button
        classes={{
          root: classes.viewHeaderBtnText,
          iconSizeMedium: classes.viewHeaderBtnIcon,
        }}
        variant="outlined"
        startIcon={innerIcon}
        data-testid={dataTestid}
      >
        {innerText}
      </Button>
    </Link>
  );
};

export default withStyles(() => ({
  viewHeaderBtnText: {
    textTransform: "capitalize",
    fontWeight: "700",
    background: "white",
  },
  viewHeaderBtnIcon: {
    "& > *:first-child": {
      fontSize: 16,
    },
  },
}))(FunnelTemplateViewHeaderBtn);
