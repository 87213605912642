// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetDictionary from "../../sagas/getAntifraudDictionary";
import watchSaveAntifraudDictionary from "../../sagas/saveAntifraudDictionary";
import watchGetAntifraudDictionaryActions from "../../sagas/getAntifraudDictionaryActions";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetDictionary),
    call(watchSaveAntifraudDictionary),
    call(watchGetAntifraudDictionaryActions),
  ]);
}
