// @flow
import joi from "@hapi/joi";
import type { JoiError, Errors } from "../types/Base";
import type { BaseSimpleErrorsRule } from "./Base";
import type { State } from "../../../reducers/placeholders/reducer";

export default class Placeholders implements BaseSimpleErrorsRule {
  schema: Object;

  errors: Errors;

  errorKey: string;

  constructor() {
    this.errors = {};
    this.errorKey = "offers";

    const placeholderValueRegex = /[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯґҐіІїЇ]/;

    this.schema = joi
      .object({})
      .pattern(
        joi.string(),
        joi.string().pattern(placeholderValueRegex, { invert: true })
      )
      .error((errors) => this._onError(errors))
      .options({
        abortEarly: false,
      });
  }

  _onError(errors: Array<JoiError>): Array<JoiError> {
    errors.forEach((item) => {
      const {
        path: [plName],
      } = item;
      const errorKey = `placeholder_${plName}`;
      switch (item.code) {
        case "string.pattern.invert.base":
          this.errors = {
            ...this.errors,
            [errorKey]: {
              message:
                "Cyrillic characters are prohibited",
            },
          };
          break;
        default:
          break;
      }
    });

    return errors;
  }

  getErrors(data: State): Errors {
    this.schema.validate(data.toJS());
    return this.errors;
  }
}
