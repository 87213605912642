// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { tabClick, tabInit } from "../../actions/tabs";
import {
  getCurrentTabId,
  getCurrentTabUrlById,
  getTabTitles,
  getFunnelTemplateId,
  getFunnelTemplateName,
} from "../../selectors/templates";
import FunnelTemplateView from "../../components/FunnelTemplateView";

const history = createBrowserHistory();

type OwnProps = $ReadOnly<{|
  initTabName?: string,
|}>;

type Props = {
  ...OwnProps,
  funnelTemplateId: string,
  funnelTemplateName: string,
  currentTabId: string,
  tabsTitleList: Map<number, string>,
  getCurrentTabUrl: (tabId: string) => string,
  handleTabClick: (tabId: string) => void,
  handleInitTab: (urlParam: string) => void,
};

const mapStateToProps = (state) => ({
  funnelTemplateId: getFunnelTemplateId(state),
  funnelTemplateName: getFunnelTemplateName(state),
  tabsTitleList: getTabTitles(state),
  currentTabId: getCurrentTabId(state),
  getCurrentTabUrl: (tabId) => getCurrentTabUrlById(state, tabId),
});

const mapDispatchToProps = (dispatch) => ({
  handleTabClick: (tabId) => dispatch(tabClick(tabId)),
  handleInitTab: (urlParam) => dispatch(tabInit(urlParam)),
});

const FunnelTemplateInfo = ({
  initTabName,
  funnelTemplateId,
  funnelTemplateName,
  currentTabId,
  tabsTitleList,
  getCurrentTabUrl,
  handleTabClick,
  handleInitTab,
}: Props) => {
  useEffect(() => {
    if (initTabName) {
      handleInitTab(initTabName);
    }
  }, [handleInitTab, initTabName]);

  const onTabClick = (id) => {
    handleTabClick(id);
    const tabUrl = getCurrentTabUrl(id);
    history.push({
      pathname: `/funnelTemplates/view/${funnelTemplateId}/${tabUrl}`,
    });
  };

  return (
    <FunnelTemplateView
      funnelTemplateId={funnelTemplateId}
      funnelTemplateName={funnelTemplateName}
      currentTabId={currentTabId}
      onTabClick={onTabClick}
      tabsTitleList={tabsTitleList}
    />
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(FunnelTemplateInfo);
