// @flow
import React from "react";
import { connect } from "react-redux";
import { Box, Typography } from "@mui/material";
import { SetupCard } from "@fas/ui-core";
import { Map } from "immutable";
import PlaceholdersList from "../../components/PlaceholdersList";
import {
  changePlaceholder,
  changePlaceholderValue,
  clickPreset,
  clearPlaceholders,
} from "../../actions/placeholders";
import PresetsList from "../../components/PresetsList";
import type { Placeholder } from "../../components/PlaceholdersList/types/PlaceholdersList.types";
import {
  getPlaceholders, getPlaceholdersList, getPresetsList, getErrors,
} from "../../selectors/smartlink";
import type { State } from "../../reducers/placeholders/reducer";
// eslint-disable-next-line import/max-dependencies
import type { Preset } from "../../components/PresetsList/types/PresetsList.types";

type OwnProps = $ReadOnly<{||}>;

type Props = {
  ...OwnProps,
  placeholders: State,
  placeholdersList: Array<Placeholder>,
  presetsList: Array<Preset>,
  handleClickPlaceholder: (name: string, value?: string) => any,
  handleChangePlaceholderValue: (name: string, value: string) => any,
  handleClickPreset: (placeholders: Array<Placeholder>) => any,
  handleClearPlaceholders: () => any,
  errors: Map
};

const mapStateToProps = (state): State => ({
  placeholders: getPlaceholders(state),
  placeholdersList: getPlaceholdersList(state),
  presetsList: getPresetsList(state),
  errors: getErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleClickPlaceholder: (name, value) => dispatch(changePlaceholder(name, value)),
  handleChangePlaceholderValue: (name, value) => dispatch(changePlaceholderValue(name, value)),
  handleClickPreset: (placeholders) => dispatch(clickPreset(placeholders)),
  handleClearPlaceholders: () => dispatch(clearPlaceholders()),
});

const Placeholders = ({
  placeholders,
  placeholdersList,
  presetsList,
  handleClickPlaceholder,
  handleChangePlaceholderValue,
  handleClickPreset,
  handleClearPlaceholders,
  errors,
}: Props) => {
  const placeholdersData = placeholders.toJS();
  return (
    <SetupCard title="Placeholders">
      <>
        <Box mb={3}>
          <Box mb={1}>
            <Typography>Presets</Typography>
          </Box>
          <PresetsList
            presetsList={presetsList}
            onClickPreset={handleClickPreset}
            onClear={handleClearPlaceholders}
          />
        </Box>
        <Box>
          <Box mb={1}>
            <Typography>Placeholders</Typography>
          </Box>
          <PlaceholdersList
            placeholdersList={placeholdersList}
            data={placeholdersData}
            onClickPlaceholder={handleClickPlaceholder}
            onChangeData={handleChangePlaceholderValue}
            errors={errors}
          />
        </Box>
      </>
    </SetupCard>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(Placeholders);
