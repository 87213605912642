// @flow
import {
  SET_BACKUP,
  CLEAR_BACKUP,
  RESTORE_BACKUP,
} from "../../helpers/constants";

type Backup = Array<string>;

export type SetBackupAction = {
  type: typeof SET_BACKUP,
  backup: Backup
};

export type ClearBackupAction = {
  type: typeof CLEAR_BACKUP,
};

export type RestoreBackupAction = {
  type: typeof RESTORE_BACKUP,
};

export const setBackup = (backup: Backup): SetBackupAction => ({
  type: SET_BACKUP,
  backup,
});

export const clearBackup = (): ClearBackupAction => ({
  type: CLEAR_BACKUP,
});

export const restoreBackup = (): RestoreBackupAction => ({
  type: RESTORE_BACKUP,
});
