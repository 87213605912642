// @flow
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import {
  ADD_FILTERING,
  CHANGE_FILTERING_RULE,
  CHANGE_FILTERING_RULE_OFFER,
  CHANGE_FILTERING_RULE_ONE_LINK_OFFER,
  CHANGE_FILTERING_RULE_VALUE,
  CLONE_FILTERING_SAGA,
  DELETE_FILTERING,
  DELETE_FILTERING_SAGA,
  CHANGE_FILTERING_RULE_OFFER_V2,
  CHANGE_FILTERING_RULE_VALUE_V2,
} from "../../helpers/constants";
import type { Filtering } from "../../reducers/filterings";
import type { Offer, ValueOption } from "../../components/FilteringList/types/FilteringList.types";

export type AddFilteringType = {|
  type: typeof ADD_FILTERING,
  funnelId: string,
  filteringId: string,
  filtering?: Filtering,
|};

export type DeleteFilteringType = {|
  type: typeof DELETE_FILTERING,
  funnelId: string,
  filteringId: string,
|};

export type DeleteFilteringSagaType = {|
  type: typeof DELETE_FILTERING_SAGA,
  funnelId: string,
  filteringId: string,
|};

export type CloneFilteringSagaType = {|
  type: typeof CLONE_FILTERING_SAGA,
  funnelId: string,
  filteringId: string,
|};

export type ChangeFilteringRuleType = {|
  type: typeof CHANGE_FILTERING_RULE,
  filteringId: string,
  name: string,
|};

export type ChangeFilteringRuleValueType = {|
  type: typeof CHANGE_FILTERING_RULE_VALUE,
  filteringId: string,
  value: Array<ValueOption | string>,
|};

export type ChangeFilteringRuleValueTypeV2 = {|
  type: typeof CHANGE_FILTERING_RULE_VALUE_V2,
  filteringId: string,
  value: Array<Option>,
|};

export type ChangeFilteringRuleOfferType = {|
  type: typeof CHANGE_FILTERING_RULE_OFFER,
  filteringId: string,
  offer: Offer,
|};
export type ChangeFilteringRuleOneOfferType = {|
  type: typeof CHANGE_FILTERING_RULE_ONE_LINK_OFFER,
  filteringIds: Array<string>,
  offer: Offer,
|};
export type ChangeFilteringRuleOffer = {|
  type: typeof CHANGE_FILTERING_RULE_OFFER_V2,
  filteringId: string,
  rule: *,
|};

export type FilteringActions = AddFilteringType
| DeleteFilteringType
| CloneFilteringSagaType
| ChangeFilteringRuleType
| ChangeFilteringRuleValueType
| ChangeFilteringRuleOfferType
| ChangeFilteringRuleOneOfferType
| DeleteFilteringSagaType
| ChangeFilteringRuleOffer
| ChangeFilteringRuleValueTypeV2

export const addFiltering = (funnelId: string, filteringId: string, filtering?: Filtering): FilteringActions => ({
  type: ADD_FILTERING,
  funnelId,
  filteringId,
  filtering,
});

export const cloneFilteringSaga = (funnelId: string, filteringId: string): FilteringActions => ({
  type: CLONE_FILTERING_SAGA,
  funnelId,
  filteringId,
});

export const deleteFiltering = (funnelId: string, filteringId: string): FilteringActions => ({
  type: DELETE_FILTERING,
  funnelId,
  filteringId,
});

export const deleteFilteringSaga = (funnelId: string, filteringId: string): DeleteFilteringSagaType => ({
  type: DELETE_FILTERING_SAGA,
  funnelId,
  filteringId,
});

export const changeFilteringRule = (filteringId: string, name: string): FilteringActions => ({
  type: CHANGE_FILTERING_RULE,
  filteringId,
  name,
});

export const changeFilteringRuleValue: (
  filteringId: string,
  value: Array<ValueOption | string>
) => ChangeFilteringRuleValueType = (
  filteringId: string,
  value: Array<ValueOption | string>
): ChangeFilteringRuleValueType => ({
  type: CHANGE_FILTERING_RULE_VALUE,
  filteringId,
  value,
});

export const changeFilteringRuleValueV2: (
  filteringId: string,
  value: Array<Option>
) => ChangeFilteringRuleValueTypeV2 = (
  filteringId: string,
  value: Array<Option>
): ChangeFilteringRuleValueTypeV2 => ({
  type: CHANGE_FILTERING_RULE_VALUE_V2,
  filteringId,
  value,
});

export const changeFilteringRuleOffer = (filteringId: string, offer: Offer): FilteringActions => ({
  type: CHANGE_FILTERING_RULE_OFFER,
  filteringId,
  offer,
});

export const changeFilteringRuleOfferV2 = (filteringId: string, rule: *): ChangeFilteringRuleOffer => ({
  type: CHANGE_FILTERING_RULE_OFFER_V2,
  rule,
  filteringId,
});

export const changeFilteringRuleOneLinkOffer = (filteringIds: Array<string>, offer: Offer): FilteringActions => ({
  type: CHANGE_FILTERING_RULE_ONE_LINK_OFFER,
  filteringIds,
  offer,
});
