// @flow
import React from "react";
import type { Element } from "react";
import { Button, Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { BaseCSSProperties } from "@mui/material/styles";
import { ClassNameMap } from "@mui/styles";
import { Multiselect } from "@fas/ui-core";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";

type Props = {
  isDefault: boolean,
  splitName: string,
  values: Array<string>,
  onClickDefault: () => void,
  onChangeValues: (values: Array<string>) => void,
  isActive?: boolean,
  error: boolean,
}

interface SyleProps {
  root: BaseCSSProperties
}

const useStyles: ClassNameMap = makeStyles<Theme, SyleProps>((theme: Theme): ClassNameMap => {
  const btnSize: number = 32;
  return {
    btn: {
      width: btnSize,
      height: btnSize,
      minWidth: btnSize,
      boxShadow: "none",
      marginRight: theme.spacing(1),
      "&:hover": "none",
    },
  };
});

const FrequencySelector: (Props) => Element<typeof Grid> = ({
  isDefault = false,
  splitName = "",
  values = [],
  onClickDefault,
  onChangeValues,
  isActive = true,
  error,
}: Props): Element<typeof Grid> => {
  const classes: ClassNameMap = useStyles();

  const onAddValue = (item) => {
    const uniqueValidValues = (v, i, a) => !isNaN(parseFloat(v)) && a.indexOf(v) === i;
    onChangeValues([...values, String(parseFloat(item.value))].filter(uniqueValidValues));
  };
  const onRemoveValue = (value) => {
    const itemIndex = values.indexOf(value);
    if (itemIndex !== -1) {
      const newValue = [...values];
      newValue.splice(itemIndex, 1);
      onChangeValues(newValue);
    }
  };
  return (
    <Grid container spacing={0} data-testid={`${splitName}-frequency-line`} alignItems="center">
      <Grid item>
        <Button
          className={classes.btn}
          variant="contained"
          color={isDefault ? "secondary" : "inherit"}
          onClick={onClickDefault}
          data-testid="is-default-button"
          disabled={!isActive}
        >
          {splitName}
        </Button>
      </Grid>
      <Grid item xs>
        <Multiselect
          type="manualMultiple"
          options={[]}
          selectedOptions={(values.map((value): Option => ({
            label: value,
            value,
          })))}
          error={{
            isError: error,
            helperText: "",
          }}
          onAddValue={onAddValue}
          onRemoveValue={onRemoveValue}
        />
      </Grid>
    </Grid>
  );
};

// $FlowFixMe
FrequencySelector.defaultProps = {
  isActive: true,
};

export default FrequencySelector;
