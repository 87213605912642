// @flow
import React, { type Element } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Visibility,
  EditOutlined,
  FileCopyOutlined,
  Check,
  RemoveCircle,
} from "@mui/icons-material";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import {
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
} from "@fas/ui-core";
import type {
  Props,
  StoreWithTable,
  Item,
} from "./types/TdsAdminTableActions.types";

const TdsAdminTableActions: (props: Props) => Element<typeof TableActions> = ({
  tableKey,
  editPath,
  getIsEditAvailable,
  viewPath,
  clonePath,
  onChangeIsActive,
  getIsActivateAvailable,
  getIsDeactivateAvailable,
  isCloneHidden,
  isViewHidden,
}: Props): Element<typeof TableActions> => {
  const items: Item[] = useSelector((state: StoreWithTable): Item[] => getTableSelections(state, tableKey));
  const dispatch: <A>(A) => A = useDispatch();
  const navigate = useNavigate();

  const getNavigatePath: (?string) => string = (path) => (path ? path.replace(":id", items[0].id) : "/");

  return (
    <TableActions>
      <TableActionGroup>
        {isViewHidden ? <></> : (
          <SimpleTableActionButton
            tooltip="View"
            disabled={items.length > 1 || !viewPath}
            onClick={() => navigate(getNavigatePath(viewPath))}
            Icon={<Visibility />}
            data-testid="viewButton"
          />
        )}
        <SimpleTableActionButton
          tooltip="Edit"
          disabled={!editPath || (getIsEditAvailable && getIsEditAvailable(items))}
          onClick={() => navigate(getNavigatePath(editPath))}
          Icon={<EditOutlined />}
          data-testid="editButton"
        />
        {isCloneHidden ? <></> : (
          <SimpleTableActionButton
            tooltip="Clone"
            disabled={items.length > 1 || !clonePath}
            onClick={() => {
              window.location = clonePath ? clonePath.replace(":id", items[0].id) : "/";
            }}
            Icon={<FileCopyOutlined />}
            data-testid="cloneButton"
          />
        )}
      </TableActionGroup>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Activate"
          disabled={!onChangeIsActive || (getIsActivateAvailable && getIsActivateAvailable(items))}
          onClick={(): mixed => onChangeIsActive && dispatch(onChangeIsActive())}
          Icon={<Check />}
          data-testid="activateButton"
        />
        <SimpleTableActionButton
          tooltip="Deactivate"
          disabled={!onChangeIsActive || (getIsDeactivateAvailable && getIsDeactivateAvailable(items))}
          onClick={(): mixed => onChangeIsActive && dispatch(onChangeIsActive())}
          Icon={<RemoveCircle />}
          data-testid="deactivateButton"
        />
      </TableActionGroup>
    </TableActions>
  );
};

export default TdsAdminTableActions;
