// @flow
import Joi from "@hapi/joi";
import BaseTargeting from "./Base";

class Group extends BaseTargeting {
  getJoiSchemaValidation() {
    return Joi.object({
      targetings: Joi.array().min(1).items(Joi.string()).required(),
      type: Joi.allow("group"),
      groupOperator: Joi.allow("OR", "AND"),
      parentId: Joi.any(),
    });
  }
}

export default Group;
