// @flow
import React from "react";
import { Box } from "@mui/material";
import { userService } from "../../services/user";
import FunnelTemplateViewHeader from "./FunnelTemplateViewHeader";
import TabsMapper from "../TabsMapper";
import FunnelTemplateGeneral from "../../containers/FunnelTemplateGeneral";
import FunnelTemplateFunnels from "../../containers/FunnelTemplateFunnels";
import FunnelTemplateCampaigns from "../../containers/FunnelTemplateCampaigns";

import type { Props } from "./types/FunnelTemplateView.types";
import JsonCampaignViewer from "../../containers/JsonCampaignViewer";

const tabPanelComponents = [
  { "id": 1, "name": "General", "component": FunnelTemplateGeneral },
  { "id": 2, "name": "Campaigns", "component": FunnelTemplateCampaigns },
  { "id": 3, "name": "Funnels", "component": FunnelTemplateFunnels },
  {
    "id": 4, "name": "JSON", "component": JsonCampaignViewer, permissions: [{ method: "GET", path: "/api/v1/campaign" }, "/unitedReborn/campaign/json"],
  },
];

const FunnelTemplateView = (props: Props) => {
  const {
    funnelTemplateId,
    funnelTemplateName,
    currentTabId,
    onTabClick,
    tabsTitleList,
  } = props;
  const tabs = [...tabsTitleList.values()].map((title) => ({ "label": title }));

  return (
    <Box
      p={3}
      width="100%"
    >
      <FunnelTemplateViewHeader
        funnelTemplateId={funnelTemplateId}
        funnelTemplateName={funnelTemplateName}
      />
      <TabsMapper
        value={currentTabId}
        onChange={onTabClick}
        tabs={[
          { label: "", disabled: true },
          ...tabs,
        ]}
        tabPanelComponents={tabPanelComponents.filter(({ permissions = [] }) => userService.can(permissions))}
      />
    </Box>
  );
};

export default FunnelTemplateView;
