import { all } from "redux-saga/effects";
import tabsSaga from "../../sagas/tabsSaga";
import loadFunnelTemplateCampaignListPageSaga from "../../sagas/loadFunnelTemplateCampaignListPageSaga";
import openFunnelSaga from "../../sagas/openFunnelSaga";
import closeFunnelSaga from "../../sagas/closeFunnelSaga";
import fetchTargetingsSaga from "../../sagas/fetchTargetingsSaga";
import fetchCampaignJsonSaga from "../../sagas/fetchCampaignJsonSaga";

export default function* mainSaga() {
  yield all([
    tabsSaga(),
    fetchCampaignJsonSaga(),
    loadFunnelTemplateCampaignListPageSaga(),
    openFunnelSaga(),
    closeFunnelSaga(),
    fetchTargetingsSaga(),
  ]);
}
