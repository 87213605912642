/* eslint-disable import/max-dependencies */
// @flow
import React, {
  type StatelessFunctionalComponent,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { Box } from "@mui/material";
import { Table } from "@fas/ui-core";
import { makeStyles } from "@mui/styles";
import { HISTORY_REPLACE_TABLE } from "../../helpers/constants";
import {
  getHistoryReplaceListSaga,
  getHistoryReplaceDropdownListsSaga,
  type GetHistoryReplaceListSaga,
} from "../../actions/historyReplace";
import TableActions from "../TdsAdminTableActions";
import type { Props, Classes } from "./HistoryReplaceList.types";
import { getHistoryReplaceDropdownLists, type HistoryReplaceItem } from "../../selectors/historyReplace";
import getColumns from "./getColumns";
import HistoryReplaceActions from "../HistoryReplaceActions";

const useStyles: () => Classes = makeStyles(() => ({
  smallTableCol: {
    minWidth: "80px",
    maxWidth: "80px",
  },
}));

const HistoryReplaceList: StatelessFunctionalComponent<Props> = () => {
  const tableProps: TableProps = useTable(HISTORY_REPLACE_TABLE);
  const dispatch: <A>(A) => A = useDispatch();
  const dropdownsList: { [string]: Array<string> } = useSelector(getHistoryReplaceDropdownLists);
  const classes: Classes = useStyles();

  const onGetHistoryReplaceListSaga: () => GetHistoryReplaceListSaga = () => (
    dispatch(getHistoryReplaceListSaga()));

  const columns: Array<Column<HistoryReplaceItem>> = getColumns(dropdownsList, classes);

  useEffect(() => {
    dispatch(getHistoryReplaceDropdownListsSaga());
  }, [getHistoryReplaceDropdownListsSaga]);

  return (
    <Box p={3}>
      <Box>
        <HistoryReplaceActions />
      </Box>
      <Table
        {...tableProps}
        title="Replace LP History"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onLoad={onGetHistoryReplaceListSaga}
        Actions={(
          <TableActions
            tableKey={HISTORY_REPLACE_TABLE}
            viewPath="/historyReplace/view/:id"
            isCloneHidden
          />
        )}
      />
    </Box>
  );
};

export default HistoryReplaceList;
