// @flow
import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import type { Placeholder, PlaceholderOptions } from "./types/PlaceholdersList.types";

type Props = Placeholder & PlaceholderOptions & {
  onClick: () => any,
}

const useStyles = makeStyles(() => ({
  upperCaseDisable: {
    textTransform: "none",
  },
}));

const PlaceholderButton = ({ name, selected, onClick }: Props) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.upperCaseDisable}
      variant="contained"
      disableElevation
      color={selected ? "primary" : "inherit"}
      onClick={onClick}
      data-testid={`placeholderButton-${name}`}
    >
      {name}
    </Button>
  );
};

export default React.memo<Props>(PlaceholderButton);
