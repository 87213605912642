/* eslint-disable import/max-dependencies */
// @flow
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch } from "redux";
import createSagaMiddleware from "redux-saga";
import notifications, { initNotificationsState } from "@fas/ui-framework/lib/redux/reducers/notifications";
import Immutable from "immutable";
import { tableReducer as tables, initTableState } from "@fas/ui-framework/lib/redux/reducers/table";
import loading, { initLoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import mainSaga from "./saga";
import funnelTemplateReport, { initFunnelTemplateReport } from "../../reducers/funnelTemplateReport";
import configureColumnsModal, { initConfigureFunnelTemplateReportColumnsModalState } from "../../reducers/configureFunnelTemplateReportColumnsModal";
import type { State as FunnelTemlateReportState } from "../../reducers/funnelTemplateReport";
import type { State as ConfigureColumnsModalState } from "../../reducers/configureFunnelTemplateReportColumnsModal";
import type { Actions as FunnelTemplateReportActions } from "../../actions/funnelTemplateReport";
import type { Actions as ConfigureColumnsActions } from "../../actions/configureFunnelTemplateReportColumnsModal";
import { FUNNEL_TEMPLATE_REPORT_TABLE } from "../../helpers/constants";

export type StoreType = {
  funnelTemplateReport: FunnelTemlateReportState,
  configureColumnsModal: ConfigureColumnsModalState,
};

export type Actions = FunnelTemplateReportActions | ConfigureColumnsActions;

function mapReportToState() {
  return {
    notifications: initNotificationsState(),
    funnelTemplateReport: initFunnelTemplateReport(),
    loading: initLoadingState({
      [FUNNEL_TEMPLATE_REPORT_TABLE]: false,
    }),
    tables: initTableState({
      [FUNNEL_TEMPLATE_REPORT_TABLE]: { pageSize: 20 },
    }),
    configureColumnsModal: initConfigureFunnelTemplateReportColumnsModalState(),
  };
}

const reducers = combineReducers({
  notifications,
  funnelTemplateReport,
  loading,
  tables,
  configureColumnsModal,
});

const sagaMiddleware = createSagaMiddleware();

export default () => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store = createStore<StoreType, Actions, Dispatch<Actions>>(
    reducers,
    mapReportToState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
