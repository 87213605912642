/* eslint-disable import/max-dependencies */
// @flow
import React, {
  type StatelessFunctionalComponent,
  type Element,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Table,
  SelectSearchComponent,
  IsActiveLabel,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import moment from "moment";
import { userService } from "../../services/user";
import { ANTIFRAUD_SCENARIOS_TABLE } from "../../helpers/constants";
import {
  getAntifraudScenariosListSaga,
  changeAntifraudScenarioActiveSaga,
  type GetAntifraudScenariosListSaga,
} from "../../actions/antifraudScenarios";
import TableActions from "../TdsAdminTableActions";
import type {
  Props,
  ScenarioListItem,
} from "./AntifraudScenariosList.types";

export type Classes = {
  smallTableCol: string,
  middleTableCol: string,
  longTableCol: string,
};

const useStyles: () => Classes = makeStyles(() => ({
  smallTableCol: {
    minWidth: "80px",
    maxWidth: "80px",
    wordBreak: "break-all",
  },
  middleTableCol: {
    minWidth: "160px",
    maxWidth: "160px",
    wordBreak: "break-all",
  },
  longTableCol: {
    minWidth: "350px",
    maxWidth: "350px",
    wordBreak: "break-all",
  },
}));

const onCreate: () => void = () => {
  window.location = "/antifraudScenarios";
};

const AntifraudScenariosList: StatelessFunctionalComponent<Props> = () => {
  const classes: Classes = useStyles();
  const tableProps: TableProps = useTable(ANTIFRAUD_SCENARIOS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const columns: Array<Column<ScenarioListItem>> = [
    {
      field: "date",
      label: "Date",
      searchable: false,
      sortable: true,
      className: classes.smallTableCol,
      render: ({ date }: ScenarioListItem): string => moment(date).format("MM-DD-YYYY hh:mm:ss"),
    },
    {
      field: "name",
      label: "Name",
      searchable: true,
      sortable: true,
      className: classes.middleTableCol,
    },
    {
      field: "typeName",
      label: "Type",
      searchable: false,
      sortable: false,
      className: classes.smallTableCol,
    },
    {
      field: "event",
      label: "Event",
      searchable: true,
      sortable: true,
      className: classes.middleTableCol,
    },
    {
      field: "isActive",
      label: "Is Active",
      searchable: true,
      sortable: true,
      className: classes.smallTableCol,
      render: IsActiveLabel,
      SearchComponent: (searchProps: SearchComponentProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { title: "All", value: "" },
            { title: "Active", value: "true" },
            { title: "Inactive", value: "false" },
          ]}
          label="isActive"
        />
      ),
    },
  ];

  const onGetAntifraudScenariosListSaga: () => GetAntifraudScenariosListSaga = () => (
    dispatch(getAntifraudScenariosListSaga()));

  const isActivateAvailable: boolean = userService.can([{ path: "/api/v1/frodi/scenarios/changeIsActive", method: "POST" }]);

  const getIsEditAvailable: (items: ScenarioListItem[]) => boolean = (items) => (
    items.length > 1 || !userService.can([{ path: "/api/v1/frodi/scenarios", method: "PUT" }])
  );

  const getIsActivateAvailable: (items: ScenarioListItem[]) => boolean = (items) => (
    items.some(({ isActive }: ScenarioListItem): boolean => isActive) || !isActivateAvailable
  );

  const getIsDeactivateAvailable: (items: ScenarioListItem[]) => boolean = (items) => (
    items.some(({ isActive }: ScenarioListItem): boolean => !isActive) || !isActivateAvailable
  );

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        title="Scenarios"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onCreate={onCreate}
        onLoad={onGetAntifraudScenariosListSaga}
        Actions={(
          <TableActions
            tableKey={ANTIFRAUD_SCENARIOS_TABLE}
            editPath="/antifraudScenarios/:id"
            getIsEditAvailable={getIsEditAvailable}
            onChangeIsActive={changeAntifraudScenarioActiveSaga}
            getIsActivateAvailable={getIsActivateAvailable}
            getIsDeactivateAvailable={getIsDeactivateAvailable}
          />
        )}
      />
    </Box>
  );
};

export default AntifraudScenariosList;
