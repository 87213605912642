import { put, select, takeEvery } from "redux-saga/effects";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import { CLONE_SPLIT_SAGA } from "../../helpers/constants";
import { addSplit } from "../../actions/splits";
// import { cloneFunnelSaga } from "../../actions/funnels";
import { cloneFunnelSaga } from "../cloneFunnelSaga/cloneFunnelSaga";
import {
  getIsActiveBySplitId,
  getSplitsAllIds,
  getSplitNames,
  getFunnelsBySplitId,
  getSplitById,
} from "../../selectors/smartlink";

export function* cloneSplit(action) {
  const allIds = yield select(getSplitsAllIds);
  const splitNames = yield select(getSplitNames);
  if (allIds.size === splitNames.size) {
    return;
  }

  const id = getNextId(allIds);
  const name = splitNames.get(allIds.size);
  const isActive = yield select(getIsActiveBySplitId, action.id);

  const splitCloneFrom = yield select(getSplitById, action.id);

  yield put(
    addSplit(
      id,
      name,
      isActive,
      splitCloneFrom.get("weight"),
      splitCloneFrom.get("frequency"),
      splitCloneFrom.get("funnelTemplate")
    )
  );

  const funnels = yield select(getFunnelsBySplitId, action.id);
  for (let i = 0; i < funnels.size; i++) {
    yield* cloneFunnelSaga({ splitId: id, funnelId: funnels.get(i) });
  }
}

export default function* watchCloneSplit() {
  yield takeEvery(CLONE_SPLIT_SAGA, cloneSplit);
}
