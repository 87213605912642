// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  CLONE_GENERAL_FILTER_SAGA,
  GENERAL_FILTER_TABLE,
} from "../../helpers/constants";
import { cloneGeneralFilter } from "../../services/generalFilterApiV2";
import { initTargetingStateEdit } from "../../actions/gfTargeting";
import { type CloneGeneralFilterSaga } from "../../actions/generalFilters";

export function* makeFetch({ id }: CloneGeneralFilterSaga): Saga<void> {
  try {
    yield put(setLoading(CLONE_GENERAL_FILTER_SAGA, true));

    const data = yield call(cloneGeneralFilter, id);

    yield put(initTargetingStateEdit(data.targeting));

    yield put(setTableSelection(GENERAL_FILTER_TABLE, []));
    yield put(addNotification({ message: "General Filter cloned", severity: "success" }));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to clone", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CLONE_GENERAL_FILTER_SAGA, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(CLONE_GENERAL_FILTER_SAGA, (makeFetch: Function));
}
