/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Box,
} from "@mui/material";
import type { Map } from "immutable";
import { useParams } from "react-router-dom";
import { StepControl } from "@fas/ui-core";
import { stepClick } from "@fas/ui-framework/lib/redux/actions/steps";
import { getNextStepId } from "@fas/ui-framework/lib/services/generators";
import type { Error } from "@fas/ui-framework/lib/redux/reducers/errors";
import {
  getCurrentStepId,
  getTemplateInfo,
  getErrors,
} from "../../selectors/templates";
import TemplateGeneralInfo from "../../components/TemplateGeneralInfo";
import type { TemplateInfoState } from "../../reducers/templateInfo";
import {
  changeTemplateName,
  changeTemplateType,
} from "../../actions/templateInfo";
import { startFetchCampaignIdSaga } from "../../actions/campaignInfo";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  campaignInfo: Map<TemplateInfoState>,
  currentStep: string,
  errors: Map<string, Error>,
  startFetchCampaignId: (type: string) => void,
  handleNextClick: (stepId: string) => void,
  handleNameChange: (name: string) => void,
  handleTypeChange: (type: string) => void,
|}>;

const mapStateToProps = (state) => ({
  campaignInfo: getTemplateInfo(state),
  currentStep: getCurrentStepId(state),
  errors: getErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  startFetchCampaignId: (type) => (dispatch(startFetchCampaignIdSaga(type))),
  handleNextClick: (stepId) => dispatch(stepClick(stepId)),
  handleNameChange: (name) => dispatch(changeTemplateName(name)),
  handleTypeChange: (type) => dispatch(changeTemplateType(type)),
});

const TemplateGeneralInfoStep = ({
  campaignInfo,
  currentStep,
  errors,
  startFetchCampaignId,
  handleNextClick,
  handleNameChange,
  handleTypeChange,
}: Props) => {
  const routerMatch = useParams();

  if (!routerMatch.id && !campaignInfo.get("id")) {
    startFetchCampaignId("template");
  }

  const {
    name,
    templateType: type,
  } = campaignInfo.toJS();

  return (
    <Container maxWidth="md">
      <Box data-testid="templateInfo">
        <TemplateGeneralInfo
          name={name}
          disabledName={Boolean(routerMatch && routerMatch.id)}
          type={type}
          errors={errors.toJS()}
          onChangeName={handleNameChange}
          onChangeType={handleTypeChange}
        />
      </Box>
      <Box mt={3} display="flex" justifyContent="flex-end">
        <StepControl
          showBackButton={false}
          onNext={() => handleNextClick(getNextStepId(currentStep))}
        />
      </Box>
    </Container>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(TemplateGeneralInfoStep);
