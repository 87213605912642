/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useEffect, useState,
} from "react";
import type { Node } from "react";
import { connect } from "react-redux";
import { Box, Button, Container } from "@mui/material";
import {
  GroupContainer,
  LoadingButton,
  TargetingRuleComponent,
  TargetingRuleContainer,
} from "@fas/ui-core";
import type { MultiselectTypes, Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import type { Props as TargetingRuleProps } from "@fas/ui-core/lib/FlatQueryBuilder/TargetingRule/TargetingRuleComponent";
import type {
  RuleTargetingQb,
  SelectedTargetingRule,
  BaseOperator,
} from "@fas/ui-core/lib/FlatQueryBuilder";
import type { Dispatch } from "redux";
import {
  removeError,
} from "@fas/ui-framework/lib/redux/actions/errors";
import CopyToClipboard from "copy-to-clipboard";
import { stepChange } from "@fas/ui-framework/lib/redux/actions/steps";
import {
  getTargetingRuleById,
  getTargetingGroupById,
  getIsTargetingListLoading,
  getTargetingRulesList,
  getOperators,
  getValues,
  getLoadingStatusForValues,
  getTargetingErrorById,
  getIsSaveGFLoading,
  getTargetingGroupErrorById, selectCountriesList,
} from "../../selectors/generalFilter/indexV2";
import {
  changeTargetingGroupOperatorGf,
  deleteTargetingGf,
  changeTargetingGfValue,
  changeTargetingGfRule,
  changeTargetingGfOperator,
  fetchTargetingGfSaga,
  fetchTargetingValuesGfSaga,
  addTargetingGroupGf,
  addTargetingGf,
  removeTargetingGfGroupSaga,
  saveTargetingGfSaga,
} from "../../actions/gfTargeting";
import type { Actions } from "../../actions/gfTargeting";
import type { State, GroupStateJs } from "../../reducers/gfTargeting";
import PasteValuesPopupContainer from "../PasteValuesPopupContainer";

type OwnProps = $ReadOnly<{||}>;
type StateProps = $ReadOnly<{|
  getRuleById: (State, string) => SelectedTargetingRule,
  getGroupById: (State, string) => GroupStateJs,
  isRuleListLoading: boolean,
  getRuleList: (State, string) => Array<RuleTargetingQb>,
  getOperatorsList: (string) => Array<BaseOperator>,
  getValuesList: (State, string) => {
    multiSelectType: MultiselectTypes,
    list: Array<Option>
  },
  engSpeakingCountries: string[],
  commercialCountries: string[],
  isValuesLoading: (State, string) => boolean,
  isSaveLoading: boolean,
|}>;
type DispatchProps = $ReadOnly<{|
  handleChangeGroupOperator: (string, "OR" | "AND") => void,
  handleRemoveRule: (string, string) => void,
  handleChangeValue: (string, Array<Option>) => void,
  handleChangeRule: (string, RuleTargetingQb) => void,
  handleChangeOperator: (string, BaseOperator) => void,
  fetchTargeting: () => void,
  fetchTargetingValues: (string) => void,
  handleAddGroup: (string) => void,
  handleAddRule: (string) => void,
  handleRemoveGroup: (string, ?string) => void,
  handleSave: () => void,
  handleRemoveError: (Array<string>) => void,
  handleStepChange: (string) => void,
|}>;

type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps
}

function mapStateToProps(state: State): StateProps {
  return {
    getRuleById: getTargetingRuleById,
    getGroupById: getTargetingGroupById,
    isRuleListLoading: getIsTargetingListLoading(state),
    getRuleList: getTargetingRulesList,
    getOperatorsList: getOperators,
    getValuesList: getValues,
    engSpeakingCountries: selectCountriesList(state, "englishSpeakingCountries"),
    commercialCountries: selectCountriesList(state, "commercialCountries"),
    isValuesLoading: getLoadingStatusForValues,
    isSaveLoading: getIsSaveGFLoading(state),
  };
}
function mapDispatchToProps(dispatch: Dispatch<Actions>): DispatchProps {
  return {
    handleChangeGroupOperator: (id: string, val: "OR" | "AND") => {
      dispatch(changeTargetingGroupOperatorGf(id, val));
    },
    handleRemoveRule: (id: string, parent: string) => {
      dispatch(deleteTargetingGf(id, parent));
    },
    handleChangeValue: (id: string, value: Array<Option>) => {
      dispatch(changeTargetingGfValue(id, value.map((val) => (val.subValues ? val : val.value))));
    },
    handleChangeRule: (id: string, rule: RuleTargetingQb) => {
      dispatch(changeTargetingGfRule(id, rule));
    },
    handleChangeOperator: (id: string, operator: BaseOperator) => {
      dispatch(changeTargetingGfOperator(id, operator));
    },
    fetchTargeting: () => {
      dispatch(fetchTargetingGfSaga());
    },
    fetchTargetingValues: (ruleName: string) => {
      dispatch(fetchTargetingValuesGfSaga(ruleName));
    },
    handleAddGroup: (id: string) => {
      dispatch(addTargetingGroupGf(id));
    },
    handleAddRule: (id: string) => {
      dispatch(addTargetingGf(id));
    },
    handleRemoveGroup: (id: string, parentId: ?string) => {
      dispatch(removeTargetingGfGroupSaga(id, parentId));
    },
    handleSave: () => {
      dispatch(saveTargetingGfSaga());
    },
    handleRemoveError: (keyPath: Array<string>) => {
      dispatch(removeError(keyPath));
    },
    handleStepChange: (id: string) => {
      dispatch(stepChange(id));
    },
  };
}

const TargetingsGeneralFilter: (Props) => Node = (props :Props) => {
  const {
    isRuleListLoading,
    getRuleById,
    getGroupById,
    handleChangeGroupOperator,
    handleRemoveRule,
    handleChangeValue,
    handleChangeRule,
    handleChangeOperator,
    handleRemoveGroup,
    fetchTargeting,
    fetchTargetingValues,
    getRuleList,
    getOperatorsList,
    getValuesList,
    handleAddGroup,
    handleAddRule,
    isValuesLoading,
    handleSave,
    handleRemoveError,
    engSpeakingCountries,
    commercialCountries,
    isSaveLoading,
    handleStepChange,
  }: Props = props;
  useEffect(() => {
    fetchTargeting();
  }, [fetchTargeting]);

  const [isPopupOpen, setPopupOpen] = useState(false);
  const [ruleId, setRuleId] = useState("");

  const closePopup = () => {
    setRuleId("");
    setPopupOpen(false);
  };
  const handleCopyValue = (id, values) => {
    CopyToClipboard(values.map(({ label }) => label).join(", "));
  };
  const handlePasteValue = (id) => {
    setRuleId(id);
    setPopupOpen(true);
  };

  return (
    <Container maxWidth="md">
      <Box>
        <GroupContainer
          id="1"
          isRuleListLoading={isRuleListLoading}
          RuleComponent={
            // eslint-disable-next-line no-unused-vars
            ({ isRemoveDisabled, ...propsRule }: TargetingRuleProps): Node => <TargetingRuleComponent {...propsRule} />
          }
          RuleContainer={TargetingRuleContainer}
          groupProps={{
            getGroupByIdSelector: getGroupById,
            changeGroupOperator: handleChangeGroupOperator,
            removeRule: handleRemoveRule,
            getStatusRule: (): boolean => false,
            getErrorMessage: getTargetingGroupErrorById,
            addGroup: (id: string) => {
              handleAddGroup(id);
              handleRemoveError(["targetings", id]);
            },
            removeGroup: (id: string, parentId: ?string) => {
              handleRemoveGroup(id, parentId);
              handleRemoveError(["targetings", id]);
            },
            addRule: (id: string) => {
              handleAddRule(id);
              handleRemoveError(["targetings", id]);
            },
            disabledDelete: true,
          }}
          ruleProps={{
            getRuleByIdSelector: getRuleById,
            handleChangeValue: (id: string, value: Array<Option>) => {
              handleChangeValue(id, value);
              handleRemoveError(["targetings", id]);
            },
            handleChangeRule: (id: string, rule: RuleTargetingQb) => {
              if (rule) {
                fetchTargetingValues(rule.name.value);
              }
              handleChangeRule(id, rule);
              handleRemoveError(["targetings", id]);
            },
            handleChangeOperator: (id: string, operator: BaseOperator) => {
              handleChangeOperator(id, operator);
              handleRemoveError(["targetings", id]);
            },
            isRemoveDisabled: false,
            checkLoadingForValues: isValuesLoading,
            getRuleList,
            getOperatorsList,
            getValuesList,
            engSpeakingCountries,
            commercialCountries,
            getErrors: getTargetingErrorById,
            handleCopyValue,
            handlePasteValue,
          }}
        />
        {isPopupOpen && ruleId && (
          <PasteValuesPopupContainer
            open={isPopupOpen}
            ruleId={ruleId}
            onClose={() => {
              closePopup();
            }}
            onSave={(values) => {
              handleChangeValue(ruleId, values);
              closePopup();
            }}
          />
        )}
      </Box>
      <Box mt={3} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleStepChange("1")}
        >
          Back
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSave}
          loading={isSaveLoading}
          data-testid="next-button"
        >
          Save
        </LoadingButton>
      </Box>
    </Container>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(TargetingsGeneralFilter);
