// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { getAntifraudTriggerListSaga } from "../../actions/antifraudTriggers";
import {
  CHANGE_ANTIFRAUD_TRIGGER_ACTIVE_SAGA,
  ANTIFRAUD_TRIGGERS_TABLE,
} from "../../helpers/constants";
import { changeAntifraudTriggerActive } from "../../services/antifraudTriggersApi";
import type { TriggerListItem } from "../../containers/AntifraudTriggerList/types/AntifraudTriggerList.types";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(ANTIFRAUD_TRIGGERS_TABLE, true));

    const selections: TriggerListItem[] = yield select(getTableSelections, ANTIFRAUD_TRIGGERS_TABLE);
    const status: boolean = !selections.every(({ isActive }: TriggerListItem): boolean => isActive);
    const ids: number[] = selections.map(({ ruleId }: TriggerListItem): number => ruleId);

    yield call(changeAntifraudTriggerActive, ids, status);
    yield put(setTableSelection(ANTIFRAUD_TRIGGERS_TABLE, []));
    yield put(getAntifraudTriggerListSaga());
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(ANTIFRAUD_TRIGGERS_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(CHANGE_ANTIFRAUD_TRIGGER_ACTIVE_SAGA, (makeFetch: Function));
}
