// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import createSagaMiddleware from "redux-saga";
import notifications from "@fas/ui-framework/lib/redux/reducers/notifications";
import Immutable from "immutable";
import { reducer as errors, initState as initErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors";
import { tableReducer as tables, initTableState } from "@fas/ui-framework/lib/redux/reducers/table";
import loading, { initLoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import mainSaga from "./saga";
import steps, { initFunnelTemplatesStepsState } from "../../reducers/funnelTemplatesSteps";
import splits, { initSplitsState } from "../../reducers/splits";
import frequency, { initFrequencyState } from "../../reducers/frequency";
import funnels, { initFunnelsState } from "../../reducers/funnels";
import dictionaries, { initDictionariesState } from "../../reducers/dictionaries";
import targetings, { initTargetingsState } from "../../reducers/targetings";
import offers, { initOffersState } from "../../reducers/offers";
import filterings, { initFilteringsState } from "../../reducers/filterings";
import modals, { initModalsState } from "../../reducers/modals";
import actionOptionsParams, { initActionOptionsParamsState } from "../../reducers/actionOptionsParams";
import campaignInfo, { initTemplateInfoState } from "../../reducers/templateInfo";
import backupReducer from "../../reducers/backup";
import searchNavigation, { initSearchNavigationState } from "../../reducers/searchNavigation/reducer";

import { OFFERS_TABLE } from "../../helpers/constants";

function mapTemplateToState(template) {
  return {
    splits: initSplitsState(template),
    frequency: initFrequencyState(template),
    campaignInfo: initTemplateInfoState(template),
    // $FlowFixMe
    steps: initFunnelTemplatesStepsState(template),
    // $FlowFixMe
    dictionaries: initDictionariesState(),
    errors: initErrorsState(),
    modals: initModalsState(template),
    funnels: initFunnelsState(template),
    targetings: initTargetingsState(template),
    offers: initOffersState(template),
    filterings: initFilteringsState(template),
    actionOptionsParams: initActionOptionsParamsState(template),
    loading: initLoadingState({
      [OFFERS_TABLE]: false,
      loadingSaveCampaign: false,
    }),
    searchNavigation: initSearchNavigationState(),
    tables: initTableState({
      [OFFERS_TABLE]: { pageSize: 20 },
    }),
  };
}

const reducers = combineReducers({
  searchNavigation,
  notifications,
  splits,
  frequency,
  campaignInfo,
  steps,
  dictionaries,
  errors,
  modals,
  funnels,
  targetings,
  offers,
  filterings,
  actionOptionsParams,
  loading,
  tables,
});

// eslint-disable-next-line
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  serialize: {
    immutable: Immutable,
  },
}) : compose;

const sagaMiddleware = createSagaMiddleware();

// $FlowFixMe
export default (template) => {
  const store = createStore(
    backupReducer(reducers),
    mapTemplateToState(template),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
