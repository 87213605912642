// @flow
import { Map } from "immutable";

type Item = {
  weight: number,
};

export type Collection = Map<{
  [key: string]: Map<Item>
}>;

export const equalShares = (keys: Array<string>, collection: Collection): Collection => {
  const totalLength = keys.length;

  if (!totalLength) {
    return collection;
  }

  const integerPart = Math.floor(100 / totalLength);
  const residualPart = 100 % totalLength;

  return collection.withMutations((newCollection) => {
    keys.forEach((key) => newCollection.setIn([key, "weight"], integerPart));

    for (let i = 0; i < residualPart; i++) {
      newCollection.update(keys[i], (item) => item.set("weight", item.get("weight") + 1));
    }
  });
};

export const isTotalWeightValid = (collection: Array<Item>): boolean => {
  let totalWeight = 0;

  collection.forEach(({ weight }) => {
    totalWeight += weight;
  });

  return totalWeight === 100;
};
