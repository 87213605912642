// @flow
import { SEARCH_FUNNELS, SET_SEARCH_DATA } from "../../helpers/constants";
import type { DefaultState } from "../../reducers/searchNavigation/reducer";

export type SearchFunnels = {
  type: typeof SEARCH_FUNNELS,
  text: string,
};

export type SetSearchData = {
  type: typeof SET_SEARCH_DATA,
  data: $Shape<DefaultState>
};

export type Actions = SearchFunnels
  | SetSearchData

export function searchFunnels(text: string): SearchFunnels {
  return {
    type: SEARCH_FUNNELS,
    text,
  };
}

export function setSearchData(data: $Shape<DefaultState>): SetSearchData {
  return {
    type: SET_SEARCH_DATA,
    data,
  };
}
