// @flow
import { List, Map, fromJS } from "immutable";
import {
  ADD_ACTION_OPTIONS_PARAMS,
  DELETE_ACTION_OPTIONS_PARAMS,
  SET_EMAIL_BASE64,
  SET_EMAIL_PLACEHOLDER,
  SET_USER_DATA_SOURCE,
} from "../../helpers/constants";
import type { Actions as ActionsActionOptionParams } from "../../actions/actionOptionsParams";
import type { Campaign } from "../campaignInfo/reducer";

export type ActionOptionsParam = {
  userDataSource: string,
  emailPlaceholder: string,
  emailBase64: boolean,
};

export const defaultActionOptionsParams: Map<ActionOptionsParam> = Map({
  userDataSource: "getAttribute",
  emailPlaceholder: "",
  emailBase64: false,
});

export type ActionOptionsParamsState = {
  byId: Map<{
    [key: string]: Map<ActionOptionsParam>
  }>,
  allIds: List<string>
};

export type State = ActionOptionsParamsState;

export const initialState: Map<State> = Map({
  byId: Map({}),
  allIds: List([]),
});

export function initActionOptionsParamsState(campaign: Campaign) {
  const { actionOptionsParams } = campaign;
  return actionOptionsParams ? fromJS(actionOptionsParams) : initialState;
}

type ActionType = ActionsActionOptionParams;

export default (state: Map<State> = initialState, action: ActionType) => {
  switch (action.type) {
    case SET_USER_DATA_SOURCE: {
      const { actionOptionsParamsId, userDataSource } = action;
      return state.setIn(["byId", actionOptionsParamsId, "userDataSource"], userDataSource);
    }
    case SET_EMAIL_PLACEHOLDER: {
      const { actionOptionsParamsId, emailPlaceholder } = action;
      return state.setIn(["byId", actionOptionsParamsId, "emailPlaceholder"], emailPlaceholder);
    }
    case SET_EMAIL_BASE64: {
      const { actionOptionsParamsId, emailBase64 } = action;
      return state.setIn(["byId", actionOptionsParamsId, "emailBase64"], emailBase64);
    }
    case ADD_ACTION_OPTIONS_PARAMS: {
      const { actionOptionsParamsId, actionOptionsParams = defaultActionOptionsParams } = action;
      return state.withMutations((newState) => {
        newState.setIn(["byId", actionOptionsParamsId], actionOptionsParams);
        if (!newState.get("allIds").includes(actionOptionsParamsId)) {
          newState.updateIn(["allIds"], (allIds) => allIds.push(actionOptionsParamsId));
        }
      });
    }
    case DELETE_ACTION_OPTIONS_PARAMS: {
      const { actionOptionsParamsId } = action;
      return state.withMutations((newState) => {
        newState.updateIn(["byId"], (byId) => byId.filterNot((v, k) => actionOptionsParamsId === k));
        newState.updateIn(["allIds"], (allIds) => allIds.filterNot((k) => actionOptionsParamsId === k));
      });
    }
    default:
      return state;
  }
};
