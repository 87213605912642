/* eslint-disable import/max-dependencies */
// @flow
import {
  takeEvery,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import parseJoiErrors from "@fas/ui-framework/lib/services/parseJoiErrors";
import { type ValidationResult } from "joi";
import {
  SAVE_ANTIFRAUD_DICTIONARY_SAGA,
  DYNAMIC_DICT,
} from "../../helpers/constants";
import { getAntifraudDictionary } from "../../selectors/antifraudDictionaries";
import { saveAntifraudDictionary, createAntifraudDictionary } from "../../services/antifraudDictionariesApi";
import type { DictionaryFormData, DictionaryForSave } from "../../selectors/antifraudDictionaries";
import { dynamicSchema, valueSchema } from "./schemas";
import ExternalRedirect from "../../components/App/ExternalRedirect";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(SAVE_ANTIFRAUD_DICTIONARY_SAGA, true));

    const {
      dictId,
      dictTypeName,
      value: elements,
      scenario,
      ttl,
      ...restDict
    }: DictionaryFormData = yield select(getAntifraudDictionary);

    let body: DictionaryForSave;
    let validationResult: ValidationResult;

    if (dictTypeName === DYNAMIC_DICT) {
      validationResult = dynamicSchema.validate({
        ...restDict,
        scenario,
        ttl,
      });
      body = { ...restDict, scenario, ttl };
    }
    else {
      const value: Array<string> = elements.split("\n").filter((val: string): boolean => val !== "");
      validationResult = valueSchema.validate({
        ...restDict,
        value,
      });
      body = { ...restDict, value };
    }

    const validationErrors: ErrorsMapper = parseJoiErrors(validationResult);
    yield put(setErrors(validationErrors));
    if (Object.keys(validationErrors).length) return;

    if (dictId) {
      yield call(saveAntifraudDictionary, body, dictId);
    }
    else {
      yield call(createAntifraudDictionary, body);
    }

    ExternalRedirect({ to: "/antifraudDictionaries/list" });
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(SAVE_ANTIFRAUD_DICTIONARY_SAGA, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(SAVE_ANTIFRAUD_DICTIONARY_SAGA, (makeFetch: Function));
}
