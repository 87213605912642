// @flow
import React from "react";
import { connect, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  OutlinedInput,
  Typography,
  Select,
  MenuItem,
  TextField,
  Switch,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Map, List } from "immutable";
import { userService } from "../../services/user";
import { getGeneralFilterInfo, selectErrors } from "../../selectors/generalFilter";
import {
  changeName,
  changeDescription,
  changePriority,
  changeRate,
  changeMustHave,
  changeIsActive,
  changeFact,
  changeDryMode,
} from "../../actions/generalFilterInfo";
import type { FilterInfo, ErrorOption } from "../../reducers/generalFilterInfo";
import {
  checkError,
  getErrorMessage,
} from "../../helpers/generalFilter";

type Props = {
  filterInfo: Map<FilterInfo>,
  handleChangeName: (name: string) => any,
  handleChangeDescription: (description: string) => any,
  handleChangePriority: (priority: number) => any,
  handleChangeRate: (rate: number) => any,
  handleChangeMustHave: (mustHave: boolean) => any,
  handleChangeIsActive: (isActive: boolean) => any,
  handleChangeFact: (fact: number) => any,
  handleChangeDryMode: (dryMode: number) => any,
  priorityList: List,

};

const mapStateToProps = getGeneralFilterInfo;
const mapDispatchToProps = (dispatch) => ({
  handleChangeName: (e) => dispatch(changeName(e.target.value)),
  handleChangeDescription: (e) => dispatch(changeDescription(e.target.value)),
  handleChangePriority: (e) => dispatch(changePriority(e.target.value)),
  handleChangeRate: (e) => dispatch(changeRate(e.target.value)),
  handleChangeMustHave: () => dispatch(changeMustHave()),
  handleChangeIsActive: () => dispatch(changeIsActive()),
  handleChangeFact: (e) => dispatch(changeFact(e.target.value)),
  handleChangeDryMode: (e) => dispatch(changeDryMode(e.target.value)),
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  labelSpacing: {
    marginLeft: theme.spacing(30),
  },
  cardContentBorderBottom: {
    borderBottom: "1px solid #edf2ff",
  },
}));

const itemsSelect = (items, value) => {
  if (items) {
    return items.map((item) => (
      <MenuItem key={`priorityId-${item}`} value={item} selected={item === value}>{item}</MenuItem>
    ));
  }

  return null;
};

const oldPriorityView = (oldPriority) => {
  if (oldPriority !== null) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <Typography>
            Old Priority
          </Typography>
        </Grid>
        <Grid style={{ padding: "10px" }} item xs={9}>
          {oldPriority}
        </Grid>
      </Grid>
    );
  }

  return null;
};
const FilterInfoComponent = ({
  filterInfo,
  handleChangeName,
  handleChangeDescription,
  handleChangePriority,
  handleChangeRate,
  handleChangeMustHave,
  handleChangeIsActive,
  handleChangeFact,
  handleChangeDryMode,
  priorityList,
}: Props) => {
  const classes = useStyles();
  const {
    id,
    name,
    description,
    priority,
    oldPriority,
    rate,
    mustHave,
    isActive,
    fact,
    dryModeRate,
  } = filterInfo.toJS();

  const errors: ErrorOption = useSelector(selectErrors);

  return (
    <Card data-testid="filter-info-box">
      <CardContent className={classes.cardContentBorderBottom}>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <Typography>
              Name
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl className={classes.formControl} variant="outlined" fullWidth>
              <TextField
                error={checkError("name", errors)}
                helperText={getErrorMessage("name", errors)}
                fullWidth
                value={name}
                onChange={handleChangeName}
                variant="outlined"
                size="small"
                inputProps={{ "data-testid": "name" }}
                disabled={!(!id || userService.can(["generalFilter.name"]))}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              Description
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl className={classes.formControl} variant="outlined" fullWidth>
              <OutlinedInput
                fullWidth
                value={description}
                onChange={handleChangeDescription}
                inputProps={{ "data-testid": "description" }}
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              Priority
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl className={classes.formControl} error={checkError("priority", errors)} variant="outlined" fullWidth>
              <Select
                value={priority}
                onChange={handleChangePriority}
                data-testid="prioritySelect"
                MenuProps={{ "data-testid": "select-menu" }}
                size="small"
              >
                <MenuItem value="" key="priorityId"> </MenuItem>
                {itemsSelect(priorityList, priority)}
              </Select>
              <FormHelperText>
                {getErrorMessage("priority", errors)}
              </FormHelperText>
            </FormControl>
          </Grid>
          {oldPriorityView(oldPriority)}
          <Grid item xs={3}>
            <Typography>
              Rate (%)
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl className={classes.formControl} variant="outlined" fullWidth>
              <TextField
                error={checkError("rate", errors)}
                helperText={getErrorMessage("rate", errors)}
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={rate}
                onChange={handleChangeRate}
                inputProps={{ "data-testid": "rate" }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              Fact (%)
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl className={classes.formControl} variant="outlined" fullWidth>
              <TextField
                error={checkError("fact", errors)}
                helperText={getErrorMessage("fact", errors)}
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={fact}
                onChange={handleChangeFact}
                InputProps={{
                  "data-testid": "factInput",
                  inputProps: { min: 0, max: 100 },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              Dry mode rate, %
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <FormControl className={classes.formControl} variant="outlined" fullWidth>
              <TextField
                error={checkError("dryModeRate", errors)}
                helperText={getErrorMessage("dryModeRate", errors)}
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={dryModeRate}
                onChange={handleChangeDryMode}
                InputProps={{
                  "data-testid": "dryModeRateInput",
                  inputProps: { min: 0, max: 100 },
                }}
                min={0}
                max={100}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <FormControlLabel
          control={(
            <Switch
              checked={mustHave}
              color="primary"
              onChange={handleChangeMustHave}
            />
          )}
          label="Must have"
          data-testid="must-have"
        />
        <FormControlLabel
          control={(
            <Switch
              checked={isActive}
              color="primary"
              onChange={handleChangeIsActive}
            />
          )}
          label="Is Active"
          data-testid="is-active"
        />
      </CardContent>
    </Card>
  );
};

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(FilterInfoComponent);
