// @flow
import {
  put,
  select,
  call,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import environment from "environment";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  SAVE_TEMPLATE_SAGA,
} from "../../helpers/constants";
import {
  startSaveTemplate,
} from "../../actions/template";
import type { SaveTemplateSagaType } from "../../actions/template";
import {
  getTemplateData,
  getTemplateId,
} from "../../selectors/templates";
import TemplateApi from "../../services/templateApi";
import externalRedirect from "../../components/App/ExternalRedirect";

export function* saveTemplate({ cb = () => {} }: SaveTemplateSagaType): Saga<void> {
  try {
    yield put(startSaveTemplate());

    const templateId = yield select(getTemplateId);
    const template = yield select(getTemplateData);
    yield call(TemplateApi.saveTemplate, template);

    yield put(addNotification({ message: "Template saved successfully", severity: "success" }));
    cb();

    externalRedirect({ to: `${environment.links.templateView}/${templateId}` });
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage, severity: "error" }));
    cb();
  }
}

export default function* watchSaveTemplate(): Saga<void> {
  yield takeEvery(SAVE_TEMPLATE_SAGA, saveTemplate);
}
