// @flow
import {
  SMARTLINK_AGGREGATORS_TABLE,
  CHANGE_SMARTLINK_AGGREGATOR_ACTIVE,
  SMARTLINK_AGGREGATORS_TO_CAMPAIGNS_TABLE,
} from "../../helpers/constants";

export type GetSmartlinkAggregatorsListSaga = {
  type: typeof SMARTLINK_AGGREGATORS_TABLE,
}

export type ChangeSmartlinkAggregatorActive = {
  type: typeof CHANGE_SMARTLINK_AGGREGATOR_ACTIVE,
}

export type GetSmartlinkAggregatorsToCampaignsSaga = {
  type: typeof SMARTLINK_AGGREGATORS_TO_CAMPAIGNS_TABLE
}

export type Actions = GetSmartlinkAggregatorsListSaga | ChangeSmartlinkAggregatorActive;

export const getSmartlinkAggregatorsListSaga: () => GetSmartlinkAggregatorsListSaga = () => ({
  type: SMARTLINK_AGGREGATORS_TABLE,
});

export const changeSmartlinkAggregatorActive: () => ChangeSmartlinkAggregatorActive = () => ({
  type: CHANGE_SMARTLINK_AGGREGATOR_ACTIVE,
});

export const getSmartlinkAggregatorsToCampaignsSaga: () => GetSmartlinkAggregatorsToCampaignsSaga = () => ({
  type: SMARTLINK_AGGREGATORS_TO_CAMPAIGNS_TABLE,
});
