// @flow
import { Map, List, fromJS } from "immutable";
import {
  ADD_FALLBACK_OFFER,
  DELETE_FALLBACK_OFFER,
} from "../../helpers/constants";
import type { Campaign } from "../campaignInfo/reducer";

export type FallbackState = {
  offers: List<string>
};

export type State = FallbackState;

export const initialState: Map<State> = Map({
  offers: List([]),
});

export function initFallbackState(campaign: Campaign) {
  let state;
  const { fallback } = campaign;
  if (fallback) {
    state = fromJS(fallback);
  }
  else {
    state = initialState;
  }
  return state;
}

export default (state: Map<State> = initialState, action: any) => {
  switch (action.type) {
    case ADD_FALLBACK_OFFER: {
      const { offerId } = action;

      return state.setIn(["offers", 0], offerId);
    }
    case DELETE_FALLBACK_OFFER: {
      const { offerId } = action;

      return state.deleteIn(["offers", state.get("offers").findIndex((el) => el === offerId)]);
    }
    default:
      return state;
  }
};
