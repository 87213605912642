// @flow
import Joi from "@hapi/joi";

const extendSchema = Joi.extend((joi) => ({
  type: "array",
  base: joi.array(),
  rules: {
    validateFrequencyUnique: {
      method() {
        return this.$_addRule({ name: "validateFrequencyUnique" });
      },
      validate: (value, helpers) => {
        const frequencyWeights = [];
        value.forEach(({ frequency }) => frequencyWeights.push(...frequency));
        const uniqueFrequencyWeights = [...new Set(frequencyWeights)];

        const isValid = frequencyWeights.length === uniqueFrequencyWeights.length;

        return isValid
          ? value
          : helpers.error("frequency.unique");
      },
    },
  },
}));

export default extendSchema;
