// @flow

import type { Placeholder } from "../components/PlaceholdersList/types/PlaceholdersList.types";
import type { Preset } from "../components/PresetsList/types/PresetsList.types";

export const placeholdersList: Array<Placeholder> = [
  {
    name: "_fData",
  },
  {
    name: "aclid",
  },
  {
    name: "c",
  },
  {
    name: "click",
  },
  {
    name: "click_id",
  },
  {
    name: "cr",
  },
  {
    name: "d_m",
  },
  {
    name: "data2",
  },
  {
    name: "data3",
  },
  {
    name: "data4",
  },
  {
    name: "dob",
  },
  {
    name: "email",
  },
  {
    name: "ex",
  },
  {
    name: "ic",
  },
  {
    name: "imp_id",
  },
  {
    name: "ip",
  },
  {
    name: "keyword",
  },
  {
    name: "orientation",
  },
  {
    name: "p1",
  },
  {
    name: "p2",
  },
  {
    name: "p3",
  },
  {
    name: "p4",
  },
  {
    name: "p5",
  },
  {
    name: "p6",
  },
  {
    name: "p7",
  },
  {
    name: "p8",
  },
  {
    name: "p9",
  },
  {
    name: "p10",
  },
  {
    name: "rcid",
  },
  {
    name: "s1",
  },
  {
    name: "s2",
  },
  {
    name: "s3",
  },
  {
    name: "s4",
  },
  {
    name: "s5",
  },
  {
    name: "s6",
  },
  {
    name: "b",
  },
  {
    name: "scr",
  },
  {
    name: "sex",
  },
  {
    name: "th",
  },
  {
    name: "tid",
  },
  {
    name: "tr",
  },
  {
    name: "ts",
  },
  {
    name: "userEmail",
  },
  {
    name: "utm_boff",
  },
  {
    name: "utm_campaign",
  },
  {
    name: "utm_content",
  },
  {
    name: "utm_medium",
  },
  {
    name: "utm_partner",
  },
  {
    name: "utm_source",
  },
  {
    name: "utm_term",
  },
  {
    name: "utm_sub",
  },
  {
    name: "xs",
  },
  {
    name: "zone_id",
  },
  {
    name: "SUBID",
  },
  {
    name: "wpid",
  },
  {
    name: "m",
    value: "ps",
  },
  {
    name: "utm_campaignid",
  },
  {
    name: "unitid",
  },
  {
    name: "matchtype",
  },
  {
    name: "mediatype",
  },
  {
    name: "creative",
  },
  {
    name: "keywordid",
  },
  {
    name: "placement",
  },
  {
    name: "adposition",
  },
  {
    name: "h",
  },
];
export const presetsList: Array<Preset> = [
  {
    name: "ae",
    title: "AE",
    placeholders: [
      { name: "utm_campaign", value: "{affid}" },
      { name: "utm_content", value: "{subid}" },
      { name: "s3", value: "{subid2}" },
      { name: "data2", value: "{clickid}" },
    ],
  },
  {
    name: "dd-exo",
    title: "DD Exoclick",
    placeholders: [
      { name: "utm_source", value: "{utm_source}" },
      { name: "utm_medium", value: "{utm_medium}" },
      { name: "utm_campaign", value: "{utm_campaign}" },
      { name: "utm_term", value: "{utm_term}" },
      { name: "utm_content", value: "{variation_id}" },
      { name: "p1", value: "{src_hostname}" },
      { name: "p5", value: "{zone_id}" },
      { name: "p8", value: "{time}" },
      { name: "p9", value: "{catregory_id}" },
      { name: "tr", value: "{conversions_tracking}" },
    ],
  },
  {
    name: "dd-tf",
    title: "DD TrafficFactory",
    placeholders: [
      { name: "utm_source", value: "{utm_source}" },
      { name: "utm_medium", value: "{utm_medium}" },
      { name: "utm_campaign", value: "{utm_campaign}" },
      { name: "utm_term", value: "{utm_term}" },
      { name: "utm_content", value: "{banner.name}" },
      { name: "p3", value: "{target.name}" },
    ],
  },
  {
    name: "dd-reporo",
    title: "DD Reporo",
    placeholders: [
      { name: "utm_source", value: "{utm_source}" },
      { name: "utm_medium", value: "{utm_medium}" },
      { name: "utm_campaign", value: "{utm_campaign}" },
      { name: "utm_term", value: "{utm_term}" },
      { name: "utm_content", value: "{repBannerid}" },
      { name: "p1", value: "{repWebsiteid}" },
      { name: "p3", value: "{repZoneid}" },
      { name: "rcid", value: "{rcid}" },
    ],
  },
  {
    name: "dd-tj",
    title: "DD TrafficJunky",
    placeholders: [
      { name: "utm_source", value: "{utm_source}" },
      { name: "utm_medium", value: "{utm_medium}" },
      { name: "utm_campaign", value: "{utm_campaign}" },
      { name: "utm_term", value: "{utm_term}" },
      { name: "utm_content", value: "{BanName}" },
      { name: "p1", value: "{SpotName}" },
    ],
  },
  {
    name: "dd-th",
    title: "DD TrafficHunt",
    placeholders: [
      { name: "utm_source", value: "{utm_source}" },
      { name: "utm_medium", value: "{utm_medium}" },
      { name: "utm_campaign", value: "{utm_campaign}" },
      { name: "utm_term", value: "{utm_term}" },
      { name: "utm_content", value: "[banner]" },
      { name: "p1", value: "[site]" },
      { name: "p2", value: "[user_os]" },
      { name: "p4", value: "[carrier]" },
      { name: "th", value: "[txn_id]" },
    ],
  },
  {
    name: "dd-ts",
    title: "DD TrafficStars",
    placeholders: [
      { name: "utm_source", value: "{utm_source}" },
      { name: "utm_medium", value: "{utm_medium}" },
      { name: "utm_campaign", value: "{utm_campaign}" },
      { name: "utm_term", value: "{utm_term}" },
      { name: "utm_content", value: "{creative_id}" },
      { name: "p1", value: "{site_id}" },
      { name: "p5", value: "{format}" },
      { name: "cr", value: "{click_id}" },
    ],
  },
  {
    name: "int",
    title: "INT",
    placeholders: [
      { name: "utm_source", value: "{utm_source}" },
      { name: "utm_campaign", value: "{utm_campaign}" },
      { name: "utm_content", value: "{utm_content}" },
      { name: "data2", value: "{data2}" },
      { name: "utm_sub", value: "{utm_sub}" },
    ],
  },
  {
    name: "cpa",
    title: "CPA",
    placeholders: [
      { name: "utm_source", value: "{utm_source}" },
      { name: "utm_medium", value: "{utm_medium}" },
      { name: "utm_campaign", value: "{utm_campaign}" },
      { name: "utm_term", value: "{utm_campaign}" },
      { name: "utm_content", value: "{utm_content}" },
      { name: "data2", value: "{data2}" },
      { name: "data3", value: "{data3}" },
      { name: "data4", value: "{data4}" },
      { name: "utm_sub", value: "{utm_sub}" },
    ],
  },
  {
    name: "profsocial",
    title: "",
    placeholders: [
      { name: "utm_source", value: "{utm_source}" },
      { name: "utm_campaign", value: "{utm_campaign}" },
      { name: "utm_content", value: "{utm_content}" },
      { name: "data2", value: "{data2}" },
      { name: "utm_sub", value: "{utm_sub}" },
      { name: "m", value: "ps" },
    ],
  },
  {
    name: "resale",
    title: "resale",
    placeholders: [
      { name: "s1", value: "{s1}" },
      { name: "s2", value: "{s2}" },
      { name: "s3", value: "{s3}" },
      { name: "s4", value: "{s4}" },
      { name: "s5", value: "{s5}" },
      { name: "s6", value: "{s6}" },
    ],
  },
  {
    name: "resaleBO",
    title: "resaleBO",
    placeholders: [
      { name: "s1", value: "{s1}" },
      { name: "s2", value: "{s2}" },
      { name: "s3", value: "{s3}" },
      { name: "s4", value: "{s4}" },
      { name: "s5", value: "{s5}" },
      { name: "s6", value: "{s6}" },
      { name: "b", value: "{b}" },
    ],
  },
  {
    name: "resaleSO",
    title: "resaleSO",
    placeholders: [
      { name: "s1", value: "{s1}" },
      { name: "s2", value: "{s2}" },
      { name: "s3", value: "{s3}" },
      { name: "s4", value: "{s4}" },
      { name: "s5", value: "{s5}" },
      { name: "s6", value: "{s6}" },
      { name: "wpid", value: "{wpid}" },
    ],
  },
  {
    name: "cpa-offer",
    title: "cpa-offer",
    placeholders: [
      { name: "utm_source", value: "int" },
      { name: "utm_medium", value: "{utm_medium}" },
      { name: "utm_campaign", value: "{utm_campaign}" },
      { name: "utm_term", value: "{utm_term}" },
      { name: "utm_content", value: "{utm_content}" },
      { name: "data2", value: "{data2}" },
      { name: "data3", value: "{data3}" },
      { name: "utm_sub", value: "{utm_sub}" },
      { name: "data4", value: "{data4}" },
      { name: "s1", value: "{s1}" },
      { name: "s2", value: "{s2}" },
      { name: "s3", value: "{s3}" },
      { name: "s4", value: "{s4}" },
      { name: "s5", value: "{s5}" },
      { name: "s6", value: "{s6}" },
    ],
  },
  {
    name: "ppc-baidu",
    title: "ppc-baidu",
    placeholders: [
      { name: "utm_campaignid", value: "{planid}" },
      { name: "unitid", value: "{unitid}" },
      { name: "matchtype", value: "{matchtype}" },
      { name: "mediatype", value: "{mediatype}" },
      { name: "creative", value: "{creative}" },
      { name: "keywordid", value: "{keywordid}" },
      { name: "placement", value: "{placement}" },
      { name: "adposition", value: "{adposition}" },
    ],
  },
];

export type PlaceholderType = { [string]: string };

/**
 * Get replaced placeholders
 * @param {PlaceholderType} placeholders source placeholder
 * @param {PlaceholderType} renamedPlaceholders placeholder to rename
 * @returns {PlaceholderType} renamed placeholders
 * @example getRenamedPlaceholders({ utm_campaign: "test" }, { utm_campaign: "{asd}" }) -> { asd: "" }
 */
// eslint-disable-next-line max-len
export function getRenamedPlaceholders(placeholders: PlaceholderType, renamedPlaceholders: PlaceholderType): PlaceholderType {
  const actualPlaceholders: PlaceholderType = { ...placeholders };

  Object.keys(renamedPlaceholders)
    // eslint-disable-next-line no-prototype-builtins
    .filter((key: string): boolean => actualPlaceholders.hasOwnProperty(key))
    .forEach((key: string) => {
      const renamedKey: string = renamedPlaceholders[key].replace(/[{}]/g, "");

      actualPlaceholders[renamedKey] = `{${renamedKey}}`;

      delete actualPlaceholders[key];
    });
  return actualPlaceholders;
}

/**
 * Convert placeholders to string
 * @param {PlaceholderType} obj placeholders
 * @param {string} operator symbol between placeholders
 * @param {string} equal symbol between key value each placeholder
 * @returns {string} income url by placeholders
 * @example placeholderToIncomeUrl({ utm_campaign: "test", data2: "click" }) -> utm_campaign=test&data2=click
 */
export function placeholderToIncomeUrl(obj: PlaceholderType, operator: string = "&", equal: string = "="): string {
  return Object.keys(obj)
    .reduce((acc: string, key: string, index: number): string => {
      const operatorSymbol: string = index === 0 ? "" : operator;
      const value: string = obj[key];
      return `${acc}${operatorSymbol}${key}${equal}${value}`;
    }, "");
}

/**
 * Parse incomeUrl to placeholders
 * @param {string} incomeUrl to parse
 * @param {string} operator symbol between placeholders
 * @param {string} equal symbol between key value each placeholder
 * @returns {{}} placeholders by income url
 * @example parseIncomeUrl("utm_campaign=test&data2=click") -> { utm_campaign: "test", data2: "click" }
 */
export function incomeUrlToPlaceholder(incomeUrl: string, operator: string = "&", equal: string = "="): PlaceholderType {
  const placeholders: PlaceholderType = {};
  incomeUrl.split(operator).forEach((elem: string) => {
    if (elem && elem.includes(equal)) {
      const [key, value] = elem.split(equal);
      placeholders[key] = value;
    }
  });

  return placeholders;
}
