/* eslint-disable import/max-dependencies */
// @flow
import React, {
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Table, SelectSearchComponent, IsActiveLabel } from "@fas/ui-core";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { userService } from "../../services/user";
import { ANTIFRAUD_VARIABLES_TABLE } from "../../helpers/constants";
import {
  getAntifraudVariablesListSaga,
  changeAntifraudVariableActiveSaga,
  type GetAntifraudVariablesListSaga,
} from "../../actions/antifraudVariablesList";
import TableActions from "../TdsAdminTableActions";
import type { Props, Classes } from "./AntifraudVariablesList.types";
import type { VariableListItem } from "../../selectors/antifraudVariables";

const onCreate: () => void = () => {
  window.location = "/antifraudVariables";
};

const useStyles: () => Classes = makeStyles(() => ({
  smallTableCol: {
    minWidth: "80px",
    maxWidth: "80px",
    wordBreak: "break-all",
  },
  middleTableCol: {
    minWidth: "160px",
    maxWidth: "160px",
    wordBreak: "break-all",
  },
  longTableCol: {
    minWidth: "350px",
    maxWidth: "350px",
    wordBreak: "break-all",
  },
}));

const AntifraudVariablesList: StatelessFunctionalComponent<Props> = () => {
  const classes: Classes = useStyles();
  const tableProps: TableProps = useTable(ANTIFRAUD_VARIABLES_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onGetAntifraudVariablesListSaga: () => GetAntifraudVariablesListSaga = () => (
    dispatch(getAntifraudVariablesListSaga()));

  const isActivateAvailable: boolean = userService.can([{ path: "/api/v1/frodi/variables/statusChange", method: "POST" }]);

  const getIsEditAvailable: (items: Array<VariableListItem>) => boolean = (items) => (
    items.length > 1 || !userService.can([
      { path: "/api/v1/frodi/variables", method: "GET" },
      { path: "/api/v1/frodi/variables", method: "PUT" },
    ]));

  const getIsActivateAvailable: (items: Array<VariableListItem>) => boolean = (items) => (
    items.some(({ isActive }: VariableListItem): boolean => isActive) || !isActivateAvailable
  );

  const getIsDeactivateAvailable: (items: Array<VariableListItem>) => boolean = (items) => (
    items.some(({ isActive }: VariableListItem): boolean => !isActive) || !isActivateAvailable
  );

  const columns: Array<Column<VariableListItem>> = [
    {
      field: "name",
      label: "Name",
      className: classes.middleTableCol,
      searchable: true,
      sortable: true,
    },
    {
      field: "description",
      label: "Description",
      className: classes.longTableCol,
      searchable: true,
      sortable: false,
    },
    {
      field: "type",
      label: "Type",
      className: classes.middleTableCol,
      searchable: true,
      sortable: true,
    },
    {
      field: "isActive",
      label: "Is Active",
      className: classes.smallTableCol,
      searchable: true,
      sortable: true,
      render: IsActiveLabel,
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { title: "All", value: "" },
            { title: "Active", value: "true" },
            { title: "Inactive", value: "false" },
          ]}
          label="isActive"
        />
      ),
    },
  ];

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        title="Variables"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onCreate={onCreate}
        onLoad={onGetAntifraudVariablesListSaga}
        Actions={(
          <TableActions
            tableKey={ANTIFRAUD_VARIABLES_TABLE}
            editPath="/antifraudVariables/:id"
            getIsEditAvailable={getIsEditAvailable}
            onChangeIsActive={changeAntifraudVariableActiveSaga}
            getIsActivateAvailable={getIsActivateAvailable}
            getIsDeactivateAvailable={getIsDeactivateAvailable}
          />
        )}
      />
    </Box>
  );
};

export default AntifraudVariablesList;
