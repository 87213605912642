// @flow
import { Map, List, fromJS } from "immutable";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import type { Placeholder } from "../../components/PlaceholdersList/types/PlaceholdersList.types";
import type { Preset } from "../../components/PresetsList/types/PresetsList.types";
import { placeholdersList, presetsList } from "../../helpers/placeholders";
import {
  SET_TARGETING_VALUES,
  SET_TARGETING_RULES,
  SET_FUNNELTEMPLATE,
  SET_FUNNELTEMPLATE_LIST,
  SET_TARGETING_VALUE,
  SET_CAMPAIGN_JSON,
  ADD_DICTIONARY,
  ADD_DICTIONARY_OPTIONS,
  SET_HISTORY_REPLACE_LISTS,
} from "../../helpers/constants";
import type {
  AddDictionaryAction,
  AddDictionaryOptionsAction,
  FunnelTemplate,
  Actions,
} from "../../actions/dictionaries";

export type DropDownObjItemType = Option;

export type DropDownObjType = {
  [string]: Array<DropDownObjItemType>
}

export type State = Map<{
  defaultSplitNames: List<string>,
  placeholders: Array<Placeholder>,
  presets: Array<Preset>,
  funnelTemplates: Array<FunnelTemplate>,
  jsonCampaign: string,
  englishSpeakingCountries: List<string>,
  commercialCountries: List<string>,
  historyReplace: Map<{
    [key: string]: List<string>
  }>,
}>;

export type TargetingRuleOption = {|
  name: {
    value: string,
    label: string,
  },
  group?: string,
  type: string,
  components: Array<string>,
  maxTargetingRulesCount: number,
  maxFilteringRulesCount: number,
  filteringMaxValueCount?: number,
  operators: Array<string>,
  allowedCampaignTypes: Array<string>,
|};

export type TargetingValueOption = {|
  label: string,
  value: string,
  subValues?: List<string>,
|};

export const initialState: Map<State> = Map({
  splitNames: List(["a", "b", "c", "d", "e", "f"]),
  placeholders: placeholdersList,
  presets: presetsList,
  maxFunnels: 300,
  targetingRules: List([]),
  targetingValues: Map({}),
  funnelTemplateValues: Map({}),
  funnelTemplates: List([]),
  jsonCampaign: {},
  englishSpeakingCountries: List([
    "usa",
    "can",
    "aus",
    "gbr",
    "irl",
    "nzl",
  ]),
  commercialCountries: List([
    "can",
    "aus",
    "gbr",
    "irl",
    "nzl",
    "usa",
    "ita",
    "esp",
    "swe",
    "dnk",
    "nor",
    "fin",
    "deu",
    "nld",
    "zaf",
    "prt",
    "che",
    "aut",
    "bel",
    "pol",
    "cze",
    "bra",
    "arg",
    "hun",
    "rou",
    "grc",
    "tur",
    "nga",
    "hrv",
    "svn",
    "mex",
    "chl",
    "jpn",
    "tha",
    "twn",
    "mys",
    "sgp",
    "kor",
  ]),
  historyReplace: Map({
    brand: [],
    locale: [],
    platform: [],
    safeMode: [],
    niche: [],
    s1: [],
    status: [],
    statusReplace: [],
    messageReplace: [],
  }),
});

export function initDictionariesState(shape?: {[key: string]: mixed}): Map<State> {
  return shape ? Map(shape) : initialState;
}

type Action = Actions<Object>;

export default (state: Map<State> = initialState, action: Action): Map<State> => {
  switch (action.type) {
    case SET_TARGETING_VALUES: {
      const { data } = action;

      return state.withMutations((newState) => {
        Object.keys(data).forEach((key) => newState.setIn(["targetingValues", key], data[key]));
      });
    }
    case SET_TARGETING_VALUE: {
      const { data, key } = action;

      return state.withMutations((newState) => {
        newState.setIn(["targetingValues", key], data);
      });
    }
    case SET_TARGETING_RULES: {
      const { data } = action;
      return state.withMutations((newState) => {
        newState.setIn(["targetingRules"], List(data));
      });
    }
    case SET_HISTORY_REPLACE_LISTS: {
      const { data } = action;
      return state.set("historyReplace", Map(data));
    }
    case SET_FUNNELTEMPLATE: {
      const { template } = action;
      return state.setIn(["funnelTemplateValues", template.id], template);
    }
    case SET_FUNNELTEMPLATE_LIST: {
      const { data } = action;
      return state.set("funnelTemplates", data);
    }
    case SET_CAMPAIGN_JSON: {
      const { jsonCampaign } = action;
      return state.set("jsonCampaign", jsonCampaign);
    }
    case ADD_DICTIONARY: {
      const { key, options }: AddDictionaryAction<*> = action;
      return state.set(key, fromJS(options));
    }
    case ADD_DICTIONARY_OPTIONS: {
      const { key, options: optionsJs }: AddDictionaryOptionsAction<*> = action;
      return state.withMutations((newState) => {
        const options = newState.get(key).merge(fromJS(optionsJs));
        newState.set(key, options);
      });
    }
    default:
      return state;
  }
};
