// @flow
import {
  ADD_ACTION_OPTIONS_PARAMS,
  CLONE_ACTION_OPTIONS_PARAMS_SAGA,
  DELETE_ACTION_OPTIONS_PARAMS,
  SET_EMAIL_BASE64,
  SET_EMAIL_PLACEHOLDER,
  SET_USER_DATA_SOURCE,
} from "../../helpers/constants";
import type { ActionOptionsParam } from "../../reducers/actionOptionsParams/reducer";

export type SetUserDataSourceType = {|
  type: typeof SET_USER_DATA_SOURCE,
  actionOptionsParamsId: string,
  userDataSource: string,
|};

export type SetEmailPlaceholderType = {|
  type: typeof SET_EMAIL_PLACEHOLDER,
  actionOptionsParamsId: string,
  emailPlaceholder: string,
|};

export type SetEmailBase64Type = {|
  type: typeof SET_EMAIL_BASE64,
  actionOptionsParamsId: string,
  emailBase64: boolean,
|};

export type AddActionOptionsParamsType = {|
  type: typeof ADD_ACTION_OPTIONS_PARAMS,
  funnelId: string,
  actionOptionsParamsId: string,
  actionOptionsParams?: ActionOptionsParam,
|};

export type DeleteActionOptionsParamsType = {|
  type: typeof DELETE_ACTION_OPTIONS_PARAMS,
  funnelId: string,
  actionOptionsParamsId: string,
|};

export type CloneActionOptionsParamsSagaType = {|
  type: typeof CLONE_ACTION_OPTIONS_PARAMS_SAGA,
  funnelId: string,
  actionOptionsParamsId: string,
|};

export type Actions = SetUserDataSourceType
  | SetEmailPlaceholderType
  | SetEmailBase64Type
  | CloneActionOptionsParamsSagaType
  | AddActionOptionsParamsType
  | DeleteActionOptionsParamsType;

export const setUserDataSource = (actionOptionsParamsId: string, userDataSource: string): Actions => ({
  type: SET_USER_DATA_SOURCE,
  actionOptionsParamsId,
  userDataSource,
});

export const setEmailPlaceholder = (actionOptionsParamsId: string, emailPlaceholder: string): Actions => ({
  type: SET_EMAIL_PLACEHOLDER,
  actionOptionsParamsId,
  emailPlaceholder,
});

export const setEmailBase64 = (actionOptionsParamsId: string, emailBase64: boolean): Actions => ({
  type: SET_EMAIL_BASE64,
  actionOptionsParamsId,
  emailBase64,
});

export const addActionOptionsParams = (
  funnelId: string,
  actionOptionsParamsId: string,
  actionOptionsParams?: ActionOptionsParam
): Actions => ({
  type: ADD_ACTION_OPTIONS_PARAMS,
  actionOptionsParamsId,
  actionOptionsParams,
  funnelId,
});

export const deleteActionOptionsParams = (funnelId: string, actionOptionsParamsId: string): Actions => ({
  type: DELETE_ACTION_OPTIONS_PARAMS,
  funnelId,
  actionOptionsParamsId,
});

export const cloneActionOptionsParamsSaga = (funnelId: string, actionOptionsParamsId: string): Actions => ({
  type: CLONE_ACTION_OPTIONS_PARAMS_SAGA,
  funnelId,
  actionOptionsParamsId,
});
