// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { getSmartlinkAggregatorsListSaga } from "../../actions/smartlinkAggregators";
import {
  CHANGE_SMARTLINK_AGGREGATOR_ACTIVE,
  SMARTLINK_AGGREGATORS_TABLE,
} from "../../helpers/constants";
import { changeSmartlinkAggregatorActive } from "../../services/smartlinkAggregatorsApi";
import type { SmartlinkRedirect } from "../../selectors/smartlinkAggregators";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(SMARTLINK_AGGREGATORS_TABLE, true));

    const selections: Array<SmartlinkRedirect> = yield select(getTableSelections, SMARTLINK_AGGREGATORS_TABLE);
    const status: boolean = !selections.every(({ isActive }: SmartlinkRedirect): boolean => isActive);
    const ids: number[] = selections.map(({ id }: SmartlinkRedirect): number => id);

    yield call(changeSmartlinkAggregatorActive, ids, status);
    yield put(addNotification({ message: "Status changed", severity: "success" }));
    yield put(setTableSelection(SMARTLINK_AGGREGATORS_TABLE, []));
    yield put(getSmartlinkAggregatorsListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to change status", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(SMARTLINK_AGGREGATORS_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(CHANGE_SMARTLINK_AGGREGATOR_ACTIVE, (makeFetch: Function));
}
