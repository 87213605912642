// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { HistoryReplaceItem, HistoryReplaceForm } from "../../selectors/historyReplace";
import type { Sorting } from "../../sagas/getTableSaga/getTableSaga";

// eslint-disable-next-line
export const fetchHistoryReplaceList: (
  options: {
    page: number,
    pageSize: number,
    filters: { [string]: mixed },
    sorting?: Sorting,
  },
) => PromiseResponse<{ data: { data: Array<HistoryReplaceItem>, count: number } }> = (
  options
) => {
  const { sorting, ...params }: {
    page: number,
    pageSize: number,
    filters: { [string]: mixed },
    sorting?: Sorting,
  } = options;
  let newSorting: Sorting;
  if (sorting && Object.keys(sorting).length) {
    const current = Object.keys(sorting)[0];
    newSorting = {
      current,
      direction: sorting[current],
    };
  }
  // $FlowFixMe
  const paramsData = {
    ...params,
    ...(newSorting && { sorting: newSorting }),
  };

  return requestService
    .post(environment.endpoints.post.getHistoryReplaceList, paramsData)
    .then(({ data }) => data);
};

export const getHistoryReplaceForm: (
  id: number
) => PromiseResponse<{ data: HistoryReplaceForm }> = (id) => requestService
  .get(environment.endpoints.get.getHistoryReplaceForm.replace("{id}", `${id}`))
  .then(({ data }) => data);

export function getDropdownList(): Promise<*> {
  return requestService
    .get(environment.endpoints.get.getHistoryReplaceDropdown)
    .then(({ data: { lists } }) => lists);
}
