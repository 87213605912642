// @flow
import {
  Map, List, fromJS,
} from "immutable";
import {
  FUNNEL_IS_ACTIVE_CHANGE,
  FUNNEL_NAME_CHANGE,
  DELETE_FUNNEL,
  ADD_FUNNEL,
  ADD_OFFER,
  DELETE_OFFER,
  ADD_TARGETING_TO_FUNNEL,
  ADD_FILTERING,
  CHANGE_FILTERING_ORDER,
  DELETE_FILTERING,
  ADD_ACTION_OPTIONS_PARAMS,
  DELETE_ACTION_OPTIONS_PARAMS,
} from "../../helpers/constants";
import type { FunnelActions } from "../../actions/funnels";
import type { OffersActions } from "../../actions/offers";
import type { FilteringActions } from "../../actions/filterings";
import type { Actions as ActionOptionsParamsActions } from "../../actions/actionOptionsParams/actions";

export type Funnel = {
  name: string,
  hits: string,
  timestamp: number,
  isActive: boolean,
  targetings: Array<string>,
  filterings: Array<string>,
  offers: Array<string>,
  rootTargeting: Array<string>
};

export type FunnelsState = {
  byId: Map<{
    [key: string]: Map<Funnel>
  }>,
  allIds: List<string>
};

export type State = FunnelsState;

export const initialState: Map<State> = Map({
  byId: Map({}),
  allIds: List([]),
});

export function initFunnelsState(data: { funnels: FunnelsState }) {
  let state;
  const { funnels } = data;
  if (funnels) {
    state = fromJS(funnels);
  }
  else {
    state = initialState;
  }
  return state;
}

type Action = FunnelActions
  | OffersActions
  | FilteringActions
  | ActionOptionsParamsActions

export default (state: Map<State> = initialState, action: Action) => {
  switch (action.type) {
    case FUNNEL_IS_ACTIVE_CHANGE: {
      const { id } = action;
      return state.updateIn(["byId", id], (funnel) => funnel.set("isActive", !funnel.get("isActive")));
    }
    case FUNNEL_NAME_CHANGE: {
      const { funnelId, name } = action;
      return state.setIn(["byId", funnelId, "name"], name.trim());
    }
    case DELETE_FUNNEL: {
      const { funnelId } = action;

      return state.withMutations((newState) => {
        newState.deleteIn(["byId", funnelId]);
        newState.deleteIn([
          "allIds",
          newState.get("allIds").findIndex((el) => el === funnelId),
        ]);
      });
    }
    case ADD_FUNNEL: {
      const {
        funnelId,
        name,
        timestamp,
        isActive,
        rootTargetingId,
      } = action;

      return state.withMutations((newState) => {
        newState.setIn(["byId", funnelId], Map({
          name,
          timestamp,
          isActive,
          rootTargeting: List([rootTargetingId]),
          filterings: List([]),
          offers: List([]),
        }));
        newState.updateIn(["allIds"], (allIds) => allIds.push(funnelId));
      });
    }
    case ADD_OFFER: {
      const {
        funnelId,
        offerId,
      } = action;

      return state.updateIn(["byId", funnelId, "offers"], (offers) => offers.push(offerId));
    }
    case DELETE_OFFER: {
      const {
        funnelId,
        offerId,
      } = action;

      return state.updateIn(
        ["byId", funnelId],
        (funnel) => funnel.deleteIn(["offers", funnel.get("offers").findIndex((el) => el === offerId)])
      );
    }
    case ADD_TARGETING_TO_FUNNEL: {
      const {
        funnelId,
        targetingRootId,
      } = action;

      return state.setIn(["byId", funnelId, "rootTargeting"], List([targetingRootId]));
    }
    case ADD_ACTION_OPTIONS_PARAMS: {
      const {
        funnelId,
        actionOptionsParamsId,
      } = action;

      return state.setIn(["byId", funnelId, "actionOptionsParams"], List([actionOptionsParamsId]));
    }
    case DELETE_ACTION_OPTIONS_PARAMS: {
      const {
        funnelId,
      } = action;

      return state.setIn(["byId", funnelId, "actionOptionsParams"], List([]));
    }
    case CHANGE_FILTERING_ORDER: {
      const {
        funnelId,
        filterings: orders,
      } = action;

      return state.updateIn(["byId", funnelId, "filterings"],
        (array) => array.sort((a, b) => orders.indexOf(a) - orders.indexOf(b)));
    }
    case ADD_FILTERING: {
      const {
        funnelId,
        filteringId,
      } = action;

      return state.withMutations((newState) => {
        newState.updateIn(["byId", funnelId, "filterings"], (filterings) => filterings.push(filteringId));
      });
    }
    case DELETE_FILTERING: {
      const {
        funnelId,
        filteringId,
      } = action;

      return state.updateIn(
        ["byId", funnelId],
        (funnel) => funnel.deleteIn(["filterings", funnel.get("filterings").findIndex((el) => el === filteringId)])
      );
    }
    default:
      return state;
  }
};
