/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { connect } from "react-redux";
import type { List, Map } from "immutable";
import { Box, Container } from "@mui/material";
import { AddData } from "@fas/ui-core";
import { getFunnelsArrayBySplitId, getFunnelSearchScrollToItem } from "../../selectors/smartlink";
import FunnelList from "../../components/FunnelList";
import { getErrors } from "../../selectors/templates";
import { openFunnelSaga } from "../../actions/funnels";
import { changeFunnelsOrder } from "../../actions/splits/actions";
import SmartlinkFunnel from "../SmartlinkFunnel";
import FunnelsTemplateFunnelItem from "../FunnelsTemplateFunnelItem";
import SearchFunnels from "../SearchFunnels/SearchFunnels";
import StepControl from "../StepControl";

type OwnProps = $ReadOnly<{||}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  funnelsIds: List<string>,
  scrollToItem: ?string,
  errors: Map<Error>,

  handleOpenFunnel: (splitId: string, funnelId: string | null) => void,
  handleChangeFunnelsOrder: (splitId: string, funnelsIds: Array<string>) => void,
|}>;

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  funnelsIds: getFunnelsArrayBySplitId(state, "1"),
  scrollToItem: getFunnelSearchScrollToItem(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleOpenFunnel: (splitId, funnelId) => dispatch(openFunnelSaga(splitId, funnelId)),
  handleChangeFunnelsOrder: (splitId, funnels) => dispatch(changeFunnelsOrder(splitId, funnels)),
});

const TemplateFunnelsStep: StatelessFunctionalComponent<Props> = ({
  errors,
  funnelsIds,
  handleOpenFunnel,
  handleChangeFunnelsOrder,
  scrollToItem,
}: Props) => {
  const funnelListContainerHeight: number = Math.min(
    70 * funnelsIds.length + 120,
    window.innerHeight - 120
  );

  return (
    <Container maxWidth="lg">
      <Box mt={3} mb={3}>
        {funnelsIds.length === 0 && (
          <AddData
            linkText="+ add new funnel"
            title="You have no funnels yet."
            error={errors.has("splits")}
            onClick={(): void => handleOpenFunnel("1", null)}
          />
        )}
        {funnelsIds.length !== 0 && (
          <Box height={`${funnelListContainerHeight}px`}>
            <FunnelList
              SearchComponent={SearchFunnels}
              FunnelItemComponent={FunnelsTemplateFunnelItem}
              splitId="1"
              funnelsIds={funnelsIds}
              onMoveRules={handleChangeFunnelsOrder}
              handleOpenFunnel={handleOpenFunnel}
              scrollToItem={scrollToItem}
            />
          </Box>
        )}
        <Box mt={3}>
          <StepControl showBackButton />
        </Box>
        <SmartlinkFunnel />
      </Box>
    </Container>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(TemplateFunnelsStep);
