// @flow
import { Map, fromJS } from "immutable";
import type { Campaign } from "../campaignInfo/reducer";
import {
  CHANGE_SPLITS_FREQUENCY_METHOD,
  CHANGE_SPLITS_FREQUENCY_PERIOD,
  CHANGE_SPLITS_FREQUENCY_DEFAULT_SPLIT,
} from "../../helpers/constants";

export type FrequencyState = {
  method: "weights" | "frequency" | "epc",
  period: "" | "24h" | "48h" | "72h" | "96h" | "120h",
  default: "a"
};

export const initialState: Map<FrequencyState> = Map({
  method: "weights",
  period: "",
  default: "a",
});

export function initFrequencyState(campaign: Campaign) {
  const { frequency } = campaign;

  return frequency ? fromJS(frequency) : initialState;
}

export default (state: Map<FrequencyState> = initialState, action: any) => {
  switch (action.type) {
    case CHANGE_SPLITS_FREQUENCY_METHOD: {
      const { method } = action;
      return state.set("method", method);
    }
    case CHANGE_SPLITS_FREQUENCY_PERIOD: {
      const { period } = action;
      return state.set("period", period);
    }
    case CHANGE_SPLITS_FREQUENCY_DEFAULT_SPLIT: {
      const { split } = action;
      return state.set("default", split);
    }
    default:
      return state;
  }
};
