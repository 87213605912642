import { put, select, takeEvery } from "redux-saga/effects";
import { getNextId } from "@fas/ui-framework/lib/services/generators";
import { CLONE_ACTION_OPTIONS_PARAMS_SAGA } from "../../helpers/constants";
import { getActionOptionsParamsById, getActionOptionsParamsllIds } from "../../selectors/smartlink";
import { addActionOptionsParams } from "../../actions/actionOptionsParams";

export function* cloneActionOptionsParamsSaga(action) {
  const { funnelId, actionOptionsParamsId } = action;

  const allIds = yield select(getActionOptionsParamsllIds);
  const nextId = getNextId(allIds);
  const actionOptionsParams = yield select(getActionOptionsParamsById, actionOptionsParamsId);

  yield put(addActionOptionsParams(funnelId, nextId, actionOptionsParams));
}

export default function* watchCloneActionOptionsParams() {
  yield takeEvery(CLONE_ACTION_OPTIONS_PARAMS_SAGA, cloneActionOptionsParamsSaga);
}
