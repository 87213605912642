// @flow
import { Map } from "immutable";
import type { Errors } from "../campaignValidation/types/Base";
import {
  getTemplateName,
  getTemplateType,
  getCurrentStepId,
} from "../../selectors/templates";
import {
  getSplitsById,
} from "../../selectors/smartlink";
import TemplateName from "./rules/templateName";
import TemplateType from "./rules/templateType";
import Splits from "../campaignValidation/rules/splits";
import Smartlink from "../campaignValidation/types/Smartlink";

export default class FunnelTemplate extends Smartlink { // todo move to campaignValidation/types
  _validateTemplateInfoStep(state: Map<{}>): Errors {
    const name = getTemplateName(state);
    const type = getTemplateType(state);

    const templateNameErrors = new TemplateName().getErrors(name);
    const templateTypeErrors = new TemplateType().getErrors(type);

    return Object.assign({}, templateNameErrors, templateTypeErrors);
  }

  _validateSplitsFunnelsStep(state: Map<{}>) {
    const splits = getSplitsById(state);

    let splitsErrors = new Splits().getErrors(splits);
    if (Object.keys(splitsErrors).length) {
      splitsErrors = { splits: splitsErrors };
    }

    return Object.assign({}, splitsErrors);
  }

  _validatePreviewStep() {
    return {};
  }

  validateCurrentStep(state: Map<{}>): Errors {
    const currentStepId: string = getCurrentStepId(state);

    switch (currentStepId) {
      case "1": {
        return this._validateTemplateInfoStep(state);
      }
      case "2": {
        return this._validateSplitsFunnelsStep(state);
      }
      case "3": {
        return this._validatePreviewStep();
      }
      default:
        return {};
    }
  }
}
