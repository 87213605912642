// @flow
import * as React from "react";
import {
  Divider, Paper, Box,
} from "@mui/material";
import SplitHeader from "./SplitHeader";
import type { Props as SplitHeaderProps } from "./SplitHeader";

type Props = SplitHeaderProps & {
  children?: React.Node,
}

const SplitItem = ({
  children,
  ...splitHeaderProps
}: Props) => (
  <Paper data-testid="split">
    <SplitHeader {...splitHeaderProps} />
    <Box mb={2}><Divider /></Box>
    {children}
  </Paper>
);

export default SplitItem;
