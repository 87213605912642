// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetAntifraudScenario from "../../sagas/getAntifraudScenario";
import watchSaveAntifraudScenario from "../../sagas/saveAntifraudScenario";
import watchGetAntifraudListSaga from "../../sagas/getAntifraudTriggerListSaga";
import watchGetDropdownList from "../../sagas/getDropdownList";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetAntifraudScenario),
    call(watchSaveAntifraudScenario),
    call(watchGetAntifraudListSaga),
    call(watchGetDropdownList),
  ]);
}
