// @flow
import React from "react";
import { Check, Close } from "@mui/icons-material";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import moment from "moment";
import type { FrequencyCapForJumps, Classes } from "./FrequencyCapForJumps.types";

export default function getColumns(
  classes: Classes
): Array<Column<FrequencyCapForJumps>> {
  return [
    {
      field: "jump_key",
      label: "Jump id",
      sortable: false,
      searchable: true,
      className: classes.smallTableCol,
    },
    {
      field: "name",
      label: "Name",
      sortable: true,
      searchable: true,
      className: classes.middleTableCol,
    },
    {
      field: "isFrequencyCap",
      label: "Is Frequency Cap",
      searchable: false,
      sortable: false,
      className: classes.smallTableCol,
      render: ({ isFrequencyCap }) => (isFrequencyCap ? <Check color="action" /> : <Close color="error" />),
    },
    {
      field: "createdAt",
      label: "Created At",
      sortable: true,
      searchable: false,
      className: classes.smallTableCol,
      render: ({ createdAt }) => moment(createdAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      field: "updatedAt",
      label: "Updated At",
      sortable: true,
      searchable: false,
      className: classes.smallTableCol,
      render: ({ updatedAt }) => moment(updatedAt).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      field: "updatedBy",
      label: "Updated By",
      sortable: true,
      searchable: false,
      className: classes.middleTableCol,
    },
  ];
}
