// @flow
/* eslint-disable max-classes-per-file */
import React, {
  type Node,
} from "react";
import { CopyToClipboardInput } from "@fas/ui-core";
import url from "url";
import type { Permission } from "@fas/ui-framework/lib/services/user";
import { userService } from "../../services/user";
import {
  URL_TYPE_REDIRECT,
  URL_TYPE_RICHMEDIA,
  URL_TYPE_IFRAME,
  URL_TYPE_API,
  URL_TYPE_AE,
  URL_TYPE_AEAPI,
  URL_TYPE_SHORTURL,
} from "../constants";
import RenamePlaceholderUrlBuilder from "../../components/RenamePlaceholderUrlBuilder";
import ShortUrlBuilder from "../../components/ShortUrlBuilder";

import { placeholderToIncomeUrl, type PlaceholderType } from "../placeholders";

type Url = {
  value: string,
  title: string,
  permissions: Permission[],
};

export type Props = {
  campaignId: string,
  protocol: string,
  domain: string,
  path: string,
  placeholders: PlaceholderType,
  shortUrlId: string,
  shortUrlTime: string,
}

export default class UrlType {
  _urlTypes: Url[];

  _protocol: string;

  _domain: string;

  _path: string;

  _campaignId: string;

  _placeholders: PlaceholderType;

  _shortUrlId: string;

  _shortUrlTime: string;

  constructor({
    campaignId,
    protocol,
    domain,
    path,
    placeholders,
    shortUrlId,
    shortUrlTime,
  }: Props) {
    this._campaignId = campaignId;
    this._protocol = protocol;
    this._domain = domain;
    this._path = path;
    this._placeholders = placeholders;
    this._shortUrlId = shortUrlId;
    this._shortUrlTime = shortUrlTime;

    this._urlTypes = [
      {
        value: URL_TYPE_REDIRECT,
        title: "Redirect",
        permissions: [],
      },
      {
        value: URL_TYPE_RICHMEDIA,
        title: "Rich media",
        permissions: [],
      },
      {
        value: URL_TYPE_IFRAME,
        title: "iFrame",
        permissions: [],
      },
      {
        value: URL_TYPE_API,
        title: "API",
        permissions: ["unitedReborn.ApiURL"],
      },
      {
        value: URL_TYPE_AE,
        title: "AE redirect",
        permissions: [],
      },
      {
        value: URL_TYPE_AEAPI,
        title: "AE API",
        permissions: [],
      },
      {
        value: URL_TYPE_SHORTURL,
        title: "Short URL",
        permissions: [],
      },
    ];
  }

  getUrlTypeValues(): Array<{ value: string, title: string }> {
    return this._urlTypes
      .filter(({ permissions }: Url): boolean => userService.can(permissions))
      .map(({ value, title }: Url): { value: string, title: string } => ({ value, title }));
  }

  _getUrlPart(incomeUrl: string): string {
    return `tdsId=${this._campaignId}_r&tds_campaign=${this._campaignId}&${incomeUrl}`;
  }

  _getUrl(incomeUrl: string): string {
    return url.format({
      protocol: this._protocol,
      hostname: this._domain,
      pathname: this._path,
      search: this._getUrlPart(incomeUrl),
    });
  }

  getView(): Node {
    const placeholders: PlaceholderType = this._placeholders;
    const incomeUrl: string = placeholderToIncomeUrl(placeholders);
    const value: string = this._getUrl(incomeUrl);

    return (
      <CopyToClipboardInput
        title="Campaign url"
        value={value}
        fullWidth
      />
    );
  }
}

export class AEUrlType extends UrlType {
  getView(): Node {
    return (
      <RenamePlaceholderUrlBuilder
        manualPlaceholders={{
          data2: "{clickid}",
          utm_content: "{subid}",
          s3: "{subid2}",
          utm_campaign: "{affid}",
        }}
        getUrl={(incomeUrl: string): string => this._getUrl(incomeUrl)}
        placeholders={this._placeholders}
      />
    );
  }
}

export class AEApiUrlType extends UrlType {
  _getUrl(incomeUrl: string): string {
    return url.format({
      protocol: this._protocol,
      hostname: this._domain,
      pathname: "/tdsApi",
      search: this._getUrlPart(incomeUrl),
    });
  }

  getView(): Node {
    return (
      <RenamePlaceholderUrlBuilder
        manualPlaceholders={{
          data2: "{clickid}",
          utm_content: "{subid}",
          s3: "{subid2}",
          utm_campaign: "{affid}",
        }}
        getUrl={(incomeUrl: string): string => this._getUrl(incomeUrl)}
        placeholders={this._placeholders}
      />
    );
  }
}

export class ApiUrlType extends UrlType {
  _getUrl(incomeUrl: string): string {
    return url.format({
      protocol: this._protocol,
      hostname: this._domain,
      pathname: "/tdsApi",
      search: this._getUrlPart(incomeUrl),
    });
  }
}

export class RichMediaUrlType extends UrlType {
  _getUrlPart(incomeUrl: string): string {
    return `tdsId=${this._campaignId}_r&tds_campaign=${this._campaignId}&${incomeUrl}&format=json`;
  }
}

export class IFrameUrlType extends UrlType {
  _getUrlPart(incomeUrl: string): string {
    return `tdsId=${this._campaignId}_r&tds_campaign=${this._campaignId}&${incomeUrl}&format=html`;
  }
}

export class ShortLinkUrlType extends UrlType {
  _getUrl(): string {
    return url.format({
      protocol: this._protocol,
      hostname: this._domain,
      pathname: "/",
      search: this._getUrlPart(),
    });
  }

  _getUrlPart(): string {
    return `i=${this._shortUrlId}&c=${this._shortUrlTime}`;
  }

  getView(): Node {
    return (
      <ShortUrlBuilder
        getUrl={(): string => this._getUrl()}
        shortUrlId={this._shortUrlId}
        shortUrlTime={this._shortUrlTime}
        campaignId={this._campaignId}
      />
    );
  }
}
