/* eslint-disable import/max-dependencies */
// @flow
import {
  debounce,
  put,
  call,
  select,
} from "redux-saga/effects";
import { type Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setTableData, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table";
import {
  FETCH_GENERAL_FILTER_CAMPAIGNS_SAGA,
  GENERAL_FILTERS_CAMPAIGNS_TABLE,
} from "../../helpers/constants";
import GeneralFilterApi from "../../services/generalFilterApiV2";
import type { CampaignType } from "../../reducers/generalFilterInfo";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(GENERAL_FILTERS_CAMPAIGNS_TABLE, true));

    const page: number = yield select(getTablePage, GENERAL_FILTERS_CAMPAIGNS_TABLE);
    const pageSize: number = yield select(getTablePageSize, GENERAL_FILTERS_CAMPAIGNS_TABLE);
    const filters: Filters = yield select(getTableFilters, GENERAL_FILTERS_CAMPAIGNS_TABLE);

    if (!Object.keys(filters).length) {
      return;
    }

    const { data: response }: Response<CampaignType> = yield call(
      GeneralFilterApi.getCampaigns,
      page,
      pageSize,
      filters
    );

    yield put(setTableData(GENERAL_FILTERS_CAMPAIGNS_TABLE, response.data));
    yield put(changeTableItemsTotalAmount(GENERAL_FILTERS_CAMPAIGNS_TABLE, response.count));
  }
  catch (err) {
    yield put(addNotification({ message: err.errorMessage || "Failed to fetch campaigns", severity: "error" }));
    console.error(err); // eslint-disable-line
  }
  finally {
    yield put(setLoading(GENERAL_FILTERS_CAMPAIGNS_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield debounce(1000, FETCH_GENERAL_FILTER_CAMPAIGNS_SAGA, (makeFetch: Function));
}
