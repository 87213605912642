// @flow
import React, { type Element } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EditOutlined,
  Check,
  RemoveCircle,
  CallSplit,
} from "@mui/icons-material";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import {
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
} from "@fas/ui-core";
import type { DataItem } from "@fas/ui-framework/lib/redux/reducers/table";
import { useNavigate } from "react-router-dom";
import { SMARTLINK_AGGREGATORS_TABLE } from "../../helpers/constants";
import type { State } from "../../pages/AppStoreWrapper";
import type { SmartlinkRedirect } from "../../selectors/smartlinkAggregators";
import { userService } from "../../services/user";

type Props = {
  onChangeIsActive: () => mixed,
};

const SaTableActions: (props: Props) => Element<typeof TableActions> = ({
  onChangeIsActive,
}: Props): Element<typeof TableActions> => {
  const items: DataItem[] = useSelector(
    (state: State): DataItem[] => getTableSelections(state, SMARTLINK_AGGREGATORS_TABLE)
  );
  const navigate = useNavigate();
  const dispatch: <A>(A) => A = useDispatch();
  const editPath: string = "/smartlinkAggregators/:id";
  const getIsEditAvailable: () => boolean = () => (
    items.length > 1 || !userService.can([
      { path: "/api/v1/smartlinkAggregators", method: "GET" },
      { path: "/api/v1/smartlinkAggregators", method: "PUT" },
    ]));

  const isActivateAvailable: boolean = userService.can([{ path: "/api/v1/smartlinkAggregators/switchIsActive", method: "PUT" }]);

  const getIsActivateAvailable: () => boolean = () => (
    items.some(({ isActive }: SmartlinkRedirect): boolean => isActive) || !isActivateAvailable
  );

  const getIsDeactivateAvailable: () => boolean = () => (
    items.some(({ isActive }: SmartlinkRedirect): boolean => !isActive) || !isActivateAvailable
  );

  return (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Edit"
          disabled={getIsEditAvailable && getIsEditAvailable()}
          onClick={() => {
            navigate(editPath ? editPath.replace(":id", items[0].id) : "/");
          }}
          Icon={<EditOutlined />}
          data-testid="editButton"
        />
      </TableActionGroup>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Activate"
          disabled={getIsActivateAvailable && getIsActivateAvailable()}
          onClick={(): mixed => dispatch(onChangeIsActive())}
          Icon={<Check />}
          data-testid="activateButton"
        />
        <SimpleTableActionButton
          tooltip="Deactivate"
          disabled={getIsDeactivateAvailable && getIsDeactivateAvailable()}
          onClick={(): mixed => dispatch(onChangeIsActive())}
          Icon={<RemoveCircle />}
          data-testid="deactivateButton"
        />
      </TableActionGroup>
      <TableActionGroup>
        <SimpleTableActionButton
          data-testid="SaToCampaigns"
          tooltip="Show campaigns"
          onClick={() => {
            const ids = items.map((i) => i.id).join(",");
            navigate(`/smartlinkAggregators/campaigns?id=${ids}`);
          }}
          Icon={<CallSplit sx={{ transform: "rotate(90deg)" }} />}
        />
      </TableActionGroup>
    </TableActions>
  );
};

export default SaTableActions;
