import {
  call,
  put,
  select,
  throttle,
} from "redux-saga/effects";
import {
  getNextId,
  getFunnelTimestamp,
} from "@fas/ui-framework/lib/services/generators";
import { CLONE_FUNNEL_SAGA } from "../../helpers/constants";
import { addFunnel } from "../../actions/funnels";
import { cloneOfferSaga } from "../../actions/offers";
import {
  getFunnelsBySplitId,
  getMaxFunnels,
  getFunnelsAllIds,
  generateCloneName,
  getIsActiveByFunnelId,
  getOffersByFunnelId,
  getTargetingsAllIds,
  getActionOptionsParamsByFunnelId,
  getFilteringsByFunnelId,
} from "../../selectors/smartlink";
import { cloneTargetingSaga } from "../../actions/targetings";
import { cloneFilteringSaga } from "../../actions/filterings";
import { cloneActionOptionsParamsSaga } from "../../actions/actionOptionsParams/actions";

export function* cloneFunnelSaga(action) {
  const {
    splitId,
    funnelId,
  } = action;
  const funnels = yield select(getFunnelsBySplitId, splitId);
  const maxFunnels = yield select(getMaxFunnels);
  if (funnels.size === maxFunnels) {
    return;
  }

  const allIds = yield select(getFunnelsAllIds);

  const nextFunnelId = getNextId(allIds);
  const timestamp = yield call(getFunnelTimestamp, nextFunnelId);
  const name = yield select(generateCloneName, funnelId);
  const isActive = yield select(getIsActiveByFunnelId, funnelId);

  const targetingAllIds = yield select(getTargetingsAllIds);
  const nextRootTargetingId = getNextId(targetingAllIds);
  yield put(addFunnel(
    splitId,
    nextFunnelId,
    name,
    timestamp,
    isActive,
    nextRootTargetingId
  ));

  const offers = yield select(getOffersByFunnelId, funnelId);
  const filterings = yield select(getFilteringsByFunnelId, funnelId);
  const actionOptionsParamses = yield select(getActionOptionsParamsByFunnelId, funnelId);

  yield put(cloneTargetingSaga({ funnelId }));

  for (let i = 0; i < offers.size; i++) {
    yield put(cloneOfferSaga(nextFunnelId, offers.get(i)));
  }

  for (let i = 0; i < filterings.size; i++) {
    yield put(cloneFilteringSaga(nextFunnelId, filterings.get(i)));
  }

  for (let i = 0; i < actionOptionsParamses.size; i++) {
    yield put(cloneActionOptionsParamsSaga(nextFunnelId, actionOptionsParamses.get(i)));
  }
}

export default function* watchCloneFunnel() {
  yield throttle(1000, CLONE_FUNNEL_SAGA, cloneFunnelSaga);
}
