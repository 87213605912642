// @flow
import React, { type StatelessFunctionalComponent } from "react";
import environment from "environment";
import type { Url, CollapseItem, Breadcrumb } from "@fas/ui-core/lib/AppNavbar";
import { AppNavbar } from "@fas/ui-core";
import { useLocation } from "react-router-dom";
import { userService } from "../../services/user";
import { userApiService } from "../../services/userApi";
import getBreadcrumbs from "./breadcrumbsMap";
import appLinks from "./appLinks";
import type { Link } from "./types/AppHeader.types";

const AppHeader: StatelessFunctionalComponent<{}> = () => {
  const location = useLocation();
  const menuLinks: Array<CollapseItem | Url> = [
    {
      url: "/smartlink",
      text: "Smartlink",
      permissions: ["/unitedReborn/v2/smartlink/create"],
    },
    {
      url: "/smartlinkAggregators/list",
      text: "Smartlink Aggregators",
      permissions: [{ path: "/api/v1/smartlinkAggregators/list", method: "POST" }],
    },
    {
      text: "General filter",
      links: [
        {
          url: "/generalFilter/list",
          text: "List",
          permissions: [{ path: "/api/v1/generalFilterV2/list", method: "POST" }],
        },
        {
          url: "/generalFilter/add", // FIX 92090
          text: "Create new",
          permissions: [],
        },
      ],
    },
    {
      url: "/funnelTemplates/list",
      text: "Funnels Template",
      permissions: ["/dictionary/funnelTemplates/list"],
    },
    {
      text: "Antifraud",
      links: [
        {
          url: "/antifraudTriggers/list",
          text: "Triggers",
          permissions: [{ path: "/api/v1/frodi/triggers/list", method: "GET" }],
        },
        {
          url: "/antifraudDictionaries/list",
          text: "Dictionaries",
          permissions: [{ path: "/api/v1/frodi/dictionaries/list", method: "GET" }],
        },
        {
          url: "/antifraudScenarios/list",
          text: "Scenarios",
          permissions: [{ path: "/api/v1/frodi/scenarios/list", method: "GET" }],
        },
        {
          url: "/antifraudVariables/list",
          text: "Variables",
          permissions: [{ path: "/api/v1/frodi/variables/list", method: "GET" }],
        },
      ],
    },
    {
      url: "/historyReplace/list",
      text: "Replace LP History",
      permissions: [{ path: "/api/v1/historyReplaceLP/list", method: "POST" }],
    },
    {
      url: "/frequencyCapForJumps",
      text: "Frequency Cap for Jumps",
      permissions: [{ path: "/api/v1/jumpsForFrequencyCap", method: "PUT" }],
    },
  ]
    .filter(({ permissions = [] }): boolean => userService.can(permissions))
    .map(({ url = "", text, links }: { url?: string, text: string, links?: Array<Link>}): CollapseItem | Url => {
      if (links) {
        return ({
          text,
          links: links
            .filter(({ permissions }: Link): boolean => userService.can(permissions))
            .map((item: Link): Url => ({ url: item.url, text: item.text })),
        });
      }
      return ({ text, url });
    })
    .filter((item: Url | CollapseItem): boolean => !item.links || item.links.length !== 0);

  const breadcrumbs: Breadcrumb[] = getBreadcrumbs(location.pathname);

  const handleLogout: () => void = () => {
    userApiService.logout(environment.logoutUrl);
  };

  return (
    <AppNavbar
      appName="TDS Admin"
      menuLinks={menuLinks}
      appLinks={appLinks}
      breadcrumbs={breadcrumbs}
      onLogout={handleLogout}
    />
  );
};

export default AppHeader;
