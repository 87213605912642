// @flow
import {
  ADD_OFFER,
  CLONE_OFFER_SAGA,
  DELETE_OFFER,
  DELETE_SERVICE_OFFER,
  EQUAL_SHARES_OFFERS,
  OFFER_WEIGHT_CHANGE,
  SAVE_OFFERS_SAGA,
  TOGGLE_OFFER_ACTIVE_STATUS,
  CHANGE_OFFER_URL,
  GET_OFFERS_SAGA,
} from "../../helpers/constants";
import type { Offer } from "../../reducers/offers";

export type AddOfferAction = {
  type: typeof ADD_OFFER,
  funnelId: string,
  offerId: string,
  offer: Offer,
};

export type ChangeOfferWeightAction = {
  type: typeof OFFER_WEIGHT_CHANGE,
  offerId: string,
  weight: number,
};

export type DeleteOfferAction = {
  type: typeof DELETE_OFFER,
  funnelId: string,
  offerId: string,
};

export type DeleteServiceOfferAction = {
  type: typeof DELETE_SERVICE_OFFER,
  offerId: string,
};

export type EqualSharesOffersAction = {
  type: typeof EQUAL_SHARES_OFFERS,
  offersIds: Array<string>,
};

export type CloneOfferSaga = {
  type: typeof CLONE_OFFER_SAGA,
  funnelId: string,
  offerId: string,
};

export type SaveOffersSagaAction = {
  type: typeof SAVE_OFFERS_SAGA,
  funnelId: string,
  offers: Array<Offer>,
};

export type ToggleOfferActiveStatus = {
  type: typeof TOGGLE_OFFER_ACTIVE_STATUS,
  offerId: string,
  isActive: boolean,
};

export type ChangeOfferURLAction = {
  type: typeof CHANGE_OFFER_URL,
  offerId: string,
  url: string,
}

export type GetOffersSaga = {
  type: typeof GET_OFFERS_SAGA,
};

export type OffersActions = AddOfferAction
  | ChangeOfferWeightAction
  | DeleteOfferAction
  | EqualSharesOffersAction
  | CloneOfferSaga
  | SaveOffersSagaAction
  | DeleteServiceOfferAction
  | ToggleOfferActiveStatus
  | ChangeOfferURLAction
  | GetOffersSaga;

export const addOffer = (
  funnelId: string,
  offerId: string,
  offer: Offer
): AddOfferAction => ({
  type: ADD_OFFER,
  funnelId,
  offerId,
  offer,
});

export const changeOfferWeight = (offerId: string, weight: number): ChangeOfferWeightAction => ({
  type: OFFER_WEIGHT_CHANGE,
  offerId,
  weight,
});

export const deleteOffer = (funnelId: string, offerId: string): DeleteOfferAction => ({
  type: DELETE_OFFER,
  funnelId,
  offerId,
});

export const deleteServiceOffer = (offerId: string): DeleteServiceOfferAction => ({
  type: DELETE_SERVICE_OFFER,
  offerId,
});

export const equalSharesOffers = (offersIds: Array<string>): EqualSharesOffersAction => ({
  type: EQUAL_SHARES_OFFERS,
  offersIds,
});

export const cloneOfferSaga = (funnelId: string, offerId: string): CloneOfferSaga => ({
  type: CLONE_OFFER_SAGA,
  funnelId,
  offerId,
});

export const saveOffersSaga = (funnelId: string, offers: Array<Offer>): SaveOffersSagaAction => ({
  type: SAVE_OFFERS_SAGA,
  funnelId,
  offers,
});

export const toggleOfferActiveStatus = (offerId:string, isActive:boolean): ToggleOfferActiveStatus => ({
  type: TOGGLE_OFFER_ACTIVE_STATUS,
  offerId,
  isActive,
});

export const changeOfferUrl = (offerId:string, url:string): ChangeOfferURLAction => ({
  type: CHANGE_OFFER_URL,
  offerId,
  url,
});

export const getOffersSaga: () => GetOffersSaga = () => ({ type: GET_OFFERS_SAGA });
