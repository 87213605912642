// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import DocumentTitle from "react-document-title";
import GeneralFilterTable from "../../containers/GeneralFilterList";

function GeneralFilterList() {
  return (
    <DocumentTitle title="General Filters">
      <GeneralFilterTable />
    </DocumentTitle>
  );
}

export default GeneralFilterList;
