// @flow
/* eslint-disable import/max-dependencies */
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { createCachedSelector } from "re-reselect";
import {
  Map,
  List,
  type Map as MapType,
  type List as ListType,
  type Set as SetType,
} from "immutable";
import { createLoadingSelector, type StoreWithLoading } from "@fas/ui-framework/lib/redux/selectors/loading";
import { getErrorMessage } from "@fas/ui-framework/lib/redux/selectors/errors";
import type { Group } from "@fas/ui-core/lib/FlatQueryBuilder";
import type { Option } from "@fas/ui-framework/lib/redux/reducers/dropdowns/types";
import type { State } from "../../reducers/antifraudTriggerForm";
import type { State as AntifraudTriggerFormState } from "../../pages/AntifraudTriggerForm";
import type {
  AntifraudTriggersLoadingTypes,
  Errors,
  Trigger,
  ConditionRule,
  RuleTriggerQb,
  SelectedTriggerRule,
} from "./types";

export * from "./types";

export const getAntifraudTrigger: OutputSelector<AntifraudTriggerFormState, *, Trigger> = createSelector(
  (state: AntifraudTriggerFormState): State => state.antifraudTriggerForm,
  (data: State): Trigger => {
    // eslint-disable-next-line no-unused-vars
    const { triggerRules, triggerValues, ...trigger }: State = data.toJS();
    return trigger;
  }
);

export const getLoading: OutputSelector<
  StoreWithLoading<AntifraudTriggersLoadingTypes>, AntifraudTriggersLoadingTypes, boolean
> = createLoadingSelector<AntifraudTriggersLoadingTypes>();

export const getErrors: OutputSelector<AntifraudTriggerFormState, *, Errors> = createSelector(
  [
    (state: AntifraudTriggerFormState): string => getErrorMessage(state, "name"),
    (state: AntifraudTriggerFormState): string => getErrorMessage(state, "score"),
    (state: AntifraudTriggerFormState): string => getErrorMessage(state, "location"),
    (state: AntifraudTriggerFormState): string => getErrorMessage(state, "description"),
    (state: AntifraudTriggerFormState): string => getErrorMessage(state, "conditions"),
  ],
  (
    name: string,
    score: string,
    location: string,
    description: string,
    conditions: string
  ): Errors => ({
    name,
    score,
    location,
    description,
    conditions,
  })
);

/**
 * Antifraud conditions QB
 */

export const getRuleList: OutputSelector<
  AntifraudTriggerFormState, *, Array<RuleTriggerQb>
> = createSelector(
  (state: AntifraudTriggerFormState): ListType<RuleTriggerQb> => state.antifraudTriggerForm.get("triggerRules"),
  (conditionsList: ListType<RuleTriggerQb>): Array<RuleTriggerQb> => conditionsList.toArray()
);

const getAntifraudTriggerConditions: (state: AntifraudTriggerFormState) => MapType<string, mixed> = (state) => state.antifraudTriggerForm.get("conditions");

const getAntifraudTriggerConditionById: (state: AntifraudTriggerFormState, id: string) => MapType<string, mixed> = (state, id): MapType<string, mixed> => state.antifraudTriggerForm.getIn(["conditions", id], Map({}));

export const getAllConditionsRules: OutputSelector<
  AntifraudTriggerFormState, *, Array<string>
> = createSelector(
  getAntifraudTriggerConditions,
  (conditions: MapType<string, mixed>): Array<string> => {
    const rules: SetType<string> = conditions
      .filter((rule: MapType<string, mixed>): boolean => rule.get("type") === "rule")
      .map((rule: MapType<string, mixed>): string => rule.get("name"))
      .toSet();
    return rules.toArray();
  }
);

export const getGroupByIdSelector: OutputSelector<AntifraudTriggerFormState, *, Group> = createCachedSelector(
  getAntifraudTriggerConditionById,
  (condition: MapType<string, mixed>): Group => {
    const { children, groupOperator, type }: {
      children: ListType<string>,
      groupOperator: "AND" | "OR",
      type: string
    } = condition.toObject();

    const targetings: Array<string> = children ? children.toArray() : [];
    return ({
      groupOperator,
      type,
      targetings,
    });
  }
)(
  (state: AntifraudTriggerFormState, id: string): string => id
);

export const getRuleByIdSelector: OutputSelector<
  AntifraudTriggerFormState, *, SelectedTriggerRule
> = createCachedSelector(
  [
    getRuleList,
    getAntifraudTriggerConditionById,
  ],
  (ruleList: Array<RuleTriggerQb>, rule: MapType<string, mixed>): SelectedTriggerRule => {
    const {
      name = "",
      ...ruleObj
    }: ConditionRule = rule.toJS();
    const ruleFromDic: RuleTriggerQb | typeof undefined = ruleList
      .find((item: RuleTriggerQb): boolean => item.value === name);
    const label: string = ruleFromDic ? ruleFromDic.label : name;

    return ({
      ...ruleObj,
      name,
      label,
    });
  }
)(
  (state: AntifraudTriggerFormState, id: string): string => id
);

export const getTriggerValuesList: OutputSelector<
  AntifraudTriggerFormState, *, Array<Option<string>>
> = createSelector(
  (state: AntifraudTriggerFormState): ListType<Option<string>> => state.antifraudTriggerForm.get("triggerValues"),
  (conditionsList: ListType<Option<string>>): Array<Option<string>> => conditionsList.toArray()
);

export const getOperatorsList: OutputSelector<
  AntifraudTriggerFormState,
  *,
  Array<Option<string>>
> = createCachedSelector(
  (state: AntifraudTriggerFormState, ruleName: string): List<Option<string>> => state.antifraudTriggerForm
    .get("triggerRules")
    .find((rule: Option<string>): boolean => rule.value === ruleName),
  (rule: Map<string, mixed>): Array<Option<string>> => (rule ? rule.operators : [])
)(
  (state: AntifraudTriggerFormState, ruleName: string): string => ruleName
);

export const getConditionsErrorById: OutputSelector<State, *, {
  name: { message: string } | null,
  value: { message: string } | null,
  operator: { message: string } | null
}> = createCachedSelector(
  (state: State, id: string): Map<string, mixed> => state.errors.getIn(["conditions", id], Map({})),
  (error: Map<string, mixed>): {
    rule: { message: string } | null,
    value: { message: string } | null,
    operator: { message: string } | null
  } => error.toJS()
)(
  (state: AntifraudTriggerFormState, id: string): string => id
);

export const getGroupErrorById: OutputSelector<State, *, string> = createCachedSelector(
  (state: State, id: string): string => state.errors
    .getIn(["conditions", id, "children", "message"], ""),
  (error: string): string => error
)(
  (state: AntifraudTriggerFormState, id: string): string => id
);
