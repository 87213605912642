// @flow
/* eslint-disable import/max-dependencies */
import { fromJS, Map } from "immutable";
import type { MarketingGroupInfo } from "@fas/ui-framework/lib/services/user";
import { userService } from "../../services/user";
import {
  CHANGE_DOMAIN,
  CHANGE_CAMPAIGN_NAME,
  CHANGE_FUNNEL_GUARD_OUT,
  CHANGE_TEST_CAMPAIGN,
  SET_FETCHED_CAMPAIGN_ID,
  CHANGE_REQUEST_LIMIT_PER_SEC, CHANGE_CAMPAIGN_EXTERNAL_NAME,
  CHANGE_AFFILIATE_DATA_TYPE,
  SET_SHORT_URL,
} from "../../helpers/constants";
import type { PlaceholdersState } from "../placeholders/reducer";
import type { FilteringsState } from "../filterings/reducer";
import type { FunnelsState } from "../funnels/reducer";
import type { SplitsState } from "../splits/reducer";
import type { TargetingsState } from "../targetings/reducer";
import type { OffersState } from "../offers/reducer";
import type { FallbackState } from "../fallback/reducer";
import type { NonTargetState } from "../nonTarget/reducer";
import type { FrequencyState } from "../frequency/reducer";
import type { ActionOptionsParamsState } from "../actionOptionsParams/reducer";
import type { Setting } from "../../components/FilterSettingsList/types/FilterSettingsList.types";

export type CampaignInfoState = {|
  id: string,
  domain: string,
  protocol: string,
  type: string,
  name: string,
  externalName: string,
  requestLimitPerSec: number,
  funnelGuardOut: boolean,
  isTest: boolean,
  path: string,
  affiliateDataType?: "direct" | "smartlink",
  shortUrlId: string,
  shortUrlTime: string,
|};

export type State = CampaignInfoState;

export type Campaign = {
  campaignType: "smartlink",
  campaignInfo?: CampaignInfoState,
  fallback?: FallbackState,
  nonTarget?: NonTargetState,
  placeholders?: PlaceholdersState,
  splits?: SplitsState,
  funnels?: FunnelsState,
  targetings?: TargetingsState,
  filterings?: FilteringsState,
  offers?: OffersState,
  frequency?: FrequencyState,
  actionOptionsParams?: ActionOptionsParamsState,
  generalFilters?: Array<Setting>
};

export const initialState: Map<State> = Map({
  id: "",
  domain: "",
  protocol: "",
  type: "smartlink",
  name: "",
  externalName: "",
  affiliateDataType: null,
  isAffiliateDataTypeDisabled: false,
  requestLimitPerSec: 300,
  funnelGuardOut: false,
  isTest: false,
  path: "/tds",
  shortUrlId: "",
  shortUrlTime: "",
});

/**
 * Get initial state by campaign type and user
 * @param {string} campaignType campaign type smartlink | basic
 * @returns {Map<State>} initialState initial state for campaign info
 */
export function getInitialState(): Map<State> {
  const marketingGroupInfo: MarketingGroupInfo | {} = userService.getMarketingGroupInfo();

  const path: string = String(marketingGroupInfo.ur_url || "/tds");

  return initialState.merge({
    path,
  });
}

export function initCampaignInfoState(campaign: Campaign): Map<State> {
  const { campaignInfo }: Campaign = campaign;
  if (campaignInfo) {
    return fromJS({
      ...campaignInfo,
      isAffiliateDataTypeDisabled: !!campaignInfo.affiliateDataType,
    });
  }
  return getInitialState();
}

export default (state: Map<State> = initialState, action: any): Map<State> => {
  switch (action.type) {
    case SET_FETCHED_CAMPAIGN_ID: {
      const { campaignId } = action;
      return state.set("id", campaignId);
    }
    case CHANGE_DOMAIN: {
      const { domain, protocol } = action;
      return state.set("domain", domain).set("protocol", protocol);
    }
    case CHANGE_CAMPAIGN_NAME: {
      const { name } = action;
      return state.set("name", name);
    }
    case CHANGE_CAMPAIGN_EXTERNAL_NAME: {
      const { name } = action;
      return state.set("externalName", name);
    }
    case CHANGE_FUNNEL_GUARD_OUT: {
      const { value } = action;
      return state.set("funnelGuardOut", value);
    }
    case CHANGE_TEST_CAMPAIGN: {
      const { value } = action;
      return state.set("isTest", value);
    }
    case CHANGE_REQUEST_LIMIT_PER_SEC: {
      const { value } = action;
      return state.set("requestLimitPerSec", value);
    }
    case CHANGE_AFFILIATE_DATA_TYPE: {
      const { value } = action;
      return state.set("affiliateDataType", value);
    }
    case SET_SHORT_URL: {
      const { shortUrlId, shortUrlTime } = action;
      return state.set("shortUrlId", shortUrlId).set("shortUrlTime", shortUrlTime);
    }
    default:
      return state;
  }
};
