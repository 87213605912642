// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { useDispatch } from "react-redux";
import {
  EditOutlined,
  FileCopyOutlined,
  Check,
  RemoveCircle,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { SimpleTableActionButton } from "@fas/ui-core";
// import { useNavigate } from "react-router-dom";
import type { GeneralFilterListItem } from "./type";
import { userService } from "../../services/user";
import { changeGeneralFilterActive } from "../../actions/generalFilters";

export type Props = {
  ...GeneralFilterListItem,
};

const TableRowActions: StatelessFunctionalComponent<Props> = ({
  id, isActive,
}: Props) => {
  const dispatch: <A>(A) => A = useDispatch();
  // const navigate = useNavigate();

  const editPath = "/generalFilter/edit/:id";
  const clonePath = "/generalFilter/clone/:id";

  const isEditAvailable: boolean = userService.can([
    { path: "/api/v1/generalFilter", method: "POST" },
    // { path: "/api/v1/generalFilterV2", method: "GET" },
    // { path: "/api/v1/generalFilterV2", method: "PUT" },
  ]);

  const isCloneAvailable: boolean = userService.can([
    { path: "/api/v1/generalFilter", method: "POST" },
    // { path: "/api/v1/generalFilterV2/clone", method: "GET" },
  ]);
  const isActivateAvailable: boolean = userService.can([
    { path: "/api/v1/generalFilterV2/switchIsActive", method: "PUT" },
  ]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <SimpleTableActionButton
        tooltip="Edit"
        disabled={!isEditAvailable}
        onClick={() => window.open(editPath.replace(":id", `${id}`), "_blank")}
        Icon={<EditOutlined sx={{ color: "text.primary" }} />}
        data-testid="editButton"
      />
      <SimpleTableActionButton
        tooltip="Clone"
        disabled={!isCloneAvailable}
        onClick={() => window.open(clonePath.replace(":id", `${id}`), "_blank")}
        Icon={<FileCopyOutlined sx={{ color: "text.primary" }} />}
        data-testid="cloneButton"
      />
      {!isActive && (
        <SimpleTableActionButton
          tooltip="Activate"
          disabled={!isActivateAvailable}
          onClick={(): mixed => dispatch(changeGeneralFilterActive([id], true))}
          Icon={<Check sx={{ color: "success.main" }} />}
          data-testid="activateButton"
        />
      )}
      {isActive && (
        <SimpleTableActionButton
          tooltip="Deactivate"
          disabled={!isActivateAvailable}
          onClick={(): mixed => dispatch(changeGeneralFilterActive([id], false))}
          Icon={<RemoveCircle sx={{ color: "error.main" }} />}
          data-testid="deactivateButton"
        />
      )}
    </Box>
  );
};

export default TableRowActions;
