// @flow
import React, { useState, useCallback, useEffect } from "react";
import { TextField, Box, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import debounce from "lodash.debounce";
import {
  Clear, ExpandLess, ExpandMore, Search,
} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";

type Props = {
  scrollToItem: ?string,
  searchText: string,
  result: string[],
  onChange: (string) => mixed,
  onChangeSearchData: ({ scrollToItem: ?string }) => mixed,
}

const useStyle = makeStyles(() => ({
  root: {
    width: "250px",
    "&:hover $clearIndicator": {
      visibility: "visible",
    },
  },
  clearIndicator: {
    padding: 4,
    visibility: "hidden",
  },
}));

const SearchComponent = ({
  scrollToItem,
  onChangeSearchData,
  searchText,
  onChange,
  result,
}: Props) => {
  const classes = useStyle();
  const [value, setValue] = useState<string>(searchText);
  const debounceChange = useCallback(debounce(onChange, 600), []);

  useEffect(() => () => {
    setValue("");
    onChange("");
  }, [/* empty */]);// clear search in unmount component

  const allCount: number = result.length;
  const currentSearchIndex: number = result.indexOf(scrollToItem);

  function handlePrev() {
    if (allCount) {
      const prevIndex: number = (currentSearchIndex - 1 + allCount) % allCount;
      onChangeSearchData({
        scrollToItem: result[prevIndex],
      });
    }
  }
  function handleNext() {
    if (allCount) {
      const nextIndex: number = (currentSearchIndex + 1) % allCount;
      onChangeSearchData({
        scrollToItem: result[nextIndex],
      });
    }
  }

  return (
    <Box display="flex" alignItems="center">
      <TextField
        className={classes.root}
        variant="outlined"
        size="small"
        value={value}
        placeholder="Find"
        onChange={({ target: { value: newValue } }) => {
          setValue(newValue);
          debounceChange(newValue);
        }}
        onKeyUp={({ which, shiftKey }) => {
          const enterKey: number = 13;
          if (which === enterKey) {
            shiftKey ? handlePrev() : handleNext();
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {value && (
                <IconButton
                  title="Clear"
                  className={classes.clearIndicator}
                  onClick={() => {
                    setValue("");
                    debounceChange("");
                  }}
                >
                  <Clear />
                </IconButton>
              )}
              {(allCount === 0 ? "0/0" : `${currentSearchIndex + 1}/${allCount}`) }
            </InputAdornment>
          ),
        }}
      />
      <IconButton
        title="Previous occurrence"
        size="small"
        disabled={allCount === 0}
        onClick={() => handlePrev()}
      >
        <ExpandLess />
      </IconButton>
      <IconButton
        title="Next occurrence"
        size="small"
        disabled={allCount === 0}
        onClick={() => handleNext()}
      >
        <ExpandMore />
      </IconButton>
    </Box>
  );
};

export default SearchComponent;
