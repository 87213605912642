// @flow
import environment from "environment";
import RequestService from "@fas/ui-framework/lib/services/request";
import { userService } from "../user";

const getAuthorizationHeaders: () => { [key: string]: string } = () => ({
  "X-Api-Key": userService.getApiKey() || "",
});

// eslint-disable-next-line import/prefer-default-export
export const requestService: RequestService = new RequestService({
  withCredentials: true,
  timeout: 30000,
  customAttr: {
    getAuthorizationHeaders,
    redirect: () => {
      window.location = `${environment.endpoints.get.loginUrl}?r=${window.location.href}`;
    },
  },
});
