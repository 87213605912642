// @flow

import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import { useEffect, useRef } from "react";
import { SAVE_FORM_DATA_SAGA, GET_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import { useLoading } from "../../hooks/useLoading";
import {
  ANTIFRAUD_VARIABLE_FORM,
} from "../../helpers/constants";

// eslint-disable-next-line import/prefer-default-export
export function useResetFieldByFieldChange(watchFieldName: string, targetFieldName: string, resetValue: mixed = "") {
  const loading: boolean = useLoading(GET_FORM_DATA_SAGA, SAVE_FORM_DATA_SAGA);
  const { onChange, value: targetValue } = useFormField(ANTIFRAUD_VARIABLE_FORM, targetFieldName);
  const { value } = useFormField(ANTIFRAUD_VARIABLE_FORM, watchFieldName);

  const isMount = useRef<boolean>(true);
  useEffect(() => {
    if (isMount.current) {
      isMount.current = false;
      return;
    }

    if (loading || targetValue === resetValue) return;
    onChange(resetValue);
  }, [value]);
}
