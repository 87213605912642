// @flow
import {
  CHANGE_DOMAIN,
  CHANGE_CAMPAIGN_NAME,
  CHANGE_CAMPAIGN_EXTERNAL_NAME,
  CHANGE_FUNNEL_GUARD_OUT,
  CHANGE_TEST_CAMPAIGN,
  START_FETHING_CAMPAIGN_ID_SAGA,
  SET_FETCHED_CAMPAIGN_ID,
  CHANGE_REQUEST_LIMIT_PER_SEC,
  CHANGE_AFFILIATE_DATA_TYPE,
  SET_SHORT_URL,
  GET_SHORT_URL_SAGA,
} from "../../helpers/constants";

export type SelectDomainAction = {
  type: typeof CHANGE_DOMAIN,
  domain: string,
  protocol: string,
};
export type ChangeCampaignNameAction = {
  type: typeof CHANGE_CAMPAIGN_NAME,
  name: string,
};
export type ChangeCampaignExternalNameAction = {
  type: typeof CHANGE_CAMPAIGN_EXTERNAL_NAME,
  name: string,
};
export type ChangeFGOutAction = {
  type: typeof CHANGE_FUNNEL_GUARD_OUT,
  value: boolean,
};
export type ChangeTestCampaignAction = {
  type: typeof CHANGE_TEST_CAMPAIGN,
  value: boolean,
};

export type ChangeRequestLimitPerSecAction = {
  type: typeof CHANGE_REQUEST_LIMIT_PER_SEC,
  value: number,
};

export type ChangeAffiliateDataTypeAction = {
  type: typeof CHANGE_AFFILIATE_DATA_TYPE,
  value: string,
};

export type GetCampaignIdSagaAction = {|
  type: typeof START_FETHING_CAMPAIGN_ID_SAGA,
  campaignType: string,
|};

export type SetCampaignIdAction = {|
  type: typeof SET_FETCHED_CAMPAIGN_ID,
  campaignId: string,
|};

export type SetShortUrl = {
  type: typeof SET_SHORT_URL,
  shortUrlId: string,
  shortUrlTime: string,
};

export type GetShortUrlSaga = {
  type: typeof GET_SHORT_URL_SAGA,
  campaignId: string,
};

export const selectDomain = (
  domain: string,
  protocol: string
): SelectDomainAction => ({
  type: CHANGE_DOMAIN,
  domain,
  protocol,
});
export const changeCampaignName = (
  name: string
): ChangeCampaignNameAction => ({
  type: CHANGE_CAMPAIGN_NAME,
  name,
});
export const changeCampaignExternalName = (
  name: string
): ChangeCampaignExternalNameAction => ({
  type: CHANGE_CAMPAIGN_EXTERNAL_NAME,
  name,
});
export const changeFGOut = (
  value: boolean
): ChangeFGOutAction => ({
  type: CHANGE_FUNNEL_GUARD_OUT,
  value,
});
export const changeTestCampaign = (
  value: boolean
): ChangeTestCampaignAction => ({
  type: CHANGE_TEST_CAMPAIGN,
  value,
});

export const startFetchCampaignIdSaga = (campaignType: string): GetCampaignIdSagaAction => ({
  type: START_FETHING_CAMPAIGN_ID_SAGA,
  campaignType,
});

export const setCampaignId = (campaignId: string): SetCampaignIdAction => ({
  type: SET_FETCHED_CAMPAIGN_ID,
  campaignId,
});

export const changeRequestLimitPerSec = (value: number): ChangeRequestLimitPerSecAction => ({
  type: CHANGE_REQUEST_LIMIT_PER_SEC,
  value,
});

export const changeAffiliateDataType = (value: string): ChangeAffiliateDataTypeAction => ({
  type: CHANGE_AFFILIATE_DATA_TYPE,
  value,
});

export const setShortUrl = (shortUrlId: string, shortUrlTime: string): SetShortUrl => ({
  type: SET_SHORT_URL,
  shortUrlId,
  shortUrlTime,
});

export const getShortUrlSaga = (campaignId: string): GetShortUrlSaga => ({
  type: GET_SHORT_URL_SAGA,
  campaignId,
});
