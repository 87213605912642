// @flow
import {
  SAVE_CAMPAIGN_SAGA,
  START_SAVE_CAMPAIGN,
} from "../../helpers/constants";

export type SaveCampaignSagaType = {|
  type: typeof SAVE_CAMPAIGN_SAGA,
|};

export type StartSaveCampaignType = {|
  type: typeof START_SAVE_CAMPAIGN
|};

export const saveCampaignSaga = (): SaveCampaignSagaType => ({ type: SAVE_CAMPAIGN_SAGA });

export const startSaveCampaign = (): StartSaveCampaignType => ({ type: START_SAVE_CAMPAIGN });
