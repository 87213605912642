/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import { SetupCard } from "@fas/ui-core";
import type { Error } from "@fas/ui-framework/lib/redux/reducers/errors";
import SplitsDistribution from "../../components/SplitsDistribution";
import {
  getSplits,
  getSelectBy,
  getPeriod,
  getDefaultSplit,
  getErrors,
} from "../../selectors/smartlink";
import {
  changeFrequencyMethod,
  changeFrequencyPeriod,
  changeFrequencyDefaultSplit,
} from "../../actions/frequency";
import {
  setSplitWeight,
  setSplitFrequency,
  splitWeightsEqualShares,
} from "../../actions/splits";

import type { Split } from "../../reducers/splits";
import type { Props as ComponentProps } from "../../components/SplitsDistribution/types/SplitsDistribution.types";

const mapStateToProps = (state) => ({
  splits: getSplits(state),
  errors: getErrors(state),
  selectBy: getSelectBy(state),
  period: getPeriod(state),
  defaultSplit: getDefaultSplit(state),
});

const mapDispatchToProps = (dispatch) => ({
  onChangeSelection: (method) => dispatch(changeFrequencyMethod(method)),
  onPeriodChange: (period) => dispatch(changeFrequencyPeriod(period)),
  onSplitWeightChange: (splitId, weight) => dispatch(setSplitWeight(splitId, weight)),
  onSelectDefaultSplit: (split) => dispatch(changeFrequencyDefaultSplit(split)),
  onChangeSplitFrequency: (splitId, frequency) => dispatch(setSplitFrequency(splitId, frequency)),
  onSplitWeightsEqualShares: () => dispatch(splitWeightsEqualShares()),
});

type Props = {
  ...ComponentProps,
  errors: Map<string, Error>,
  splits: Map<Split>;
  onSplitWeightsEqualShares: void => void;
}

const SmartlinkSplitsInfo = ({
  splits,
  errors,
  selectBy,
  period,
  onChangeSelection,
  onPeriodChange,
  onSplitWeightChange,
  onSelectDefaultSplit,
  defaultSplit,
  onChangeSplitFrequency,
  onSplitWeightsEqualShares,
}: Props) => {
  if (splits.get("allIds").size <= 1) return <></>;
  return (
    <SetupCard
      title="Split Distribution"
    >
      <SplitsDistribution
        splits={splits.get("byId").toJS()}
        errors={errors.toJS()}
        selectBy={selectBy}
        period={period}
        onChangeSelection={onChangeSelection}
        onPeriodChange={onPeriodChange}
        onSplitWeightChange={onSplitWeightChange}
        onSelectDefaultSplit={onSelectDefaultSplit}
        defaultSplit={defaultSplit}
        onChangeSplitFrequency={onChangeSplitFrequency}
        onSplitWeightsEqualShares={onSplitWeightsEqualShares}
      />
    </SetupCard>
  );
};

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartlinkSplitsInfo);
