// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { getAntifraudVariablesListSaga } from "../../actions/antifraudVariablesList";
import {
  CHANGE_ANTIFRAUD_VARIABLE_ACTIVE_SAGA,
  ANTIFRAUD_VARIABLES_TABLE,
} from "../../helpers/constants";
import { changeAntifraudVariableActive } from "../../services/antifraudVariablesApi";
import type { Variable } from "../../selectors/antifraudVariables";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("changeAntifraudVariableActive", true));

    const selections: Array<Variable> = yield select(getTableSelections, ANTIFRAUD_VARIABLES_TABLE);
    const status: boolean = !selections.every(({ isActive }: Variable): boolean => isActive);
    const selectedIds: number[] = selections.map(({ varId }: Variable): number => varId);

    const ids: Array<{ id: number, status: boolean }> = selectedIds.map((id: number) => ({ id, status }));

    yield call(changeAntifraudVariableActive, ids);
    yield put(addNotification({ message: "Status changed", severity: "success" }));
    yield put(setTableSelection(ANTIFRAUD_VARIABLES_TABLE, []));
    yield put(getAntifraudVariablesListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to change status", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("changeAntifraudVariableActive", false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(CHANGE_ANTIFRAUD_VARIABLE_ACTIVE_SAGA, (makeFetch: Function));
}
